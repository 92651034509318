import relatorios from "@/modules/Relatorios/pages/Index";

export default [
  {
    path: "/relatorios",
    name: "relatorios",
    component: relatorios,
    meta: { title: "Rep Compras" },
  },
];
