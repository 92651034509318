<template>
  <b-tab title="Transportador (Opcional)" @click="() => store.currentTab = 2" :active="isTheCurrentTab" :disabled="!isTheCurrentTab && !store.isUpdate" class="mt-5">
    <b-row>
      <b-col sm="5">
        <b-form-group label="Razão Social" label-for="razaoSocial">
          <VueSelect ref="buscarTransportadora" :filterable="false" :state="validateTransportadoraState('razaoSocial')"
            :options="transportadoras" @search="onSearchTransportadoras" v-model="selectedTransportador">
            <template slot="no-options">
              Pesquise uma transportadora
            </template>
            <template slot="option" slot-scope="option">
              <div class="d-center">
                {{ option.nome }}
              </div>
            </template>
            <template slot="selected-option" slot-scope="option">
              <div class="selected d-center">
                {{ (typeof option.nome) !== 'string' ? '' : option.nome }}
              </div>
            </template>
          </VueSelect>
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group label="CNPJ/CPF" label-for="cnpj_cpf">
          <b-form-input disabled id="cnpj_cpf" name="cnpj_cpf" :value="selectedTransportador?.cnpj_cpf" :key="keyToRender" v-mask="['###.###.###-##', '##.###.###/####-##']" />
        </b-form-group>
      </b-col>

      <b-col>
        <b-form-group label="Veículo" label-for="veiculo">
          <VueSelect ref="buscarNatureza" :filterable="false" :options="veiculos" @search="onSearchVeiculo"
            v-model="selectedVehicle" :state="validateTransportadoraState('endereco')">
            <template slot="no-options">
              Selecione um veiculo
            </template>
            <template slot="option" slot-scope="option">
              <div class="d-center">
                {{ option.placa }}
              </div>
            </template>
            <template slot="selected-option" slot-scope="option">
              <div class="selected d-center">
                {{ option.placa }}
              </div>
            </template>
          </VueSelect>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col sm="7">
        <b-form-group label="Frete" label-for="frete">
          <VueSelect ref="buscarFrete" :filterable="false" :options="fretesSearched" @search="onSearchFrete"
            v-model="store.formTransportador.frete" :state="validateTransportadoraState('frete')">
            <template slot="no-options">
              Informe o tipo de frete
            </template>
            <template slot="option" slot-scope="option">
              <div class="d-center">
                {{ `${option.codigo} - ${option.descricao}` }}
              </div>
            </template>
            <template slot="selected-option" slot-scope="option">
              <div class="selected d-center">
                {{ `${option.codigo} - ${option.descricao}` }}
              </div>
            </template>
          </VueSelect>
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group label="Quantidade" label-for="quantidade">
          <money class="form-control" id="quantidade" name="quantidade" v-model="store.formTransportador.quantidade"
            v-bind="quantityOptions" :state="validateTransportadoraState('quantidade')">
          </money>
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group label="Espécie" label-for="especie">
          <b-form-input id="especie" name="especie" v-model="store.formTransportador.especie"
            :state="validateTransportadoraState('especie')">
          </b-form-input>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-form-group label="Marca" label-for="marca">
          <b-form-input id="marca" name="marca" v-model="store.formTransportador.marca"
            :state="validateTransportadoraState('marca')">
          </b-form-input>
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group label="Numeração" label-for="numeracao">
          <b-form-input id="numeracao" name="numeracao" v-model="store.formTransportador.numeracao"
            :state="validateTransportadoraState('numeracao')">
          </b-form-input>
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group label="Peso Bruto" label-for="pesoBruto">
          <money class="form-control" id="pesoBruto" name="pesoBruto" v-model="store.formTransportador.pesoBruto"
            v-bind="quantityOptions" :state="validateTransportadoraState('pesoBruto')">
          </money>
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group label="Peso Líquido" label-for="pesoLiquido">
          <money class="form-control" id="pesoLiquido" name="pesoLiquido" v-model="store.formTransportador.pesoLiquido"
            v-bind="quantityOptions" :state="validateTransportadoraState('pesoLiquido')">
          </money>
        </b-form-group>
      </b-col>
    </b-row>
  </b-tab>
</template>

<script>
import { mapGetters, mapState, mapActions } from "vuex";
import VueSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import { validationMixin } from "vuelidate";
import TransportadorData, { TransportadorDataRequired } from "../TransportadorData";
import { SistemaMixin, ValidacaoMixin } from "@/modules/Application";
import _ from "lodash";
import { mask } from "vue-the-mask";
import { Money } from "v-money";
import { MONEY as settingMoney, quantityOptions } from "@/modules/Application";
import { store } from "../../storePedidos";

export default {
  name: "app-nfe-form-transportadorTab",
  components: {
    VueSelect,
    Money,
  },
  directives: {
    mask,
  },
  data() {
    return {
      store,
      transportadoras: [],
      quantityOptions,
      selectedTransportador: null,
      transportadorSelected: null,
      selectedVehicle: null,
      keyToRender: 0,
      configMask: settingMoney,
      veiculos: [],
      cliente: null,
      ieOptions: ['Contribuinte', 'Isento', 'Não contribuinte'],
      fretes: [
        {
          "codigo": 0,
          "descricao": "Contratação do Frete por conta do Remetente (CIF)"
        },
        {
          "codigo": 1,
          "descricao": "Contratação do Frete por conta do Destinatário (FOB)"
        },
        {
          "codigo": 2,
          "descricao": "Contratação do Frete por conta de Terceiros"
        },
        {
          "codigo": 3,
          "descricao": "Transporte Próprio por conta do Rementente"
        },
        {
          "codigo": 4,
          "descricao": "Transporte Próprio por conta do Destinatário"
        },
        {
          "codigo": 9,
          "descricao": "Sem Ocorrência de Transporte"
        }
      ],
      fretesSearched: [],
      enderecos: [],
      fones: null,
      isCnpj: false,
    }
  },
  mixins: [
    validationMixin,
    SistemaMixin,
    ValidacaoMixin,
  ],
  validations: {
    form: TransportadorDataRequired
  },
  methods: {
    ...mapActions(["setLoading"]),
    async getDataInit() {
      this.fretesSearched = this.fretes
      if (Object.keys(this.$route.params).length !== 0) {
        const { deliveryInfo, volume } = this.$route.params.pedidoData.transportador;
        this.store.formTransportador = deliveryInfo ? deliveryInfo?.delivery : new TransportadorData({})
        this.selectedTransportador = _.cloneDeep(this.store.formTransportador);
        if (deliveryInfo?.delivery?.frete) this.store.formTransportador.frete = deliveryInfo.delivery.frete;
        if (volume) {
          this.store.formTransportador.quantidade = volume.quantity;
          this.store.formTransportador.especie = volume.species;
          this.store.formTransportador.marca = volume.brand;
          this.store.formTransportador.pesoLiquido = volume.netWeight;
          this.store.formTransportador.pesoBruto = volume.grossWeight;
          this.store.formTransportador.numeracao = volume.numeration;
        }
      } else {
        this.store.formTransportador = new TransportadorData({});
      }
    },
    validateTransportadoraState() {
      // const { $dirty, $error } = this.$v.form[name];
      // return $dirty ? !$error : null;
    },
    onSearchTransportadoras(search, loading) {
      if (search.length) {
        loading(true);
        this.searchTransportadoras(loading, search, this);
      }
    },
    onSearchVeiculo() {

    },
    searchTransportadoras: _.debounce(async (loading, search, vm) => {
      vm.transportadoras = await vm.getTransportadoras(search);
      loading(false);
    }, 350),
    onSearchFrete(search, loading) {
      if (search.length) {
        loading(true);
        this.fretesSearched = this.fretes.filter(frete => frete.codigo == search);
        loading(false);
      }
    },
  },
  created() {
    this.getDataInit();
  },
  watch: {
    selectedTransportador: {
      handler(value) {
        const prevsValues = {
          frete: this.store.formTransportador.frete,
          quantidade: this.store.formTransportador.quantidade,
          especie: this.store.formTransportador.especie,
          marca: this.store.formTransportador.marca,
          pesoLiquido: this.store.formTransportador.pesoLiquido,
          pesoBruto: this.store.formTransportador.pesoBruto,
          numeracao: this.store.formTransportador.numeracao,
          veiculo: this.store.formTransportador?.veiculo ?? null
        }
        this.store.formTransportador = {
          ...prevsValues,
          ...value,
        }
        if (!value) {
          this.veiculos = [];
          this.selectedVehicle = null;
          this.store.formTransportador = {
          ...prevsValues,
          ...value,
          veiculos: this.veiculos,
          veiculo: null,
        }
          return;
        };
        this.veiculos = value.veiculos;
        this.selectedVehicle = value?.veiculo ?? value.veiculos[0];
        this.store.formTransportador = {
          ...prevsValues,
          ...value,
          veiculo: this.selectedVehicle,
        }
        this.keyToRender = this.keyToRender + 1;
      }, deep: true, immediate: true
    },
    "store.currentTab"(newValue, oldValue) {
      if (newValue !== oldValue && newValue === 3 && oldValue < newValue) {
        this.store.formTransportador = {
          ...this.store.formTransportador,
          veiculo: this.selectedVehicle,
        }
        console.log(this.store.formTransportador);
      }
    },
  },
  computed: {
    ...mapGetters([
      "isAuthenticated",
      "breadcrumbs",
      "pageTitle",
      "layoutConfig"
    ]),
    ...mapState(["loading"]),
    isLoading() {
      return this.loading.loading;
    },
    transportadorName() {
      return !store.formTransportador?.nome ? null : store.formTransportador
    },
    isTheCurrentTab() {
      return store.currentTab === 2
    }
  }
}

</script>