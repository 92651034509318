<template>
  <div class="container-app">
    <b-card class="card-header">
      <b-row>
        <b-col md="2"></b-col>
        <b-col md="8" class="pdv-pesquisa">
          <slot name="formulario"></slot>
        </b-col>
        <b-col md="2">
          <button
            v-if="!isMobile"
            class="btn btn-secondary font-weight-bold"
            v-b-tooltip="
              !isFullScreen ? 'Entrar em Fullscreen' : 'Sair de Fullscreen'
            "
            @click="
              () => {
                if (!isFullScreen) requestFullScreen();
                else closeFullscreen();
              }
            "
          >
            <Fullscreen :size="20" v-if="!isFullScreen" />
            <Close :size="20" v-else />
          </button>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import Close from "vue-material-design-icons/Close";
import Fullscreen from "vue-material-design-icons/Fullscreen.vue";

export default {
  name: "CardBuscaTerminal",
  components: {
    Close,
    Fullscreen,
  },
  data() {
    return {
      windowWidth: window.innerWidth,
      isFullScreen: false,
    };
  },
  methods: {
    requestFullScreen() {
      this.isFullScreen = true;
      const elem = document.documentElement;
      if (elem.requestFullscreen) {
        elem.requestFullscreen();
      } else if (elem.msRequestFullscreen) {
        elem.msRequestFullscreen();
      } else if (elem.mozRequestFullScreen) {
        elem.mozRequestFullScreen();
      } else if (elem.webkitRequestFullscreen) {
        elem.webkitRequestFullscreen();
      }
    },
    closeFullscreen() {
      this.isFullScreen = false;
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      }
    },
  },
  computed: {
    isMobile() {
      return this.windowWidth < 992;
    },
  },
};
</script>

<style>
.card-header {
  border: 0px;
  padding: 0 1rem;
  background: var(--primary-color);
}

.card-header .card-title {
  text-align: center;
  color: #fff;
}

.card-header .card-body {
  padding: 10px;
  color: #fff;
}

.pdv-pesquisa {
  background-color: var(--color-secondary);
  align-self: center;
}

.checkout {
  background: #eef0ef;
  color: #6d747c;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 5px;
  padding-top: 12px;
  font-size: 14px;
}

.checkout b {
  display: block;
  font-size: 22px;
  line-height: 20px;
}

.checkout button {
  margin: 0;
}

.checkout-action button.movimentation {
  margin-right: 10px;
}
</style>
