import { required } from "vuelidate/lib/validators";

export default class ClienteData {
  nome = null;
  cnpj_cpf = null;
  ie_rg = null;
  endereco = null;
  email = null;
  fone = null;
  contato = null;
  constructor(
    nome = null,
    cnpj_cpf = null,
    ie_rg = null,
    endereco = null,
    email = null,
    fone = null,
    contato = null
  ) {
    this.nome = nome;
    this.cnpj_cpf = cnpj_cpf;
    this.ie_rg = ie_rg;
    this.endereco = endereco;
    this.email = email;
    this.fone = fone;
    this.contato = contato;
  }
}

export const ClienteDataRequired = {
  nome: {
    required,
  },
  cnpj_cpf: {
    required,
  },
  endereco: {
    required,
  },
  contato: {
    required,
  },
};
