import { required } from "vuelidate/lib/validators";

export default class UNData {
  nome = "";
  sigla = "";
  id = null;
  conversor = 0;
  tipo_conversor = 0;

  constructor({
    nome = "",
    sigla = "",
    id = null,
    conversor = 0,
    tipo_conversor = 0,
  }) {
    this.nome = nome;
    this.sigla = sigla;
    this.id = id;
    this.conversor = conversor;
    this.tipo_conversor = tipo_conversor;
  }
}

export const UNDataRequired = {
  nome: {
    required,
  },
  sigla: {
    required,
  },
};
