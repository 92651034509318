<template>
  <div>
    <div class="card-form container" id="print">
      <div class="mb-5 w-100 d-flex justify-content-between">
        <div>
          <img
            alt="Logo"
            :src="layoutConfig('self.logo.secundary')"
            class="max-h-40px"
          />
        </div>
        <div class="mt-4">
          <span
            >Impressão - <label>{{ title }}</label></span
          >
          <br />
        </div>
      </div>
      <div class="col-12">
        <b-card title="" tag="article" class="mb-2 border border-primary">
          <b-card-text>
            <b-table
              v-if="data.length > 0"
              striped
              small
              hover
              :items="tableItems"
            />
          </b-card-text>
        </b-card>
      </div>
      <div class="mt-5 w-100 d-flex justify-content-end">
        <img
          alt="Logo"
          :src="layoutConfig('self.logo.icone')"
          class="max-h-30px mr-5"
        />
      </div>
    </div>
    <div class="mt-5 w-100 d-flex justify-content-center d-print-none">
      <button
        @click="printDocument"
        class="btn btn-success btn-hover-primary mr-2"
      >
        Imprimir
      </button>
      <button
        @click="$router.go(-1)"
        class="btn btn-success btn-hover-primary mr-2"
      >
        Voltar
      </button>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { validationMixin } from "vuelidate";
import { SistemaMixin, ValidacaoMixin } from "@/modules/Application";
import { mask } from "vue-the-mask";
import moment from "moment";

export default {
  name: "app-visualizacao-impressao",
  mixins: [validationMixin, SistemaMixin, ValidacaoMixin],
  directives: {
    mask,
  },
  components: {},
  data() {
    return {
      allStatus: [],
      data: [],
      labels: [],
      title: null,
    };
  },
  watch: {},
  computed: {
    ...mapGetters(["pageTitle", "layoutConfig"]),
    tableItems() {
      return this.data.map((item) => {
        const row = this.labels.reduce(
          (acc, label) => ({
            ...acc,
            ...(label.field !== "actions" && {
              [label.label]:
                label.field !== "status" &&
                !item[label.field] &&
                this.title !== "Cadastro de Produtos"
                  ? "-"
                  : label.type === "date"
                  ? moment(item[label.field]).format("DD/MM/YYYY")
                  : label.field === "status"
                  ? item[label.field] === undefined
                    ? this.statusByDate(item)
                    : this.title === "Ordem de Compra"
                    ? this.statusOrdemCompraLabel(item[label.field])
                    : this.title === "Pessoas"
                    ? this.statusLabelPessoa(item[label.field])
                    : this.title === "NFe"
                    ? this.statusLabel(item.statusTransmissao)
                    : this.title === "Vendas Realizadas"
                    ? this.statusLabel(item.status)
                    : this.title === "Monitor NFe"
                    ? this.statusMonitorLabel(item.status)
                    : this.title === "Pedidos"
                    ? this.statusPedidoLabel(item.status)
                    : this.title === "Orçamentos"
                    ? this.statusPedidoLabel(item.status)
                    : this.title === "Ordem de Produção"
                    ? this.statusProducaoLabel(item.status)
                    : this.statusLabel(item[label.field])
                  : label.field === "valorTotal" ||
                    label.field === "valor" ||
                    label.field === "valor_total" ||
                    label.field === "preco" ||
                    label.field === "custo"
                  ? this.numberToReal(item[label.field], 2)
                  : label.field === "chave"
                  ? `${item[label.field].slice(1, 10)}...`
                  : item[label.field] === undefined
                  ? item[label.field.split(".")[1]]
                  : label.field === "fones"
                  ? this.formatFones(item[label.field])
                  : label.field === "enderecos"
                  ? this.formatEndereco(item[label.field])
                  : label.field === "fornecedor" &&
                    this.title === "Compras Realizadas"
                  ? this.formatNomeFornecedor(item.fornecedor)
                  : label.field === "dataEmissao" ||
                    label.field === "dataEntrada" ||
                    label.field === "dataValidade"
                  ? moment(item[label.field]).format("DD/MM/YYYY")
                  : label.field === "vendedor"
                  ? this.formatNomeVendedor(item[label.field])
                  : label.field === "produto"
                  ? this.formaPordutoProducao(item[label.field])
                  : item[label.field],
            }),
          }),
          {}
        );

        return row;
      });
    },
  },
  methods: {
    ...mapActions(["setLoading"]),
    async getInit() {
      this.data = this.$route.params.data;
      this.labels = this.$route.params.labels;
      this.title = this.$route.params.title;
    },
    printDocument() {
      window.print();
    },
  },
  async mounted() {
    await this.getInit();
  },
  created() {},
};
</script>

<style scoped>
svg {
  outline-width: 0;
  outline-color: transparent;
  color: rgb(241, 249, 255);
}

.container {
  background-color: white;
  margin-top: 20px;
  border-radius: 10px;
  padding: 3rem;
  box-shadow: 0px 0px 30px 0px rgba(82, 63, 105, 0.05);
  min-width: 100%;
}

.select-city .vs__search::placeholder,
.select-city .vs__dropdown-toggle,
.select-city .vs__dropdown-menu {
  height: 10rem !important;
  background-color: green !important;
}

.contato-input {
  height: 3rem;
}
</style>