import { required } from "vuelidate/lib/validators";

export default class GrupoData {
  nome = "";

  id = null;

  constructor({ nome = "", id = null }) {
    this.nome = nome;
    this.id = id;
  }
}

export const GrupoDataRequired = {
  nome: {
    required
  }
};
