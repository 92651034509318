<template>
  <div>
    <div class="card-form container" id="print">
      <div class="mb-5 w-100 d-flex justify-content-between">
        <div>
          <img
            alt="Logo"
            :src="layoutConfig('self.logo.secundary')"
            class="max-h-40px"
          />
        </div>
        <div class="mt-4">
          <span
            >Impressão - <label>{{ title }}</label></span
          >
          <br />
        </div>
      </div>
      <div class="col-12">
        <b-card title="" tag="article" class="mb-2 border border-primary">
          <b-card-text>
            <b-table
              v-if="data.length > 0"
              striped
              small
              hover
              responsive="sm"
              :fields="labels"
              :items="tableItems"
            >
              <template v-slot:cell(descricao)="data">
                <div
                  v-if="data.item.isSaldoAnterior"
                  class="font-weight-bold text-right"
                >
                  Saldo em {{ formatDate(data.item.data_saldo_dia_anterior) }}
                </div>
                <div v-else>
                  {{ data.item.descricao }}
                </div>
              </template>

              <template v-slot:cell(valor_movimento)="data">
                <div v-if="data.item.isSaldoAnterior" class="font-weight-bold">
                  {{ numberToReal(data.item.valor_movimento) }}
                </div>
                <div v-else>
                  <span
                    :style="{
                      color: data.item.tipo_movimento === 1 ? 'green' : 'red',
                    }"
                    >{{ numberToReal(data.item.valor_movimento) }}
                  </span>
                </div>
              </template>

              <template v-slot:cell(data_movimento)="data">
                <div v-if="data.item.isSaldoAnterior"></div>
                <div v-else>
                  {{ formatDate(data.item.data_movimento) }}
                </div>
              </template>
            </b-table>
          </b-card-text>
        </b-card>
      </div>
      <div class="mt-5 w-100 d-flex justify-content-end">
        <img
          alt="Logo"
          :src="layoutConfig('self.logo.icone')"
          class="max-h-30px mr-5"
        />
      </div>
    </div>
    <div class="mt-5 w-100 d-flex justify-content-center d-print-none">
      <button
        @click="printDocument"
        class="btn btn-success btn-hover-primary mr-2"
      >
        Imprimir
      </button>
      <button
        @click="$router.go(-1)"
        class="btn btn-success btn-hover-primary mr-2"
      >
        Voltar
      </button>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { validationMixin } from "vuelidate";
import { SistemaMixin, ValidacaoMixin } from "@/modules/Application";
import { mask } from "vue-the-mask";

export default {
  name: "app-visualizacao-banco",
  mixins: [validationMixin, SistemaMixin, ValidacaoMixin],
  directives: {
    mask,
  },
  components: {},
  data() {
    return {
      allStatus: [],
      data: [],
      labels: [],
      title: null,
    };
  },
  watch: {},
  computed: {
    ...mapGetters(["pageTitle", "layoutConfig"]),
    tableItems() {
      const movimentacoes = this.data;
      const tableData = [];
      let lastDate = null;
      let saldoTotal = 0;
      movimentacoes.forEach((mov, index) => {
        if (lastDate !== mov.data_movimento) {
          tableData.push({
            descricao: "",
            valor_movimento: mov.saldo_dia_anterior, // Saldo anterior
            data_movimento: "", // Deixe a data vazia
            tipo_movimento: null,
            data_saldo_dia_anterior: mov.data_saldo_dia_anterior, // Data do saldo anterior
            isSaldoAnterior: true, // Indica que esta linha é para o saldo anterior
          });
        }

        tableData.push({
          ...mov,
          saldo_dia_anterior: null,
          data_saldo_dia_anterior: null,
        });

        saldoTotal +=
          mov.tipo_movimento === 1
            ? parseFloat(mov.valor_movimento)
            : -parseFloat(mov.valor_movimento);

        lastDate = mov.data_movimento; 
      });

      if (movimentacoes.length > 0) {
        const lastMov = movimentacoes[movimentacoes.length - 1]; // Última movimentação
        tableData.push({
          descricao: "Saldo Final",
          valor_movimento: saldoTotal,
          data_movimento: "", // Deixe a data vazia para o saldo final
          tipo_movimento: null,
          data_saldo_dia_anterior: lastMov.data_movimento, // Data da última movimentação
          isSaldoAnterior: true, // Indica que esta linha é para o saldo final
        });
      }

      return tableData;
    },
  },
  methods: {
    ...mapActions(["setLoading"]),
    async getInit() {
      this.data = this.$route.params.data;
      this.labels = this.$route.params.labels;
      this.title = this.$route.params.title;
    },
    printDocument() {
      window.print();
    },
  },
  async mounted() {
    await this.getInit();
  },
  created() {},
};
</script>

<style scoped>
svg {
  outline-width: 0;
  outline-color: transparent;
  color: rgb(241, 249, 255);
}

.container {
  background-color: white;
  margin-top: 20px;
  border-radius: 10px;
  padding: 3rem;
  box-shadow: 0px 0px 30px 0px rgba(82, 63, 105, 0.05);
  min-width: 100%;
}

.select-city .vs__search::placeholder,
.select-city .vs__dropdown-toggle,
.select-city .vs__dropdown-menu {
  height: 10rem !important;
  background-color: green !important;
}

.contato-input {
  height: 3rem;
}
</style>