<template>
  <b-tab
    title="Carga"
    :active="isTheCurrentTab"
    :disabled="!isTheCurrentTab"
    class="mt-5"
  >
    <b-row>
      <b-col>
        <b-form-group
          id="tipo_cargo"
          label-for="tipo_cargo"
          label="Tipo da Carga"
        >
          <VueSelect
            ref="tipo_cargo"
            :filterable="false"
            :searchable="false"
            :options="tipoCargas"
            v-model="form.tipo_cargo"
            :class="{ hasErrors: tipoCargoError }"
            @input="() => (tipoCargoError = false)"
          >
            <template slot="no-options"> Informe o tipo de carga </template>
            <template slot="option" slot-scope="option">
              <div class="d-center">
                {{ option.descricao }}
              </div>
            </template>
            <template slot="selected-option" slot-scope="option">
              <div class="selected d-center">
                {{ option.descricao }}
              </div>
            </template>
          </VueSelect>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="8">
        <b-form-group
          id="produto_predominante"
          label-for="produto_predominante"
          label="Produto Dominante"
        >
          <b-form-input
            maxlength="50"
            v-model="form.produto_predominante"
            autofocus
            :state="validateState('produto_predominante')"
          />
        </b-form-group>
      </b-col>
      <b-col md="4">
        <b-form-group
          id="valor_cargo"
          label-for="valor_cargo"
          label="Valor Carga R$"
        >
          <money
            class="form-control"
            v-model="form.valor_cargo"
            v-bind="configMoney"
            :state="validateState('valor_cargo')"
          />
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="4">
        <b-form-group id="peso_cargo" label-for="peso_cargo" label="Peso">
          <money
            class="form-control"
            v-model="form.peso_cargo"
            v-bind="configMoney"
            :state="validateState('peso_cargo')"
          />
        </b-form-group>
      </b-col>
      <b-col md="4">
        <b-form-group
          id="peso_unidade_cargo"
          label-for="peso_unidade_cargo"
          label="Unidade de medida"
        >
          <VueSelect
            ref="selectTipoEmissor"
            :filterable="false"
            :options="unidadesPeso"
            v-model="form.peso_unidade_cargo"
            :class="{ hasErrors: unidadeError }"
            @input="() => (unidadeError = false)"
          >
            <template slot="option" slot-scope="option">
              <div class="d-center">
                {{ option.descricao }}
              </div>
            </template>
            <template slot="selected-option" slot-scope="option">
              <div class="selected d-center">
                {{ option.descricao }}
              </div>
            </template>
          </VueSelect>
        </b-form-group>
      </b-col>
    </b-row>
  </b-tab>
</template>

<script>
import { mapGetters, mapState, mapActions } from "vuex";
import { validationMixin } from "vuelidate";
import { SistemaMixin } from "@/modules/Application";
import { store } from "../../storeLogistica";
import VueSelect from "vue-select";
import _ from "lodash";
import { Money } from "v-money";
import { MONEY as settingMoney } from "@/modules/Application";

export default {
  name: "app-mdfe-form-cargaTab",
  components: {
    VueSelect,
    Money,
  },
  directives: {},
  data() {
    return {
      store,
      configMoney: settingMoney,
      form: {
        tipo_cargo: null,
        produto_predominante: null,
        valor_cargo: null,
        peso_cargo: null,
        peso_unidade_cargo: null,
      },
      tipoCargoError: false,
      unidadeError: false,
      tipoCargas: [],
      unidadesPeso: [
        {
          id: "01",
          descricao: "UN",
        },
        {
          id: "02",
          descricao: "KG",
        },
      ],
    };
  },
  mixins: [validationMixin, SistemaMixin],
  methods: {
    ...mapActions(["setLoading"]),
    async getDataInit() {
      this.setLoading(true);
      const [{ data: tipoCargoData }] = await Promise.all([
        this.$http.post("/mdfe/get-cargas-tipo"),
      ]);
      this.tipoCargas = tipoCargoData;
      const mdfeData = this.$route.params?.mdfeData?.mdfe;
      try {
        if (mdfeData) {
          this.form.peso_cargo = mdfeData.peso_cargo;
          this.form.produto_predominante = mdfeData.produto_predominante;
          this.form.valor_cargo = mdfeData.valor_cargo;
          this.form.tipo_cargo = tipoCargoData.find(
            (carga) => carga.id === mdfeData.tipo_cargo
          );
          this.form.peso_unidade_cargo = mdfeData.peso_unidade_cargo;
        }
      } catch (error) {
        console.log(error);
      }
      this.setLoading(false);
    },
    validateState(field) {
      return !!this.form[field];
    },
  },
  created() {
    this.getDataInit();
  },
  computed: {
    ...mapGetters([
      "isAuthenticated",
      "breadcrumbs",
      "pageTitle",
      "layoutConfig",
    ]),
    ...mapState(["loading"]),
    isLoading() {
      return this.loading.loading;
    },
    isTheCurrentTab() {
      return store.currentTab === 2;
    },
  },
  watch: {
    "store.currentTab"(newValue, oldValue) {
      if (newValue !== oldValue && newValue === 3 && oldValue < newValue) {
        if (!this.form.tipo_cargo) {
          this.$toast.error("Tipo de carga obrigatoria!");
          this.tipoCargoError = true;
          this.store.currentTab = oldValue;
        }
        if (!this.form.produto_predominante) {
          this.$toast.error("Produto predominante obrigatorio!");
          this.tipoCargoError = true;
          this.store.currentTab = oldValue;
        }
        if (!this.form.valor_cargo) {
          this.$toast.error("Valor carga obrigatoria!");
          this.store.currentTab = oldValue;
        }
        if (!this.form.peso_unidade_cargo) {
          this.$toast.error("Unidade obrigatoria!");
          this.store.currentTab = oldValue;
        }
        if (!this.form.peso_cargo) {
          this.$toast.error("Peso obrigatorio!");
          this.store.currentTab = oldValue;
        }
        this.store.formData = {
          ...this.store.formData,
          ...this.form,
          peso_unidade_cargo: this.form.peso_unidade_cargo.id,
          tipo_cargo: this.form.tipo_cargo.id,
        };
      }
    },
  },
};
</script>

<style></style>