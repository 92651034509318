<template>
  <div class="card card-custom card-stretch gutter-b">
    <div class="card-header border-0 py-5">
      <h3 class="card-title align-items-start flex-column">
        <span class="card-label font-weight-bolder text-dark">
          Editar Usuário
        </span>
      </h3>
    </div>
    <Form url="/usuario/update" :usuario="usuario" />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Form from "./Form.vue";
import UsuariosData from "./UsuariosData";

export default {
  name: "app-usuario-update",
  data() {
    return {
      usuario: new UsuariosData({}),
    };
  },
  components: {
    Form,
  },
  methods: {
    ...mapActions(["setLoading"]),
    async getusuario(id) {
      const { data } = await this.$http.post("/usuario/find", {
        id_usuario: id,
      });
      return data[0];
    },
    async getInit() {
      try {
        const id = this.$route.params.id;
        this.setLoading(true);

        const usuario = await this.getusuario(id);

        this.setLoading(false);

        if (!usuario.id_usuario || usuario == null) {
          this.$router.push({ name: "usuariosLista" });

          return this.$toast.error("Usuário não encontrado!");
        }

        this.usuario = new UsuariosData(usuario);
      } catch (e) {
        return;
      }
    },
  },
  computed: {
    ...mapGetters(["itsblocked"]),
  },
  mounted() {
    if (this.itsblocked) {
      this.$router.push({ name: "assinatura" });
    }
    this.getInit();
  },
};
</script>
