<template>
  <div>
    <div class="card card-custom gutter-b" style="box-shadow: none">
      <div class="card-header card-header-tabs-line">
        <ul
          class="nav nav-dark nav-bold nav-tabs nav-tabs-line"
          role="tablist"
          ref="builder-tab"
        >
          <li class="nav-item">
            <a
              class="nav-link active"
              data-tab="0"
              data-toggle="tab"
              href="#"
              role="tab"
            >
              Cadastro
            </a>
          </li>
        </ul>
      </div>

      <b-form class="form" @submit.stop.prevent="onSubmit">
        <div class="card-body">
          <b-tabs class="hide-tabs" v-model="tabIndex">
            <b-tab active>
              <b-row>
                <b-col md="6">
                  <b-row>
                    <b-col>
                      <b-form-group
                        id="descricao"
                        label-for="descricao"
                        label="Descrição"
                      >
                        <b-form-input
                          maxlength="20"
                          v-model="$v.form.descricao.$model"
                          :state="validateState('descricao')"
                          autofocus
                        >
                        </b-form-input>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-col>
                <b-col md="3">
                  <b-row>
                    <b-col>
                      <b-form-group
                        id="serial_pos"
                        label-for="serial_pos"
                        label="Serial Pos"
                      >
                        <b-form-input
                          maxlength="20"
                          v-model="$v.form.serial_pos.$model"
                          :state="validateState('serial_pos')"
                          autofocus
                        >
                        </b-form-input>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-col>
                <b-col md="3">
                  <b-row>
                    <b-col>
                      <b-form-group
                        id="username"
                        label-for="username"
                        label="Usuário"
                      >
                        <b-form-input
                          maxlength="100"
                          v-model="$v.form.username.$model"
                          :state="validateState('username')"
                          autofocus
                        >
                        </b-form-input>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-col>
                <b-col md="3">
                  <b-row>
                    <b-col>
                      <b-form-group
                        id="password"
                        label-for="password"
                        label="Senha Integração"
                      >
                        <b-form-input
                          maxlength="100"
                          v-model="$v.form.password.$model"
                          :state="validateState('password')"
                          autofocus
                        >
                        </b-form-input>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-col>
                <b-col md="3">
                  <b-row>
                    <b-col>
                      <b-form-group label="Modelos" label-for="modelos">
                        <select
                          name="modelos"
                          id="modelos"
                          v-model="form.modelo"
                          class="form-control"
                        >
                          <option
                            v-for="(m, index) in modelos"
                            :value="m.id"
                            :key="'modelos' + index"
                          >
                            {{ m.descricao }}
                          </option>
                        </select>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <b-form-group id="ativo" label-for="ativo" label="Ativo">
                    <span class="switch switch-icon">
                      <label>
                        <input
                          type="checkbox"
                          :value="true"
                          @change="setAtivo"
                          :checked="form.status == true"
                        />
                        <span></span>
                      </label>
                    </span>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-tab>
          </b-tabs>
        </div>

        <div class="card-footer">
          <div class="row">
            <div class="col-lg-12" style="text-align: center">
              <button
                type="submit"
                ref="submit_novo_terminal"
                class="btn btn-primary font-weight-bold"
              >
                Salvar
              </button>
              <router-link
                class="btn btn-clean font-weight-bold"
                :to="{
                  name: 'posLista',
                }"
              >
                Cancelar
              </router-link>
            </div>
          </div>
        </div>
      </b-form>
    </div>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import PosData, { PosDataRequired } from "./PosData";
import { mapGetters } from "vuex";
import { SistemaMixin } from "@/modules/Application";

export default {
  name: "app-pos-form",
  props: {
    url: {
      type: String,
    },
    pos: {
      type: Object,
      default: () => {},
    },
  },
  mixins: [validationMixin, SistemaMixin],
  data() {
    return {
      tabIndex: 0,
      form: {},
      modelos: [],
    };
  },
  validations: {
    form: PosDataRequired,
  },
  watch: {
    pos(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.form = newValue;
      }
    },
  },
  computed: {
    ...mapGetters(["itsblocked"]),
  },
  mounted() {
    if (this.itsblocked) {
      this.$router.push({ name: "assinatura" });
    }
    this.getDataInit();
  },
  methods: {
    setAtivo() {
      this.form.status = this.form.status == true ? false : true;
    },
    async getDataInit() {
      this.form = await new PosData({});
      this.getModelosPos().then((data) => (this.modelos = data));
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    async onSubmit() {
      const submitButton = this.$refs["submit_novo_terminal"];

      try {
        this.$v.form.$touch();

        if (this.$v.form.$anyError) {
          this.$toast.error(
            "Verifique todos os campos obrigatórios do formulário!"
          );
          return;
        }

        submitButton.classList.add("spinner", "spinner-light", "spinner-right");
        submitButton.setAttribute("disabled", "");

        await this.$http.post(this.url, this.form);

        this.$router.push({ name: "posLista" });
      } catch (error) {
        submitButton.classList.remove(
          "spinner",
          "spinner-light",
          "spinner-right"
        );
        submitButton.removeAttribute("disabled", "");
      }
    },
  },
};
</script>

<style>
.vs__dropdown-toggle {
  border: 2px solid #dedede;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border-radius: 6px;
  outline: none;
  height: 38px;
}
</style>
