import { reactive } from 'vue';

export const store = reactive({
  formFornecedor: {},
  formProduto: {},
  formFaturamento: {},
  formInformacoes: {},
  isUpdate: false,
  currentTab: 0,
});
