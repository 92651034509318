<template>
  <Form url="/tributacao/update" v-if="!loader" :tributacao="tributacao" />
</template>

<script>
import Form from "./Form.vue";

export default {
  name: "app-tributacao-update",
  data() {
    return {
      tributacao: {},
      loader: true,
    };
  },
  components: {
    Form,
  },
  methods: {
    async getTributacao(id) {
      try {
        const { data } = await this.$http.post("/tributacao/find", {
          id: id,
        });
        return data;
      } catch (error) {
        return null;
      }
    },
    async getInit() {
      try {
        const id = this.$route.params.id;

        const tributacao = await this.getTributacao(id);

        if (!tributacao.id || tributacao == null) {
          return this.$toast.error("Tributação não encontrada!");
        }

        this.tributacao = tributacao;
        this.loader = false;
      } catch {
        return;
      }
    },
  },
  mounted() {
    this.getInit();
  },
};
</script>
