import { required } from "vuelidate/lib/validators";

export default class RuralData {
  numeroNF = null;
  serie = null;
  modelo = null;
  mes = null;
  ano = null;
  chave = null;
  constructor(
    numeroNF = null,
    serie = null,
    modelo = null,
    mes = null,
    ano = null,
    chave = null,
  ) {
    this.numeroNF = numeroNF;
    this.serie = serie;
    this.modelo = modelo;
    this.mes = mes;
    this.ano = ano;
    this.chave = chave;
  }
}

export const RuralDataRequired = {
  numeroNF: {
    required,
  },
  serie: {
    required,
  },
  modelo: {
    required,
  },
  ano: {
    required,
  },
  mes: {
    required,
  },
};
