import posLista from "@/modules/Pos/pages/Index";
import posNovo from "@/modules/Pos/pages/Create";
import posEditar from "@/modules/Pos/pages/Update";

export default [
  {
    name: "posLista",
    path: "/pos",
    component: posLista,
    meta: { title: "Pos" },
  },
  {
    name: "posNovo",
    path: "/pos/novo",
    component: posNovo,
    meta: { title: "Pos" },
  },
  {
    name: "posEditar",
    path: "/pos/:id/editar",
    component: posEditar,
    meta: { title: "Pos" },
  },
];
