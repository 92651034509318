export default class PdvVendaProduto {
  product = {};

  price = 0.1;

  totalPrice = 0.1;

  quantity = 1;

  currentIndex = null;

  active = false;

  constructor({
    product = {},
    price = 0.1,
    totalPrice = 0.1,
    quantity = 1,
    currentIndex = null,
    active = true
  }) {
    this.product = product;
    this.price = price;
    this.totalPrice = totalPrice;
    this.quantity = quantity;
    this.currentIndex = currentIndex;
    this.active = active;
  }
}
