<template>
  <b-form-group :label-for="inputId" :label="label" class="mr-5">
    <b-form-input v-model="dataInputComponentValue" :id="inputId" autocomplete="off" @input="emitChange"/>
  </b-form-group>
</template>

<script>
import flatPickr from "flatpickr";
import { Portuguese } from "flatpickr/dist/l10n/pt.js";

export default {
  name: "DatePickerInput",
  data() {
    return {
      dataInputComponentValue: null,
      components: {
        flatPickr,
      },
    }
  },
  props: {
    label: {
      type: String,
      required: true
    },
    mode: {
      type: String,
      required: true,
    },
    value: {
      type: String,
      required: false,
    },
    field: {
      type: String,
      required: false,
    }
  },
  methods: {
    emitChange() {
      this.$emit('valueUpdated', this.dataInputComponentValue)
    }
  },
  mounted() {
    const mode = this.mode
    let inputs = [`input[id="${this.inputId}"]`];
    inputs.forEach((input) => {
      flatPickr(input, {
        dateFormat: "Y-m-d",
        allowInput: true,
        locale: Portuguese,
        mode,
      });
    });
  },
  watch: {
    value(newValue) {
      this.dataInputComponentValue = newValue[`range_data_${this.field}`] ?? newValue[`range_${this.field}`];
    }
  },
  computed: {
    inputId() {
      return this.label.replaceAll(' ', '').toLowerCase()
    }
  }
};
</script>
