import { required } from "vuelidate/lib/validators";

export default class PessoaData {
  nome = "";

  responsavel = "";

  status = "A";

  observacao = "";

  cnpj_cpf = "";

  ie_rg = "";

  inscricao_municipal = "";

  nome_fantasia = "";

  id_empresa = 1;

  id_pessoa_tipo = null;

  email = [];

  emails = [];

  endereco = [];

  enderecos = [];

  telefone = [];

  fones = [];

  id_pessoa = null;

  pessoa_tipo = null;

  cpf = null;

  crc = null;

  constructor({
    nome = "",
    responsavel = "",
    status = "A",
    observacao = "",
    cnpj_cpf = "",
    ie_rg = "",
    inscricao_municipal = "",
    nome_fantasia = "",
    id_empresa = 1,
    id_pessoa_tipo = null,
    email = [],
    endereco = [],
    telefone = [],
    emails = [],
    enderecos = [],
    fones = [],
    id_pessoa = null,
    pessoa_tipo = null,
    cpf = null,
    crc = null,
  }) {
    this.nome = nome;
    this.responsavel = responsavel;
    this.status = status;
    this.observacao = observacao;
    this.cnpj_cpf = cnpj_cpf;
    this.ie_rg = ie_rg;
    this.inscricao_municipal = inscricao_municipal;
    this.nome_fantasia = nome_fantasia;
    this.id_empresa = id_empresa;
    this.id_pessoa_tipo = id_pessoa_tipo;
    this.email = email;
    this.endereco = endereco;
    this.telefone = telefone;
    this.emails = emails;
    this.enderecos = enderecos;
    this.fones = fones;
    this.id_pessoa = id_pessoa;
    this.pessoa_tipo = pessoa_tipo;
    this.cpf = cpf;
    this.crc = crc;
  }
}

export const PessoaDataRequired = {
  nome: {
    required,
  },
  cpf: {
    required,
  },
  crc: {
    required,
  },
};
