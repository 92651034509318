<template>
  <Form url="/ordemcompra/create" />
</template>

<script>
import Form from "./Form.vue";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

export default {
  name: "app-ordem-compra-create",
  data() {
    return {};
  },
  components: {
    Form,
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {
        title: "novaOrdemCompra",
      },
    ]);
  },
};
</script>
