import parcelamentos from "@/modules/Parcelamentos/pages/Index";
import parcelamentosNovo from "@/modules/Parcelamentos/pages/Create";
import parcelamentosUpdate from "@/modules/Parcelamentos/pages/Update";

export default [
  {
    name: "parcelamentos",
    path: "/parcelamentos",
    component: parcelamentos,
    meta: { title: "Parcelamentos" },
  },
  {
    name: "parcelamentosNovo",
    path: "/parcelamentos/novo",
    component: parcelamentosNovo,
    meta: { title: "Parcelamentos" },
  },
  {
    name: "parcelamentosUpdate",
    path: "/parcelamentos/:id_parcelamento/editar",
    component: parcelamentosUpdate,
    meta: { title: "Parcelamentos" },
  },
];
