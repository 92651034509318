import integracoes from "@/modules/Integracoes/pages/Index";

export default [
  {
    name: "configuracoesIntegracoes",
    path: "/configuracoesIntegracoes",
    component: integracoes,
    meta: { title: "Plataformas" },
  },
];
