var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"kt_header",staticClass:"header flex-column header-fixed",attrs:{"id":"kt_header"}},[_c('div',{class:`${!_vm.isMobile ? 'd-none' : ''}`},[_c('div',{staticClass:"header-top"},[_c('div',{staticClass:"w-100 d-lg-flex mr-0 ml-0 justify-content-between p-0"},[_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"d-none d-lg-flex align-items-center mr-10"},[_c('router-link',{attrs:{"to":"/"}},[_c('img',{staticClass:"max-h-30px",attrs:{"alt":"Logo","src":_vm.layoutConfig('self.logo.default')}})])],1),_c('div',{staticClass:"d-none d-lg-flex align-items-center mr-3"},[_c('ul',{staticClass:"header-tabs nav align-self-end font-size-lg",attrs:{"role":"tablist"}},[_c('li',{staticClass:"nav-item",on:{"click":function($event){return _vm.handleShowSubmenu(0)},"mouseover":(event) => {
                    _vm.tabIndex = 0;
                    _vm.handleShowSubmenu(0);
                    _vm.setActiveTab(event);
                    _vm.storeTabID(_vm.item.name);
                  }}},[_c('a',{staticClass:"nav-link py-4 px-6 active",attrs:{"data-tab":"0","data-toggle":"tab","href":"#","id":"home","role":"tab","aria-selected":"true"},on:{"click":(event) => {
                      _vm.tabIndex = 0;
                      _vm.storeTabID('home');
                      _vm.setActiveTab(event);
                    }}},[_vm._v("Home")])]),_vm._l((_vm.headerMenus),function(item,index){return _c('li',{key:item.name,staticClass:"nav-item",on:{"click":function($event){return _vm.handleShowSubmenu(index + 1)},"mouseover":(event) => {
                    _vm.tabIndex = index + 1;
                    _vm.handleShowSubmenu(index + 1);
                    _vm.setActiveTab(event);
                    _vm.storeTabID(item.name);
                  }}},[(item.checked)?_c('a',{staticClass:"nav-link py-4 px-6",attrs:{"data-tab":`${index + 1}`,"data-toggle":"tab","role":"button","aria-selected":"true","id":item.name},on:{"click":(event) => {
                      _vm.tabIndex = index + 1;
                      _vm.setActiveTab(event);
                      _vm.storeTabID(item.name);
                    }}},[_vm._v(_vm._s(item.name))]):_vm._e()])})],2)])]),_c('KTTopbar')],1)]),_c('div',{class:`header-bottom`},[_c('div',{staticClass:"container"},[_c('div',{ref:"kt_header_navs",staticClass:"header-navs header-navs-left",attrs:{"id":"kt_header_navs"}},[_c('ul',{staticClass:"p-5 p-lg-0 d-flex d-lg-none nav nav-bold nav-tabs",attrs:{"role":"tablist"}},[_c('li',{staticClass:"nav-item"},[_c('a',{staticClass:"nav-link btn btn-clean active",attrs:{"data-tab":"0","data-toggle":"tab","href":"#","id":"home","role":"tab","aria-selected":"true"},on:{"click":(event) => {
                    _vm.tabIndex = 0;
                    _vm.storeTabID('home');
                    _vm.setActiveTab(event);
                  }}},[_vm._v("Home")])]),_vm._l((_vm.headerMenus),function(item,index){return _c('li',{key:index,staticClass:"nav-item mr-2"},[(item.checked)?_c('a',{staticClass:"nav-link btn btn-clean",attrs:{"data-tab":`${index + 1}`,"data-toggle":"tab","role":"button","aria-selected":"true","id":item.name},on:{"click":(event) => {
                    _vm.tabIndex = index + 1;
                    _vm.setActiveTab(event);
                    _vm.storeTabID(item.name);
                  }}},[_vm._v(_vm._s(item.name))]):_vm._e()])})],2),_c('b-tabs',{staticClass:"hide-tabs"},[_c('b-tab',{attrs:{"active":_vm.tabIndex === 0}},[_c('div',{staticClass:"tab-pane show active"},[_c('div',{ref:"kt_header_menu",staticClass:"header-menu header-menu-mobile header-menu-layout-default",attrs:{"id":"kt_header_menu"}},[_c('ul',{staticClass:"menu-nav"},[_c('router-link',{attrs:{"to":"/dashboard"},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
                          isActive && 'menu-item-active',
                          isExactActive && 'menu-item-active',
                        ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":() => {
                              _vm.tabIndex = 0;
                              _vm.storeTabID('home');
                              navigate();
                              _vm.subMenuShow = false;
                            }}},[_c('i',{staticClass:"menu-icon flaticon-home-2"}),_c('span',{staticClass:"menu-text"},[_vm._v(" Dashboard ")])])])]}}])})],1)])])]),_vm._l((_vm.headerMenus),function(menu,index){return _c('b-tab',{key:index,attrs:{"active":_vm.tabIndex === index + 1}},[_c('div',{staticClass:"tab-pane show active"},[_c('div',{ref:"kt_header_menu",refInFor:true,staticClass:"header-menu header-menu-mobile header-menu-layout-default",attrs:{"id":"kt_header_menu"}},_vm._l((_vm.submenus[index]),function(submenu){return _c('ul',{key:submenu.url,staticClass:"menu-nav"},[(submenu.checked)?_c('router-link',{attrs:{"to":`/${submenu.url}`},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('li',{staticClass:"menu-item",class:[
                          isActive && 'menu-item-active',
                          isExactActive && 'menu-item-active',
                        ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":() => {
                              _vm.tabIndex = index + 1;
                              _vm.storeTabID(menu.name);
                              _vm.subMenuShow = false;
                            }}},[_c('span',{staticClass:"menu-text"},[_vm._v(_vm._s(submenu.name))])])])]}}],null,true)}):_vm._e()],1)}),0)])])})],2)],1)])])]),_c('div',{class:`${
      _vm.isMobile || _vm.isFullScreen ? 'd-none' : 'header-nuts-desktop'
    } position-relative pl-25`},[_c('div',{staticClass:"bg-primary position-absolute left-0 d-flex",staticStyle:{"width":"60px","height":"60px","border-bottom-right-radius":"4px","align-items":"center","justify-content":"center","cursor":"pointer"}},[_c('router-link',{attrs:{"to":"/"}},[_c('img',{staticClass:"max-h-40px mt-1",attrs:{"alt":"Logo","src":_vm.layoutConfig('self.logo.iconeWhite')}})])],1),_c('div',{staticClass:"d-flex justify-content-center align-items-center h-100"},_vm._l((_vm.headerMenus),function(item,index){return _c('div',{key:index,staticClass:"d-flex cursor-pointer h-100"},[(item.checked)?_c('div',{class:`d-flex align-items-center text-dark mr-2 h-100 ${
            _vm.isCurrentMenuAcvtive(item) ? 'menuItemActive' : ''
          }`,staticStyle:{"box-sizing":"border-box"}},[_c('div',{staticClass:"d-flex justify-content-center align-items-center w-100 h-100",attrs:{"id":`popover-${index}`}},[_c('div',{class:`d-flex align-items-center text-dark font-weight-bold p-1 h-100`,style:(_vm.isCurrentMenuAcvtive(item)
                  ? { marginTop: '2px', transition: 'none' }
                  : { transition: 'none' })},[_vm._v(" "+_vm._s(item.name)+" ")]),_c('p',{staticClass:"text-primary mb-0",style:(_vm.isCurrentMenuAcvtive(item)
                  ? { marginTop: '2px', transition: 'none' }
                  : { transition: 'none' })},[_c('ChevronDown',{attrs:{"size":18}})],1)])]):_vm._e(),_c('b-popover',{staticClass:"p-0",staticStyle:{"padding":"0 !important"},attrs:{"target":`popover-${index}`,"triggers":"hover","placement":"bottom","boundary-padding":"2"}},[_c('div',{staticStyle:{"display":"flex","flex-direction":"column","margin-right":"-15px","margin-left":"-15px"}},_vm._l((_vm.submenus[index]),function(subMenuItem){return _c('div',{key:subMenuItem.url,staticClass:"sumenuItem"},[(subMenuItem.checked)?_c('router-link',{staticStyle:{"width":"100%","height":"100%","margin-left":"-5px","padding":"2px 5px"},attrs:{"to":`/${subMenuItem.url}`},scopedSlots:_vm._u([{key:"default",fn:function({ href, navigate, isActive, isExactActive }){return [_c('a',{attrs:{"href":href}},[_c('p',{staticClass:"mb-0 text-dark submenuItem"},[_vm._v(" "+_vm._s(subMenuItem.name)+" ")])])]}}],null,true)}):_vm._e()],1)}),0)])],1)}),0),_c('div',{staticClass:"bg-primary pl-3 pr-5 d-flex align-items-center"},[_c('KTTopbar'),_c('p',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip",value:('Sair'),expression:"'Sair'"}],staticClass:"text-white mb-0 ml-2 cursor-pointer",on:{"click":_vm.handleLogout}},[_c('Logout',{attrs:{"size":20}})],1)],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }