<template>
  <b-tab title="Faturamento" :active="isTheCurrentTab" @click="() => store.currentTab = 3"
    :disabled="!isTheCurrentTab && !store.isUpdate">
    <b-row align-h="center">
      <b-col md="8">
        <b-row>
          <b-col>
            <b-form-group label="Valor R$" label-for="valorPagamento">
              <money class="form-control" id="valorPagamento" name="valorPagamento" :value="valorPagoARealizar"
                @input="onInputValue" v-bind="configMask" :class="{
    hasErrorsValor: valorError,
    inputFilledvalor: valorFilled,
  }">
              </money>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form-group label="Desconto R$" label-for="valorDesconto">
              <money class="form-control" id="valorDesconto" name="valorDesconto" v-model="form.desconto"
                v-bind="configMask" :class="{
    hasErrorsValor: valorError,
    inputFilledvalor: valorFilled,
  }">
              </money>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-form-group label="Forma de Pagamento" label-for="forma_pagamento">
              <VueSelect ref="buscar_forma_pagamento" :filterable="false" :options="formas_pagamento"
                :disabled="valorPagoARealizar === 0" @search="onSearchFormaPagamentos" @input="setFormaPagamento"
                v-model="formaPagamento" :class="{
    hasErrors: pagamentoError,
    inputFilled: pagamentoFilled,
  }">
                <template slot="no-options">
                  Seleciona a forma de pagamento
                </template>
                <template slot="option" slot-scope="option">
                  <div class="d-center">
                    {{ option.descricao }}
                  </div>
                </template>
                <template slot="selected-option" slot-scope="option">
                  <div class="selected d-center">
                    {{ option.descricao }}
                  </div>
                </template>
              </VueSelect>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <TableApp item="pagamento" subTitulo="" :data="pagamento" :fields="fields" classFilterColumn="valor"
              ref="valor">
              <template slot="customRow" scope="props">
                <span slot="nome" class="pl-0 pt-8" v-if="props.field == 'id'">
                  <div class="d-flex align-items-center">
                    <div class="font-weight-bold text-muted">
                      {{ props.rowData.originalIndex + 1 }}
                    </div>
                  </div>
                </span>
                <span slot="nome" class="pl-0 pt-8" v-if="props.field == 'valor'">
                  <div class="d-flex align-items-center">
                    <div v-b-modal.modal-pagamento @click="changePagamento(props.rowData.originalIndex)"
                      class="font-weight-bold text-muted cursor-pointer">
                      {{ numberToReal(props.rowData.valor, 2) }}
                    </div>
                  </div>
                </span>
                <span slot="quantidade" class="pl-0 pt-8" v-if="props.field == 'vencimento'">
                  <div class="d-flex align-items-center">
                    <div v-b-modal.modal-calendar class="font-weight-bold text-muted cursor-pointer"
                      @click="selectVencimento(props.rowData.originalIndex)">
                      {{ props.rowData.vencimento | moment("DD/MM/YYYY") }}
                    </div>
                  </div>
                </span>
              </template>
            </TableApp>
          </b-col>
        </b-row>
        <b-row>
          <button ref="submit_novo_pagamento123" class="btn btn-secondary font-weight-bold" @click="addPagamento"
            :disabled="isAllInputsNotFull">
            + Adicionar Pagamento
          </button>
        </b-row>
      </b-col>
    </b-row>
    <b-row align-h="center">
      <b-col md="8">
        <b-row class="d-flex flex-row-reverse px-10">
          <div class="d-flex flex-column text-md-right">
            <span class="font-size-lg font-weight-bolder mb-1">A PAGAR</span>
            <span class="font-size-h2 font-weight-boldest mb-1" :key="keyToReRender + 1">R$ {{
    numberToReal(subtotal, 2)
  }}</span>
            <span class="text-success" :key="keyToReRender">
              Valor Pago: R$ {{ numberToReal(valorPago, 2) }}
            </span>
          </div>
        </b-row>
      </b-col>
    </b-row>
    <b-row align-h="center">
      <b-col md="8">
        <TableApp class="mt-10" item="pagamento" titulo="Pagamentos" :data="form.pagamentos
    " :fields="fieldsPagamentosRealizados" classFilterColumn="formaPagamento" ref="formaPagamento">
          <template slot="customRow" scope="props">
            <span slot="nome" class="pl-0 pt-8" v-if="props.field == 'forma_pagamento'">
              <div class="d-flex align-items-center">
                <div class="font-weight-bold text-muted">
                  {{ props.rowData.forma_pagamento.descricao }}
                </div>
              </div>
            </span>
            <span slot="quantidade" class="pl-0 pt-8" v-if="props.field == 'valor'">
              <div class="d-flex align-items-center">
                <div class="font-weight-bold text-muted">
                  R$ {{ numberToReal(props.rowData.valor, 2) }}
                </div>
              </div>
            </span>
            <span slot="actions" v-if="props.field == 'actions'">
              <div class="row-actions" style="text-align: right !important">
                <span style="overflow: visible; position: relative; width: 130px">
                  <div class="action-buttons">
                    <button @click="
    removePagamento(
      props.rowData,
      props.rowData.originalIndex
    )
    " class="
                        btn btn-sm btn-danger btn-hover-danger btn-icon
                        mr-2
                      ">
                      <TrashCanIcon :size="16" />
                    </button>
                  </div>
                </span>
              </div>
            </span>
          </template>
        </TableApp>
      </b-col>
    </b-row>
    <b-modal id="modal-calendar" title="Ajustar vencimento" size="sm" centered>
      <div class="mx-auto">
        <b-calendar v-model="vencimentoSelected" locale="pt-BR"></b-calendar>
      </div>
      <template #modal-footer="{ ok, cancel }">
        <b-button @click="cancel()"> Cancelar </b-button>
        <b-button variant="primary" @click="salvarVencimento" ref="transmitir_nfe">
          Salvar
        </b-button>
      </template>
    </b-modal>

    <b-modal id="modal-pagamento" title="Ajustar pagamento" size="sm" centered>
      <div class="mx-auto">
        <b-form class="form" @submit.stop.prevent="salvarPagamento">
          <money @input="changePagamentoEdited" class="form-control" id="valorPagamento" name="valorPagamento"
            :value="valorPagamento" v-bind="configMask">
          </money>
        </b-form>
      </div>
      <template #modal-footer="{ ok, cancel }">
        <b-button @click="cancel()"> Cancelar </b-button>
        <b-button variant="primary" @click="salvarPagamento" ref="transmitir_nfe">
          Salvar
        </b-button>
      </template>
    </b-modal>
  </b-tab>
</template>

<script>
import { mapGetters, mapState, mapActions } from "vuex";
import VueSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import { validationMixin } from "vuelidate";
import FaturamentoData, { FaturamentoDataRequired } from "../FaturamentoData";
import TrashCanIcon from "vue-material-design-icons/TrashCan.vue";
import TableApp from "../../../../components/Table/Table3.vue";
import { SistemaMixin, ValidacaoMixin, InstallmentsMixin } from "@/modules/Application";
import _ from "lodash";
import { mask } from "vue-the-mask";
import { Money } from "v-money";
import { MONEY as settingMoney } from "@/modules/Application";
import { store } from "../../storePedidos";
import moment from "moment";

export default {
  name: "app-orcamento-form-faturamentoTab",
  components: {
    VueSelect,
    Money,
    TableApp,
    TrashCanIcon,
  },
  directives: {
    mask,
  },
  data() {
    return {
      store,
      form: {},
      vencimento: [],
      configMask: settingMoney,
      categorias: [],
      contas: [],
      vencimentoSelected: null,
      indexVencimento: null,
      pagamento: [],
      formas_pagamento: [],
      formasPagamentoArray: [],
      plano_contas: [],
      pagamentosEditados: [],
      selectedPagamento: null,
      keyToReRender: 0,
      fieldsPagamentosRealizados: [
        {
          label: "",
          field: "forma_pagamento",
          slot: true,
          width: "45%",
          sortable: false,
        },
        {
          label: "",
          field: "valor",
          slot: true,
          width: "45%",
          sortable: false,
        },
        {
          label: "",
          field: "actions",
          slot: true,
          width: "10%",
          sortable: false,
        },
      ],
      fields: [
        {
          label: "",
          field: "id",
          slot: true,
          width: "10%",
          sortable: false,
        },
        {
          label: "Valor R$",
          field: "valor",
          slot: true,
          width: "45%",
          sortable: false,
        },
        {
          label: "Vencimento",
          field: "vencimento",
          slot: true,
          sortable: false,
          width: "45%",
        },
      ],
      subtotal: 0,
      valorPagoARealizar: 0,
      valorPago: 0,
      formaPagamento: null,
      planoConta: null,
      openChangeVencimentoModal: false,
      informacoesAdicionais: "",
      pagamentoEditado: 0,
      valorError: false,
      valorFilled: false,
      pagamentoError: false,
      pagamentoFilled: false,
      planoContasError: false,
      planoContasFilled: false,
    };
  },
  mixins: [validationMixin, SistemaMixin, ValidacaoMixin, InstallmentsMixin],
  validations: {
    form: FaturamentoDataRequired,
  },
  methods: {
    ...mapActions(["setLoading"]),
    async getDataInit() {
      this.form = new FaturamentoData({});
      this.vencimentoSelected = this.thisDate();
      this.formas_pagamento = await this.getFormasPagamentos();
      this.formasPagamentoArray = this.formas_pagamento;
      this.plano_contas = await this.getPlanoContas(2);
      this.planoConta = this.plano_contas.find((plano) => plano.descricao === "Venda de Produtos");
    },

    removePagamento(pagamentoDeleted, indexToRemove) {
      this.store.formFaturamento.pagamentos =
        this.store.formFaturamento.pagamentos.filter(
          (pagamento, index) => index !== indexToRemove
        );
      this.form.pagamentos = this.form.pagamentos.filter(
        (pagamento, index) => index !== indexToRemove
      );
      this.subtotal =
        this.subtotal + pagamentoDeleted.valor - this.form.desconto;
      this.valorPago = this.valorPago - pagamentoDeleted.valor;
    },
    changePagamento(pagamentoIndex) {
      this.selectedPagamento = pagamentoIndex;
    },
    closeModalPagamento() {
      this.openChangePagamentoModal = false;
    },
    changePagamentoEdited(valor) {
      this.pagamentoEditado = valor;
    },
    salvarPagamento() {
      this.pagamento[this.selectedPagamento].valor = this.pagamentoEditado;
      const indexEditPagamento = this.pagamentosEditados.findIndex(pagamento => pagamento?.index === this.selectedPagamento)
      if (indexEditPagamento === -1) {
        this.pagamentosEditados.push({
          index: this.selectedPagamento,
          value: this.pagamentoEditado
        })
      } else {
        this.pagamentosEditados.splice(indexEditPagamento, 1, {
          index: this.selectedPagamento,
          value: this.pagamentoEditado
        })
      };

      const pagamentosEditadosTotal = this.pagamentosEditados.reduce((acc, pagamento) => {
        if (pagamento?.value) return acc + pagamento.value
        else return acc
      }, 0)

      const editPagamentoCount = this.pagamentosEditados.length

      const pagamentoRestante = this.valorPagoARealizar - pagamentosEditadosTotal;
      const valorParcelasRestantes = Math.round(pagamentoRestante / (this.pagamento.length - editPagamentoCount) * 100) / 100;

      this.pagamento = this.pagamento.map((item, index) => {
        const hasInPagamentosEditado = this.pagamentosEditados.find(pagamento => pagamento.index === index)
        return {
          ...item,
          valor: hasInPagamentosEditado ?
            hasInPagamentosEditado.value
            :
            index !== this.selectedPagamento ?
              valorParcelasRestantes
              :
              this.pagamentoEditado
        }
      })

      this.$bvModal.hide('modal-pagamento')
    },
    validateFaturamentoState() { },
    onSearchConta(search, loading) {
      if (search.length) {
        loading(true);
        this.searchContas(loading, search, this);
      }
    },
    selectVencimento(index) {
      this.indexVencimento = index;
    },
    salvarVencimento() {
      this.pagamento[this.indexVencimento].vencimento = this.vencimentoSelected;
      this.$bvModal.hide("modal-calendar");
    },
    closeModalCalendario() {
      this.openChangeVencimentoModal = false;
    },
    clearInputs() {
      this.formaPagamento = null;
      this.informacoesAdicionais = "";
      this.planoConta = this.plano_contas.find((plano) => plano.descricao === "Venda de Produtos");
      this.valorPagoARealizar = 0;
      this.form.vencimento = this.thisDate();
      this.pagamento = [];
      this.pagamentosEditados = [];
    },
    addPagamento() {
      this.form.pagamentos = [
        ...this.form.pagamentos,
        {
          forma_pagamento: this.formaPagamento,
          valor: this.valorPagoARealizar - this.form.desconto,
          vencimento: this.form.vencimento,
          range: this.pagamento.map((parcela) => ({
            value: parcela.valor,
            date: moment(parcela.vencimento).format("YYYY-MM-DD"),
          })),
        },
      ];
      this.valorPago = this.form.pagamentos.reduce(
        (acc, pagamento) => acc + pagamento.valor,
        0
      );
      this.subtotal = this.subtotal - this.valorPago;
      this.clearInputs();
      this.keyToReRender++;
    },
    onSearchCategorias(search, loading) {
      if (search.length) {
        loading(true);
        this.searchCategorias(loading, search, this);
      }
    },
    onSearchFormaPagamentos(search, loading) {
      if (search.length) {
        loading(true);
        this.searchFormaPagamentos(search, loading, this);
      } else {
        this.formas_pagamento = this.formasPagamentoArray;
      }
    },
    onInputValue(valor) {
      this.changePagamentoValue(valor, this);
    },
    changePagamentoValue: _.debounce((valor, vm) => {
      vm.valorPagoARealizar = valor;
      if (valor === 0) return;
      vm.pagamento = vm.calculateInstallments(vm.formaPagamento, vm.valorPagoARealizar).map(el => ({
        vencimento: el.date,
        valor: el.value
      }))
      vm.vencimentoSelected = vm.pagamento[0]?.vencimento;

      vm.valorError = false;
      vm.valorFilled = true;
    }, 1000),

    setFormaPagamento(forma_pagamento) {
      const aPagar = this.valorPagoARealizar - this.form.desconto;

      this.pagamento = this.calculateInstallments(forma_pagamento, aPagar).map(el => ({
        vencimento: el.date,
        valor: el.value
      }))
      this.vencimentoSelected = this.pagamento[0]?.vencimento;

      this.formaPagamento = forma_pagamento;
      this.pagamentoError = false;
      this.pagamentoFilled = true;
      this.pagamentosEditados = [];
    },
    searchFormaPagamentos: _.debounce(async (search, loading, vm) => {
      vm.formas_pagamento = vm.formasPagamentoArray.filter((item) =>
        item.descricao.includes(search)
      );
      loading(false);
    }, 350),
    searchCategorias: _.debounce(async (search, loading, vm) => {
      vm.plano_contas = vm.plano_contas.filter((item) =>
        item.descricao.includes(search)
      );
      loading(false);
    }, 350),
    searchContas: _.debounce(async (search, loading, vm) => {
      vm.form.conta = await vm.getContas(search);
      loading(false);
    }, 350),
  },
  mounted() { },
  created() {
    if (Object.keys(this.$route.params).length !== 0) {
      this.getDataInit();
      const { pagamentos, desconto } = this.$route.params.pedidoData;
      this.form.pagamentos = pagamentos.map((pagamento) => ({
        forma_pagamento: {
          descricao: pagamento.descricao,
          id_meio_pagamento: pagamento.id_meio_pagamento,
          vezes: pagamento.vezes,
          id: pagamento.paymentMode.id,
        },
        valor: pagamento.value,
        vencimento: pagamento.range[0].date,
        range: pagamento.range,
      }));
      this.form.desconto = desconto;
      this.valorPago = this.form.pagamentos.reduce(
        (acc, pagamento) => acc + pagamento.valor,
        0
      );
    } else {
      this.getDataInit();
    }
  },
  watch: {
    "store.formProduto.subtotal"(newValue) {
      this.subtotal = newValue;
      this.valorPagoARealizar = newValue;
      this.valorPago = 0;
    },
    "form.desconto"(newValue) {
      this.subtotal = this.store.formProduto.subtotal - newValue;
      const aPagar = this.valorPagoARealizar - this.form.desconto;

      this.pagamento = this.calculateInstallments(this.formaPagamento, aPagar).map(el => ({
        vencimento: el.date,
        valor: el.value
      }))
      this.vencimentoSelected = this.pagamento[0]?.vencimento;
    },
    "store.formFaturamento.pagamentos"(newValue) {
      this.valorPago = newValue.reduce(
        (acc, pagamento) => acc + pagamento.valor,
        0
      );
      this.subtotal = this.store.formProduto.subtotal - this.valorPago - this.form.desconto;
    },
    "form.vencimento"(newVencimento, oldVencimento) {
      if (newVencimento !== oldVencimento) {
        if (!this.formaPagamento) return;
        this.pagamento = this.calculateInstallments(this.formaPagamento, this.valorPagoARealizar).map(el => ({
          vencimento: el.date,
          valor: el.value
        }))
        this.vencimentoSelected = this.pagamento[0]?.vencimento;
      }
    },
    form(newValue, oldValue) {
      if (JSON.stringify(newValue) === JSON.stringify(oldValue)) return;
      this.store.formFaturamento = newValue;
    },
    planoConta(newValue, oldValue) {
      if (JSON.stringify(newValue) === JSON.stringify(oldValue)) return;
      this.planoContasFilled = true;
      this.store.formFaturamento.plano_conta = newValue;
    },
    "store.currentTab"(newValue, oldValue) {
      if (newValue !== oldValue && newValue === 4 && oldValue < newValue) {
        this.$v.form.$touch();

        if (this.$v.form.$anyError) {
          if (this.$v.form.$anyError)
            this.$toast.error("Necessário incluir pagamento");
          this.store.currentTab = oldValue;
          return;
        }

        if (this.pagamento.length === 0 && this.$v.form.$anyError) {
          this.$toast.error("Necessário incluir valor do pagamento");
          this.valorError = true;
        }
        if (!this.formaPagamento && this.$v.form.$anyError) {
          this.$toast.error("Necessário incluir forma de pagamento");
          this.pagamentoError = true;
        }
        if (!this.planoConta && this.$v.form.$anyError) {
          this.$toast.error("Necessário incluir plano de contas");
          this.planoContasError = true;
        }
      }
    },
  },
  computed: {
    ...mapGetters([
      "isAuthenticated",
      "breadcrumbs",
      "pageTitle",
      "layoutConfig",
    ]),
    ...mapState(["loading"]),
    isLoading() {
      return this.loading.loading;
    },
    isAllInputsNotFull() {
      return (
        this.valorPagoARealizar === 0 ||
        !this.formaPagamento ||
        !this.planoConta
      );
    },
    valorPagamento() {
      if (this.pagamento.length === 0) return 0;
      else return this.pagamento[0].valor;
    },
    isTheCurrentTab() {
      return store.currentTab === 3;
    },
  },
};
</script>

<style>
.hasErrors .vs__dropdown-toggle {
  border: 2px solid red;
  /* color: #394066; */
}

.hasErrors .vs__clear,
.hasErrors .vs__open-indicator {
  fill: red;
}

.inputFilled .vs__dropdown-toggle {
  border: 2px solid #1bc5bd;
  /* color: #394066; */
}

.inputFilled .vs__clear,
.inputFilled .vs__open-indicator {
  fill: #1bc5bd;
}
</style>