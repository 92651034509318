export default {
  data() {
    return {
      natureza: [],
    };
  },
  methods: {
    async getNatureza(id) {
      const { data } = await this.$http.post("/natureza/find", {
        id: id,
      });
      this.natureza = data;

      return data;
    },
  },
};
