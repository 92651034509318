import variacoes from "@/modules/Variacoes/pages/Index";
import produtosCreate from "@/modules/Variacoes/pages/Create";
import produtosUpdate from "@/modules/Variacoes/pages/Update";

export default [
  {
    name: "variacoes",
    path: "/variacoes",
    component: variacoes,
    meta: { title: "Variações" },
  },
  {
    name: "variacoesNovo",
    path: "/variacoes/novo",
    component: produtosCreate,
    meta: { title: "Variações" },
  },
  {
    name: "variacoesUpdate",
    path: "/variacoes/:id_variavel/editar",
    component: produtosUpdate,
    meta: { title: "Variações" },
  },
];
