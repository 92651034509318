export default {
  data() {
    return {
      configuracoes: [],
    };
  },
  methods: {
    async getConfiguracao() {
      const params = {
        page: 1,
        per_page: 10,
      };
      const { data } = await this.$http.post("/configuracoes", params);
      this.configuracoes = data.data;
    },
  },
};
