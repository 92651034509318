<template>
  <Form url="/bancos/update" v-if="!loader" :banco="banco" />
</template>

<script>
import Form from "./Form.vue";

export default {
  name: "app-banco-update",
  data() {
    return {
      banco: {},
      loader: true,
    };
  },
  components: {
    Form,
  },
  methods: {
    async getBanco(id) {
      try {
        const { data } = await this.$http.post("/bancos/find", {
          id_banco: id,
        });
        return data;
      } catch (error) {
        return null;
      }
    },
    async getInit() {
      try {
        const id_banco = this.$route.params.id_banco;

        const banco = await this.getBanco(id_banco);

        if (!banco.id_banco || banco == null) {
          return this.$toast.error("Banco não encontrado!");
        }

        this.banco = await banco;
        this.loader = false;
      } catch (error) {
        console.log(error);
      }
    },
  },
  mounted() {
    this.getInit();
  },
};
</script>
