var render = function render(){var _vm=this,_c=_vm._self._c;return _c('TableApp',{ref:"bancos",attrs:{"titulo":"Contas bancárias","subTitulo":"Controle das suas contas bancárias","api-url":"bancos","acaoBtn":"/#/bancos/novo","textoBtn":"Novo Banco","fields":_vm.fields,"classFilterColumn":"bancos","perPage":10},scopedSlots:_vm._u([{key:"customRow",fn:function(props){return [(props.field == 'nome')?_c('span',{attrs:{"slot":"nome"},slot:"nome"},[_c('span',{staticClass:"pl-0 pt-8"},[_c('div',{staticClass:"d-flex align-items-center"},[_c('div',{staticClass:"symbol symbol-40 symbol-light-primary flex-shrink-0",staticStyle:{"margin-right":"10px"}}),_c('div',[_c('a',[_vm._v(_vm._s(props.rowData.nome))])])])])]):_vm._e(),(props.field == 'saldo')?_c('span',{attrs:{"slot":"saldo"},slot:"saldo"},[_c('div',{staticClass:"font-weight-bold text-muted"},[_vm._v(" R$ "+_vm._s(_vm.numberToReal(props.rowData.saldo, 2))+" ")])]):_vm._e(),(props.field == 'actions')?_c('span',{attrs:{"slot":"actions"},slot:"actions"},[_c('div',{staticClass:"row-actions",staticStyle:{"text-align":"right !important"}},[_c('span',{staticStyle:{"overflow":"visible","position":"relative","width":"130px"}},[_c('div',{staticClass:"dropdown dropdown-inline"},[_c('router-link',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.bottom",value:('Editar'),expression:"'Editar'",modifiers:{"hover":true,"bottom":true}}],staticClass:"btn btn-sm btn-info btn-hover-info btn-icon mr-2",attrs:{"to":{
                name: 'bancosEditar',
                params: {
                  id_banco: props.rowData.id_banco,
                },
              }}},[_c('Pencil',{attrs:{"size":16,"title":"Editar"}})],1),_c('router-link',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.bottom",value:('Extrato'),expression:"'Extrato'",modifiers:{"hover":true,"bottom":true}}],staticClass:"btn btn-sm btn-success btn-hover-success btn-icon mr-2",attrs:{"to":{
                name: 'bancosMovimentacao',
                params: {
                  id_banco: props.rowData.id_banco,
                },
              }}},[_c('List',{attrs:{"size":16,"title":"Editar"}})],1),_c('button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.bottom",value:('Excluir conta Bancária'),expression:"'Excluir conta Bancária'",modifiers:{"hover":true,"bottom":true}}],staticClass:"btn btn-sm btn-danger btn-hover-danger btn-icon mr-2",on:{"click":function($event){return _vm.excluirCliente(props.rowData)}}},[_c('TrashCanIcon',{attrs:{"size":16}})],1)],1)])])]):_vm._e()]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }