<template>
  <div style="position: relative">
    <FilterFields
      :fields="[
        {
          type: 'text',
          label: 'Nome',
          field: 'cliente',
        },
        {
          type: 'text',
          label: 'E-mail',
          field: 'email',
        },
        {
          type: 'text',
          label: 'CNPJ',
          field: 'cnpj',
        },
      ]"
    />
    <v-avatar
      v-if="nivelPartner.nivel != 1"
      style="position: absolute; top: 0; left: 70px; margin-top: -20px"
    >
      <img :src="nivelPartner.selo" />
    </v-avatar>
    <router-link
      v-if="nivelPartner.nivel == 5"
      style="position: absolute; top: 0; left: 140px"
      class="btn btn-sm btn-primary btn-hover-primary btn-icon mr-2"
      v-b-tooltip="'Configuração White Label'"
      :to="{
        name: 'clientesConfiguracao',
      }"
    >
      <Settings :size="16" title="Configuração" />
    </router-link>
    <TableApp
      titulo="Gestão de Clientes"
      subTitulo="Controle de forma simples todos seus clientes"
      api-url="empresa/meus-clientes"
      acaoBtn="/#/clientesNovo"
      textoBtn="Novo Cliente"
      :fields="fields"
      classFilterColumn="clientes"
      ref="clientes"
      :perPage="10"
      :licencas="licencas"
    >
      <template slot="customRow" slot-scope="props">
        <span slot="nome" v-if="props.field == 'nome'">
          <span class="pl-0 pt-8">
            <div class="d-flex align-items-center">
              <div
                class="symbol symbol-40 symbol-light-primary flex-shrink-0"
                style="margin-right: 10px"
              ></div>
              <div>
                <a
                  class="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg"
                  >{{ props.rowData.cliente }}</a
                >
                <span class="text-muted font-weight-bold d-block"
                  >Fantasia: {{ props.rowData.nome_fantasia }}<br />
                  Telefone: {{ props.rowData.fone }}<br />
                  Email: {{ props.rowData.email }}<br />
                  Data cadastro:
                  {{ formatDate(props.rowData.data_cadastro) }} <br />
                </span>
              </div>
            </div>
          </span>
        </span>
        <span slot="cnpj" v-if="props.field == 'cnpj'">
          <span style="width: 110px">
            <div
              class="font-weight-bolder font-size-lg mb-0"
              v-if="props.rowData.cnpj"
            >
              {{ formatCpfCnpj(props.rowData.cnpj) }}
            </div>
            <div v-else></div>
          </span>
        </span>
        <span slot="cliente_cidade" v-if="props.field == 'cliente_cidade'">
          <span style="width: 110px">
            <div class="font-weight-bolder font-size-lg mb-0">
              {{ props.rowData.cliente_cidade }}
              <span
                class="text-muted font-weight-bold d-block"
                v-if="props.rowData.cliente_uf"
                >UF: {{ props.rowData.cliente_uf }}
              </span>
            </div>
          </span>
        </span>
        <span slot="data_validade" v-if="props.field == 'data_validade'">
          <span style="width: 110px">
            <div class="font-weight-bold text-muted font-size-lg mb-0">
              Validade: {{ formatDate(props.rowData.data_validade) }} <br />
              Cobrança: {{ formatDate(props.rowData.data_pagamento) }} <br />
              Valor: {{ numberToReal(props.rowData.valor, 2) }}
            </div>
            <div class="font-weight-bold text-muted">
              <span
                v-if="props.rowData.bloqueado"
                class="label label-lg label-inline label-light-danger"
                >Bloqueado</span
              >
              <span
                v-else
                class="label label-lg label-inline label-light-success"
                >Ativo</span
              >
            </div>
          </span>
        </span>
        <span slot="actions" v-if="props.field == 'actions'">
          <div class="row-actions" style="text-align: right !important">
            <span style="overflow: visible; position: relative; width: 130px">
              <div class="dropdown dropdown-inline">
                <router-link
                  class="btn btn-sm btn-info btn-hover-info btn-icon mr-2"
                  v-b-tooltip="'Contrato'"
                  :to="{
                    name: 'clientesContratoEditar',
                    params: {
                      id_empresa: props.rowData.id_empresa,
                    },
                  }"
                >
                  <Contract :size="16" title="Contrato" />
                </router-link>
                <button
                  @click="liberarAcesso(props.rowData)"
                  class="btn btn-sm btn-success btn-hover-success btn-icon mr-2"
                  v-b-tooltip="'Liberar Acesso'"
                >
                  <Block :size="16" />
                </button>
                <button
                  @click="bloquearAcesso(props.rowData)"
                  class="btn btn-sm btn-warning btn-hover-warning btn-icon mr-2"
                  v-b-tooltip="'Bloquear Acesso'"
                >
                  <Block :size="16" />
                </button>
                <button
                  @click="reenviarEmail(props.rowData)"
                  class="btn btn-sm btn-info btn-hover-info btn-icon mr-2"
                  v-b-tooltip="'Reenviar e-mail primeiro acesso'"
                >
                  <SendEmail :size="16" />
                </button>
              </div>
            </span>
          </div>
        </span>
      </template>
    </TableApp>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Block from "vue-material-design-icons/ArchiveLock.vue";
import SendEmail from "vue-material-design-icons/MessageArrowRight.vue";
import TableApp from "@/components/Table/Table2";
import Contract from "vue-material-design-icons/EyeCheck.vue";
import Settings from "vue-material-design-icons/Console.vue";
import { SistemaMixin } from "@/modules/Application";
import FilterFields from "@/components/FilterFields";

export default {
  name: "app-clientes-revenda",
  mixins: [SistemaMixin],
  components: {
    Block,
    TableApp,
    SendEmail,
    Contract,
    FilterFields,
    Settings,
  },
  data() {
    return {
      fields: [
        {
          label: "Nome",
          field: "nome",
          type: "text",
          slot: true,
          sortable: true,
          width: "15%",
        },
        {
          label: "CPF/CNPJ",
          field: "cnpj",
          type: "text",
          slot: true,
          sortable: false,
          width: "8%",
        },
        {
          label: "Cidade",
          field: "cliente_cidade",
          type: "text",
          slot: true,
          sortable: false,
          width: "10%",
        },
        {
          label: "Assinatura",
          field: "data_validade",
          type: "text",
          slot: true,
          sortable: false,
          width: "8%",
          dateInputFormat: "DD/MM/YYYY",
          dateOutputFormat: "YYYY-MM-DD",
        },
        {
          label: "",
          field: "actions",
          sortable: false,
          slot: true,
          width: "15%",
          filterOptions: {
            enabled: false,
          },
        },
      ],
      licencas: null,
      nivelPartner: null,
    };
  },
  methods: {
    ...mapActions(["setLoading"]),

    excluirCliente(data) {
      this.getRequestAdmin(() => {
        this.$swal
          .fire({
            icon: "warning",
            title:
              "Deseja  deletar o cliente " +
              data.cliente +
              "? Lembrando que o mesmo não irá mais acessar o sistema.",
            showCancelButton: true,
            confirmButtonText: `Sim`,
            cancelButtonText: `Não`,
            confirmButtonColor: "#f64e60",
          })
          .then(async (result) => {
            if (result.isConfirmed) {
              await this.$http.post("/empresa/excluir-cliente", {
                id_empresa: data.id_empresa,
              });
              this.$refs.clientes.refresh();
              this.$toast.success("E-mail enviado!");
            }
          });
      });
    },
    reenviarEmail(data) {
      this.$swal
        .fire({
          icon: "warning",
          title:
            "Deseja reenviar e-mail de primeiro acesso ao cliente " +
            data.cliente +
            "?",
          showCancelButton: true,
          confirmButtonText: `Sim`,
          cancelButtonText: `Não`,
          confirmButtonColor: "#f64e60",
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            await this.$http.post("/empresa/enviar-email", {
              id_empresa: data.id_empresa,
            });
            this.$refs.clientes.refresh();
            this.$toast.success("E-mail enviado!");
          }
        });
    },
    bloquearAcesso(data) {
      this.$swal
        .fire({
          icon: "warning",
          title: "Deseja bloquear acesso ao cliente " + data.cliente + "?",
          showCancelButton: true,
          confirmButtonText: `Sim`,
          cancelButtonText: `Não`,
          confirmButtonColor: "#f64e60",
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            await this.$http.post("/empresa/bloquear-cliente", {
              id_empresa: data.id_empresa,
            });
            this.$refs.clientes.refresh();
            this.$toast.success("Acesso bloqueado!");
          }
        });
    },
    liberarAcesso(data) {
      this.$swal
        .fire({
          icon: "warning",
          title: "Deseja liberar acesso ao cliente " + data.cliente + "?",
          showCancelButton: true,
          confirmButtonText: `Sim`,
          cancelButtonText: `Não`,
          confirmButtonColor: "#f64e60",
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            await this.$http.post("/empresa/liberar-cliente", {
              id_empresa: data.id_empresa,
            });
            this.$refs.clientes.refresh();
            this.$toast.success("Acesso liberado!");
          }
        });
    },
    async getLicencas() {
      const data = await this.$http.post("/empresa/total-licencas", {
        id_empresa: this.currentUser.usuario_empresa.id_empresa,
      });

      this.licencas = data.data.total;
    },
  },
  computed: {
    ...mapGetters([
      "layoutConfig",
      "itsblocked",
      "isRevenda",
      "itsblockedDate",
      "currentUser",
    ]),
    config() {
      return this.layoutConfig();
    },
  },
  async mounted() {
    if (this.itsblocked || this.itsblockedDate) {
      this.$router.push({ name: "assinatura" });
    }

    if (!this.isRevenda) {
      this.$router.push({ name: "dashboard" });
    }

    await this.getLicencas();
    this.nivelPartner = this.currentUser.usuario_empresa.empresa.nivel_revenda;
  },
};
</script>
