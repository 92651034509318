export default class PdvData {
  modelo = 65;

  tpNF = 1;

  serie = 99;

  remainder = 0;

  discount = 0;

  extraValue = 0;

  freightPrice = 0;

  price = 0;

  observation = "";

  buyer = {};

  seller = {};

  paymentModeCollection = [];

  productOfTradeCollection = [];

  tipoIntermediador = {
    id: 0,
    name: "Operação sem intermediador (em site ou plataforma própria)"
  };

  intermediador = null;

  usuarioIntermediador = null;

  status = 1;

  constructor({
    modelo = 65,
    tpNF = 1,
    serie = 99,
    remainder = 0,
    discount = 0,
    extraValue = 0,
    freightPrice = 0,
    price = 0,
    observation = "",
    buyer = {},
    seller = {},
    paymentModeCollection = [],
    productOfTradeCollection = [],
    tipoIntermediador = {
      id: 0,
      name: "Operação sem intermediador (em site ou plataforma própria)"
    },
    intermediador = null,
    usuarioIntermediador = null,
    status = 1,
  }) {
    this.modelo = modelo;
    this.tpNF = tpNF;
    this.serie = serie;
    this.remainder = remainder;
    this.discount = discount;
    this.extraValue = extraValue;
    this.freightPrice = freightPrice;
    this.price = price;
    this.observation = observation;
    this.buyer = buyer;
    this.seller = seller;
    this.paymentModeCollection = paymentModeCollection;
    this.productOfTradeCollection = productOfTradeCollection;
    this.tipoIntermediador = tipoIntermediador;
    this.intermediador = intermediador;
    this.usuarioIntermediador = usuarioIntermediador;
    this.status = status;
  }
}
