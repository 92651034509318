<template>
  <div class="card card-custom card-stretch gutter-b">
    <div class="card-header border-0 py-5">
      <h3 class="card-title align-items-start flex-column">
        <span class="card-label font-weight-bolder text-dark">
          Dados da Empresa
        </span>
      </h3>
    </div>
    <Form url="/empresa/update" />
  </div>
</template>

<script>
import Form from "./Form.vue";

export default {
  name: "app-emitente",
  data() {
    return {};
  },
  components: {
    Form,
  },
  methods: {},
  mounted() {},
};
</script>
