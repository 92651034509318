<template>
  <div>
    <div class="card card-custom gutter-b" style="box-shadow: none">
      <div class="card-header card-header-tabs-line">
        <ul
          class="nav nav-dark nav-bold nav-tabs nav-tabs-line"
          role="tablist"
          ref="builder-tab"
        >
          <li class="nav-item">
            <a
              class="nav-link active"
              data-tab="0"
              data-toggle="tab"
              href="#"
              role="tab"
            >
              Cadastro
            </a>
          </li>
        </ul>
      </div>
      <b-form class="form" @submit.stop.prevent="onSubmit">
        <div class="card-body">
          <b-tabs class="hide-tabs" v-model="tabIndex">
            <b-tab active>
              <b-row>
                <b-col md="2">
                  <b-row>
                    <b-col>
                      <b-form-group label="Modelo" label-for="modelo">
                        <b-form-select v-model="form.modelo">
                          <b-form-select-option
                            v-for="(m, index) in modelos"
                            :value="m.id"
                            :key="'modelo' + index"
                          >
                            {{ m.descricao }}
                          </b-form-select-option>
                        </b-form-select>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-col>
                <b-col md="3">
                  <b-row>
                    <b-col>
                      <b-form-group
                        id="proximaNota"
                        label-for="proximaNota"
                        label="N° do próximo documento fiscal"
                      >
                        <b-form-input
                          type="number"
                          maxlength="9"
                          v-model="$v.form.proximaNota.$model"
                          :state="validateState('proximaNota')"
                          autofocus
                        >
                        </b-form-input>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-col>
                <b-col md="3" v-show="form.modelo == 65">
                  <b-row>
                    <b-col>
                      <b-form-group
                        id="proximaVenda"
                        label-for="proximaVenda"
                        label="N° da próxima Pré-Venda"
                      >
                        <b-form-input
                          type="number"
                          maxlength="9"
                          v-model="form.proximaVenda"
                        >
                        </b-form-input>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-col>
                <b-col md="3">
                  <b-row>
                    <b-col>
                      <b-form-group
                        id="serie"
                        label-for="serie"
                        label="Série do documento"
                      >
                        <b-form-input
                          v-model="$v.form.serie.$model"
                          :state="validateState('serie')"
                        >
                        </b-form-input>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-col>
                <b-col md="3">
                  <b-form-group label="Ambiente" label-for="ambiente">
                    <b-form-select
                      v-model="$v.form.ambiente.$model"
                      placeholder=""
                      :state="validateState('ambiente')"
                      @change="setSerie"
                    >
                      <b-form-select-option
                        v-for="(a, index) in ambientes"
                        :value="a.id"
                        :key="'ambiente' + index"
                      >
                        {{ a.descricao }}
                      </b-form-select-option>
                    </b-form-select>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row v-show="form.modelo == 65">
                <b-col md="6">
                  <b-row>
                    <b-col>
                      <b-form-group
                        id="idTokenCSC"
                        label-for="idTokenCSC"
                        label="ID Token SC"
                      >
                        <b-form-input
                          placeholder="000000"
                          maxlength="6"
                          v-model="form.idTokenCSC"
                        >
                        </b-form-input>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-col>
                <b-col md="6">
                  <b-row>
                    <b-col>
                      <b-form-group
                        id="tokenCSC"
                        label-for="tokenCSC"
                        label="Token SC"
                      >
                        <b-form-input
                          maxlength="50"
                          placeholder="A321BCDE-1234-5678"
                          v-model="form.tokenCSC"
                        >
                        </b-form-input>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
              <b-row v-show="form.modelo == 65">
                <b-col md="12">
                  <b-form-group
                    id="informacoesComplementares"
                    label="Informações Complementares"
                    label-for="informacoesComplementares"
                  >
                    <b-form-textarea
                      rows="6"
                      max-rows="6"
                      id="informacoesComplementares"
                      name="informacoesComplementares"
                      v-model="form.informacoesComplementares"
                    >
                    </b-form-textarea>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row v-show="form.modelo == 58">
                <b-col md="4">
                  <b-form-group
                    label="Tipo MDFe"
                    label-for="tipo_transportador"
                  >
                    <select
                      name="tipo_transportador"
                      id="tipo_transportador"
                      v-model="form.tipo_transportador"
                      class="form-control"
                    >
                      <option
                        v-for="(u, index) in tiposMDFe"
                        :value="u.id"
                        :key="'tipo_transportador' + index"
                      >
                        {{ u.descricao }}
                      </option>
                    </select>
                  </b-form-group>
                </b-col>
                <b-col md="2">
                  <b-row>
                    <b-col>
                      <b-form-group id="rntrc" label-for="rntrc" label="RNTRC">
                        <b-form-input maxlength="20" v-model="form.rntrc">
                        </b-form-input>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
              <b-col>
                <b-row>
                  <b-form-group id="ativo" label-for="ativo" label="Ativo">
                    <span class="switch switch-icon">
                      <label>
                        <input
                          type="checkbox"
                          :value="true"
                          @change="setAtivo"
                          :checked="form.ativo == true"
                        />
                        <span></span>
                      </label>
                    </span>
                  </b-form-group>
                  <b-row>
                    <b-form-group
                      v-show="form.modelo == 55"
                      style="margin-left: 20px"
                      id="contigenciaSVCAN"
                      label-for="solicitarDadosCartao"
                      label="SVCAN (SEFAZ Virtual de Contingência Ambiente Nacional)"
                    >
                      <span class="switch switch-icon">
                        <label>
                          <input
                            type="checkbox"
                            :value="true"
                            @change="setContigenciaSVCAN"
                            :checked="form.contigenciaSVCAN == true"
                          />
                          <span></span>
                        </label>
                      </span>
                    </b-form-group>

                    <b-form-group
                      v-show="form.modelo == 65"
                      style="margin-left: 10px"
                      id="solicitarDadosCartao"
                      label-for="solicitarDadosCartao"
                      label="Solicitar dados da transação ao finalizar venda Crédito/Débito?"
                    >
                      <span class="switch switch-icon">
                        <label>
                          <input
                            type="checkbox"
                            :value="true"
                            @change="setDadosCartao"
                            :checked="form.solicitarDadosCartao == true"
                          />
                          <span></span>
                        </label>
                      </span>
                    </b-form-group>
                  </b-row>
                </b-row>
              </b-col>
            </b-tab>
          </b-tabs>
        </div>

        <div class="card-footer">
          <div class="row">
            <div class="col-lg-12" style="text-align: center">
              <button
                type="submit"
                ref="submit_nova_configuracao"
                class="btn btn-primary font-weight-bold"
              >
                Salvar
              </button>
              <router-link
                class="btn btn-clean font-weight-bold"
                :to="{
                  name: 'configuracoesLista',
                }"
              >
                Cancelar
              </router-link>
            </div>
          </div>
        </div>
      </b-form>
    </div>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import ConfiguracaoData, { ConfiguracaoDataRequired } from "./ConfiguracaoData";
import { onboardStore } from "../../../onboardStore";
import { mapGetters } from "vuex";
const HOMOLOCAGAO = 2;
import { SistemaMixin } from "@/modules/Application";

export default {
  name: "app-configuracao-form",
  props: {
    url: {
      type: String,
    },
    configuracao: {
      type: ConfiguracaoData,
      required: true,
    },
  },
  mixins: [validationMixin, SistemaMixin],
  data() {
    return {
      onboardStore,
      tabIndex: 0,
      form: {},
      ambientes: [
        {
          id: 1,
          descricao: "Produçao",
        },
        {
          id: 2,
          descricao: "Homologacao",
        },
      ],
      modelos: [
        {
          id: 55,
          descricao: "NFe",
        },
        {
          id: 65,
          descricao: "NFCe/Pré-venda",
        },
        {
          id: 58,
          descricao: "MDFe",
        },
      ],
      tiposMDFe: [
        {
          id: 0,
          descricao: "Não Aplicavel",
        },
        {
          id: 1,
          descricao: "ETC - Empresa de Transporte Rodoviário de Cargas",
        },
        {
          id: 2,
          descricao: "TAC - Transportador Autônomo de Cargas",
        },
        {
          id: 3,
          descricao: "CTC - Cooperativa de Transporte rodoviário de Carga",
        },
      ],
      tipo_transportador: null,
    };
  },
  validations: {
    form: ConfiguracaoDataRequired,
  },
  watch: {
    configuracao(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.form = newValue;
      }
    },
  },
  computed: {
    ...mapGetters(["itsblocked"]),
  },
  async mounted() {
    this.$root.$emit("bv::show::popover", "ambienteTuto");
    if (this.itsblocked) {
      this.$router.push({ name: "assinatura" });
    }
    await this.getDataInit();
    if (this.onboardStore.currentStep === 4) {
      this.$root.$emit("bv::show::popover", "emitenteTuto");
      this.fireOnboardToast({
        id: "configuracao",
        title: "2/5 - Configuração do ambiente",
        content:
          "Agora vamos configurar o ambiente. Essa configuração é necessária para quem vai usar a emissão de NFe, NFCe ou Pré-venda. Dúvidas sobre a numeração a ser configurada, contate seu contador ele irá saber responder essas questões.",
        buttonText: "Ok",
        fowardStep: () => (this.onboardStore.currentStep = 4),
      });
    }
  },
  methods: {
    async getDataInit() {
      this.form = await new ConfiguracaoData({});
    },
    setDadosCartao() {
      this.form.solicitarDadosCartao =
        this.form.solicitarDadosCartao == true ? false : true;
    },
    setAtivo() {
      this.form.ativo = this.form.ativo == true ? false : true;
    },
    setContigenciaSVCAN() {
      this.form.contigenciaSVCAN =
        this.form.contigenciaSVCAN == true ? false : true;
    },
    setModelo() {
      this.form.modelo = this.form.modelo;
    },
    setSerie() {
      if (this.form.ambiente === HOMOLOCAGAO) {
        this.form.serie = 99;
      }
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    async onSubmit() {
      const submitButton = this.$refs["submit_nova_configuracao"];

      try {
        this.$v.form.$touch();

        if (this.$v.form.$anyError) {
          this.$toast.error(
            "Verifique todos os campos obrigatórios do formulário!"
          );
          return;
        }

        submitButton.classList.add("spinner", "spinner-light", "spinner-right");
        submitButton.setAttribute("disabled", "");

        if (this.form.ambiente === HOMOLOCAGAO) {
          this.form.serie = 99;
        }

        await this.$http.post(this.url, this.form);

        if (this.onboardStore.currentStep > 0) {
          this.onboardStore.currentStep = 5;
        }

        this.$router.push({ name: "configuracoesLista" });
      } catch (error) {
        submitButton.classList.remove(
          "spinner",
          "spinner-light",
          "spinner-right"
        );
        submitButton.removeAttribute("disabled", "");
      }
    },
  },
};
</script>

<style>
.vs__dropdown-toggle {
  border: 2px solid #dedede;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border-radius: 6px;
  outline: none;
  height: 38px;
}
</style>
