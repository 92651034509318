import { required } from "vuelidate/lib/validators";

export default class ClienteData {
  nome = null;
  constructor(
    nome = null,
  ) {
    this.nome = nome;
  }
}

export const ClienteDataRequired = {
  nome: {
    required,
  },
};
