<template>
  <div class="container-app">
    <b-card class="card-header">
      <b-row>
        <b-col md="6" class="pdv-pesquisa">
          <slot name="formulario"></slot>
        </b-col>
        <b-col class="pdv-valor">
          <slot name="quantidade"></slot>
        </b-col>
        <b-col class="pdv-valor">
          <slot name="valor"></slot>
        </b-col>
        <b-col class="pdv-button">
          <slot name="addButton"></slot>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
export default {
  name: "CardBusca",
};
</script>

<style>
.card-header {
  /* border-bottom-right-radius: 20px !important; */
  /* border-bottom-left-radius: 20px !important; */
  border: 0px;
  padding: 0 1rem;
  background: var(--primary-color);
}

.card-header .card-title {
  text-align: center;
  color: #fff;
}

.card-header .card-body {
  padding: 10px;
  color: #fff;
}

.pdv-pesquisa {
  background-color: var(--color-secondary);
  align-self: center;
}

.pdv-quantidade {
  background-color: var(--color-primary);
  border-bottom-left-radius: 20px !important;
  padding: 10px 10px 10px 15px;
  align-self: center;
}

.pdv-valor {
  background-color: var(--color-primary);
  border-bottom-right-radius: 20px !important;
  padding: 0px 15px 25px 0px;
  align-self: center;
}

.checkout {
  background: #eef0ef;
  color: #6d747c;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 5px;
  padding-top: 12px;
  font-size: 14px;
}

.checkout b {
  display: block;
  font-size: 22px;
  line-height: 20px;
}

.checkout button {
  margin: 0;
}

.checkout-action button.movimentation {
  margin-right: 10px;
}
</style>
