<template>
  <div>
    <div class="card card-custom gutter-b">
      <div class="card-header card-header-tabs-line">
        <ul
          class="nav nav-dark nav-bold nav-tabs nav-tabs-line"
          role="tablist"
          ref="builder-tab"
        >
          <li class="nav-item">
            <a
              class="nav-link active"
              data-tab="0"
              data-toggle="tab"
              href="#"
              role="tab"
            >
              Cadastro
            </a>
          </li>
        </ul>
      </div>

      <b-form class="form" @submit.stop.prevent="onSubmit">
        <div class="card-body">
          <b-tabs class="hide-tabs" v-model="tabIndex">
            <b-tab active>
              <b-row>
                <b-col md="5">
                  <b-form-group label="CFOP" label-for="codigo">
                    <VueSelect
                      ref="buscarCliente"
                      :filterable="false"
                      v-model="form.cfop"
                      :options="cfops"
                      @search="onSearchCFOP"
                    >
                      <template slot="no-options">
                        Informe o código do CFOP
                      </template>
                      <template slot="option" slot-scope="option">
                        <div class="d-center">
                          {{ option.codigo }} - {{ option.descricao }}
                        </div>
                      </template>
                      <template slot="selected-option" slot-scope="option">
                        <div class="selected d-center">
                          {{ option.codigo }} -
                          {{ option.descricao.substring(35, 0) }}
                        </div>
                      </template>
                    </VueSelect>
                  </b-form-group>
                </b-col>
                <b-col md="4">
                  <b-form-group
                    id="descricao"
                    label-for="descricao"
                    label="Descrição"
                    placeholder="Venda de mercadoria"
                  >
                    <b-form-input
                      v-model="$v.form.descricao.$model"
                      :state="validateState('descricao')"
                    >
                    </b-form-input>
                  </b-form-group>
                </b-col>
                <b-col md="3">
                  <b-form-group label="Tributação" label-for="tributacao">
                    <VueSelect
                      label="descricao"
                      :options="tributacoes"
                      v-model="form.tributacao"
                      @search="onSearchTributacao"
                    >
                      <template slot="no-options">
                        Informe a descricao
                      </template>
                      <template slot="option" slot-scope="option">
                        <div class="d-center">
                          {{ option.descricao }}
                        </div>
                      </template>
                      <template slot="selected-option" slot-scope="option">
                        <div class="selected d-center">
                          {{ option.descricao }}
                        </div>
                      </template>
                    </VueSelect>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col md="2">
                  <b-form-group
                    id="movimentaEstoque"
                    label-for="movimentaEstoque"
                    label="Movimenta Estoque"
                  >
                    <span class="switch switch-icon">
                      <label>
                        <input
                          type="checkbox"
                          :value="true"
                          @change="setMovimentaEstoque"
                          :checked="form.movimentaEstoque"
                        />
                        <span></span>
                      </label>
                    </span>
                  </b-form-group>
                </b-col>
                <b-col md="2">
                  <b-form-group
                    id="calculaIpi"
                    label-for="calculaIpi"
                    label="Calcular IPI"
                  >
                    <span class="switch switch-icon">
                      <label>
                        <input
                          type="checkbox"
                          :value="false"
                          @change="setCalculaIPI"
                          :checked="form.calculaIpi"
                        />
                        <span></span>
                      </label>
                    </span>
                  </b-form-group>
                </b-col>
                <b-col md="3">
                  <b-form-group
                    id="retencaoPISCOFINSCSLL"
                    label-for="retencaoPISCOFINSCSLL"
                    label="Rentenção PIS/COFINS/CSLL"
                  >
                    <span class="switch switch-icon">
                      <label>
                        <input
                          type="checkbox"
                          :value="false"
                          @change="setRetencaoPISCOFINS"
                          :checked="form.retencaoPISCOFINSCSLL"
                        />
                        <span></span>
                      </label>
                    </span>
                  </b-form-group>
                </b-col>
                <b-col md="2">
                  <b-form-group
                    id="calculaTributo"
                    label-for="calculaTributo"
                    label="Calcular Tributos"
                  >
                    <span class="switch switch-icon">
                      <label>
                        <input
                          type="checkbox"
                          :value="true"
                          @change="setCalcularTributo"
                          :checked="form.calculaTributo"
                        />
                        <span></span>
                      </label>
                    </span>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col md="12">
                  <b-form-group
                    id="informacoesComplementares"
                    label="Informações Complementares"
                    label-for="informacoesComplementares"
                  >
                    <b-form-textarea
                      rows="6"
                      max-rows="6"
                      id="informacoesComplementares"
                      name="informacoesComplementares"
                      v-model="form.informacoesComplementares"
                    >
                    </b-form-textarea>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-tab>
          </b-tabs>
        </div>

        <div class="card-footer">
          <div class="row">
            <div class="col-lg-12" style="text-align: center">
              <button
                type="submit"
                ref="submit_nova_natureza"
                class="btn btn-primary font-weight-bold"
              >
                Salvar
              </button>

              <router-link
                class="btn btn-clean font-weight-bold"
                :to="{
                  name: 'naturezasLista',
                }"
              >
                Cancelar
              </router-link>
            </div>
          </div>
        </div>
      </b-form>
    </div>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { SistemaMixin } from "@/modules/Application";
import NaturezaData, { NaturezaDataRequired } from "./NaturezaData";
import VueSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import _ from "lodash";

export default {
  name: "app-natureza-form",
  props: {
    url: {
      type: String,
    },
    natureza: {
      type: Object,
      default: () => {},
    },
  },
  mixins: [validationMixin, SistemaMixin],
  data() {
    return {
      tabIndex: 0,
      form: {},
      cfops: [],
      tributacoes: [],
    };
  },
  validations: {
    form: NaturezaDataRequired,
  },
  components: {
    VueSelect,
  },
  mounted() {
    this.getDataInit();
  },
  methods: {
    async getDataInit() {
      this.form = new NaturezaData({});

      if (this.natureza.id) {
        this.form = this.natureza;
      }
      this.getTributacao().then((data) => (this.tributacoes = data));
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    validateTributacao(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    onSearchCFOP(search, loading) {
      if (search.length) {
        loading(true);
        this.searchCFOP(loading, search, this);
      }
    },
    searchCFOP: _.debounce(async (loading, search, vm) => {
      vm.cfops = await vm.getCFOPByCodigo(search);
      loading(false);
    }, 350),
    onSearchTributacao(search, loading) {
      if (search.length) {
        loading(true);
        this.onTributacao(loading, search, this);
      }
    },
    onTributacao: _.debounce(async (loading, search, vm) => {
      vm.tributacoes = await vm.getTributacaoByDescricao(search);
      loading(false);
    }, 350),
    setMovimentaEstoque() {
      this.form.movimentaEstoque = this.form.movimentaEstoque ? false : true;
    },
    setCalculaIPI() {
      this.form.calculaIpi = this.form.calculaIpi ? false : true;
    },
    setRetencaoPISCOFINS() {
      this.form.retencaoPISCOFINSCSLL = this.form.retencaoPISCOFINSCSLL
        ? false
        : true;
    },
    setCalcularTributo() {
      this.form.calculaTributo = this.form.calculaTributo ? false : true;
    },
    async onSubmit() {
      const submitButton = this.$refs["submit_nova_natureza"];

      if (this.form.cfop.length <= 0) {
        return this.$toast.error("Selecione um CFOP!");
      }

      if (this.form.tributacao.length <= 0) {
        return this.$toast.error("Selecione uma tributação!");
      }

      try {
        this.$v.form.$touch();

        if (this.$v.form.$anyError) {
          return;
        }

        submitButton.classList.add("spinner", "spinner-light", "spinner-right");
        submitButton.setAttribute("disabled", "");

        await this.$http.post(this.url, this.form);

        this.$router.push({ name: "naturezasLista" });
      } catch (error) {
        submitButton.classList.remove(
          "spinner",
          "spinner-light",
          "spinner-right"
        );
        submitButton.removeAttribute("disabled", "");
      }
    },
  },
};
</script>

<style>
.vs__dropdown-toggle {
  border: 2px solid #dedede;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border-radius: 6px;
  outline: none;
  height: 38px;
}
</style>
