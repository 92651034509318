export default class ImpostosData {
  valorFrete = 0;
  icmsBase = 0;
  icmsValue = 0;
  ipiValue = 0;
  sTValue = 0;
  vBCST = 0;
  vFCPST = 0;
  outrasDespesas = 0;
  seguro = 0;
  desconto = 0;
  totalProdutos = 0;
  valor = 0;
  descontoPorcentagem = false;
  constructor(
    valorFrete = 0,
    icmsBase = 0,
    icmsValue = 0,
    ipiValue = 0,
    sTValue = 0,
    vBCST = 0,
    vFCPST = 0,
    outrasDespesas = 0,
    seguro = 0,
    desconto = 0,
    totalProdutos = 0,
    valor = 0,
    descontoPorcentagem = false
  ) {
    this.valorFrete = valorFrete;
    this.icmsBase = icmsBase;
    this.icmsValue = icmsValue;
    this.ipiValue = ipiValue;
    this.sTValue = sTValue;
    this.vBCST = vBCST;
    this.vFCPST = vFCPST;
    this.outrasDespesas = outrasDespesas;
    this.seguro = seguro;
    this.desconto = desconto;
    this.totalProdutos = totalProdutos;
    this.valor = valor;
    this.descontoPorcentagem = descontoPorcentagem;
  }
}
