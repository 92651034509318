<template>
  <div>
    <div class="card card-custom gutter-b" style="box-shadow: none">
      <div class="card-header border-0 py-5">
        <h3 class="card-title align-items-start flex-column">
          <span class="card-label font-weight-bolder text-dark">
            Novo Veículo
          </span>
        </h3>
      </div>
      <div class="card-body">
        <b-tabs class="hide-tabs" v-model="tabIndex">
          <b-tab active>
            <b-row>
              <b-col>
                <b-form-group id="placa" label-for="placa" label="Placa">
                  <b-form-input maxlength="100" v-mask="['AAA-#X##']" v-model="form.placa" autofocus
                    :state="validateState('placa')">
                  </b-form-input>
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group id="uf" label-for="uf" label="UF">
                  <b-form-input maxlength="2" v-model="form.uf" autofocus :state="validateState('uf')" />
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group id="renavam" label-for="renavam" label="Renavam">
                  <b-form-input maxlength="100" v-model="form.renavam" autofocus :state="validateState('renavam')">
                  </b-form-input>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="8">
                <b-form-group id="descricao" label-for="descricao" label="Descrição">
                  <b-form-input maxlength="100" v-model="form.descricao" autofocus :state="validateState('descricao')">
                  </b-form-input>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-form-group id="tipo_rodado" label="Tipo Rodado" label-for="tipo_rodado">
                  <VueSelect placeholder="Selecione o tipo rodado" label="tipo_rodado" :filterable="false"
                    :searchable="false" :options="tiposRodados" v-model="form.tipo_rodado"
                    @input="() => rodadosError = false" :state="validateState('tipo_rodado')"
                    :class="{ hasErrors: rodadosError }">
                    <template slot="option" slot-scope="option">
                      <div class="d-center">
                        {{ option.descricao }}
                      </div>
                    </template>
                    <template slot="selected-option" slot-scope="option">
                      <div class="selected d-center">
                        {{ option.descricao }}
                      </div>
                    </template>
                  </VueSelect>
                </b-form-group>
              </b-col>

              <b-col>
                <b-form-group id="tipo_caroceria" label="Tipo Caroceria" label-for="tipo_caroceria">
                  <VueSelect placeholder="Selecione o tipo de caroceria" label="tipo_caroceria" :filterable="false"
                    :searchable="false" :options="tiposCaroceria" v-model="form.tipo_caroceria"
                    :state="validateState('tipo_caroceria')" @input="() => caroceriaError = false"
                    :class="{ hasErrors: caroceriaError }">
                    <template slot="option" slot-scope="option">
                      <div class="d-center">
                        {{ option.descricao }}
                      </div>
                    </template>
                    <template slot="selected-option" slot-scope="option">
                      <div class="selected d-center">
                        {{ option.descricao }}
                      </div>
                    </template>
                  </VueSelect>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="2">
                <b-form-group id="tara" label-for="tara" label="Tara">
                  <money class="form-control" v-model="form.tara" v-bind="configMoney" :state="validateState('tara')">
                  </money>
                </b-form-group>
              </b-col>
              <b-col md="2">
                <b-form-group id="tara_kg" label-for="tara_kg" label="Tara Kg">
                  <money class="form-control" v-model="form.tara_kg" v-bind="configMoney"
                    :state="validateState('tara_kg')">
                  </money>
                </b-form-group>
              </b-col>
              <b-col md="2">
                <b-form-group id="capacidade_kg" label-for="capacidade_kg" label="Capacidade Kg">
                  <money class="form-control" v-model="form.capacidade_kg" v-bind="configMoney"
                    :state="validateState('capacidade_kg')">
                  </money>
                </b-form-group>
              </b-col>
              <b-col md="2">
                <b-form-group id="capacidade_m3" label-for="capacidade_m3" label="Capacidade m3">
                  <money class="form-control" v-model="form.capacidade_m3" v-bind="configMoney"
                    :state="validateState('capacidade_m3')">
                  </money>
                </b-form-group>
              </b-col>
            </b-row>
          </b-tab>
        </b-tabs>
      </div>

      <div class="card-footer">
        <div class="row">
          <div class="col-lg-12" style="text-align: center">
            <button type="submit" ref="submit_novo_veiculo" @click="onSubmit" class="btn btn-primary font-weight-bold">
              Salvar
            </button>
            <router-link class="btn btn-clean font-weight-bold" :to="{
              name: 'veiculos',
            }">
              Cancelar
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { SistemaMixin } from "@/modules/Application";
import VeiculoData, { VeiculoDataRequired } from "./VeiculoData";
import { Money } from "v-money";
import { MONEY as settingMoney } from "@/modules/Application";
import VueSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import _ from "lodash";
import { mapGetters, mapActions } from "vuex";
import { mask } from "vue-the-mask";

export default {
  name: "app-veiculos-form",
  directives: {
    mask,
  },
  components: {
    Money,
    VueSelect,
  },
  mixins: [validationMixin, SistemaMixin],
  data() {
    return {
      tabIndex: 0,
      configMoney: settingMoney,
      form: {},
      grupos: [],
      anp: null,
      cstscofins: [],
      tipo_rodado: null,
      tiposRodados: [],
      tiposCaroceria: [],
      caroceriaError: false,
      rodadosError: false,
    };
  },
  validations: {
    form: VeiculoDataRequired,
  },
  watch: {
  },
  computed: {
    ...mapGetters(["itsblocked"]),
  },
  created() {
    if (this.itsblocked) {
      this.$router.push({ name: "assinatura" });
    }
    this.getDataInit();
  },
  methods: {
    ...mapActions(["setLoading"]),
    async getDataInit() {
      this.setLoading(true);
      this.form = new VeiculoData({});
      try {
        const { data: tiposRodados } = await this.$http.post('/veiculos/get-tipo-rodado');
        this.tiposRodados = tiposRodados;

        const { data: tiposCaroceria } = await this.$http.post('/veiculos/get-tipo-caroceria');
        this.tiposCaroceria = tiposCaroceria;

        if (this.$route.params?.veiculoData) {
          const veiculoData = this.$route.params?.veiculoData
          this.form = veiculoData;
          this.form.tipo_caroceria = this.tiposCaroceria.find(caroceria => caroceria.id === veiculoData.tipo_caroceria);
          this.form.tipo_rodado = this.tiposRodados.find(rodado => rodado.id === veiculoData.tipo_rodado);
        }
      } catch (error) {
        console.log(error);
      }
      this.setLoading(false);
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    setActivePreviousTab() {
      this.tabIndex = parseInt(localStorage.getItem("builderTab")) || 0;
      const links = this.$refs["builder-tab"].querySelectorAll(".nav-link");

      for (let i = 0; i < links.length; i++) {
        links[i].classList.remove("active");
      }

      this.$refs["builder-tab"]
        .querySelector(`[data-tab="${this.tabIndex}"]`)
        .classList.add("active");
    },
    setActiveTab(event) {
      const tab = event.target.closest('[role="tablist"]');
      const links = tab.querySelectorAll(".nav-link");

      for (let i = 0; i < links.length; i++) {
        links[i].classList.remove("active");
      }

      this.tabIndex = parseInt(event.target.getAttribute("data-tab"));

      event.target.classList.add("active");
    },

    async onSubmit() {
      this.addLoadingToButton("submit_novo_veiculo")

      try {
        this.$v.form.$touch();

        if (this.$v.form.$anyError) {
          this.$toast.error(
            "Verifique todos os campos obrigatórios do formulário!"
          );
          if (this.$v.form.tipo_rodado.$anyError) {
            this.$toast.error("Preencha o tipo rodao!");
            this.rodadosError = true;
          }
          if (this.$v.form.tipo_caroceria.$anyError) {
            this.$toast.error("Preencha o tipo de caroceria!");
            this.caroceriaError = true;
          }
          this.removeLoadingToButton('submit_novo_veiculo')
          return;
        }
        const url = this.$route.params?.veiculoData?.id_veiculo ?
          '/veiculos/update'
          :
          '/veiculos/create'

        await this.$http.post(url, {
          ...this.form,
          tipo_rodado: this.form.tipo_rodado.id,
          tipo_caroceria: this.form.tipo_caroceria.id,
        });

        this.$router.push({ name: "veiculos" });
      } catch (error) {
        console.log(error)
      }
      this.removeLoadingToButton('submit_novo_veiculo')
    },
  },
};
</script>