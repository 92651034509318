import { required } from "vuelidate/lib/validators";

export default class CompraData {
  natureza = null;
  chaveDeAcesso = null;
  dataEmissao = null;
  dataEntrada = null;
  observacao = null;
  numeroNFe = null;
  serieNFe = null;
  modeloNFe = "55";
  pagamentos = [];
  fornecedor = {};
  produtos = [];
  transportador = {};
  impostos = {};
  constructor(
    natureza = null,
    chaveDeAcesso = null,
    dataEmissao = null,
    dataEntrada = null,
    observacao = null,
    numeroNFe = null,
    serieNFe = null,
    modeloNFe = "55",
    pagamentos = [],
    fornecedor = {},
    produtos = [],
    transportador = {},
    impostos = {}
  ) {
    this.natureza = natureza;
    this.chaveDeAcesso = chaveDeAcesso;
    this.dataEmissao = dataEmissao;
    this.dataEntrada = dataEntrada;
    this.observacao = observacao;
    this.numeroNFe = numeroNFe;
    this.serieNFe = serieNFe;
    this.modeloNFe = modeloNFe;
    this.pagamentos = pagamentos;
    this.fornecedor = fornecedor;
    this.produtos = produtos;
    this.transportador = transportador;
    this.impostos = impostos;
  }
}

export const CompraDataRequired = {
  chaveDeAcesso: {
    required,
  },
  dataEmissao: {
    required,
  },
  dataEntrada: {
    required,
  },
  numeroNFe: {
    required,
  },
  serieNFe: {
    required,
  },
  modeloNFe: {
    required,
  },
};
