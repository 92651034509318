import vendedorLista from "@/modules/Vendedor/pages/Index";
import vendedorNovo from "@/modules/Vendedor/pages/Create";
import vendedorEditar from "@/modules/Vendedor/pages/Update";

export default [
  {
    name: "vendedorLista",
    path: "/vendedor",
    component: vendedorLista,
    meta: { title: "Vendedores" },
  },
  {
    name: "vendedorNovo",
    path: "/vendedor/novo",
    component: vendedorNovo,
    meta: { title: "Vendedores" },
  },
  {
    name: "vendedorEditar",
    path: "/vendedor/:id/editar",
    component: vendedorEditar,
    meta: { title: "Vendedores" },
  },
];
