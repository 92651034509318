var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"card card-custom card-stretch gutter-b"},[(_vm.titulo != null)?_c('div',{staticClass:"card-header border-0 py-5"},[_c('h3',{staticClass:"card-title align-items-start flex-column"},[_c('span',{staticClass:"card-label font-weight-bolder text-dark"},[_vm._v(_vm._s(_vm.titulo))]),_c('span',{staticClass:"text-muted mt-3 font-weight-bold font-size-sm"},[_vm._v(_vm._s(_vm.subTitulo))])]),_c('div',{staticClass:"card-toolbar"},[(_vm.acaoBtn != null)?_c('a',{staticClass:"btn btn-dark font-weight-bolder font-size-sm",attrs:{"href":_vm.acaoBtn}},[_vm._v(_vm._s(_vm.textoBtn))]):_vm._e(),(_vm.acaoBtnTwo != null)?_c('a',{staticClass:"btn btn-dark font-weight-bolder font-size-sm",staticStyle:{"margin-left":"5px"},attrs:{"href":_vm.acaoBtnTwo}},[_vm._v(_vm._s(_vm.textoBtnTwo))]):_vm._e()])]):_vm._e(),_c('div',{staticClass:"card-body pt-0 pb-3"},[_c('div',{staticClass:"tab-content"},[_c('div',{staticClass:"table-responsive"},[_c('vue-good-table',{attrs:{"mode":"remote","theme":"polar-bear","styleClass":"vgt-table table-striped","max-height":"300px","totalRows":_vm.totalRecords,"isLoading":_vm.isLoading,"pagination-options":{
              perPage: 5,
              mode: 'pages',
              enabled: false,
              nextLabel: 'Próximo',
              prevLabel: 'Voltar',
              rowsPerPageLabel: '',
              ofLabel: 'de',
              allLabel: 'Todos',
              dropdownAllowAll: false,
              infoFn: (params) => '',
            },"rows":_vm.data,"columns":_vm.fields},on:{"on-page-change":_vm.onPageChange,"on-sort-change":_vm.onSortChange,"on-column-filter":_vm.onColumnFilter,"on-per-page-change":_vm.onPerPageChange,"update:isLoading":function($event){_vm.isLoading=$event},"update:is-loading":function($event){_vm.isLoading=$event}},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [_vm._v(" "+_vm._s(props.slot)+" "),(props.column.slot)?[_vm._t("customRow",null,{"rowData":props.row,"field":props.column.field})]:[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")]]}}],null,true)},[_c('template',{slot:"loadingContent"},[_c('Loading')],1),_c('template',{slot:"emptystate"},[_c('div',{staticClass:"vgt-center-align vgt-text-disabled"},[_vm._v(" Nenhum "+_vm._s(_vm.item)+" encontrado ")])])],2)],1)])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }