import planodecontas from "@/modules/PlanoDeContas/pages/Index";

export default [
  {
    name: "planodecontas",
    path: "/planodecontas",
    component: planodecontas,
    meta: { title: "Plano de Contas" },
  },
];
