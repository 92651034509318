<template>
  <div class="card card-custom card-stretch gutter-b">
    <div class="card-header border-0 py-5">
      <h3 class="card-title align-items-start flex-column">
        <span class="card-label font-weight-bolder text-dark">
          Editar Conta á Receber
        </span>
      </h3>
    </div>
    <Form url="/contareceber/update" v-if="!loader" :conta="contareceber" />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Form from "./Form.vue";

export default {
  name: "app-contareceber-update",
  data() {
    return {
      contareceber: {},
      loader: true,
    };
  },
  components: {
    Form,
  },
  methods: {
    ...mapActions(["setLoading"]),
    async getcontareceber(id) {
      const { data } = await this.$http.post("/contareceber/find", {
        id: id,
      });

      return data;
    },
    async getInit() {
      try {
        const id = this.$route.params.id;

        this.setLoading(true);

        const contareceber = await this.getcontareceber(id);

        this.setLoading(false);

        if (!contareceber.id || contareceber == null) {
          this.$router.push({ name: "contareceberLista" });
          return this.$toast.error("Movimento não encontrado!");
        }

        this.contareceber = await contareceber;
        this.loader = false;
      } catch {
        return;
      }
    },
  },
  computed: {
    ...mapGetters(["itsblocked"]),
  },
  mounted() {
    if (this.itsblocked) {
      this.$router.push({ name: "assinatura" });
    }
    this.getInit();
  },
};
</script>
