import { required } from "vuelidate/lib/validators";

export default class ClienteData {
  objeto = null;
  identificadores = null;
  constructor(
    objeto = null,
    identificadores = null,
  ) {
    this.objeto = objeto;
    this.identificadores = identificadores;
  }
}

export const ClienteDataRequired = {
  objeto: {
    required,
  },
  identificadores: {
    required,
  },
};
