<template>
  <div>
    <div class="card card-custom card-stretch gutter-b">
      <div class="card-header border-0 py-5">
        <h3 class="card-title align-items-start flex-column">
          <span class="card-label font-weight-bolder text-dark">
            Configurações gerais
          </span>
        </h3>
      </div>
      <b-card>
        <b-tabs>
          <b-tab no-body title="NFe/NFCe">
            <b-card-footer>
              <div class="border-bottom border-light py-3">
                <b-col md="3">
                  <p class="font-weight-bolder text-dark mb-2">
                    Impressão retrato na Pré-venda
                  </p>
                  <span class="switch switch-icon">
                    <label v-b-tooltip.hover.right="'Desativar/Ativar'">
                      <input type="checkbox" v-model="retratoImpressao" />
                      <span></span>
                    </label>
                  </span>
                </b-col>
              </div>
              <div class="border-bottom border-light py-3">
                <b-col md="3">
                  <p class="font-weight-bolder text-dark mb-2">
                    Margem de 4mm na NFC-e
                  </p>
                  <span class="switch switch-icon">
                    <label v-b-tooltip.hover.right="'Desativar/Ativar'">
                      <input type="checkbox" v-model="margemNFCe" />
                      <span></span>
                    </label>
                  </span>
                </b-col>
              </div>
              <div class="border-bottom border-light py-3">
                <b-col md="3">
                  <p class="font-weight-bolder text-dark mb-2">
                    2 Vias na Pré-venda
                  </p>
                  <span class="switch switch-icon">
                    <label v-b-tooltip.hover.right="'Desativar/Ativar'">
                      <input type="checkbox" v-model="viasPrevenda" />
                      <span></span>
                    </label>
                  </span>
                </b-col>
              </div>
              <div class="border-bottom border-light py-3">
                <b-col md="6">
                  <p class="font-weight-bolder text-dark mb-2">
                    Considerar desconto de ICMS na base do PIS/COFINS
                  </p>
                  <span class="switch switch-icon">
                    <label v-b-tooltip.hover.right="'Não/Sim'">
                      <input
                        type="checkbox"
                        v-model="descontar_icms_base_piscofins"
                      />
                      <span></span>
                    </label>
                  </span>
                </b-col>
              </div>
              <div class="border-bottom border-light py-3">
                <b-col md="6">
                  <p class="font-weight-bolder text-dark mb-2">
                    Vendas na NFCe no cartão devem ir para Contas a Receber?
                  </p>
                  <span class="switch switch-icon">
                    <label v-b-tooltip.hover.right="'Não/Sim'">
                      <input
                        type="checkbox"
                        v-model="vendas_cartao_conta_receber"
                      />
                      <span></span>
                    </label>
                  </span>
                </b-col>
              </div>
              <div class="border-bottom border-light py-3">
                <b-col md="6">
                  <p class="font-weight-bolder text-dark mb-2">
                    Abertura de caixa
                  </p>
                  <span class="switch switch-icon">
                    <label v-b-tooltip.hover.right="'Não/Sim'">
                      <input type="checkbox" v-model="abertura_caixa" />
                      <span></span>
                    </label>
                  </span>
                </b-col>
              </div>
              <div class="border-bottom border-light py-3">
                <b-col md="6">
                  <p class="font-weight-bolder text-dark mb-2">
                    Calcular ST considerando desconto do ICMS
                  </p>
                  <span class="switch switch-icon">
                    <label v-b-tooltip.hover.right="'Não/Sim'">
                      <input
                        type="checkbox"
                        v-model="calcular_st_desconto_icms"
                      />
                      <span></span>
                    </label>
                  </span>
                </b-col>
              </div>
              <div class="border-bottom border-light py-3">
                <b-col md="6">
                  <p class="font-weight-bolder text-dark mb-2">
                    Impressão duplicata para NFCe
                  </p>
                  <span class="switch switch-icon">
                    <label v-b-tooltip.hover.right="'Não/Sim'">
                      <input type="checkbox" v-model="duplicata_nfce" />
                      <span></span>
                    </label>
                  </span>
                </b-col>
              </div>
              <div class="border-bottom border-light py-3">
                <b-col md="3">
                  <p class="font-weight-bolder text-dark mb-2">
                    Importar OBS do cadastro do cliente para NFCe/Pré-Venda
                  </p>
                  <span class="switch switch-icon">
                    <label v-b-tooltip.hover.right="'Não/Sim'">
                      <input type="checkbox" v-model="importar_obs_pessoa" />
                      <span></span>
                    </label>
                  </span>
                </b-col>
              </div>

              <div class="border-bottom border-light py-3">
                <b-col md="3">
                  <p class="font-weight-bolder text-dark mb-2">
                    Utilizar referência na gereção da Danfe
                  </p>
                  <span class="switch switch-icon">
                    <label v-b-tooltip.hover.right="'Não/Sim'">
                      <input type="checkbox" v-model="referencia_danfe" />
                      <span></span>
                    </label>
                  </span>
                </b-col>
              </div>

              <div class="border-bottom border-light py-3">
                <b-col md="3">
                  <p class="font-weight-bolder text-dark mb-2">
                    Desconto Incondicional
                  </p>
                  <span class="switch switch-icon">
                    <label v-b-tooltip.hover.right="'Não/Sim'">
                      <input type="checkbox" v-model="desconto_incondicional" />
                      <span></span>
                    </label>
                  </span>
                </b-col>
              </div>

              <div class="border-bottom border-light py-3">
                <b-col md="3">
                  <p class="font-weight-bolder text-dark mb-2">
                    Ordenar Impressão da Pré-Venda pela descrição dos produtos
                  </p>
                  <span class="switch switch-icon">
                    <label v-b-tooltip.hover.right="'Não/Sim'">
                      <input
                        type="checkbox"
                        v-model="ordenacao_produtos_prevenda"
                      />
                      <span></span>
                    </label>
                  </span>
                </b-col>
              </div>
              <div class="border-bottom border-light py-3">
                <b-col md="6">
                  <p class="font-weight-bolder text-dark mb-2">
                    Somar Frete/Seguro/Outras/IPI despesas na Base ICMS
                  </p>
                  <span class="switch switch-icon">
                    <label v-b-tooltip.hover.right="'Não/Sim'">
                      <input
                        type="checkbox"
                        v-model="somar_frete_despesas_baseicms"
                      />
                      <span></span>
                    </label>
                  </span>
                </b-col>
              </div>
              <div class="border-bottom border-light py-3">
                <b-col md="6">
                  <p class="font-weight-bolder text-dark mb-2">
                    Integração com POS Nuts
                  </p>
                  <span class="switch switch-icon">
                    <label v-b-tooltip.hover.right="'Não/Sim'">
                      <input type="checkbox" v-model="integracao_pos" />
                      <span></span>
                    </label>
                  </span>
                </b-col>
              </div>
              <div class="border-bottom border-light py-3">
                <b-col md="6">
                  <p class="font-weight-bolder text-dark mb-2">
                    Impressão paisagem Danfe
                  </p>
                  <span class="switch switch-icon">
                    <label v-b-tooltip.hover.right="'Não/Sim'">
                      <input
                        type="checkbox"
                        v-model="impressao_paisagem_danfe"
                      />
                      <span></span>
                    </label>
                  </span>
                </b-col>
              </div>
              <div class="border-bottom border-light py-3">
                <b-col md="6">
                  <p class="font-weight-bolder text-dark mb-2">
                    Não gerar impressão de Pré-vendas/NFCe
                  </p>
                  <span class="switch switch-icon">
                    <label v-b-tooltip.hover.right="'Não/Sim'">
                      <input type="checkbox" v-model="nao_gerar_impressao" />
                      <span></span>
                    </label>
                  </span>
                </b-col>
              </div>
              <div class="border-bottom border-light py-3">
                <b-col md="3">
                  <p class="font-weight-bolder text-dark mb-2">
                    Não converter CFOP
                  </p>
                  <span class="switch switch-icon">
                    <label v-b-tooltip.hover.right="'Desativar/Ativar'">
                      <input type="checkbox" v-model="nao_converter_cfop" />
                      <span></span>
                    </label>
                  </span>
                </b-col>
              </div>
              <div class="border-bottom border-light py-3">
                <b-col md="3">
                  <p class="font-weight-bolder text-dark mb-2">
                    Obrigatório informar vendedor NFCe/Pré-Venda
                  </p>
                  <span class="switch switch-icon">
                    <label v-b-tooltip.hover.right="'Desativar/Ativar'">
                      <input type="checkbox" v-model="vendedor_obrigatorio" />
                      <span></span>
                    </label>
                  </span>
                </b-col>
              </div>
            </b-card-footer>
          </b-tab>
          <b-tab no-body title="Compras">
            <b-card-footer>
              <div class="border-bottom border-light py-3">
                <b-col md="3">
                  <p class="font-weight-bolder text-dark mb-2">
                    Último NSU - Monitor NFe
                  </p>
                  <b-form-input v-model="ultimo_nsu"> </b-form-input>
                </b-col>
              </div>
              <div class="border-bottom border-light py-3">
                <b-col md="6">
                  <p class="font-weight-bolder text-dark mb-2">
                    Todas as compras devem gerar as parcelas em
                    Financeiro/Contas a pagar
                  </p>
                  <span class="switch switch-icon">
                    <label v-b-tooltip.hover.right="'Não/Sim'">
                      <input type="checkbox" v-model="gerar_conta_pagar" />
                      <span></span>
                    </label>
                  </span>
                </b-col>
              </div>
              <div class="border-bottom border-light py-3">
                <b-col md="6">
                  <p class="font-weight-bolder text-dark mb-2">
                    Considerar quantidade tributável na importação do XML
                  </p>
                  <span class="switch switch-icon">
                    <label v-b-tooltip.hover.right="'Não/Sim'">
                      <input
                        type="checkbox"
                        v-model="considerar_qtd_tributavel"
                      />
                      <span></span>
                    </label>
                  </span>
                </b-col>
              </div>

              <div class="border-bottom border-light py-3">
                <b-col md="6">
                  <p class="font-weight-bolder text-dark mb-2">
                    Importar informações adicionais do item de forma automática
                  </p>
                  <span class="switch switch-icon">
                    <label v-b-tooltip.hover.right="'Não/Sim'">
                      <input
                        type="checkbox"
                        v-model="importar_desc_complementar"
                      />
                      <span></span>
                    </label>
                  </span>
                </b-col>
              </div>
            </b-card-footer>
          </b-tab>
          <b-tab no-body title="Pedidos/Orçamentos/OS">
            <b-card-footer>
              <div class="border-bottom border-light py-3">
                <b-col md="3">
                  <p class="font-weight-bolder text-dark mb-2">
                    Impressão OS em 80 colunas
                  </p>
                  <span class="switch switch-icon">
                    <label v-b-tooltip.hover.right="'Não/Sim'">
                      <input
                        type="checkbox"
                        v-model="impressao_os_80_colunas"
                      />
                      <span></span>
                    </label>
                  </span>
                </b-col>
              </div>
              <div class="border-bottom border-light py-3">
                <b-col md="6">
                  <p class="font-weight-bolder text-dark mb-2">
                    Considerar OBS do produto em Orçamentos/Pedidos
                  </p>
                  <span class="switch switch-icon">
                    <label v-b-tooltip.hover.right="'Não/Sim'">
                      <input type="checkbox" v-model="considerar_obs" />
                      <span></span>
                    </label>
                  </span>
                </b-col>
              </div>

              <div class="border-bottom border-light py-3">
                <b-col md="3">
                  <p class="font-weight-bolder text-dark mb-2">
                    Impressão pedidos em 80 colunas
                  </p>
                  <span class="switch switch-icon">
                    <label v-b-tooltip.hover.right="'Não/Sim'">
                      <input
                        type="checkbox"
                        v-model="impressao_pedido_80_colunas"
                      />
                      <span></span>
                    </label>
                  </span>
                </b-col>
              </div>

              <div class="border-bottom border-light py-3">
                <b-col md="12">
                  <p class="font-weight-bolder text-dark mb-2">
                    Incluir mensagem adicional Pedidos/Orçamentos
                  </p>
                  <span class="switch switch-icon">
                    <label v-b-tooltip.hover.right="'Não/Sim'">
                      <input type="checkbox" v-model="mensagem_adicional" />
                      <span></span>
                    </label>
                  </span>
                  <br />
                  <b-form-group
                    label="Mensagem"
                    label-for="mensagem_adicional_texto"
                    v-if="mensagem_adicional"
                  >
                    <v-textarea
                      full-width
                      no-resize
                      rows="1"
                      auto-grow
                      v-model="mensagem_adicional_texto"
                    ></v-textarea>
                  </b-form-group>
                </b-col>
              </div>
              <div class="border-bottom border-light py-3">
                <b-col md="6">
                  <p class="font-weight-bolder text-dark mb-2">
                    Impressão mensagem (art.1275, INCISO III DO CCB) na OS
                  </p>
                  <span class="switch switch-icon">
                    <label v-b-tooltip.hover.right="'Não/Sim'">
                      <input type="checkbox" v-model="impressao_msg_os" />
                      <span></span>
                    </label>
                  </span>
                </b-col>
              </div>
              <div class="border-bottom border-light py-3">
                <b-col md="12">
                  <p class="font-weight-bolder text-dark mb-2">
                    Custo Operacional
                  </p>
                  <span class="switch switch-icon">
                    <label v-b-tooltip.hover.right="'Não/Sim'">
                      <input type="checkbox" v-model="custo_operacional" />
                      <span></span>
                    </label>
                  </span>
                  <br />
                  <b-col md="2">
                    <b-form-group
                      id="custo_percentual"
                      label-for="custo_percentual"
                      label="Custo %"
                      v-if="custo_operacional"
                    >
                      <money
                        class="form-control"
                        v-model="custo_percentual"
                        v-bind="configMoney"
                      >
                      </money>
                    </b-form-group>
                  </b-col>
                </b-col>
              </div>
              <div class="border-bottom border-light py-3">
                <b-col md="6">
                  <p class="font-weight-bolder text-dark mb-2">
                    Impressão código de barras nos Orçamentos
                  </p>
                  <span class="switch switch-icon">
                    <label v-b-tooltip.hover.right="'Não/Sim'">
                      <input
                        type="checkbox"
                        v-model="imprimir_codigo_barra_orcamento"
                      />
                      <span></span>
                    </label>
                  </span>
                </b-col>
              </div>
              <div class="border-bottom border-light py-3">
                <b-col md="3">
                  <p class="font-weight-bolder text-dark mb-2">
                    Impressão do orçamento em 80 colunas
                  </p>
                  <span class="switch switch-icon">
                    <label v-b-tooltip.hover.right="'Não/Sim'">
                      <input
                        type="checkbox"
                        v-model="impressao_orcamento_80_colunas"
                      />
                      <span></span>
                    </label>
                  </span>
                </b-col>
              </div>
              <div class="border-bottom border-light py-3">
                <b-col md="3">
                  <p class="font-weight-bolder text-dark mb-2">
                    Impressão do NCM
                  </p>
                  <span class="switch switch-icon">
                    <label v-b-tooltip.hover.right="'Não/Sim'">
                      <input type="checkbox" v-model="impressao_ncm" />
                      <span></span>
                    </label>
                  </span>
                </b-col>
              </div>
              <div class="border-bottom border-light py-3">
                <b-col md="6">
                  <p class="font-weight-bolder text-dark mb-2">
                    Não imprimir dados do emitente
                  </p>
                  <span class="switch switch-icon">
                    <label v-b-tooltip.hover.right="'Não/Sim'">
                      <input
                        type="checkbox"
                        v-model="impressao_emitente_documentos"
                      />
                      <span></span>
                    </label>
                  </span>
                </b-col>
              </div>
            </b-card-footer>
          </b-tab>
          <b-tab no-body title="NFSe" v-if="userAccessStore.access.nfse">
            <b-card-footer>
              <div class="border-bottom border-light py-3">
                <b-col md="12">
                  <p class="font-weight-bolder text-dark mb-2">
                    Informação complementar fixa
                  </p>
                  <b-form-input
                    maxlength="100"
                    v-model="informacao_nfse"
                  ></b-form-input>
                </b-col>
              </div>
            </b-card-footer>
          </b-tab>
          <b-tab no-body title="Gerais">
            <b-card-footer>
              <div class="border-bottom border-light py-3">
                <b-col md="4">
                  <p class="font-weight-bolder text-dark mb-2">
                    Verificar se a pessoa já está cadastrada
                  </p>
                  <span class="switch switch-icon">
                    <label v-b-tooltip.hover.right="'Não/Sim'">
                      <input
                        type="checkbox"
                        v-model="verifica_cadastro_pessoa"
                      />
                      <span></span>
                    </label>
                  </span>
                </b-col>
              </div>
              <div class="border-bottom border-light py-3">
                <b-col md="4">
                  <p class="font-weight-bolder text-dark mb-2">
                    Calcular Juros Simples
                  </p>
                  <span class="switch switch-icon">
                    <label v-b-tooltip.hover.right="'Não/Sim'">
                      <input type="checkbox" v-model="juros_simples" />
                      <span></span>
                    </label>
                  </span>
                </b-col>
              </div>
              <div class="border-bottom border-light py-3">
                <b-row v-if="juros_simples">
                  <b-col md="2">
                    <b-form-group
                      id="taxa_diaria"
                      label-for="taxa_diaria"
                      label="Taxa Mensal %"
                    >
                      <money
                        class="form-control"
                        v-model="taxa_diaria"
                        v-bind="configMoney"
                      >
                      </money>
                    </b-form-group>
                  </b-col>
                  <b-col md="2">
                    <b-form-group id="multa" label-for="multa" label="Multa %">
                      <money
                        class="form-control"
                        v-model="multa"
                        v-bind="configMoney"
                      >
                      </money>
                    </b-form-group>
                  </b-col>
                </b-row>
              </div>

              <div class="border-bottom border-light py-3">
                <b-col md="6">
                  <p class="font-weight-bolder text-dark mb-2">
                    Cor Contas marcadas
                  </p>
                  <label v-b-tooltip.hover.right="'Cor das contas marcadas'">
                    <input type="color" v-model="contas_marcadas_color" />
                    <span></span>
                  </label>
                </b-col>
              </div>

              <div class="border-bottom border-light py-3">
                <b-col md="3">
                  <p class="font-weight-bolder text-dark mb-2">
                    Casas decimais preço
                  </p>
                  <b-form-select
                    placeholder=""
                    :options="casaDecimaisOptions"
                    v-model="casas_decimal_preco"
                  />
                </b-col>
              </div>

              <div class="border-bottom border-light py-3">
                <b-col md="3">
                  <p class="font-weight-bolder text-dark mb-2">
                    Casas decimais quantidade
                  </p>
                  <b-form-select
                    placeholder=""
                    :options="casaDecimaisOptions"
                    v-model="casas_decimal_quantidade"
                  />
                </b-col>
              </div>
              <div class="border-bottom border-light py-3">
                <b-col md="6">
                  <p class="font-weight-bolder text-dark mb-2">
                    Solicitar senha de Admnistrador
                  </p>
                  <span class="switch switch-icon">
                    <label v-b-tooltip.hover.right="'Não/Sim'">
                      <input type="checkbox" v-model="solicita_senha_adm" />
                      <span></span>
                    </label>
                  </span>
                </b-col>
              </div>

              <div class="border-bottom border-light py-3">
                <b-col md="6">
                  <p class="font-weight-bolder text-dark mb-2">
                    Utilizar sempre o campo referência para pesquisar os
                    produtos
                  </p>
                  <span class="switch switch-icon">
                    <label v-b-tooltip.hover.right="'Não/Sim'">
                      <input
                        type="checkbox"
                        v-model="utilizar_sempre_referencia"
                      />
                      <span></span>
                    </label>
                  </span>
                </b-col>
              </div>

              <div class="border-bottom border-light py-3">
                <b-col md="6">
                  <p class="font-weight-bolder text-dark mb-2">
                    Sincronizar os dados de cadastros com VendiZap
                  </p>
                  <span class="switch switch-icon">
                    <label v-b-tooltip.hover.right="'Não/Sim'">
                      <input type="checkbox" v-model="sync_vendizap" />
                      <span></span>
                    </label>
                  </span>
                </b-col>
              </div>

              <div class="border-bottom border-light py-3">
                <b-col md="3">
                  <p class="font-weight-bolder text-dark mb-2">
                    Definir formato (.html) como padrão dos relatórios
                  </p>
                  <span class="switch switch-icon">
                    <label v-b-tooltip.hover.right="'Não/Sim'">
                      <input type="checkbox" v-model="relatorios_html" />
                      <span></span>
                    </label>
                  </span>
                </b-col>
              </div>

              <div class="border-bottom border-light py-3">
                <b-col md="3">
                  <p class="font-weight-bolder text-dark mb-2">
                    Verificar estoque nas vendas
                  </p>
                  <span class="switch switch-icon">
                    <label v-b-tooltip.hover.right="'Não/Sim'">
                      <input type="checkbox" v-model="verifica_estoque" />
                      <span></span>
                    </label>
                  </span>
                </b-col>
              </div>

              <div class="border-bottom border-light py-3">
                <b-col md="6">
                  <p class="font-weight-bolder text-dark mb-2">
                    Utilizar sempre o campo descrição para pesquisar os produtos
                  </p>
                  <span class="switch switch-icon">
                    <label v-b-tooltip.hover.right="'Não/Sim'">
                      <input
                        type="checkbox"
                        v-model="utilizar_sempre_descricao"
                      />
                      <span></span>
                    </label>
                  </span>
                </b-col>
              </div>
              <div class="border-bottom border-light py-3">
                <b-col md="6">
                  <p class="font-weight-bolder text-dark mb-2">
                    Impressão recibo 80 colunas
                  </p>
                  <span class="switch switch-icon">
                    <label v-b-tooltip.hover.right="'Não/Sim'">
                      <input
                        type="checkbox"
                        v-model="impressao_recibo_80_colunas"
                      />
                      <span></span>
                    </label>
                  </span>
                </b-col>
              </div>
            </b-card-footer>
          </b-tab>
        </b-tabs>
      </b-card>
      <div class="card-footer">
        <div class="row">
          <div class="col-lg-12" style="text-align: center">
            <button
              ref="submit_nova_configs"
              class="btn btn-primary font-weight-bold mt-5"
              @click="salvarConfigs"
            >
              Salvar
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { SistemaMixin } from "@/modules/Application";
import { Money } from "v-money";
import { MONEY as settingMoney } from "@/modules/Application";
import { userAccessStore } from "@/userAccessStore";

export default {
  name: "app-configuracoes-gerais",
  data() {
    return {
      userAccessStore,
      configuracoes: null,
      retratoImpressao: false,
      margemNFCe: false,
      solicita_senha_adm: false,
      viasPrevenda: false,
      ultimo_nsu: null,
      verifica_cadastro_pessoa: false,
      abertura_caixa: false,
      juros_simples: false,
      configMoney: settingMoney,
      taxa_diaria: 0,
      multa: 0,
      casaDecimaisOptions: [
        { value: 2, text: "2" },
        { value: 3, text: "3" },
        { value: 4, text: "4" },
      ],
      descontar_icms_base_piscofins: false,
      integracao_pos: false,
      vendas_cartao_conta_receber: false,
      contas_marcadas_color: null,
      impressao_os_80_colunas: false,
      impressao_msg_os: false,
      casas_decimal_preco: 2,
      casas_decimal_quantidade: 2,
      calcular_st_desconto_icms: false,
      duplicata_nfce: false,
      gerar_conta_pagar: false,
      utilizar_sempre_referencia: false,
      considerar_qtd_tributavel: false,
      importar_desc_complementar: false,
      somar_frete_despesas_baseicms: false,
      sync_vendizap: false,
      considerar_obs: false,
      mensagem_adicional: false,
      mensagem_adicional_texto: null,
      impressao_pedido_80_colunas: false,
      relatorios_html: false,
      importar_obs_pessoa: false,
      referencia_danfe: false,
      desconto_incondicional: false,
      ordenacao_produtos_prevenda: false,
      impressao_paisagem_danfe: false,
      verifica_estoque: false,
      custo_operacional: false,
      custo_percentual: 0,
      nao_gerar_impressao: false,
      utilizar_sempre_descricao: false,
      nao_converter_cfop: false,
      imprimir_codigo_barra_orcamento: false,
      vendedor_obrigatorio: false,
      impressao_orcamento_80_colunas: false,
      impressao_ncm: false,
      impressao_recibo_80_colunas: false,
      informacao_nfse: null,
      impressao_emitente_documentos: false,
    };
  },
  components: {
    Money,
  },
  mixins: [SistemaMixin],
  computed: {
    ...mapGetters(["itsblocked"]),
  },
  methods: {
    async getConfigurationList() {
      try {
        const response = await this.$http.post("/configuracoesgerais/find");
        this.configuracoes = response.data;
        this.retratoImpressao = response.data[0].impressao_retrato_prevenda;
        this.margemNFCe = response.data[0].impressao_margem_nfce;
        this.viasPrevenda = response.data[0].imprimir_vias_prevenda;
        this.ultimo_nsu = response.data[0].ultimo_nsu;
        this.verifica_cadastro_pessoa =
          response.data[0].verifica_cadastro_pessoa;
        this.juros_simples = response.data[0].juros_simples;
        this.taxa_diaria = response.data[0].taxa_diaria;
        this.multa = response.data[0].multa;
        this.descontar_icms_base_piscofins =
          response.data[0].descontar_icms_base_piscofins;
        this.integracao_pos = response.data[0].integracao_pos;
        this.vendas_cartao_conta_receber =
          response.data[0].vendas_cartao_conta_receber;
        this.impressao_os_80_colunas = response.data[0].impressao_os_80_colunas;
        this.impressao_msg_os = response.data[0].impressao_msg_os;
        this.casas_decimal_quantidade =
          response.data[0].casas_decimal_quantidade;
        this.casas_decimal_preco = response.data[0].casas_decimal_preco;
        this.solicita_senha_adm = response.data[0].solicita_senha_adm;
        this.abertura_caixa = response.data[0]?.abertura_caixa ?? false;
        this.calcular_st_desconto_icms =
          response.data[0]?.calcular_st_desconto_icms ?? false;
        this.duplicata_nfce = response.data[0]?.duplicata_nfce ?? false;
        this.gerar_conta_pagar = response.data[0]?.gerar_conta_pagar ?? false;
        this.utilizar_sempre_referencia =
          response.data[0]?.utilizar_sempre_referencia ?? false;
        this.considerar_qtd_tributavel =
          response.data[0]?.considerar_qtd_tributavel ?? false;
        this.importar_desc_complementar =
          response.data[0]?.importar_desc_complementar ?? false;
        this.somar_frete_despesas_baseicms =
          response.data[0]?.somar_frete_despesas_baseicms ?? false;
        this.sync_vendizap = response.data[0]?.sync_vendizap ?? false;
        this.considerar_obs = response.data[0]?.considerar_obs ?? false;
        this.mensagem_adicional = response.data[0]?.mensagem_adicional ?? false;
        this.mensagem_adicional_texto =
          response.data[0]?.mensagem_adicional_texto ?? null;
        this.impressao_pedido_80_colunas =
          response.data[0]?.impressao_pedido_80_colunas ?? false;
        this.relatorios_html = response.data[0]?.relatorios_html ?? false;
        this.importar_obs_pessoa =
          response.data[0]?.importar_obs_pessoa ?? false;
        this.referencia_danfe = response.data[0]?.referencia_danfe ?? false;
        this.desconto_incondicional =
          response.data[0]?.desconto_incondicional ?? false;
        this.ordenacao_produtos_prevenda =
          response.data[0]?.ordenacao_produtos_prevenda ?? false;
        this.impressao_paisagem_danfe =
          response.data[0]?.impressao_paisagem_danfe ?? false;
        this.verifica_estoque = response.data[0]?.verifica_estoque ?? false;
        this.custo_operacional = response.data[0]?.custo_operacional ?? false;
        this.custo_percentual = response.data[0]?.custo_percentual ?? false;
        this.nao_gerar_impressao =
          response.data[0]?.nao_gerar_impressao ?? false;
        this.utilizar_sempre_descricao =
          response.data[0]?.utilizar_sempre_descricao ?? false;
        this.nao_converter_cfop = response.data[0]?.nao_converter_cfop ?? false;
        this.imprimir_codigo_barra_orcamento =
          response.data[0]?.imprimir_codigo_barra_orcamento ?? false;
        this.vendedor_obrigatorio =
          response.data[0]?.vendedor_obrigatorio ?? false;
        this.impressao_orcamento_80_colunas =
          response.data[0]?.impressao_orcamento_80_colunas ?? false;
        this.impressao_ncm = response.data[0]?.impressao_ncm ?? false;
        this.impressao_recibo_80_colunas =
          response.data[0]?.impressao_recibo_80_colunas ?? false;
        this.informacao_nfse = response.data[0]?.informacao_nfse ?? null;
        this.impressao_emitente_documentos =
          response.data[0]?.impressao_emitente_documentos ?? null;
      } catch (error) {
        console.log(error);
      }
    },
    async salvarConfigs() {
      this.addLoadingToButton("submit_nova_configs");
      try {
        await this.$http.post("/configuracoesgerais/create", {
          impressao_retrato_prevenda: this.retratoImpressao ?? false,
          impressao_margem_nfce: this.margemNFCe ?? false,
          imprimir_vias_prevenda: this.viasPrevenda ?? false,
          ultimo_nsu: this.ultimo_nsu ?? null,
          verifica_cadastro_pessoa: this.verifica_cadastro_pessoa ?? null,
          juros_simples: this.juros_simples ?? null,
          taxa_diaria: this.taxa_diaria ?? null,
          multa: this.multa ?? null,
          descontar_icms_base_piscofins:
            this.descontar_icms_base_piscofins ?? null,
          integracao_pos: this.integracao_pos ?? null,
          vendas_cartao_conta_receber: this.vendas_cartao_conta_receber ?? null,
          impressao_os_80_colunas: this.impressao_os_80_colunas ?? null,
          casas_decimal_preco: this.casas_decimal_preco ?? null,
          casas_decimal_quantidade: this.casas_decimal_quantidade ?? null,
          impressao_msg_os: this.impressao_msg_os ?? null,
          solicita_senha_adm: this.solicita_senha_adm ?? null,
          abertura_caixa: this.abertura_caixa,
          calcular_st_desconto_icms: this.calcular_st_desconto_icms ?? null,
          duplicata_nfce: this.duplicata_nfce ?? null,
          gerar_conta_pagar: this.gerar_conta_pagar ?? null,
          utilizar_sempre_referencia: this.utilizar_sempre_referencia ?? null,
          considerar_qtd_tributavel: this.considerar_qtd_tributavel ?? null,
          importar_desc_complementar: this.importar_desc_complementar ?? false,
          somar_frete_despesas_baseicms:
            this.somar_frete_despesas_baseicms ?? false,
          sync_vendizap: this.sync_vendizap ?? false,
          considerar_obs: this.considerar_obs ?? false,
          mensagem_adicional: this.mensagem_adicional ?? false,
          mensagem_adicional_texto: this.mensagem_adicional_texto ?? null,
          impressao_pedido_80_colunas:
            this.impressao_pedido_80_colunas ?? false,
          relatorios_html: this.relatorios_html ?? false,
          importar_obs_pessoa: this.importar_obs_pessoa ?? false,
          referencia_danfe: this.referencia_danfe ?? false,
          desconto_incondicional: this.desconto_incondicional ?? false,
          ordenacao_produtos_prevenda:
            this.ordenacao_produtos_prevenda ?? false,
          impressao_paisagem_danfe: this.impressao_paisagem_danfe ?? false,
          verifica_estoque: this.verifica_estoque ?? false,
          custo_operacional: this.custo_operacional ?? false,
          custo_percentual: this.custo_percentual ?? false,
          nao_gerar_impressao: this.nao_gerar_impressao ?? false,
          utilizar_sempre_descricao: this.utilizar_sempre_descricao ?? false,
          nao_converter_cfop: this.nao_converter_cfop ?? false,
          imprimir_codigo_barra_orcamento:
            this.imprimir_codigo_barra_orcamento ?? false,
          vendedor_obrigatorio: this.vendedor_obrigatorio ?? false,
          impressao_orcamento_80_colunas:
            this.impressao_orcamento_80_colunas ?? false,
          impressao_ncm: this.impressao_ncm ?? false,
          impressao_recibo_80_colunas:
            this.impressao_recibo_80_colunas ?? false,
          informacao_nfse: this.informacao_nfse ?? false,
          impressao_emitente_documentos:
            this.impressao_emitente_documentos ?? false,
        });
        this.$toast.success("Configuração foi salva com sucesso!");
      } catch (error) {
        console.log(error);
      }
      this.removeLoadingToButton("submit_nova_configs");
    },
  },
  mounted() {
    if (this.itsblocked) {
      this.$router.push({ name: "assinatura" });
    }
    this.getConfigurationList();
    this.contas_marcadas_color = localStorage.getItem("contas_marcadas_color");
  },
  watch: {
    contas_marcadas_color: {
      handler(color) {
        localStorage.setItem("contas_marcadas_color", color);
      },
    },
  },
};
</script>
