import produtosLista from "@/modules/Produtos/pages/Index";
import produtosCreate from "@/modules/Produtos/pages/Create";
import produtosUpdate from "@/modules/Produtos/pages/Update";
import terminalConsulta from "@/modules/Produtos/pages/TerminalConsulta";

export default [
  {
    name: "produtosLista",
    path: "/produtos",
    component: produtosLista,
    meta: { title: "Produtos" },
  },
  {
    name: "produtosCreate",
    path: "/produtos/novo",
    component: produtosCreate,
    meta: { title: "Produtos" },
  },
  {
    name: "produtosUpdate",
    path: "/produtos/:id_produto/editar",
    component: produtosUpdate,
    meta: { title: "Produtos" },
  },
  {
    name: "terminalConsulta",
    path: "/terminal",
    component: terminalConsulta,
    meta: { title: "Terminal de Consulta" },
  },
];
