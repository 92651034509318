<template>
  <div class="d-flex flex-column flex-root">
    <div class="d-flex flex-row flex-column-fluid page">
      <div id="kt_content" class="content d-flex flex-column flex-column-fluid">
        <div class="d-flex flex-column-fluid">
          <div class="container-fluid">
            <transition name="fade">
              <Loading v-if="isLoading" />
            </transition>
            <div class="d-lg-flex flex-row-fluid">
              <KTAside v-if="asideEnabled"></KTAside>
              <div class="content-wrapper flex-row-fluid">
                <transition name="fade-in-up">
                  <router-view />
                </transition>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import KTAside from "@/view/layout/aside/Aside.vue";
import HtmlClass from "@/core/services/htmlclass.service";
import Loading from "@/components/Loading";
import {
  ADD_BODY_CLASSNAME,
  REMOVE_BODY_CLASSNAME,
} from "@/core/services/store/htmlclass.module.js";

export default {
  name: "LayoutImpressao",
  components: {
    KTAside,
    Loading,
  },
  beforeMount() {
    this.$store.dispatch(ADD_BODY_CLASSNAME, "page-loading");
    HtmlClass.init(this.layoutConfig());
  },
  mounted() {
    setTimeout(() => {
      this.$store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading");
    }, 2000);
  },
  methods: {},
  computed: {
    ...mapGetters(["pageTitle", "layoutConfig"]),
    ...mapState(["loading"]),
    contentFluid() {
      return "fluid";
    },
    asideEnabled() {
      return !!this.layoutConfig("aside.self.display");
    },
    isLoading() {
      return this.loading.loading;
    },
  },
};
</script>
