import { required } from "vuelidate/lib/validators";
import moment from "moment";

export default class FinanceiroData {
  descricao = "";

  valor = 0;

  data = moment().format("YYYY-MM-DD");

  tipo = "";

  id_plano_contas = null;

  id = null;

  id_meio_pagamento = null;

  constructor({
    descricao = "",
    valor = 0,
    data = moment().format("YYYY-MM-DD"),
    tipo = "",
    id_plano_contas = null,
    id = null,
    id_meio_pagamento = null,
  }) {
    this.descricao = descricao;
    this.valor = valor;
    this.data = data;
    this.tipo = tipo;
    this.id_plano_contas = id_plano_contas;
    this.id = id;
    this.id_meio_pagamento = id_meio_pagamento;
  }
}

export const FinanceiroDataRequired = {
  descricao: {
    required,
  },
  valor: {
    required,
  },
  tipo: {
    required,
  },
};
