import { required } from "vuelidate/lib/validators";

export default class ProdutoData {
  produtos = [];
  price = 0;
  quantity = 0;
  totalPrice = 0;
  discount = 0;
  active = true;
  currentIndex = 0;
  informacoesAdicionais = '';
  totais = [];
  constructor(
    produtos = [],
    price = 0,
    quantity = 0,
    totalPrice = 0,
    discount = 0,
    active = true,
    currentIndex = 0,
    informacoesAdicionais = '',
    totais = [],
  ) {
    this.produtos = produtos;
    this.price = price;
    this.quantity = quantity;
    this.totalPrice = totalPrice;
    this.discount = discount;
    this.active = active;
    this.currentIndex = currentIndex;
    this.informacoesAdicionais = informacoesAdicionais;
    this.totais = totais;
  }
}

export const ProdutoDataRequired = {
  produtos: {
    required,
  },
};
