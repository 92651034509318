<template>
  <div class="container-fluid">
    <div class="card-body">
      <b-card>
        <b-tabs pills :horizontal="windowWidth < 768" :vertical="windowWidth > 768"
          :nav-wrapper-class="(windowWidth > 768 ? 'w-25' : 'w-100')" fill>
          <Cliente />
          <Produto />
          <Transportador />
          <Faturamento />
          <Informacoes />
        </b-tabs>
      </b-card>
      <div class="card-footer">
        <div class="row">
          <div class="col-lg-12" style="text-align: center">
            <div v-if="canSaveOrcamento">
              <div class="form mb-5">
                <button v-if="store.currentTab !== 0" class="btn btn-secondary font-weight-bold mr-2"
                  @click="handlePrevious">
                  Anterior
                </button>
                <button v-if="store.isUpdate && store.currentTab !== 4" class="btn btn-primary font-weight-bold mr-4"
                  @click="handleNext">
                  Proximo
                </button>
                <button ref="submit_novo_pedido" class="btn btn-primary font-weight-bold" @click="salvarPedido">
                  Salvar
                </button>
                <button ref="submit_previa_nfe" class="btn btn-clean font-weight-bold"
                  @click="confirmCancel('pedidos')">
                  Cancelar
                </button>
              </div>
            </div>
            <div v-else>
              <button :disabled="store.currentTab === 0" class="btn btn-secondary font-weight-bold mr-4"
                @click="handlePrevious">
                Anterior
              </button>
              <button class="btn btn-primary font-weight-bold" @click="handleNext">
                Proximo
              </button>
              <button ref="submit_previa_nfe" class="btn btn-clean font-weight-bold" @click="confirmCancel('pedidos')">
                Cancelar
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState, mapActions } from "vuex";
import "vue-select/dist/vue-select.css";
import { validationMixin } from "vuelidate";
import FormatMixin from "../../Application/mixins/FormatMixin";
import { SistemaMixin, ValidacaoMixin } from "@/modules/Application";
import { mask } from "vue-the-mask";
import PagamentosMixin from "../../Pdv/mixins/PagamentosMixin";
import InstallmentsMixin from "../../Application/mixins/InstallmentsMixin";
import Cliente from "./componentes/ClienteTab.vue";
import Produto from "./componentes/ProdutoTab.vue";
import Informacoes from "./componentes/Informacoes.vue";
import Transportador from "./componentes/TransportadorTab.vue";
import Faturamento from "./componentes/FaturamentoTab.vue";
import { store } from "../storePedidos";

export default {
  name: "app-pedido-form",
  props: {
    notaCompra: {
      type: Object,
      default: () => { },
    },
  },
  directives: {
    mask,
  },
  data() {
    return {
      store,
      pedidoId: null,
      windowWidth: window.innerWidth,
    };
  },
  mixins: [
    validationMixin,
    FormatMixin,
    SistemaMixin,
    ValidacaoMixin,
    PagamentosMixin,
    InstallmentsMixin,
  ],
  components: {
    Cliente,
    Produto,
    Transportador,
    Faturamento,
    Informacoes,
  },
  watch: {
    total(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.valorTotal = newValue;
      }
    },
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
    });
    if (this.$route.params.pedidoData.pedidoId) {
      this.id = this.$route.params.pedidoData.pedidoId;
    }
    this.getDataInit();

    this.store.currentTab = 0;

    if (!this.isAuthenticated) {
      this.$router.push({ name: "login" });
    }
  },
  methods: {
    ...mapActions(["setLoading"]),
    async getDataInit() {
      if (this.$route.params.pedidoData.pedidoId) {
        // this.setLoading(true);
      }
    },
    onResize() {
      this.windowWidth = window.innerWidth;
    },
    handleSair() {
      this.$swal
        .fire({
          icon: "warning",
          title: "Cancelar o lançamento em andamento?",
          showCancelButton: true,
          confirmButtonText: `Sim`,
          cancelButtonText: `Não`,
          confirmButtonColor: "#f64e60",
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            this.$router.push({ name: "pedidos" });
          }
        });
    },
    async salvarPedido() {
      this.addLoadingToButton('submit_novo_pedido')
      if (Object.keys(this.$route.params).length !== 0) {
        const { id_pedido } = this.$route.params.pedidoData;

        const data = this.formatDataToSendToOrcamentoApi(
          this.store.formCliente,
          this.store.formProduto,
          this.store.formFaturamento,
          this.store.formInformacoes,
          this.store.formTransportador,
        );
        try {
          await this.$http.post("/pedido/update", {
            id_pedido,
            ...data,
          });
          this.$toast.success("Pedido editado com sucesso!");
          this.$router.push({ name: "pedidos" });
        } catch (error) {
          this.$toast.error("Erro ao editar Pedido!");
        }
      } else {
        try {
          const data = this.formatDataToSendToOrcamentoApi(
            this.store.formCliente,
            this.store.formProduto,
            this.store.formFaturamento,
            this.store.formInformacoes,
            this.store.formTransportador,
          );
          if (this.pedidoId) {
            await this.$http.post("/pedido/update", {
              id_pedido: this.pedidoId,
              ...data,
            });
          } else {
            const { id_orcamento } = await this.$http.post("/pedido/create", data);
            this.pedidoId = id_orcamento;
          }
          this.$toast.success("Pedido salvo com sucesso!");
          this.$router.push({ name: "pedidos" });
        } catch (error) {
          this.$toast.error("Erro ao salvar Pedido");
          console.log(error);
        }
      }
      this.removeLoadingToButton('submit_novo_pedido')
    },
    handleNext() {
      if (this.store.currentTab === 6) return;
      this.store.currentTab++;
    },
    handlePrevious() {
      if (this.store.currentTab === 0) return;
      this.store.currentTab--;
    },
  },
  computed: {
    ...mapGetters([
      "isAuthenticated",
      "breadcrumbs",
      "pageTitle",
      "layoutConfig",
      "nutsDigital",
    ]),
    ...mapState(["loading"]),
    isLoading() {
      return this.loading.loading;
    },
    loaderEnabled() {
      return !/false/.test(this.layoutConfig("loader.type"));
    },
    contentFluid() {
      return this.layoutConfig("content.width") === "fluid";
    },
    toolbarDisplay() {
      return true;
    },
    subheaderDisplay() {
      return !!this.layoutConfig("subheader.display");
    },
    displaySubheaderOnDashboard() {
      return this.$route.name !== "dashboard";
    },
    canSaveOrcamento() {
      return this.store.currentTab === 4 || this.store.isUpdate;
    },
  },
  created() {
    this.store.currentTab = 0
    this.store.isUpdate = false;
    if (Object.keys(this.$route.params).length !== 0) {
      const { id_pedido } = this.$route.params.pedidoData;
      this.pedidoId = id_pedido;
      this.store.isUpdate = true;
    }
  },
};
</script>
