<template>
  <b-tab title="Viagem" :active="isTheCurrentTab" :disabled="!isTheCurrentTab" class="mt-5">
    <b-row>
      <b-col md="3">
        <b-form-group id="uf_carregamento" label-for="uf_carregamento" label="UF de carregamento">
          <VueSelect ref="uf_carregamento" :filterable="false" @search="(search, loading) => searchUf(search, 1)"
            :options="ufsSearched1" v-model="form.uf_carregamento" :class="{ hasErrors: ufError }"
            @input="() => ufError = false">
            <template slot="no-options">
              Informe a UF
            </template>
            <template slot="option" slot-scope="option">
              <div class="d-center">
                {{ option.sigla }}
              </div>
            </template>
            <template slot="selected-option" slot-scope="option">
              <div class="selected d-center">
                {{ option.sigla }}
              </div>
            </template>
          </VueSelect>
        </b-form-group>
      </b-col>

      <b-col>
        <b-form-group id="cidade_carregamento" label-for="cidade_carregamento" label="Cidade de Carregamento">
          <VueSelect ref="cidade_carregamento" @search="onSearchCidades" :filterable="false" :options="cidades"
            v-model="form.cidade_carregamento" :class="{ hasErrors: cidadeError }" @input="() => cidadeError = false">
            <template slot="no-options">
              Informe o nome da cidade
            </template>
            <template slot="option" slot-scope="option">
              <div class="d-center">
                {{ option.cidade }}
              </div>
            </template>
            <template slot="selected-option" slot-scope="option">
              <div class="selected d-center">
                {{ option.cidade }}
              </div>
            </template>
          </VueSelect>
        </b-form-group>
      </b-col>
      <b-col md="3">
        <b-form-group id="uf_descarga" label-for="uf_descarga" label="UF de descarga">
          <VueSelect ref="uf_descarga" :filterable="false" @search="(search, loading) => searchUf(search, 2)"
            :options="ufsSearched2" v-model="form.uf_descarga" :class="{ hasErrors: ufErrorDescarga }"
            @input="() => ufErrorDescarga = false">
            <template slot="no-options">
              Informe a UF
            </template>
            <template slot="option" slot-scope="option">
              <div class="d-center">
                {{ option.sigla }}
              </div>
            </template>
            <template slot="selected-option" slot-scope="option">
              <div class="selected d-center">
                {{ option.sigla }}
              </div>
            </template>
          </VueSelect>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="4">
        <b-form-group id="cep_carregamento" label-for="cep_carregamento" label="CEP de carregamento">
          <b-form-input maxlength="8" v-model="form.cep_carregamento" autofocus
            :state="validateState('cep_carregamento')" />
        </b-form-group>
      </b-col>
      <b-col md="4">
        <b-form-group id="cep_descarga" label-for="cep_descarga" label="CEP de descarga">
          <b-form-input maxlength="8" v-model="form.cep_descarga" autofocus :state="validateState('cep_descarga')" />
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="8">
        <b-form-group id="percurso" label-for="percurso" label="Percurso">
          <VueSelect ref="percurso" multiple :filterable="false" @search="(search, loading) => searchUf(search, 3)"
            :options="ufsSearched3" v-model="form.percurso" :class="{ hasErrors: ufErrorDescarga }" @input="(value) => {
              handleSelectPercurso(value)
              ufErrorDescarga = false
            }">
            <template slot="no-options">
              Informe a UF
            </template>
            <template slot="option" slot-scope="option">
              <div class="d-center">
                {{ option.sigla }}
              </div>
            </template>
            <template slot="selected-option" slot-scope="option">
              <div class="selected d-center">
                {{ option.sigla }}
              </div>
            </template>
          </VueSelect>
        </b-form-group>
      </b-col>
    </b-row>
  </b-tab>
</template>

<script>
import { mapGetters, mapState, mapActions } from "vuex";
import { validationMixin } from "vuelidate";
import { SistemaMixin } from "@/modules/Application";
import { store } from "../../storeLogistica";
import VueSelect from "vue-select";
import _ from "lodash";

export default {
  name: "app-mdfe-form-viagemTab",
  components: {
    VueSelect,
  },
  directives: {
  },
  data() {
    return {
      store,
      form: {
        uf_carregamento: null,
        uf_descarga: null,
        cidade_carregamento: null,
        cep_carregamento: null,
        cep_descarga: null,
        percurso: [],
      },
      ufError: false,
      ufErrorDescarga: false,
      cidadeError: false,
      ufs: [],
      ufsSearched1: [],
      ufsSearched2: [],
      ufsSearched3: [],
      cidades: [],
    }
  },
  mixins: [
    validationMixin,
    SistemaMixin,
  ],
  methods: {
    ...mapActions(["setLoading"]),
    async getDataInit() {
      const [{ data: ufsData }] = await Promise.all([
        this.$http.post('/sistema/get-uf'),
      ])
      this.ufs = ufsData;
      this.ufsSearched1 = this.ufs;
      this.ufsSearched2 = this.ufs;
      this.ufsSearched3 = this.ufs;
      const mdfeData = this.$route.params?.mdfeData?.mdfe;
      if (mdfeData) {
        this.form.uf_carregamento = ufsData.find(uf => uf.sigla === mdfeData.uf_carregamento);
        this.form.uf_descarga = ufsData.find(uf => uf.sigla === mdfeData.uf_descarga);
        this.form.cep_carregamento = mdfeData.cep_carregamento;
        this.form.cep_descarga = mdfeData.cep_descarga;
        const { data } = await this.$http.post('/sistema/get-cidade-by-uf',
          { uf: this.form.uf_carregamento.sigla, cidade: mdfeData.cidade_carregamento.cidade }
        );
        this.form.cidade_carregamento = data[0];
        this.form.percurso = mdfeData.ufsPercurso ? ufsData.filter(uf => mdfeData.ufsPercurso.includes(uf.sigla)) : []
        this.ufsSearched3 = this.ufs
          .filter(uf => {
            const onlyUfsSelected = this.form?.percurso ? this.form.percurso.map(item => item.sigla) : []
            return !onlyUfsSelected.includes(uf.sigla)
          })
      }
    },
    searchUf(search, ufNumber) {
      if (ufNumber === 3) {
        this[`ufsSearched${ufNumber}`] = this.ufs
          .filter(uf => uf.sigla.toLowerCase().includes(search.toLowerCase()))
          .filter(uf => {
            const onlyUfsSelected = this.form?.percurso ? this.form.percurso.map(item => item.sigla) : []
            return !onlyUfsSelected.includes(uf.sigla)
          })
      } else {
        this[`ufsSearched${ufNumber}`] = this.ufs.filter(uf => uf.sigla.toLowerCase().includes(search.toLowerCase()))
      }
    },
    handleSelectPercurso(value) {
      this.ufsSearched3 = this.ufs.filter(uf => {
        const onlyUfsSelected = value.map(item => item.sigla)
        return !onlyUfsSelected.includes(uf.sigla)
      })
    },
    onSearchCidades(search, loading) {
      if (!this.form.uf_carregamento) {
        this.$toast.error('Informe primeiro o UF');
        return;
      }
      if (search.length) {
        loading(true);
        this.searchCidades(loading, search, this);
      }
    },
    searchCidades: _.debounce(async (loading, cidade, vm) => {
      const { data } = await vm.$http.post('/sistema/get-cidade-by-uf', { uf: vm.form.uf_carregamento.sigla, cidade });
      vm.cidades = data
      if (loading) loading(false);
    }, 350),
    validateState(field) {
      return !!this.form[field];
    }
  },
  created() {
    this.getDataInit();
  },
  computed: {
    ...mapGetters([
      "isAuthenticated",
      "breadcrumbs",
      "pageTitle",
      "layoutConfig"
    ]),
    ...mapState(["loading"]),
    isLoading() {
      return this.loading.loading;
    },
    isTheCurrentTab() {
      return store.currentTab === 3
    }
  },
  watch: {
    "store.currentTab"(newValue, oldValue) {
      if (newValue !== oldValue && newValue === 4 && oldValue < newValue) {
        if (!this.form.uf_carregamento) {
          this.$toast.error('UF de carregamento obrigatória!')
          this.ufError = true;
          this.store.currentTab = oldValue;
        }
        if (!this.form.cidade_carregamento) {
          this.$toast.error('Cidade de carregamento obrigatória!')
          this.cidadeError = true;
          this.store.currentTab = oldValue;
        }
        if (!this.form.uf_descarga) {
          this.$toast.error('UF de carregamento obrigatória!')
          this.ufErrorDescarga = true;
          this.store.currentTab = oldValue;
        }
        if (!this.form.cep_carregamento) {
          this.$toast.error('CEP de carregamento obrigatório!')
          this.store.currentTab = oldValue;
        }
        if (!this.form.cep_descarga) {
          this.$toast.error('CEP de descarga obrigatório!')
          this.store.currentTab = oldValue;
        }
        this.store.formData = {
          ...this.store.formData,
          cidade_carregamento: {
            cidade: this.form.cidade_carregamento.cidade,
            id_cidade: this.form.cidade_carregamento.id_cidade
          },
          uf_carregamento: this.form.uf_carregamento.sigla,
          uf_descarga: this.form.uf_descarga.sigla,
          cep_carregamento: this.form.cep_carregamento,
          cep_descarga: this.form.cep_descarga,
          ufsPercurso: this.form.percurso.map(uf => uf.sigla),
        }
      }
    },
  }
}

</script>

<style></style>