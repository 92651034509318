<template>
  <div class="container-fluid">
    <div class="card-body">
      <b-card>
        <b-tabs pills :horizontal="windowWidth < 768" :vertical="windowWidth > 768"
          :nav-wrapper-class="(windowWidth > 768 ? 'w-25' : 'w-100')" fill>
          <DadosGerais />
          <Objeto />
          <Problema />
          <Produto />
          <Servicos />
          <Pagamento />
          <Observacoes />
          <Anexos />
        </b-tabs>
      </b-card>
      <div class="card-footer">
        <div class="row">
          <div class="col-lg-12" style="text-align: center">
            <div v-if="canSaveOrdem">
              <div class="form mb-5">
                <button v-if="store.currentTab !== 0" class="btn btn-secondary font-weight-bold mr-2" @click="handlePrevious">
                  Anterior
                </button>
                <button v-if="store.isUpdate && store.currentTab !== 7" class="btn btn-primary font-weight-bold mr-4" @click="handleNext">
                  Próximo
                </button>
                <button ref="submit_nova_os" class="btn btn-primary font-weight-bold" @click="salvarOrdemServico">
                  Salvar
                </button>
                <button ref="submit_previa_nfe" class="btn btn-clean font-weight-bold"
                  @click="confirmCancel('ordensServicos')">
                  Cancelar
                </button>
              </div>
            </div>
            <div v-else>
              <button :disabled="store.currentTab === 0" class="btn btn-secondary font-weight-bold mr-4"
                @click="handlePrevious">
                Anterior
              </button>
              <button class="btn btn-primary font-weight-bold" @click="handleNext">
                Próximo
              </button>
              <button ref="submit_previa_nfe" class="btn btn-clean font-weight-bold"
                @click="confirmCancel('ordensServicos')">
                Cancelar
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState, mapActions } from "vuex";
import "vue-select/dist/vue-select.css";
import { validationMixin } from "vuelidate";
import FormatMixin from "../../Application/mixins/FormatMixin";
import { SistemaMixin, ValidacaoMixin } from "@/modules/Application";
import { mask } from "vue-the-mask";
import PagamentosMixin from "../../Pdv/mixins/PagamentosMixin";
import InstallmentsMixin from "../../Application/mixins/InstallmentsMixin";
import DadosGerais from "./componentes/DadosGeraisTab.vue";
import Objeto from "./componentes/ObjetoTab.vue";
import Produto from "./componentes/ProdutoTab.vue";
import Servicos from "./componentes/ServicosTab.vue";
import Pagamento from "./componentes/PagamentoTab.vue";
import Problema from "./componentes/ProblemaTab.vue";
import Anexos from "./componentes/AnexosTab.vue";
import { store } from "../storeOS";
import Observacoes from "./componentes/ObservacoesTab.vue";
import _ from "lodash";
import swal from "sweetalert";

export default {
  name: "app-os-form",
  directives: {
    mask,
  },
  data() {
    return {
      store,
      windowWidth: window.innerWidth,
      formProduto: {},
    };
  },
  mixins: [
    validationMixin,
    FormatMixin,
    SistemaMixin,
    ValidacaoMixin,
    PagamentosMixin,
    InstallmentsMixin,
  ],
  components: {
    DadosGerais,
    Objeto,
    Problema,
    Produto,
    Servicos,
    Pagamento,
    Observacoes,
    Anexos,
  },
  watch: {
    total(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.valorTotal = newValue;
      }
    },
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
    });

    this.getDataInit();

    this.store.currentTab = 0;

    if (!this.isAuthenticated) {
      this.$router.push({ name: "login" });
    }
  },
  methods: {
    ...mapActions(["setLoading"]),
    async getDataInit() {
      if (this.$route.params.nfeData.nfeId) {
        this.setLoading(true);
      }
    },
    resetStore() {
      this.store.formGerais = {};
      this.store.formObjeto = {};
      this.store.formProblema = {};
      this.store.formProduto = {};
      this.store.formServicos = {};
      this.store.formPagamentos = {};
      this.store.formObservacoes = {};
      this.store.formAnexos = {};
    },
    onResize() {
      this.windowWidth = window.innerWidth;
    },

    handleSair() {
      this.$swal
        .fire({
          icon: "warning",
          title: "Cancelar o lançamento em andamento?",
          showCancelButton: true,
          confirmButtonText: `Sim`,
          cancelButtonText: `Não`,
          confirmButtonColor: "#f64e60",
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            this.$router.push({ name: "comprasLista" });
          }
        });
    },
    async salvarOrdemServico() {
      this.addLoadingToButton('submit_nova_os');
      const dataToSend = this.formatDataToSendToOSApi();
      try {
        if (Object.keys(this.$route.params).length !== 0) {
          const { id_ordem_servico } = this.$route.params.osData;
          dataToSend.append("id_ordem_servico", id_ordem_servico);
          console.log(dataToSend);
          await this.$http.post('/ordemservico/update', dataToSend)
          this.$toast.success('Ordem de serviço atualizada com sucesso!')
          this.resetStore();
          this.$router.push({ name: "ordensServicos" })
        } else {
          console.log(dataToSend);
          await this.$http.post('/ordemservico/create', dataToSend)
          this.$toast.success('Ordem de serviço salva com sucesso!')
          this.resetStore();
          this.$router.push({ name: "ordensServicos" })
        }
      } catch (error) {
        console.log(error);
      }
      this.removeLoadingToButton('submit_nova_os');
    },

    imprimirPDF(id) {
      swal({
        title: "Deseja visualizar a prévia da NFe?",
        text: "Este documento ainda não tem validade fiscal, somente após ser autorizado!",
        icon: "info",
        buttons: {
          cancel: {
            text: "Cancelar",
            value: "cancel",
            visible: true,
            closeModal: true,
          },
          confirm: {
            text: "OK",
            visible: true,
            closeModal: false,
          },
        },
        dangerMode: false,
        closeModal: false,
        closeOnClickOutside: false,
      }).then(async (value) => {
        if (value === "cancel") {
          swal.close();
          swal.stopLoading();
          return;
        }
        await this.$http
          .post("/nfe/print-nfe", {
            id: id,
          })
          .then((result) => {
            const resultData = result["data"]["danfe-url"];
            if (resultData) {
              window.open(result["data"]["danfe-url"], "_blank");
            }
            swal.stopLoading();
            swal.close();
            this.$toast.success("PDF gerado com sucesso!");
          })
          .catch(() => {
            swal.stopLoading();
            swal.close();
          });
      });
    },
    handleNext() {
      if (this.store.currentTab === 7) return;
      this.store.currentTab++;
    },
    handlePrevious() {
      if (this.store.currentTab === 0) return;
      this.store.currentTab--;
    },
    checkNutsDigital() {
      if (!this.nutsDigital) {
        return "Recurso disponível para quem possui a Nuts Digital, realize o seu cadastro";
      }

      return "Os boletos serão enviados para o e-mail do cliente, conforme está lançado na NFe";
    },
  },
  computed: {
    ...mapGetters([
      "isAuthenticated",
      "breadcrumbs",
      "pageTitle",
      "layoutConfig",
      "nutsDigital",
    ]),
    ...mapState(["loading"]),
    isLoading() {
      return this.loading.loading;
    },
    loaderEnabled() {
      return !/false/.test(this.layoutConfig("loader.type"));
    },
    contentFluid() {
      return this.layoutConfig("content.width") === "fluid";
    },
    toolbarDisplay() {
      return true;
    },
    subheaderDisplay() {
      return !!this.layoutConfig("subheader.display");
    },
    displaySubheaderOnDashboard() {
      return this.$route.name !== "dashboard";
    },
    canSaveOrdem() {
      return this.store.currentTab === 7 || this.store.isUpdate;
    },
  },
  created() {
    this.store.dataFetching.servicos = true;
    this.store.dataFetching.produtos = true;
    this.store.isUpdate = false;
    if (Object.keys(this.$route.params).length !== 0) {
      this.store.isUpdate = true;
    }
  },
};
</script>

<style>
@media (max-width: 991.98px) {
  .container {
    padding: 0
  }

  .card-body-container {
    padding: 0rem;
  }

  .card-body {
    padding: 1rem;
  }
}

.action-buttons {
  display: flex;
}

.cardHeader {
  display: flex;
  max-width: 11rem;
  justify-content: center;
  background-color: white;
  margin-bottom: 5px;
  border-radius: 14px;
  padding: 0.5rem;
  margin-left: auto;
}

.busca-produto .vs__search {
  font-weight: 400;
  font-size: 1.45rem !important;
  margin-bottom: 0.5rem !important;
  line-height: 1.2 !important;
  text-transform: uppercase !important;
}

.busca-produto .vs__dropdown-toggle {
  border: 0px !important;
}

.busca-produto .vs__actions {
  display: none;
}

.busca-produto .vs__selected-options {
  max-width: 100%;
  flex-wrap: initial;
}

.busca-produto .vs__selected {
  text-align: left !important;
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.busca-cliente .vs__search {
  font-size: 18px;
}

.busca-cliente .vs__dropdown-toggle {
  color: var(--primary-color);
  background-color: #fff;
  border: 2px solid #dedede;
  height: 40px;
}

.busca-cliente .vs--open .vs__dropdown-toggle {
  background-color: #fff;
  border: 2px solid var(--primary-color);
}

.busca-cliente .vs__actions {
  display: none;
}

.busca-cliente .vs__dropdown-option--highlight,
.vs__dropdown-menu li:hover,
.vs__dropdown-menu li:focus {
  background-color: var(--primary-color)5e !important;
  color: var(--primary-color) !important;
}

.busca-cliente .vs__dropdown-menu {
  color: var(--primary-color) !important;
}

.busca-cliente .vs__selected-options {
  max-width: 100%;
  flex-wrap: initial;
}

.busca-cliente .vs__selected {
  text-align: left !important;
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.remove-pgto {
  font-weight: bold;
  font-size: 18px;
  color: #4f4f4f;
}

.add-pgto {
  font-weight: bold;
  font-size: 22px;
  color: var(--primary-color);
  float: right;
}

.input-quantidade {
  font-size: 1.75rem;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
  background: transparent;
  border: transparent;
  color: #fff;
  padding: 0px;
  margin-top: -10px;
  margin-bottom: 0px;
  text-align: right;
}

.input-quantidade:focus {
  box-shadow: 0 0 0 0;
  border: 0 none;
  outline: 0;
  border: 0px;
  background-color: transparent;
  color: #fff;
}

.card {
  border: 0;
}

h4 {
  color: #6d747c;
  /* font-weight: 900; */
}

.btn-finalizar-venda {
  background-color: #eee5ff !important;
  font-size: 1.25rem !important;
  text-align: center !important;
  cursor: pointer;
}

.card-total-right p,
.card-total-left p {
  color: #fff !important;
}

.card-header h5,
.card-header h3 {
  margin-bottom: 0rem;
}

.card-header .form-control {
  height: auto;
  margin-top: 0px;
}

.card-header .busca-produto {
  margin-top: 2px;
}


.card-titulo-itens .card-body-container {
  padding: 0px 0 !important;
}

.card-itens .table tr th {
  padding: 5px;
  font-size: 12px;
}

.card-itens .table tr td,
.card-total-right p,
.card-total-left p {
  font-size: 12px;
}

.tableItensCompra {
  max-height: 300px !important;
  height: calc(100vh - 395px) !important;
}

.card-total-left,
.card-total-right {
  height: 80px !important;
}

.btn.btn-secondary {
  color: var(--primary-color);
  background-color: #fff;
  border-color: #fff;
}
</style>
