<template>
  <div class="card-total"></div>
</template>

<script>
export default {
  name: "pdv-card-total",
  props: {
    valor: {
      default: 0
    }
  }
};
</script>

<style>
.card-total .card-body {
  padding: 0 1.25rem 0 1.25rem;
}
.card-total {
  position: static;
  bottom: 0;
  width: 100%;
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  background: #fff;
}

.card-total-left {
  background-color: var(--color-primary);
  border-color: var(--color-secondary);
  color: #fff;
  border-radius: 0px;
  border-bottom-left-radius: 20px !important;
  padding: 0px;
}

.card-total-right {
  background-color: #17b55f;
  border-color: #17b55f;
  color: #fff;
  border-radius: 0px;
  align-items: flex-end;
  border-bottom-right-radius: 20px !important;
  padding: 0px;
}

.card-total-actions {
  background-color: #fff;
  border-color: #fff;
  border-radius: 0px;
  align-items: flex-end;
  border-bottom-right-radius: 20px !important;
  padding: 0px;
}

.card-total .card-title {
  margin-bottom: 0;
  font-size: 1.75rem;
}
</style>
