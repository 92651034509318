import { required } from "vuelidate/lib/validators";

export default class ParcelamentoData {
  descricao = null;
  tipo_repeticao = null;
  id_meio_pagamento = null;
  vezes = null;
  visualizar = true;
  visualizar_app = true;
  informar_cliente = false;
  movimento_caixa = false;
  constructor({
    descricao = null,
    tipo_repeticao = null,
    id_meio_pagamento = null,
    vezes = null,
    visualizar = true,
    visualizar_app = true,
    informar_cliente = false,
    movimento_caixa = false,
  }) {
    this.descricao = descricao;
    this.tipo_repeticao = tipo_repeticao;
    this.id_meio_pagamento = id_meio_pagamento;
    this.vezes = vezes;
    this.visualizar = visualizar;
    this.visualizar_app = visualizar_app;
    this.informar_cliente = informar_cliente;
    this.movimento_caixa = movimento_caixa;
  }
}

export const ParcelamentoDataRequired = {
  descricao: {
    required,
  },
};
