import identificadoresLista from "@/modules/Identificadores/pages/Index";
import identificadoresNovo from "@/modules/Identificadores/pages/Create";
import identificadoresEditar from "@/modules/Identificadores/pages/Update";

export default [
  {
    name: "identificadoresLista",
    path: "/identificadores",
    component: identificadoresLista,
    meta: { title: "Identificadores" },
  },
  {
    name: "identificadoresNovo",
    path: "/identificadores/novo",
    component: identificadoresNovo,
    meta: { title: "Identificadores" },
  },
  {
    name: "identificadoresEditar",
    path: "/identificadores/:id/editar",
    component: identificadoresEditar,
    meta: { title: "Identificadores" },
  },
];
