import { required } from "vuelidate/lib/validators";

export default class TecnicoData {
  nome = "";
  status = true;
  id_tecnico = null;

  constructor({ nome = "", status = true, id_tecnico = null }) {
    this.nome = nome;
    this.status = status;
    this.id_tecnico = id_tecnico;
  }
}

export const TecnicoDataRequired = {
  nome: {
    required,
  },
};
