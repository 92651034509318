<template>
  <div style="position: relative">
    <FilterFields
      :fields="[
        {
          type: 'text',
          label: 'Número',
          field: 'numero',
        },
        {
          type: 'text',
          label: 'Fornecedor',
          field: 'fornecedor',
        },
        {
          type: 'date',
          label: 'Data Solicitação',
          field: 'data_solicitacao',
        },
        {
          type: 'date',
          label: 'Criação',
          field: 'created_at',
        },
        {
          type: 'select',
          label: 'Status',
          field: 'status',
          options: [
            'Em Aberto',
            'Em Andamento',
            'Cancelado',
            'Atendida',
            'Encaminhada',
          ],
        },
      ]"
    />

    <b-dropdown
      id="menu"
      variant="dark"
      v-b-tooltip.right="'Mais ações'"
      v-if="actionsStore.selectedRows.length > 0"
      style="position: absolute; top: 0; left: 50px"
    >
      <template #button-content>
        <Menu :size="18" />
      </template>
      <b-dropdown-item
        v-b-modal.atualizarStatus
      >
        Atualizar Status
      </b-dropdown-item>
      <b-dropdown-item
        v-b-modal.duplicarOrdem
        :disabled="actionsStore.selectedRows.length > 1"
      >
        Duplicar Ordem Compra
      </b-dropdown-item>
    </b-dropdown>

    <b-modal
      id="duplicarOrdem"
      title="Deseja duplicar o Ordem selecionada?"
      centered
      size="xs"
    >
      <label
        >Pedido a ser duplicado
        <p>Número: {{ actionsStore.selectedRows[0]?.numero }}</p>
      </label>
      <template #modal-footer="{ ok, cancel }">
        <div>
          <b-button
            variant="primary"
            @click="duplicar"
            ref="doubleOrdem"
            class="mr-5"
          >
            Confirmar
          </b-button>
          <b-button
            @click="
              {
                actionsStore.updateTable = !actionsStore.updateTable;
                actionsStore.selectedRows = [];
                cancel();
              }
            "
          >
            Cancelar
          </b-button>
        </div>
      </template>
    </b-modal>

    <b-modal
      id="faturarPedido"
      title="Deseja faturar o Pedido?"
      centered
      size="xs"
    >
      <label>
        <p>
          <strong
            >Pedido a ser faturado número:
            {{ actionsStore.selectedRows[0]?.numero }}
          </strong>
        </p>
        <p v-if="actionsStore.selectedRows[0]?.pagamentos">
          Forma Pagamento:
          {{ formataPagamento(actionsStore.selectedRows[0]?.pagamentos) }}
        </p>
      </label>
      <b-row>
        <b-col md="6">
          <p class="font-weight-bolder text-dark mb-2">Movimentar estoque</p>
          <span class="switch switch-icon">
            <label v-b-tooltip.hover.right="'Não/Sim'">
              <input type="checkbox" v-model="movimentar_estoque" />
              <span></span>
            </label>
          </span>
        </b-col>
        <b-col md="6">
          <p class="font-weight-bolder text-dark mb-2">Gerar financeiro</p>
          <span class="switch switch-icon">
            <label v-b-tooltip.hover.right="'Não/Sim'">
              <input type="checkbox" v-model="gerar_financeiro" />
              <span></span>
            </label>
          </span>
        </b-col>
        <b-col md="12" style="margin-top: 10px">
          <b-form-group
            id="data_faturamento"
            label-for="data_faturamento"
            label="Data do Faturamento"
          >
            <b-form-input v-model="data_faturamento" type="date" required />
          </b-form-group>
        </b-col>
      </b-row>
      <template #modal-footer="{ ok, cancel }">
        <div>
          <b-button
            variant="primary"
            @click="faturarPedido"
            ref="faturarPedido"
            class="mr-5"
            :disabled="permitido_faturar"
          >
            Confirmar
          </b-button>
          <b-button
            @click="
              {
                actionsStore.updateTable = !actionsStore.updateTable;
                actionsStore.selectedRows = [];
                cancel();
              }
            "
          >
            Cancelar
          </b-button>
        </div>
      </template>
    </b-modal>

    <TableApp
      titulo="Ordem de Compra"
      acaoBtn="/#/ordem-compra-nova"
      textoBtn="Nova Ordem"
      api-url="/ordemcompra/lista"
      :fields="fields"
      classFilterColumn="numero"
      ref="ordemList"
      :perPage="10"
      :multiselect="true"
    >
      <template slot="customRow" scope="props">
        <span slot="numero" class="pl-0 pt-8" v-if="props.field == 'numero'">
          <div class="d-flex align-items-center">
            <div class="font-weight-bold text-muted">
              {{ props.rowData.numero }}
            </div>
          </div>
        </span>
        <span slot="fornecedor" class="pl-0 pt-8" v-if="props.field == 'fornecedor'">
          <div class="d-flex align-items-center">
            <div class="font-weight-bold text-muted">
              {{ props.rowData.fornecedor }}
            </div>
          </div>
        </span>
        <span
          slot="status"
          v-if="props.field == 'status'"
          :class="statusOrdemCompraClass(props.rowData.status)"
        >
          {{ statusOrdemCompraLabel(props.rowData.status) }}
        </span>
        <span
          slot="data_solicitacao"
          class="pl-0 pt-8"
          v-if="props.field == 'data_solicitacao'"
        >
          <div
            class="d-flex align-items-center justify-content-end font-weight-bold text-muted"
          >
            {{ props.rowData.data_solicitacao | moment("DD/MM/YYYY") }}
          </div>
        </span>
        <span
          slot="created_at"
          class="pl-0 pt-8"
          v-if="props.field == 'created_at'"
        >
          <div
            class="d-flex align-items-center justify-content-end font-weight-bold text-muted"
          >
            {{ props.rowData.created_at | moment("DD/MM/YYYY") }}
          </div>
        </span>
        <span
          slot="valorTotal"
          v-if="props.field == 'valorTotal'"
          class="text-dark-75 font-weight-bolder d-block font-size-lg"
        >
          R$
          {{ numberToReal(props.rowData.valorTotal, 2) }}
        </span>

        <span
          slot="actions"
          v-if="props.field == 'actions'"
          class="text-dark-75 font-weight-bolder d-block font-size-lg"
        >
          <div class="action-buttons">
            <button
              @click="imprimirOrdem(props.rowData)"
              v-b-tooltip.righttop="'Imprimir Ordem'"
              class="btn btn-sm btn-info btn-hover-info btn-icon mr-2"
            >
              <PdfIcon :size="22" />
            </button>
            <button
              @click="editarOrdem(props.rowData)"
              v-if="props.rowData.status !== 4 && props.rowData.status !== 3"
              class="btn btn-sm btn-info btn-hover-info btn-icon mr-2"
              v-b-tooltip.righttop="'Editar Ordem'"
            >
              <PencilIcon :size="20" />
            </button>
            <button
              @click="shareToWhatsapp(props.rowData)"
              v-b-tooltip.righttop="
                'Encaminhar PDF para o whatsapp do fornecedor'
              "
              class="btn btn-sm btn-success btn-hover-success btn-icon mr-2"
            >
              <Whatsapp :size="20" />
            </button>
            <button
              @click="doCopy(props.rowData, props.rowData.id_ordem_compra)"
              class="btn btn-sm btn-success btn-hover-success btn-icon mr-2"
              v-b-tooltip.hover.bottom="'Copiar Link'"
              placement="bottom"
            >
              <Link :size="16" />
            </button>
            <button
              @click="excluir(props.rowData)"
              v-if="props.rowData.status == 1"
              v-b-tooltip.hover.bottom="'Excluir Ordem'"
              class="btn btn-sm btn-danger btn-hover-danger btn-icon mr-2"
            >
              <TrashCanIcon :size="20" />
            </button>
          </div>
        </span>
      </template>
    </TableApp>

    <b-modal id="atualizarStatus" title="Atualizar status" size="md" centered>
      <div class="mx-auto">
        <b-form class="form">
          <b-row>
            <b-col>
              <b-form-group label="Status" label-for="status">
                <b-form-select v-model="statusToUpdate" placeholder="">
                  <b-form-select-option
                    v-for="status in ordemStatus"
                    :key="status.id"
                    :value="status.id"
                  >
                    {{ status.descricao }}
                  </b-form-select-option>
                </b-form-select>
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>
      </div>
      <template #modal-footer="{ ok, cancel }">
        <b-button @click="cancel()"> Cancelar </b-button>
        <b-button variant="primary" ref="enviar_correcao" @click="salvarStatus">
          Salvar
        </b-button>
      </template>
    </b-modal>

    <SendToWppModal
      :data="dataToWhatsapp"
      :openModal="wppOpenModal"
      @handleOpenModal="(openModal) => (wppOpenModal = openModal)"
      module="ordemCompra"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import TableApp from "@/components/Table/Table2";
import swal from "sweetalert";
import { SistemaMixin } from "@/modules/Application";
import flatPickr from "flatpickr";
import "flatpickr/dist/flatpickr.css";
import Whatsapp from "vue-material-design-icons/Whatsapp.vue";
import PdfIcon from "vue-material-design-icons/FilePdfBox.vue";
import PencilIcon from "vue-material-design-icons/Pencil.vue";
import TrashCanIcon from "vue-material-design-icons/TrashCan.vue";
import Link from "vue-material-design-icons/LinkPlus.vue";

import "flatpickr/dist/themes/material_blue.css";
import { Portuguese } from "flatpickr/dist/l10n/pt.js";
import FilterFields from "@/components/FilterFields";
import SendToWppModal from "@/components/SendToWppModal";
import Menu from "vue-material-design-icons/Menu";
import { actionsStore } from "@/components/Table/actionsStore";

export default {
  name: "pedidos",
  mixins: [SistemaMixin],
  components: {
    TableApp,
    PdfIcon,
    PencilIcon,
    Link,
    Whatsapp,
    TrashCanIcon,
    FilterFields,
    SendToWppModal,
    Menu,
  },
  data() {
    return {
      actionsStore,
      components: {
        flatPickr,
      },
      ordemStatus: null,
      statusToUpdate: null,
      wppOpenModal: false,
      dataToWhatsapp: null,
      fields: [
        {
          label: "Número",
          field: "numero",
          type: "text",
          slot: true,
          sortable: false,
        },
        {
          label: "Fornecedor",
          field: "fornecedor",
          type: "text",
          slot: true,
          sortable: false,
        },
        {
          label: "Data da Solicitação",
          field: "data_solicitacao",
          sortable: false,
          slot: true,
          type: "date",
          dateInputFormat: "yyyy-MM-dd HH:mm:ss",
          dateOutputFormat: "dd-MM-yyyy",
        },
        {
          label: "Data de Criação",
          field: "created_at",
          sortable: false,
          slot: true,
          type: "date",
          dateInputFormat: "yyyy-MM-dd HH:mm:ss",
          dateOutputFormat: "dd-MM-yyyy",
        },
        {
          label: "Valor",
          field: "valorTotal",
          sortable: false,
          slot: true,
          type: "text",
        },
        {
          label: "Status",
          field: "status",
          sortable: true,
          slot: true,
        },
        {
          label: "Ações",
          field: "actions",
          sortable: false,
          slot: true,
        },
      ],
      gerar_financeiro: true,
      movimentar_estoque: true,
      permitido_faturar: false,
      data_faturamento: null,
    };
  },
  async created() {
    try {
      const { data } = await this.$http.post("/ordemcompra/status");
      this.ordemStatus = data;
    } catch (error) {
      console.log(error);
    }
  },
  methods: {
    async duplicar() {
      const { id_ordem_compra } = this.actionsStore?.selectedRows[0];

      this.addLoadingToButton("doubleOrdem");

      try {
        await this.$http.post("/ordemcompra/duplicar", {
          id_ordem_compra,
        });
        this.actionsStore.updateTable = !this.actionsStore.updateTable;
        this.$toast.success("Ordem duplicada com sucesso!");
        this.$refs.ordemList.refresh();
        this.$bvModal.hide("duplicarOrdem");
      } catch (error) {
        console.log(error);
      }
      this.removeLoadingToButton("doubleOrcamento");
    },
    doCopy(data) {
      swal({
        title: "Deseja enviar o link para o cliente?",
        text: "Ao confirmar será copiado para a area de transferencia o link",
        icon: "info",
        buttons: {
          cancel: {
            text: "Cancelar",
            value: "cancel",
            visible: true,
            closeModal: true,
          },
          confirm: {
            text: "OK",
            visible: true,
            closeModal: false,
          },
        },
        dangerMode: false,
        closeModal: false,
        closeOnClickOutside: false,
      }).then(async (value) => {
        if (value === "cancel") {
          swal.close();
          swal.stopLoading();
          return;
        }
        swal.close();
        swal.stopLoading();
        this.$copyText(data.link);
        this.$toast.success("Link copiado com sucesso!");
      });
    },
    salvarStatus() {
      this.$bvModal.hide("atualizarStatus");
      swal({
        title: "Deseja atualizar o(s) status?",
        icon: "info",
        buttons: {
          cancel: {
            text: "Cancelar",
            value: "cancel",
            visible: true,
            closeModal: true,
          },
          confirm: {
            text: "OK",
            visible: true,
            closeModal: false,
          },
        },
        dangerMode: false,
        closeModal: false,
        closeOnClickOutside: false,
      }).then(async (value) => {
        if (value === "cancel") {
          swal.close();
          swal.stopLoading();
          return;
        }
        await this.$http.post("/ordemcompra/update-status", {
          ordens: actionsStore.selectedRows.map(({ id_ordem_compra }) => ({
            id_ordem_compra,
          })),
          status: this.statusToUpdate,
        });
        this.statusToUpdate = null;
        swal.close();
        swal.stopLoading();
        this.$refs.ordemList.refresh();
      });
    },
    imprimirOrdem(data) {
      swal({
        title: "Deseja imprimir a ordem?",
        text: "Aguarde, pois será gerado o PDF automaticamente!",
        icon: "info",
        buttons: {
          cancel: {
            text: "Cancelar",
            value: "cancel",
            visible: true,
            closeModal: true,
          },
          confirm: {
            text: "OK",
            visible: true,
            closeModal: false,
          },
        },
        dangerMode: false,
        closeModal: false,
        closeOnClickOutside: false,
      }).then(async (value) => {
        if (value === "cancel") {
          swal.close();
          swal.stopLoading();
          return;
        }
        await this.$http
          .post("/ordemcompra/print", {
            id_ordem_compra: data.id_ordem_compra,
          })
          .then(({ data }) => {
            if (data.url_ordem_compra) {
              window.open(data.url_ordem_compra, "_blank");
            }
            swal.stopLoading();
            swal.close();
            this.$toast.success("Ordem impressa com sucesso!");
          })
          .catch(() => {
            swal.stopLoading();
            swal.close();
          });
      });
    },
    async shareToWhatsapp(data) {
      this.dataToWhatsapp = data;
      this.wppOpenModal = true;
    },

    async editarOrdem(data) {
      swal({
        title: "Deseja editar a Ordem?",
        text: "Você ira ser direcionado para a pagina de edição aguarde...",
        icon: "info",
        buttons: {
          cancel: {
            text: "Cancelar",
            value: "cancel",
            visible: true,
            closeModal: true,
          },
          confirm: {
            text: "OK",
            visible: true,
            closeModal: false,
          },
        },
        dangerMode: false,
        closeModal: false,
        closeOnClickOutside: false,
      }).then(async (value) => {
        if (value === "cancel") {
          swal.close();
          swal.stopLoading();
          return;
        }

        await this.$http
          .post("/ordemcompra/find", {
            id_ordem_compra: data.id_ordem_compra,
          })
          .then((response) => {
            swal.stopLoading();
            swal.close();
            this.$toast.info("Você será redirecionado agora");
            this.$router.push({
              name: "ordemCompraNova",
              params: {
                ordemData: {
                  ...response.data,
                  ordemId: response.data.id_ordem_compra,
                },
              },
            });
          })
          .catch(() => {
            swal.stopLoading();
            this.$toast.error("Erro ao solicitar o endpoint do Pedido");
            swal.close();
          });
      });
    },

    excluir(data) {
      this.$swal
        .fire({
          icon: "warning",
          title: "Excluir a ordem de compra?",
          showCancelButton: true,
          confirmButtonText: `Sim`,
          cancelButtonText: `Não`,
          confirmButtonColor: "#f64e60",
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            await this.$http.post("/ordemcompra/delete", {
              id_ordem_compra: data.id_ordem_compra,
            });
            this.$refs.ordemList.refresh();
            this.$toast.success("Ordem excluída!");
          }
        });
    },
    formataPagamento(pagamento) {
      const pagamentos = JSON.parse(pagamento);
      var pagamentoFormatado = "";

      pagamentos.forEach((pag) => {
        if (pag.value == 0) {
          pagamentoFormatado = "Pedido sem valores para realizar faturamento.";
          this.permitido_faturar = true;
          return;
        }

        if (pag.value != 0 && pag.paymentMode.description) {
          this.permitido_faturar = false;
          pagamentoFormatado +=
            pag.paymentMode.description +
            ": R$ " +
            this.numberToReal(pag.value, 2);
        }
      });
      return pagamentoFormatado;
    },
  },

  dateRangeFilter(data, filterString) {
    let dateRange = filterString.split("to");
    let startDate = Date.parse(dateRange[0]);
    let endDate = Date.parse(dateRange[1]);
    return (data =
      Date.parse(data) >= startDate && Date.parse(data) <= endDate);
  },
  computed: {
    ...mapGetters(["layoutConfig", "itsblocked"]),
    config() {
      return this.layoutConfig();
    },
  },
  async mounted() {
    if (this.itsblocked) {
      this.$router.push({ name: "assinatura" });
    }

    let inputs = ['input[placeholder="Data"]'];
    inputs.forEach(function (input) {
      flatPickr(input, {
        dateFormat: "Y-m-d",
        allowInput: true,
        locale: Portuguese,
      });
    });
  },
};
</script>

<style>
.flatpickr-months .flatpickr-month,
.flatpickr-current-month .flatpickr-monthDropdown-months,
.flatpickr-weekdays,
span.flatpickr-weekday {
  background: var(--primary-color) !important;
}

.headerColumn {
  text-align: start;
}

.flatpickr-calendar.arrowTop:before {
  border-bottom-color: var(--primary-color) !important;
  color: var(--primary-color) !important;
}
</style>
