<template>
  <b-row>
    <b-col col>
      <b-card title="Venda em Andamento" class="card-titulo-itens" />
      <b-card class="card-itens tableItensPdv" id="tableItensPdv">
        <table
          class="table table-head-custom table-head-bg table-vertical-center"
        >
          <thead>
            <tr>
              <th></th>
              <th class="pl-0 font-weight-bold text-muted pl-7">
                Produto<br />Código de Barras
              </th>
              <th class="text-right pr-0 font-weight-bold text-muted">
                Un. Medida
              </th>
              <th class="text-right pr-0 font-weight-bold text-muted">
                Quant.
              </th>
              <th class="text-right pr-0 font-weight-bold text-muted">
                Unitário
              </th>
              <th class="text-right pr-0 font-weight-bold text-muted pr-7">
                Total
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(i, index) in items"
              :key="'item' + index"
              :class="
                !i.active
                  ? 'font-weight-boldest font-size-lg cancelado'
                  : 'font-weight-boldest font-size-lg'
              "
            >
              <td>{{ parseFloat(index + 1) }}</td>
              <td class="pl-0">
                <a
                  href="#"
                  class="text-dark font-weight-bolder text-hover-primary mb-1 font-size-lg pl-7"
                >
                  {{ i.product.descricao }}
                </a>
                <span class="text-muted font-weight-bold d-block pl-7">
                  {{ i.product.cod_barras }}
                </span>
              </td>
              <td class="text-right pr-0 pt-7">
                {{ i.product.unidade_medida.un }}
              </td>
              <td class="text-right pr-0 pt-7">
                <a
                  v-if="i.active"
                  href="javascript:;"
                  @click="substract(index)"
                  class="btn btn-xs btn-light-success btn-icon mr-2"
                >
                  <i class="ki ki-minus icon-xs"></i>
                </a>
                <span class="mr-2 font-weight-bolder">{{
                  valorReal(i.quantity, true)
                }}</span>
                <a
                  href="javascript:;"
                  v-if="i.active"
                  @click="add(index)"
                  class="btn btn-xs btn-light-success btn-icon"
                >
                  <i class="ki ki-plus icon-xs"></i>
                </a>
              </td>
              <td class="text-right pr-0 pt-7">
                R$ {{ valorReal(i.product.preco, true) }}
              </td>
              <td class="text-right pr-0 pr-7 pt-7">
                R$ {{ valorReal(i.totalPrice) }}
              </td>
            </tr>
          </tbody>
        </table>
      </b-card>
      <blockquote />
    </b-col>
  </b-row>
</template>

<script>
import FormatMixin from "@/modules/Application/mixins/FormatMixin";

export default {
  name: "pdv-card-itens",
  mixins: [FormatMixin],
  data() {
    return {
      fields: [
        {
          key: "currentIndex + parseFloat(1)",
          label: "",
          sortable: true
        },
        {
          key: "product.description",
          label: "Descrição do Produto \n Código de Barras",
          sortable: true
        },
        {
          key: "quantity",
          label: "Qtd.",
          sortable: true
        },
        {
          key: "totalPrice",
          label: "Total",
          sortable: true
        }
      ],
      calculoTotal: 0
    };
  },
  props: {
    items: {
      default: []
    },
    total: {
      default: 0
    },
    acrescimo: {
      default: 0
    },
    desconto: {
      default: 0
    },
    valorPago: {
      default: 0
    },
    addQtd: {
      type: Function
    },
    substractQtd: {
      type: Function
    }
  },
  methods: {
    add(index) {
      this.addQtd(index);
    },
    substract(index) {
      this.substractQtd(index);
    },
  },
  watch: {
    total(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.calculoTotal = newValue;
      }
    }
  }
};
</script>

<style>
.card-titulo-itens .card-title {
  margin-bottom: 0 !important;
  font-weight: 700;
  font-size: 1.75rem;
  padding: 0.75rem;
}

.card-titulo-itens .card-body {
  padding: 0.45rem;
}

.card-titulo-itens {
  background-color: #fff;
  border: 0px;
  text-align: center;
  margin-bottom: 0px;
  padding: 0;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}

.card-itens .card-body {
  padding: 0;
}

.table {
  border-bottom: solid 0px #dedede;
  margin-bottom: 0px;
}

.table-striped tbody tr:nth-of-type(odd) {
  /* background-color: rgb(229 245 234);*/
  background-color: var(--color-primary-table-striped);
}

.pdv-itens {
  padding-right: 0px;
}

.cancelado {
  text-decoration: line-through;
  font-style: italic;
  color: #80808099 !important;
}

.cancelado td {
  color: #80808099 !important;
}

.tableItensPdv {
  padding: 10px;
  overflow-y: auto;
  max-height: calc(100vh - 297px);
  height: calc(100vh - 297px);
  border: 0px;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
}

.tableItensPdv th,
.tableItensPdv td {
  padding: 8px 16px;
}

.tableItensPdv .table tr th {
  border-bottom: 2px solid var(--color-primary);
}

.tableItensPdv th {
  position: sticky;
  top: 0;
  background: #fff;
  /*color: #1f8a50;*/
}

::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-thumb {
  background: transparent;
  border-radius: 1px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 200px rgb(229 245 234 / 19%);
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: var(--color-secondary);
}

.card-total-venda {
  background: transparent;
  border: 0px;
  top: 30px;
}

.card-total-venda .card-body {
  padding: 0rem 1rem 0rem 1rem;
}

.card-total-left {
  background-color: var(--color-primary);
  border-color: var(--color-primary);
  color: #fff;
  border-radius: 0px;
  border-bottom-left-radius: 20px !important;
  height: 90px;
  width: 101%;
  display: table;
  text-align: left;
}

.card-total-left .card-body {
  display: table-cell;
  vertical-align: middle;
}

.card-total-right {
  background-color: var(--color-secondary);
  border-color: var(--color-secondary);
  color: #fff;
  border-radius: 0px;
  align-items: flex-end;
  border-bottom-right-radius: 20px !important;
  height: 90px;
  width: 100%;
  display: table;
  text-align: center;
  text-align: right;
}

.card-total-right .card-body {
  display: table-cell;
  vertical-align: middle;
}

.card-total-right p,
.card-total-left p {
  margin-bottom: 0;
  font-size: 0.8rem;
}

.card-total-right h5,
.card-total-left h5 {
  font-size: 1.75rem !important;
  margin-bottom: 0;
}

.tableItensPdv tbody tr {
  border-top-width: 1.5px;
  border-top-style: dashed;
  border-top-color: var(--color-primary);
}

blockquote {
  margin: 0 auto;
  position: relative;
  text-align: center;
  z-index: 1;
}

blockquote:after {
  content: "";
  display: block;
  position: absolute;
  width: 100%;
  height: 29px;
  right: 0;
  bottom: -24px;
  left: 0;
  padding-top: 10px;
  background: linear-gradient(-45deg, transparent 70%, white 75%),
    linear-gradient(45deg, transparent 70%, white 75%);
  background-repeat: repeat-x;
  background-size: 25px 20px, 25px 20px;
  background-position: center;
}
</style>
