import { required } from "vuelidate/lib/validators";

export default class ProdutoData {
  produtos = [];
  subTotal = 0;
  constructor(produtos = [], subTotal = 0) {
    this.produtos = produtos;
    this.subTotal = subTotal;
  }
}

export const ProdutoDataRequired = {
  produtos: {
    required,
  },
};
