<template>
  <TableApp
    titulo="Usuários"
    acaoBtn="/#/usuarios/novo"
    textoBtn="Novo"
    api-url="/usuario"
    :fields="fields"
    classFilterColumn="descricao"
    ref="usuarios"
    :perPage="10"
  >
    <template slot="customRow" scope="props">
      <span slot="nome" v-if="props.field == 'nome'">
        <span class="pl-0 pt-8">
          <div class="d-flex align-items-center">
            <div>
              <a
                href="#"
                class="
                  text-dark-75
                  font-weight-bolder
                  text-hover-primary
                  mb-1
                  font-size-lg
                "
                >{{ props.rowData.nome }}</a
              >
              <span class="text-muted font-weight-bold d-block">{{
                props.rowData.usuario
              }}</span>
            </div>
          </div>
        </span>
      </span>
      <span v-if="props.field == 'status'">
        <span
          v-if="props.rowData.status == 'A'"
          class="label label-lg label-inline label-light-success"
          >Ativo</span
        >
        <span v-else class="label label-lg label-inline label-light-danger"
          >Inativo</span
        >
      </span>
      <span v-if="props.field == 'actions' && currentUser.admin && currentUser.id_usuario !== props.rowData.id_usuario">
        <div class="row-actions" style="text-align: right !important">
          <span style="overflow: visible; position: relative; width: 130px">
            <div class="dropdown dropdown-inline">
              <router-link
                class="btn btn-sm btn-info btn-hover-info btn-icon mr-2"
                v-b-tooltip="'Editar'"
                :to="{
                  name: 'usuariosEditar',
                  params: {
                    id: props.rowData.id_usuario,
                  },
                }"
              >
                <Pencil :size="16" title="Editar" />
              </router-link>
              <button
                @click="excluir(props.rowData)"
                class="btn btn-sm btn-danger btn-hover-danger btn-icon mr-2"
                v-b-tooltip="'Excluir'"
                v-if="!props.rowData.admin"
              >
                <TrashCanIcon :size="16" />
              </button>
            </div>
          </span>
        </div>
      </span>
    </template>
  </TableApp>
</template>

<script>
import { mapGetters } from "vuex";
import Pencil from "vue-material-design-icons/Pencil.vue";
import TrashCanIcon from "vue-material-design-icons/TrashCan.vue";
import TableApp from "@/components/Table/Table2";
import { SistemaMixin } from "@/modules/Application";

export default {
  name: "app-usuarios",
  mixins: [SistemaMixin],
  components: {
    Pencil,
    TrashCanIcon,
    TableApp,
  },
  data() {
    return {
      fields: [
        {
          label: "Usuário",
          field: "nome",
          type: "text",
          sortable: true,
          slot: true,
          width: "60%",
          filterOptions: {
            enabled: true,
            placeholder: "Pesquisar Usuário",
          },
        },
        {
          label: "Status",
          field: "status",
          type: "text",
          sortable: false,
          slot: true,
          width: "20%",
          filterOptions: {
            enabled: false,
          },
        },
        {
          label: "",
          field: "actions",
          sortable: false,
          slot: true,
          width: "15%",
          filterOptions: {
            enabled: false,
          },
        },
      ],
    };
  },
  methods: {
    excluir(data) {
      this.$swal
        .fire({
          icon: "warning",
          title: "Excluir o usuário " + data.nome + "?",
          showCancelButton: true,
          confirmButtonText: `Sim`,
          cancelButtonText: `Não`,
          confirmButtonColor: "#f64e60",
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            await this.$http.post("/usuario/delete", {
              id_usuario: data.id_usuario,
            });

            this.$refs.usuarios.refresh();
            this.$toast.success("Usuário excluído!");
          }
        });
    },
  },
  computed: {
    ...mapGetters(["layoutConfig", "itsblocked", "currentUser"]),
    config() {
      return this.layoutConfig();
    },
  },
  mounted() {
    if (this.itsblocked) {
      this.$router.push({ name: "assinatura" });
    }
  },
};
</script>
