<template>
  <TableApp titulo="Contas bancárias" subTitulo="Controle das suas contas bancárias" api-url="bancos"
    acaoBtn="/#/bancos/novo" textoBtn="Novo Banco" :fields="fields" classFilterColumn="bancos" ref="bancos" :perPage="10">
    <template slot="customRow" slot-scope="props">
      <span slot="nome" v-if="props.field == 'nome'">
        <span class="pl-0 pt-8">
          <div class="d-flex align-items-center">
            <div class="symbol symbol-40 symbol-light-primary flex-shrink-0" style="margin-right: 10px"></div>
            <div>
              <a>{{ props.rowData.nome }}</a>
            </div>
          </div>
        </span>
      </span>
      <span slot="saldo" v-if="props.field == 'saldo'">
        <div class="font-weight-bold text-muted">
          R$ {{ numberToReal(props.rowData.saldo, 2) }}
        </div>
      </span>
      <span slot="actions" v-if="props.field == 'actions'">
        <div class="row-actions" style="text-align: right !important">
          <span style="overflow: visible; position: relative; width: 130px">
            <div class="dropdown dropdown-inline">
              <router-link class="btn btn-sm btn-info btn-hover-info btn-icon mr-2" v-b-tooltip.hover.bottom="'Editar'"
                :to="{
                  name: 'bancosEditar',
                  params: {
                    id_banco: props.rowData.id_banco,
                  },
                }">
                <Pencil :size="16" title="Editar" />
              </router-link>
              <router-link class="btn btn-sm btn-success btn-hover-success btn-icon mr-2"
                v-b-tooltip.hover.bottom="'Extrato'" :to="{
                  name: 'bancosMovimentacao',
                  params: {
                    id_banco: props.rowData.id_banco,
                  },
                }">
                <List :size="16" title="Editar" />
              </router-link>
              <button @click="excluirCliente(props.rowData)" class="btn btn-sm btn-danger btn-hover-danger btn-icon mr-2"
                v-b-tooltip.hover.bottom="'Excluir conta Bancária'">
                <TrashCanIcon :size="16" />
              </button>
            </div>
          </span>
        </div>
      </span>
    </template>
  </TableApp>
</template>

<script>
import { mapGetters } from "vuex";
import TableApp from "@/components/Table/Table2";
import TrashCanIcon from "vue-material-design-icons/TrashCan.vue";
import Pencil from "vue-material-design-icons/Pencil.vue";
import List from "vue-material-design-icons/ArrowRightThick.vue";
import { SistemaMixin } from "@/modules/Application";

export default {
  name: "app-lista-banco",
  mixins: [SistemaMixin],
  components: {
    TableApp,
    TrashCanIcon,
    Pencil,
    List,
  },
  data() {
    return {
      fields: [
        {
          label: "Banco",
          field: "nome",
          type: "text",
          slot: true,
          sortable: true,
          width: "15%",
        },
        {
          label: "Saldo",
          field: "saldo",
          type: "text",
          slot: true,
          sortable: true,
          width: "15%",
        },
        {
          label: "Ações",
          field: "actions",
          sortable: false,
          slot: true,
          width: "10%",
          filterOptions: {
            enabled: false,
          },
        },
      ],
    };
  },
  methods: {
    excluirCliente(data) {
      this.getRequestAdmin(async() => {
        this.$swal
          .fire({
            icon: "warning",
            title: "Deseja  deletar o banco " + data.nome + "?",
            showCancelButton: true,
            confirmButtonText: `Sim`,
            cancelButtonText: `Não`,
            confirmButtonColor: "#f64e60",
          })
          .then(async (result) => {
            if (result.isConfirmed) {
              await this.$http.post("/bancos/delete", {
                id_banco: data.id_banco,
              });
              this.$refs.bancos.refresh();
              this.$toast.success("Banco excluído!");
            }
          });
      })
    },
  },
  computed: {
    ...mapGetters(["layoutConfig", "itsblocked"]),
    config() {
      return this.layoutConfig();
    },
  },
  async mounted() {
    if (this.itsblocked) {
      this.$router.push({ name: "assinatura" });
    }
  },
};
</script>
