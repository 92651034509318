<template>
  <div style="position: relative;">
    <TableApp titulo="Serviços" acaoBtn="/#/servicos-novo" textoBtn="Novo serviço" api-url="/servicos" :fields="fields"
      classFilterColumn="discriminacao" ref="servicolist" :perPage="10">
      <template slot="customRow" scope="props">
        <span slot="discriminacao" class="pl-0 pt-8" v-if="props.field == 'discriminacao'">
          <div class="d-flex align-items-center">
            <div class="font-weight-bold text-muted">
              {{ props.rowData.discriminacao }}
            </div>
          </div>
        </span>

        <span slot="valor" class="pl-0 pt-8" v-if="props.field == 'valor'">
          <div class="d-flex align-items-center">
            <div class="font-weight-bold text-muted">
              {{ numberToReal(props.rowData.valor, 2) }}
            </div>
          </div>
        </span>

        <span slot="actions" v-if="props.field == 'actions'">
          <div class="row-actions" style="text-align: right !important">
            <span style="overflow: visible; ">
              <div class="action-buttons">
                <button @click="handleEditServico(props.rowData)" v-b-tooltip.bottom="'Editar servico'"
                  class="btn btn-sm btn-info btn-hover-info btn-icon mr-2">
                  <Pencil :size="16" />
                </button>
                <button @click="handleDeleteServico(props.rowData)" v-b-tooltip.bottom="'Excluir servico'"
                  class="btn btn-sm btn-danger btn-icon mr-2">
                  <TrashCanIcon :size="16" />
                </button>
              </div>
            </span>
          </div>
        </span>
      </template>
    </TableApp>
  </div>
</template>

<script>
import { SistemaMixin } from "@/modules/Application";
import TableApp from "@/components/Table/Table2";
import TrashCanIcon from "vue-material-design-icons/TrashCan.vue";
import Pencil from "vue-material-design-icons/Pencil.vue";
import swal from "sweetalert";
import { actionsStore } from "@/components/Table/actionsStore";

export default {
  name: "servicos",
  mixins: [SistemaMixin],
  components: {
    TableApp,
    TrashCanIcon,
    Pencil,
  },
  data() {
    return {
      actionsStore,
      fields: [
        {
          label: "Descrição",
          field: "discriminacao",
          type: "text",
          slot: true,
          width: '80%',
          sortable: false,
        },
        {
          label: "Valor",
          field: "valor",
          slot: true,
          sortable: false,
        },
        {
          label: "",
          field: "actions",
          sortable: false,
          width: "5%",
          slot: true,
        },
      ],
    }
  },
  created() {
  },
  methods: {
    async handleDeleteServico(servico) {
      this.$swal
        .fire({
          icon: "warning",
          title: "Excluir o serviço " + servico.discriminacao + "?",
          showCancelButton: true,
          confirmButtonText: `Sim`,
          cancelButtonText: `Não`,
          confirmButtonColor: "#f64e60",
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            try {
              this.$http.post('/servicos/delete', {
                id_servico: servico.id_servico,
              })
              this.$toast.success('Serviço excluído com sucesso!')
              this.actionsStore.updateTable = !this.actionsStore.updateTable;
            } catch (error) {
              console.log(error);
            }
          }
        });
    },
    async handleEditServico(data) {
      swal({
        title: "Deseja editar o serviço?",
        text: "Você ira ser direcionado para a pagina de edição aguarde...",
        icon: "info",
        buttons: {
          cancel: {
            text: "Cancelar",
            value: "cancel",
            visible: true,
            closeModal: true,
          },
          confirm: {
            text: "OK",
            visible: true,
            closeModal: false,
          },
        },
        dangerMode: false,
        closeModal: false,
        closeOnClickOutside: false,
      }).then(async (value) => {
        if (value === "cancel") {
          swal.close();
          swal.stopLoading();
          return;
        }

        const responseTributacao = await this.$http.post("/tributacaoservico/find", {
          id_tributacao_servico: data.id_tributacao_servico,
        })

        await this.$http
          .post("/servicos/find", {
            id_servico: data.id_servico,
          })
          .then((response) => {
            swal.stopLoading();
            swal.close();
            this.$toast.info("Você será redirecionado agora");
            this.$router.push({
              name: "novoServicos",
              params: { servicoData: { ...response.data, id_servico: data.id_servico }, tributacaoData: { ...responseTributacao.data } },
            });
          })
          .catch(() => {
            swal.stopLoading();
            this.$toast.error("Erro ao solicitar o endpoint do serviço");
            swal.close();
          });
      });
    }
  },
  computed: {
    config() {
      return this.layoutConfig();
    },
  },
  mounted() {

  },
};
</script>
