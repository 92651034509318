<template>
  <b-modal
    id="variacaoModal"
    :title="`Produto: ${produto?.descricao}`"
    centered
    @shown="handleProdutoVariavel"
  >
    <div v-if="!loading">
      <b-row class="mb-10">
        <b-col>
          <div style="display: flex; align-items: center; gap: 10px">
            <button
              @click="() => (combinacao.quantidade = combinacao.quantidade + 1)"
              class="btn btn-sm btn-secondary font-weight-bold border border-primary"
            >
              <Plus :size="10" />
            </button>
            <h4 class="mb-0">{{ combinacao.quantidade }}</h4>
            <button
              @click="
                () =>
                  (combinacao.quantidade =
                    combinacao.quantidade - 1 < 0
                      ? 0
                      : combinacao.quantidade - 1)
              "
              class="btn btn-sm btn-secondary font-weight-bold border border-primary"
            >
              <Minus :size="10" />
            </button>
            <p class="mb-0 mt-5" style="width: 50px">un</p>
          </div>
        </b-col>
        <b-col style="display: flex; align-items: center">
          <h2 class="mb-0">R$ {{ numberToReal(produto?.preco) }}</h2>
        </b-col>
      </b-row>
      <b-row v-if="!!variacoes">
        <b-col
          v-for="(variacaoLabel, index) in variacoes.labels"
          :key="variacaoLabel"
        >
          <b-form-group :label="variacaoLabel" :label-for="variacaoLabel">
            <b-form-select
              v-model="combinacao[variacaoLabel]"
              @change="() => handleChangeVariacao(index)"
              placeholder=""
            >
              <b-form-select-option
                v-for="variacao in variacoes[variacaoLabel]"
                :key="variacao"
                :value="variacao"
                :disabled="findEstoque(variacaoLabel, variacao, index)"
                :style="{
                  color: findEstoque(variacaoLabel, variacao, index)
                    ? 'grey'
                    : '',
                  backgroundColor: findEstoque(variacaoLabel, variacao, index)
                    ? '#efefef'
                    : '',
                }"
              >
                {{ variacao
                }}{{
                  findEstoque(variacaoLabel, variacao, index)
                    ? " - ESGOTADO"
                    : ""
                }}
              </b-form-select-option>
            </b-form-select>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col> Selecionado: {{ combinacaoSelected }} </b-col>
      </b-row>
    </div>
    <div
      v-else
      style="
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
      "
    >
      <b-spinner
        variant="primary"
        label="Spinning"
        style="width: 3rem; height: 3rem"
      />
    </div>
    <template #modal-footer="{ ok, cancel }">
      <div>
        <b-button
          variant="primary"
          @click="handleAddProdutoVariavel"
          ref="addProdutoVariavel"
          class="mr-5"
        >
          Adicionar
        </b-button>
        <b-button
          @click="
            () => {
              cancel();
            }
          "
        >
          Cancelar
        </b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
import { mapGetters } from "vuex";
import { SistemaMixin } from "@/modules/Application";
import Plus from "vue-material-design-icons/PlusThick.vue";
import Minus from "vue-material-design-icons/MinusThick.vue";

export default {
  name: "VariacaoModal",
  mixins: [SistemaMixin],
  components: {
    Plus,
    Minus,
  },
  props: {
    produto: {
      type: Object,
    },
    addProduto: {
      type: Function,
    },
  },
  data() {
    return {
      loading: true,
      allCombinations: [],
      variacoes: null,
      combinacao: {
        quantidade: 1,
      },
      combinacaoSelected: null,
      foraDeEstoque: false,
    };
  },
  methods: {
    handleAddProdutoVariavel() {
      if (!this.combinacaoSelected) {
        this.$toast.warning("Selecione a variação desejada!");
        return;
      }
      if (this.combinacao.quantidade === 0) {
        this.$toast.warning("Adicione pelo menos uma unidade");
        return;
      }

      this.produto = {
        ...this.produto,
        combinacao: this.combinacao,
        preco: this.formatNumberToFloat(this.produto.preco),
        quantidade: this.formatNumberToFloat(this.produto.quantidade),
      };

      this.$emit("onAddProduto", {
        newPreco: this.produto.preco,
        newQuantidade: this.combinacao.quantidade,
        newTotal:
          this.formatNumberToFloat(this.produto.preco) *
          this.combinacao.quantidade,
        newProduto: this.produto,
      });

      this.produto.descricao = `${this.produto.descricao} - ${this.combinacaoSelected}`;
      this.addProduto(this.produto);
      this.combinacao = { quantidade: 1 };
      this.combinacaoSelected = "";
      this.$bvModal.hide("variacaoModal");
    },
    handleChangeVariacao(index) {
      if (index !== 0 || this.variacoes.labels.length === 1) return;
      const aux = Object.keys(this.combinacao)[index];
      this.combinacao[aux] = null;
    },
    findEstoque(label, option, index) {
      if (this.variacoes.labels.length === 1) {
        const variacaoChosed = this.allCombinations.find(
          (el) => el[label] === option
        );
      }

      if (index === 0) return;

      const otherLabel = Object.keys(this.combinacao).find(
        (el) => el !== label
      );
      const variacaoChosed = this.allCombinations
        .filter((el) => el[otherLabel] === this.combinacao[otherLabel])
        .find((el) => el[label] === option);
      const quantidadeOfChooseElement = this.allCombinations.find(
        (el) =>
          el[label] === this.combinacao[label] &&
          el[otherLabel] === this.combinacao[otherLabel]
      )?.quantidade;

      return false;
    },
    async handleProdutoVariavel() {
      this.loading = true;
      try {
        const { data } = await this.$http.post("/produtos/get-combinacao", {
          id_produto: this.produto.id,
        });
        const combinacao = data.combinacao;
        console.log(combinacao);
        this.allCombinations = combinacao;
        const variacoesLabel = Object.keys(combinacao[0]).filter(
          (element) => element !== "quantidade"
        );
        let variacoes = {
          labels: variacoesLabel,
        };
        variacoesLabel.forEach((variacao) => {
          this.combinacao = {
            [variacao]: null,
            ...this.combinacao,
          };
          const aux = combinacao.reduce((acc, el) => {
            if (acc.includes(el[variacao])) return acc;
            else return [...acc, el[variacao]];
          }, []);
          variacoes = {
            ...variacoes,
            [variacao]: aux,
          };
        });
        this.variacoes = variacoes;
      } catch (error) {
        console.log(error);
      }
      this.loading = false;
    },
  },
  watch: {
    combinacao: {
      handler(newCombinacao, oldCombinacao) {
        const string = [];
        for (const selection in this.combinacao) {
          if (this.combinacao[selection] && selection !== "quantidade")
            string.push(this.combinacao[selection]);
        }
        this.combinacaoSelected =
          string.length > 0 ? string.reverse().join("/") : "";
      },
      deep: true,
    },
  },
  computed: {
    ...mapGetters(["layoutConfig"]),
  },
};
</script>
