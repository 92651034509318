import { required } from "vuelidate/lib/validators";

export default class ObjetoData {
  descricao = "";
  status = true;
  id_objeto = null;
  id_pessoa = null;
  pessoa = {};
  identificadores = [];

  constructor({ 
    descricao = "",
    status = true,
    id_objeto = null,
    id_pessoa = null,
    pessoa = {},
    identificadores = [],
  }) {
    this.descricao = descricao;
    this.status = status;
    this.id_objeto = id_objeto;
    this.id_pessoa = id_pessoa;
    this.pessoa = pessoa;
    this.identificadores = identificadores;
  }
}

export const ObjetoDataRequired = {
  descricao: {
    required,
  },
};
