<template>
  <div style="position: relative">
    <FilterFields
      :fields="[
        {
          type: 'text',
          label: 'Descrição',
          field: 'descricao',
        },
      ]"
    />

    <TableApp
      titulo="Parcelamentos"
      acaoBtn="/#/parcelamentos/novo"
      textoBtn="Novo"
      api-url="/financeiro/lista-formas-pagamento"
      :fields="fields"
      classFilterColumn="parcelamentos"
      ref="parcelamentos"
      :perPage="10"
      :multiselect="true"
    >
      <template slot="customRow" scope="props">
        <span slot="descricao" v-if="props.field == 'descricao'">
          <span class="pl-0 pt-8">
            <div class="d-flex align-items-center">
              <div>
                <a
                  class="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg"
                  >{{ props.rowData.descricao.substr(0, 50) }}</a
                >
                <span
                  class="text-muted font-weight-bold d-block"
                  v-if="props.rowData.desc_complementar"
                  >{{ props.rowData.desc_complementar }}</span
                >
              </div>
            </div>
          </span>
        </span>
        <span slot="vezes" v-if="props.field == 'vezes'">
          <span class="pl-0 pt-8">
            <div class="d-flex align-items-center">
              <div class="font-weight-bold">
                {{ props.rowData.vezes }}
              </div>
            </div>
          </span>
        </span>
        <span slot="status" v-if="props.field == 'status'">
          <span
            v-if="props.rowData.visualizar == true"
            class="label label-lg label-inline label-light-success"
            >Ativo</span
          >
          <span v-else class="label label-lg label-inline label-light-danger"
            >Inativo</span
          >
        </span>
        <span slot="actions" v-if="props.field == 'actions'">
          <div class="row-actions" style="text-align: right !important">
            <span style="overflow: visible; position: relative; width: 130px" v-if="!props.rowData.default">
              <div class="dropdown dropdown-inline">
                <router-link
                  class="btn btn-sm btn-info btn-hover-info btn-icon mr-2"
                  v-b-tooltip.hover.bottom="'Editar'"
                  :to="{
                    name: 'parcelamentosUpdate',
                    params: {
                      id_parcelamento: props.rowData.id,
                    },
                  }"
                >
                  <Pencil :size="16" title="Editar" />
                </router-link>
                <button
                  @click="excluir(props.rowData)"
                  class="btn btn-sm btn-danger btn-hover-danger btn-icon mr-2"
                  v-b-tooltip.hover.bottom="'Excluir'"
                >
                  <TrashCanIcon :size="16" />
                </button>
              </div>
            </span>
          </div>
        </span>
      </template>
    </TableApp>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Pencil from "vue-material-design-icons/Pencil.vue";
import TrashCanIcon from "vue-material-design-icons/TrashCan.vue";
import TableApp from "@/components/Table/Table2";
import { SistemaMixin } from "@/modules/Application";
import { onboardStore } from "../../../onboardStore";
import FilterFields from "@/components/FilterFields";
import { actionsStore } from "@/components/Table/actionsStore";
import { MONEY as settingMoney } from "@/modules/Application";

export default {
  name: "app-formasPagamento",
  mixins: [SistemaMixin],
  components: {
    Pencil,
    TrashCanIcon,
    TableApp,
    FilterFields,
  },
  data() {
    return {
      actionsStore,
      onboardStore,
      newProductDescription: null,
      fields: [
        {
          label: "Descrição",
          field: "descricao",
          type: "text",
          slot: true,
          sortable: true,
          filterOptions: {
            enabled: false,
            placeholder: "Pesquisar descrição",
          },
        },
        {
          label: "Vezes",
          field: "vezes",
          type: "text",
          slot: true,
          sortable: false,
          filterOptions: {
            enabled: false,
            placeholder: "Pesquisar nome",
          },
        },
        {
          label: "Status",
          field: "status",
          sortable: false,
          slot: true,
          filterOptions: {
            enabled: false,
          },
        },
        {
          label: "Ações",
          field: "actions",
          sortable: false,
          slot: true,
          width: "10%",
          filterOptions: {
            enabled: false,
          },
        },
      ],
      percentual: 0,
      configMoney: settingMoney,
    };
  },
  methods: {
    excluir(data) {
      this.getRequestAdmin(() => {
        this.$swal
          .fire({
            icon: "warning",
            title: "Excluir o Parcelamento " + data.descricao + "?",
            showCancelButton: true,
            confirmButtonText: `Sim`,
            cancelButtonText: `Não`,
            confirmButtonColor: "#f64e60",
          })
          .then(async (result) => {
            if (result.isConfirmed) {
              await this.$http.post("/financeiro/delete-parcelamento", {
                id: data.id,
              });
              this.$refs.parcelamentos.refresh();
              this.$toast.success("Parcelamento excluído!");
            }
          });
      });
    },
  },
  computed: {
    ...mapGetters(["layoutConfig", "itsblocked"]),
    config() {
      return this.layoutConfig();
    },
  },
  mounted() {
    if (this.itsblocked) {
      this.$router.push({ name: "assinatura" });
    }
  },
};
</script>
