<template>
  <label class="d-block">
    <slot />
  </label>
</template>

<script>
export default {
  name: "Label",
  props: {
    htmlFor: {
      type: String
    },
    bold: {
      type: Boolean,
      default: () => false
    },
    disabled: {
      type: Boolean,
      default: () => false
    }
  },
  computed: {
    classes() {
      const classes = ["label"];
      if (this.bold) {
        classes.push("label-bold");
      }
      if (this.disabled) {
        classes.push("label-disabled");
      }

      return classes.join(" ");
    }
  }
};
</script>
