import { required } from "vuelidate/lib/validators";

export default class ProdutoData {
  descricao = null;

  ativo = true;

  id_medida = null;

  preco = 0;

  id_tipo = null;

  custo = 0;

  quantidade = 0;

  marca = null;

  estoque_minimo = 0;

  imagem = null;

  cod_barras = null;

  data_ultima_venda = null;

  obs = null;

  id_ncm = null;

  id_cest = null;

  id_cfop = null;

  id_cst_csosn = null;

  id_grupo = null;

  ipi = 0;

  pis = 0;

  cofins = 0;

  id_tributacao = null;

  id = null;

  id_cenq = null;

  id_cstipi = null;

  id_cstpis = null;

  mva = 0;

  id_fcp = null;

  excecaoFiscal = null;

  id_anp = null;

  pesado = false;

  id_cstcofins = null;

  percentual_glp = 0;

  percentual_gnn = 0;

  percentual_gni = 0;

  valor_parti = 0;

  glp_qtd_tributavel = 0;

  id_pessoa = null;

  desc_complementar = null;

  referencia = null;

  id_cfop_nfce = null;

  data_ultima_compra = null;

  numero_ultima_compra = null;

  codigo_beneficio = null;

  id_cst_csosn_contribuinte = null;

  id_tributacao_st = null;

  markup = 0;

  preco_atacado = 0;

  quantidade_atacado = 0;

  peso = 0;

  tipo_embalagem = null;

  perca = false;

  constructor({
    descricao = null,
    ativo = true,
    id_medida = null,
    preco = 0,
    estoque_minimo = null,
    marca = null,
    custo = 0,
    quantidade = 0,
    imagem = null,
    cod_barras = null,
    data_ultima_venda = null,
    obs = null,
    id_ncm = null,
    id_cest = null,
    id_cfop = null,
    id_cst_csosn = null,
    id_tipo = null,
    id_grupo = null,
    ipi = 0,
    pis = 0,
    cofins = 0,
    id_tributacao = null,
    id = null,
    id_cenq = null,
    id_cstipi = null,
    id_cstpis = null,
    mva = 0,
    id_fcp = null,
    excecaoFiscal = null,
    id_anp = null,
    pesado = false,
    id_cstcofins = null,
    percentual_glp = 0,
    percentual_gnn = 0,
    percentual_gni = 0,
    valor_parti = 0,
    glp_qtd_tributavel = 0,
    id_pessoa = null,
    desc_complementar = null,
    referencia = null,
    id_cfop_nfce = null,
    data_ultima_compra = null,
    numero_ultima_compra = null,
    codigo_beneficio = null,
    id_cst_csosn_contribuinte = null,
    id_tributacao_st = null,
    markup = 0,
    preco_atacado = 0,
    quantidade_atacado = 0,
    peso = null,
    tipo_embalagem = null,
    perca = false,
  }) {
    this.descricao = descricao;
    this.ativo = ativo;
    this.id_medida = id_medida;
    this.preco = preco;
    this.custo = custo;
    this.quantidade = quantidade;
    this.imagem = imagem;
    this.marca = marca;
    this.estoque_minimo = estoque_minimo;
    this.cod_barras = cod_barras;
    this.data_ultima_venda = data_ultima_venda;
    this.obs = obs;
    this.id_ncm = id_ncm;
    this.id_cest = id_cest;
    this.id_cfop = id_cfop;
    this.id_tipo = id_tipo;
    this.id_cst_csosn = id_cst_csosn;
    this.id_grupo = id_grupo;
    this.ipi = ipi;
    this.pis = pis;
    this.cofins = cofins;
    this.id_tributacao = id_tributacao;
    this.id = id;
    this.id_cenq = id_cenq;
    this.id_cstipi = id_cstipi;
    this.id_cstpis = id_cstpis;
    this.mva = mva;
    this.id_fcp = id_fcp;
    this.excecaoFiscal = excecaoFiscal;
    this.id_anp = id_anp;
    this.pesado = pesado;
    this.id_cstcofins = id_cstcofins;
    this.percentual_glp = percentual_glp;
    this.percentual_gnn = percentual_gnn;
    this.percentual_gni = percentual_gni;
    this.valor_parti = valor_parti;
    this.glp_qtd_tributavel = glp_qtd_tributavel;
    this.id_pessoa = id_pessoa;
    this.desc_complementar = desc_complementar;
    this.referencia = referencia;
    this.id_cfop_nfce = id_cfop_nfce;
    this.data_ultima_compra = data_ultima_compra;
    this.numero_ultima_compra = numero_ultima_compra;
    this.codigo_beneficio = codigo_beneficio;
    this.id_cst_csosn_contribuinte = id_cst_csosn_contribuinte;
    this.id_tributacao_st = id_tributacao_st;
    this.markup = markup;
    this.preco_atacado = preco_atacado;
    this.quantidade_atacado = quantidade_atacado;
    this.peso = peso;
    this.tipo_embalagem = tipo_embalagem;
    this.perca = perca;
  }
}

export const ProdutoDataRequired = {
  descricao: {
    required,
  },
};
