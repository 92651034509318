<template>
  <div>
    <template v-if="node.children">
      <span
        @click="showModal"
        class="text-primary"
        style="cursor: pointer; margin-right: 10px"
      >
        <b-icon icon="pencil" aria-hidden="true"></b-icon> Editar
      </span>
      <span
        @click="deleteNode"
        class="text-danger"
        style="cursor: pointer; margin-right: 10px"
      >
        <b-icon icon="trash" aria-hidden="true"></b-icon>
      </span>
      <b-button @click="isOpen = !isOpen" variant="link">
        <span>{{ node.descricao }}</span>
        <!-- Exibir Tipo do Plano -->
        <span class="ml-2 text-muted"> ({{ getPlanType(node.tipo) }}) </span>
        <b-icon
          v-if="!node.id_pai"
          :icon="isOpen ? 'chevron-down' : 'chevron-right'"
          aria-hidden="true"
        />
      </b-button>

      <span
        v-if="!node.id_pai"
        @click="addChild"
        class="text-success"
        style="cursor: pointer; margin-left: 10px"
      >
        <b-icon icon="plus" aria-hidden="true"></b-icon> Adicionar Filho
      </span>

      <b-collapse :visible="isOpen">
        <div style="margin-left: 20px">
          <TreeNode
            v-for="(child, index) in node.children"
            :key="index"
            :node="child"
            @add-child="$emit('add-child', child)"
            @delete-node="$emit('delete-node', child)"
          />
        </div>
      </b-collapse>
    </template>
    <template v-else>
      <span
        @click="showModal"
        class="text-primary"
        style="cursor: pointer; margin-right: 10px"
      >
        <b-icon icon="pencil" aria-hidden="true"></b-icon> Editar
      </span>
      <span>{{ node.descricao }}</span>
    </template>

    <b-modal v-model="modalVisible" title="Editar Descrição" centered size="lg">
      <b-row>
        <b-col md="10">
          <b-form-group id="descricao" label="Descrição">
            <b-form-input v-model="editedName" maxlength="50"></b-form-input>
          </b-form-group>
        </b-col>
      </b-row>

      <template #modal-footer="{ ok, cancel }">
        <div>
          <b-button
            variant="primary"
            @click="saveEdit"
            ref="saveEdit"
            class="mr-5"
          >
            Salvar
          </b-button>
          <b-button
            @click="
              {
                cancel();
              }
            "
          >
            Cancelar
          </b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { SistemaMixin } from "@/modules/Application";

export default {
  name: "TreeNode",
  mixins: [SistemaMixin],
  props: {
    node: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isOpen: false,
      modalVisible: false,
      editedName: this.node.descricao,
    };
  },
  methods: {
    showModal() {
      this.editedName = this.node.descricao;
      this.modalVisible = true;
    },
    async saveEdit() {
      this.addLoadingToButton("saveEdit");
      this.node.descricao = this.editedName;

      await this.$http.post("/financeiro/update-plano", this.node);
      this.$toast.success("Plano atualizado com sucesso!");
      this.modalVisible = false;

      this.removeLoadingToButton("saveEdit");
    },
    addChild() {
      this.$emit("add-child", this.node);
    },
    deleteNode() {
      console.log(this.node);
      this.$emit("delete-node", this.node);
    },
    getPlanType(type) {
      if (type === 2) {
        return "Saída";
      }

      if (type === 1) {
        return "Despesas";
      }

      return "Tipo desconhecido";
    },
  },
};
</script>