import revendaLista from "@/modules/Revenda/pages/Index";
import listaClientes from "@/modules/Revenda/pages/ListaClientes";
import revendaEditar from "@/modules/Revenda/pages/Form";

export default [
  {
    name: "revendasLista",
    path: "/revendas",
    component: revendaLista,
    meta: { title: "Revendas" },
  },
  {
    name: "revendasListaClientes",
    path: "/revenda/:id_revenda/lista",
    component: listaClientes,
    meta: { title: "Revendas" },
  },
  {
    name: "revendasEditar",
    path: "/revenda/:id_empresa/editar",
    component: revendaEditar,
    meta: { title: "Revendas" },
  },
];
