import tecnicosLista from "@/modules/Tecnicos/pages/Index";
import tecnicosNovo from "@/modules/Tecnicos/pages/Create";
import tecnicosEditar from "@/modules/Tecnicos/pages/Update";

export default [
  {
    name: "tecnicosLista",
    path: "/tecnicos",
    component: tecnicosLista,
    meta: { title: "Técnicos" },
  },
  {
    name: "tecnicosNovo",
    path: "/tecnicos/novo",
    component: tecnicosNovo,
    meta: { title: "Técnicos" },
  },
  {
    name: "tecnicosEditar",
    path: "/tecnicos/:id/editar",
    component: tecnicosEditar,
    meta: { title: "Técnicos" },
  },
];
