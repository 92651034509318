<template>
  <div class="container-fluid p-0">
    <template>
      <Keypress
        key-event="keyup"
        :prevent-default="true"
        :key-code="teclasEnum.F2"
        @success="buscarProduto"
      />
    </template>
    <b-row>
      <b-col>
        <b-row align-v="center">
          <b-col md="12">
            <CardBuscaTerminal>
              <template v-slot:formulario>
                <Select
                  placeholder="Informe o código, descrição, código de barras ou referência do produto"
                  labelBusca="descricao"
                  v-model="produto"
                  ref="buscarProduto"
                  :options="produtos"
                  :change="setProduto"
                  :search="onSearch"
                  focus
                >
                  <template slot="options" slot-scope="props">
                    <div
                      v-if="!props.data.isKit"
                      class="selected"
                      style="text-transform: uppercase"
                    >
                      {{ props.data.codigo }} - {{ props.data.descricao }}
                      <br />
                      R$
                      {{
                        numberToReal(parseFloat(props.data.preco), "preco") +
                        " | Qtd.: " +
                        numberToReal(
                          parseFloat(props.data.quantidade),
                          "quantidade"
                        )
                      }}
                    </div>
                    <div v-else class="d-flex justify-content-between">
                      <div>
                        {{ props.data.codigo }} - {{ props.data.descricao }}
                        <span
                          id="kitInfo"
                          v-if="props.data.isKit"
                          :title="getKitInfo(props.data)"
                          v-b-tooltip.hover.html.right
                        >
                          <InformationOutline :size="14" />
                        </span>
                      </div>
                      <span class="text-muted">{{
                        !props.data.isKit
                          ? `QTD: ${props.data.quantidade}`
                          : `R$
                            ${numberToReal(props.data.totalPrice)}`
                      }}</span>
                    </div>
                  </template>
                </Select>
              </template>
            </CardBuscaTerminal>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <div v-if="!produto">
      <b-row
        style="margin-top: 250px"
        md="12"
        class="h-100 d-flex align-items-center justify-content-center"
      >
        <BarcodeScan :size="200" />
      </b-row>
      <b-row class="h-100 d-flex align-items-center justify-content-center">
        <p style="font-size: 20px">Consulte um novo produto pela tecla F2.</p>
      </b-row>
    </div>
    <div v-if="produto" md="12">
      <b-row
        style="margin-top: 100px"
        md="12"
        class="h-100 d-flex align-items-center justify-content-center"
      >
        <div
          v-if="urlProdutoImage"
          :style="{
            width: '120px',
            height: '120px',
            cursor: 'pointer',
            marginRight: '4rem',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '1rem',
            fontSize: '0.8rem',
            border: !urlProdutoImage ? '1px dashed rgb(207, 207, 207)' : '',
            color: 'rgb(207, 207, 207)',
          }"
        >
          <b-card-img
            v-if="urlProdutoImage"
            :src="urlProdutoImage"
            class="rounded-0"
            style="
              object-fit: cover;
              width: 120px;
              height: 120px;
              object-position: center;
            "
          />
        </div>
        <strong style="font-size: 40px">
          {{ produto.codigo }} - {{ produto.descricao }}
        </strong>
      </b-row>
    </div>
    <div v-if="produto" md="12" style="margin-top: 10px">
      <b-row
        md="12"
        class="h-100 d-flex align-items-center justify-content-center"
      >
        <strong style="font-size: 40px">
          R$:
          {{ numberToReal(produto.preco, "preco") }}
        </strong>
      </b-row>
      <b-row
        md="12"
        class="h-100 d-flex align-items-center justify-content-center"
      >
        <strong style="font-size: 40px">
          Quantidade disponível:
          {{ numberToReal(produto.quantidade, "quantidade") }}
        </strong>
      </b-row>
      <b-row
        style="margin-top: 100px"
        class="h-100 d-flex align-items-center justify-content-center"
      >
        <p style="font-size: 20px">Consulte um novo produto pela tecla F2.</p>
      </b-row>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { SistemaMixin } from "@/modules/Application";
import { onboardStore } from "../../../onboardStore";
import { actionsStore } from "@/components/Table/actionsStore";
import { CardBuscaTerminal } from "../../Pdv/components/Cards";
import InformationOutline from "vue-material-design-icons/InformationOutline.vue";
import { Select } from "../../Pdv/components/Form";
import "vue-select/dist/vue-select.css";
import "vue-loading-overlay/dist/vue-loading.css";
import _ from "lodash";
import BarcodeScan from "vue-material-design-icons/BarcodeScan";
import { TECLAS } from "../../Pdv/enums/Documento";

import {
  MONEY as settingMoney,
  priceOptions,
  quantityOptions,
} from "@/modules/Application";

export default {
  name: "app-terminal-consulta",
  mixins: [SistemaMixin],
  components: {
    CardBuscaTerminal,
    InformationOutline,
    Select,
    BarcodeScan,
    Keypress: () => import("vue-keypress"),
  },
  data() {
    return {
      actionsStore,
      onboardStore,
      quantityOptions,
      priceOptions,
      quantidade: 0,
      price: 1.0,
      produto: null,
      produtos: [],
      ultimoProduto: {},
      isFullScreen: false,
      teclasEnum: TECLAS,
      urlProdutoImage: null,
    };
  },
  async created() {},
  methods: {
    buscarProduto() {
      this.$refs.buscarProduto.$el.querySelector("input").focus();
      this.produto = null;
    },
    informarValor() {
      this.$nextTick(() => {
        if (this.$refs.valor) {
          this.$refs.valor.$el.querySelector("input").focus();
          let inputEl = this.$refs.valor.$el.querySelector("input");
          setTimeout(() => {
            inputEl.select();
          }, 100);
        }
      });
    },
    onSearch(search, loading) {
      if (search.length > 1 && search[0] === "+") {
        loading(true);
        this.searchKit(loading, search, this);
        return;
      }
      if (search.length > 2 || /^[0-9]+$/.test(search)) {
        this.search(loading, search, this);
      }
    },
    search: _.debounce(async (loading, search, vm) => {
      if (search === "" || search === null) {
        return loading(false);
      }

      const $this = vm;

      let busca = search;
      if (busca.split("*").length > 1) {
        let quantidade = 1 * 10 * $this.quantityOptions.precision;
        [quantidade, busca] = busca.split("*");
        $this.quantidade = $this.numberToReal(
          parseFloat(quantidade),
          "quantidade"
        );
      }

      if (busca === "") {
        return false;
      }

      loading(true);

      $this.produtos = await [];
      const { data } = await $this.$http.post("/produtos/busca-item-consulta", {
        filter: busca,
        typeFilter: $this.typeFilter,
      });
      if (data.length === 1) {
        if ($this.isBarCode(busca) && Number.parseInt(busca)) {
          $this.setProduto(data[0], true);
        }
      }

      $this.produtos = data;

      loading(false);
      return true;
    }, 350),
    searchKit: _.debounce(async (loading, search, vm) => {
      const { data } = await vm.$http.post("/kit/get-kit", { search });
      vm.produtos = data.map((kit) => ({
        ...kit,
        descricao: kit.nome,
        isKit: true,
      }));
      loading(false);
    }, 350),
    setProduto(produto) {
      if (produto === null) return false;

      this.produto = produto;
      this.urlProdutoImage = produto.imagem;
    },
    getKitInfo(kitData) {
      const description = kitData.productOfTradeCollection.reduce(
        (acc, produto, index) =>
          acc +
          `${index + 1} - ${produto.product.descricao} - QTD: ${
            produto.product.quantidade
          }<br/>`,
        ""
      );
      return description;
    },
    isBarCode(codigo) {
      if (codigo.length === 8) {
        return true;
      }

      if (codigo.length === 12) {
        return true;
      }

      if (codigo.length === 13) {
        return true;
      }

      if (codigo.length === 14) {
        return true;
      }

      return false;
    },
  },
  computed: {
    ...mapGetters(["layoutConfig", "itsblocked"]),
    config() {
      return this.layoutConfig();
    },
  },
  mounted() {
    if (this.itsblocked) {
      this.$router.push({ name: "assinatura" });
    }
  },
};
</script>
<style>
@media (max-width: 767px) {
  .right-card {
    /* Mobile styles */
    padding-top: 1rem;
    margin-top: 1.5rem;
  }
}

.busca-produto .vs__search {
  font-weight: 700;
  font-size: 1.45rem !important;
  margin-bottom: 0.5rem !important;
  line-height: 1.2 !important;
  text-transform: uppercase !important;
}

.busca-produto .vs__dropdown-toggle {
  border: 0px !important;
}

.busca-produto .vs__actions {
  display: none;
}

.busca-produto .vs__selected-options {
  max-width: 100%;
  flex-wrap: initial;
}

.busca-produto .vs__selected {
  text-align: left !important;
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.busca-cliente .vs__search {
  font-size: 18px;
}

.busca-cliente .vs__dropdown-toggle {
  color: var(--primary-color);
  background-color: #fff;
  border: 2px solid #dedede;
  height: 40px;
}

.busca-cliente .vs--open .vs__dropdown-toggle {
  background-color: #fff;
  border: 2px solid var(--primary-color);
}

.busca-cliente .vs__actions {
  display: none;
}

.busca-cliente .vs__dropdown-option--highlight,
.vs__dropdown-menu li:hover,
.vs__dropdown-menu li:focus {
  background-color: var(--primary-color)5e !important;
  color: var(--primary-color) !important;
}

.busca-cliente .vs__dropdown-menu {
  color: var(--primary-color) !important;
}

.busca-cliente .vs__selected-options {
  max-width: 100%;
  flex-wrap: initial;
}

.busca-cliente .vs__selected {
  text-align: left !important;
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.pdv-form {
  top: -30px;
}

.remove-pgto {
  font-weight: bold;
  font-size: 18px;
  color: #4f4f4f;
}

.add-pgto {
  font-weight: bold;
  font-size: 22px;
  color: var(--primary-color);
  float: right;
}

.input-quantidade {
  font-size: 1.75rem;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
  background: transparent;
  border: transparent;
  color: #fff;
  padding: 0px;
  margin-top: -10px;
  margin-bottom: 0px;
  text-align: right;
}

.input-quantidade:focus {
  box-shadow: 0 0 0 0;
  border: 0 none;
  outline: 0;
  border: 0px;
  background-color: transparent;
  color: #fff;
}

.card {
  border: 0;
}

h4 {
  color: #6d747c;
  /* font-weight: 900; */
}

.pdv-card-logo {
  display: table-cell;
  text-align: center;
  vertical-align: middle;
  width: 100vh;
  background-color: #fff;
  overflow-y: hidden !important;
  overflow-x: hidden !important;
  max-height: calc(100vh - 375px);
  height: calc(100vh - 375px);
  border: 0px;
  top: -20px !important;
  text-align: center !important;
  position: relative;
}

.pdv-card-logo img {
  max-width: 100%;
  height: auto;
}

.pdv-acoes {
  position: absolute;
  width: 100%;
  margin-top: -32px;
}

.pdv-atalhos {
  position: absolute;
  bottom: 0px;
  left: 0px;
}

.pdv-atalhos .btn-success {
  background-color: #eee5ff;
  border-color: var(--primary-color);
}

.pdv-atalhos button {
  margin-right: 0px !important;
  background-color: #fff;
  color: #4f4f4f;
  font-size: 14px;
  margin-top: 8px;
  height: 60px;
}

.pdv-atalhos button:hover {
  background-color: var(--color-secondary);
  border-color: var(--color-secondary);
}

.btn-finalizar-venda {
  background-color: #eee5ff !important;
  font-size: 1.25rem !important;
  text-align: center !important;
  cursor: pointer;
}

.card-total-right p,
.card-total-left p {
  color: #fff !important;
}

/* @media (max-width: 1024px) { */
.card-header h5,
.card-header h3 {
  margin-bottom: 0rem;
}

.card-header .form-control {
  height: auto;
  margin-top: 0px;
}

.card-header .busca-produto {
  margin-top: 2px;
  max-width: 100% !important;
}

.card-titulo-itens .card-body {
  padding: 5px 0 !important;
}

.card-itens .table tr th {
  padding: 5px;
  font-size: 12px;
}

.card-itens .table tr td,
.card-total-right p,
.card-total-left p {
  font-size: 12px;
}

.tableItensVenda {
  max-height: 300px !important;
  height: calc(100vh - 395px) !important;
}

.pdv-acoes {
  margin-top: -32px;
}

.pdv-card-logo {
  max-height: calc(100vh - 345px) !important;
  height: calc(100vh - 345px) !important;
}

.atalhos li a,
.atalhos li button span {
  font-size: 12px !important;
}

.card-total-left,
.card-total-right {
  height: 80px !important;
}

.btn.btn-secondary {
  color: var(--primary-color);
  background-color: #fff;
  border-color: #fff;
}

.venda-footer {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 5rem;
  padding: 1rem;
  width: fit-content;
  border-radius: 8px;
  margin-top: 3.5rem;
  margin: 3.5rem auto 0;
  background-color: white;
  /* vertical-align: middle; */
  z-index: 999;
}

/* } */
</style>

