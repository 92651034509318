<template>
  <div class="card card-custom card-stretch gutter-b">
    <div class="card-header border-0 py-5">
      <h3 class="card-title align-items-start flex-column">
        <span class="card-label font-weight-bolder text-dark">
          Editar Unidade de Medida
        </span>
      </h3>
    </div>
    <Form url="/produtos/update-unidades-medidas" :un="unidade" />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Form from "./Form.vue";
import UNData from "./UNData";

export default {
  name: "app-unidade-update",
  data() {
    return {
      unidade: new UNData({}),
    };
  },
  components: {
    Form,
  },
  methods: {
    ...mapActions(["setLoading"]),
    async getUnidade(id) {
      const { data } = await this.$http.post("/produtos/get-unidades-medida", {
        id: id,
      });

      return data;
    },
    async getInit() {
      try {
        const id = this.$route.params.id;

        this.setLoading(true);

        const unidade = await this.getUnidade(id);

        this.setLoading(false);

        if (!unidade.id || unidade == null) {
          this.$router.push({ name: "unidades" });
          return this.$toast.error("Unidade não encontrada!");
        }

        this.unidade = new UNData(unidade);
      } catch {
        return;
      }
    },
  },
  computed: {
    ...mapGetters(["itsblocked"]),
  },
  mounted() {
    if (this.itsblocked) {
      this.$router.push({ name: "assinatura" });
    }
    this.getInit();
  },
};
</script>
