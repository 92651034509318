<template>
  <div class="card-form">
    <div class="card-list">
      <Card
        :fields="fields"
        :labels="formData"
        :isCardNumberMasked="isCardNumberMasked"
        :randomBackgrounds="randomBackgrounds"
        :backgroundImage="backgroundImage"
      />
    </div>
    <div class="card-form__inner">
      <div class="card-form__row">
        <div class="card-form__col">
          <div class="card-form__group">
            <label for="cardMonth" class="card-input__label">
              Vezes (Pagamento)</label
            >
            <select
              class="card-input__input -select"
              :id="fields.cardVezes"
              v-model="formData.cardVezes"
              data-card-field
            >
              <option value disabled selected>Vezes</option>
              <option
                v-bind:value="n < 10 ? +n : n"
                v-for="n in vezesByPlan"
                v-bind:key="n"
              >
                {{ generateVezes(n) }}
              </option>
            </select>
          </div>
        </div>
      </div>
      <div class="card-input">
        <label for="cardNumber" class="card-input__label"
          >Número do Cartão</label
        >
        <input
          type="tel"
          :id="fields.cardNumber"
          @input="changeNumber"
          @focus="focusCardNumber"
          @blur="blurCardNumber"
          class="card-input__input"
          :value="formData.cardNumber"
          :maxlength="cardNumberMaxLength"
          data-card-field
          autocomplete="off"
        />
        <button
          class="card-input__eye"
          :class="{ '-active': !isCardNumberMasked }"
          title="Show/Hide card number"
          tabindex="-1"
          :disabled="formData.cardNumber === ''"
          @click="toggleMask"
        ></button>
      </div>
      <div class="card-input">
        <label for="cardName" class="card-input__label"> Nome Titular </label>
        <input
          type="text"
          :id="fields.cardName"
          v-letter-only
          @input="changeName"
          class="card-input__input"
          :value="formData.cardName"
          data-card-field
          autocomplete="off"
        />
      </div>
      <div class="card-form__row">
        <div class="card-form__col">
          <div class="card-form__group">
            <label for="cardMonth" class="card-input__label">
              Data de Vencimento</label
            >
            <select
              class="card-input__input -select"
              :id="fields.cardMonth"
              v-model="formData.cardMonth"
              @change="changeMonth"
              data-card-field
            >
              <option value disabled selected>Mês</option>
              <option
                v-bind:value="n < 10 ? '0' + n : n"
                v-for="n in 12"
                v-bind:disabled="n < minCardMonth"
                v-bind:key="n"
              >
                {{ generateMonthValue(n) }}
              </option>
            </select>
            <select
              class="card-input__input -select"
              :id="fields.cardYear"
              v-model="formData.cardYear"
              @change="changeYear"
              data-card-field
            >
              <option value disabled selected>Ano</option>
              <option
                v-bind:value="$index + minCardYear"
                v-for="(n, $index) in 12"
                v-bind:key="n"
              >
                {{ $index + minCardYear }}
              </option>
            </select>
          </div>
        </div>
        <div class="card-form__col -cvv">
          <div class="card-input">
            <label for="cardCvv" class="card-input__label">CVV</label>
            <input
              type="tel"
              class="card-input__input"
              v-number-only
              :id="fields.cardCvv"
              maxlength="3"
              :value="formData.cardCvv"
              @input="changeCvv"
              data-card-field
              autocomplete="off"
            />
          </div>
        </div>
      </div>

      <button class="card-form__button" v-on:click="postCard" v-if="!isSale">
        Atualizar dados
      </button>
      <button class="card-form__button" v-on:click="postCard" v-if="isSale">
        Realizar assinatura
      </button>
    </div>
  </div>
</template>

<script>
import Card from "./Card";
import { mapActions } from "vuex";
export default {
  name: "CardForm",
  directives: {
    "number-only": {
      bind(el) {
        function checkValue(event) {
          event.target.value = event.target.value.replace(/[^0-9]/g, "");
          if (event.charCode >= 48 && event.charCode <= 57) {
            return true;
          }
          event.preventDefault();
        }
        el.addEventListener("keypress", checkValue);
      },
    },
    "letter-only": {
      bind(el) {
        function checkValue(event) {
          if (event.charCode >= 48 && event.charCode <= 57) {
            event.preventDefault();
          }
          return true;
        }
        el.addEventListener("keypress", checkValue);
      },
    },
  },
  props: {
    formData: {
      type: Object,
      default: () => {
        return {
          cardName: "",
          cardNumber: "",
          cardNumberNotMask: "",
          cardMonth: "",
          cardYear: "",
          cardCvv: "",
        };
      },
    },
    dia_pagamento_revenda: {
      type: String,
    },
    isRevenda: {
      type: Boolean,
    },
    backgroundImage: [String, Object],
    randomBackgrounds: {
      type: Boolean,
      default: true,
    },
    valor: {
      type: String,
    },
    plano: {
      type: String,
    },
    id_plan: {
      type: String,
    },
    vezesByPlan: {
      type: Number,
      default: 1,
    },
    isSale: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    Card,
  },
  data() {
    return {
      fields: {
        cardNumber: "v-card-number",
        cardName: "v-card-name",
        cardMonth: "v-card-month",
        cardYear: "v-card-year",
        cardCvv: "v-card-cvv",
      },
      minCardYear: new Date().getFullYear(),
      isCardNumberMasked: true,
      mainCardNumber: this.cardNumber,
      cardNumberMaxLength: 19,
      vezes: [
        {
          codigo: 1,
          descricao: "1x",
        },
        {
          codigo: 2,
          descricao: "2x",
        },
        {
          codigo: 3,
          descricao: "3x",
        },
        {
          codigo: 4,
          descricao: "4x",
        },
        {
          codigo: 5,
          descricao: "5x",
        },
        {
          codigo: 6,
          descricao: "6x",
        },
      ],
      essencialMonthly: "3",
      essencialYearly: "2",
    };
  },
  computed: {
    minCardMonth() {
      if (this.formData.cardYear === this.minCardYear)
        return new Date().getMonth() + 1;
      return 1;
    },
  },
  watch: {
    cardYear() {
      if (this.formData.cardMonth < this.minCardMonth) {
        this.formData.cardMonth = "";
      }
    },
  },
  mounted() {
    this.maskCardNumber();
  },
  methods: {
    ...mapActions(["setLoading"]),
    generateMonthValue(n) {
      return n < 10 ? `0${n}` : n;
    },
    generateVezes(n) {
      if (this.id_plan == this.essencialYearly) {
        if (n === 1) {
          return "1x R$ 739,90";
        }

        if (n === 2) {
          return "2x R$ 366,45";
        }

        if (n === 3) {
          return "3x R$ 246,63";
        }

        if (n === 4) {
          return "4x R$ 184,97";
        }

        if (n === 5) {
          return "5x R$ 147,98";
        }

        if (n === 6) {
          return "6x R$ 123,31";
        }
      }

      if (this.id_plan == this.essencialMonthly) {
        return "1x R$ 73,90";
      }
    },
    changeName(e) {
      this.formData.cardName = e.target.value;
      this.$emit("input-card-name", this.formData.cardName);
    },
    changeNumber(e) {
      this.formData.cardNumber = e.target.value;
      let value = this.formData.cardNumber.replace(/\D/g, "");
      // american express, 15 digits
      if (/^3[47]\d{0,13}$/.test(value)) {
        this.formData.cardNumber = value
          .replace(/(\d{4})/, "$1 ")
          .replace(/(\d{4}) (\d{6})/, "$1 $2 ");
        this.cardNumberMaxLength = 17;
      } else if (/^3(?:0[0-5]|[68]\d)\d{0,11}$/.test(value)) {
        // diner's club, 14 digits
        this.formData.cardNumber = value
          .replace(/(\d{4})/, "$1 ")
          .replace(/(\d{4}) (\d{6})/, "$1 $2 ");
        this.cardNumberMaxLength = 16;
      } else if (/^\d{0,16}$/.test(value)) {
        // regular cc number, 16 digits
        this.formData.cardNumber = value
          .replace(/(\d{4})/, "$1 ")
          .replace(/(\d{4}) (\d{4})/, "$1 $2 ")
          .replace(/(\d{4}) (\d{4}) (\d{4})/, "$1 $2 $3 ");
        this.cardNumberMaxLength = 19;
      }
      // eslint-disable-next-line eqeqeq
      if (e.inputType == "deleteContentBackward") {
        let lastChar = this.formData.cardNumber.substring(
          this.formData.cardNumber.length,
          this.formData.cardNumber.length - 1
        );
        // eslint-disable-next-line eqeqeq
        if (lastChar == " ") {
          this.formData.cardNumber = this.formData.cardNumber.substring(
            0,
            this.formData.cardNumber.length - 1
          );
        }
      }
      this.$emit("input-card-number", this.formData.cardNumber);
    },
    changeMonth() {
      this.$emit("input-card-month", this.formData.cardMonth);
    },
    changeYear() {
      this.$emit("input-card-year", this.formData.cardYear);
    },
    changeCvv(e) {
      this.formData.cardCvv = e.target.value;
      this.$emit("input-card-cvv", this.formData.cardCvv);
    },
    async postCard() {
      if (this.isRevenda && !this.dia_pagamento_revenda) {
        this.$toast.warning("Preencha o dia do pagamento!");
        return;
      }

      if (!this.formData.cardName) {
        this.$toast.warning("Preencha o nome!");
        return;
      }

      if (!this.formData.cardMonth) {
        this.$toast.warning("Preencha o mês!");
        return;
      }

      if (!this.formData.cardYear) {
        this.$toast.warning("Preencha o Ano!");
        return;
      }

      if (!this.formData.cardCvv) {
        this.$toast.warning("Preencha o código do cartão!");
        return;
      }

      if (!this.formData.cardNumber) {
        this.$toast.warning("Preencha o número do cartão!");
        return;
      }

      this.setLoading(true);
      let number = this.formData.cardNumberNotMask.replace(/ /g, "");
      var sum = 0;
      for (var i = 0; i < number.length; i++) {
        var intVal = parseInt(number.substr(i, 1));
        if (i % 2 === 0) {
          intVal *= 2;
          if (intVal > 9) {
            intVal = 1 + (intVal % 10);
          }
        }
        sum += intVal;
      }
      if (sum % 10 !== 0) {
        this.setLoading(false);
        this.$toast.warning("Número do cartão inválido!");
        return;
      }
      const dadosCartao = {};
      dadosCartao.cvv = this.formData.cardCvv;
      dadosCartao.mes = this.formData.cardMonth;
      dadosCartao.ano = this.formData.cardYear;
      dadosCartao.nome = this.formData.cardName;
      dadosCartao.numero_mascara = this.formData.cardNumber;
      dadosCartao.numero = this.formData.cardNumberNotMask;
      dadosCartao.vezes = this.formData.cardVezes;
      dadosCartao.dia_pagamento_revenda = this.dia_pagamento_revenda ?? null;
      dadosCartao.isRevenda = this.isRevenda ?? null;
      dadosCartao.id_plan = this.id_plan;
      dadosCartao.is_sale = this.isSale;

      try {
        await this.$http.post("/empresa/cartao", dadosCartao);
        this.$toast.success("Dados atualizados com sucesso!");
        this.setLoading(false);

        if (this.isRevenda) {
          this.$router.push({ name: "clientesLista" });
          return;
        }
        this.$router.push({ name: "dashboard" });
      } catch (error) {
        this.setLoading(false);
        this.$toast.warning("Houve algum problema no pagamento!");
        console.log(error);
      }
    },
    blurCardNumber() {
      if (this.isCardNumberMasked) {
        this.maskCardNumber();
      }
    },
    maskCardNumber() {
      this.formData.cardNumberNotMask = this.formData.cardNumber;
      this.mainCardNumber = this.formData.cardNumber;
      let arr = this.formData.cardNumber.split("");
      arr.forEach((element, index) => {
        if (index > 4 && index < 14 && element.trim() !== "") {
          arr[index] = "*";
        }
      });
      this.formData.cardNumber = arr.join("");
    },
    unMaskCardNumber() {
      this.formData.cardNumber = this.mainCardNumber;
    },
    focusCardNumber() {
      this.unMaskCardNumber();
    },
    toggleMask() {
      this.isCardNumberMasked = !this.isCardNumberMasked;
      if (this.isCardNumberMasked) {
        this.maskCardNumber();
      } else {
        this.unMaskCardNumber();
      }
    },
  },
};
</script>
