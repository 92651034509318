import contasPagarLista from "@/modules/ContaPagar/pages/Index";
import contasPagarNovo from "@/modules/ContaPagar/pages/Create";
import contasPagarEditar from "@/modules/ContaPagar/pages/Update";

export default [
  {
    name: "contasPagarLista",
    path: "/contasPagar",
    component: contasPagarLista,
    meta: { title: "Contas Pagar" },
  },
  {
    name: "contasPagarNovo",
    props: true,
    path: "/contasPagar/novo",
    component: contasPagarNovo,
    meta: { title: "Contas Pagar" },
  },
  {
    name: "contasPagarEditar",
    path: "/contasPagar/:id/editar",
    component: contasPagarEditar,
    meta: { title: "Contas Pagar" },
  },
];
