<template>
  <div class="container-fluid">
    <div class="card-body">
      <b-card>
        <div class="d-flex justify-content-between">
          <div>
            <h5 class="card-title align-items-start flex-column mb-2">
              <span class="card-label font-weight-bolder text-dark">
                Cliente:
              </span>
              <span class="card-label text-dark">
                {{ cliente.nome }}
              </span>
            </h5>
            <h5 class="card-title align-items-start flex-column mb-2">
              <span class="card-label font-weight-bolder text-dark">
                Valor do orçamento:
              </span>
              <span class="card-label text-dark">
                R$ {{ numberToReal(orcamento.valorTotal, 2) }}
              </span>
            </h5>
            <h5 class="card-title align-items-start flex-column mb-20">
              <span class="card-label font-weight-bolder text-dark">
                Orçamento número:
              </span>
              <span class="card-label text-dark">
                {{ orcamento.numero }}
              </span>
            </h5>
          </div>
          <div>
            <button
              @click="goBackToOrcamentoList"
              class="text-primary font-weight-bolder h6"
            >
              Voltar
            </button>
          </div>
        </div>

        <vue-timeline-update
          v-for="item of timelineItems"
          :key="item.name"
          :date="new Date(item.date)"
          :description="`${item.label} em ${$moment(item.date).format(
            'DD/MM/YYYY'
          )} as ${item.time} ${
            item.label === 'Respondido'
              ? `, Contato: ${item.contato} ${
                  item.observacao ? '- ' + item.observacao : ''
                }`
              : ''
          } `"
          :category="item.name"
          :color="item.color"
          icon="code"
        />
      </b-card>
    </div>
  </div>
</template>

<script >
import { mapActions, mapGetters } from "vuex";
import { validationMixin } from "vuelidate";
import { SistemaMixin, ValidacaoMixin } from "@/modules/Application";
import { mask } from "vue-the-mask";

export default {
  name: "app-orcamento-timeline",
  mixins: [validationMixin, SistemaMixin, ValidacaoMixin],
  directives: {
    mask,
  },
  components: {},
  data() {
    return {
      timelineItems: [],
      cliente: null,
      orcamento: null,
    };
  },
  watch: {},
  computed: {
    ...mapGetters(["pageTitle", "layoutConfig"]),
  },
  methods: {
    ...mapActions(["setLoading"]),
    goBackToOrcamentoList() {
      this.$router.push({ name: "orcamento" });
    },
    async getInit() {
      const { id_orcamento } = this.$route.params;
      this.$http
        .post("/orcamento/timeline", {
          id_orcamento,
        })
        .then(({ data }) => {
          const timeline = data.timeline;
          this.cliente = data.cliente;
          this.orcamento = data.orcamento;
          timeline.dataAlteracao &&
            this.timelineItems.push({
              name: "Resposta do cliente",
              label: "Respondido",
              color: "turquoise",
              date: timeline.dataAlteracao.split(" ")[0],
              time: timeline.dataAlteracao.split(" ")[1],
              contato: timeline.contato,
              observacao: timeline.observacaoCliente,
              status: timeline.status,
            });
          timeline.dataEnvioLink &&
            this.timelineItems.push({
              name: "Envio de link",
              label: "Enviado",
              color: "green",
              date: timeline.dataEnvioLink.split(" ")[0],
              time: timeline.dataEnvioLink.split(" ")[1],
            });
          timeline.dataVisualizacao &&
            this.timelineItems.push({
              name: "Visualização",
              label: "Cliente visualizou",
              color: "orange",
              date: timeline.dataVisualizacao.split(" ")[0],
              time: timeline.dataVisualizacao.split(" ")[1],
            });
          this.timelineItems.sort((a, b) => {
            if (a.date === b.date) {
              return a.time < b.time ? -1 : 1;
            } else {
              return a.date < b.date ? -1 : 1;
            }
          });
          this.setLoading(false);
        })
        .catch((error) => {
          this.setLoading(false);
          this.$toast.error(`Erro ao buscar orçamento:\n${error}`);
        });
    },
  },
  async created() {
    this.setLoading(true);
    this.getInit();
  },
};
</script>
<style scoped>
svg {
  outline-width: 0;
  outline-color: transparent;
  color: rgb(241, 249, 255);
}

.container {
  background-color: white;
  margin-top: 20px;
  border-radius: 10px;
  padding: 3rem;
  box-shadow: 0px 0px 30px 0px rgba(82, 63, 105, 0.05);
}

.select-city .vs__search::placeholder,
.select-city .vs__dropdown-toggle,
.select-city .vs__dropdown-menu {
  height: 10rem !important;
  /* width:  100%; */
  background-color: green !important;
}

.contato-input {
  height: 3rem;
}
</style>