<template>
  <div>
    <b-button @click="showAddModal(null)" variant="primary" class="mb-3"
      >Adicionar Novo Plano de Contas Principal</b-button
    >
    <TreeNode
      v-for="(node, index) in treeData"
      :key="index"
      :node="node"
      @add-child="addChild"
      @delete-node="deleteNode"
    />

    <b-modal v-model="modalVisible" title="Descrição" centered size="lg">
      <b-row>
        <b-col md="10">
          <b-form-group id="descricao" label="Descrição">
            <b-form-input
              v-model="newNodeName"
              maxlength="50"
              required
            ></b-form-input>
          </b-form-group>
        </b-col>
        <b-col md="10">
          <b-form-group label="Tipo do Plano:" label-for="parent-type-switch">
            <b-form-checkbox-group
              id="parent-type-switch"
              v-model="newParentType"
              :options="typeOptions"
              switches
            >
            </b-form-checkbox-group>
          </b-form-group>
        </b-col>
      </b-row>

      <template #modal-footer="{ ok, cancel }">
        <div>
          <b-button
            variant="primary"
            @click="saveNewNode"
            ref="saveNewNode"
            class="mr-5"
          >
            Salvar
          </b-button>
          <b-button
            @click="
              {
                cancel();
              }
            "
          >
            Cancelar
          </b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import TreeNode from "./TreeNode.vue";
import { SistemaMixin } from "@/modules/Application";

export default {
  name: "Tree",
  mixins: [SistemaMixin],
  components: {
    TreeNode,
  },
  data() {
    return {
      nodes: [],
      modalVisible: false,
      newNodeName: "",
      parentNode: null,
      newParentType: 2,
      typeOptions: [
        { text: "Saída (Receita)", value: 2 },
        { text: "Despesa", value: 1 },
      ],
    };
  },
  props: {
    treeData: {
      type: Array,
      required: true,
    },
  },
  methods: {
    showAddModal(parent) {
      this.parentNode = parent;
      this.newNodeName = "";
      this.modalVisible = true;
    },
    async saveNewNode() {
      if (!this.newNodeName) return;
      this.addLoadingToButton("saveNewNode");
      const newNode = {
        descricao: this.newNodeName,
        children: [],
        tipo: this.newParentType,
      };

      if (this.parentNode) {
        newNode.id_pai = this.parentNode.id;

        const { data } = await this.$http.post(
          "/financeiro/create-plano-filho",
          newNode
        );

        if (data.data.id) {
          newNode.id = data.data.id;
          this.$toast.success("Plano adicionado com sucesso!");
        }

        this.parentNode.children.push(newNode);
      } else {
        const { data } = await this.$http.post(
          "/financeiro/create-plano-principal",
          newNode
        );

        if (data.data.id) {
          newNode.id = data.data.id;
          this.$toast.success("Plano adicionado com sucesso!");
          this.treeData.push(newNode);
        }
      }
      this.removeLoadingToButton("saveNewNode");
      this.modalVisible = false;
    },
    addChild(node) {
      this.showAddModal(node);
    },
    deleteNode(node) {
      this.getRequestAdmin(() => {
        this.$swal
          .fire({
            icon: "warning",
            title: "Excluir o plano de contas: " + node.descricao + "?",
            showCancelButton: true,
            confirmButtonText: `Sim`,
            cancelButtonText: `Não`,
            confirmButtonColor: "#f64e60",
          })
          .then(async (result) => {
            if (result.isConfirmed) {
              await this.$http.post("/financeiro/delete-plano", node);
              this.handleDeleteNode(node);
              this.$toast.success("Plano de contas excluído!");
            }
          });
      });
    },

    handleDeleteNode(deletedNode) {
      const removeNode = (nodes, nodeToRemove) => {
        return nodes.filter((node) => {
          if (node.id === nodeToRemove.id) {
            return false;
          }
          if (node.children) {
            node.children = removeNode(node.children, nodeToRemove);
          }
          return true;
        });
      };

      this.treeData = removeNode(this.treeData, deletedNode);
    },
  },
};
</script>