<template>
  <Modal :value="open" :onClose="close" :close="false">
    <h4 class="title">Editando Item</h4>
    <b-row class="form-group">
      <b-col class="busca" md="6">
        <Input ref="descricao" label="Descrição" id="descricao" @keypress.enter.prevent="save" labelBold
          v-model="valueProduto.descricao" disabled />
      </b-col>
    </b-row>
    <b-row>
      <b-col md="4">
        <b-form-group label="Quantidade" label-for="quantidade">
          <money class="form-control" id="quantidade" name="quantidade" v-model="valueProduto.quantidade"
            v-bind="quantityOptions" />
        </b-form-group>
      </b-col>
      <b-col md="4">
        <b-form-group label="Valor Unitário" label-for="preco">
          <money class="form-control" id="preco" name="preco" disabled v-model="valueProduto.preco"
            v-bind="priceOptions" />
        </b-form-group>
      </b-col>
      <b-col md="4">
        <b-form-group label="Total de Item R$" label-for="total">
          <money class="form-control" id="total" name="total" disabled v-model="totalProduto" v-bind="priceOptions" />
        </b-form-group>
      </b-col>
    </b-row>

    <div class="confirm-buttons">
      <Button type="error" @click="close()">Fechar</Button>
      <Button type="success" @click="() => {
        save()
        close()
      }">Gravar</Button>
    </div>
  </Modal>
</template>

<script>
import { Modal } from "@/components";
import { Button, Input } from "@/components/Form";
import { Money } from "v-money";
import { MONEY as settingMoney, priceOptions, quantityOptions } from "@/modules/Application";
import { SistemaMixin } from "@/modules/Application";
import FormatMixin from "@/modules/Application/mixins/FormatMixin";
import PessoaMixin from "@/modules/Application/mixins/PessoaMixin";
import "vue-select/dist/vue-select.css";

export default {
  name: "ModalProduto",
  mixins: [FormatMixin, PessoaMixin, SistemaMixin],
  components: {
    Modal,
    Button,
    Input,
    Money,
  },
  data() {
    return {
      valueProduto: {},
      configMask: settingMoney,
      priceOptions,
      quantityOptions,
    };
  },
  props: {
    open: {
      type: Boolean,
      default: false,
    },
    close: {
      type: Function,
      required: true,
    },
    changeProduto: {
      type: Function,
      required: true,
    },
    produto: {
      type: Object,
      required: true,
    },
  },
  watch: {
    produto(newValue) {
      this.valueProduto = {
        ...newValue,
        quantidade: newValue.quantidade,
        preco: newValue.prec,
      };
    }
  },
  mounted() {
    console.log(this.produto);
    this.valueProduto = this.produto;
    this.getDataInit();
  },
  methods: {
    save() {
      this.valueProduto = {
        ...this.valueProduto,
        cst_csosn: this.cstcsosn,
        cfop: this.cfop,
        ncm: this.ncm,
        cst_csosn_contribuinte: this.cstcsosnContribuinte,
      };
      this.changeProduto(this.valueProduto);
      this.$toast.success("Produto editado com sucesso!");
    },
    async getDataInit() {
      this.getCstCsosn().then((data) => (this.cstcsosns = data));
    },
    setProduto(produto) {
      this.valueProduto = produto;
    },
  },
  computed: {
    totalProduto() {
      return (this.formatNumberToFloat(this.valueProduto.quantidade) * this.formatNumberToFloat(this.valueProduto.preco))
    },
  },
};
</script>
