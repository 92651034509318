import linksLista from "@/modules/Links/pages/Index";
import linksNovo from "@/modules/Links/pages/Create";

export default [
  {
    name: "linksLista",
    path: "/links",
    component: linksLista,
  },
  {
    name: "linksNovo",
    path: "/links/novo",
    component: linksNovo,
  },
];
