import { required } from "vuelidate/lib/validators";

export default class TributacaoData {
  descricao = "";

  base_icms = 100;

  base_icmsfe = 100;

  base_icmsst = 0;

  diferimento = 0;

  aliquota = {
    ac: 0,
    al: 0,
    am: 0,
    ap: 0,
    ba: 0,
    ce: 0,
    df: 0,
    es: 0,
    go: 0,
    ma: 0,
    mg: 0,
    ms: 0,
    mt: 0,
    pa: 0,
    pb: 0,
    pe: 0,
    pi: 0,
    pr: 0,
    rj: 0,
    rn: 0,
    ro: 0,
    rr: 0,
    rs: 0,
    sc: 0,
    se: 0,
    sp: 0,
    to: 0,
  };
  constructor({
    descricao = "",
    base_icms = 100,
    base_icmsfe = 100,
    base_icmsst = 0,
    diferimento = 0,
    aliquota = {
      ac: 0,
      al: 0,
      am: 0,
      ap: 0,
      ba: 0,
      ce: 0,
      df: 0,
      es: 0,
      go: 0,
      ma: 0,
      mg: 0,
      ms: 0,
      mt: 0,
      pa: 0,
      pb: 0,
      pe: 0,
      pi: 0,
      pr: 0,
      rj: 0,
      rn: 0,
      ro: 0,
      rr: 0,
      rs: 0,
      sc: 0,
      se: 0,
      sp: 0,
      to: 0,
    },
  }) {
    this.descricao = descricao;
    this.base_icms = base_icms;
    this.base_icmsfe = base_icmsfe;
    this.base_icmsst = base_icmsst;
    this.diferimento = diferimento;
    this.aliquota = aliquota;
  }
}

export const TributacaoDataRequired = {
  descricao: {
    required,
  },
};
