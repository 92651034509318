import financeiroLista from "@/modules/Financeiro/pages/Index";
import financeiroNovo from "@/modules/Financeiro/pages/Create";
import financeiroEditar from "@/modules/Financeiro/pages/Update";
import relatoriosFinanceiro from "@/modules/Financeiro/pages/Relatorios";

export default [
  {
    name: "financeiroLista",
    path: "/financeiro",
    component: financeiroLista,
    meta: { title: "Caixa" },
  },
  {
    name: "financeiroNovo",
    props: true,
    path: "/financeiro/novo",
    component: financeiroNovo,
    meta: { title: "Caixa" },
  },
  {
    name: "financeiroEditar",
    path: "/financeiro/:id/editar",
    component: financeiroEditar,
    meta: { title: "Caixa" },
  },
  {
    name: "relatoriosFinanceiro",
    path: "/relatoriosFinanceiro",
    component: relatoriosFinanceiro,
    meta: { title: "Caixa" },
  },
];
