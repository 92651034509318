<template>
  <div class="card card-custom card-stretch gutter-b">
    <div class="card-header border-0 py-5">
      <h3 class="card-title align-items-start flex-column">
        <span class="card-label font-weight-bolder text-dark">
          Nova Conta á Receber
        </span>
      </h3>
    </div>
    <Form url="/contareceber/create" :conta="{}" />
  </div>
</template>

<script>
import Form from "./Form.vue";
import { mapGetters } from "vuex";

export default {
  name: "app-conta-receber-create",
  components: {
    Form,
  },
  computed: {
    ...mapGetters(["itsblocked"]),
  },
  mounted() {
    if (this.itsblocked) {
      this.$router.push({ name: "assinatura" });
    }
  },
};
</script>
