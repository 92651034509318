<template>
  <div>
    <b-card
      :title="titulo_card"
      tag="article"
      class="mb-2 border border-primary"
    >
      <div class="col-md-2">
        <b-form @submit.prevent="filterMovimentacoes">
          <b-form-group label="Período:" label-for="periodoSelect">
            <b-form-select
              id="periodoSelect"
              v-model="selectedPeriodo"
              :options="periodos"
            ></b-form-select>
          </b-form-group>

          <div v-if="selectedPeriodo === 'personalizado'">
            <b-form-group label="Data Inicial:" label-for="dataInicial">
              <b-form-datepicker
                id="dataInicial"
                v-model="data_inicial"
                placeholder="Selecione uma data"
              ></b-form-datepicker>
            </b-form-group>

            <b-form-group label="Data Final:" label-for="dataFinal">
              <b-form-datepicker
                id="dataFinal"
                v-model="data_final"
                placeholder="Selecione uma data"
              ></b-form-datepicker>
            </b-form-group>
          </div>

          <b-button type="button" variant="primary" @click="applyPeriod"
            >Aplicar</b-button
          >
        </b-form>
      </div>

      <div class="d-flex justify-content-between align-items-center mt-2">
        <div></div>
        <b-button variant="primary" @click="printData"
          ><IconPrint :size="18"
        /></b-button>
      </div>

      <div v-if="isLoading" class="text-center">
        <b-spinner variant="primary"></b-spinner>
        <p>Carregando...</p>
      </div>

      <b-table
        v-if="!isLoading && tableMovimentacoes.length > 0"
        striped
        small
        hover
        responsive="sm"
        :fields="fields"
        :items="tableMovimentacoes"
      >
        <template v-slot:cell(descricao)="data">
          <div
            v-if="data.item.isSaldoAnterior"
            class="font-weight-bold text-right"
          >
            Saldo em {{ formatDate(data.item.data_saldo_dia_anterior) }}
          </div>
          <div v-else>
            {{ data.item.descricao }}
          </div>
        </template>

        <template v-slot:cell(valor_movimento)="data">
          <div v-if="data.item.isSaldoAnterior" class="font-weight-bold">
            {{ numberToReal(data.item.valor_movimento) }}
          </div>
          <div v-else>
            <span
              :style="{
                color: data.item.tipo_movimento === 1 ? 'green' : 'red',
              }"
              >{{ numberToReal(data.item.valor_movimento) }}
            </span>
          </div>
        </template>

        <template v-slot:cell(data_movimento)="data">
          <div v-if="data.item.isSaldoAnterior"></div>
          <div v-else>
            {{ formatDate(data.item.data_movimento) }}
          </div>
        </template>
      </b-table>
    </b-card>
  </div>
</template>


<script>
import { mapGetters } from "vuex";
import { SistemaMixin } from "@/modules/Application";
import moment from "moment";
import IconPrint from "vue-material-design-icons/Printer";

export default {
  name: "app-movimentacao-banco",
  mixins: [SistemaMixin],
  data() {
    return {
      banco: null,
      titulo: null,
      movimentacoes: [],
      data_inicial: null,
      data_final: null,
      titulo_card: null,
      fields: [
        { key: "data_movimento", label: "Data", sortable: true },
        { key: "descricao", label: "Descrição", sortable: false },
        { key: "valor_movimento", label: "Valor", sortable: false },
      ],
      isLoading: true,
      selectedPeriodo: null,
      periodos: [
        { value: null, text: "Selecione um período" },
        { value: "hoje", text: "Hoje" },
        { value: "ontem", text: "Ontem" },
        { value: "semana", text: "Essa Semana" },
        { value: "semana_passada", text: "Semana Passada" },
        { value: "mes", text: "Esse Mês" },
        { value: "personalizado", text: "Período Personalizado" },
      ],
    };
  },
  components: {
    IconPrint,
  },
  methods: {
    onPeriodChange() {
      if (this.selectedPeriodo !== "personalizado") {
        this.data_inicial = null;
        this.data_final = null;
      }
    },
    applyPeriod() {
      if (this.selectedPeriodo) {
        const currentDate = moment();

        switch (this.selectedPeriodo) {
          case "hoje":
            this.data_inicial = currentDate
              .clone()
              .startOf("day")
              .format("YYYY-MM-DD");
            this.data_final = currentDate
              .clone()
              .endOf("day")
              .format("YYYY-MM-DD");
            break;
          case "ontem":
            this.data_inicial = currentDate
              .clone()
              .subtract(1, "days")
              .startOf("day")
              .format("YYYY-MM-DD");
            this.data_final = currentDate
              .clone()
              .subtract(1, "days")
              .endOf("day")
              .format("YYYY-MM-DD");
            break;
          case "semana":
            this.data_inicial = currentDate
              .clone()
              .startOf("week")
              .format("YYYY-MM-DD");
            this.data_final = currentDate
              .clone()
              .endOf("week")
              .format("YYYY-MM-DD");
            break;
          case "semana_passada":
            this.data_inicial = currentDate
              .clone()
              .subtract(1, "week")
              .startOf("week")
              .format("YYYY-MM-DD");
            this.data_final = currentDate
              .clone()
              .subtract(1, "week")
              .endOf("week")
              .format("YYYY-MM-DD");
            break;
          case "mes":
            this.data_inicial = currentDate
              .clone()
              .startOf("month")
              .format("YYYY-MM-DD");
            this.data_final = currentDate
              .clone()
              .endOf("month")
              .format("YYYY-MM-DD");
            break;
        }
      }

      this.titulo_card =
        this.banco.nome +
        ": movimentos do período: " +
        this.formatDate(this.data_inicial) +
        " até " +
        this.formatDate(this.data_final);

      this.filterMovimentacoes();
    },

    async getBanco(id) {
      try {
        const { data } = await this.$http.post("/bancos/find", {
          id_banco: id,
        });

        this.banco = data;
        this.titulo =
          "Movimentação bancária: " +
          data.nome +
          " " +
          data.agencia +
          "/" +
          data.conta;
      } catch (error) {
        return null;
      }
    },
    async filterMovimentacoes() {
      this.isLoading = true;
      const { data } = await this.$http.post("/bancos/movimentacoes", {
        id_banco: this.$route.params.id_banco,
        data_inicial: this.data_inicial,
        data_final: this.data_final,
      });

      this.movimentacoes = data;
      this.isLoading = false;
    },
    printData() {
      this.$router.push({
        name: "visualizarImpressaoBranco",
        params: {
          data: this.movimentacoes,
          labels: this.fields,
          title: this.titulo_card,
        },
      });
    },
    goBack() {
      this.$router.go(-1);
    },
  },
  computed: {
    ...mapGetters(["layoutConfig", "itsblocked"]),
    config() {
      return this.layoutConfig();
    },
    tableMovimentacoes() {
      const movimentacoes = this.movimentacoes;
      const tableData = [];
      let lastDate = null;
      let saldoTotal = 0;

      movimentacoes.forEach((mov, index) => {
        if (lastDate !== mov.data_movimento) {
          tableData.push({
            descricao: "",
            valor_movimento: mov.saldo_dia_anterior,
            data_movimento: "",
            tipo_movimento: null,
            data_saldo_dia_anterior: mov.data_saldo_dia_anterior,
            isSaldoAnterior: true,
          });
        }

        tableData.push({
          ...mov,
          saldo_dia_anterior: null,
          data_saldo_dia_anterior: null,
        });

        saldoTotal +=
          mov.tipo_movimento === 1
            ? parseFloat(mov.valor_movimento)
            : -parseFloat(mov.valor_movimento);

        lastDate = mov.data_movimento;
      });

      if (movimentacoes.length > 0) {
        const lastMov = movimentacoes[movimentacoes.length - 1];

        tableData.push({
          descricao: "Saldo Final",
          valor_movimento: saldoTotal,
          data_movimento: "",
          tipo_movimento: null,
          data_saldo_dia_anterior: lastMov.data_movimento,
          isSaldoAnterior: true,
        });
      }

      return tableData;
    },
  },
  async mounted() {
    if (this.itsblocked) {
      this.$router.push({ name: "assinatura" });
    }
    const currentDate = moment();
    const monthStart = currentDate
      .clone()
      .startOf("month")
      .format("YYYY-MM-DD");
    const monthEnd = currentDate.clone().endOf("month").format("YYYY-MM-DD");

    this.data_inicial = monthStart;
    this.data_final = monthEnd;

    await this.getBanco(this.$route.params.id_banco);
    this.titulo_card =
      this.banco.nome +
      ": movimentos do período: " +
      currentDate.clone().startOf("month").format("DD/MM/YYYY") +
      " até " +
      currentDate.clone().endOf("month").format("DD/MM/YYYY");

    const { data } = await this.$http.post("/bancos/movimentacoes", {
      id_banco: this.$route.params.id_banco,
      data_inicial: this.data_inicial,
      data_final: this.data_final,
    });

    this.movimentacoes = data;
    this.isLoading = false;
  },
};
</script>

<style scoped>
.text-right {
  text-align: right;
}
</style>
