import { required } from "vuelidate/lib/validators";

export default class GeraisData {
  cliente = null;
  tecnico = null;
  dataEntrada = null;
  dataSaida = null;
  dataPrevisao = null;
  proxRevisao = null;
  garantia = null;
  solicitante = null;
  horaAgendamento = null;
  constructor(
    cliente = null,
    tecnico = null,
    dataEntrada = null,
    dataSaida = null,
    dataPrevisao = null,
    proxRevisao = null,
    garantia = null,
    solicitante = null,
    horaAgendamento = null
  ) {
    this.cliente = cliente;
    this.dataEntrada = dataEntrada;
    this.dataSaida = dataSaida;
    this.dataPrevisao = dataPrevisao;
    this.tecnico = tecnico;
    this.proxRevisao = proxRevisao;
    this.garantia = garantia;
    this.horaAgendamento = horaAgendamento;
  }
}

export const NFeDataRequired = {
  tecnico: {
    required,
  },
  cliente: {
    required,
  },
};
