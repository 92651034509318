import { required } from "vuelidate/lib/validators";

export default class UsuariosData {
  usuario = "";


  nome = "";

  status = "A";

  id_usuario = null;

  recursos = null;

  constructor({
    usuario = "",
    nome = "",
    status = "A",
    id_usuario = null,
    recursos = null,
  }) {
    this.usuario = usuario;
    this.nome = nome;
    this.status = status;
    this.id_usuario = id_usuario;
    this.recursos = recursos;
  }
}

export const UsuariosDataRequired = {
  usuario: {
    required,
  },
  nome: {
    required,
  },
};
