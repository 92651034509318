import { required } from "vuelidate/lib/validators";

export default class ServicoData {
  discriminacao = null;
  codigo = null;
  id_tributacao_servico = null;
  valor = null;
  codigoTributacaoMunicipio = null;
  art = null;
  codigo_obra = null;
  cei = null;
  constructor(
    discriminacao = null,
    codigo = null,
    id_tributacao_servico = null,
    valor = null,
    codigoTributacaoMunicipio = null,
    art = null,
    codigo_obra = null,
    cei = null,
  ) {
    this.discriminacao = discriminacao;
    this.codigo = codigo;
    this.id_tributacao_servico = id_tributacao_servico;
    this.valor = valor;
    this.codigoTributacaoMunicipio = codigoTributacaoMunicipio;
    this.art = art;
    this.codigo_obra = codigo_obra;
    this.cei = cei;
  }
}

export const ServicoDataRequired = {
  discriminacao: {
    required,
  },
  codigo: {
    required,
  },
  id_tributacao_servico: {
    required,
  },
  valor: {
    required,
  },
};
