<template>
  <fragment>
    <Label
      v-bind="labelProps"
      :html-for="id"
      :bold="labelBold"
      v-if="label"
      :disabled="disabled"
    >
      {{ label }}
    </Label>
    <DatePicker
      :lang="lang"
      v-model="data"
      format="DD/MM"
      :placeholder="placeholder"
      :disabled="disabled"
      :class="inputClass"
      clearable="false"
      range
      confirm
      confirm-text="Buscar"
    />
    <div
      class="input-description input-margin"
      v-if="description"
      :class="descriptionClass"
    >
      {{ description }}
    </div>
  </fragment>
</template>

<script>
import DatePicker from "vue2-datepicker";
import Label from "./Label";
import "vue2-datepicker/index.css";

import "vue2-datepicker/locale/pt-br";

export default {
  name: "InputDate",
  props: {
    id: {
      type: String,
      required: true
    },
    value: {},
    label: {
      type: String
    },
    labelBold: {
      type: Boolean,
      default: () => false
    },
    labelProps: {
      type: Object
    },
    placeholder: {
      type: String
    },
    description: {
      type: String
    },
    success: {
      type: Boolean,
      default: () => false
    },
    error: {
      type: Boolean,
      default: () => false
    },
    disabled: {
      type: Boolean,
      default: () => false
    }
  },
  components: {
    DatePicker,
    Label
  },
  data() {
    return {
      data: null,
      lang: {
        formatLocale: {
          monthsShort: [
            "Janeiro",
            "Fevereiro",
            "Março",
            "Abril",
            "Maio",
            "Junho",
            "Julho",
            "Agosto",
            "Setembro",
            "Outubro",
            "Novembro",
            "Dezembro"
          ],
          weekdaysMin: ["D", "S", "T", "Q", "Q", "S", "S"],
          monthBeforeYear: false
        }
      }
    };
  },
  mounted() {
    this.setIntervalOfDays();
  },
  methods: {
    setIntervalOfDays() {
      const date = new Date();
      const firstDayOfMonth = new Date(date.getFullYear(), date.getMonth(), 1);
      const lastDayOfMonth = new Date(
        date.getFullYear(),
        date.getMonth() + 1,
        0
      );
      this.data = [firstDayOfMonth, lastDayOfMonth];
    }
  },
  computed: {
    inputClass() {
      return {
        "input-calendar": true,
        "input-margin": !this.description,
        success: this.success,
        error: this.error
      };
    },
    descriptionClass() {
      return {
        success: this.success,
        error: this.error
      };
    }
  },
  watch: {
    data(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.$emit("input", newValue);
      }
    },
    value(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.data = this.value;
      }
    }
  }
};
</script>
