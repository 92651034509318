import objetosLista from "@/modules/Objetos/pages/Index";
import objetosNovo from "@/modules/Objetos/pages/Create";
import objetosEditar from "@/modules/Objetos/pages/Update";

export default [
  {
    name: "objetosLista",
    path: "/objetos",
    component: objetosLista,
    meta: { title: "Objetos" },
  },
  {
    name: "objetosNovo",
    path: "/objetos/novo",
    component: objetosNovo,
    meta: { title: "Objetos" },
  },
  {
    name: "objetosEditar",
    path: "/objetos/:id/editar",
    component: objetosEditar,
    meta: { title: "Objetos" },
  },
];
