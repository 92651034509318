<template>
  <TableApp
    titulo="Contador"
    subTitulo="Defina seu contador para gerar Sped"
    acaoBtn="/#/contador/novo"
    textoBtn="Novo"
    api-url="pessoa/contador"
    :fields="fields"
    classFilterColumn="pessoa"
    ref="pessoa"
    :perPage="10"
  >
    <template slot="customRow" slot-scope="props">
      <span slot="nome" v-if="props.field == 'nome'">
        <span class="pl-0 pt-8">
          <div class="d-flex align-items-center">
            <div
              class="symbol symbol-40 symbol-light-primary flex-shrink-0"
              style="margin-right: 10px"
            ></div>
            <div>
              <a
                href="#"
                class="
                  text-dark-75
                  font-weight-bolder
                  text-hover-primary
                  mb-1
                  font-size-lg
                "
                >{{ props.rowData.nome }}</a
              >
            </div>
          </div>
        </span>
      </span>
      <span slot="cnpj_cpf" v-if="props.field == 'cnpj_cpf'">
        <span style="width: 110px">
          <div class="font-weight-bolder font-size-lg mb-0">
            {{ formatarCampo(props.rowData.cnpj_cpf) }}
          </div>
          <div class="font-weight-bold text-muted">
            CRC: {{ props.rowData.crc }}
          </div>
        </span>
      </span>
      <span slot="status" v-if="props.field == 'status'">
        <span
          v-if="props.rowData.status == 'A'"
          class="label label-lg label-inline label-light-success"
          >Ativo</span
        >
        <span v-else class="label label-lg label-inline label-light-danger"
          >Inativo</span
        >
      </span>
      <span slot="actions" v-if="props.field == 'actions'">
        <div class="row-actions" style="text-align: right !important">
          <span style="overflow: visible; position: relative; width: 130px">
            <div class="dropdown dropdown-inline">
              <router-link
                class="btn btn-sm btn-info btn-hover-info btn-icon mr-2"
                v-b-tooltip="'Editar'"
                :to="{
                  name: 'contadorEditar',
                  params: {
                    id_pessoa: props.rowData.id_pessoa,
                  },
                }"
              >
                <Pencil :size="16" title="Editar" />
              </router-link>
              <button
                @click="excluir(props.rowData)"
                class="btn btn-sm btn-danger btn-hover-danger btn-icon mr-2"
                v-b-tooltip="'Excluir'"
              >
                <TrashCanIcon :size="16" />
              </button>
            </div>
          </span>
        </div>
      </span>
    </template>
  </TableApp>
</template>

<script>
import { mapGetters } from "vuex";
import Pencil from "vue-material-design-icons/Pencil.vue";
import TrashCanIcon from "vue-material-design-icons/TrashCan.vue";
import TableApp from "@/components/Table/Table2";
import { SistemaMixin } from "@/modules/Application";

export default {
  name: "app-contador",
  components: {
    Pencil,
    TrashCanIcon,
    TableApp,
  },
  mixins: [SistemaMixin],
  data() {
    return {
      fields: [
        {
          label: "Nome",
          field: "nome",
          type: "text",
          slot: true,
          sortable: true,
          width: "30%",
        },
        {
          label: "CNPJ",
          field: "cnpj_cpf",
          type: "text",
          slot: true,
          sortable: false,
          width: "10%",
        },
        {
          label: "Status",
          field: "status",
          sortable: false,
          slot: true,
          width: "1%",
          filterOptions: {
            enabled: false,
          },
        },
        {
          label: "",
          field: "actions",
          sortable: false,
          slot: true,
          width: "5%",
          filterOptions: {
            enabled: false,
          },
        },
      ],
    };
  },
  methods: {
    avatarRamdom() {
      var valor = Math.floor(Math.random() * 10) - 1;

      if (valor <= 0) {
        valor = 10;
      }

      return valor + ".svg";
    },
    formatarCampo(campoTexto) {
      if (campoTexto.length <= 11) {
        return this.mascaraCpf(campoTexto);
      }
      return this.mascaraCnpj(campoTexto);
    },
    mascaraCpf(valor) {
      return valor.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, "$1.$2.$3-$4");
    },
    mascaraCnpj(valor) {
      return valor.replace(
        /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g,
        "$1.$2.$3/$4-$5"
      );
    },
    excluir(data) {
      this.getRequestAdmin(() => {
        this.$swal
          .fire({
            icon: "warning",
            title: "Excluir o contador " + data.nome + "?",
            showCancelButton: true,
            confirmButtonText: `Sim`,
            cancelButtonText: `Não`,
            confirmButtonColor: "#f64e60",
          })
          .then(async (result) => {
            if (result.isConfirmed) {
              await this.$http.post("/pessoa/delete", {
                id_pessoa: data.id_pessoa,
              });
              this.$refs.pessoa.refresh();
              this.$toast.success("Contador excluído!");
            }
          });
      })
    },
  },
  computed: {
    ...mapGetters(["layoutConfig", "itsblocked"]),
    config() {
      return this.layoutConfig();
    },
  },
  mounted() {
    if (this.itsblocked) {
      this.$router.push({ name: "assinatura" });
    }
  },
};
</script>
