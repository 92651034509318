<template>
  <b-tab title="Total da NFe" :active="isTheCurrentTab" @click="() => store.currentTab = 3"
    :disabled="!isTheCurrentTab && !store.isUpdate" class="mt-5">
    <b-row class="p-4">
      <b-row>
        <b-col :cols="windowWidth < 768 ? 6 : 4">
          <b-form-group label="BC ICMS %" label-for="baseICMS">
            <money class="form-control" disabled id="baseICMS" name="baseICMS" v-model="form.baseICMS"
              v-bind="configMask">
            </money>
          </b-form-group>
        </b-col>
        <b-col :cols="windowWidth < 768 ? 6 : 4">
          <b-form-group label="Valor do ICMS R$" label-for="valorICMS">
            <money class="form-control" disabled id="valorICMS" name="valorICMS" v-model="form.valorICMS"
              v-bind="configMask">
            </money>
          </b-form-group>
        </b-col>
        <b-col :cols="windowWidth < 768 ? 6 : 4">
          <b-form-group label="BC ICMS ST %" label-for="baseICMSST">
            <money class="form-control" disabled id="baseICMSST" name="baseICMSST" v-model="form.baseICMSST"
              v-bind="configMask">
            </money>
          </b-form-group>
        </b-col>
        <b-col :cols="windowWidth < 768 ? 6 : 4">
          <b-form-group label="Valor ICMS ST R$" label-for="valorICMSST">
            <money class="form-control" disabled id="valorICMSST" name="valorICMSST" v-model="form.valorICMSST"
              v-bind="configMask">
            </money>
          </b-form-group>
        </b-col>
        <b-col :cols="windowWidth < 768 ? 6 : 4">
          <b-form-group label="Valor IPI R$" label-for="valorIPI">
            <money class="form-control" disabled id="valorIPI" name="valorIPI" v-model="form.valorIPI"
              v-bind="configMask">
            </money>
          </b-form-group>
        </b-col>
        <b-col :cols="windowWidth < 768 ? 6 : 4">
          <b-form-group label="Valor do FCP ST R$" label-for="vFCPST">
            <money class="form-control" disabled id="vFCPST" name="vFCPST" v-model="form.vFCPST" v-bind="configMask">
            </money>
          </b-form-group>
        </b-col>
        <b-col :cols="windowWidth < 768 ? 6 : 4">
          <b-form-group label="Valor do FCP R$" label-for="vFCP">
            <money class="form-control" disabled id="vFCP" name="vFCP" v-model="form.vFCP" v-bind="configMask">
            </money>
          </b-form-group>
        </b-col>
        <b-col :cols="windowWidth < 768 ? 6 : 4">
          <b-form-group label="ICMS Deso. R$" label-for="ICMSdesoneracao">
            <money class="form-control" disabled id="ICMSdesoneracao" name="ICMSdesoneracao"
              v-model="form.ICMSdesoneracao" v-bind="configMask">
            </money>
          </b-form-group>
        </b-col>
        <b-col :cols="windowWidth < 768 ? 6 : 4">
          <b-form-group label="Total Produtos R$" label-for="totalProd">
            <money class="form-control" id="totalProd" name="totalProd" disabled v-model="form.totalProd"
              v-bind="configMask">
            </money>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col :cols="windowWidth < 768 ? 6 : 2">
          <b-form-group label="Frete R$" label-for="frete">
            <money class="form-control" id="frete" name="frete" v-model="form.freightPrice" v-bind="configMask">
            </money>
          </b-form-group>
        </b-col>
        <b-col :cols="windowWidth < 768 ? 6 : 2">
          <b-form-group label="Seguro R$" label-for="seguro">
            <money class="form-control" id="seguro" name="seguro" v-model="form.insurance" v-bind="configMask">
            </money>
          </b-form-group>
        </b-col>
        <b-col :cols="windowWidth < 768 ? 6 : 2">
          <b-form-group label="Despesas R$" label-for="despesas">
            <money class="form-control" id="despesas" name="despesas" v-model="form.extraValue" v-bind="configMask">
            </money>
          </b-form-group>
        </b-col>
        <b-col :cols="windowWidth < 768 ? 6 : 2">
          <b-form-group label="Desconto R$" label-for="desconto">
            <money class="form-control" id="desconto" name="desconto" v-model="form.discount" v-bind="configMask">
            </money>
          </b-form-group>
        </b-col>
        <b-col :cols="windowWidth < 768 ? 6 : 3">
          <b-form-group label="Total da Nota R$" label-for="totalNFE">
            <money class="form-control" id="totalNFE" name="totalNFE" disabled v-model="total" v-bind="configMask">
            </money>
          </b-form-group>
        </b-col>
      </b-row>
    </b-row>
  </b-tab>
</template>

<script>
import { mapGetters, mapState, mapActions } from "vuex";
import "vue-select/dist/vue-select.css";
import { validationMixin } from "vuelidate";
import { store } from "../../storeNfe";
import ImpostosData from "../ImpostosData";
import { Money } from "v-money";
import { MONEY as settingMoney } from "@/modules/Application";
// import Plus from "vue-material-design-icons/PlusThick.vue";
import { SistemaMixin, ValidacaoMixin } from "@/modules/Application";
import _ from "lodash";
import { mask } from "vue-the-mask";

export default {
  name: "app-nfe-form-impostosTab",
  components: {
    // Plus
    Money,
  },
  directives: {
    mask,
  },
  data() {
    return {
      store,
      loading: false,
      form: null,
      windowWidth: window.innerWidth,
      total: 0,
      configMask: settingMoney,
    };
  },
  mixins: [validationMixin, SistemaMixin, ValidacaoMixin],
  methods: {
    ...mapActions(["setLoading"]),
    onResize() {
      this.windowWidth = window.innerWidth;
    },
    requestCalcImpostos() {
      this.loading = true;
      this.calcImposto(this);
    },
    calcImposto: _.debounce(async (vm) => {
      if (vm.store.currentTab === 3) vm.$toast.info('Calculando NFe...')
      vm.dataSendToApi = vm.formatDataToSendToApi(
        vm.store.formNatureza,
        vm.store.formProduto,
        vm.store.formCliente,
        vm.store.formFaturamento.pagamentos.length === 0 ? null : vm.store.formFaturamento,
        vm.form
      )
      const { dados } = await vm.getTotaisNfe(vm.dataSendToApi)
      vm.store.formImpostos = {
        ...vm.store.formImpostos,
        ...dados.total
      }
      vm.$toast.success('Totais da NFe atualizados!')
      vm.loading = false;
    }, 1000)
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
    })
  },
  created() {
    if (Object.keys(this.$route.params).length !== 0) {
      this.store.formImpostos = new ImpostosData({});
      this.form = this.store.formImpostos;
      const {
        baseICMS,
        valorICMS,
        baseICMSST,
        valorICMSST,
        valorIPI,
        vFCPST,
        totalProd,
        ICMSdesoneracao,
        totalNFE,
        freightPrice,
        insurance,
        extraValue,
        discount,
      } = this.$route.params.nfeData;

      this.store.formImpostos = {
        baseICMS,
        valorICMS,
        baseICMSST,
        valorICMSST,
        valorIPI,
        vFCPST,
        totalProd,
        ICMSdesoneracao,
        totalNFE,
        freightPrice,
        insurance,
        extraValue,
        discount,
      };
      this.total = totalNFE;
    } else {
      this.store.formImpostos = new ImpostosData({});
    }
  },
  watch: {
    "store.formImpostos"(newValue, oldValue) {
      if (newValue !== oldValue) this.form = newValue;
    },
    "store.formImpostos.totalNFE"(newValue, oldValue) {
      if (newValue !== oldValue) this.total = newValue;
    },
    "store.formImpostos.freightPrice"(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.total =
          this.store.formImpostos.totalProd +
          newValue +
          this.store.formImpostos.extraValue +
          this.store.formImpostos.insurance -
          this.store.formImpostos.discount;
        this.requestCalcImpostos();

      }
    },
    "store.formImpostos.discount"(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.total =
          this.store.formImpostos.totalProd +
          this.store.formImpostos.freightPrice +
          this.store.formImpostos.extraValue +
          this.store.formImpostos.insurance -
          newValue;
        this.requestCalcImpostos();

      }
    },
    "store.formImpostos.extraValue"(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.total =
          this.store.formImpostos.totalProd +
          this.store.formImpostos.freightPrice +
          newValue +
          this.store.formImpostos.insurance -
          this.store.formImpostos.discount;
        this.requestCalcImpostos();
      }
    },
    "store.formImpostos.insurance"(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.total =
          this.store.formImpostos.totalProd +
          this.store.formImpostos.freightPrice +
          this.store.formImpostos.extraValue +
          newValue -
          this.store.formImpostos.discount;
        this.requestCalcImpostos();

      }
    },
    "store.currentTab"(newValue, oldValue) {
      if (Object.keys(this.$route.params).length !== 0) return;
      if (newValue > oldValue && newValue > 3) {
        this.store.formImpostos.totalProd = this.total;
      }
    },
  },
  computed: {
    ...mapGetters([
      "isAuthenticated",
      "breadcrumbs",
      "pageTitle",
      "layoutConfig",
    ]),
    ...mapState(["loading"]),
    isLoading() {
      return this.loading.loading;
    },
    isTheCurrentTab() {
      return store.currentTab === 3;
    },
  },
};
</script>