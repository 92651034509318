<template>
  <div style="position: relative">
    <div style="position: relative;">
      <FilterFields :fields="[
        {
          type: 'text',
          label: 'Documento',
          field: 'documento',
        },
        {
          type: 'text',
          label: 'Fornecedor',
          field: 'fornecedor',
        },
        {
          type: 'text',
          label: 'Descrição',
          field: 'descricao',
        },
        {
          type: 'select',
          label: 'Status',
          field: 'status',
          options: ['Paga', 'Atrasada', 'Pendente', 'Todos'],
        },
        {
          type: 'select',
          label: 'Marcada',
          field: 'conta_marcada',
          options: ['Sim', 'Não'],
        },
        {
          type: 'date',
          label: 'Vencimento',
          field: 'data_vencimento',
        },
        {
          type: 'date',
          label: 'Pagamento',
          field: 'data_pagamento',
        },
        {
          type: 'date',
          label: 'Emissão',
          field: 'created_at',
        },
  
      ]" />
  
      <div class="d-flex align-items-center my-2 mb-4" style="position: absolute; top: -7px; left: 110px; z-index: 99;">
        <b-dropdown :text="filtersStore.filterOptionSelected.label" split split-variant="secondary" id="menu"
          variant="primary" v-b-tooltip.right="''">
          <b-dropdown-item :active="filtersStore.filterOptionSelected.id === 1"
            @click="() => filtersStore.filterOptionSelected = { id: 1, label: 'Hoje' }">Hoje</b-dropdown-item>
          <b-dropdown-item :active="filtersStore.filterOptionSelected.id === 2"
            @click="() => filtersStore.filterOptionSelected = { id: 2, label: 'Ontem' }">
            Ontem
          </b-dropdown-item>
          <b-dropdown-item :active="filtersStore.filterOptionSelected.id === 3"
            @click="() => filtersStore.filterOptionSelected = { id: 3, label: 'Essa semana' }">
            Essa semana
          </b-dropdown-item>
          <b-dropdown-item :active="filtersStore.filterOptionSelected.id === 4"
            @click="() => filtersStore.filterOptionSelected = { id: 4, label: 'Semana passada' }">
            Semana passada
          </b-dropdown-item>
          <b-dropdown-item :active="filtersStore.filterOptionSelected.id === 5"
            @click="() => filtersStore.filterOptionSelected = { id: 5, label: 'Esse mês' }">
            Esse mês
          </b-dropdown-item>
        </b-dropdown>
      </div>
    </div>

    <b-dropdown id="menu" variant="dark" v-b-tooltip.right="'Mais ações'" v-if="actionsStore.selectedRows.length > 0"
      style="position: absolute; top: 0; left: 50px">
      <template #button-content>
        <Menu :size="18" />
      </template>
      <b-dropdown-item v-b-modal.menuAcoes>Pagamento de Contas</b-dropdown-item>
      <b-dropdown-item @click="() => getRequestAdmin(() => $bvModal.show('estornarContas'))">Estornar
        Contas</b-dropdown-item>
      <b-dropdown-item v-b-modal.marcarContas>Marcar/Desmarcar Contas</b-dropdown-item>
    </b-dropdown>



    <b-modal id="estornarContas" title="Deseja estornar as Contas?" centered size="xs">
      <template #modal-footer="{ ok, cancel }">
        <div>
          <b-button variant="primary" @click="estornar" ref="estornar_contas" class="mr-5">
            Estornar
          </b-button>
          <b-button @click="
                                                                                    {
            actionsStore.updateTable = !actionsStore.updateTable;
            actionsStore.selectedRows = [];
            cancel();
          }
            ">
            Cancelar
          </b-button>
        </div>
      </template>
    </b-modal>

    <b-modal id="marcarContas" title="Deseja marcar as Contas?" centered size="xs">
      <p>
        Recurso utilizado para melhorar a visualização das contas selecionadas.
      </p>
      <template #modal-footer="{ ok, cancel }">
        <div>
          <b-button variant="primary" @click="marcar" ref="marcarContas" class="mr-5">
            Marcar
          </b-button>
          <b-button @click="
                                                                                    {
            actionsStore.updateTable = !actionsStore.updateTable;
            actionsStore.selectedRows = [];
            cancel();
          }
            ">
            Cancelar
          </b-button>
        </div>
      </template>
    </b-modal>

    <b-modal id="menuAcoes" title="Pagamentos em massa" centered size="xl">
      <b-form class="form">
        <div class="d-flex" style="max-height: 450px; overflow: auto">
          <b-table v-if="actionsStore.selectedRows.length > 0" striped small hover :items="pagamentoTableItems"
            :fields="actionTableFields">
            <template #cell(actions)="row">
              <button type="button" class="btn btn-sm btn-danger btn-hover-danger btn-icon mr-2" v-b-tooltip="'Excluir'"
                @click="() => handleRemoveContaAPagar(row)">
                <TrashCanIcon :size="16" />
              </button>
            </template>
          </b-table>
        </div>
      </b-form>
      <template #modal-footer="{ ok, cancel }">
        <div class="d-flex">
          <DatePickerInput label="Data de pagamento" @valueUpdated="(date) => {
            dataPagamento = date;
          }
            " mode="single" />
          <b-form-group id="bancoPagamento" label-for="bancoPagamento" label="Conta" class="mr-5">
            <b-form-select v-model="bancoPagamento">
              <b-form-select-option v-for="b in bancos" :key="b.id_banco + 'bancos'" :value="b">
                {{ b.nome }}
              </b-form-select-option>
            </b-form-select>
          </b-form-group>
          <b-form-group id="meioPagamento" label-for="meioPagamento" label="Meio de pagamento">
            <b-form-select v-model="meioPagamento">
              <b-form-select-option v-for="meio in meiosPagamentos" :key="meio.id + 'meio'" :value="meio">
                {{ meio.descricao }}
              </b-form-select-option>
            </b-form-select>
          </b-form-group>
          <b-form-group label="Total do pagamento R$" label-for="totalPagamento" class="ml-5">
            <money class="form-control" id="totalPagamento" name="totalPagamento" :value="totalPagamento" disabled
              v-bind="configMask">
            </money>
          </b-form-group>
        </div>
        <div>
          <b-button variant="primary" @click="pagarContas" ref="pagar_contas" class="mr-5">
            Pagar
          </b-button>
          <b-button @click="
                                                                                    {
            actionsStore.updateTable = !actionsStore.updateTable;
            actionsStore.selectedRows = [];
            cancel();
          }
            ">
            Cancelar
          </b-button>
        </div>
      </template>
    </b-modal>

    <b-tooltip target="menu" placement="right" :show.sync="showTooltipMenu">
      Pagamentos de contas
    </b-tooltip>



    <TableApp titulo="Contas a Pagar" subTitulo="Lista de Contas a Pagar" acaoBtn="/#/contasPagar/novo" textoBtn="Nova"
      api-url="/contapagar" :fields="fields" classFilterColumn="historico" ref="contaPagar" :perPage="10"
      :multiselect="true">
      <template slot="customRow" scope="props">
        <span slot="documento" v-if="props.field == 'documento'">
          <span class="pl-0 pt-8">
            <div class="d-flex align-items-center">
              <div>
                <a class="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg">{{ props.rowData.documento
                }}</a>
                <span class="text-muted font-weight-bold d-block">{{
                  props.rowData.historico
                }}</span>
              </div>
            </div>
          </span>
        </span>
        <span slot="fornecedor" v-if="props.field == 'fornecedor'">
          <span class="pl-0 pt-8">
            <div class="d-flex align-items-center">
              <span class="text-muted font-weight-bold d-block">{{
                props.rowData.fornecedor
              }}</span>
            </div>
          </span>
        </span>
        <span slot="created_at" v-if="props.field == 'created_at'">
          <div class="font-weight-bold text-muted">
            {{ formatDate(props.rowData.created_at) }}
          </div>
        </span>
        <span slot="data_vencimento" v-if="props.field == 'data_vencimento'">
          <div class="font-weight-bold text-muted">
            {{ formatDate(props.rowData.data_vencimento) }}
          </div>
        </span>
        <span slot="data_pagamento" v-if="props.field == 'data_pagamento'">
          <div class="font-weight-bold text-muted" v-if="props.rowData.data_pagamento != null">
            {{ formatDate(props.rowData.data_pagamento) }}
          </div>
        </span>
        <span slot="valor" v-if="props.field == 'valor'">
          <div v-if="props.rowData.valor_total > 0" v-b-tooltip="'Conta está calculando Juros: Valor Orignal + Juros + Multa - Desconto'
            ">
            <span class="text-dark-75 font-weight-bolder d-block font-size-lg">R$
              {{ numberToReal(props.rowData.valor_total, 2) }}
            </span>
            <span class="text-muted font-weight-bold d-block" style="font-size: 12px">
              R$ {{ numberToReal(props.rowData.valor, 2) }} +
              {{ numberToReal(props.rowData.juros, 2) }} +
              {{ numberToReal(props.rowData.multa, 2) }} -
              {{ numberToReal(props.rowData.desconto, 2) }}
            </span>
          </div>
          <span class="text-dark-75 font-weight-bolder d-block font-size-lg" v-else>R$
            {{ numberToReal(props.rowData.valor, 2) }}
          </span>
        </span>
        <span slot="status" v-if="props.field == 'status'">
          <div v-html="onRowStatus(props.rowData)"></div>
        </span>
        <span slot="actions" v-if="props.field == 'actions'">
          <div class="row-actions" style="text-align: right !important">
            <span style="overflow: visible; position: relative; width: 130px">
              <div class="dropdown dropdown-inline">
                <router-link v-if="!props.rowData.data_pagamento" class="btn btn-sm btn-info btn-hover-info btn-icon mr-2"
                  v-b-tooltip="'Editar'" :to="{
                    name: 'contasPagarEditar',
                    params: {
                      id: props.rowData.id,
                    },
                  }">
                  <Pencil :size="16" title="Editar" />
                </router-link>
                <button v-if="props.rowData.id_empresa && !props.rowData.data_pagamento
                  " @click="excluir(props.rowData)" class="btn btn-sm btn-danger btn-hover-danger btn-icon mr-2"
                  v-b-tooltip="'Excluir'">
                  <TrashCanIcon :size="16" />
                </button>
              </div>
            </span>
          </div>
        </span>
      </template>
    </TableApp>
    <Chart endpoint="/contapagar/total" modulo="contapagar"/>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Pencil from "vue-material-design-icons/Pencil.vue";
import TrashCanIcon from "vue-material-design-icons/TrashCan.vue";
import TableApp from "@/components/Table/Table2";
import { SistemaMixin } from "@/modules/Application";
import Chart from "@/components/Charts/Charts.vue";
import FilterFields from "@/components/FilterFields";
import { actionsStore } from "@/components/Table/actionsStore";
import Menu from "vue-material-design-icons/Menu";
import { Money } from "v-money";
import { MONEY as settingMoney } from "@/modules/Application";
import DatePickerInput from "@/components/DatePickerInput/index.vue";
import { filtersStore } from "@/components/FilterFields/filtersStore";

export default {
  name: "app-conta-pagar",
  mixins: [SistemaMixin],
  components: {
    Pencil,
    TrashCanIcon,
    TableApp,
    Chart,
    FilterFields,
    Menu,
    Money,
    DatePickerInput,
  },
  data() {
    return {
      actionsStore,
      filtersStore,
      resumo: {},
      configMask: settingMoney,
      meioPagamento: null,
      meiosPagamentos: [],
      bancoPagamento: null,
      showTooltipMenu: false,
      dataPagamento: null,
      bancos: [
        {
          id_banco: 99999,
          nome: "Movimento Caixa",
        },
      ],
      fields: [
        {
          label: "Documento",
          field: "documento",
          type: "text",
          sortable: false,
          slot: true,
          filterOptions: {
            enabled: false,
            placeholder: "Filtrar Documento",
          },
          width: "10%",
        },
        {
          label: "Fornecedor",
          field: "fornecedor",
          type: "text",
          slot: true,
          sortable: false,
          filterOptions: {
            enabled: false,
            placeholder: "Filtrar Fornecedor",
          },
          width: "20%",
        },
        {
          label: "Emissão",
          field: "created_at",
          type: "date",
          filterable: true,
          slot: true,
          dateInputFormat: "yyyy-MM-dd",
          dateOutputFormat: "dd-MM-yyyy",
          width: "10%",
        },
        {
          label: "Vencimento",
          field: "data_vencimento",
          filterable: true,
          slot: true,
          type: "date",
          dateInputFormat: "yyyy-MM-dd",
          dateOutputFormat: "dd-MM-yyyy",
          filterOptions: {
            enabled: false,
            placeholder: "Data",
            filterFn: this.dateRangeFilter,
          },
          width: "10%",
        },
        {
          label: "Pagamento",
          field: "data_pagamento",
          slot: true,
          type: "date",
          dateInputFormat: "yyyy-MM-dd",
          dateOutputFormat: "dd-MM-yyyy",
          width: "10%",
        },
        {
          label: "Valor",
          field: "valor",
          slot: true,
        },
        {
          label: "Status",
          field: "status",
          slot: true,
          filterOptions: {
            enabled: false,
            placeholder: "Todas",
            filterDropdownItems: ["À pagar", "Paga", "Atrasada"],
          },
        },
        {
          label: "Ações",
          field: "actions",
          sortable: false,
          slot: true,
          width: "15%",
        },
      ],
      actionTableFields: [
        {
          key: "documento",
          label: "Documento",
        },
        {
          key: "cliente",
          label: "Cliente",
        },
        {
          key: "vencimento",
          label: "Vencimento",
        },
        {
          key: "pagamento",
          label: "Pagamento",
        },
        {
          key: "valor",
          label: "Valor R$",
        },
        {
          key: "juros",
          label: "Juros R$",
        },
        {
          key: "multa",
          label: "Multa R$",
        },
        {
          key: "desconto",
          label: "Desconto R$",
        },
        {
          key: "valor_total",
          label: "Vlr. Receber R$",
        },
        {
          key: "status",
          label: "Status",
        },
        {
          key: "actions",
          label: "",
        },
      ],
    };
  },
  methods: {
    dateRangeFilter(data_vencimento, filterString) {
      let dateRange = filterString.split("to");
      let startDate = Date.parse(dateRange[0]);
      let endDate = Date.parse(dateRange[1]);
      return (data_vencimento =
        Date.parse(data_vencimento) >= startDate &&
        Date.parse(data_vencimento) <= endDate);
    },
    async getDataInit() {
      const bancos = await this.getBancosByEmpresa();
      this.bancos.push(...bancos);
      this.bancoPagamento = this.bancos[0];
      const response = await this.$http.post("/financeiro/meios-pagamento");
      this.meiosPagamentos = response.data;
      this.meioPagamento = this.meiosPagamentos[0];
    },
    handleRemoveContaAPagar({ index }) {
      this.actionsStore.selectedRows = this.actionsStore.selectedRows.filter(
        (row, rowIndex) => index !== rowIndex
      );
    },
    async pagarContas() {
      this.addLoadingToButton("pagar_contas");
      try {
        await this.$http.post("/contapagar/pagamento", {
          id_banco: this.bancoPagamento.id_banco,
          id_meio_pagamento: this.meioPagamento.id,
          data_pagamento: this.dataPagamento,
          contas_pagar: this.actionsStore.selectedRows.map((conta) => ({
            id: conta.id,
          })),
        });
        this.actionsStore.selectedRows = [];
        this.actionsStore.updateTable = !this.actionsStore.updateTable;
        this.meioPagamento = null;
        this.bancoPagamento = null;
        this.$toast.success("Contas paga com sucesso!");
        this.$refs.contaPagar.refresh();
        this.$bvModal.hide("menuAcoes");
      } catch (error) {
        console.log(error);
        this.meioPagamento = null;
        this.bancoPagamento = null;
        this.$bvModal.hide("menuAcoes");
      }
      this.removeLoadingToButton("pagar_contas");
    },
    onRowStatus(dataItem, onlyLabel = false) {
      if (
        dataItem.data_vencimento < this.thisDate() &&
        dataItem.data_pagamento === null
      ) {
        return !onlyLabel
          ? '<span class="label label-lg label-light-danger label-inline">Atrasada</span>'
          : "Atrasada";
      }
      if (
        dataItem.data_vencimento > this.thisDate() &&
        dataItem.data_pagamento === null
      ) {
        return !onlyLabel
          ? '<span class="label label-lg label-light-warning label-inline">Pendente</span>'
          : "Pendente";
      }
      if (dataItem.pago == true) {
        return !onlyLabel
          ? '<span class="label label-lg label-light-success label-inline">Paga</span>'
          : "Paga";
      }

      return "";
    },
    excluir(data) {
      this.getRequestAdmin(() => {
        this.$swal
          .fire({
            icon: "warning",
            title: "Excluir o lançamento: " + data.historico + "?",
            showCancelButton: true,
            confirmButtonText: `Sim`,
            cancelButtonText: `Não`,
            confirmButtonColor: "#f64e60",
          })
          .then(async (result) => {
            if (result.isConfirmed) {
              await this.$http.post("/contapagar/delete", {
                id: data.id,
              });
              this.$refs.contaPagar.refresh();
              this.$toast.success("Lançamento excluído!");
            }
          });
      })
    },
    async estornar() {
      this.addLoadingToButton("estornar_contas");
      try {
        await this.$http.post("/contapagar/estornar", {
          contas_pagar: this.actionsStore.selectedRows.map((conta) => ({
            id: conta.id,
          })),
        });
        this.actionsStore.selectedRows = [];
        this.actionsStore.updateTable = !this.actionsStore.updateTable;
        this.$toast.success("Contas estornadas com sucesso!");
        this.$refs.contaPagar.refresh();
        this.$bvModal.hide("menuAcoes");
        this.$bvModal.hide("estornarContas");
      } catch (error) {
        this.$bvModal.hide("estornarContas");
      }
      this.removeLoadingToButton("estornar_contas");
    },
    async marcar() {
      this.addLoadingToButton("marcarContas");
      try {
        await this.$http.post("/contapagar/marcar", {
          contas_pagar: this.actionsStore.selectedRows.map((conta) => ({
            id: conta.id,
          })),
        });
        this.actionsStore.selectedRows = [];
        this.actionsStore.updateTable = !this.actionsStore.updateTable;
        this.$toast.success("Contas marcadas com sucesso!");
        this.$refs.contaPagar.refresh();
        this.$bvModal.hide("menuAcoes");
        this.$bvModal.hide("marcarContas");
      } catch (error) {
        this.$bvModal.hide("marcarContas");
      }
      this.removeLoadingToButton("marcarContas");
    },
  },
  watch: {
    'filtersStore.filterOptionSelected': {
      handler(value) {
        switch (value.id) {
          case 1:
            this.getCurrentDayData('contasPagar');
            break;
          case 2:
            this.getYesterdayData('contasPagar');
            break;
          case 3:
            this.getCurrentWeekData('contasPagar');
            break;
          case 4:
            this.getLastWeekData('contasPagar');
            break;
          case 5:
            this.getMonthData('contasPagar');
            break;
          default:
            break;
        }
      }, deep: true
    },
  },
  computed: {
    ...mapGetters(["layoutConfig", "itsblocked"]),
    config() {
      return this.layoutConfig();
    },
    pagamentoTableItems() {
      if (actionsStore.selectedRows.length === 0) return [];
      return actionsStore.selectedRows
        .map((row) => ({
          documento: row.documento,
          cliente: row.cliente,
          vencimento: row.data_vencimento,
          pagamento: row.data_pagamento,
          valor: this.numberToReal(row.valor, 2),
          juros: this.numberToReal(row.juros, 2),
          multa: this.numberToReal(row.multa, 2),
          valor_total: this.numberToReal(row.valor_total, 2),
          desconto: this.numberToReal(row.desconto, 2),
          status: this.onRowStatus(row, true),
        }))
        .filter((item) => item.status !== "Paga");
    },
    totalPagamento() {
      if (actionsStore.selectedRows.length === 0) return 0;
      return actionsStore.selectedRows.reduce(
        (acc, row) =>
          acc +
          parseFloat(row.valor) +
          parseFloat(row.juros) +
          parseFloat(row.multa) -
          parseFloat(row.desconto),
        0
      );
    },
  },
  mounted() {
    if (this.itsblocked) {
      this.$router.push({ name: "assinatura" });
    }
    this.getDataInit();
  },
};
</script>

<style>
.flatpickr-months .flatpickr-month,
.flatpickr-current-month .flatpickr-monthDropdown-months,
.flatpickr-weekdays,
span.flatpickr-weekday {
  background: var(--primary-color) !important;
}

.flatpickr-calendar.arrowTop:before {
  border-bottom-color: var(--primary-color) !important;
  color: var(--primary-color) !important;
}
</style>
