<template>
  <div class="card card-custom card-stretch gutter-b">
    <div class="card-header border-0 py-5">
      <h3 class="card-title align-items-start flex-column">
        <span class="card-label font-weight-bolder text-dark">
          Editar cadastro
        </span>
      </h3>
    </div>
    <Form url="/pessoa/update-contador" :pessoa="pessoa" />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Form from "./Form.vue";
import { PessoaMixin } from "@/modules/Application";
import PessoaData from "./PessoaData";

export default {
  name: "app-contador-update",
  mixins: [PessoaMixin],
  data() {
    return {
      pessoa: new PessoaData({}),
    };
  },
  components: {
    Form,
  },
  methods: {
    ...mapActions(["setLoading"]),
    async getInit() {
      try {
        const codigo = this.$route.params.id_pessoa;

        this.setLoading(true);

        const data = await this.getPessoa(codigo);

        this.setLoading(false);

        if (!data.id_pessoa || data == null) {
          this.$router.push({ name: "pessoasLista" });
          return this.$toast.error("Cliente não encontrado!");
        }

        this.pessoa = new PessoaData(data);
      } catch {
        return;
      }
    },
  },
  computed: {
    ...mapGetters(["itsblocked"]),
  },
  mounted() {
    if (this.itsblocked) {
      this.$router.push({ name: "assinatura" });
    }
    this.getInit();
  },
};
</script>
