<template>
  <div>
    <Label bold v-if="label !== null">{{ label }}</Label>
    <template v-if="!!options.length">
      <vSelect
        :label="labelBusca"
        :ref="refBusca"
        :class="inputClass"
        :value="value"
        :filterable="filterable"
        :options="options"
        @search="handleSearch"
        @input="handleChange"
      >
        <template slot="option" slot-scope="option" v-if="!!options">
          <slot name="options" :data="option"></slot>
        </template>
        <template slot="selected-option" slot-scope="option">
          <slot name="selected" :data="option"></slot>
        </template>
      </vSelect>
    </template>
    <template v-else>
      <vSelect
        :label="labelBusca"
        :class="inputClass"
        :filterable="filterable"
        :options="newRegister"
        @search="handleSearch"
        @input="register"
      >
        <template slot="selected-option" slot-scope="option">
          <slot name="selected" :data="option"></slot>
        </template>
      </vSelect>
    </template>
  </div>
</template>

<script>
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import { Label } from "./index";

export default {
  name: "SelectWithRegister",
  data() {
    return {
      newRegister: [
        {
          name: "Adicionar novo registro"
        }
      ],
      rawSearch: ""
    };
  },
  components: {
    vSelect,
    Label
  },
  props: {
    busca: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: null
    },
    placeholder: {
      type: String
    },
    labelBusca: {
      type: String
    },
    refBusca: {
      type: String
    },
    inputClass: {
      type: String
    },
    filterable: {
      type: Boolean,
      default: false
    },
    search: {
      type: Function,
      required: true
    },
    options: {
      type: Array
    },
    register: {
      type: Function
    },
    registerLabel: {
      type: String
    },
    change: {
      type: Function
    },
    value: {
      type: Object
    }
  },
  methods: {
    handleSearch(search) {
      this.search(search);
      this.rawSearch = search;
    },
    handleChange(selected) {
      this.change(selected);
    }
  }
};
</script>
