import contasReceber from "@/modules/ContaReceber/pages/Index";
import contasReceberNovo from "@/modules/ContaReceber/pages/Create";
import contasReceberEditar from "@/modules/ContaReceber/pages/Update";

export default [
  {
    name: "contasReceber",
    path: "/contasReceber",
    component: contasReceber,
    meta: { title: "Contas Receber" },
  },
  {
    name: "contasReceberNovo",
    props: true,
    path: "/contasReceber/novo",
    component: contasReceberNovo,
    meta: { title: "Contas Receber" },
  },
  {
    name: "contasReceberEditar",
    path: "/contasReceber/:id/editar",
    component: contasReceberEditar,
    meta: { title: "Contas Receber" },
  },
];
