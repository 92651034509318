<template>
  <div class="card card-custom gutter-b" style="box-shadow: none">
    <div class="card-header card-header-tabs-line">
      <ul
        class="nav nav-dark nav-bold nav-tabs nav-tabs-line"
        role="tablist"
        ref="builder-tab"
      >
        <li class="nav-item">
          <a
            class="nav-link active"
            data-tab="0"
            data-toggle="tab"
            href="#"
            role="tab"
          >
            Lista de Clientes
          </a>
        </li>
      </ul>
    </div>

    <b-form class="form" @submit.stop.prevent="onSubmit">
      <div class="card-body">
        <label
          >Revenda: {{ revenda.nome }}<br />
          Endereço: {{ revenda.logradouro }}, {{ revenda.numero }},
          {{ revenda.bairro }}, {{ revenda.cep }}, {{ revenda.cidade }} -
          {{ revenda.uf }}<br />
          <label>Telefone: {{ revenda.fone }}</label
          ><br />
          <span class="label label-lg label-inline label-light-warning"
            ><strong>Total: {{ totalClientes }}</strong></span
          ></label
        ><br />
        <b-tabs class="hide-tabs" v-model="tabIndex">
          <b-tab active>
            <b-row>
              <b-col md="12">
                <table class="table table-hover">
                  <thead>
                    <tr>
                      <th>Cliente</th>
                      <th>CNPJ/CPF</th>
                      <th>Telefone</th>
                      <th>Data Validade</th>
                      <th>Data Pagamento</th>
                      <th>Valor</th>
                      <th>Status</th>
                      <th>Bloqueio</th>
                      <th>Data criação</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(c, index) in clientes"
                      :key="'cliente_' + index"
                    >
                      <td>
                        {{ c.cliente }}<br />
                        <labe
                          class="text-muted font-weight-bold d-block"
                          style="font-size: 12px"
                        >
                          {{ c.empresa_cidade }} - {{ c.empresa_uf }}
                        </labe>
                      </td>
                      <td>
                        {{ formatCpfCnpj(c.cnpj) }}
                      </td>
                      <td>
                        {{ c.fone }}
                      </td>
                      <td>
                        {{ formatDate(c.data_validade) }}
                      </td>
                      <td>
                        {{ formatDate(c.data_pagamento) }}
                      </td>
                      <td>
                        {{ numberToReal(c.valor, 2) }}
                      </td>
                      <td>
                        <span
                          v-if="c.status"
                          class="
                            label label-lg label-inline label-light-success
                          "
                          >Ativo</span
                        >
                        <span
                          v-else
                          class="label label-lg label-inline label-light-danger"
                          >Inativo</span
                        >
                      </td>
                      <td>
                        <span
                          v-if="c.bloqueado"
                          class="label label-lg label-inline label-light-danger"
                          >Bloqueado</span
                        >
                        <span
                          v-else
                          class="
                            label label-lg label-inline label-light-success
                          "
                          >Não</span
                        >
                      </td>
                      <td>
                        {{ formatDate(c.data_criacao) }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </b-col>
            </b-row>
          </b-tab>
        </b-tabs>
      </div>

      <div class="card-footer">
        <div class="row">
          <div class="col-lg-12" style="text-align: center">
            <router-link
              class="btn btn-clean font-weight-bold"
              :to="{
                name: 'revendasLista',
              }"
            >
              Voltar
            </router-link>
          </div>
        </div>
      </div>
    </b-form>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { mask } from "vue-the-mask";
import { SistemaMixin, EmitenteMixin } from "@/modules/Application";

export default {
  name: "app-clientes-lista",
  directives: {
    mask,
  },
  mixins: [SistemaMixin, EmitenteMixin],
  data() {
    return {
      clientes: [],
      revenda: {},
      tabIndex: 0,
      totalClientes: 0,
    };
  },
  async mounted() {
    await this.getDataInit();

    if (!this.isNuts) {
      this.$router.push({ name: "dashboard" });
    }
  },
  methods: {
    ...mapActions(["setLoading"]),
    async getDataInit() {
      this.setLoading(true);
      const id = this.$route.params.id_revenda;
      const clientes = await this.getClientesByRevenda(id);
      this.setLoading(false);

      if (clientes == null) {
        return this.$toast.warning("Nenhum cliente vinculado a revenda!");
      }

      this.clientes = clientes.clientes;
      this.revenda.nome = clientes.clientes[0].revenda_nome ?? null;
      this.revenda.fone = clientes.clientes[0].revenda_fone ?? null;
      this.revenda.uf = clientes.clientes[0].revenda_uf ?? null;
      this.revenda.cidade = clientes.clientes[0].revenda_cidade ?? null;
      this.revenda.cep = clientes.clientes[0].revenda_cep ?? null;
      this.revenda.logradouro = clientes.clientes[0].revenda_logradouro ?? null;
      this.revenda.numero = clientes.clientes[0].revenda_numero ?? null;
      this.revenda.bairro = clientes.clientes[0].revenda_bairro ?? null;

      this.totalClientes = clientes.total;
    },
  },
  computed: {
    ...mapGetters(["isNuts"]),
  },
};
</script>