import { required } from "vuelidate/lib/validators";

export default class IdentificadorData {
  descricao = "";
  status = true;
  id_identificador = null;

  constructor({ descricao = "", status = true, id_identificador = null }) {
    this.descricao = descricao;
    this.status = status;
    this.id_identificador = id_identificador;
  }
}

export const IdentificadorDataRequired = {
  descricao: {
    required,
  },
};
