<template>
  <div style="display: flex; flex-direction: column;">
    <button v-b-toggle.filter-collapse id="filters" @click="showTooltip = false" style="max-width: 40px;"
      :class="`btn ${apliedFilters ? 'btn-success' : 'btn-dark'} font-weight-bolder font-size-sm  mb-2`">
      <FilterOutline :size="18" />
    </button>

    <div v-if="apliedFilters" class="bg-white my-2 d-flex justify-content-between rounded align-items-center">
      <p class="mb-0 font-weight-bold">
        <span class="text-primary mx-2">Filtros:</span> {{ apliedFilters }}
      </p>
      <button class="font-size-sm ml-3 font-weight-bolder bg-primary text-white py-2 px-4 rounded-right"
        @click="removerFiltros">Limpar Filtro</button>
    </div>

    <b-tooltip target="filters" placement="left" :show.sync="showTooltip">
      Filtros
    </b-tooltip>

    <b-collapse id="filter-collapse">
      <b-card class="mb-2">
        <b-row>
          <b-col md="3" v-for="field in fields" :key="field.label">
            <b-form-group :label="field.label" :label-for="field.label"
              v-if="field.field !== 'actions' && field.type !== 'date'">
              <b-form-input :id="field.label" :name="field.label" v-model="fieldsValues[field.field]"
                v-if="field.type !== 'select' && field.type !== 'date'" v-on:keydown.enter="aplicarFiltros"
                @input="(value) => receiveInput(value, field.field)" :type="field.type" :ref="field.label" />

              <b-form-select @input="(value) => setSelectedOption(value, field.field)" :value="fieldsValues[field.field]"
                :key="keyToRender" :options="field.options" v-if="field.type === 'select'" />
            </b-form-group>
            <DatePickerInput v-if="field.type === 'date'" :label="field.label"
              @valueUpdated="(date) => setSelectedDate(date, field.field)" mode="range" :value="dateValues"
              :field="field.field" />
          </b-col>
        </b-row>
        <b-row>
          <b-button variant="primary" @click="aplicarFiltros" class="mr-2">
            Aplicar Filtros
          </b-button>
          <b-button variant="secondary" @click="removerFiltros">
            Remover Filtros
          </b-button>
        </b-row>
      </b-card>
    </b-collapse>
  </div>
</template>

<script>
import FilterOutline from "vue-material-design-icons/Filter";
import { filtersStore } from "./filtersStore";
import DatePickerInput from '@/components/DatePickerInput/index.vue'
import _ from "lodash";
import { SistemaMixin } from "@/modules/Application";
import moment from "moment";

export default {
  name: "fields-filter",
  props: ['fields', 'showComponent'],
  data() {
    return {
      showTooltip: false,
      filtersToAply: null,
      apliedFilters: null,
      keyToRender: 0,
      filtersStore,
      dateValues: {},
      teste: '',
      fieldsValues: this.fields.reduce((obj, field) => ({
        ...obj,
        [field.field]: null,
      }), {}),
    };
  },
  mixins: [SistemaMixin],
  components: {
    FilterOutline,
    DatePickerInput,
  },
  mounted() {},
  watch: {
    filtersStore: {
      handler(store) {
        this.filtersToAply = store[this.moduleName].filters
        if (this.fields.length > 0) {
          for (const label in store[this.moduleName].filters) {
            this.fieldsValues[label] = store[this.moduleName].filters[label]
            if (label.includes('range')) {
              const data = store[this.moduleName].filters[label].join(' até ')
              this.dateValues = {
                ...this.dateValues,
                [label]: data,
              };
              this.teste = data;
            }
          }
          this.apliedFilters = this.fields
            .filter(field => Object.keys(this.filtersToAply).includes(field.field) || Object.keys(this.filtersToAply).includes(`range_${field.field}`))
            .map(field => `${field.label}: ${this.filtersToAply[field.field] ?? this.handleLabelDates(this.filtersToAply[`range_${field.field}`])}`)
            .join(', ');
        }
      }, deep: true, immediate: true
    }
  },
  methods: {
    onCancel() {},
    setSelectedOption(value, field) {
      this.filtersToAply = {
        ...this.filtersToAply,
        [field]: value,
      };
    },
    receiveInput(value, field) {
      this.filtersToAply = {
        ...this.filtersToAply,
        [field]: value,
      };
    },
    handleLabelDates(arrayDates){
      if(arrayDates.length > 1){
        return `${moment(arrayDates[0]).format('DD/MM/YYYY')} até ${moment(arrayDates[arrayDates.length - 1]).format('DD/MM/YYYY')}`
      } else return moment(arrayDates[0]).format('DD/MM/YYYY')
    },
    setSelectedDate(value, field) {
      const arrayOfDates = value.split(' até ')
      this.filtersToAply = {
        ...this.filtersToAply,
        [`range_${field}`]: arrayOfDates,
      };
      this.dateValues = {
        ...this.dateValues,
        [`range_${field}`]: value,
      };
    },
    aplicarFiltros() {
      this.filtersStore[this.moduleName].filters = this.filtersToAply;
      this.filtersStore[this.moduleName].page = 1;
      this.apliedFilters = this.fields
        .filter(field => this.filtersToAply[field.field] !== '' && (Object.keys(this.filtersToAply).includes(field.field) || Object.keys(this.filtersToAply).includes(`range_${field.field}`)))
        .map(field => `${field.label}: ${this.filtersToAply[field.field] ?? this.formatDate(this.filtersToAply[`range_${field.field}`][0])}`)
        .join(', ');
    },
    removerFiltros() {
      this.filtersToAply = {},
        this.filtersStore[this.moduleName].filters = {};
      this.fields.forEach(field => {
        this.fieldsValues[field.field] = null
      })
      this.dateValues = {};
      this.filtersStore[this.moduleName].page = 1;
      this.keyToRender = this.keyToRender + 1;
      this.apliedFilters = null;
    },
  },
  computed: {
    moduleName() {
      return this.$route.fullPath.replace('/', '')
    },
  },
};
</script>

<style>
.filter-fields-container {
  margin-bottom: 30px;
}
</style>
