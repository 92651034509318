<template>
  <b-tab title="Seguro (Opcional)" :active="isTheCurrentTab" :disabled="!isTheCurrentTab" class="mt-5">
    <b-row>
      <b-col md="8">
        <b-form-group id="compania" label-for="compania" label="Compania">
          <b-form-input maxlength="50" v-model="form.compania" autofocus  />
        </b-form-group>
      </b-col>
      <b-col md="8">
        <b-form-group id="cnpj_compania" label-for="cnpj_compania" label="CNPJ Compania">
          <b-form-input id="cnpj_compania" name="cnpj_compania" v-model="form.cnpj_compania"
            v-mask="['##.###.###/####-##']"  />
        </b-form-group>
      </b-col>
      <b-col md="4">
        <b-form-group id="apolice" label-for="apolice" label="Apólice">
          <b-form-input maxlength="22" v-model="form.apolice"  />
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-form-group id="tipo_responsavel" label-for="tipo_responsavel" label="Tipo Responsável">
          <VueSelect ref="tipo_responsavel" :filterable="false" :searchable="false" :options="tiposResponsavel"
            v-model="form.tipo_responsavel" :class="{ hasErrors: tipoResponsavelError }"
            @input="() => tipoResponsavelError = false">
            <template slot="no-options">
              Informe o tipo de responsável
            </template>
            <template slot="option" slot-scope="option">
              <div class="d-center">
                {{ option.descricao }}
              </div>
            </template>
            <template slot="selected-option" slot-scope="option">
              <div class="selected d-center">
                {{ option.descricao }}
              </div>
            </template>
          </VueSelect>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="4">
        <b-form-group id="responsavel_identificacao" label-for="responsavel_identificacao"
          label="Identificação do Responsável">
          <b-form-input maxlength="22" v-model="form.responsavel_identificacao"/>
        </b-form-group>
      </b-col>

    </b-row>
    <b-row>
      <b-col md="4">
        <b-form-group id="averbacoes" label-for="averbacoes" label="Averbações">
          <b-form-input maxlength="5" v-model="averbacaoToAdd" />
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group label-for="add">
          <button @click="addAverbacao(averbacaoToAdd)">
            <Plus class="btn btn-sm btn-info btn-hover-info btn-icon mr-2 mt-9" v-if="windowWidth > 768" />
            <button v-else class="btn btn-info btn-hover-dark font-weight-bolder font-size-lg ml-2">
              Adicionar averbação
            </button>
          </button>
        </b-form-group>
      </b-col>
    </b-row>
    <table class="table table-head-bg table-vertical-center">
      <thead>
        <tr>
          <th>Averbações adicionadas</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(averbacao, index) in form.averbacoes" :key="'item' + index">
          <td class="text-muted font-weight-bold d-block pl-7">
            {{ averbacao }}
          </td>
          <td>
            <div class="row-actions" style="text-align: right !important">
              <span style="overflow: visible; position: relative; width: 130px">
                <div class="action-buttons">
                  <button @click="handleDeleteAverbacao(index)"
                    class="btn btn-sm btn-danger btn-hover-danger btn-icon mr-2">
                    <TrashCanIcon :size="16" />
                  </button>
                </div>
              </span>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </b-tab>
</template>

<script>
import { mapGetters, mapState, mapActions } from "vuex";
import { validationMixin } from "vuelidate";
import { SistemaMixin } from "@/modules/Application";
import { store } from "../../storeLogistica";
import VueSelect from "vue-select";
import _ from "lodash";
import { mask } from "vue-the-mask";
import Plus from "vue-material-design-icons/PlusThick.vue";
import TrashCanIcon from "vue-material-design-icons/TrashCan.vue";


export default {
  name: "app-mdfe-form-seguroTab",
  components: {
    VueSelect,
    Plus,
    TrashCanIcon,
  },
  directives: {
    mask,
  },
  data() {
    return {
      store,
      windowWidth: window.innerWidth,
      form: {
        compania: null,
        tipo_responsavel: null,
        cnpj_compania: null,
        apolice: null,
        responsavel_identificacao: null,
        averbacoes: [],
      },
      tipoResponsavelError: false,
      averbacaoToAdd: null,
      unidadeError: false,
      tiposResponsavel: [],
      unidadesPeso: [],
      fields: [
        {
          label: "Número",
          field: "numero",
          slot: true,
          sortable: false,
          width: "40%",
          filterOptions: {
            enabled: false,
          },
        },
        {
          label: "",
          field: "actions",
          sortable: false,
          slot: true,
          width: "20%",
        },
      ],
    }
  },
  mixins: [
    validationMixin,
    SistemaMixin,
  ],
  methods: {
    ...mapActions(["setLoading"]),
    async getDataInit() {
      const mdfeData = this.$route.params?.mdfeData?.mdfe;
      const [{ data: tipoResponsavelData }] = await Promise.all([
        this.$http.post('/mdfe/get-seguros-tipo'),
      ])
      this.tiposResponsavel = tipoResponsavelData;
      if(mdfeData){
        this.form.compania = mdfeData.seguro[0].compania;
        this.form.cnpj_compania = mdfeData.seguro[0].cnpj_compania;
        this.form.apolice = mdfeData.seguro[0].apolice;
        this.form.tipo_responsavel = tipoResponsavelData.find(responsavel => responsavel.id === mdfeData.seguro[0].tipo_responsavel)
        this.form.responsavel_identificacao = mdfeData.seguro[0].responsavel_identificacao;
        this.form.averbacoes = mdfeData.seguro[0].averbacoes;
      }
    },
    onResize() {
      this.windowWidth = window.innerWidth;
    },
    validateState(field) {
      return !!this.form[field];
    },
    addAverbacao(averbacaoToAdd) {
      if (!averbacaoToAdd) return;
      this.form.averbacoes.push(averbacaoToAdd);
      this.averbacaoToAdd = null;
    },
    handleDeleteAverbacao(index) {
      this.form.averbacoes.splice(index, 1)
    },
  },
  created() {
    this.getDataInit();
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
    });
  },
  computed: {
    ...mapGetters([
      "isAuthenticated",
      "breadcrumbs",
      "pageTitle",
      "layoutConfig"
    ]),
    ...mapState(["loading"]),
    isLoading() {
      return this.loading.loading;
    },
    isTheCurrentTab() {
      return store.currentTab === 5
    }
  },
  watch: {
    "store.currentTab"(newValue, oldValue) {
      if (newValue !== oldValue && newValue === 6 && oldValue < newValue) {
        // if (!this.form.tipo_responsavel) {
        //   this.$toast.error('Tipo de responsavel obrigatorio!')
        //   this.tipoResponsavelError = true;
        //   this.store.currentTab = oldValue;
        // }
        // if (!this.form.compania) {
        //   this.$toast.error('Compania obrigatoria!')
        //   this.store.currentTab = oldValue;
        // }
        // if (!this.form.apolice) {
        //   this.$toast.error('Apólice obrigatoria!')
        //   this.store.currentTab = oldValue;
        // }
        // if (!this.form.cnpj_compania) {
        //   this.$toast.error('CNPJ da compania obrigatoria!')
        //   this.store.currentTab = oldValue;
        // }
        this.store.formData = {
          ...this.store.formData,
          seguro: [{
            ...this.form,
            tipo_responsavel: this.form.tipo_responsavel.id,
            averbacoes: this.form.averbacoes,
          }]
        }
      }
    },
  }
}

</script>

<style></style>