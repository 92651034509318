import { required } from "vuelidate/lib/validators";

export default class NaturezaData {
  natureza = null;
  serie = null;
  finalidade = null;
  dataEmissao = null;
  dataSaida = null;
  horaSaida = null;
  cfop = null;
  keyDevolution = null;
  vendedor = null;
  constructor(
    natureza = null,
    serie = null,
    dataEmissao = null,
    dataSaida = null,
    finalidade = null,
    horaSaida = null,
    cfop = null,
    keyDevolution = null,
    vendedor = null,
  ) {
    this.natureza = natureza;
    this.dataEmissao = dataEmissao;
    this.dataSaida = dataSaida;
    this.finalidade = finalidade;
    this.horaSaida = horaSaida;
    this.serie = serie;
    this.cfop = cfop;
    this.keyDevolution = keyDevolution;
    this.vendedor = vendedor;
  }
}

export const NFeDataRequired = {
  dataEmissao: {
    required,
  },
  finalidade: {
    required,
  },
  natureza: {
    required,
  },
};
