<template>
  <div id="kt_header" ref="kt_header" class="header flex-column header-fixed">
    <!-- FOR MOBILE -->
    <div :class="`${!isMobile ? 'd-none' : ''}`">
      <div class="header-top">
        <div class="w-100 d-lg-flex mr-0 ml-0 justify-content-between p-0">
          <div class="d-flex">
            <div class="d-none d-lg-flex align-items-center mr-10">
              <router-link to="/">
                <img
                  alt="Logo"
                  :src="layoutConfig('self.logo.default')"
                  class="max-h-30px"
                />
              </router-link>
            </div>
            <div class="d-none d-lg-flex align-items-center mr-3">
              <ul
                class="header-tabs nav align-self-end font-size-lg"
                role="tablist"
              >
                <li
                  class="nav-item"
                  @click="handleShowSubmenu(0)"
                  @mouseover="
                    (event) => {
                      tabIndex = 0;
                      handleShowSubmenu(0);
                      setActiveTab(event);
                      storeTabID(item.name);
                    }
                  "
                >
                  <a
                    class="nav-link py-4 px-6 active"
                    @click="
                      (event) => {
                        tabIndex = 0;
                        storeTabID('home');
                        setActiveTab(event);
                      }
                    "
                    data-tab="0"
                    data-toggle="tab"
                    href="#"
                    id="home"
                    role="tab"
                    aria-selected="true"
                    >Home</a
                  >
                </li>
                <li
                  class="nav-item"
                  v-for="(item, index) in headerMenus"
                  v-bind:key="item.name"
                  @click="handleShowSubmenu(index + 1)"
                  @mouseover="
                    (event) => {
                      tabIndex = index + 1;
                      handleShowSubmenu(index + 1);
                      setActiveTab(event);
                      storeTabID(item.name);
                    }
                  "
                >
                  <a
                    class="nav-link py-4 px-6"
                    @click="
                      (event) => {
                        tabIndex = index + 1;
                        setActiveTab(event);
                        storeTabID(item.name);
                      }
                    "
                    :data-tab="`${index + 1}`"
                    data-toggle="tab"
                    v-if="item.checked"
                    role="button"
                    aria-selected="true"
                    :id="item.name"
                    >{{ item.name }}</a
                  >
                </li>
              </ul>
            </div>
          </div>
          <KTTopbar></KTTopbar>
        </div>
      </div>
      <div :class="`header-bottom`">
        <div class="container">
          <div
            class="header-navs header-navs-left"
            id="kt_header_navs"
            ref="kt_header_navs"
          >
            <ul
              class="p-5 p-lg-0 d-flex d-lg-none nav nav-bold nav-tabs"
              role="tablist"
            >
              <li class="nav-item">
                <a
                  class="nav-link btn btn-clean active"
                  @click="
                    (event) => {
                      tabIndex = 0;
                      storeTabID('home');
                      setActiveTab(event);
                    }
                  "
                  data-tab="0"
                  data-toggle="tab"
                  href="#"
                  id="home"
                  role="tab"
                  aria-selected="true"
                  >Home</a
                >
              </li>
              <li
                class="nav-item mr-2"
                v-for="(item, index) in headerMenus"
                v-bind:key="index"
              >
                <a
                  class="nav-link btn btn-clean"
                  @click="
                    (event) => {
                      tabIndex = index + 1;
                      setActiveTab(event);
                      storeTabID(item.name);
                    }
                  "
                  :data-tab="`${index + 1}`"
                  data-toggle="tab"
                  v-if="item.checked"
                  role="button"
                  aria-selected="true"
                  :id="item.name"
                  >{{ item.name }}</a
                >
              </li>
            </ul>
            <b-tabs class="hide-tabs">
              <b-tab :active="tabIndex === 0">
                <div class="tab-pane show active">
                  <div
                    id="kt_header_menu"
                    ref="kt_header_menu"
                    class="header-menu header-menu-mobile header-menu-layout-default"
                  >
                    <ul class="menu-nav">
                      <router-link
                        to="/dashboard"
                        v-slot="{ href, navigate, isActive, isExactActive }"
                      >
                        <li
                          aria-haspopup="true"
                          data-menu-toggle="hover"
                          class="menu-item"
                          :class="[
                            isActive && 'menu-item-active',
                            isExactActive && 'menu-item-active',
                          ]"
                        >
                          <a
                            :href="href"
                            class="menu-link"
                            @click="
                              () => {
                                tabIndex = 0;
                                storeTabID('home');
                                navigate();
                                subMenuShow = false;
                              }
                            "
                          >
                            <i class="menu-icon flaticon-home-2"></i>
                            <span class="menu-text"> Dashboard </span>
                          </a>
                        </li>
                      </router-link>
                    </ul>
                  </div>
                </div>
              </b-tab>
              <b-tab
                v-for="(menu, index) in headerMenus"
                v-bind:key="index"
                :active="tabIndex === index + 1"
              >
                <div class="tab-pane show active">
                  <div
                    id="kt_header_menu"
                    ref="kt_header_menu"
                    class="header-menu header-menu-mobile header-menu-layout-default"
                  >
                    <ul
                      class="menu-nav"
                      v-for="submenu in submenus[index]"
                      v-bind:key="submenu.url"
                    >
                      <router-link
                        :to="`/${submenu.url}`"
                        v-slot="{ href, navigate, isActive, isExactActive }"
                        v-if="submenu.checked"
                      >
                        <li
                          aria-haspopup="true"
                          data-menu-toggle="hover"
                          class="menu-item"
                          :class="[
                            isActive && 'menu-item-active',
                            isExactActive && 'menu-item-active',
                          ]"
                        >
                          <a
                            :href="href"
                            class="menu-link"
                            @click="
                              () => {
                                tabIndex = index + 1;
                                storeTabID(menu.name);
                                subMenuShow = false;
                              }
                            "
                          >
                            <!-- <i
                              :class="`menu-icon ${icons[index][submenuIndex]}`"
                            ></i> -->
                            <span class="menu-text">{{ submenu.name }}</span>
                          </a>
                        </li>
                      </router-link>
                    </ul>
                  </div>
                </div>
              </b-tab>
            </b-tabs>
          </div>
        </div>
      </div>
    </div>
    <!-- FOR DESKTOP -->
    <div
      :class="`${
        isMobile || isFullScreen ? 'd-none' : 'header-nuts-desktop'
      } position-relative pl-25`"
    >
      <div
        class="bg-primary position-absolute left-0 d-flex"
        style="
          width: 60px;
        width: 60px; 
          width: 60px;
          height: 60px;
        height: 60px; 
          height: 60px;
          border-bottom-right-radius: 4px;
          align-items: center;
          justify-content: center;
          cursor: pointer;
        "
      >
        <router-link to="/">
          <img
            alt="Logo"
            :src="layoutConfig('self.logo.iconeWhite')"
            class="max-h-40px mt-1"
          />
        </router-link>
      </div>
      <div class="d-flex justify-content-center align-items-center h-100">
        <div
          class="d-flex cursor-pointer h-100"
          v-for="(item, index) in headerMenus"
          v-bind:key="index"
        >
          <div
            :class="`d-flex align-items-center text-dark mr-2 h-100 ${
              isCurrentMenuAcvtive(item) ? 'menuItemActive' : ''
            }`"
            v-if="item.checked"
            style="box-sizing: border-box"
          >
            <div
              :id="`popover-${index}`"
              class="d-flex justify-content-center align-items-center w-100 h-100"
            >
              <div
                :class="`d-flex align-items-center text-dark font-weight-bold p-1 h-100`"
                :style="
                  isCurrentMenuAcvtive(item)
                    ? { marginTop: '2px', transition: 'none' }
                    : { transition: 'none' }
                "
              >
                {{ item.name }}
              </div>
              <p
                class="text-primary mb-0"
                :style="
                  isCurrentMenuAcvtive(item)
                    ? { marginTop: '2px', transition: 'none' }
                    : { transition: 'none' }
                "
              >
                <ChevronDown :size="18" />
              </p>
            </div>
          </div>

          <b-popover
            :target="`popover-${index}`"
            triggers="hover"
            placement="bottom"
            class="p-0"
            style="padding: 0 !important"
            boundary-padding="2"
          >
            <div
              style="
                display: flex;
                flex-direction: column;
                margin-right: -15px;
                margin-left: -15px;
              "
            >
              <div
                v-for="subMenuItem in submenus[index]"
                v-bind:key="subMenuItem.url"
                class="sumenuItem"
              >
                <router-link
                  :to="`/${subMenuItem.url}`"
                  v-slot="{ href, navigate, isActive, isExactActive }"
                  v-if="subMenuItem.checked"
                  style="
                    width: 100%;
                    height: 100%;
                    margin-left: -5px;
                    padding: 2px 5px;
                  "
                >
                  <a :href="href">
                    <p class="mb-0 text-dark submenuItem">
                      {{ subMenuItem.name }}
                    </p>
                  </a>
                </router-link>
              </div>
            </div>
          </b-popover>
        </div>
      </div>
      <div class="bg-primary pl-3 pr-5 d-flex align-items-center">
        <KTTopbar />
        <p
          class="text-white mb-0 ml-2 cursor-pointer"
          v-b-tooltip="'Sair'"
          @click="handleLogout"
        >
          <Logout :size="20" />
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import KTTopbar from "@/view/layout/header/Topbar.vue";
import KTLayoutHeader from "@/assets/js/layout/base/header.js";
import KTLayoutHeaderMenu from "@/assets/js/layout/base/header-menu.js";
import { userAccessStore } from "../../../userAccessStore.js";
import ChevronDown from "vue-material-design-icons/ChevronDown.vue";
import Logout from "vue-material-design-icons/Logout.vue";
import { LOGOUT } from "@/core/services/store/auth.module";
import swal from "sweetalert";

export default {
  name: "KTHeader",
  data() {
    return {
      tabIndex: 0,
      isFullScreen: false,
      userAccessStore,
      currentTab: -1,
      userAccess: [],
      dropdownActive: false,
      windowWidth: window.innerWidth,
      subMenuShow: false,
      icons: [
        [
          "flaticon-business",
          "flaticon-cart",
          "flaticon2-shopping-cart",
          "flaticon2-digital-marketing",
          "flaticon-paper-plane",
        ],
        [
          "flaticon2-percentage",
          "flaticon2-up",
          "flaticon2-down",
          "flaticon-suitcase",
          "flaticon-piggy-bank",
          "flaticon-piggy-bank",
          "flaticon-paper-plane",
        ],
        [
          "flaticon-user-add",
          "flaticon-shopping-basket",
          "flaticon2-list-2",
          "flaticon2-list-2",
          "flaticon2-list-2",
          "flaticon-users",
          "flaticon-users",
          "flaticon-paper-plane",
        ],
        [
          "flaticon2-cup",
          "flaticon-profile",
          "flaticon2-settings",
          "flaticon2-list-2",
          "flaticon2-list-2",
          "flaticon2-settings",
          "flaticon-coins",
          "flaticon2-list",
        ],
      ],
      isAdmin: false,
    };
  },
  components: {
    KTTopbar,
    ChevronDown,
    Logout,
  },
  async mounted() {
    // Init Desktop & Mobile Headers
    KTLayoutHeader.init(
      this.$refs["kt_header"],
      this.$refs["kt_header_mobile"]
    );
    document.addEventListener(
      "fullscreenchange",
      this.onFullScreenChange,
      false
    );
    document.addEventListener(
      "webkitfullscreenchange",
      this.onFullScreenChange,
      false
    );
    document.addEventListener(
      "mozfullscreenchange",
      this.onFullScreenChange,
      false
    );

    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
    });
    // Init Header Menu
    KTLayoutHeaderMenu.init(
      this.$refs["kt_header_menu"],
      this.$refs["kt_header_navs"]
    );

    const headerRef = this.$refs["kt_header"];

    headerRef.querySelectorAll("a[class='menu-link']").forEach((item) => {
      item.addEventListener("click", () => {
        KTLayoutHeaderMenu.getOffcanvas().hide();
      });
    });
  },
  computed: {
    ...mapGetters(["layoutConfig", "getClasses", "isNuts", "isRevenda"]),
    ...mapState({
      currentUser: (state) => state.auth.user,
    }),
    headerMenus() {
      return this.userAccess;
    },
    isMobile() {
      return this.windowWidth < 992;
    },
    submenus() {
      if (this.userAccess.lenght === 0) return [];
      return this.userAccess.map((item) => item.submenus);
    },
    headerMenuEnabled() {
      return !!this.layoutConfig("header.menu.self.display");
    },

    headerClasses() {
      const classes = this.getClasses("header");
      if (typeof classes !== "undefined") {
        return classes.join(" ");
      }
      return null;
    },

    headerMenuClasses() {
      const classes = this.getClasses("header_menu");
      if (typeof classes !== "undefined") {
        return classes.join(" ");
      }
      return null;
    },

    widthFluid() {
      return this.layoutConfig("header.self.width") === "fluid";
    },
  },

  watch: {
    currentUser(user) {
      this.userAccess = user.recursos;
      this.isAdmin = user.admin;
      this.userAccessStore.access = this.userAccess.reduce((acc1, menu) => {
        const submenus = menu.submenus.reduce((acc2, submenu) => {
          return {
            ...acc2,
            [submenu.url]: submenu.checked,
          };
        }, {});
        return {
          ...acc1,
          ...submenus,
        };
      }, {});
      this.getCurrentTab();
    },
  },
  methods: {
    handleLogout() {
      swal({
        title: "Deseja realmente fazer o Logoff?",
        icon: "info",
        buttons: {
          cancel: {
            text: "Cancelar",
            value: "cancel",
            visible: true,
            closeModal: true,
          },
          confirm: {
            text: "Sim",
            visible: true,
            closeModal: false,
          },
        },
        dangerMode: false,
        closeModal: false,
        closeOnClickOutside: false,
      }).then(async (value) => {
        if (value === "cancel") {
          swal.close();
          swal.stopLoading();
          return;
        }
        swal.close();
        swal.stopLoading();
        this.onLogout();
      });
    },
    onFullScreenChange() {
      const fullscreenElement =
        document.fullscreenElement ||
        document.mozFullScreenElement ||
        document.webkitFullscreenElement;
      if (fullscreenElement) this.isFullScreen = true;
      else this.isFullScreen = false;
    },
    isCurrentMenuAcvtive(item) {
      const nameOfPath = this.$route.path.replace("/", "");
      const isCurrentActiveItem = item.submenus.find(
        (item) => item.url === nameOfPath
      );
      return !!isCurrentActiveItem;
    },
    handleNavigationClick(url) {
      this.$router.push({ path: `/${url}` });
    },
    handleOpenDropdown(index) {
      this.dropdownActive = true;
      this.$refs[index].visible = true;
    },
    handleCloseDropdown(index) {
      this.$refs[index].visible = false;
      this.dropdownActive = false;
    },
    onLogout() {
      this.$store
        .dispatch(LOGOUT)
        .then(() => this.$router.push({ name: "login" }));
    },
    handleShowSubmenu(index) {
      if (this.currentTab !== index) {
        this.subMenuShow = true;
        this.currentTab = index;
        return;
      }
      this.subMenuShow = false;
      this.currentTab = -1;
    },
    onResize() {
      this.windowWidth = window.innerWidth;
    },
    async getCurrentTab() {
      const { tabId, tabIndex } = await JSON.parse(
        localStorage.getItem("tabSelected")
      );
      const tabToSelect = document.getElementById(tabId);
      const tab = tabToSelect.closest('[role="tablist"]');
      const links = tab.querySelectorAll(".nav-link");

      for (let i = 0; i < links.length; i++) {
        links[i].classList.remove("active");
      }
      tabToSelect.classList.add("active");
      this.tabIndex = tabIndex;
    },
    storeTabID(tabId) {
      localStorage.setItem(
        "tabSelected",
        JSON.stringify({
          tabId,
          tabIndex: this.tabIndex,
        })
      );
    },
    setActiveTab(event) {
      let target = event.target;
      if (!event.target.classList.contains("nav-link")) {
        target = event.target.closest(".nav-link");
      }

      const tab = target.closest('[role="tablist"]');
      const links = tab.querySelectorAll(".nav-link");

      for (let i = 0; i < links.length; i++) {
        links[i].classList.remove("active");
      }

      this.tabIndex = parseInt(target.getAttribute("data-tab"));
      target.classList.add("active");
    },
  },
};
</script>
<style scoped>
.header-nuts-desktop {
  background-color: #fff;
  height: 44.5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.submenuItem:hover {
  color: var(--primary-color);
}

.hover-active .b-dropdown-menu {
  display: block !important;
}

.sumenuItem {
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 1rem;
  /* padding: 5px; */
}

.sumenuItem:hover {
  color: var(--primary-color);
  background-color: var(--hover-sub-menu);
  transition: all 0s !important;
}

.sumenuItem:hover::before {
  opacity: 1;
}

.sumenuItem:hover::after {
  opacity: 1;
}

.sumenuItem::before {
  content: "";
  display: inline-block;
  width: 2px;
  height: 25px;
  border-radius: 20%;
  background-color: var(--primary-color);
  /* Cor do círculo */
  margin-right: 5px;
  /* Espaçamento entre o círculo e o texto */
  opacity: 0;
  /* Inicialmente, o círculo é invisível */
  transition: opacity 0.3s;
  /* Adicione uma transição suave */
  /* margin-top: -1px; */
}

.menuItemActive {
  box-sizing: border-box;
  border-bottom: 2px solid var(--primary-color);
  transition: none;
}

.sumenuItem::after {
  content: "";
  display: inline-block;
  width: 2px;
  height: 25px;
  border-radius: 20%;
  background-color: var(--primary-color);
  /* Cor do círculo */
  /* Espaçamento entre o círculo e o texto */
  opacity: 0;
  /* Inicialmente, o círculo é invisível */
  transition: opacity 0.3s;
  /* Adicione uma transição suave */
  /* margin-top: -1px; */
}

</style>