<template>
  <h2 class="title sub-title" :class="classes">{{ title }}</h2>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true
    },
    titleAlign: {
      type: String,
      validator: () => ["left", "right", "center"],
      default: () => "left"
    },
    margin: {
      type: String,
      validator: () => ["default", "large"],
      default: () => "default"
    }
  },
  name: "SubTitle",
  computed: {
    classes() {
      return {
        [`text-${this.titleAlign}`]: true,
        "title-margin-large": true
      };
    }
  }
};
</script>
