<template>
  <label class="switch-container input-margin">
    <input
      type="checkbox"
      @change="updateValue"
      :checked="value"
      :disabled="disabled"
      :id="id"
    />
    <span class="container-align">
      <span class="switch" :class="switchClass"></span>
      <span class="text">{{ label }}</span>
    </span>
  </label>
</template>

<script>
export default {
  name: "CheckboxSwitch",
  props: {
    id: {
      type: String,
      required: true
    },
    label: {
      type: String
    },
    value: {
      type: Boolean,
      required: true
    },
    disabled: {
      type: Boolean,
      default: () => false
    }
  },
  methods: {
    updateValue() {
      this.$emit("input", !this.value);
    }
  },
  computed: {
    switchClass() {
      return {
        disabled: this.disabled,
        checked: this.value
      };
    }
  }
};
</script>
