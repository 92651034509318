<template>
  <Form url="/compras/update" :compra="compra" />
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import CompraData from "./CompraData";
import Form from "./Form.vue";

export default {
  name: "app-compra-update",
  data() {
    return {
      compra: new CompraData({}),
    };
  },
  components: {
    Form,
  },
  methods: {
    ...mapActions(["setLoading"]),
    async getCompra(id) {
      try {
        const { data } = await this.$http.post("/compras/find", {
          id: id,
        });
        return data;
      } catch (error) {
        return null;
      }
    },
    async getInit() {
      try {
        const id = this.$route.params.id;

        this.setLoading(true);

        const compra = await this.getCompra(id);

        this.setLoading(false);

        if (!compra.id || compra == null) {
          this.$router.push({ name: "comprasLista" });
          return this.$toast.error("Compra não encontrada!");
        }

        this.compra = new CompraData({ compra });
      } catch {
        return;
      }
    },
  },
  computed: {
    ...mapGetters(["itsblocked"]),
  },
  mounted() {
    this.getInit();
    if (this.itsblocked) {
      this.$router.push({ name: "assinatura" });
    }
  },
};
</script>
