<template>
  <div style="position: relative">
    <FilterFields
      :fields="[
        {
          type: 'text',
          label: 'Nome',
          field: 'nome',
        },
        {
          type: 'select',
          label: 'Status',
          field: 'status',
          options: ['Todos', 'Ativo', 'Inativo'],
        },
      ]"
    />
    <TableApp
      titulo="Vendedores"
      acaoBtn="/#/vendedor/novo"
      textoBtn="Novo"
      api-url="/vendedor/lista"
      :fields="fields"
      classFilterColumn="descricao"
      ref="vendedores"
      :perPage="10"
    >
      <template slot="customRow" scope="props">
        <span slot="nome" v-if="props.field == 'nome'">
          <span class="pl-0 pt-8">
            <div class="d-flex align-items-center">
              <div>
                <span class="text-dark-75font-weight-bold d-block">{{
                  props.rowData.nome
                }}</span>
              </div>
            </div>
          </span>
        </span>
        <span v-if="props.field == 'status'">
          <span
            v-if="props.rowData.status == true"
            class="label label-lg label-inline label-light-success"
            >Ativo</span
          >
          <span v-else class="label label-lg label-inline label-light-danger"
            >Inativo</span
          >
        </span>
        <span v-if="props.field == 'actions'">
          <div class="row-actions" style="text-align: right !important">
            <span style="overflow: visible; position: relative; width: 130px">
              <div class="dropdown dropdown-inline">
                <router-link
                  class="btn btn-sm btn-info btn-hover-info btn-icon mr-2"
                  v-b-tooltip.hover.bottom="'Editar'"
                  :to="{
                    name: 'vendedorEditar',
                    params: {
                      id: props.rowData.id_vendedor,
                    },
                  }"
                >
                  <Pencil :size="16" title="Editar" />
                </router-link>
                <button
                  @click="excluir(props.rowData)"
                  class="btn btn-sm btn-danger btn-hover-danger btn-icon mr-2"
                  v-b-tooltip.hover.bottom="'Excluir'"
                >
                  <TrashCanIcon :size="16" />
                </button>
              </div>
            </span>
          </div>
        </span>
      </template>
    </TableApp>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Pencil from "vue-material-design-icons/Pencil.vue";
import TrashCanIcon from "vue-material-design-icons/TrashCan.vue";
import TableApp from "@/components/Table/Table2";
import { SistemaMixin } from "@/modules/Application";
import FilterFields from "@/components/FilterFields";

export default {
  name: "app-vendedores",
  mixins: [SistemaMixin],
  components: {
    Pencil,
    TrashCanIcon,
    TableApp,
    FilterFields,
  },
  data() {
    return {
      fields: [
        {
          label: "Nome",
          field: "nome",
          type: "text",
          sortable: true,
          slot: true,
          width: "60%",
        },
        {
          label: "Status",
          field: "status",
          type: "text",
          sortable: false,
          slot: true,
          width: "20%",
        },
        {
          label: "",
          field: "actions",
          sortable: false,
          slot: true,
          width: "15%",
        },
      ],
    };
  },
  methods: {
    excluir(data) {
      this.getRequestAdmin(() => {
        this.$swal
          .fire({
            icon: "warning",
            title: "Excluir o vendedor " + data.nome + "?",
            showCancelButton: true,
            confirmButtonText: `Sim`,
            cancelButtonText: `Não`,
            confirmButtonColor: "#f64e60",
          })
          .then(async (result) => {
            if (result.isConfirmed) {
              await this.$http.post("/vendedor/delete", {
                id_vendedor: data.id_vendedor,
              });
  
              this.$refs.vendedores.refresh();
              this.$toast.success("Vendedor excluído!");
            }
          });
      })
    },
  },
  computed: {
    ...mapGetters(["layoutConfig", "itsblocked", "currentUser"]),
    config() {
      return this.layoutConfig();
    },
  },
  mounted() {
    if (this.itsblocked) {
      this.$router.push({ name: "assinatura" });
    }
  },
};
</script>
