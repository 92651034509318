<template>
  <div class="card card-custom gutter-b" style="box-shadow: none">
    <div class="card-header card-header-tabs-line">
      <ul
        class="nav nav-dark nav-bold nav-tabs nav-tabs-line"
        role="tablist"
        ref="builder-tab"
      >
        <li class="nav-item">
          <a
            class="nav-link active"
            v-on:click="setActiveTab"
            data-tab="0"
            data-toggle="tab"
            href="#"
            role="tab"
          >
            Novo Cliente -<strong>
              Este cadastro tem direito de 5 dias gratuitos</strong
            >
          </a>
        </li>
      </ul>
    </div>

    <b-form class="form" @submit.stop.prevent="onSubmit">
      <div class="card-body">
        <b-tabs class="hide-tabs" v-model="tabIndex">
          <b-tab active>
            <b-row>
              <b-col md="4">
                <b-form-group
                  id="usuario"
                  label-for="usuario"
                  label="Nome usuário"
                >
                  <b-form-input
                    v-model="$v.form.nome.$model"
                    :state="validateState('nome')"
                    autofocus
                  >
                  </b-form-input>
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group id="email" label-for="email" label="E-mail">
                  <b-form-input
                    type="email"
                    v-model="$v.form.email.$model"
                    :state="validateState('email')"
                  >
                  </b-form-input>
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group
                  id="nome_empresa"
                  label-for="nome_empresa"
                  label="Razão Social"
                >
                  <b-form-input
                    v-model="$v.form.nome_empresa.$model"
                    :state="validateState('nome_empresa')"
                  >
                  </b-form-input>
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group
                  id="fone"
                  label="Telefone"
                  label-for="example-input-0"
                >
                  <b-form-input
                    v-mask="['(##) #### ####', '(##) # #### ####']"
                    v-model="$v.form.fone.$model"
                    :state="validateState('fone')"
                    aria-describedby="input-0-live-feedback"
                  >
                  </b-form-input>
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group label="Planos" label-for="plans">
                  <select
                    name="id_plano"
                    id="id_plano"
                    v-model="form.id_plan"
                    class="form-control"
                  >
                    <option
                      v-for="(p, index) in plans"
                      :value="p.id_plan"
                      :key="'id_plan' + index"
                    >
                      {{ p.name }} - {{ p.price }}
                    </option>
                  </select>
                </b-form-group>
              </b-col>
            </b-row>
          </b-tab>
        </b-tabs>
      </div>

      <div class="card-footer">
        <div class="row">
          <div class="col-lg-12" style="text-align: center">
            <button
              type="submit"
              ref="submit_emitente"
              class="btn btn-primary font-weight-bold"
            >
              Salvar
            </button>
            <router-link
              class="btn btn-clean font-weight-bold"
              :to="{
                name: 'clientesLista',
              }"
            >
              Cancelar
            </router-link>
          </div>
        </div>
      </div>
    </b-form>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import { validationMixin } from "vuelidate";
import ClienteData, { ClienteDataRequired } from "./ClienteData";
import { mask } from "vue-the-mask";
import SistemaMixin from "../../Application/mixins/SistemaMixin";

export default {
  name: "app-cliente-form",
  mixins: [validationMixin, SistemaMixin],
  directives: {
    mask,
  },
  watch: {
    cliente(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.form = newValue;
      }
    },
  },
  data() {
    return {
      tabIndex: 0,
      form: {},
      cliente: new ClienteData({}),
      plans: [],
    };
  },
  validations: {
    form: ClienteDataRequired,
  },
  async mounted() {
    await this.getDataInit();

    if (!this.isRevenda) {
      this.$router.push({ name: "dashboard" });
    }
  },
  methods: {
    ...mapActions(["setLoading"]),
    async getDataInit() {
      this.cliente = new ClienteData({});
      this.plans = await this.getPlans();
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    setActivePreviousTab() {
      this.tabIndex = parseInt(localStorage.getItem("builderTab")) || 0;
      const links = this.$refs["builder-tab"].querySelectorAll(".nav-link");

      for (let i = 0; i < links.length; i++) {
        links[i].classList.remove("active");
      }

      this.$refs["builder-tab"]
        .querySelector(`[data-tab="${this.tabIndex}"]`)
        .classList.add("active");
    },
    setActiveTab(event) {
      const tab = event.target.closest('[role="tablist"]');
      const links = tab.querySelectorAll(".nav-link");

      for (let i = 0; i < links.length; i++) {
        links[i].classList.remove("active");
      }

      this.tabIndex = parseInt(event.target.getAttribute("data-tab"));

      event.target.classList.add("active");
    },

    async onSubmit() {
      const submitButton = this.$refs["submit_emitente"];

      try {
        this.$v.form.$touch();

        if (this.$v.form.$anyError) {
          this.$toast.error(
            "Verifique todos os campos obrigatórios do formulário!"
          );
          return;
        }

        if (!this.cliente.id_plan) {
          this.$toast.warning("Selecione um plano para o cliente!");
          return;
        }

        submitButton.classList.add("spinner", "spinner-light", "spinner-right");
        submitButton.setAttribute("disabled", "");

        this.cliente.id_revenda = this.currentUser.usuario_empresa.id_empresa;

        await this.$http.post("empresa/create", this.cliente);

        submitButton.classList.remove(
          "spinner",
          "spinner-light",
          "spinner-right"
        );

        this.$toast.success("Cliente cadastrado com sucesso!");

        submitButton.removeAttribute("disabled", "");
        this.$router.push({ name: "clientesLista" });
      } catch (error) {
        submitButton.classList.remove(
          "spinner",
          "spinner-light",
          "spinner-right"
        );
        submitButton.removeAttribute("disabled", "");
      }
    },
  },
  computed: {
    ...mapGetters(["isRevenda"]),
    ...mapState({
      currentUser: (state) => state.auth.user,
    }),
  },
};
</script>

<style>
.vs__dropdown-toggle {
  border: 2px solid #dedede;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border-radius: 6px;
  outline: none;
  height: 38px;
}
</style>
