import { required } from "vuelidate/lib/validators";

export default class ClienteData {
  nome = "";

  nome_empresa = "";

  email = "";

  fone = "";

  id_plan = null;

  constructor({
    nome = "",
    nome_empresa = "",
    email = "",
    fone = "",
    id_plan = null,
  }) {
    this.nome = nome;
    this.nome_empresa = nome_empresa;
    this.email = email;
    this.fone = fone;
    this.id_plan = id_plan;
  }
}

export const ClienteDataRequired = {
  nome: {
    required,
  },
  nome_empresa: {
    required,
  },
  email: {
    required,
  },
  fone: {
    required,
  },
};
