<template>
  <div class="card card-custom card-stretch gutter-b">
    <div class="card-header border-0 py-5">
      <h3 class="card-title align-items-start flex-column">
        <span class="card-label font-weight-bolder text-dark">
          Novo cadastro
        </span>
      </h3>
    </div>
    <Form url="/pessoa/create-contador" :pessoa="pessoa" />
  </div>
</template>

<script>
import Form from "./Form.vue";
import PessoaData from "./PessoaData";
import { mapGetters } from "vuex";

export default {
  name: "app-contador-create",
  data() {
    return {
      pessoa: new PessoaData({}),
    };
  },
  components: {
    Form,
  },
  computed: {
    ...mapGetters(["itsblocked"]),
  },
  mounted() {
    if (this.itsblocked) {
      this.$router.push({ name: "assinatura" });
    }
  },
};
</script>
