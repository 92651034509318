import { reactive } from 'vue';

export const store = reactive({
  tpNF: 1,
  isUpdate: false,
  formProduto: {},
  transportadorImported: null,
  formNatureza: {},
  formTransportador: {},
  formFornecedor: {},
  formFaturamento: {},
  formImpostos: {},
  formCliente: {},
  formIntermediador: {},
  orcamentos: [],
  pedidos: [],
  currentTab: 0,
  refNFCe: null,
  dataFetched: false,
  cfop: null,
  formRural: {},
  os: [],
  fromDevolucao: false,
  verificaEstoque: false,
});
