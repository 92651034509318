<template>
  <fragment>
    <Label
      v-bind="labelProps"
      :html-for="id"
      :bold="labelBold"
      v-if="label"
      :disabled="disabled"
    >
      {{ label }}
    </Label>

    <b-dropdown class="select" :class="inputClass" :disabled="disabled">
      <template v-slot:button-content>
        {{ placeholderValue }}
        <ChevronUp class="icon-dropdown-select" :size="18" />
      </template>
      <b-dropdown-item-button
        v-for="option in options"
        @click="updateValue(option.value)"
        :key="option.value"
      >
        {{ option.text }}
      </b-dropdown-item-button>
    </b-dropdown>

    <div
      class="input-description input-margin"
      v-if="description"
      :class="descriptionClass"
    >
      {{ description }}
    </div>
  </fragment>
</template>

<script>
import ChevronUp from "vue-material-design-icons/ChevronUp";
import Label from "./Label";

export default {
  name: "Select",
  components: {
    Label,
    ChevronUp
  },
  props: {
    id: {
      type: String,
      required: true
    },
    options: {
      type: Array,
      required: true
    },
    value: {},
    label: {
      type: String
    },
    labelBold: {
      type: Boolean,
      default: () => false
    },
    labelProps: {
      type: Object
    },
    placeholder: {
      type: String
    },
    description: {
      type: String
    },
    success: {
      type: Boolean,
      default: () => false
    },
    error: {
      type: Boolean,
      default: () => false
    },
    disabled: {
      type: Boolean,
      default: () => false
    }
  },
  methods: {
    updateValue(value) {
      this.$emit("input", value);
    }
  },
  computed: {
    placeholderValue() {
      const selected = this.options.filter(
        ({ value }) => value === this.value
      )[0]?.text;
      if (!this.value || !selected) {
        return this.placeholder || "Selecione";
      }

      return selected;
    },
    inputClass() {
      return {
        "input-margin": !this.description,
        success: this.success,
        error: this.error
      };
    },
    descriptionClass() {
      return {
        success: this.success,
        error: this.error
      };
    }
  }
};
</script>
