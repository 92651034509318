import { required } from "vuelidate/lib/validators";

export default class FaturamentoData {
  forma_pagamento = null;
  valor = null;
  vencimento = null;
  subtotal = 0;
  pagamentos = [];
  constructor(
    forma_pagamento = null,
    valor = 0,
    vencimento = null,
    subtotal = 0,
    pagamentos = [],
  ) {
    this.forma_pagamento = forma_pagamento;
    this.valor = valor;
    this.vencimento = vencimento;
    this.subtotal = subtotal;
    this.pagamentos = pagamentos;
  }
}

export const FaturamentoDataRequired = {
  pagamentos: {
    required,
  },
};
