<template>
  <div style="position: relative">
    <FilterFields :fields="[
      {
        type: 'text',
        label: 'Placa',
        field: 'placa',
      },
      {
        type: 'text',
        label: 'Descrição',
        field: 'descricao',
      },
    ]" />
    <TableApp titulo="Veículos" acaoBtn="/#/veiculos/novo-veiculo" textoBtn="Novo veículo" api-url="/veiculos/lista"
      :fields="fields" classFilterColumn="descricao" ref="veiculoList" :perPage="10">
      <template slot="customRow" scope="props">
        <span slot="descricao" class="pl-0 pt-8" v-if="props.field == 'descricao'">
          <div class="d-flex align-items-center">
            <div class="font-weight-bold text-muted">
              {{ props.rowData.descricao }}
            </div>
          </div>
        </span>

        <span slot="valor" class="pl-0 pt-8" v-if="props.field == 'renavam'">
          <div class="d-flex align-items-center">
            <div class="font-weight-bold text-muted">
              {{ props.rowData.renavam }}
            </div>
          </div>
        </span>

        <span slot="valor" class="pl-0 pt-8" v-if="props.field == 'placa'">
          <div class="d-flex align-items-center">
            <div class="font-weight-bold text-muted">
              {{ props.rowData.placa }}
            </div>
          </div>
        </span>

        <span slot="actions" v-if="props.field == 'actions'">
          <div class="row-actions" style="text-align: right !important">
            <span style="overflow: visible; ">
              <div class="action-buttons">
                <button @click="handleEditVeiculo(props.rowData)" v-b-tooltip.bottom="'Editar veículo'"
                  class="btn btn-sm btn-info btn-hover-info btn-icon mr-2">
                  <Pencil :size="16" />
                </button>
                <button @click="handleDeleteVeiculo(props.rowData)" v-b-tooltip.bottom="'Excluir veículo'"
                  class="btn btn-sm btn-danger btn-icon mr-2">
                  <TrashCanIcon :size="16" />
                </button>
              </div>
            </span>
          </div>
        </span>
      </template>
    </TableApp>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { SistemaMixin } from "@/modules/Application";
import TableApp from "@/components/Table/Table2";
import FilterFields from "@/components/FilterFields";
import Pencil from "vue-material-design-icons/Pencil.vue";
import TrashCanIcon from "vue-material-design-icons/TrashCan.vue";
import swal from "sweetalert";

export default {
  name: "veiculos",
  mixins: [SistemaMixin],
  components: {
    TableApp,
    FilterFields,
    Pencil,
    TrashCanIcon,
  },
  data() {
    return {
      fields: [
        {
          label: "Descrição",
          field: "descricao",
          type: "text",
          slot: true,
          width: '50%',
          sortable: false,
        },
        {
          label: "Renavam",
          field: "renavam",
          slot: true,
          sortable: false,
        },
        {
          label: "Placa",
          field: "placa",
          slot: true,
          sortable: false,
        },
        {
          label: "",
          field: "actions",
          sortable: false,
          width: "5%",
          slot: true,
        },
      ],
    };
  },
  created() {

  },
  methods: {
    handleEditVeiculo(data) {
      swal({
        title: "Deseja editar o veículo?",
        text: "Você ira ser direcionado para a pagina de edição aguarde...",
        icon: "info",
        buttons: {
          cancel: {
            text: "Cancelar",
            value: "cancel",
            visible: true,
            closeModal: true,
          },
          confirm: {
            text: "OK",
            visible: true,
            closeModal: false,
          },
        },
        dangerMode: false,
        closeModal: false,
        closeOnClickOutside: false,
      }).then(async (value) => {
        if (value === "cancel") {
          swal.close();
          swal.stopLoading();
          return;
        }

        await this.$http
          .post("/veiculos/find", {
            id_veiculo: data.id_veiculo,
          })
          .then((response) => {
            swal.stopLoading();
            swal.close();
            this.$toast.info("Você será redirecionado agora");
            this.$router.push({
              name: "novoVeiculos",
              params: { veiculoData: { ...response.data, id_veiculo: data.id_veiculo } },
            });
          })
          .catch(() => {
            swal.stopLoading();
            this.$toast.error("Erro ao solicitar o endpoint do veículo");
            swal.close();
          });
      });
    },
    handleDeleteVeiculo(data) {
      this.getRequestAdmin(() => {
        this.$swal
          .fire({
            icon: "warning",
            title: "Excluir o veiculo?",
            showCancelButton: true,
            confirmButtonText: `Sim`,
            cancelButtonText: `Não`,
            confirmButtonColor: "#f64e60",
          })
          .then(async (result) => {
            if (result.isConfirmed) {
              await this.$http.post("/veiculos/delete", {
                id_veiculo: data.id_veiculo,
              });
              this.$refs.veiculoList.refresh();
              this.$toast.success("Veículo excluído!");
            }
          });
      })
    },
  },
  computed: {
    ...mapGetters(["layoutConfig", "itsblocked"]),
    config() {
      return this.layoutConfig();
    },
  },
  async mounted() { },
};
</script>

<style></style>
