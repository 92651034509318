<template>
  <div style="position: relative">
    <FilterFields
      :fields="[
        {
          type: 'text',
          label: 'Nome',
          field: 'nome',
        },
        {
          type: 'text',
          label: 'E-mail',
          field: 'email',
        },
        {
          type: 'text',
          label: 'CPF/CNPJ',
          field: 'cnpj_cpf',
        },
        {
          type: 'date',
          label: 'Data Cadastro',
          field: 'created_at',
        },
        {
          type: 'date',
          label: 'Data Vencimento',
          field: 'data_validade',
        },
        {
          type: 'select',
          label: 'Tipo',
          field: 'tipo',
          options: ['Cliente', 'Revenda', 'Todos'],
        },
        {
          type: 'select',
          label: 'Plano',
          field: 'plano',
          options: [
            'Free',
            'Essencial Anual',
            'Essencial Mensal',
            'Standard Mensal',
            'Standard Anual',
            'Todos',
          ],
        },
      ]"
    />
    <b-dropdown
      id="menu"
      variant="dark"
      v-b-tooltip.right="'Mais ações'"
      style="position: absolute; top: 0; left: 50px"
    >
      <template #button-content>
        <Menu :size="18" />
      </template>
      <b-dropdown-item v-b-modal.clientesNuts
        >Relatório Clientes Nuts
      </b-dropdown-item>
      <b-dropdown-item v-b-modal.clientesRevendas
        >Relatório Clientes Revendas
      </b-dropdown-item>
      <b-dropdown-item v-b-modal.clientePorUF
        >Relatório Clientes por UF/Cidade
      </b-dropdown-item>
    </b-dropdown>

    <b-modal
      id="clientesNuts"
      title="Esse relatório será somente dos clientes Nuts!"
      centered
      size="xs"
    >
      <template #modal-footer="{ ok, cancel }">
        <div>
          <b-button
            variant="primary"
            @click="gerarRelatorioNuts"
            ref="clientesNuts"
            class="mr-5"
          >
            Confirmar
          </b-button>
          <b-button
            @click="
              {
                cancel();
              }
            "
          >
            Cancelar
          </b-button>
        </div>
      </template>
    </b-modal>

    <b-modal
      id="clientePorUF"
      title="Esse relatório será somente dos clientes Nuts!"
      centered
      size="xs"
    >
      <template #modal-footer="{ ok, cancel }">
        <div>
          <b-button
            variant="primary"
            @click="gerarRelatorioPorUF"
            ref="clientePorUF"
            class="mr-5"
          >
            Confirmar
          </b-button>
          <b-button
            @click="
              {
                cancel();
              }
            "
          >
            Cancelar
          </b-button>
        </div>
      </template>
    </b-modal>

    <b-modal
      id="clientesRevendas"
      title="Esse relatório será somente dos clientes das Revendas Nuts!"
      centered
      size="xs"
    >
      <template #modal-footer="{ ok, cancel }">
        <div>
          <b-button
            variant="primary"
            @click="gerarRelatorioRevendasNuts"
            ref="clientesRevendas"
            class="mr-5"
          >
            Confirmar
          </b-button>
          <b-button
            @click="
              {
                cancel();
              }
            "
          >
            Cancelar
          </b-button>
        </div>
      </template>
    </b-modal>

    <TableApp
      titulo="Revendas/Clientes"
      subTitulo="Nuts"
      api-url="empresa/clientes"
      :fields="fields"
      classFilterColumn="clientes"
      ref="clientes"
      :perPage="10"
    >
      <template slot="customRow" slot-scope="props">
        <span slot="nome" v-if="props.field == 'nome'">
          <span class="pl-0 pt-8">
            <div class="d-flex align-items-center">
              <div
                class="symbol symbol-40 symbol-light-primary flex-shrink-0"
                style="margin-right: 10px"
              ></div>
              <div>
                <a
                  href="#"
                  class="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg"
                  >{{ props.rowData.nome }}</a
                >
                <span class="text-muted font-weight-bold d-block"
                  >Revenda: {{ props.rowData.revenda_nome }}<br />
                  {{ props.rowData.revenda_cnpj }}<br />
                  {{ props.rowData.revenda_fone }}<br />
                </span>
                <span class="text-dark-75" v-if="props.rowData.saldo"
                  >Créditos Unicheck: {{ props.rowData.saldo.saldo }} <br />
                </span>
                <span class="text-dark-75" v-if="props.rowData.licenca_revenda"
                  >Licenças Nuts: {{ props.rowData.licenca_revenda.total }}
                </span>
                <span class="text-dark-75" v-if="props.rowData.nivel_revenda">
                  <br>
                  <v-avatar v-if="props.rowData.nivel_revenda.nivel != 1">
                    <img :src="props.rowData.nivel_revenda.selo"/>
                  </v-avatar>
                  <p v-else><strong>Selo Start</strong></p>
                </span>
              </div>
            </div>
          </span>
        </span>
        <span slot="fone" v-if="props.field == 'fone'">
          <span style="width: 110px">
            <div class="font-weight-bolder font-size-lg mb-0">
              {{ props.rowData.fone }}
            </div>
          </span>
        </span>
        <span slot="cnpj_cpf" v-if="props.field == 'cnpj_cpf'">
          <span style="width: 110px">
            <div
              class="font-weight-bolder font-size-lg mb-0"
              v-if="props.rowData.cnpj_cpf"
            >
              {{ formatCpfCnpj(props.rowData.cnpj_cpf) }}
            </div>
            <div v-else></div>
            <div
              class="font-weight-bold text-muted"
              v-show="props.rowData.ie_rg"
            >
              IE: {{ props.rowData.ie_rg }}
            </div>
          </span>
        </span>
        <span slot="data_validade" v-if="props.field == 'data_validade'">
          <span style="width: 110px">
            <div class="font-weight-bold text-muted font-size-lg mb-0">
              Validade: {{ formatDate(props.rowData.data_validade) }} <br />
              Pagamento: {{ formatDate(props.rowData.data_pagamento) }} <br />
              Valor: {{ numberToReal(props.rowData.valor, 2) }}
            </div>
            <div class="font-weight-bold text-muted">
              <span
                v-if="props.rowData.bloqueado"
                class="label label-lg label-inline label-light-danger"
                >Bloqueado</span
              >
              <span
                v-else
                class="label label-lg label-inline label-light-success"
                >Ativo</span
              >
            </div>
          </span>
        </span>
        <span slot="revenda" v-if="props.field == 'revenda'">
          <span
            v-if="props.rowData.revenda"
            class="label label-lg label-inline label-light-success"
            >Revenda</span
          >
          <span v-else class="label label-lg label-inline label-light-primary"
            >Cliente</span
          >
        </span>
        <span slot="plano" v-if="props.field == 'plano'">
          <span
            v-if="props.rowData.id_plan == 1"
            class="label label-lg label-inline label-light-success"
            >Free</span
          >
          <span
            v-if="props.rowData.id_plan == 2"
            class="label label-lg label-inline label-light-primary"
            >Essencial Anual</span
          >
          <span
            v-if="props.rowData.id_plan == 3"
            class="label label-lg label-inline label-light-primary"
            >Essencial Mensal</span
          >
          <span
            v-if="props.rowData.id_plan == 4"
            class="label label-lg label-inline label-light-primary"
            >Standard Mensal</span
          >
          <span
            v-if="props.rowData.id_plan == 5"
            class="label label-lg label-inline label-light-primary"
            >Standard Anual</span
          >
        </span>
        <span slot="actions" v-if="props.field == 'actions'">
          <div class="row-actions" style="text-align: right !important">
            <span style="overflow: visible; position: relative; width: 130px">
              <div class="dropdown dropdown-inline">
                <router-link
                  class="btn btn-sm btn-info btn-hover-info btn-icon mr-2"
                  v-b-tooltip="'Ver dados'"
                  :to="{
                    name: 'revendasEditar',
                    params: {
                      id_empresa: props.rowData.id_empresa,
                    },
                  }"
                >
                  <Pencil :size="16" title="Editar" />
                </router-link>
                <router-link
                  v-show="props.rowData.revenda"
                  class="btn btn-sm btn-info btn-hover-info btn-icon mr-2"
                  v-b-tooltip="'Lista de clientes'"
                  :to="{
                    name: 'revendasListaClientes',
                    params: {
                      id_revenda: props.rowData.id_empresa,
                    },
                  }"
                >
                  <ListaCliente :size="16" />
                </router-link>
                <button
                  @click="setarCreditosUnicheck(props.rowData)"
                  class="btn btn-sm btn-primary btn-hover-primary btn-icon mr-2"
                  v-b-tooltip="'Informar créditos Unicheck'"
                >
                  <InserirLicenca :size="16" />
                </button>
                <button
                  v-if="props.rowData.revenda"
                  @click="setarLicencas(props.rowData)"
                  class="btn btn-sm btn-warning btn-hover-warning btn-icon mr-2"
                  v-b-tooltip="'Informar Licenças para Revenda'"
                >
                  <InserirLicenca :size="16" />
                </button>
                <button
                  v-if="!props.rowData.revenda"
                  @click="tornarRevenda(props.rowData)"
                  class="btn btn-sm btn-warning btn-hover-warning btn-icon mr-2"
                  v-b-tooltip="'Tornar Revenda'"
                >
                  <Revenda :size="16" />
                </button>
                <button
                  @click="liberarAcesso(props.rowData)"
                  class="btn btn-sm btn-success btn-hover-success btn-icon mr-2"
                  v-b-tooltip="'Liberar Acesso'"
                >
                  <Block :size="16" />
                </button>
                <button
                  @click="bloquearAcesso(props.rowData)"
                  class="btn btn-sm btn-danger btn-hover-danger btn-icon mr-2"
                  v-b-tooltip="'Bloquear Acesso'"
                >
                  <Block :size="16" />
                </button>
                <button
                  v-if="props.rowData.revenda"
                  @click="setarNivel(props.rowData)"
                  class="btn btn-sm btn-warning btn-hover-danger btn-icon mr-2"
                  v-b-tooltip="'Nível Partner'"
                >
                  <Nivel :size="16" />
                </button>
              </div>
            </span>
          </div>
        </span>
      </template>
    </TableApp>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Pencil from "vue-material-design-icons/Pencil.vue";
import ListaCliente from "vue-material-design-icons/Lan.vue";
import Block from "vue-material-design-icons/ArchiveLock.vue";
import Revenda from "vue-material-design-icons/Sync.vue";
import InserirLicenca from "vue-material-design-icons/AlphaI.vue";
import TableApp from "@/components/Table/Table2";
import { SistemaMixin } from "@/modules/Application";
import FilterFields from "@/components/FilterFields";
import Menu from "vue-material-design-icons/Menu";
import Nivel from "vue-material-design-icons/AlertPlus";

export default {
  name: "app-revendas",
  mixins: [SistemaMixin],
  components: {
    Pencil,
    Block,
    Revenda,
    TableApp,
    ListaCliente,
    InserirLicenca,
    FilterFields,
    Menu,
    Nivel,
  },
  data() {
    return {
      fields: [
        {
          label: "Nome",
          field: "nome",
          type: "text",
          slot: true,
          sortable: true,
          width: "25%",
          filterOptions: {
            enabled: false,
            placeholder: "Pesquisar Nome/Razão Social",
          },
        },
        {
          label: "Telefone",
          field: "fone",
          type: "text",
          slot: true,
          sortable: false,
          width: "10%",
        },
        {
          label: "CPF/CNPJ",
          field: "cnpj_cpf",
          type: "text",
          slot: true,
          sortable: false,
          width: "8%",
        },
        {
          label: "Assinatura",
          field: "data_validade",
          type: "text",
          slot: true,
          sortable: false,
          width: "15%",
          dateInputFormat: "DD/MM/YYYY",
          dateOutputFormat: "YYYY-MM-DD",
        },
        {
          label: "Tipo",
          field: "revenda",
          slot: true,
          width: "1%",
          sortable: true,
          filterOptions: {
            enabled: false,
          },
        },
        {
          label: "Plano",
          field: "plano",
          slot: true,
          width: "8%",
          sortable: true,
          filterOptions: {
            enabled: false,
          },
        },
        {
          label: "",
          field: "actions",
          sortable: false,
          slot: true,
          width: "15%",
          filterOptions: {
            enabled: false,
          },
        },
      ],
    };
  },
  methods: {
    async gerarRelatorioNuts() {
      this.addLoadingToButton("clientesNuts");
      try {
        await this.$http
          .post("/empresa/clientes-nuts")
          .then((result) => {
            if (result["data"]["url_pdf"]) {
              window.open(result["data"]["url_pdf"], "_blank");
            }
            this.$toast.success("Relatório gerado com sucesso!");
            this.$bvModal.hide("menuAcoes");
            this.$bvModal.hide("clientesNuts");
          })
          .catch(() => {});
        this.$bvModal.hide("menuAcoes");
        this.$bvModal.hide("clientesNuts");
      } catch (error) {
        this.$bvModal.hide("clientesNuts");
      }
      this.removeLoadingToButton("clientesNuts");
    },
    async gerarRelatorioPorUF() {
      this.addLoadingToButton("clientePorUF");
      try {
        await this.$http
          .post("/empresa/clientes-by-uf")
          .then((result) => {
            if (result["data"]["url_pdf"]) {
              window.open(result["data"]["url_pdf"], "_blank");
            }
            this.$toast.success("Relatório gerado com sucesso!");
            this.$bvModal.hide("menuAcoes");
            this.$bvModal.hide("clientePorUF");
          })
          .catch(() => {});
        this.$bvModal.hide("menuAcoes");
        this.$bvModal.hide("clientePorUF");
      } catch (error) {
        this.$bvModal.hide("clientePorUF");
      }
      this.removeLoadingToButton("clientesRevendas");
    },
    async gerarRelatorioRevendasNuts() {
      this.addLoadingToButton("clientesRevendas");
      try {
        await this.$http
          .post("/empresa/clientes-revendas-nuts")
          .then((result) => {
            if (result["data"]["url_pdf"]) {
              window.open(result["data"]["url_pdf"], "_blank");
            }
            this.$toast.success("Relatório gerado com sucesso!");
            this.$bvModal.hide("menuAcoes");
            this.$bvModal.hide("clientesRevendas");
          })
          .catch(() => {});
        this.$bvModal.hide("menuAcoes");
        this.$bvModal.hide("clientesRevendas");
      } catch (error) {
        this.$bvModal.hide("clientesRevendas");
      }
      this.removeLoadingToButton("clientesRevendas");
    },
    tornarRevenda(data) {
      this.$swal
        .fire({
          icon: "warning",
          title: "Deseja tornar Revenda o  cliente: " + data.nome + "?",
          showCancelButton: true,
          confirmButtonText: `Sim`,
          cancelButtonText: `Não`,
          confirmButtonColor: "#f64e60",
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            await this.$http.post("/empresa/tornar-revenda", {
              id_empresa: data.id_empresa,
            });
            this.$refs.clientes.refresh();
            this.$toast.success("Cliente Atualizado!");
          }
        });
    },
    bloquearAcesso(data) {
      this.$swal
        .fire({
          icon: "warning",
          title: "Deseja bloquear acesso ao cliente " + data.nome + "?",
          showCancelButton: true,
          confirmButtonText: `Sim`,
          cancelButtonText: `Não`,
          confirmButtonColor: "#f64e60",
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            await this.$http.post("/empresa/bloquear-cliente", {
              id_empresa: data.id_empresa,
            });
            this.$refs.clientes.refresh();
            this.$toast.success("Acesso bloqueado!");
          }
        });
    },
    liberarAcesso(data) {
      this.$swal
        .fire({
          icon: "warning",
          title: "Deseja liberar acesso ao cliente " + data.nome + "?",
          showCancelButton: true,
          confirmButtonText: `Sim`,
          cancelButtonText: `Não`,
          confirmButtonColor: "#f64e60",
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            await this.$http.post("/empresa/liberar-cliente", {
              id_empresa: data.id_empresa,
            });
            this.$refs.clientes.refresh();
            this.$toast.success("Acesso liberado!");
          }
        });
    },
    setarLicencas(data) {
      this.$swal
        .fire({
          title: "Deseja setar licenças para essa revenda?",
          text: "Informe o número de licenças desejada!",
          icon: "info",
          html: `
      <input type="text" id="total" class="swal2-input" placeholder="Número">`,
          confirmButtonText: "Enviar",
          cancelButtonText: "Cancelar",
          focusConfirm: false,
          showCancelButton: true,
          preConfirm: async () => {
            const total = this.$swal.getPopup().querySelector("#total").value;

            if (!total) {
              this.$toast.warning("Informe o número de licenças");
            }
            await this.$http
              .post("/empresa/inserir-licenca", {
                total: total,
                id_empresa: data.id_empresa,
              })
              .then(() => {
                this.$toast.success("Licenças inseridas com sucesso!");
                this.$refs.clientes.refresh();
              })
              .catch(() => {});
          },
        })
        .then(() => {});
    },
    setarCreditosUnicheck(data) {
      this.$swal
        .fire({
          title: "Deseja setar créditos Unicheck para consulta de CPFs?",
          text: "Informe o número de créditos desejado!",
          icon: "info",
          html: `
      <input type="text" id="total" class="swal2-input" placeholder="Número">`,
          confirmButtonText: "Creditar",
          cancelButtonText: "Cancelar",
          focusConfirm: false,
          showCancelButton: true,
          preConfirm: async () => {
            const total = this.$swal.getPopup().querySelector("#total").value;

            if (!total) {
              this.$toast.warning("Informe o número de licenças");
            }

            await this.$http
              .post("/saldo/create", {
                saldo: total,
                id_empresa: data.id_empresa,
                tipo: 1,
              })
              .then(() => {
                this.$toast.success("Créditos inseridos com sucesso!");
                this.$refs.clientes.refresh();
              })
              .catch(() => {});
          },
        })
        .then(() => {});
    },
    setarNivel(data) {
      this.$swal
        .fire({
          title:
            "Deseja setar nível partner do parceiro? 1-Start, 2-Prime, 3-Pró, 4-Elite, 5-Supreme!",
          icon: "info",
          html: `
      <input type="text" id="nivel" class="swal2-input" placeholder="Nível">`,
          confirmButtonText: "Atribuir",
          cancelButtonText: "Cancelar",
          focusConfirm: false,
          showCancelButton: true,
          preConfirm: async () => {
            const nivel = this.$swal.getPopup().querySelector("#nivel").value;

            if (!nivel) {
              this.$toast.warning("Informe o nível do parceiro");
            }

            await this.$http
              .post("/empresa/create-nivel-parceiro", {
                nivel: nivel,
                id_revenda: data.id_empresa,
              })
              .then(() => {
                this.$toast.success("Nível Partner definido ao parceiro!");
                this.$refs.clientes.refresh();
              })
              .catch(() => {});
          },
        })
        .then(() => {});
    },
  },
  computed: {
    ...mapGetters(["layoutConfig", "itsblocked", "isNuts"]),
    config() {
      return this.layoutConfig();
    },
  },
  async mounted() {
    if (this.itsblocked) {
      this.$router.push({ name: "assinatura" });
    }

    if (!this.isNuts) {
      this.$router.push({ name: "dashboard" });
    }
  },
};
</script>
