var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"position":"relative"}},[_c('FilterFields',{attrs:{"fields":[
    {
      type: 'text',
      label: 'Nome',
      field: 'nome',
    },
    {
      type: 'text',
      label: 'CPF',
      field: 'cpf',
    },
  ]}}),_c('TableApp',{ref:"motoristaList",attrs:{"titulo":"Motoristas","acaoBtn":"/#/motoristas/novo-motorista","textoBtn":"Novo motorista","api-url":"/motoristas/lista","fields":_vm.fields,"classFilterColumn":"nome","perPage":10},scopedSlots:_vm._u([{key:"customRow",fn:function(props){return [(props.field == 'nome')?_c('span',{staticClass:"pl-0 pt-8",attrs:{"slot":"nome"},slot:"nome"},[_c('div',{staticClass:"d-flex align-items-center"},[_c('div',{staticClass:"font-weight-bold text-muted"},[_vm._v(" "+_vm._s(props.rowData.nome)+" ")])])]):_vm._e(),(props.field == 'cpf')?_c('span',{staticClass:"pl-0 pt-8",attrs:{"slot":"valor"},slot:"valor"},[_c('div',{staticClass:"d-flex align-items-center"},[_c('div',{staticClass:"font-weight-bold text-muted"},[_vm._v(" "+_vm._s(_vm.formatarCampo(props.rowData.cpf))+" ")])])]):_vm._e(),(props.field == 'actions')?_c('span',{attrs:{"slot":"actions"},slot:"actions"},[_c('div',{staticClass:"row-actions",staticStyle:{"text-align":"right !important"}},[_c('span',{staticStyle:{"overflow":"visible"}},[_c('div',{staticClass:"action-buttons"},[_c('button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.bottom",value:('Editar motorista'),expression:"'Editar motorista'",modifiers:{"bottom":true}}],staticClass:"btn btn-sm btn-info btn-hover-info btn-icon mr-2",on:{"click":function($event){return _vm.handleEditMotorista(props.rowData)}}},[_c('Pencil',{attrs:{"size":16}})],1),_c('button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.bottom",value:('Excluir motorista'),expression:"'Excluir motorista'",modifiers:{"bottom":true}}],staticClass:"btn btn-sm btn-danger btn-icon mr-2",on:{"click":function($event){return _vm.handleDeleteMotorista(props.rowData)}}},[_c('TrashCanIcon',{attrs:{"size":16}})],1)])])])]):_vm._e()]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }