export const TECLAS = {
  F1: 112,
  F2: 113,
  F3: 114,
  F4: 115,
  F6: 117,
  F7: 118,
  F8: 119,
  F9: 120,
  F10: 121,
  F11: 122,
  INSERT: 45,
};