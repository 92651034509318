import NumbersMixin from "./NumbersMixin";

export default {
  mixins: [NumbersMixin],
  methods: {
    calculateInstallments(paymentMode, total) {
      if (paymentMode?.descricao === 'Nenhum') return [{ value: 0, date: this.$moment().format("YYYY-MM-DD") }]
      if (paymentMode?.tipo_repeticao === 0) return [{ value: total, date: this.$moment().format("YYYY-MM-DD") }]
      const range = this.getRange(parseFloat(total), paymentMode.vezes);
      return this.setRangeDates(paymentMode, range);
    },
    getRange(total, repeatTimes) {
      const partialValue = this.divide(total, repeatTimes);
      const range = Array.from({ length: repeatTimes }, () => ({
        value: partialValue,
      }));

      const rest = this.subtract(
        total,
        this.multiply(partialValue, repeatTimes)
      );
      range[0].value = this.add(partialValue, rest);
      return range;
    },
    setRangeDates(paymentMode, range) {
      if (!paymentMode?.default && paymentMode?.intervalo_dias) {
        let previousDate;
        const rangeWithDates = range.map((portion, index) => {
          const intervaloData = paymentMode.tipo_repeticao === 9 ?
            paymentMode.intervalo_dias.intervalo[index]
            :
            paymentMode.intervalo_dias.intervalo
          const offsetDay = intervaloData.periodo_qtd;

          const currentDate = paymentMode?.intervalo_dias_collection || !previousDate ?
            this.$moment().add(offsetDay, intervaloData.periodo_descricao)
            :
            previousDate.add(offsetDay, intervaloData.periodo_descricao);

          previousDate = currentDate;

          const date = currentDate.format("YYYY-MM-DD");
          return {
            ...portion,
            date,
          };
        });

        return rangeWithDates;
      } else {
        const increment = this.getIncrement(paymentMode);
        const offsetDay = paymentMode.requiresEntryPayment ? 0 : increment.value;

        const currentDate = this.$moment().add(offsetDay, increment.period);
        const rangeWithDates = range.map(portion => {
          const date = currentDate.format("YYYY-MM-DD");
          currentDate.add(increment.value, increment.period);

          return {
            ...portion,
            date,
          };
        });

        return rangeWithDates;
      }
    },
    getIncrement(paymentMode) {
      if (!paymentMode.repeatType || !paymentMode.repeatType.modifier) {
        return {
          value: 1,
          period: "month"
        };
      }

      return {
        value: parseInt(paymentMode.repeatType.modifier.periodQty, 10),
        period: paymentMode.repeatType.modifier.periodName
      };
    }
  }
};
