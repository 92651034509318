<template>
  <Form url="/natureza/update" v-if="!loader" :natureza="natureza" />
</template>

<script>
import Form from "./Form.vue";
import { NaturezaMixin } from "@/modules/Application";

export default {
  name: "app-natureza-update",
  mixins: [NaturezaMixin],
  data() {
    return {
      natureza: {},
      loader: true,
    };
  },
  components: {
    Form,
  },
  methods: {
    async getInit() {
      try {
        const id = this.$route.params.id;

        const natureza = await this.getNatureza(id);
        
        if (!natureza.id || natureza == null) {
          return this.$toast.error("Natureza não encontrada!");
        }

        this.natureza = await natureza;
        this.loader = false;
      } catch {
        return;
      }
    }
  },
  mounted() {
    this.getInit();
  }
};
</script>
