<template>
  <b-row style="width: 100%">
    <b-col>
      <h4 class="title" :class="classes">
        {{ title }}
      </h4>
    </b-col>
    <b-col class="title-actions">
      <b-row>
        <b-col sm="3" class="hidden-print" v-if="hasPrint">
          <Button class="btn-white" @click="print()">
            <Printer /> Imprimir
          </Button>
        </b-col>
      </b-row>
      <slot name="actions" />
    </b-col>
  </b-row>
</template>

<script>
import Printer from "vue-material-design-icons/Printer";
import { Button } from "@/components/Form";

export default {
  components: {
    Button,
    Printer
  },
  props: {
    hasPrint: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      required: true
    },
    titleAlign: {
      type: String,
      validator: () => ["left", "right", "center"],
      default: () => "left"
    },
    margin: {
      type: String,
      validator: () => ["default", "large"],
      default: () => "default"
    },
    marginTop: {
      type: Boolean,
      default: () => false
    }
  },
  name: "Title",
  methods: {
    print() {
      window.print();
    }
  },
  computed: {
    classes() {
      return {
        [`text-${this.titleAlign}`]: true,
        "title-margin-large": true,
        "title-margin-top": this.marginTop
      };
    }
  }
};
</script>
