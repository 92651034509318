<template>
  <b-tab title="Objeto/Identificadores" @click="() => store.currentTab = 1" :active="isTheCurrentTab" :disabled="!isTheCurrentTab && !store.isUpdate" class="mt-5">
    <b-row align-v="center">
      <b-col sm="8">
        <b-form-group label="Objeto" label-for="objeto">
          <VueSelect ref="buscarObjeto" :filterable="false" :state="validateClienteState('objeto')" v-model="form.objeto"
            :options="objetos" @search="onSearchObjetos" :clearSearchOnSelect="true" @input="setObjeto">
            <template slot="no-options"> Informe o nome do objeto </template>
            <template slot="option" slot-scope="option">
              <div class="d-flex justify-content-between">
                <div>
                  {{ option.descricao }}
                </div>
              </div>
            </template>
            <template slot="selected-option" slot-scope="option">
              <div class="selected d-center">
                {{ option.descricao }}
              </div>
            </template>
          </VueSelect>
        </b-form-group>
      </b-col>
      <b-col>
        <button class="btn btn-primary font-weight-bold" v-b-modal.newObjectModal>
          Novo
        </button>
      </b-col>
    </b-row>
    <b-row v-if="identificadores.length > 0">
      <b-col v-for="identificador in identificadores" :key="identificador.id_identificador" sm="4">
        <b-form-group :label="identificador.descricao" :label-for="identificador.descricao">
          <b-form-input @input="(value) => changeValues(identificador.descricao, value)" maxLength="30"
            :value="identificadoresFilled[identificador.descricao]" />
        </b-form-group>
      </b-col>
    </b-row>

    <b-modal id="newObjectModal" centered title="Cadastrar novo objeto">
      <b-row>
        <b-col>
          <b-form-group id="descricao" label-for="descricao" label="Descrição">
            <b-form-input v-model="newObjetoDescricao" maxlength="50" autofocus>
            </b-form-input>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group id="cliente" label-for="cliente" label="Cliente">
            <VueSelect placeholder="Informe o nome do cliente" ref="buscarCliente" label="id_pessoa" :filterable="false"
              @input="setCliente" v-model="valueCliente" :options="clientes" @search="onSearchCliente">
              <template slot="no-options">
                Informe o nome do cliente
              </template>
              <template slot="option" slot-scope="option">
                <div class="d-center">
                  {{ option.nome }}
                  <span class="text-muted">CNPJ/CPF:
                    {{ formatCpfCnpj(option.cnpj_cpf) }}</span>
                </div>
              </template>
              <template slot="selected-option" slot-scope="option">
                <div class="selected d-center">
                  {{ option.nome }}
                </div>
              </template>
            </VueSelect>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="12">
          <b-row v-if="identificadores.length > 0">
            <b-col v-for="identificador in identificadores" :key="identificador.id_identificador" sm="4">
              <b-form-group :label="identificador.descricao" :label-for="identificador.descricao">
                <b-form-input @input="(value) =>
                  newObjectChangeValues(identificador.descricao, value)" maxLength="30"
                  :value="newObjectIdentificadoresFilled[identificador.descricao]" />
              </b-form-group>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <template #modal-footer="{ ok, cancel }">
        <b-button @click="() => {
          cancel();
          openModal = false;
        }"> Cancelar </b-button>
        <b-button variant="primary" ref="saveNewObject" @click="saveNewObject">
          Salvar
        </b-button>
      </template>
    </b-modal>
  </b-tab>
</template>

<script>
import { mapGetters, mapState, mapActions } from "vuex";
import VueSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import { validationMixin } from "vuelidate";
import PessoaMixin from "@/modules/Application/mixins/PessoaMixin";
import ObjetoData, { ObjetoDataRequired } from "../ObjetoData";
import { SistemaMixin, ValidacaoMixin } from "@/modules/Application";
import _ from "lodash";
import { mask } from "vue-the-mask";
import { store } from "../../storeOS";

export default {
  name: "app-nfe-form-objetoTab",
  components: {
    VueSelect,
  },
  directives: {
    mask,
  },
  data() {
    return {
      form: {
        objeto: null,
      },
      identificadores: [],
      identificadoresFilled: {},
      newObjectIdentificadoresFilled: {},
      objetos: [],
      valueCliente: null,
      newObjetoDescricao: null,
      clientes: [],
      cliente: null,
      keyToReRender: 0,
      store,
    };
  },
  mixins: [validationMixin, SistemaMixin, ValidacaoMixin, PessoaMixin],
  validations: {
    form: ObjetoDataRequired,
  },
  methods: {
    ...mapActions(["setLoading"]),
    onSearchEmail() { },
    validateClienteState(name) {
      // const { $dirty, $error } = this.$v.form[name];
      // return $dirty ? !$error : null;
    },
    setObjeto(objeto) {
      this.identificadoresFilled = objeto.identificadores.reduce((acc, identificador) => ({
        ...acc,
        [identificador.descricao]: identificador.value,
      }), {})
      this.store.formObjeto.identificadores = objeto.identificadores;
    },
    onSearchCliente(search, loading) {
      if (search.length) {
        loading(true);
        this.searchClient(loading, search, this);
      }
    },
    searchClient: _.debounce(async (loading, search, vm) => {
      vm.clientes = await vm.getPessoaNome(search);
      loading(false);
    }, 450),
    setCliente(cliente) {
      this.valueCliente = cliente;
    },
    changeValues(key, value) {
      this.identificadoresFilled[key] = value
      this.store.formObjeto = {
        ...this.store.formObjeto,
        identificadores: this.identificadores.map(identificador => ({
          descricao: identificador.descricao,
          value: this.identificadoresFilled[identificador.descricao]
        }))
      }
    },
    newObjectChangeValues(key, value) {
      this.newObjectIdentificadoresFilled[key] = value
    },
    async saveNewObject() {
      this.addLoadingToButton('saveNewObject');
      try {
        const newObjetoData = {
          descricao: this.newObjetoDescricao,
          id_pessoa: this.valueCliente?.id_pessoa ?? null,
          identificadores: this.identificadores.map(identificador => ({
            descricao: identificador.descricao,
            value: this.newObjectIdentificadoresFilled[identificador.descricao]
          })),
          status: true,
        }
        const response = await this.$http.post('/objeto/create', newObjetoData)
        const identificadoresFetched = JSON.parse(response.data.data.identificadores)

        this.form.objeto = {
          ...response.data.data,
          identificadores: identificadoresFetched
        };
        this.form.identificadores = identificadoresFetched;
        this.identificadoresFilled = this.newObjectIdentificadoresFilled;
        this.$bvModal.hide('newObjectModal')
      } catch (error) {
        console.log(error);
      }
      this.removeLoadingToButton('saveNewObject');

    },
    onSearchObjetos(search, loading) {
      if (search.length > 2) {
        loading(true);
        this.searchObjeto(loading, search, this);
      }
    },

    searchObjeto: _.debounce(async (loading, search, vm) => {
      vm.objetos = [];
      await vm.$nextTick();
      const data = await vm.getObjeto(search);
      vm.objetos = _.cloneDeep(data);
      loading(false);
    }, 500),

    formatEndereco(endereco) {
      if (!endereco) return "";
      const formatData = `${endereco.cep ?? ""}, ${endereco.logradouro ?? ""
        }, ${endereco.numero ?? ""}, ${endereco.bairro ?? ""} - ${endereco?.cidade?.cidade ?? ""
        } / ${endereco?.cidade?.uf ?? ""}`;
      return formatData;
    },
    async getDataInit() {
      const { data } = await this.$http.post('/identificador/get-identificador');
      this.identificadores = data;
      if (Object.keys(this.$route.params).length !== 0) {
        const { objeto, identificadores } = this.$route.params.osData;
        this.form.objeto = objeto;
        const identificadoresParsed = identificadores

        this.identificadoresFilled = identificadoresParsed.reduce((acc, identificador) => ({
          ...acc,
          [identificador.descricao]: identificador.value,
        }), {})
        this.store.formObjeto.identificadores = identificadores;
      } else {
        this.identificadoresFilled = this.identificadores.reduce((acc, identificador) => ({
          ...acc,
          [identificador.descricao]: null,
        }), {})
      }
    }
  },
  created() {
    this.form = new ObjetoData({});
    this.getDataInit();
  },
  computed: {
    ...mapGetters([
      "isAuthenticated",
      "breadcrumbs",
      "pageTitle",
      "layoutConfig",
    ]),
    ...mapState(["loading"]),
    isLoading() {
      return this.loading.loading;
    },
    isTheCurrentTab() {
      return store.currentTab === 1;
    },
  },
  watch: {
    form: {
      handler(newValue) {
        this.store.formObjeto = newValue;
      },
      deep: true,
    },
    'store.formObjeto': {
      handler(newValue) {
        if (newValue.fromSetClient) {
          this.form.objeto = newValue

          if (newValue?.identificadores) this.identificadoresFilled = newValue.identificadores.reduce((acc, identificador) => ({
            ...acc,
            [identificador.descricao]: identificador.value,
          }), {})

          this.form.identificadores = newValue?.identificadores
        };
      },
      deep: true,
    },
    "store.currentTab"(newValue, oldValue) {
      if (newValue !== oldValue && newValue === 2 && oldValue < newValue) {
        if (_.isEmpty(this.store.formObjeto.objeto)) {
          this.$toast.error("Objeto Obrigatório!");
          this.store.currentTab = oldValue;
          return;
        }
        if (_.isEmpty(this.store.formObjeto.identificadores)) {
          this.$toast.error("Identificador Obrigatório!");
          this.store.currentTab = oldValue;
          return;
        }
      }
    },
  },
};
</script>
