<template>
  <div class="card card-custom gutter-b" style="box-shadow: none">
    <div class="card-header card-header-tabs-line">
      <ul
        class="nav nav-dark nav-bold nav-tabs nav-tabs-line"
        role="tablist"
        ref="builder-tab"
      >
        <li class="nav-item">
          <a
            class="nav-link active"
            v-on:click="setActiveTab"
            data-tab="0"
            data-toggle="tab"
            href="#"
            role="tab"
          >
            Contrato
          </a>
        </li>
        <li class="nav-item">
          <a
            class="nav-link"
            v-on:click="setActiveTab"
            data-tab="1"
            data-toggle="tab"
            href="#"
            role="tab"
          >
            Parcelas
          </a>
        </li>
      </ul>
    </div>

    <b-form class="form" @submit.stop.prevent="onSubmit">
      <div class="card-body">
        <b-tabs class="hide-tabs" v-model="tabIndex">
          <b-tab active>
            <b-row>
              <b-col md="4">
                <b-form-group
                  id="dia_pagamento"
                  label-for="dia_pagamento"
                  label="Dia do mês para gerar parcelas"
                >
                  <b-form-input
                    type="number"
                    v-model="$v.form.dia_pagamento.$model"
                    :state="validateState('dia_pagamento')"
                    autofocus
                  >
                  </b-form-input>
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group
                  id="vezes"
                  label-for="vezes"
                  label="Quantas vezes quer fazer a cobrança"
                >
                  <b-form-input
                    type="number"
                    v-model="$v.form.vezes.$model"
                    :state="validateState('vezes')"
                  >
                  </b-form-input>
                </b-form-group>
              </b-col>
              <b-col md="2">
                <b-form-group
                  id="valor"
                  label-for="valor"
                  label="Valor R$ total"
                >
                  <money
                    class="form-control"
                    v-model="form.valor"
                    v-bind="configMoney"
                  >
                  </money>
                </b-form-group>
              </b-col>
              <b-col md="12">
                <b-form-group
                  id="gerar_boleto"
                  label-for="gerar_boleto"
                  label="Gerar Boleto?"
                >
                  <span class="switch switch-icon">
                    <label>
                      <input
                        type="checkbox"
                        :value="true"
                        @change="setBoleto"
                        :checked="form.gerar_boleto"
                      />
                      <span></span>
                    </label>
                  </span>
                </b-form-group>
              </b-col>
            </b-row>
          </b-tab>
          <b-tab>
            <b-row>
              <table
                class="
                  table table-head-custom table-head-bg table-vertical-center
                "
              >
                <thead>
                  <tr>
                    <th>Documento</th>
                    <th class="pl-0 font-weight-bold text-muted pl-7">Valor</th>
                    <th class="pr-0 font-weight-bold text-muted">
                      Data Vencimento
                    </th>
                    <th class="pr-0 font-weight-bold text-muted">
                      Data Pagamento
                    </th>
                    <th>Link Boleto</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(i, index) in contasReceber"
                    :key="'item' + index"
                    :class="
                      i.data_recebimento
                        ? 'font-weight-boldest font-size-lg cancelado'
                        : 'font-weight-boldest font-size-lg'
                    "
                  >
                    <td>{{ i.documento }}</td>
                    <td class="pl-0">
                      <a>
                        {{ numberToReal(i.valor, 2) }}
                      </a>
                    </td>
                    <td>
                      <span class="text-muted font-weight-bold d-block pl-7">
                        {{ formatDate(i.data_vencimento) }}
                      </span>
                    </td>
                    <td class="text-muted font-weight-bold pr-0 pt-7">
                      {{ formatDate(i.data_recebimento) }}
                    </td>
                    <td class="text-muted" v-if="!i.url_boleto">Não foi gerado boleto</td>
                    <td class="text-muted font-weight-bold d-block pl-7" v-if="i.url_boleto">
                      <a
                        :href="i.url_boleto"
                        target="_blank"
                        class="
                          text-dark
                          font-weight-bolder
                          text-hover-primary
                          mb-1
                          font-size-lg
                          pl-7
                        "
                      >
                        Ver boleto
                      </a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </b-row>
          </b-tab>
        </b-tabs>
      </div>

      <div class="card-footer">
        <div class="row">
          <div class="col-lg-12" style="text-align: center">
            <button
              v-if="!form.id_contrato_cliente"
              type="submit"
              ref="submit_contrato"
              class="btn btn-primary font-weight-bold"
            >
              Gerar contrato
            </button>
            <button
              @click="atualizarContrato()"
              v-if="form.id_contrato_cliente"
              class="btn btn-primary font-weight-bold"
            >
              Atualizar contrato
            </button>
            <router-link
              class="btn btn-clean font-weight-bold"
              :to="{
                name: 'clientesLista',
              }"
            >
              Cancelar
            </router-link>
          </div>
        </div>
      </div>
    </b-form>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { validationMixin } from "vuelidate";
import ContratoData, { ContratoDataRequired } from "./ContratoData";
import { mask } from "vue-the-mask";
import { Money } from "v-money";
import { MONEY as settingMoney } from "@/modules/Application";
import SistemaMixin from "../../Application/mixins/SistemaMixin";
export default {
  name: "app-contrato-form",
  mixins: [validationMixin, SistemaMixin],
  directives: {
    mask,
  },
  watch: {
    contrato(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.form = newValue;
      }
    },
  },
  components: {
    Money,
  },
  data() {
    return {
      tabIndex: 0,
      form: {},
      id_empresa: null,
      url: null,
      contrato: new ContratoData({}),
      configMoney: settingMoney,
      contasReceber: [],
    };
  },
  validations: {
    form: ContratoDataRequired,
  },
  async mounted() {
    await this.getDataInit();
    if (!this.isRevenda) {
      this.$router.push({ name: "dashboard" });
    }
  },
  methods: {
    ...mapActions(["setLoading"]),
    async getDataInit() {
      this.setLoading(true);
      const id_empresa = this.$route.params.id_empresa;
      this.id_empresa = id_empresa;
      let { data } = await this.$http.post("contratos/find", {
        id_empresa: id_empresa,
      });
      this.setLoading(false);
      if (!data.id) {
        this.contrato = new ContratoData({});
        this.url = "/contratos/create";
        return;
      }
      this.contrato = new ContratoData(data);
      this.url = "contratos/update";
      this.contasReceber = data.contas;
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    setActivePreviousTab() {
      this.tabIndex = parseInt(localStorage.getItem("builderTab")) || 0;
      const links = this.$refs["builder-tab"].querySelectorAll(".nav-link");
      for (let i = 0; i < links.length; i++) {
        links[i].classList.remove("active");
      }
      this.$refs["builder-tab"]
        .querySelector(`[data-tab="${this.tabIndex}"]`)
        .classList.add("active");
    },
    setActiveTab(event) {
      const tab = event.target.closest('[role="tablist"]');
      const links = tab.querySelectorAll(".nav-link");
      for (let i = 0; i < links.length; i++) {
        links[i].classList.remove("active");
      }
      this.tabIndex = parseInt(event.target.getAttribute("data-tab"));
      event.target.classList.add("active");
    },
    setBoleto() {
      this.form.gerar_boleto = this.form.gerar_boleto == true ? false : true;
    },
    async atualizarContrato() {
      if (this.form.id_contrato_cliente) {
        this.$swal
          .fire({
            icon: "warning",
            title:
              "Deseja atualizar o contrato, as parcelas não recebidas serão excluídas," +
              "e serão criadas novamente conforme o valor do contrato!",
            showCancelButton: true,
            confirmButtonText: `Sim`,
            cancelButtonText: `Não`,
            confirmButtonColor: "#f64e60",
          })
          .then(async (result) => {
            if (result.isConfirmed) {
              this.setLoading(true);
              await this.$http.post(this.url, this.form);
              this.setLoading(false);
              this.$toast.success("Contrato atualizado com sucesso!");
              this.$router.push({ name: "clientesLista" });
            }
          });
      }
    },
    async onSubmit() {
      const submitButton = this.$refs["submit_contrato"];
      try {
        this.$v.form.$touch();
        if (this.$v.form.$anyError) {
          this.$toast.error(
            "Verifique todos os campos obrigatórios do formulário!"
          );
          return;
        }
        submitButton.classList.add("spinner", "spinner-light", "spinner-right");
        submitButton.setAttribute("disabled", "");
        this.form.id_empresa = this.id_empresa;
        await this.$http.post(this.url, this.form);
        submitButton.classList.remove(
          "spinner",
          "spinner-light",
          "spinner-right"
        );
        this.$toast.success("Contrato gerado com sucesso!");
        submitButton.removeAttribute("disabled", "");
        this.$router.push({ name: "clientesLista" });
      } catch (error) {
        submitButton.classList.remove(
          "spinner",
          "spinner-light",
          "spinner-right"
        );
        submitButton.removeAttribute("disabled", "");
      }
    },
  },
  computed: {
    ...mapGetters(["isRevenda"]),
  },
};
</script>

<style>
.vs__dropdown-toggle {
  border: 2px solid #dedede;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border-radius: 6px;
  outline: none;
  height: 38px;
}
</style>