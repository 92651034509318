<template>
  <b-tab title="Produtos" @click="() => store.currentTab = 2" :active="isTheCurrentTab"
    :disabled="!isTheCurrentTab && !store.isUpdate" class="mt-5">
    <b-row align-v="center">
      <b-col sm="10">
        <b-form-group label="Nome" label-for="name">
          <VueSelect ref="buscarProduto" :filterable="false" @input="setProduto" :options="produtosFetched"
            :class="{ hasErrors: produtoError, inputFilled: produtoFilled }" @search="onSearchProduto"
            v-model="descricao">
            <template slot="no-options">
              Informe o produto pelo nome
            </template>
            <template slot="option" slot-scope="option">
              <div class="d-flex justify-content-between">
                <div>
                  {{ option.codigo }} - {{ option.descricao }}
                  <span id="kitInfo" v-if="option.isKit" :title="getKitInfo(option)" v-b-tooltip.hover.html.right>
                    <InformationOutline :size="14" />
                  </span>
                </div>
                <span>{{
                  !option.isKit
                    ? `R$: ${numberToReal(option.preco)} - ` +
                      `QTD: ${option.quantidade}`
                    : `R$
                  ${numberToReal(option.totalPrice)}`
                }}</span>
              </div>
            </template>
            <template slot="selected-option" slot-scope="option">
              <div class="selected d-center">
                {{ option.label }}
              </div>
            </template>
          </VueSelect>
        </b-form-group>
      </b-col>
      <b-col class="mt-1">
        <b-dropdown id="dropdown-dropright" dropright variant="primary" v-b-tooltip.hover.right
          title="Mudar tipo de filtro">
          <template #button-content>
            <FilterOutline :size="20" class="mt-" />
          </template>
          <b-dropdown-item @click="() => { typeFilter = 1 }" :class="typeFilter === 1 ? 'bg-primary' : ''">
            <p :class="typeFilter === 1 ? 'text-white' : ''">
              Começando com
            </p>
          </b-dropdown-item>
          <b-dropdown-item @click="() => { typeFilter = 2 }" :class="typeFilter === 2 ? 'bg-primary' : ''">
            <p :class="typeFilter === 2 ? 'text-white' : ''">
              Terminando com
            </p>
          </b-dropdown-item>
          <b-dropdown-item @click="() => { typeFilter = 3 }" :class="typeFilter === 3 ? 'bg-primary' : ''">
            <p :class="typeFilter === 3 ? 'text-white' : ''">
              Contendo
            </p>
          </b-dropdown-item>
        </b-dropdown>
      </b-col>

    </b-row>
    <b-row>
      <b-col :cols="windowWidth > 768 ? 0 : 6">
        <b-form-group label="Quantidade" label-for="quantidade">
          <money 
            class="form-control" 
            ref="quantidade" 
            id="quantidade" 
            name="quantidade" 
            v-model="quantidade"
            v-bind="quantityOptions" 
            v-on:keyup.native.enter="handleEnter('preco')" />
        </b-form-group>
      </b-col>
      <b-col :cols="windowWidth > 768 ? 0 : 6">
        <b-form-group label="Unitário" label-for="preco">
          <money 
            class="form-control" 
            ref="preco" 
            id="preco" 
            name="preco" 
            v-model="preco" 
            v-bind="priceOptions"
            v-on:keyup.native.enter="handleEnter('desconto')" />
        </b-form-group>
      </b-col>
      <b-col :cols="windowWidth > 768 ? 0 : 6">
        <b-form-group label="Desconto" label-for="desconto">
          <money class="form-control" ref="desconto" id="desconto" name="desconto" v-model="desconto" v-bind="configMask"
            v-on:keyup.native.enter="handleEnter('total')" />
        </b-form-group>
      </b-col>
      <b-col :cols="windowWidth > 768 ? 0 : 6">
        <b-form-group label="Total R$" label-for="total">
          <money class="form-control" ref="total" id="total" name="total" v-model="total" v-bind="priceOptions" 
          v-on:keyup.native.enter="addProduto(produtoSelected)"
           />
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group label-for="add">
          <button @click="addProduto(produtoSelected)">
            <Plus class="btn btn-sm btn-info btn-hover-info btn-icon mr-2 mt-9" v-if="windowWidth > 768" />
            <button v-else class="btn btn-info btn-hover-dark font-weight-bolder font-size-lg ml-2">
              Adicionar produto
            </button>
          </button>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <TableApp item="produto" subTitulo="" :data="produtos" :fields="fields" classFilterColumn="nome" ref="nome">
          <template slot="customRow" scope="props">
            <span slot="item" class="pl-0 pt-8" v-if="props.field == 'item'">
              <div class="d-flex align-items-center">
                <div class="font-weight-bold text-muted">
                  {{ props.rowData.originalIndex + 1 }} / {{ props.rowData.product.codigo }}
                </div>
              </div>
            </span>
            <span slot="nome" class="pl-0 pt-8" v-if="props.field == 'descricao'">
              <div class="d-flex align-items-center">
                <div class="font-weight-bold text-muted">
                  {{ props.rowData.product.descricao }}
                  <span v-b-tooltip.hover.right="'Item atendeu a regra de preço de atacado'"
                    v-if="props.rowData.product.tipo_atacado">
                    <InformationOutline :size="14" />
                  </span>
                </div>
              </div>
            </span>
            <span slot="quantidade" class="pl-0 pt-8" v-if="props.field == 'quantidade'">
              <div class="d-flex align-items-center">
                <div class="font-weight-bold text-muted">
                  {{ numberToReal(props.rowData.quantity, 'quantidade') }}
                </div>
              </div>
            </span>
            <span slot="preco" class="pl-0 pt-8" v-if="props.field == 'preco'">
              <div class="d-flex align-items-center">
                <div class="font-weight-bold text-muted">
                  {{ numberToReal(props.rowData.product.preco, 'preco') }}
                </div>
              </div>
            </span>
            <span slot="desconto" v-if="props.field == 'desconto'"
              class="text-dark-75 font-weight-bolder d-block font-size-lg">
              R$
              {{ numberToReal(props.rowData.discount, 2) }}
            </span>
            <span slot="total" v-if="props.field == 'total'" class="text-dark-75 font-weight-bolder d-block font-size-lg">
              R$
              {{ numberToReal(props.rowData.totalPrice) }}
            </span>
            <span slot="actions" v-if="props.field == 'actions'">
              <div class="row-actions" style="text-align: right !important">
                <span style="overflow: visible; position: relative; width: 130px">
                  <div class="action-buttons">
                    <button @click="openModalImpostos(props.rowData, props.rowData.currentIndex)"
                      :disabled="!props.rowData.totais" class="btn btn-sm btn-warning btn-hover-warning btn-icon mr-2">
                      <Eye :size="16" title="Impostos" />
                    </button>
                    <button @click="openModalProduto(props.rowData, props.rowData.currentIndex)"
                      class="btn btn-sm btn-info btn-hover-info btn-icon mr-2">
                      <Pencil :size="16" title="Editar" />
                    </button>
                    <button @click="handleDeleteProduto(props.rowData)"
                      class="btn btn-sm btn-danger btn-hover-danger btn-icon mr-2">
                      <TrashCanIcon :size="16" />
                    </button>
                  </div>
                </span>
              </div>
            </span>
          </template>
        </TableApp>
        <b-row v-if="produtos.length > 0">
          <b-col></b-col>
          <b-col></b-col>
          <b-col><span class="text-primary font-weight-bolder">Total Itens:</span> {{ produtos.reduce((acc, produto) =>
            acc + produto.quantity, 0) }}</b-col>
          <b-col><span class="text-primary font-weight-bolder">Total R$:</span>{{ numberToReal(produtos.reduce((acc,
            produto) => acc + produto.totalPrice, 0), 'preco') }}</b-col>
        </b-row>
        <b-row>
          <b-button variant="primary" @click="atribuirCFOP" ref="atribuir-cfop">
            Atribuir CFOP
          </b-button>
        </b-row>
        <ModalProduto
          :close="closeModalProduto"
          :changeProduto="saveEditProduto"
          :produto="produtoToEdit"
          :open="modalProdutoIsOpen"
          v-if="modalProdutoIsOpen"
        />
        <ModalProdutoImpostos :close="closeModalProdutoImpostos" :produto="produtoToImpostos"
          :open="modalProdutoImpostosIsOpen" v-if="modalProdutoImpostosIsOpen" />
      </b-col>
    </b-row>

    <VariacaoProdutoModal :addProduto="addProduto" :produto="produtoSelected" @onAddProduto="({ newPreco, newQuantidade, newTotal }) => {
      preco = formatNumberToFloat(newPreco);
      quantidade = newQuantidade;
      total = newTotal;
    }" />
  </b-tab>
</template>

<script>
import { mapGetters, mapState, mapActions } from "vuex";
import VueSelect from "vue-select";
import { Money } from "v-money";
import { MONEY as settingMoney, priceOptions, quantityOptions } from "@/modules/Application";
import "vue-select/dist/vue-select.css";
import Eye from "vue-material-design-icons/Eye.vue";
import Pencil from "vue-material-design-icons/Pencil.vue";
import InformationOutline from "vue-material-design-icons/InformationOutline.vue";
import TrashCanIcon from "vue-material-design-icons/TrashCan.vue";
import Plus from "vue-material-design-icons/PlusThick.vue";
import TableApp from "../../../../components/Table/Table3.vue"
import ModalProduto from "./ModalProduto.vue";
import ModalProdutoImpostos from "./ModalProdutoImpostos.vue";
import { validationMixin } from "vuelidate";
import ProdutoData, { ProdutoDataRequired } from "../ProdutoData";
import { SistemaMixin, ValidacaoMixin, FormatMixin } from "@/modules/Application";
import _ from "lodash";
import { mask } from "vue-the-mask";
import { store } from "../../storeNfe";
import swal from "sweetalert";
import FilterOutline from "vue-material-design-icons/Filter";
import VariacaoProdutoModal from "@/components/VariacaoProdutoModal"

export default {
  name: "app-nfe-form-ProdutoTab",
  components: {
    VueSelect,
    ModalProduto,
    TableApp,
    Pencil,
    Plus,
    TrashCanIcon,
    Money,
    FilterOutline,
    Eye,
    ModalProdutoImpostos,
    InformationOutline,
    VariacaoProdutoModal,
  },
  directives: {
    mask,
  },
  data() {
    return {
      store,
      configMask: settingMoney,
      priceOptions,
      quantityOptions,
      windowWidth: window.innerWidth,
      typeFilter: 3,
      fromSelect: false,
      fields: [
        {
          label: "Item/Cód",
          field: "item",
          slot: true,
          sortable: false,
          width: "5%",
          filterOptions: {
            enabled: false,
          },
        },
        {
          label: "Nome",
          field: "descricao",
          slot: true,
          sortable: false,
          width: "40%",
          filterOptions: {
            enabled: false,
          },
        },
        {
          label: "Qtd",
          field: "quantidade",
          slot: true,
          sortable: false,
          width: "8%",
        },
        {
          label: "Unitário",
          field: "preco",
          sortable: false,
          slot: true,
          width: "10%",
        },
        {
          label: "Desconto",
          field: "desconto",
          sortable: false,
          slot: true,
          width: "5%",
        },
        {
          label: "Total",
          field: "total",
          sortable: false,
          slot: true,
          width: "20%",
        },
        {
          label: "",
          field: "actions",
          sortable: false,
          slot: true,
          width: "10%",
        },
      ],
      form: {},
      descricao: '',
      quantidade: 1,
      preco: 0,
      desconto: 0,
      total: 0,
      produtoSelected: null,
      estoqueProduto: 0,
      produtosFetched: [],
      produtos: [],
      produtoToEdit: null,
      modalProdutoIsOpen: false,
      dataSendToApi: {},
      produtoError: false,
      produtoFilled: false,
      modalProdutoImpostosIsOpen: false,
      produtoToImpostos: null,
    };
  },
  mixins: [
    validationMixin,
    SistemaMixin,
    ValidacaoMixin,
    FormatMixin,
  ],
  validations: {
    form: ProdutoDataRequired,
  },
  watch: {
    form(newValue, oldValue) {
      if (JSON.stringify(newValue) === JSON.stringify(oldValue)) {
        JSON.stringify(newValue);
        return;
      }
      this.store.formProduto = newValue;
    },
    'store.currentTab'(newValue, oldValue) {
      if (newValue !== oldValue && newValue === 3 && oldValue < newValue) {
        this.$v.form.$touch();
        if (this.$v.form.$anyError) {
          this.$toast.error("Necessario incluir um produto");
          this.produtoError = true;
          this.store.currentTab = oldValue;
          return;
        }
        const invalidProduct = this.form.produtos.find(produto =>
          produto.product.cfop === null ||
          produto.product.cst_csosn === null ||
          produto.product.ncm === null
        )
        if (invalidProduct) {
          this.$toast.warning("Produto sem CFOP ou CSOSN ou NCM incluso!!");
          this.produtoError = true;
          this.store.currentTab = oldValue;
          return;
        }
      }
    },
    'store.formProduto'(newValue, oldValue) {
      if (JSON.stringify(newValue) === JSON.stringify(oldValue)) return;
      this.form.produtos = newValue.produtos;
      this.produtos = newValue.produtos;
      if (newValue.produtos.length !== 0) this.calcImposto();
    },
    preco(newValue, oldValue) {
      if (oldValue !== newValue) {
        this.total = newValue * this.quantidade;
      }
    },
    quantidade(newValue, oldValue) {
      if (oldValue !== newValue) {
        const isAtacado = newValue >= this.produtoSelected.quantidade_atacado &&
          this.produtoSelected.preco_atacado > 0 &&
          this.store.formCliente.tipo_atacado

        this.preco = isAtacado ?
          this.formatNumberToFloat(this.produtoSelected.preco_atacado)
          :
          this.produtoSelected.preco;

        this.total = newValue * this.formatNumberToFloat(this.preco);
        this.produtoSelected.total = this.total
        this.produtoSelected.tipo_atacado = isAtacado
        this.produtoSelected.quantidade = newValue;
      }
    },
    desconto(newValue, oldValue) {
      if (oldValue !== newValue && !isNaN(newValue) && newValue !== '') {
        this.total = (this.quantidade * parseFloat(this.preco)) - parseFloat(newValue);
      }
    },
  },
  methods: {
    ...mapActions(["setLoading"]),
    handleEnter(ref) {
      if (this.fromSelect) {
        this.fromSelect = false;
        return;
      }

      if (!this.$refs[ref]) return;

      this.$refs[ref].$el.focus();
      let inputEl = this.$refs[ref].$el;

      setTimeout(() => {
        inputEl.select();
      }, 100);
    },
    onResize() {
      this.windowWidth = window.innerWidth;
    },
    getKitInfo(kitData) {
      const description = kitData.productOfTradeCollection
        .reduce((acc, produto, index) => acc + `${index + 1} - ${produto.product.descricao} - QTD: ${produto.product.quantidade}<br/>`, '')
      return description;
    },
    atribuirCFOP() {
      swal({
        title: "Deseja aplicar o mesmo CFOP para os produtos desta NFe?",
        text: `Se sim todos os produtos cadastrados nesta NFe vão ter seu CFOP atribuido para ${this.store.formNatureza.cfop}`,
        icon: "info",
        buttons: {
          confirm: {
            text: "Sim",
            visible: true,
            closeModal: false,
          },
          cancel: {
            text: 'Não',
            visible: true,
            closeModal: true,
          }
        },
        dangerMode: false,
        closeModal: false,
        closeOnClickOutside: false,
      }).then(async (value) => {
        if (value) {
          this.store.cfop = this.store.formNatureza.natureza.cfop;
          const produtosWithCFOPAplied = this.store.formProduto.produtos.map(produto => ({
            ...produto,
            product: {
              ...produto.product,
              cfop: this.store.cfop
            }
          }));
          this.store.formProduto.produtos = produtosWithCFOPAplied;
          this.produtos = produtosWithCFOPAplied;
          swal.close();
          swal.stopLoading();
          return;
        }
        swal.close();
        swal.stopLoading();
      });
    },
    async calcImposto() {
      this.dataSendToApi = this.formatDataToSendToApi(
        this.store.formNatureza,
        this.form,
        this.store.formCliente,
        this.store.formFaturamento.pagamentos.length === 0 ? null : this.store.formFaturamento,
        this.store.formImpostos
      );
      const { dados } = await this.getTotaisNfe(this.dataSendToApi);
      this.store.formImpostos = {
        ...this.store.formImpostos,
        ...dados.total,
      };
      this.produtos = dados.productOfTradeCollection;
      if (dados?.volume?.netWeight) this.store.formTransportador.pesoLiquido = dados.volume.netWeight
      if (dados?.volume?.grossWeight) this.store.formTransportador.pesoBruto = dados.volume.grossWeight
    },
    async addProduto(produto, isKit) {
      if (_.isEmpty(produto)) return;

      if (produto.variavel && !produto.combinacao) {
        this.$toast.warning("Produto sem variação informada!");
        return;
      }

      if (this.store.verificaEstoque) {
        if (this.quantidade > parseFloat(produto.quantidade_original).toFixed(2)) {
          this.$toast.warning("Quantidade informada no item é superior a seu estoque!");
          return false;
        }
      }

    const produtoFormatted = !isKit ? {
        ...produto,
        preco: this.preco,
        quantidade: this.quantidade,
        desconto: this.desconto,
        total: this.total,
        ...(this.store.cfop && { cfop: this.store.cfop })
      } : { ...produto, ...(this.store.cfop && { cfop: this.store.cfop }) };

      this.produtos = [
        ...this.produtos, !isKit ? {
          product: produtoFormatted,
          price: this.total / this.quantidade,
          quantity: this.quantidade,
          totalPrice: this.total,
          discount: this.desconto,
          active: true,
          currentIndex: this.produtos.length,
          ...(produto.variavel && { combinacao: produto.combinacao })
        } : { ...produto, product: { ...produto }, ...(produto.variavel && { combinacao: produto.combinacao }) }];
      const subtotal = this.produtos.reduce((acc, produto) => (acc + (produto.quantity * produto.price - produto.discount)), 0);
      this.store.formImpostos.totalProd = subtotal;
      this.form = {
        produtos: [...this.produtos],
        subtotal,
      };
      this.clearFields();
      if (this.store.formFaturamento.forma_pagamento.descricao === 'Nenhum') {
        this.store.formFaturamento.pagamentos[0].valor = this.store.formImpostos.totalNFE;
      }
      this.produtoError = false;
      this.$nextTick(() => {
        this.$refs.buscarProduto.$refs.search.focus();
      });
    },
    clearFields() {
      this.descricao = '';
      this.estoqueProduto = 0;
      this.quantidade = 1;
      this.preco = 0;
      this.total = 0;
      this.desconto = 0;
      this.produtoSelected = null;
      this.produtoFilled = false;
      this.produtosFetched = [];
      this.descricao = null;
    },
    openModalProduto(produtoData, currentIndex) {
      let importacao = false;
      this.modalProdutoIsOpen = true;

      if (produtoData.product.cfop !== null) {
        if (produtoData.product.cfop.codigo == "3102") {
          importacao = true;
        }
      }

      this.produtoToEdit = {
        ...produtoData.product,
        quantity: produtoData.quantity,
        price: produtoData.price,
        discount: produtoData.discount,
        totais: produtoData.totais,
        importacao: importacao,
        currentIndex,
      };
    },
    openModalImpostos(produtoData, currentIndex) {
      this.modalProdutoImpostosIsOpen = true;
      this.produtoToImpostos = {
        ...produtoData.product,
        quantity: produtoData.quantity,
        price: produtoData.price,
        discount: produtoData.discount,
        totais: produtoData.totais,
        currentIndex,
      };
    },
    handleDeleteProduto(item) {
      this.$swal
        .fire({
          icon: "warning",
          title: "Excluir o produto " + item.product.descricao + "?",
          showCancelButton: true,
          confirmButtonText: `Sim`,
          cancelButtonText: `Não`,
          confirmButtonColor: "#f64e60",
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            const aux = this.produtos.filter(produto => produto.currentIndex !== item.currentIndex).map((item, index) => ({
              ...item,
              currentIndex: index,
            }));
            this.form.produtos = [...aux];
            this.produtos = [...aux];
            this.calcImposto();
            if (this.store.formFaturamento.forma_pagamento.descricao === 'Nenhum') {
              this.store.formFaturamento.pagamentos[0].valor = this.store.formImpostos.totalNFE
            }
          }
        });

    },
    closeModalProduto() {
      this.modalProdutoIsOpen = false;
    },
    closeModalProdutoImpostos() {
      this.modalProdutoImpostosIsOpen = false;
    },
    setDesconto() {
      if (!this.produtoSelected) return;
      this.form.total = (this.form.preco * this.form.quantidade) - this.form.desconto;
      this.produtoSelected.total = (parseFloat(this.produtoSelected.preco) * parseFloat(this.produtoSelected.quantidade)) - this.produtoSelected.desconto;
    },
    validateProdutoState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    async saveEditProduto(newEditedProduto) {
      this.form.produtos[newEditedProduto.currentIndex] = {
        ...this.form.produtos[newEditedProduto.currentIndex],
        product: newEditedProduto,
        price: newEditedProduto.price - parseFloat(newEditedProduto.desconto),
        quantity: newEditedProduto.quantidade,
        totalPrice: newEditedProduto.price * newEditedProduto.quantidade - parseFloat(newEditedProduto.desconto),
        discount: parseFloat(newEditedProduto.desconto),
      };
      this.form.subtotal = this.form.produtos.reduce((acc, produto) => (acc + (produto.price * produto.quantity) - produto.discount), 0)
      this.produtos = _.cloneDeep(this.form.produtos);
      this.store.formProduto = _.cloneDeep(this.form);

      this.store.formImpostos.totalProd = this.form.subtotal;

      this.dataSendToApi = this.formatDataToSendToApi(
        this.store.formNatureza,
        this.form,
        this.store.formCliente,
        this.store.formFaturamento.pagamentos.length === 0 ? null : this.store.formFaturamento,
        this.store.formImpostos
      );
      try {
        this.$toast.info('Atualizando impostos...')
        const { dados } = await this.getTotaisNfe(this.dataSendToApi);
        this.store.formImpostos = {
          ...this.store.formImpostos,
          ...dados.total,
        };

        this.produtos = dados.productOfTradeCollection;
      } catch (error) {
        console.log("🚀 ~ file: ProdutoTab.vue:388 ~ saveEditProduto ~ error", error)
      }

      this.modalProdutoIsOpen = false;
    },
    onSearchProduto(search, loading) {
      if (search.length > 1 && search[0] === '+') {
        loading(true);
        this.searchKit(loading, search, this);
        return;
      }
      if (search.length > 2 || /^[0-9]+$/.test(search)) {
        loading(true);
        this.searchProdutos(loading, search, this);
      }
    },
    searchProdutos: _.debounce(async (loading, search, vm) => {
      vm.produtosFetched = await vm.fetchProdutos(search, vm.typeFilter);
      loading(false);
    }, 350),
    searchKit: _.debounce(async (loading, search, vm) => {
      const { data } = await vm.$http.post('/kit/get-kit', { search });
      vm.produtosFetched = data.map(kit => ({
        ...kit,
        descricao: kit.nome,
        isKit: true,
      }))
      loading(false);
    }, 350),
    setProduto(produto) {
      if (!produto) return;
      this.descricao = produto.descricao;

      if (this.store.verificaEstoque) {
        if (parseFloat(produto.quantidade).toFixed(2) <= 0) {
          this.$toast.warning("Produto selecionado está com estoque zerado!");
          return false;
        }
      }

      if (produto?.variavel) {
        this.produtoSelected = produto;
        this.$bvModal.show('variacaoModal');
        return;
      };
      if (produto?.isKit) {
        produto.productOfTradeCollection.forEach((product, index) => {
          const produtoToAdd = {
            ...product.product,
            price: product.product.preco,
            quantity: product.product.quantidade,
            totalPrice: product.product.total,
            discount: product.product?.desconto ?? 0,
            active: true,
            currentIndex: index,
          };
          this.addProduto(produtoToAdd, true);
        });
        return;
      }
      this.descricao = produto.descricao;
      this.estoqueProduto = produto.quantidade;
      const preco_atacado = produto?.preco_atacado ?? 0;
      const qtd_atacado = produto?.quantidade_atacado ?? 0;
      this.quantidade = 1;

      const isAtacadoValue = this.quantidade >= qtd_atacado && preco_atacado > 0 && this.store.formCliente.tipo_atacado

      if (isAtacadoValue) {
        this.preco = parseFloat(preco_atacado);
      } else this.preco = parseFloat(produto.preco);

      this.total = this.quantidade * parseFloat(this.preco);
      this.produtoSelected = {
        ...produto,
        preco: this.preco,
        quantidade: this.quantidade,
        tipo_atacado: isAtacadoValue,
      };
      this.produtoSelected.total = this.total;
      this.produtoSelected.quantidade_original = produto.quantidade;

      this.produtoFilled = true;
      this.fromSelect = true;
      this.$nextTick(() => {
      this.$refs.quantidade.$el.focus();
      let inputEl = this.$refs.quantidade.$el;
        setTimeout(() => {
          inputEl.select();
        }, 100);
      });
    },
  },
  created() {
    if (Object.keys(this.$route.params).length !== 0) {
      const { productOfTradeCollection: produtos } = this.$route.params.nfeData;

      this.produtos = produtos.map(produto => {
        const produtoFormatted = {
          ...produto.product,
          ...(produto.product.cst_csosn === null && { cst_csosn: { codigo: '-' } }),
          ...(produto.product.cfop === null && { cfop: { codigo: '-' } }),
          ...(produto.product.ncm === null && { ncm: { codigo: '-' } }),
          preco: produto.product.preco,
          quantidade: produto.product.quantidade,
          desconto: produto.product.desconto,
          total: produto.product.total,
          totais: produto.totais,
        };
        return {
          product: produtoFormatted,
          price: produto.price,
          quantity: produto.quantity,
          totalPrice: produto.totalPrice,
          discount: produto.discount,
          active: produto.active,
          currentIndex: produto.currentIndex,
          totais: produto.totais,
        };
      });
      const subtotal = this.produtos.reduce((acc, produto) => (acc + (produto.quantity * produto.price - produto.discount)), 0);
      this.form = {
        produtos: [...this.produtos],
        subtotal,
      };
    } else {
      this.form = new ProdutoData({});
      this.form.produtos = [];
    }
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
    });
  },
  computed: {
    ...mapGetters([
      "isAuthenticated",
      "breadcrumbs",
      "pageTitle",
      "layoutConfig",
    ]),
    ...mapState(["loading"]),
    isLoading() {
      return this.loading.loading;
    },
    isTheCurrentTab() {
      return store.currentTab === 2;
    },
  },
};
</script>
