<template>
  <div class="card card-custom card-stretch gutter-b">
    <div class="card-header border-0 py-5">
      <h3 class="card-title align-items-start flex-column">
        <span class="card-label font-weight-bolder text-dark">
          Editar Conta á Pagar
        </span>
      </h3>
    </div>
    <Form url="/contapagar/update" v-if="!loader" :conta="contapagar" />
  </div>
</template>

<script>
import { mapActions } from "vuex";
import Form from "./Form.vue";
import { mapGetters } from "vuex";

export default {
  name: "app-conta-pagar-update",
  data() {
    return {
      contapagar: {},
      loader: true,
    };
  },
  components: {
    Form,
  },
  methods: {
    ...mapActions(["setLoading"]),
    async getcontapagar(id) {
      const { data } = await this.$http.post("/contapagar/find", {
        id: id,
      });

      return data;
    },
    async getInit() {
      try {
        const id = this.$route.params.id;

        this.setLoading(true);

        const contapagar = await this.getcontapagar(id);

        this.setLoading(false);

        if (!contapagar.id || contapagar == null) {
          this.$router.push({ name: "contapagarLista" });
          return this.$toast.error("Movimento não encontrado!");
        }

        this.contapagar = await contapagar;
        this.loader = false;
      } catch {
        return;
      }
    },
  },
  computed: {
    ...mapGetters(["itsblocked"]),
  },
  mounted() {
    if (this.itsblocked) {
      this.$router.push({ name: "assinatura" });
    }
    this.getInit();
  },
};
</script>
