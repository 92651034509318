<template>
  <b-modal id="modal-wpp-fone" size="md" centered title="Envio por whatsapp">
    <template #modal-header="{ close }">
      <!-- Emulate built in modal header close button action -->
      <h5>Envio por whatsapp</h5>
      <b-button
        size="sm"
        variant="outline-danger"
        @click="
          () => {
            close();
            openModal = false;
          }
        "
      >
        X
      </b-button>
    </template>
    <div class="mx-auto d-flex flex-column justify-content-center">
      <b-form-group
        id="wppNumberInput"
        label-for="wppNumberInput"
        label="Whatsapp:"
      >
        <b-form-input
          v-mask="['(##) #### ####', '(##) # #### ####']"
          v-model="phone"
          :key="render"
        />
      </b-form-group>
    </div>
    <template #modal-footer="{ ok, cancel }">
      <b-button
        @click="
          () => {
            cancel();
            openModal = false;
          }
        "
      >
        Cancelar
      </b-button>
      <b-button variant="primary" ref="sendToWpp" @click="confirmSendToWpp">
        Enviar
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import { mapGetters } from "vuex";
import { SistemaMixin } from "@/modules/Application";
import { mask } from "vue-the-mask";
import swal from "sweetalert";

export default {
  name: "SendToWppModal",
  mixins: [SistemaMixin],
  directives: {
    mask,
  },
  props: {
    data: {
      type: Object,
    },
    openModal: {
      type: Boolean,
    },
    module: {
      type: String,
    },
  },
  data() {
    return {
      phone: null,
      companyToDisplay: null,
      render: 0,
      windowWidth: window.innerWidth,
    };
  },
  methods: {
    async confirmSendToWpp() {
      if (!this.phone) {
        this.$toast.error("Preenchimento do número obrigatório!");
        return;
      }
      this.addLoadingToButton("sendToWpp");
      try {
        await this.sendToWhatsApp({
          type: this.module,
          data: this.data,
          linklabel:
            this.module === "os"
              ? "PDF"
              : this.module !== "orcamento" &&
                this.module !== "pedidos" &&
                this.module !== "ordemCompra"
              ? "XML e PDF"
              : this.module !== "pedidos" && this.module !== "ordemCompra"
              ? "Link de orçamento"
              : this.module === "ordemCompra"
              ? "ordem de compra"
              : "pedido",
        });
      } catch (error) {
        console.log(error);
      }
      this.phone = null;
      this.removeLoadingToButton("sendToWpp");
      this.openModal = false;
    },
    async getUserInfo() {
      try {
        const { data: clientData } = await this.$http.post(
          "/pessoa/get-fone-whatsapp",
          {
            ...((this.module === "orcamento" ||
              this.module === "nfse" ||
              this.module === "os" ||
              this.module === "pedidos" ||
              this.module === "aniversariante") && {
              id_pessoa: this.data.id_pessoa,
            }),
            ...((this.module === "nfe" || this.module === "nfce") && {
              cnpj_cpf: this.data.identificacaoCliente,
            }),
          }
        );
        this.phone = clientData?.whatsapp ?? null;
        if (!this.phone)
          this.$toast.error("Não existe número cadastrado para este cliente!");
        this.companyToDisplay = clientData?.nome_empresa ?? "X";
        this.render++;
      } catch (error) {
        this.companyToDisplay = "X";
      }
    },
    async sendToWhatsApp({ type, data, linklabel }) {
      let dataToSend = [];
      let nameToDisplay = "";

      if (type === "nfe" || type === "nfce") {
        if (data.statusTransmissao) {
          const responseXML = await this.$http.post("/nfe/xml", {
            id: data.id,
          });
          const resultXmlData = responseXML["data"]["xml-url"];
          dataToSend.push({
            url: resultXmlData,
            label: "XML",
          });
        }
      }

      if (type === "nfe") {
        this.$toast.info("Gerando PDF e XML aguarde...");
        nameToDisplay = data.nomeCliente;
        const responsePdf = await this.$http.post("/nfe/print-nfe", {
          id: data.id,
        });
        const resultPdfData = responsePdf["data"]["danfe-url"];
        dataToSend.push({
          url: resultPdfData,
          label: "PDF",
        });
      }
      if (type === "nfse") {
        this.$toast.info("Gerando PDF e XML aguarde...");
        const { data: wppRequestData } = await this.$http.post(
          "/nfse/whats-app",
          {
            id_nfse: data.id_nfse,
            telefone: this.phone,
          }
        );
        const { tomador, url_pdf_nfse, url_xml_nfse, boletos_url } =
          wppRequestData.dados;

        nameToDisplay = tomador.split(" ")[0];
        dataToSend.push({
          url: url_pdf_nfse,
          label: "PDF",
        });
        dataToSend.push({
          url: url_xml_nfse,
          label: "XML",
        });

        let boletos = [];

        if (boletos_url) {
          boletos_url.forEach((element) => {
            boletos.push(element.url_boleto);
            dataToSend.push({
              url: element.url_boleto,
              label: "Boleto: " + element.documento,
            });
          });
        }
      }

      if (type === "nfce") {
        this.$toast.info("Gerando PDF e XML aguarde...");

        if (!data.statusTransmissao) {
          const responsePdf = await this.$http.post("/nfe/printVenda", {
            id: data.id,
          });
          const resultPdfData = responsePdf["data"]["venda-url"];
          dataToSend.push({
            url: resultPdfData,
            label: "PDF",
          });
        }

        if (data.statusTransmissao) {
          const responsePdf = await this.$http.post("/nfe/printNota", {
            id: data.id,
          });
          const resultPdfData = responsePdf["data"]["danfe-url"];
          dataToSend.push({
            url: resultPdfData,
            label: "PDF",
          });
        }
      }

      if (type === "orcamento") {
        dataToSend = [
          {
            url: data.link,
            label: "Link orçamento",
          },
        ];

        this.$http.post("/orcamento/update-timeline", {
          id_orcamento: data.id_orcamento,
        });
      }

      if (type === "pedidos") {
        const responsePdf = await this.$http.post("/pedido/print", {
          id_pedido: data.id_pedido,
        });
        const resultPdfData = responsePdf.data.url_pedido;
        dataToSend.push({
          url: resultPdfData,
          label: "Pedido",
        });
      }

      if (type === "os") {
        dataToSend = [
          {
            url: data.link,
            label: "Link da ordem de serviço",
          },
        ];

        this.$http.post("/ordemservico/update-status-os", {
          id_ordem_servico: data.id_ordem_servico,
        });
      }

      if (type === "ordemCompra") {
        const responsePdf = await this.$http.post("/ordemcompra/print", {
          id_ordem_compra: data.id_ordem_compra,
        });
        const resultPdfData = responsePdf.data.url_ordem_compra;
        dataToSend.push({
          url: resultPdfData,
          label: "Ordem de Compra",
        });
      }

      let titleSwal = `Compartilhar ${linklabel} por WhatsApp?`;

      if (type === "aniversariante") {
        titleSwal = "Desejando um aniversário incrível para: " + data.nome;
      }

      const presentationText =
        type === "os"
          ? "Segue abaixo o link para acessar a OS!"
          : type === "ordemCompra"
          ? `Estamos lhe enviando o link da ${linklabel} para visualização!`
          : type === "aniversariante"
          ? `${data.nome},\nHoje é um dia especial, e queremos aproveitar para lhe desejar um feliz aniversário!\nQue esta nova fase traga muita felicidade, saúde e conquistas.\nQue todos os seus objetivos sejam alcançados.\nFeliz Aniversário!🎉 🥳🎈\nCom carinho, ${this.companyToDisplay}.`
          : `Estamos lhe enviando o link da ${linklabel} para visualização!`;

      const urlsMessage = dataToSend.reduce(
        (acc, element) => acc + `*${element.label}*: ${element.url}\n\n`,
        ""
      );

      let msg = "";
      if (type != "aniversariante") {
        msg = `Olá ${
          nameToDisplay === "" ? nameToDisplay : "*" + nameToDisplay + "*"
        }, somos da ${
          this.companyToDisplay
        }! \n${presentationText} \n\nAqui esta: \n ${urlsMessage}`;
      }

      if (type === "aniversariante") {
        msg = `${presentationText}`;
      }

      this.phone = this.phone.replace(/[^\d]+/g, "");
      if (this.phone.substr(0, 3) == "499") {
        this.phone = "49" + this.phone.substr(3, 8);
      }

      if (this.phone.substr(0, 3) == "479") {
        this.phone = "47" + this.phone.substr(3, 8);
      }

      if (this.phone.substr(0, 3) == "489") {
        this.phone = "48" + this.phone.substr(3, 8);
      }

      if (this.phone.substr(0, 3) == "549") {
        this.phone = "54" + this.phone.substr(3, 8);
      }

      if (this.phone.substr(0, 3) == "419") {
        this.phone = "41" + this.phone.substr(3, 8);
      }

      if (this.phone.substr(0, 3) == "459") {
        this.phone = "45" + this.phone.substr(3, 8);
      }

      if (this.phone.substr(0, 3) == "439") {
        this.phone = "43" + this.phone.substr(3, 8);
      }

      if (this.phone.substr(0, 3) == "559") {
        this.phone = "55" + this.phone.substr(3, 8);
      }
      if (this.phone.substr(0, 3) == "519") {
        this.phone = "51" + this.phone.substr(3, 8);
      }
      swal({
        title: titleSwal,
        text: "",
        icon: "info",
        buttons: {
          cancel: {
            text: "Não",
            value: "cancel",
            visible: true,
            closeModal: true,
          },
          confirm: {
            text: "Sim",
            visible: true,
            closeModal: false,
          },
        },
        dangerMode: false,
        closeModal: false,
        closeOnClickOutside: false,
      }).then(async (value) => {
        if (value === "cancel") {
          swal.close();
          swal.stopLoading();
          return;
        }

        if (this.windowWidth < 768) {
          window.open(
            "https://wa.me/55" +
              this.phone.replace(/[^a-z0-9]/gi, "") +
              "?text=" +
              encodeURIComponent(msg)
          );
        }

        if (this.windowWidth > 768) {
          window.open(
            "https://web.whatsapp.com/send?phone=55" +
              this.phone +
              "&text=" +
              encodeURIComponent(msg),
            "_blank"
          );
        }

        swal.stopLoading();
        swal.close();
      });
    },
  },
  watch: {
    openModal(value) {
      if (value) {
        this.$bvModal.show("modal-wpp-fone");
        this.$emit("handleOpenModal", true);
        this.getUserInfo();
      } else {
        this.$bvModal.hide("modal-wpp-fone");
        this.$emit("handleOpenModal", false);
      }
    },
  },
  computed: {
    ...mapGetters(["layoutConfig"]),
  },
};
</script>
