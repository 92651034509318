import NFSe from "@/modules/Servicos/pages/NFse";
import NFseForm from "@/modules/Servicos/pages/NFseForm";
import Configuracoes from "@/modules/Servicos/pages/Configuracoes";
import Relatorios from "@/modules/Servicos/pages/Relatorios";
import Tributacoes from "@/modules/Servicos/pages/Tributacoes";
import TributacaoForm from "@/modules/Servicos/pages/TributacaoForm";
import Servicos from "@/modules/Servicos/pages/Servicos";
import ServicosForm from "@/modules/Servicos/pages/ServicosForm";

export default [
  {
    name: "tributacoesServicos",
    path: "/tributacoesServicos",
    component: Tributacoes,
    meta: { title: "Serviços - Tributação" },
  },
  {
    name: "nova-nfseServicos",
    path: "/servicos/nova-nfse",
    component: NFseForm,
    meta: { title: "Serviços - NFSe" },
  },
  {
    name: "nfseServicos",
    path: "/nfse",
    component: NFSe,
    meta: { title: "Serviços - NFSe" },
  },
  {
    name: "configuracoesServicos",
    path: "/configuracoesServicos",
    component: Configuracoes,
    meta: { title: "Serviços - Configurações" },
  },
  {
    name: "nova-tributacaoServicos",
    path: "/servicos/nova-tributacao",
    component: TributacaoForm,
    meta: { title: "Serviços - Tributação" },
  },
  {
    name: "relatoriosServicos",
    path: "/relatoriosServicos",
    component: Relatorios,
    meta: { title: "Serviços - Relatórios" },
  },
  {
    name: "servicos",
    path: "/servicos",
    component: Servicos,
    meta: { title: "Serviços" },
  },
  {
    name: "novoServicos",
    path: "/servicos-novo",
    component: ServicosForm,
    meta: { title: "Serviços - Novo" },
  },
];
