<template>
  <b-card v-if="chartsLoaded && modulo != 'financeiro'" class="chartContainer">
    <b-row>
      <b-col md="12">
        <apexchart
          type="bar"
          height="300"
          :options="barChartOptions"
          :series="reaisSeries"
        ></apexchart>
      </b-col>
    </b-row>
  </b-card>
  <div v-else-if="chartsLoaded && modulo == 'financeiro'">
    <div class="row">
      <div class="col-md-4">
        <div class="card card-custom card-stretch" style="padding: 5px">
          <span
            class="svg-icon svg-icon-2x svg-icon-success font-weight-bolder font-size-h1 text-dark-75"
          >
            <ArrowUp :size="16" fillColor="#1BC5BD" />
            R$ {{ totalReceitas }}
          </span>
          <span class="font-weight-bold font-size-lg">Receitas</span>
        </div>
      </div>
      <div class="col-md-4">
        <div class="card card-custom card-stretch" style="padding: 5px">
          <span
            class="svg-icon svg-icon-2x svg-icon-success font-weight-bolder font-size-h1 text-dark-75"
          >
            <ArrowDown :size="16" fillColor="#F64E60" />
            R$ {{ totalDespesas }}
          </span>
          <span class="font-weight-bold font-size-lg">Despesas</span>
        </div>
      </div>
      <div class="col-md-4">
        <div class="card card-custom card-stretch" style="padding: 5px">
          <span
            class="svg-icon svg-icon-2x svg-icon-success ard-title font-weight-bolder font-size-h1 text-dark-75"
          >
            <Bank :size="16" :fillColor="colorCaixa" />
            R$ {{ saldoCaixa }}
          </span>
          <span class="font-weight-bold font-size-lg">Saldo</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { SistemaMixin } from "@/modules/Application";
import { chartStore } from "@/components/Charts/chartStore";
import ArrowUp from "vue-material-design-icons/ArrowUp";
import ArrowDown from "vue-material-design-icons/ArrowDown";
import Bank from "vue-material-design-icons/Bank";

export default {
  name: "chart-contas-receber",
  mixins: [SistemaMixin],
  components: {
    ArrowUp,
    ArrowDown,
    Bank,
  },
  data() {
    return {
      chartStore,
      chartsLoaded: false,
      radioChartValues: [],
      barChartOptions: {
        colors: ["#ffe380", "#13d8aa", "#d4526e"],
        chart: {
          id: "contas-receber-chart",
          toolbar: {
            show: false,
          },
        },
        plotOptions: {
          bar: {
            borderRadius: 2,
            horizontal: true,
            distributed: true,
            barHeight: "50%",
          },
        },
        dataLabels: {
          enabled: true,
          textAnchor: "start",
          style: {
            colors: ["#fff"],
          },
          formatter: function (val) {
            return val;
          },
          offsetX: 0,
        },
        xaxis: {
          categories: [],
          // categories: ['Contas a pagar', 'Contas pagas', 'Contas atrasadas']
        },
      },
      reaisSeries: [
        {
          data: [1, 2, 3],
        },
      ],
      radioChartOptions: {
        chart: {
          height: 390,
          type: "radialBar",
        },
        plotOptions: {
          radialBar: {
            offsetY: 0,
            startAngle: 0,
            // endAngle: 270,
            hollow: {
              margin: 5,
              size: "20%",
              background: "transparent",
              image: undefined,
            },
          },
          legend: {
            show: false,
            floating: true,
            fontSize: "16px",
            position: "left",
            offsetX: 160,
            offsetY: 15,
            labels: {
              useSeriesColors: true,
            },
            markers: {
              size: 0,
            },
            itemMargin: {
              vertical: 3,
            },
          },
        },

        colors: ["#33b2df", "#ffe433", "#13d8aa", "#d4526e"],
        labels: [],
        legend: {
          show: false,
          floating: true,
          fontSize: "14px",
          position: "left",
          offsetX: 90,
          offsetY: 15,
          labels: {
            useSeriesColors: true,
          },
          markers: {
            size: 0,
          },
          formatter: function (seriesName, opts) {
            return (
              seriesName + ":  " + opts.w.globals.series[opts.seriesIndex].value
            );
          },
          itemMargin: {
            vertical: 3,
          },
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              legend: {
                show: false,
              },
            },
          },
        ],
      },
      totalContas: 0,
      radioChartSeries: [],
      totalReceitas: 0,
      totalDespesas: 0,
      saldoCaixa: 0,
      colorCaixa: null,
    };
  },
  props: ["endpoint", "modulo"],
  methods: {
    async loadContasReceberTotais(bodyToSendToApi) {
      try {
        const body = {
          columnFilters: {
            ...bodyToSendToApi,
          },
        };
        const { data } = await this.$http.post(this.endpoint, body);

        if (this.modulo == "financeiro") {
          this.totalReceitas = this.numberToReal(
            data.total_receitas.value_monetario,
            2
          );
          this.totalDespesas = this.numberToReal(
            data.total_despesas.value_monetario,
            2
          );
          this.saldoCaixa = this.numberToReal(
            data.total_receitas.value_monetario -
              data.total_despesas.value_monetario,
            2
          );

          if (parseFloat(this.saldoCaixa) > 0) {
            this.colorCaixa = "#1BC5BD";
          }

          if (parseFloat(this.saldoCaixa) <= 0) {
            this.colorCaixa = "#F64E60";
          }

          this.chartsLoaded = true;
          return;
        }

        const dataToArray = Object.entries(data).map((item) => item[1]);
        const length = dataToArray.length;

        this.reaisSeries = [
          {
            name: "Total",
            data: null,
          },
        ];
        this.reaisSeries[0].data = dataToArray.map((el) => el.value_monetario);
        this.barChartOptions.dataLabels.formatter = (val) => {
          return this.numberToReal(val, 2);
        };
        this.barChartOptions.colors =
          length === 2
            ? ["#13d8aa", "#d4526e"]
            : ["#ffe380", "#13d8aa", "#d4526e"];

        this.radioChartOptions.colors =
          length === 2
            ? ["#33b2df", "#13d8aa", "#d4526e"]
            : ["#33b2df", "#ffe433", "#13d8aa", "#d4526e"];

        const totalContas = dataToArray.reduce((acc, el) => acc + el.value, 0);
        this.radioChartValues = dataToArray.map((el) => el.value);
        this.radioChartSeries = [
          100,
          ...this.radioChartValues.map(
            (item) => Math.round((item / totalContas) * 100 * 100) / 100
          ),
        ];
        this.radioChartOptions.labels = [
          "Total",
          ...dataToArray.map((item) => item.label),
        ];
        this.barChartOptions.xaxis.categories = dataToArray.map(
          (item) => item.label
        );
        this.radioChartOptions.legend.formatter = (seriesName, opts) => {
          return seriesName + ":  " + this.radioChartValues[opts.seriesIndex];
        };
        this.chartsLoaded = true;
      } catch (error) {
        console.log(error);
      }
    },
  },
  computed: {
    ...mapGetters(["layoutConfig", "itsblocked", "nutsDigital"]),
    config() {
      return this.layoutConfig();
    },
    moduleName() {
      const aux = this.$route.fullPath.replace("/", "").split("/");
      const isBancoMovimentacao =
        aux.at(-1) === "movimentacao" && aux.at(0) === "bancos";
      return !isBancoMovimentacao
        ? this.$route.fullPath.replace("/", "")
        : "bancosMovimentacao";
    },
  },
  watch: {
    "chartStore.bodyToSendToApi"(newValue) {
      this.loadContasReceberTotais(newValue);
    },
  },
  mounted() {
    if (this.itsblocked) {
      this.$router.push({ name: "assinatura" });
    }
    this.loadContasReceberTotais(this.chartStore.bodyToSendToApi);
  },
};
</script>

<style>
.chartContainer {
  display: flex;
}
</style>