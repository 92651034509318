var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-modal',{attrs:{"id":"variacaoModal","title":`Produto: ${_vm.produto?.descricao}`,"centered":""},on:{"shown":_vm.handleProdutoVariavel},scopedSlots:_vm._u([{key:"modal-footer",fn:function({ ok, cancel }){return [_c('div',[_c('b-button',{ref:"addProdutoVariavel",staticClass:"mr-5",attrs:{"variant":"primary"},on:{"click":_vm.handleAddProdutoVariavel}},[_vm._v(" Adicionar ")]),_c('b-button',{on:{"click":() => {
            cancel();
          }}},[_vm._v(" Cancelar ")])],1)]}}])},[(!_vm.loading)?_c('div',[_c('b-row',{staticClass:"mb-10"},[_c('b-col',[_c('div',{staticStyle:{"display":"flex","align-items":"center","gap":"10px"}},[_c('button',{staticClass:"btn btn-sm btn-secondary font-weight-bold border border-primary",on:{"click":() => (_vm.combinacao.quantidade = _vm.combinacao.quantidade + 1)}},[_c('Plus',{attrs:{"size":10}})],1),_c('h4',{staticClass:"mb-0"},[_vm._v(_vm._s(_vm.combinacao.quantidade))]),_c('button',{staticClass:"btn btn-sm btn-secondary font-weight-bold border border-primary",on:{"click":() =>
                (_vm.combinacao.quantidade =
                  _vm.combinacao.quantidade - 1 < 0
                    ? 0
                    : _vm.combinacao.quantidade - 1)}},[_c('Minus',{attrs:{"size":10}})],1),_c('p',{staticClass:"mb-0 mt-5",staticStyle:{"width":"50px"}},[_vm._v("un")])])]),_c('b-col',{staticStyle:{"display":"flex","align-items":"center"}},[_c('h2',{staticClass:"mb-0"},[_vm._v("R$ "+_vm._s(_vm.numberToReal(_vm.produto?.preco)))])])],1),(!!_vm.variacoes)?_c('b-row',_vm._l((_vm.variacoes.labels),function(variacaoLabel,index){return _c('b-col',{key:variacaoLabel},[_c('b-form-group',{attrs:{"label":variacaoLabel,"label-for":variacaoLabel}},[_c('b-form-select',{attrs:{"placeholder":""},on:{"change":() => _vm.handleChangeVariacao(index)},model:{value:(_vm.combinacao[variacaoLabel]),callback:function ($$v) {_vm.$set(_vm.combinacao, variacaoLabel, $$v)},expression:"combinacao[variacaoLabel]"}},_vm._l((_vm.variacoes[variacaoLabel]),function(variacao){return _c('b-form-select-option',{key:variacao,style:({
                color: _vm.findEstoque(variacaoLabel, variacao, index)
                  ? 'grey'
                  : '',
                backgroundColor: _vm.findEstoque(variacaoLabel, variacao, index)
                  ? '#efefef'
                  : '',
              }),attrs:{"value":variacao,"disabled":_vm.findEstoque(variacaoLabel, variacao, index)}},[_vm._v(" "+_vm._s(variacao)+_vm._s(_vm.findEstoque(variacaoLabel, variacao, index) ? " - ESGOTADO" : "")+" ")])}),1)],1)],1)}),1):_vm._e(),_c('b-row',[_c('b-col',[_vm._v(" Selecionado: "+_vm._s(_vm.combinacaoSelected)+" ")])],1)],1):_c('div',{staticStyle:{"display":"flex","justify-content":"center","align-items":"center","position":"relative"}},[_c('b-spinner',{staticStyle:{"width":"3rem","height":"3rem"},attrs:{"variant":"primary","label":"Spinning"}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }