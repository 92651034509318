import { required } from "vuelidate/lib/validators";

export default class AssinaturaData {
  status = null;
  data_validade = null;
  data_pagamento = null;
  motivo_bloqueio = null;
  valor = null;
  id_plan = null;
  plano = null;
  constructor({
    status = null,
    data_validade = null,
    data_pagamento = null,
    motivo_bloqueio = null,
    valor = null,
    id_plan = null,
    plano = null,
  }) {
    this.status = status;
    this.data_validade = data_validade;
    this.data_pagamento = data_pagamento;
    this.motivo_bloqueio = motivo_bloqueio;
    this.valor = valor;
    this.id_plan = id_plan;
    this.plano = plano;
  }
}

export const AssinaturaDataRequired = {
  status: {
    required,
  },
};
