<template>
  <div class="card card-custom card-stretch gutter-b">
    <div class="card-header border-0 py-5">
      <h3 class="card-title align-items-start flex-column">
        <span class="card-label font-weight-bolder text-dark">
          Configuração de Boletos
          <router-link
            class="btn btn-xs btn-primary btn-icon mr-2"
            v-b-tooltip="
              'Dúvidas sobre aplicar Multa/Juros/Desconto no boleto? Clique aqui!'
            "
            :to="{
              name: 'boletosConfigQuest',
            }"
          >
            <Quest :size="16" />
          </router-link>
        </span>
      </h3>
    </div>
    <b-form class="form" @submit.stop.prevent="onSubmit">
      <div class="card-body">
        <b-tabs class="hide-tabs" v-model="tabIndex">
          <b-tab active>
            <label>Configurações Multa</label>
            <hr />
            <b-row>
              <b-col md="5">
                <b-form-group
                  label="Selecione o modo da multa"
                  label-for="late_fee_mode"
                >
                  <select
                    name="late_fee_mode"
                    id="late_fee_mode"
                    v-model="form.late_fee_mode"
                    class="form-control"
                  >
                    <option
                      v-for="(m, index) in modeFee"
                      :value="m.code"
                      :key="'code' + index"
                    >
                      {{ m.description }}
                    </option>
                  </select>
                </b-form-group>
              </b-col>
              <b-col md="3" v-if="form.late_fee_mode == 'FIXED'">
                <b-form-group
                  id="late_fee_amount"
                  label-for="late_fee_amount"
                  label="Valor fixo para o tipo de multa R$"
                >
                  <money
                    class="form-control"
                    v-model="form.late_fee_amount"
                    v-bind="configMoney"
                  >
                  </money>
                </b-form-group>
              </b-col>
              <b-col md="3" v-if="form.late_fee_mode == 'PERCENTAGE'">
                <b-form-group
                  id="late_fee_percentage"
                  label-for="late_fee_percentage"
                  label="Porcentagem baseado no valor do boleto %"
                >
                  <money
                    class="form-control"
                    v-model="form.late_fee_percentage"
                    v-bind="configMoney"
                  >
                  </money>
                </b-form-group>
              </b-col>
              <b-col md="3">
                <b-form-group
                  id="late_fee_start_date"
                  label-for="late_fee_start_date"
                  label="Dias para somar a data de vencimento"
                >
                  <b-input
                    class="form-control"
                    type="number"
                    v-model="$v.form.late_fee_start_date.$model"
                    :state="validateState('late_fee_start_date')"
                  >
                  </b-input>
                </b-form-group>
              </b-col>
            </b-row>
            <label>Configurações Juros</label>
            <hr />
            <b-row>
              <b-col md="5">
                <b-form-group
                  label="Selecione o modo de Juros"
                  label-for="interest_mode"
                >
                  <select
                    name="interest_mode"
                    id="interest_mode"
                    v-model="form.interest_mode"
                    class="form-control"
                  >
                    <option
                      v-for="(m, index) in modeInterest"
                      :value="m.code"
                      :key="'code' + index"
                    >
                      {{ m.description }}
                    </option>
                  </select>
                </b-form-group>
              </b-col>
              <b-col md="3" v-if="form.interest_mode == 'DAILY_AMOUNT'">
                <b-form-group
                  id="interest_amount"
                  label-for="interest_amount"
                  label="Valor fixo para juros R$"
                >
                  <money
                    class="form-control"
                    v-model="form.interest_amount"
                    v-bind="configMoney"
                  >
                  </money>
                </b-form-group>
              </b-col>
              <b-col
                md="3"
                v-if="
                  form.interest_mode == 'DAILY_PERCENTAGE' ||
                  form.interest_mode == 'MONTHLY_PERCENTAGE'
                "
              >
                <b-form-group
                  id="interest_percentage"
                  label-for="interest_percentage"
                  label="Porcentagem baseado no valor do boleto %"
                >
                  <money
                    class="form-control"
                    v-model="form.interest_percentage"
                    v-bind="configMoney"
                  >
                  </money>
                </b-form-group>
              </b-col>
              <b-col md="3">
                <b-form-group
                  id="interest_start_date"
                  label-for="interest_start_date"
                  label="Dias para somar a data de vencimento"
                >
                  <b-input
                    class="form-control"
                    type="number"
                    v-model="$v.form.interest_start_date.$model"
                    :state="validateState('interest_start_date')"
                  >
                  </b-input>
                </b-form-group>
              </b-col>
            </b-row>
            <label>Configurações Desconto</label>
            <hr />
            <b-row>
              <b-col md="5">
                <b-form-group
                  label="Selecione o modo do desconto"
                  label-for="discount_mode"
                >
                  <select
                    name="discount_mode"
                    id="discount_mode"
                    v-model="form.discount_mode"
                    class="form-control"
                  >
                    <option
                      v-for="(m, index) in modeDiscount"
                      :value="m.code"
                      :key="'code' + index"
                    >
                      {{ m.description }}
                    </option>
                  </select>
                </b-form-group>
              </b-col>
              <b-col md="3" v-if="form.discount_mode == 'FIXED'">
                <b-form-group
                  id="discount_amount"
                  label-for="discount_amount"
                  label="Valor fixo para o desconto R$"
                >
                  <money
                    class="form-control"
                    v-model="form.discount_amount"
                    v-bind="configMoney"
                  >
                  </money>
                </b-form-group>
              </b-col>
              <b-col md="3" v-if="form.discount_mode == 'PERCENTAGE'">
                <b-form-group
                  id="discount_percentage"
                  label-for="discount_percentage"
                  label="Porcentagem baseado no valor do boleto %"
                >
                  <money
                    class="form-control"
                    v-model="form.discount_percentage"
                    v-bind="configMoney"
                  >
                  </money>
                </b-form-group>
              </b-col>
              <b-col md="3">
                <b-form-group
                  id="discount_limit_date"
                  label-for="discount_limit_date"
                  label="Dias para descontar da data de vencimento"
                >
                  <b-input
                    class="form-control"
                    type="number"
                    v-model="$v.form.discount_limit_date.$model"
                    :state="validateState('discount_limit_date')"
                  >
                  </b-input>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-form-group
                  id="instructions_line1"
                  label-for="instructions_line1"
                  label="Primeira linha de instruções"
                >
                  <b-input
                    class="form-control"
                    v-model="form.instructions_line1"
                  >
                  </b-input>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-form-group
                  id="instructions_line2"
                  label-for="instructions_line2"
                  label="Segunda linha de instruções"
                >
                  <b-input
                    class="form-control"
                    v-model="form.instructions_line2"
                  >
                  </b-input>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-form-group
                  id="instructions_line3"
                  label-for="instructions_line3"
                  label="Terceira linha de instruções"
                >
                  <b-input
                    class="form-control"
                    v-model="form.instructions_line3"
                  >
                  </b-input>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-form-group
                  id="instructions_line4"
                  label-for="instructions_line4"
                  label="Quarta linha de instruções"
                >
                  <b-input
                    class="form-control"
                    v-model="form.instructions_line4"
                  >
                  </b-input>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-form-group
                  id="instructions_line5"
                  label-for="instructions_line5"
                  label="Quinta linha de instruções"
                >
                  <b-input
                    class="form-control"
                    v-model="form.instructions_line5"
                  >
                  </b-input>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-form-group
                  id="use_logo"
                  label-for="use_logo"
                  label="Usar a logo do emitente?"
                >
                  <span class="switch switch-icon">
                    <label>
                      <input
                        type="checkbox"
                        :value="true"
                        @change="setUseLogo"
                        :checked="form.use_logo"
                      />
                      <span></span>
                    </label>
                  </span>
                </b-form-group>
              </b-col>
            </b-row>
          </b-tab>
        </b-tabs>
      </div>

      <div class="card-footer">
        <div class="row">
          <div class="col-lg-12" style="text-align: center">
            <button
              type="submit"
              ref="submit_config_boleto"
              class="btn btn-primary font-weight-bold"
            >
              Salvar
            </button>
          </div>
        </div>
      </div>
    </b-form>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { validationMixin } from "vuelidate";
import { SistemaMixin } from "@/modules/Application";
import { PessoaMixin } from "@/modules/Application";
import ConfigBoletoData, { ConfigBoletoDataRequired } from "./ConfigBoletoData";
import { Money } from "v-money";
import { MONEY as settingMoney } from "@/modules/Application";
import Quest from "vue-material-design-icons/AccountQuestion.vue";
export default {
  name: "app-config-boleto",
  data() {
    return {
      url: null,
      tabIndex: 0,
      configMoney: settingMoney,
      form: null,
      configBoleto: new ConfigBoletoData({}),
      modeFee: [
        {
          code: "FIXED",
          description: "Valor fixo para o tipo de multa",
        },
        {
          code: "PERCENTAGE",
          description: "Porcentagem baseado no valor do boleto",
        },
      ],
      modeInterest: [
        {
          code: "DAILY_AMOUNT",
          description:
            "Valor diário para incidência de juros após um dia corrido da data de vencimento",
        },
        {
          code: "DAILY_PERCENTAGE",
          description:
            "Percentual diário para incidência de juros após um dia corrido da data de vencimento.",
        },
        {
          code: "MONTHLY_PERCENTAGE",
          description:
            "Percentual mensal para incidência de juros após um dia corrido da data de vencimento.",
        },
      ],
      modeDiscount: [
        {
          code: "FIXED",
          description: "Valor fixo para o desconto",
        },
        {
          code: "PERCENTAGE",
          description: "Porcentagem baseado no valor do boleto",
        },
      ],
    };
  },
  mixins: [validationMixin, SistemaMixin, PessoaMixin],
  computed: {
    ...mapGetters(["itsblocked"]),
  },
  validations: {
    form: ConfigBoletoDataRequired,
  },
  components: {
    Money,
    Quest,
  },
  watch: {
    configBoleto(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.form = newValue;
      }
    },
  },
  methods: {
    ...mapActions(["setLoading"]),
    async getConfigBoletoByEmpresa() {
      const { data } = await this.$http.post("/boletos/find-empresa");
      return data;
    },
    setUseLogo() {
      this.form.use_logo = this.form.use_logo == true ? false : true;
    },
    async getInit() {
      try {
        this.setLoading(true);
        const configBoleto = await this.getConfigBoletoByEmpresa();
        this.setLoading(false);
        if (!configBoleto.id_config_boleto || configBoleto == null) {
          this.configBoleto = new ConfigBoletoData({});
          this.url = "/boletos/create-config";
          return;
        }
        this.configBoleto = new ConfigBoletoData(configBoleto);
        this.configBoleto.id_config_boleto = configBoleto.id_config_boleto;
        this.url = "boletos/update-config";
      } catch {
        return;
      }
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    async onSubmit() {
      const submitButton = this.$refs["submit_config_boleto"];
      try {
        this.$v.form.$touch();
        if (this.$v.form.$anyError) {
          this.$toast.error(
            "Verifique todos os campos obrigatórios do formulário!"
          );
          return;
        }
        submitButton.classList.add("spinner", "spinner-light", "spinner-right");
        submitButton.setAttribute("disabled", "");
        await this.$http.post(this.url, this.form);
        this.$toast.success("Configuração cadastrada com sucesso!");
        await this.getInit();
        submitButton.classList.remove(
          "spinner",
          "spinner-light",
          "spinner-right"
        );
        submitButton.removeAttribute("disabled", "");
      } catch (error) {
        submitButton.classList.remove(
          "spinner",
          "spinner-light",
          "spinner-right"
        );
        submitButton.removeAttribute("disabled", "");
      }
    },
  },
  async mounted() {
    if (this.itsblocked) {
      this.$router.push({ name: "assinatura" });
    }
    await this.getInit();
  },
};
</script>