<template>
  <div :class="className">
    <div class="spinner"></div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "Loader",
  props: {
    insideComponent: {
      type: Boolean,
      default: () => false,
    },
  },
  computed: {
    ...mapGetters(["layoutConfig"]),
    className() {
      const className = ["loader"];

      if (this.insideComponent) {
        className.push("inside-component");
      }

      return className.join(" ");
    },
  },
};
</script>
