import { required } from "vuelidate/lib/validators";

export default class FaturamentoData {
  forma_pagamento = null;
  valor = null;
  desconto = null;
  conta = null;
  plano_conta = null;
  informacoes_adicionais = null;
  vencimento = null;
  subtotal = 0;
  pagamentos = []
  constructor(
    forma_pagamento = null,
    valor = 0,
    conta = null,
    plano_conta = null,
    desconto = null,
    informacoes_adicionais = '',
    vencimento = null,
    subtotal = 0,
    pagamentos = []
  ) {
    this.forma_pagamento = forma_pagamento;
    this.valor = valor;
    this.conta = conta;
    this.plano_conta = plano_conta;
    this.informacoes_adicionais = informacoes_adicionais;
    this.vencimento = vencimento;
    this.subtotal = subtotal;
    this.pagamentos = pagamentos;
    this.desconto = desconto;
  }
}

export const FaturamentoDataRequired = {
  pagamentos: {
    required
  }
};
