<template>
  <div class="container-fluid">
    <div class="card-body">
      <b-card>
        <b-row>
          <b-col :md="productionTimelineItems.length > 0 ? '7' : '12'">
            <div class="mb-10">
              <h3>
                Ordem de produção {{ `${numeroOP ? ` - ${numeroOP}` : ""}` }}
              </h3>
            </div>
            <b-row>
              <b-col>
                <b-form-group label="Item para produção" label-for="item">
                  <VueSelect
                    ref="buscarProduto"
                    :filterable="false"
                    @input="setProduto"
                    :options="produtosFetched"
                    @search="onSearchProduto"
                    v-model="produtoSelected"
                    :disabled="!notSaved"
                  >
                    <template slot="no-options">
                      Informe o produto pelo nome
                    </template>
                    <template slot="option" slot-scope="option">
                      <div class="d-flex justify-content-between">
                        <div>{{ option.codigo }} - {{ option.descricao }}</div>
                        <span class="text-muted">{{
                          `QTD: ${option.quantidade}`
                        }}</span>
                      </div>
                    </template>
                    <template slot="selected-option" slot-scope="option">
                      <div class="selected d-center">
                        {{ option.codigo }} - {{ option.descricao }}
                      </div>
                    </template>
                  </VueSelect>
                </b-form-group>
              </b-col>
              <b-col md="2">
                <b-form-group label="Quantidade" label-for="quantidade">
                  <money
                    class="form-control"
                    id="quantidade"
                    name="quantidade"
                    v-model="quantidade"
                    v-bind="quantityOptions"
                    @blur.native="handleChangeQuantidade"
                    :disabled="!notSaved"
                  >
                  </money>
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group label="Status" label-for="status">
                  <b-form-select
                    :disabled="notSaved"
                    @change="handleChangeStatus"
                    :value="statusProd"
                    :key="key"
                  >
                    <b-form-select-option
                      v-for="status in allStatus"
                      :key="status.id"
                      :value="status"
                      :disabled="
                        (status.id === 5 || status.id === 6) && !prodIniciada
                      "
                    >
                      {{ status.descricao }}
                    </b-form-select-option>
                  </b-form-select>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-form-group label="Responsável" label-for="responsavel">
                  <b-form-input v-model="responsavel" :disabled="!notSaved" />
                </b-form-group>
              </b-col>
              <b-col md="3">
                <b-form-group
                  id="dataPrevisao"
                  label-for="dataPrevisao"
                  label="Data Previsão Início"
                >
                  <b-form-datepicker
                    v-model="dataPrevisao"
                    :disabled="!notSaved"
                    reset-button
                    locale="pt-br"
                    :date-format-options="{
                      year: 'numeric',
                      month: 'numeric',
                      day: 'numeric',
                    }"
                  >
                  </b-form-datepicker>
                </b-form-group>
              </b-col>
              <b-col md="3">
                <b-form-group
                  id="dataPrevisaoTermino"
                  label-for="dataPrevisaoTermino"
                  label="Data Previsão Término"
                >
                  <b-form-datepicker
                    v-model="dataPrevisaoTermino"
                    :disabled="!notSaved"
                    reset-button
                    locale="pt-br"
                    :date-format-options="{
                      year: 'numeric',
                      month: 'numeric',
                      day: 'numeric',
                    }"
                  >
                  </b-form-datepicker>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row class="">
              <b-col style="padding: 0 !important">
                <label for="Composição(insumos)">Composição(insumos)</label>
                <TableApp
                  item="insumos"
                  subTitulo=""
                  :data="insumos"
                  :fields="fields"
                  classFilterColumn="nome"
                  ref="nome"
                >
                  <template slot="customRow" scope="props">
                    <span
                      slot="codigo"
                      class="pl-0 pt-8"
                      v-if="props.field == 'codigo'"
                    >
                      <div class="d-flex align-items-center">
                        <div class="font-weight-bold">
                          {{ props.rowData.codigo }}
                        </div>
                      </div>
                    </span>
                    <span
                      slot="descricao"
                      class="pl-0 pt-8"
                      v-if="props.field == 'descricao'"
                    >
                      <div class="d-flex align-items-center">
                        <div class="font-weight-bold">
                          {{ props.rowData.descricao }}
                        </div>
                      </div>
                    </span>
                    <span
                      slot="unidade_medida"
                      class="pl-0 pt-8"
                      v-if="props.field == 'unidade_medida'"
                    >
                      <div class="d-flex align-items-center">
                        <div class="font-weight-bold">
                          {{ props.rowData.unidade_medida }}
                        </div>
                      </div>
                    </span>

                    <span
                      slot="qtd_total"
                      class="pl-0 pt-8"
                      v-if="props.field == 'qtd_total'"
                    >
                      <div class="d-flex align-items-center">
                        <div class="font-weight-bold">
                          {{ numberToReal(props.rowData.qtd_total) }}
                        </div>
                      </div>
                    </span>
                    <span
                      slot="estoque_atual"
                      class="pl-0 pt-8"
                      v-if="props.field == 'estoque_atual'"
                    >
                      <div class="d-flex align-items-center">
                        <div class="font-weight-bold">
                          {{ numberToReal(props.rowData.estoque_atual) }}
                        </div>
                      </div>
                    </span>
                    <span
                      slot="estoque_previsto"
                      class="pl-0 pt-8"
                      v-if="props.field == 'estoque_previsto'"
                    >
                      <div class="d-flex align-items-center">
                        <div class="font-weight-bold">
                          {{ numberToReal(props.rowData.estoque_previsto) }}
                        </div>
                      </div>
                    </span>
                  </template>
                </TableApp>
              </b-col>
            </b-row>
            <b-row class="mt-5">
              <b-form-group
                id="observacoes"
                label="Observações"
                label-for="observacoes"
                style="width: 100%"
              >
                <b-form-textarea
                  rows="6"
                  max-rows="6"
                  id="observacoes"
                  name="observacoes"
                  v-model="observacoes"
                >
                </b-form-textarea>
              </b-form-group>
            </b-row>
          </b-col>
          <b-col class="pt-20">
            <div
              v-for="item of productionTimelineItems"
              :key="item.name"
              style="display: flex"
            >
              <vue-timeline-update
                :date="new Date(item.date)"
                :description="`${$moment(item.date).format('DD/MM/YYYY')} as ${
                  item.time
                }${item.observacao ? `\n Observação: ${item.observacao}` : ''}${
                  item.quantidade
                    ? `\nQtd: ${numberToReal(item.quantidade)}`
                    : ''
                }`"
                :category="item.name"
                :color="item.color"
                icon="code"
              />
            </div>
          </b-col>
        </b-row>
      </b-card>
      <div class="card-footer">
        <div class="row">
          <div class="col-lg-12" style="text-align: center">
            <div>
              <div
                class="form mb-5"
                style="
                  display: flex;
                  gap: 10px;
                  align-items: center;
                  justify-content: center;
                "
              >
                <button
                  ref="submit_nova_prod"
                  class="btn btn-primary font-weight-bold"
                  @click="salvarProducao"
                  v-if="notSaved"
                >
                  Salvar
                </button>
                <button
                  ref="submit_previa_nfe"
                  class="btn btn-clean font-weight-bold"
                  @click="confirmCancel('producao')"
                >
                  Cancelar
                </button>
                <button
                  ref="finalizar"
                  class="btn btn-success font-weight-bold"
                  v-b-modal.finalizarProducao
                  v-if="!notSaved"
                >
                  Finalizar
                </button>
                <button
                  ref="finalizar"
                  class="btn btn-warning font-weight-bold"
                  v-b-modal.entregaParcial
                  v-if="prodIniciada"
                >
                  Entrega parcial
                </button>
                <button
                  ref="iniciar_prod"
                  class="btn btn-secondary font-weight-bold border border-primary"
                  v-if="
                    !notSaved &&
                    (statusProd.id === 1 ||
                      statusProd.id === 4 ||
                      statusProd.id === 5)
                  "
                  @click="iniciarProd"
                >
                  Iniciar
                </button>
                <button
                  ref="pausar_prod"
                  class="btn btn-secondary font-weight-bold border border-primary"
                  v-if="!notSaved && statusProd.id === 2"
                  @click="pausarProd"
                >
                  Pausar
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <b-modal
      id="finalizarProducao"
      title="Deseja finalizar a produção?"
      centered
      size="xs"
    >
      <label>
        <p>Ordem de Produção: {{ numeroOP }}</p>
      </label>
      <b-row>
        <b-col>
          <b-form-group label="Quantidade" label-for="quantidadeFinalizar">
            <money
              class="form-control"
              id="quantidadeFinalizar"
              name="quantidadeFinalizar"
              v-model="quantidadeFinalizar"
              v-bind="quantityOptions"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group
            id="observacao"
            label="Observações"
            label-for="observacao"
            style="width: 100%"
          >
            <b-form-textarea
              rows="6"
              max-rows="6"
              id="observacao"
              name="observacao"
              v-model="observacaoFinalizar"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <template #modal-footer="{ ok, cancel }">
        <div>
          <b-button
            variant="success"
            @click="finalizarProd"
            ref="finalizar_prod"
            class="mr-5"
          >
            Finalizar
          </b-button>
          <b-button
            @click="cancel()"
            class="btn btn-secondary font-weight-bold border border-primary"
          >
            Cancelar
          </b-button>
        </div>
      </template>
    </b-modal>

    <b-modal id="entregaParcial" title="Entrega Parcial" centered size="xs">
      <label>
        <p>Ordem de Produção: {{ numeroOP }}</p>
      </label>
      <b-row>
        <b-col>
          <b-form-group label="Estoque Parcial" label-for="estoqueParcial">
            <money
              class="form-control"
              id="estoqueParcial"
              name="estoqueParcial"
              v-model="estoqueParcial"
              v-bind="quantityOptions"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group
            id="observacao"
            label="Observações"
            label-for="observacao"
            style="width: 100%"
          >
            <b-form-textarea
              rows="6"
              max-rows="6"
              id="observacao"
              name="observacao"
              v-model="observacaoParcial"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <template #modal-footer="{ ok, cancel }">
        <div>
          <b-button
            variant="success"
            @click="handleUpdateParcial"
            ref="parcial_prod"
            class="mr-5"
          >
            Finalizar parcial
          </b-button>
          <b-button
            @click="cancel()"
            class="btn btn-secondary font-weight-bold border border-primary"
          >
            Cancelar
          </b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters, mapState, mapActions } from "vuex";
import "vue-select/dist/vue-select.css";
import { validationMixin } from "vuelidate";
import { quantityOptions } from "@/modules/Application";
import FormatMixin from "../../Application/mixins/FormatMixin";
import { SistemaMixin, ValidacaoMixin } from "@/modules/Application";
import { mask } from "vue-the-mask";
import PagamentosMixin from "../../Pdv/mixins/PagamentosMixin";
import InstallmentsMixin from "../../Application/mixins/InstallmentsMixin";
import { store } from "../storeOP";
import TableApp from "@/components/Table/Table3.vue";
import VueSelect from "vue-select";
import _ from "lodash";
import swal from "sweetalert";
import { Money } from "v-money";
import moment from "moment";

export default {
  name: "app-os-form",
  directives: {
    mask,
  },
  data() {
    return {
      store,
      quantityOptions,
      producaoId: null,
      numeroOP: null,
      notSaved: true,
      key: 0,
      windowWidth: window.innerWidth,
      productionTimelineItems: [],
      dataPrevisaoTermino: null,
      dataPrevisao: null,
      responsavel: null,
      statusProd: null,
      selectedOcamentos: null,
      produtoSelected: null,
      insumos: [],
      quantidade: 1,
      observacoes: null,
      produtosFetched: [],
      prodIniciada: false,
      quantidadeFinalizar: null,
      observacaoFinalizar: null,
      estoqueParcial: null,
      observacaoParcial: null,
      allStatus: [
        {
          id: 1,
          descricao: "Não Iniciado",
        },
        {
          id: 2,
          descricao: "Em produção",
        },
        {
          id: 3,
          descricao: "Atrasado",
        },
        {
          id: 4,
          descricao: "Pausado",
        },
        {
          id: 5,
          descricao: "Entrega Parcial",
        },
        {
          id: 6,
          descricao: "Finalizado",
        },
      ],
      fields: [
        {
          label: "Cód.",
          field: "codigo",
          slot: true,
          sortable: false,
          width: "5%",
          filterOptions: {
            enabled: false,
          },
        },
        {
          label: "Insumo",
          field: "descricao",
          slot: true,
          sortable: false,
          width: "40%",
          filterOptions: {
            enabled: false,
          },
        },
        {
          label: "UN",
          field: "unidade_medida",
          slot: true,
          sortable: false,
        },
        {
          label: "Qtd Total",
          field: "qtd_total",
          slot: true,
          sortable: false,
        },
        {
          label: "Estoque atual",
          field: "estoque_atual",
          slot: true,
          sortable: false,
        },
        {
          label: "Estoque previsto",
          field: "estoque_previsto",
          slot: true,
          sortable: false,
        },
      ],
    };
  },
  mixins: [
    validationMixin,
    FormatMixin,
    SistemaMixin,
    ValidacaoMixin,
    PagamentosMixin,
    InstallmentsMixin,
  ],
  components: {
    VueSelect,
    Money,
    TableApp,
  },
  watch: {
    total(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.valorTotal = newValue;
      }
    },
    statusProd: {
      handler(status) {
        if (status.id === 2) this.prodIniciada = true;
        if (status.id === 4) this.prodIniciada = false;
      },
      deep: true,
    },
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
    });
    if (!this.isAuthenticated) {
      this.$router.push({ name: "login" });
    }
  },
  methods: {
    ...mapActions(["setLoading"]),
    handlePopulateTimeline(productionItems) {
      const timelineItems = productionItems
        .filter((item) => item.status !== "Não Iniciado")
        .map((item) => {
          let color;
          switch (item.status) {
            case "Em produção":
              color = "turquoise";
              break;
            case "Finalizada":
              color = "green";
              break;
            case "Entrega Parcial":
              color = "blue";
              break;
            case "Pausado":
            case "Atrasado":
              color = "orange";
              break;
            default:
              color = "blue";
              break;
          }
          return {
            name: item.status,
            label: item.status,
            color,
            observacao: item.observacao,
            quantidade: item.quantidade,
            date: moment(item.data_hora.date).format("YYYY-MM-DD"),
            time: moment(item.data_hora.date).format("HH:mm:ss"),
          };
        });
      return timelineItems;
    },
    handleChangeStatus(status) {
      this.$swal
        .fire({
          icon: "warning",
          title: `Deseja alterar o status para: "${status.descricao}?""`,
          showCancelButton: true,
          confirmButtonText: `Sim`,
          cancelButtonText: `Não`,
          confirmButtonColor: "#f64e60",
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            if (status.id === 5) {
              this.$bvModal.show("entregaParcial");
              this.statusProd = status;
              return;
            }
            if (status.id === 2) {
              const { data } = await this.$http.post(
                "/producao/iniciar-producao",
                { id_producao: this.producaoId }
              );
              this.productionTimelineItems = this.handlePopulateTimeline(data);
              this.statusProd = status;
              this.$toast.info("Produção iniciada");
            } else {
              if (status.id === 4) this.$toast.info("Produção pausada");
              this.$http
                .post("/producao/update-status", {
                  id_producao: this.producaoId,
                  status: status.id,
                })
                .then(({ data }) => {
                  this.productionTimelineItems =
                    this.handlePopulateTimeline(data);
                  this.statusProd = status;
                });
            }
          } else {
            this.key = this.key + 1;
          }
        });
    },
    async finalizarProd() {
      if (!this.quantidadeFinalizar) {
        this.$toast.warning("Informe a quantidade para finalizar!");
        return;
      }
      this.$swal
        .fire({
          icon: "warning",
          title: "Deseja finalizar a produção??",
          showCancelButton: true,
          confirmButtonText: `Sim`,
          cancelButtonText: `Não`,
          confirmButtonColor: "#f64e60",
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            this.addLoadingToButton("finalizar_prod");
            try {
              const response = await this.$http.post(
                "/producao/finalizar-producao",
                {
                  id_producao: this.producaoId,
                  quantidade: this.quantidadeFinalizar,
                  observacao: this.observacaoFinalizar,
                  status: 6,
                }
              );
              console.log(response);
              this.productionTimelineItems.push({
                name: "Finalizada",
                label: "Finalizado",
                color: "green",
                quantidade: this.quantidadeFinalizar,
                observacao: this.observacaoFinalizar,
                date: moment().format("DD/MM/YYYY"),
                time: moment().format("h:mm"),
              });
              this.prodIniciada = false;
              this.statusProd = this.allStatus.find(
                (status) => status.id === 6
              );
              this.$router.push({ name: "producao" });
            } catch (error) {
              console.log(error);
            }
            this.$bvModal.hide("finalizarProducao");
            this.observacaoFinalizar = null;
            this.quantidadeFinalizar = null;
            this.removeLoadingToButton("finalizar_prod");
          }
        });
    },
    async salvarProducao() {
      if (!this.dataPrevisaoTermino || !this.dataPrevisao) {
        this.$toast.warning("Preencha a(s) data(s)! (Campo(s) Obrigatório(s))");
        return;
      }
      if (!this.produtoSelected) {
        this.$toast.warning("Produto obrigatório!");
        return;
      }
      this.addLoadingToButton("submit_nova_prod");
      try {
        const { data } = await this.$http.post("/producao/create", {
          id_produto: this.produtoSelected.id,
          quantidade: this.quantidade,
          data_previsao: this.dataPrevisao,
          data_previsao_termino: this.dataPrevisaoTermino,
          responsavel: this.responsavel,
          observacao: this.observacoes,
          insumos: this.insumos,
          status: 1,
        });
        this.producaoId = data.data.id_producao;
        this.numeroOP = data.data.numero;
        this.$toast.success("Ordem de produção salva com sucesso!");
      } catch (error) {
        console.log(error);
      }
      this.notSaved = false;
      this.removeLoadingToButton("submit_nova_prod");
    },
    async iniciarProd() {
      this.handleChangeStatus(this.allStatus.find((status) => status.id === 2));
    },
    async pausarProd() {
      this.handleChangeStatus(this.allStatus.find((status) => status.id === 4));
    },
    handleChangeQuantidade() {
      this.insumos = this.insumos.map((insumo) => {
        const totalInsumo =
          this.formatNumberToFloat(insumo.quantidade) *
          this.formatNumberToFloat(this.quantidade);
        return {
          ...insumo,
          qtd_total:
            this.formatNumberToFloat(insumo.quantidade) *
            this.formatNumberToFloat(this.quantidade),
          estoque_previsto:
            this.formatNumberToFloat(insumo.estoque_atual) - totalInsumo,
        };
      });
    },
    async handleUpdateParcial() {
      if (!this.estoqueParcial) {
        this.$toast.warning("Informe o estoque parcial para prosseguir");
        return;
      }
      this.addLoadingToButton("parcial_prod");
      try {
        await this.$http.post("/producao/update-parcial", {
          id_producao: this.producaoId,
          estoque_parcial: this.estoqueParcial,
          observacao: this.observacaoParcial,
          status: 5,
        });
        this.productionTimelineItems.push({
          name: "Entrega parcial",
          label: "Entrega parcial",
          color: "blue",
          quantidade: this.estoqueParcial,
          observacao: this.observacaoParcial,
          date: moment().format("DD/MM/YYYY"),
          time: moment().format("h:mm"),
        });
        this.observacaoParcial = null;
        this.estoqueParcial = null;
        this.prodIniciada = false;
        this.$bvModal.hide("entregaParcial");
      } catch (error) {
        console.log(error);
      }
      this.removeLoadingToButton("parcial_prod");
    },
    setProduto(produto) {
      if (!produto) return;
      this.quantidade = 1;
      this.insumos = produto.composicao.map((produto) => {
        const totalInsumo =
          this.formatNumberToFloat(produto.quantidade) *
          this.formatNumberToFloat(this.quantidade);
        return {
          ...produto.produto,
          quantidade: produto.quantidade,
          qtd_total: totalInsumo,
          estoque_atual: produto.produto.quantidade,
          estoque_previsto:
            this.formatNumberToFloat(produto.produto.quantidade) - totalInsumo,
        };
      });
    },
    onResize() {
      this.windowWidth = window.innerWidth;
    },

    onSearchProduto(search, loading) {
      if (search.length > 2 || /^[0-9]+$/.test(search)) {
        loading(true);
        this.searchProdutos(loading, search, this);
      }
    },
    searchProdutos: _.debounce(async (loading, search, vm) => {
      const { data } = await vm.$http.post("/produtos/get-composto", {
        filter: search,
        typeFilter: 3,
      });
      vm.produtosFetched = [...data];
      loading(false);
    }, 350),

    handleSair() {
      this.$swal
        .fire({
          icon: "warning",
          title: "Cancelar o lançamento em andamento?",
          showCancelButton: true,
          confirmButtonText: `Sim`,
          cancelButtonText: `Não`,
          confirmButtonColor: "#f64e60",
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            this.$router.push({ name: "producao" });
          }
        });
    },
  },
  computed: {
    ...mapGetters([
      "isAuthenticated",
      "breadcrumbs",
      "pageTitle",
      "layoutConfig",
      "nutsDigital",
    ]),
    ...mapState(["loading"]),
    isLoading() {
      return this.loading.loading;
    },
    loaderEnabled() {
      return !/false/.test(this.layoutConfig("loader.type"));
    },
    contentFluid() {
      return this.layoutConfig("content.width") === "fluid";
    },
    toolbarDisplay() {
      return true;
    },
    subheaderDisplay() {
      return !!this.layoutConfig("subheader.display");
    },
    displaySubheaderOnDashboard() {
      return this.$route.name !== "dashboard";
    },
  },
  created() {
    this.statusProd = this.allStatus.find((status) => status.id === 1);
    if (Object.keys(this.$route.params).length !== 0) {
      const { prodData } = this.$route.params;
      const historico = this.handlePopulateTimeline(
        JSON.parse(prodData.historico).reverse()
      );
      this.productionTimelineItems = historico;
      this.producaoId = prodData.id_producao;
      this.numeroOP = prodData.numero;
      this.observacoes = prodData.observacao;
      this.insumos = JSON.parse(prodData.insumos);
      this.responsavel = prodData.responsavel;
      this.quantidade = this.formatNumberToFloat(prodData.quantidade);
      this.statusProd = this.allStatus.find(
        (status) => status.id === prodData.status
      );
      this.dataPrevisao = prodData.data_previsao;
      this.dataPrevisaoTermino = prodData.data_previsao_termino;
      this.produtoSelected = prodData.produto;
      this.notSaved = false;
      if (this.statusProd.id === 2) this.prodIniciada = true;
      this.store.isUpdate = true;
    }
  },
};
</script>

<style>
@media (max-width: 991.98px) {
  .container {
    padding: 0;
  }

  .card-body-container {
    padding: 0rem;
  }

  .card-body {
    padding: 1rem;
  }
}

.action-buttons {
  display: flex;
}

.cardHeader {
  display: flex;
  max-width: 11rem;
  justify-content: center;
  background-color: white;
  margin-bottom: 5px;
  border-radius: 14px;
  padding: 0.5rem;
  margin-left: auto;
}

.busca-produto .vs__search {
  font-weight: 400;
  font-size: 1.45rem !important;
  margin-bottom: 0.5rem !important;
  line-height: 1.2 !important;
  text-transform: uppercase !important;
}

.busca-produto .vs__dropdown-toggle {
  border: 0px !important;
}

.busca-produto .vs__actions {
  display: none;
}

.busca-produto .vs__selected-options {
  max-width: 100%;
  flex-wrap: initial;
}

.busca-produto .vs__selected {
  text-align: left !important;
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.busca-cliente .vs__search {
  font-size: 18px;
}

.busca-cliente .vs__dropdown-toggle {
  color: var(--primary-color);
  background-color: #fff;
  border: 2px solid #dedede;
  height: 40px;
}

.busca-cliente .vs--open .vs__dropdown-toggle {
  background-color: #fff;
  border: 2px solid var(--primary-color);
}

.busca-cliente .vs__actions {
  display: none;
}

.busca-cliente .vs__dropdown-option--highlight,
.vs__dropdown-menu li:hover,
.vs__dropdown-menu li:focus {
  background-color: var(--primary-color)5e !important;
  color: var(--primary-color) !important;
}

.busca-cliente .vs__dropdown-menu {
  color: var(--primary-color) !important;
}

.busca-cliente .vs__selected-options {
  max-width: 100%;
  flex-wrap: initial;
}

.busca-cliente .vs__selected {
  text-align: left !important;
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.remove-pgto {
  font-weight: bold;
  font-size: 18px;
  color: #4f4f4f;
}

.add-pgto {
  font-weight: bold;
  font-size: 22px;
  color: var(--primary-color);
  float: right;
}

.input-quantidade {
  font-size: 1.75rem;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
  background: transparent;
  border: transparent;
  color: #fff;
  padding: 0px;
  margin-top: -10px;
  margin-bottom: 0px;
  text-align: right;
}

.input-quantidade:focus {
  box-shadow: 0 0 0 0;
  border: 0 none;
  outline: 0;
  border: 0px;
  background-color: transparent;
  color: #fff;
}

.card {
  border: 0;
}

h4 {
  color: #6d747c;
  /* font-weight: 900; */
}

.btn-finalizar-venda {
  background-color: #eee5ff !important;
  font-size: 1.25rem !important;
  text-align: center !important;
  cursor: pointer;
}

.card-total-right p,
.card-total-left p {
  color: #fff !important;
}

.card-header h5,
.card-header h3 {
  margin-bottom: 0rem;
}

.card-header .form-control {
  height: auto;
  margin-top: 0px;
}

.card-header .busca-produto {
  margin-top: 2px;
}

.card-titulo-itens .card-body-container {
  padding: 0px 0 !important;
}

.card-itens .table tr th {
  padding: 5px;
  font-size: 12px;
}

.card-itens .table tr td,
.card-total-right p,
.card-total-left p {
  font-size: 12px;
}

.tableItensCompra {
  max-height: 300px !important;
  height: calc(100vh - 395px) !important;
}

.card-total-left,
.card-total-right {
  height: 80px !important;
}

.btn.btn-secondary {
  color: var(--primary-color);
  background-color: #fff;
  border-color: #fff;
}
</style>
