import { required } from "vuelidate/lib/validators";

export default class PessoaVeiculoData {
  placa = null;

  ANTT = null;

  estado = null;

  constructor({
    placa = null,
    ANTT = null,
    estado = null,
  }) {
    this.placa = placa;
    this.ANTT = ANTT;
    this.estado = estado;
  }
}

export const PessoaVeiculoDataRequired = {
  placa: {
    required,
  },
};
