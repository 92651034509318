import linkProspect from "@/modules/LinkProspect/Index.vue";

export default [
  {
    name: "linkProspect",
    path: "/new-prospect",
    component: linkProspect,
    meta: { title: "Prospect Digital" },
  },
];
