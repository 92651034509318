import { required } from "vuelidate/lib/validators";

export default class PosData {
  descricao = "";
  serial_pos = "";
  username = "";
  password = "";
  modelo = null;
  id_configuracao_pos = null;
  status = true;

  constructor({ 
    descricao = "", 
    serial_pos = "", 
    modelo = null, 
    id_configuracao_pos = null, 
    status = true,
    username = "",
    password = "",
  }) {
    this.descricao = descricao;
    this.serial_pos = serial_pos;
    this.modelo = modelo;
    this.id_configuracao_pos = id_configuracao_pos;
    this.status = status;
    this.username = username;
    this.password = password;
  }
}

export const PosDataRequired = {
  descricao: {
    required,
  },
  serial_pos: {
    required,
  },
  username: {
    required,
  },
  password: {
    required,
  },
};
