<template>
  <div>
    <div
      class="card card-custom gutter-b"
      style="box-shadow: none"
      v-if="isNotDigital"
    >
      <div class="card-header card-header-tabs-line">
        <div class="card-body">
          <form-wizard
            @on-complete="onComplete"
            title="Nuts Digital"
            subtitle="Com a Nuts Digital sua empresa terá muitos recursos"
            nextButtonText="Avançar"
            backButtonText="Voltar"
            finishButtonText="Finalizar"
            stepSize="xs"
            color="var(--primary-color)"
            errorColor="#172c30"
          >
            <tab-content
              title="Dados da Empresa"
              :before-change="() => validateStep(1)"
            >
              <b-row>
                <b-col md="6">
                  <b-form-group
                    id="business_name"
                    label-for="business_name"
                    label="Razão Social"
                  >
                    <b-form-input
                      v-model="$v.emitente.business_name.$model"
                      :state="$v.emitente.business_name.required"
                      autofocus
                    >
                    </b-form-input>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group
                    id="statement_descriptor"
                    label-for="statement_descriptor"
                    label="Nome Fantasia"
                  >
                    <b-form-input
                      v-model="$v.emitente.statement_descriptor.$model"
                      :state="$v.emitente.statement_descriptor.required"
                    >
                    </b-form-input>
                  </b-form-group>
                </b-col>
                <b-col md="4">
                  <b-form-group id="ein" label="CPF/CNPJ" label-for="ein">
                    <b-form-input
                      id="ein"
                      name="ein"
                      v-model="$v.emitente.ein.$model"
                      v-mask="['###.###.###-##', '##.###.###/####-##']"
                      :state="$v.emitente.ein.required"
                    >
                    </b-form-input>
                  </b-form-group>
                </b-col>
                <b-col md="4">
                  <b-form-group
                    id="business_phone"
                    label="Telefone"
                    label-for="example-input-0"
                  >
                    <b-form-input
                      v-mask="['(##) #### ####', '(##) # #### ####']"
                      v-model="$v.emitente.business_phone.$model"
                      :state="$v.emitente.business_phone.required"
                    >
                    </b-form-input>
                  </b-form-group>
                </b-col>
                <b-col md="4">
                  <b-form-group
                    id="business_email"
                    label="E-mail"
                    label-for="business_email"
                  >
                    <b-form-input
                      id="business_email"
                      name="business_email"
                      v-model="$v.emitente.business_email.$model"
                      :state="$v.emitente.business_email.required"
                    >
                    </b-form-input>
                  </b-form-group>
                </b-col>
                <b-col md="4">
                  <b-form-group label="Ramo de Atividade" label-for="mcc">
                    <VueSelect
                      id="mcc"
                      ref="mcc"
                      :filterable="false"
                      @input="fillInput('atividade')"
                      :options="listaMCC"
                      v-model="emitente.mcc"
                      :class="{
                        hasErrorsAcvtivity: !atividadeFilled,
                        inputFilledAcvtivity: atividadeFilled,
                      }"
                      @search="onSearchAtividade"
                    >
                      <template slot="no-options">
                        Informe a descrição
                      </template>
                      <template slot="option" slot-scope="option">
                        <div class="d-center">
                          {{ option.description }}
                        </div>
                      </template>
                      <template slot="selected-option" slot-scope="option">
                        <div class="selected d-center">
                          {{ option.description }}
                        </div>
                      </template>
                    </VueSelect>
                  </b-form-group>
                </b-col>
                <b-col md="4">
                  <b-form-group
                    id="data-business_opening_date"
                    label-for="business_opening_date"
                    label="Data Abertura "
                  >
                    <b-form-input
                      v-mask="['##-##-####']"
                      id="business_opening_date"
                      name="business_opening_date"
                      v-model="$v.emitente.business_opening_date.$model"
                      :state="$v.emitente.business_opening_date.required"
                    >
                    </b-form-input>
                  </b-form-group>
                </b-col>
              </b-row>
            </tab-content>

            <tab-content
              title="Dados do Proprietário"
              :before-change="() => validateStep(2)"
            >
              <b-row>
                <b-col md="4">
                  <b-form-group
                    id="first_name"
                    label-for="first_name"
                    label="Nome"
                  >
                    <b-form-input
                      v-model="$v.emitente.first_name.$model"
                      :state="$v.emitente.first_name.required"
                      autofocus
                    >
                    </b-form-input>
                  </b-form-group>
                </b-col>
                <b-col md="4">
                  <b-form-group
                    id="last_name"
                    label-for="last_name"
                    label="Sobrenome"
                  >
                    <b-form-input
                      v-model="$v.emitente.last_name.$model"
                      :state="$v.emitente.last_name.required"
                    >
                    </b-form-input>
                  </b-form-group>
                </b-col>
                <b-col md="4">
                  <b-form-group
                    id="taxpayer_id"
                    label-for="taxpayer_id"
                    label="CPF"
                  >
                    <b-form-input
                      v-mask="['###.###.###-##']"
                      v-model="$v.emitente.taxpayer_id.$model"
                      :state="$v.emitente.taxpayer_id.required"
                    >
                    </b-form-input>
                  </b-form-group>
                </b-col>
                <b-col md="4">
                  <b-form-group id="email" label="E-mail" label-for="email">
                    <b-form-input
                      id="email"
                      name="email"
                      v-model="$v.emitente.email.$model"
                      :state="$v.emitente.email.required"
                    >
                    </b-form-input>
                  </b-form-group>
                </b-col>
                <b-col md="4">
                  <b-form-group
                    id="phone_number"
                    label="Telefone"
                    label-for="example-input-0"
                  >
                    <b-form-input
                      v-mask="['(##) #### ####', '(##) # #### ####']"
                      v-model="$v.emitente.phone_number.$model"
                      :state="$v.emitente.phone_number.required"
                    >
                    </b-form-input>
                  </b-form-group>
                </b-col>
                <b-col md="4">
                  <b-form-group
                    id="data-birthdate"
                    label-for="birthdate"
                    label="Data Nascimento "
                  >
                    <b-form-input
                      v-mask="['##-##-####']"
                      id="birthdate"
                      name="birthdate"
                      v-model="$v.emitente.birthdate.$model"
                      :state="$v.emitente.phone_number.required"
                    >
                    </b-form-input>
                  </b-form-group>
                </b-col>
              </b-row>
            </tab-content>
            <tab-content
              title="Endereço da Empresa"
              :before-change="() => validateStep(3)"
            >
              <b-row>
                <b-col md="3">
                  <b-form-group label="CEP" label-for="postal_code">
                    <b-form-input
                      ref="cep"
                      id="postal_code"
                      name="postal_code"
                      v-model="emitente.postal_code"
                      v-mask="['#####-###']"
                      aria-describedby="postal_code"
                      :state="emitente.postal_code !== ''"
                    >
                    </b-form-input>
                  </b-form-group>
                </b-col>
                <b-col md="2">
                  <b-form-group style="margin-top: 30px">
                    <a
                      class="btn btn-primary font-weight-bold"
                      @click="consultaCep"
                    >
                      Consultar CEP
                    </a>
                  </b-form-group>
                </b-col>
                <b-col md="5">
                  <b-form-group label="Logradouro" label-for="line1">
                    <b-form-input
                      id="line1"
                      name="line1"
                      v-model="emitente.line1"
                      :state="emitente.line1 !== ''"
                    >
                    </b-form-input>
                  </b-form-group>
                </b-col>
                <b-col md="2">
                  <b-form-group label="Número" label-for="line2">
                    <b-form-input
                      id="line2"
                      name="line2"
                      v-model="emitente.line2"
                      aria-describedby="line2"
                      :state="emitente.line2 !== ''"
                    >
                    </b-form-input>
                  </b-form-group>
                </b-col>
                <b-col md="4">
                  <b-form-group label="Bairro" label-for="neighborhood">
                    <b-form-input
                      id="neighborhood"
                      name="neighborhood"
                      v-model="emitente.neighborhood"
                      aria-describedby="neighborhood"
                      :state="emitente.neighborhood !== ''"
                    >
                    </b-form-input>
                  </b-form-group>
                </b-col>
                <b-col md="4">
                  <b-form-group label="Cidade" label-for="city">
                    <b-form-input
                      id="city"
                      name="city"
                      v-model="emitente.city"
                      aria-describedby="city"
                      :state="emitente.city !== ''"
                    >
                    </b-form-input>
                  </b-form-group>
                </b-col>
                <b-col md="4">
                  <b-form-group label="UF" label-for="state">
                    <b-form-input
                      id="state"
                      name="state"
                      v-model="emitente.state"
                      aria-describedby="state"
                      :state="emitente.state !== ''"
                    >
                    </b-form-input>
                  </b-form-group>
                </b-col>
              </b-row>
            </tab-content>
            <tab-content
              title="Dados Conta Bancária"
              :before-change="() => validateStep(4)"
            >
              <b-row>
                <b-col md="12" v-if="!bancos.length">
                  <br />
                  <br />
                  <h2 style="color: var(--primary-color); text-align: center">
                    <strong>Nenhuma conta bancária cadastrada no Nuts</strong>
                  </h2>
                </b-col>
                <b-col md="4" v-if="bancos.length">
                  <b-form-group
                    label="Selecione a conta bancária"
                    label-for="banco"
                  >
                    <select
                      name="banco"
                      id="banco"
                      v-model="emitente.banco"
                      class="form-control"
                      @input="fillInput('contaBancaria')"
                      :class="{
                        'form-control': true,
                        hasErrors: !contaBancariaFilled,
                        inputFilled: contaBancariaFilled,
                      }"
                    >
                      <option
                        v-for="(b, index) in bancos"
                        :value="b"
                        :key="'code' + index"
                      >
                        {{ b.nome }}
                      </option>
                    </select>
                  </b-form-group>
                </b-col>
              </b-row>
            </tab-content>
            <tab-content
              title="Envie o cartão CNPJ"
              :before-change="() => validateStep(5)"
            >
              <b-row>
                <b-col md="4">
                  <b-form-group
                    label="Copia cartão Cnpj"
                    label-for="cartao_cnpj"
                  >
                    <b-form-file
                      accept=".jpg, .png, .pdf"
                      placeholder="Selecione a cópia do cartão cnpj..."
                      @change="setCartao"
                    ></b-form-file>
                  </b-form-group>
                </b-col>
              </b-row>
            </tab-content>
          </form-wizard>
        </div>
      </div>
    </div>
    <h2 style="color: var(--primary-color); text-align: center">
      <strong>Nuts Digital, confira suas vantagens!</strong>
    </h2>
    <div class="card-body">
      <div class="row justify-content-center text-center my-0 my-md-25">
        <div class="col-md-4 col-xxl-3 bg-white my-md-n15 rounded shadow-sm">
          <div class="pt-25 pb-25 pb-md-10 px-4">
            <h4 class="mb-15">Recursos Gratuito</h4>
            <span
              class="
                px-7
                py-3
                bg-white
                d-inline-flex
                flex-center
                rounded-lg
                mb-15
                bg-white
              "
              >Criação</span
            >
            <br />
            <p class="mb-10 d-flex flex-column text-dark-50">
              <span
                >Crie a conta digital do seu negócio em minutos e facilite a
                organização financeira da sua empresa.</span
              >
            </p>
            <span
              class="
                px-7
                py-3
                bg-white
                d-inline-flex
                flex-center
                rounded-lg
                mb-15
                bg-white
              "
              >Conciliação</span
            >
            <br />
            <p class="mb-10 d-flex flex-column text-dark-50">
              <span
                >Mais facilidade e rapidez para a sua conciliação bancária.
                Todas as movimentações da Nust Digital são integradas
                automaticamente no sistema de contas a receber da sua
                empresa.</span
              >
            </p>
            <span
              class="
                px-7
                py-3
                bg-white
                d-inline-flex
                flex-center
                rounded-lg
                mb-15
                bg-white
              "
              >Painel Administrativo</span
            >
            <br />
            <p class="mb-10 d-flex flex-column text-dark-50">
              <span
                >Painel Gratuito onde será possível controlar suas vendas no
                cartão (Pos), boletos e transações no PIX. Relatórios em vários
                formatos, extratos para sua gestão financeira.</span
              >
            </p>
          </div>
        </div>

        <div class="col-md-4 col-xxl-3 bg-primary my-md-n15 rounded shadow-sm">
          <div class="pt-25 pt-md-37 pb-25 pb-md-10 py-md-28 px-4">
            <h4 class="text-white mb-15">Pix</h4>
            <span
              class="
                px-7
                py-3
                bg-white
                d-inline-flex
                flex-center
                rounded-lg
                mb-15
                bg-white
              "
            >
              <span class="pr-2 text-primary opacity-70">%</span>
              <span class="pr-2 font-size-h1 font-weight-bold text-primary"
                >0,99</span
              >
              <span class="text-primary opacity-70"
                >/&nbsp;&nbsp;do valor da Transação</span
              >
            </span>
            <h4 class="text-white mb-15">Boleto</h4>
            <span
              class="
                px-7
                py-3
                bg-white
                d-inline-flex
                flex-center
                rounded-lg
                mb-15
                bg-white
              "
            >
              <span class="pr-2 text-primary opacity-70">R$</span>
              <span class="pr-2 font-size-h1 font-weight-bold text-primary"
                >3,00</span
              >
              <span class="text-primary opacity-70"
                >/&nbsp;&nbsp;Somente por boleto pago</span
              >
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { validationMixin } from "vuelidate";
import {
  SistemaMixin,
  EmitenteMixin,
  ValidacaoMixin,
} from "@/modules/Application";
import { FormWizard, TabContent } from "vue-form-wizard";
import { mask } from "vue-the-mask";
import DigitalData, { DigitalDataRequired } from "./DigitalData";
import _ from "lodash";
import VueSelect from "vue-select";

export default {
  name: "app-nuts-digital",
  mixins: [validationMixin, SistemaMixin, EmitenteMixin, ValidacaoMixin],
  components: {
    FormWizard,
    TabContent,
    VueSelect,
  },
  directives: {
    mask,
  },
  data() {
    return {
      atividadeFilled: false,
      contaBancariaFilled: false,
      listaMccFirst: [],
      emitente: {},
      listaMCC: [],
      bancos: [],
      nutsDigital: null,
      isNotDigital: true,
    };
  },
  validations: {
    emitente: DigitalDataRequired,
  },
  methods: {
    ...mapActions(["setLoading"]),
    fillInput(type) {
      if (type === "atividade") {
        this.atividadeFilled = true;
      }
      if (type === "contaBancaria") {
        this.contaBancariaFilled = true;
      }
    },
    onSearchAtividade(search, loading) {
      if (search.length) {
        loading(true);
        this.searchAtividade(loading, search, this);
      }
    },
    searchAtividade: _.debounce(async (loading, search, vm) => {
      vm.listaMCC = await vm.getListaMCC(search);
      loading(false);
    }, 250),
    async getDataInit() {
      this.setLoading(true);
      const dataDigital = await this.getNutsDigital();

      if (dataDigital.ein) {
        this.nutsDigital = dataDigital;
        this.isNotDigital = false;
      }

      const data = await this.getDadosEmitente();
      this.bancos = await this.getBancosByEmpresa();
      this.setLoading(false);
      if (!data.id_empresa || data == null) {
        return this.$toast.error("Emitente não encontrado!");
      }
      const dados_empresa = {};
      dados_empresa.business_name = data.nome;
      dados_empresa.ein = data.cnpj_cpf;
      dados_empresa.business_phone = data.fone;
      dados_empresa.business_email = data.email;
      dados_empresa.statement_descriptor = data.nome_fantasia;
      dados_empresa.line1 = data.logradouro;
      dados_empresa.line2 = data.numero;
      dados_empresa.neighborhood = data.bairro;
      dados_empresa.postal_code = data.cep;
      dados_empresa.city = data.cidade.cidade;
      dados_empresa.state = data.cidade.uf;
      this.emitente = new DigitalData(dados_empresa);
    },
    validateStep(step) {
      const emitente = this.$v.emitente;
      if (step === 1) {
        if (
          emitente.business_opening_date.required &&
          emitente.business_name.required &&
          emitente.statement_descriptor.required &&
          emitente.ein.required &&
          emitente.business_phone.required &&
          emitente.business_email.required &&
          emitente.business_email.required &&
          this.emitente?.mcc
        )
          return true;
        else {
          this.$toast.error("Preencha todos os campos!");
          return false;
        }
      }
      if (step === 2) {
        if (
          emitente.first_name.required &&
          emitente.last_name.required &&
          emitente.taxpayer_id.required &&
          emitente.email.required &&
          emitente.phone_number.required &&
          emitente.birthdate.required
        )
          return true;
        else {
          this.$toast.error("Preencha todos os campos!");
          return false;
        }
      }
      if (step === 3) {
        if (
          this.emitente.postal_code !== "" &&
          this.emitente.line1 !== "" &&
          this.emitente.line2 !== "" &&
          this.emitente.neighborhood !== "" &&
          this.emitente.city !== "" &&
          this.emitente.state !== ""
        )
          return true;
        else {
          this.$toast.error("Preencha todos os campos!");
          return false;
        }
      }
      if (step === 4) {
        if (!this.emitente?.banco) {
          this.$toast.error("Preencha a conta bancária!");
          return false;
        } else return true;
      }
      if (step === 5) {
        if (!this.emitente?.cartao_cnpj) {
          this.$toast.error("Selecione o arquivo da cópia do cartão CNPJ!");
          return false;
        } else return true;
      }
    },
    handleErrorMessage() {},
    validateState(name) {
      const { $dirty, $error } = this.$v.emitente[name];
      return $dirty ? !$error : null;
    },
    onSearch(search, loading) {
      if (search.length) {
        loading(true);
        this.search(loading, search, this);
      }
    },
    async consultaCep() {
      this.setLoading(true);
      const cep = await this.getCep(this.emitente.postal_code);

      if (cep.length == 0) {
        this.setLoading(false);
        return;
      }

      this.emitente.line1 = cep.logradouro;
      this.emitente.neighborhood = cep.bairro;
      this.emitente.city = cep.localidade;
      this.emitente.state = cep.uf;

      this.setLoading(false);
    },
    async setCartao(event) {
      this.emitente.cartao_cnpj = await event.target.files[0];
    },
    async onComplete() {
      try {
        this.$v.emitente.$touch();
        if (this.$v.emitente.$anyError) {
          this.$toast.error(
            "Verifique todos os campos obrigatórios do formulário!"
          );
          return;
        }
        if (!this.validaCpfCnpj(this.emitente.ein)) {
          return this.$toast.error("CNPJ informado é inválido!");
        }
        if (!this.validaCpfCnpj(this.emitente.taxpayer_id)) {
          return this.$toast.error("CPF informado é inválido!");
        }
        if (!this.emitente.mcc) {
          return this.$toast.error("Selecione o ramo de atividade!");
        }
        if (!this.emitente.banco) {
          return this.$toast.error("Selecione a conta bancária!");
        }
        if (!this.emitente.cartao_cnpj) {
          return this.$toast.error("Falta enviar a cópia do cartão CNPJ!");
        }
        let nutsDigital = new FormData();
        if (this.emitente.cartao_cnpj != null) {
          nutsDigital.append("cartao_cnpj", this.emitente.cartao_cnpj);
        }
        nutsDigital.append("business_name", this.emitente.business_name);
        nutsDigital.append("business_phone", this.emitente.business_phone);
        nutsDigital.append("business_email", this.emitente.business_email);
        nutsDigital.append("ein", this.emitente.ein);
        nutsDigital.append(
          "business_opening_date",
          this.emitente.business_opening_date
        );
        nutsDigital.append(
          "statement_descriptor",
          this.emitente.statement_descriptor
        );
        nutsDigital.append("first_name", this.emitente.first_name);
        nutsDigital.append("last_name", this.emitente.last_name);
        nutsDigital.append("email", this.emitente.email);
        nutsDigital.append("phone_number", this.emitente.phone_number);
        nutsDigital.append("taxpayer_id", this.emitente.taxpayer_id);
        nutsDigital.append("birthdate", this.emitente.birthdate);
        nutsDigital.append("line1", this.emitente.line1);
        nutsDigital.append("line2", this.emitente.line2);
        nutsDigital.append("neighborhood", this.emitente.neighborhood);
        nutsDigital.append("state", this.emitente.state);
        nutsDigital.append("city", this.emitente.city);
        nutsDigital.append("postal_code", this.emitente.postal_code);
        nutsDigital.append("id_banco", this.emitente.banco.id_banco);
        nutsDigital.append("mcc", this.emitente.mcc.code);
        nutsDigital.append("country_code", "BR");
        this.setLoading(true);
        await this.$http.post("bancos/create-digital", nutsDigital);
        this.setLoading(false);
        this.$toast.success("Nuts Digital criada com sucesso!");
        await this.getDataInit();
      } catch (error) {
        this.setLoading(false);
        console.log(error);
      }
    },
  },
  computed: {
    ...mapGetters(["layoutConfig", "itsblocked"]),
    config() {
      return this.layoutConfig();
    },
  },
  async mounted() {
    if (this.itsblocked) {
      this.$router.push({ name: "assinatura" });
    }
    await this.getDataInit();
  },
};
</script>

<style>
.vs__dropdown-toggle {
  border: 2px solid #dedede;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border-radius: 6px;
  outline: none;
  height: 38px;
  color: black;
}

.hasErrorsAcvtivity .vs__dropdown-toggle {
  border: 2px solid red;
  /* color: #394066; */
}

.vs__dropdown-menu {
  color: black !important;
}

.hasErrorsAcvtivity .vs__clear,
.hasErrorsAcvtivity .vs__open-indicator {
  fill: red;
}

.inputFilledAcvtivity .vs__dropdown-toggle {
  border: 2px solid #1bc5bd;
  /* color: #394066; */
}

.inputFilledAcvtivity .vs__clear,
.inputFilledAcvtivity .vs__open-indicator {
  fill: #1bc5bd;
}

.inputFilled {
  border: 2px solid #1bc5bd !important;
}
</style>