<template>
  <b-tab title="Veículos" :active="isTheCurrentTab" :disabled="!isTheCurrentTab" class="mt-5">
    <b-row>
      <b-col>
        <b-form-group id="veiculo1" label-for="veiculo1" label="Caminhão">
          <VueSelect ref="buscarVeiculo1" :filterable="false" :options="veiculos1" v-model="form.caminhao"
            :class="{ hasErrors: veiculo1Error }" @search="(search, loading) => onSearchVeiculos(search, loading, 1)"
            @input="() => veiculo1Error = false">
            <template slot="no-options">
              Pesquise pela placa
            </template>
            <template slot="option" slot-scope="option">
              <div class="d-center">
                {{ option.placa }} - {{ option.descricao }}
              </div>
            </template>
            <template slot="selected-option" slot-scope="option">
              <div class="selected d-center">
                {{ option.placa }} - {{ option.descricao }}
              </div>
            </template>
          </VueSelect>
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group id="veiculo2" label-for="veiculo2" label="1 Reboque">
          <VueSelect ref="buscarVeiculo1" :filterable="false" :options="veiculos2" v-model="form.reboque1"
            @search="(search, loading) => onSearchVeiculos(search, loading, 2)">
            <template slot="no-options">
              Pesquise pela placa
            </template>
            <template slot="option" slot-scope="option">
              <div class="d-center">
                {{ option.placa }} - {{ option.descricao }}
              </div>
            </template>
            <template slot="selected-option" slot-scope="option">
              <div class="selected d-center">
                {{ option.placa }} - {{ option.descricao }}
              </div>
            </template>
          </VueSelect>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-form-group id="veiculo3" label-for="veiculo3" label="2 Reboque">
          <VueSelect ref="buscarVeiculo1" :filterable="false" :options="veiculos3" v-model="form.reboque2"
            @search="(search, loading) => onSearchVeiculos(search, loading, 3)">
            <template slot="no-options">
              Pesquise pela placa
            </template>
            <template slot="option" slot-scope="option">
              <div class="d-center">
                {{ option.placa }} - {{ option.descricao }}
              </div>
            </template>
            <template slot="selected-option" slot-scope="option">
              <div class="selected d-center">
                {{ option.placa }} - {{ option.descricao }}
              </div>
            </template>
          </VueSelect>
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group id="veiculo4" label-for="veiculo4" label="3 Reboque">
          <VueSelect ref="buscarVeiculo1" :filterable="false" :options="veiculos4" v-model="form.reboque3"
            @search="(search, loading) => onSearchVeiculos(search, loading, 4)">
            <template slot="no-options">
              Pesquise pela placa
            </template>
            <template slot="option" slot-scope="option">
              <div class="d-center">
                {{ option.placa }} - {{ option.descricao }}
              </div>
            </template>
            <template slot="selected-option" slot-scope="option">
              <div class="selected d-center">
                {{ option.placa }} - {{ option.descricao }}
              </div>
            </template>
          </VueSelect>
        </b-form-group>
      </b-col>
    </b-row>
  </b-tab>
</template>

<script>
import { mapGetters, mapState, mapActions } from "vuex";
import { validationMixin } from "vuelidate";
import { SistemaMixin } from "@/modules/Application";
import { store } from "../../storeLogistica";
import VueSelect from "vue-select";
import _ from "lodash";

export default {
  name: "app-mdfe-form-veiculosTab",
  components: {
    VueSelect
  },
  directives: {
  },
  data() {
    return {
      store,
      form: {
        caminhao: null,
        reboque1: null,
        reboque2: null,
        reboque3: null,
      },
      veiculo1Error: false,
      veiculos1: [],
      veiculos2: [],
      veiculos3: [],
      veiculos4: [],
    }
  },
  mixins: [
    validationMixin,
    SistemaMixin,
  ],
  methods: {
    ...mapActions(["setLoading"]),
    async getDataInit() {
      const mdfeData = this.$route.params?.mdfeData?.mdfe;
      if(mdfeData){
        this.form.caminhao = mdfeData.veiculo;
        mdfeData.veiculosReboques.forEach((veiculo, index) => this.form[`reboque${index + 1}`] = veiculo);
      }
    },
    onSearchVeiculos(search, loading, index) {
      if (search.length) {
        loading(true);
        this.searchVeiculos(loading, search, this, index);
      }
    },
    searchVeiculos: _.debounce(async (loading, placa, vm, index) => {
      const { data } = await vm.$http.post('/veiculos/get-veiculo', { placa });
      vm[`veiculos${index}`] = data;
      if (loading) loading(false);
    }, 350),
    validateState(field) {
      return !!this.form[field];
    }
  },
  created() {
    this.getDataInit();
  },
  computed: {
    ...mapGetters([
      "isAuthenticated",
      "breadcrumbs",
      "pageTitle",
      "layoutConfig"
    ]),
    ...mapState(["loading"]),
    isLoading() {
      return this.loading.loading;
    },
    isTheCurrentTab() {
      return store.currentTab === 1
    }
  },
  watch: {
    "store.currentTab"(newValue, oldValue) {
      if (newValue !== oldValue && newValue === 2 && oldValue < newValue) {
        if (!this.form.caminhao) {
          this.$toast.error('Caminhão obrigatorio!')
          this.veiculo1Error = true;
          this.store.currentTab = oldValue;
          return;
        }
      }
      const veiculosReboques = []
      for (let i = 0; i < 3; i++) {
        if (this.form[`reboque${i + 1}`]) veiculosReboques.push(this.form[`reboque${i + 1}`])
      }
      this.store.formData = {
        ...this.store.formData,
        veiculo: this.form.caminhao,
        veiculosReboques,
      }
    },
  }
}

</script>

<style></style>