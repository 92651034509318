<template>
  <div>
    <div class="card card-custom gutter-b" style="box-shadow: none">
      <div class="card-header card-header-tabs-line">
        <ul
          class="nav nav-dark nav-bold nav-tabs nav-tabs-line"
          role="tablist"
          ref="builder-tab"
        >
          <li class="nav-item">
            <a
              class="nav-link active"
              data-tab="0"
              data-toggle="tab"
              href="#"
              role="tab"
            >
              Cadastro
            </a>
          </li>
        </ul>
      </div>

      <b-form class="form" @submit.stop.prevent="onSubmit">
        <div class="card-body">
          <b-tabs class="hide-tabs" v-model="tabIndex">
            <b-tab active>
              <b-row>
                <b-col md="6">
                  <b-form-group
                    id="descricao"
                    label-for="descricao"
                    label="Descrição"
                  >
                    <b-form-input
                      v-model="$v.form.descricao.$model"
                      :state="validateState('descricao')"
                      autofocus
                    >
                    </b-form-input>
                  </b-form-group>
                </b-col>
                <b-col md="2">
                  <b-form-group id="tipo" label-for="tipo" label="Tipo">
                    <b-form-select
                      @input="setTipo"
                      v-model="$v.form.tipo.$model"
                      placeholder=""
                      :state="validateState('tipo')"
                    >
                      <b-form-select-option value="E"
                        >Receita</b-form-select-option
                      >
                      <b-form-select-option value="S"
                        >Despesa</b-form-select-option
                      >
                    </b-form-select>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group
                    id="id_plano_contas"
                    label-for="id_plano_contas"
                    label="Plano de Contas"
                  >
                    <VueSelect
                      label="id_plano_contas"
                      :filterable="false"
                      @input="setPlano"
                      v-model="plano"
                      :options="plano_contas"
                      @search="onSearchPlano"
                    >
                      <template slot="no-options">
                        Informe a descrição
                      </template>
                      <template slot="option" slot-scope="option">
                        <div class="d-center">
                          {{ option.descricao }}
                        </div>
                      </template>
                      <template slot="selected-option" slot-scope="option">
                        <div class="selected d-center">
                          {{ option.descricao }}
                        </div>
                      </template>
                    </VueSelect>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col md="4">
                  <b-form-group id="data" label-for="data" label="Data">
                    <b-form-datepicker
                      id="data-lancamento"
                      placeholder=" "
                      locale="pt-br"
                      v-model="form.data"
                    >
                    </b-form-datepicker>
                  </b-form-group>
                </b-col>
                <b-col md="4">
                  <b-form-group
                    label="Meio de pagamento"
                    label-for="form.meio_pagamento"
                  >
                    <VueSelect
                      label="id_pessoa"
                      :filterable="false"
                      v-model="meioPagamento"
                      :options="meiosDePagamento"
                      @search="onSearchMeioPagamento"
                    >
                      <template slot="no-options">
                        Informe o meio de pagamento
                      </template>
                      <template slot="option" slot-scope="option">
                        <div class="d-center">
                          {{ option.descricao }}
                        </div>
                      </template>
                      <template slot="selected-option" slot-scope="option">
                        <div class="selected d-center">
                          {{ option.descricao }}
                        </div>
                      </template>
                    </VueSelect>
                  </b-form-group>
                </b-col>
                <b-col md="3">
                  <b-form-group id="valor" label-for="valor" label="Valor R$">
                    <money
                      class="form-control"
                      v-model="form.valor"
                      v-bind="configMoney"
                    >
                    </money>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-tab>
          </b-tabs>
        </div>

        <div class="card-footer">
          <div class="row">
            <div class="col-lg-12" style="text-align: center">
              <button
                type="submit"
                ref="submit_financeiro_novo"
                class="btn btn-primary font-weight-bold"
              >
                Salvar
              </button>
              <router-link
                class="btn btn-clean font-weight-bold"
                :to="{
                  name: 'financeiroLista',
                }"
              >
                Cancelar
              </router-link>
            </div>
          </div>
        </div>
      </b-form>
    </div>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { SistemaMixin } from "@/modules/Application";
import FinanceiroData, { FinanceiroDataRequired } from "./FinanceiroData";
import { Money } from "v-money";
import { MONEY as settingMoney } from "@/modules/Application";
import { mapGetters } from "vuex";
import loadsh from "loadsh";
import VueSelect from "vue-select";

export default {
  name: "app-financeiro-form",
  props: {
    url: {
      type: String,
    },
    financeiro: {
      type: Object,
      default: () => {},
    },
  },
  components: {
    Money,
    VueSelect,
  },
  mixins: [validationMixin, SistemaMixin],
  watch: {
    financeiro(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.form = newValue;
        this.form.valor = this.numberToReal(newValue.valor, 2);
        this.setPlano(newValue.plano_contas);
        if (newValue.meio_pagamento) {
          this.meioPagamento = newValue.meio_pagamento;
        }
      }
    },
  },
  data() {
    return {
      tabIndex: 0,
      configMoney: settingMoney,
      form: {},
      plano_contas: [],
      plano: null,
      plano_tipo: null,
      meiosDePagamento: [],
      meiosPagamentoFetched: [],
    };
  },
  validations: {
    form: FinanceiroDataRequired,
  },
  computed: {
    ...mapGetters(["itsblocked"]),
  },
  mounted() {
    if (this.itsblocked) {
      this.$router.push({ name: "assinatura" });
    }
    this.getDataInit();
  },
  methods: {
    async getDataInit() {
      this.form = new FinanceiroData({});
      const response = await this.$http.post("/financeiro/meios-pagamento");
      this.meiosDePagamento = response.data;
      this.meiosPagamentoFetched = this.meiosDePagamento;
      if (this.financeiro.id) {
        this.form = this.financeiro;
        this.meioPagamento = this.financeiro.meio_pagamento;
        this.form.tipo = this.financeiro.tipo;
        this.setTipo(this.financeiro.tipo);
        this.setPlano(this.financeiro.plano_contas);
        this.plano_contas = await this.getPlanoContasFinanceiro(
          this.plano_tipo
        );
      }
    },
    setTipo(tipo) {
      if (tipo === "S") {
        this.plano_tipo = 1;
      }

      if (tipo === "E") {
        this.plano_tipo = 2;
      }
    },
    setPlano(plano) {
      this.plano = plano;
    },
    onSearchPlano(search, loading) {
      if (search.length) {
        loading(true);
        this.searchPlano(loading, search, this);
      }
    },
    searchPlano: loadsh.debounce(async (loading, search, vm) => {
      vm.plano_contas = await vm.getPlanoContasFinanceiro(
        vm.plano_tipo,
        search
      );
      loading(false);
    }, 450),
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    onSearchMeioPagamento(search) {
      this.meiosDePagamento = this.meiosPagamentoFetched.filter((pagamento) =>
        pagamento.descricao.toLowerCase().includes(search.toLowerCase())
      );
    },
    async onSubmit() {
      this.addLoadingToButton("submit_financeiro_novo");
      try {
        this.$v.form.$touch();

        if (this.$v.form.$anyError) {
          this.$toast.error(
            "Verifique todos os campos obrigatórios do formulário!"
          );
          return;
        }

        this.form.id_plano_contas = this.plano?.id ?? null;
        this.form.id_meio_pagamento = this.meioPagamento?.id ?? null;

        await this.$http.post(this.url, this.form);

        this.$router.push({ name: "financeiroLista" });
      } catch (error) {
        console.log(error);
      }
      this.removeLoadingToButton("submit_financeiro_novo");
    },
  },
};
</script>

<style>
.vs__dropdown-toggle {
  border: 2px solid #dedede;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border-radius: 6px;
  outline: none;
  height: 38px;
}
</style>
