<template>
  <div class="container-bottons">
    <slot />
  </div>
</template>

<script>
export default {
  name: "ContainerButtons"
};
</script>

<style scoped></style>
