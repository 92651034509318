<template>
  <label class="checkbox-container radio">
    <input
      type="radio"
      @change="updateValue"
      :checked="isChecked"
      :disabled="disabled"
    />
    <span class="container-align" :class="disabled ? 'disabled' : ''">
      <span class="checkmark" :class="isChecked ? 'checked' : ''">
        <span class="check"></span>
      </span>
      <span class="text">{{ label }}</span>
    </span>
  </label>
</template>

<script>
export default {
  name: "Radio",
  props: {
    label: {},
    value: {
      required: true
    },
    disabled: {
      type: Boolean,
      default: () => false
    },
    option: {
      required: true
    }
  },
  computed: {
    isChecked() {
      return this.option === this.value;
    }
  },
  methods: {
    updateValue() {
      this.$emit("input", this.option);
    }
  }
};
</script>
