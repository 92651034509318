import Login from "@/modules/Login/pages/Login";

export default [
  {
    path: "/login",
    component: Login,
    name: "login",
    meta: { title: "Login" },
  },
];
