<template>
  <div>
    <div class="card card-custom gutter-b" style="box-shadow: none">
      <div class="card-header card-header-tabs-line">
        <ul
          class="nav nav-dark nav-bold nav-tabs nav-tabs-line"
          role="tablist"
          ref="builder-tab"
        >
          <li class="nav-item">
            <a
              class="nav-link active"
              data-tab="0"
              data-toggle="tab"
              href="#"
              role="tab"
            >
              Cadastro
            </a>
          </li>
        </ul>
      </div>

      <b-form class="form" @submit.stop.prevent="onSubmit">
        <div class="card-body">
          <b-tabs class="hide-tabs" v-model="tabIndex">
            <b-tab active>
              <b-row>
                <b-col md="4">
                  <b-row>
                    <b-col>
                      <Quest :size="16" />
                      <b-form-group
                        id="nome_destinatario"
                        label-for="nome_destinatario"
                        label="Nome do destinatário"
                        v-b-tooltip="
                          'Nome do cliente para quem voçe está vendendo'
                        "
                      >
                        <b-form-input
                          autofocus
                          maxlength="50"
                          v-model="$v.form.nome_destinatario.$model"
                          :state="validateState('nome_destinatario')"
                        >
                        </b-form-input>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-col>

                <b-col md="2">
                  <b-row>
                    <b-col>
                      <Quest :size="16" />
                      <b-form-group
                        id="valor"
                        label-for="valor"
                        label="R$ Valor do link"
                        v-b-tooltip="
                          'A quantia deve ser entre R$ 5,00 e R$ 20.000,00'
                        "
                      >
                        <money
                          class="form-control"
                          v-bind="configMoney"
                          v-model="$v.form.valor.$model"
                          :state="validateState('valor')"
                        >
                        </money>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-col>

                <b-col md="4">
                  <b-col>
                    <Quest :size="16" />
                    <b-form-group
                      id="descricao"
                      label-for="descricao"
                      label="Descrição da venda"
                      v-b-tooltip="
                        'Será apresentado na fatura do cartão do cliente'
                      "
                    >
                      <b-form-input
                        maxlength="100"
                        v-model="$v.form.descricao.$model"
                        :state="validateState('descricao')"
                      >
                      </b-form-input>
                    </b-form-group>
                  </b-col>
                </b-col>
              </b-row>
              <b-row>
                <b-col md="3">
                  <b-form-group
                    id="vezes"
                    label-for="vezes"
                    label="Quantidade de parcelas"
                  >
                    <select
                      name="vezes"
                      id="vezes"
                      v-model="form.vezes"
                      class="form-control"
                    >
                      <option
                        v-for="(p, index) in parcelas"
                        :value="p.id"
                        :key="'parcela' + index"
                      >
                        {{ p.descricao }}
                      </option>
                    </select>
                  </b-form-group>
                </b-col>
                <b-col md="3">
                  <b-col>
                    <b-form-group
                      id="data_expiracao"
                      label-for="data_expiracao"
                      label="Data Expiração link"
                    >
                      <b-form-input
                        type="date"
                        v-model="$v.form.data_expiracao.$model"
                        :state="validateState('data_expiracao')"
                      >
                      </b-form-input>
                    </b-form-group>
                  </b-col>
                </b-col>
                <b-col md="3">
                  <b-col>
                    <b-form-group
                      id="data_expiracao"
                      label-for="data_expiracao"
                      label="Hora Expiração link"
                    >
                      <b-form-input
                        type="time"
                        v-model="$v.form.hora.$model"
                        :state="validateState('hora')"
                      >
                      </b-form-input>
                    </b-form-group>
                  </b-col>
                </b-col>
              </b-row>
            </b-tab>
          </b-tabs>
        </div>

        <div class="card-footer">
          <div class="row">
            <div class="col-lg-12" style="text-align: center">
              <button
                type="submit"
                ref="submit_nova_grupo"
                class="btn btn-primary font-weight-bold"
              >
                Salvar
              </button>
              <router-link
                class="btn btn-clean font-weight-bold"
                :to="{
                  name: 'linksLista',
                }"
              >
                Cancelar
              </router-link>
            </div>
          </div>
        </div>
      </b-form>
    </div>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import LinkData, { LinkDataRequired } from "./LinkData";
import { mapGetters } from "vuex";
import { Money } from "v-money";
import { MONEY as settingMoney } from "@/modules/Application";
import Quest from "vue-material-design-icons/AccountQuestion.vue";

export default {
  name: "app-link-form",
  props: {
    url: {
      type: String,
    },
    link: {
      type: Object,
      default: () => {},
    },
  },
  mixins: [validationMixin],
  data() {
    return {
      tabIndex: 0,
      form: {},
      configMoney: settingMoney,
      parcelas: [
        {
          id: 1,
          descricao: "À vista",
        },
        {
          id: 2,
          descricao: "Até 2x sem juros",
        },
        {
          id: 3,
          descricao: "Até 3x sem juros",
        },
        {
          id: 4,
          descricao: "Até 4x sem juros",
        },
        {
          id: 5,
          descricao: "Até 5x sem juros",
        },
        {
          id: 6,
          descricao: "Até 6x sem juros",
        },
        {
          id: 7,
          descricao: "Até 7x sem juros",
        },
        {
          id: 8,
          descricao: "Até 8x sem juros",
        },
        {
          id: 9,
          descricao: "Até 9x sem juros",
        },
        {
          id: 10,
          descricao: "Até 10x sem juros",
        },
        {
          id: 11,
          descricao: "Até 11x sem juros",
        },
        {
          id: 12,
          descricao: "Até 12x sem juros",
        },
      ],
    };
  },
  validations: {
    form: LinkDataRequired,
  },
  watch: {
    link(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.form = newValue;
      }
    },
  },
  components: {
    Money,
    Quest,
  },
  computed: {
    ...mapGetters(["itsblocked"]),
  },
  mounted() {
    if (this.itsblocked) {
      this.$router.push({ name: "assinatura" });
    }
    this.getDataInit();
  },
  methods: {
    async getDataInit() {
      this.form = await new LinkData({});
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    async onSubmit() {
      const submitButton = this.$refs["submit_nova_grupo"];

      try {
        this.$v.form.$touch();

        if (this.$v.form.$anyError) {
          this.$toast.error(
            "Verifique todos os campos obrigatórios do formulário!"
          );
          return;
        }

        if (!this.form.vezes) {
          this.$toast.warning("Selecione a quantidade de parcelas!");
          return;
        }

        submitButton.classList.add("spinner", "spinner-light", "spinner-right");
        submitButton.setAttribute("disabled", "");

        this.form.data_expiracao =
          this.form.data_expiracao + " " + this.form.hora;

        await this.$http.post(this.url, this.form);

        this.$router.push({ name: "linksLista" });
      } catch (error) {
        submitButton.classList.remove(
          "spinner",
          "spinner-light",
          "spinner-right"
        );
        submitButton.removeAttribute("disabled", "");
      }
    },
  },
};
</script>

<style>
.vs__dropdown-toggle {
  border: 2px solid #dedede;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border-radius: 6px;
  outline: none;
  height: 38px;
}
</style>
