<template>
  <div class="card card-custom card-stretch gutter-b">
    <div class="card-header border-0 py-5">
      <h3 class="card-title align-items-start flex-column">
        <span class="card-label font-weight-bolder text-dark">
          {{ currentUser.nome }}
        </span>
      </h3>
    </div>
    <div>
      <div class="card card-custom gutter-b" style="box-shadow: none">
        <ul class="nav nav-dark nav-bold" role="tablist" ref="builder-tab">
          <li class="nav-item">
            <a class="nav-link active" data-tab="0" data-toggle="tab" href="#" role="tab">
              Email: {{ currentUser.usuario }}
            </a>
          </li>
        </ul>
        <b-form class="form" @submit.stop.prevent="salvarNovaSenha">
          <div class="card-body">
            <b-row class="justify-content-md-center">
              <b-col md="4">
                <b-form-group id="password" label-for="password" label="Nova senha">
                  <b-form-input v-model="password" autofocus type="password">
                  </b-form-input>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row class="justify-content-md-center">
              <b-col md="4">
                <b-form-group id="confirmPassword" label-for="confirmPassword" label="Confirme a senha">
                  <b-form-input v-model="confirmPassword" type="password">
                  </b-form-input>
                </b-form-group>
              </b-col>
            </b-row>
          </div>
          <div class="card-footer">
            <div class="row">
              <div class="col-lg-12" style="text-align: center">
                <button type="submit" ref="submit_nova_senha" class="btn btn-primary font-weight-bold">
                  Salvar
                </button>
                <router-link class="btn btn-clean font-weight-bold" :to="{
                  name: 'dashboard',
                }">
                  Cancelar
                </router-link>
              </div>
            </div>
          </div>
        </b-form>
      </div>
    </div>
    <!-- <Form url="/usuario/create" :usuario="usuario" /> -->
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { SistemaMixin } from "@/modules/Application";

export default {
  name: "app-usuarios",
  mixins: [SistemaMixin],
  components: {
  },
  data() {
    return {
      tabIndex: 0,
      password: '',
      confirmPassword: '',
    };
  },
  methods: {
    async salvarNovaSenha() {
      const submitButton = this.$refs["submit_nova_senha"];

      submitButton.classList.add("spinner", "spinner-light", "spinner-right");
      submitButton.setAttribute("disabled", "");

      if (this.password !== '' && this.confirmPassword !== '' && this.password === this.confirmPassword) {
        try {
          await this.$http.post('/usuario/update-perfil', {
            id_usuario: this.currentUser.id_usuario,
            password: this.password,
            nome: this.currentUser.nome
          })
          this.$toast.success('Senha atualizada com sucesso!')
        } catch (error) {
          this.$toast.error(JSON.stringify(error))
        }
      } else {
        this.$toast.error('Erro ao salvar senha nova! Verique se as duas são iguais!')
      };
      submitButton.classList.remove(
        "spinner",
        "spinner-light",
        "spinner-right"
      );
      submitButton.removeAttribute("disabled", "");
    },
  },
  computed: {
    ...mapGetters(["layoutConfig", "itsblocked", "currentUser"]),
    ...mapActions(["setLoading"]),
    config() {
      return this.layoutConfig();
    },
  },
  mounted() {
    if (this.itsblocked) {
      this.$router.push({ name: "assinatura" });
    }
  },
};
</script>
