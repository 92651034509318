<template>
  <div>
    <div class="card card-custom card-stretch gutter-b">
      <div class="card-header border-0 py-5">
        <h3 class="card-title align-items-start flex-column">
          <span class="card-label font-weight-bolder text-dark">
            Plataformas
          </span>
        </h3>
      </div>
      <div class="card-body">
        <b-row>
          <b-col style="max-width: 600px">
            <div class="border rounded p-5">
              <div
                style="
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                  gap: 20px;
                "
              >
                <a
                  href="https://www.vendizap.com"
                  target="_blank"
                  v-b-tooltip.hover.top="'Visitar Vendizap'"
                >
                  <img
                    alt="vendizap"
                    class="avatar-img"
                    src="https://www.vendizap.com/assets/img/logo.png"
                    width="300"
                  />
                </a>
                <button v-b-toggle.vendizap>
                  <Plus class="btn btn-secondary btn-hover-info btn-icon" />
                </button>
              </div>
              <b-collapse
                id="vendizap"
                accordion="clienteAcordion"
                role="tabpanel"
              >
                <b-card>
                  <b-row>
                    <b-col>
                      <b-form-group label="Cliente ID">
                        <b-form-input
                          v-model="vendizapClientId"
                          :disabled="vendizapIntegrated"
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col>
                      <b-form-group label="Cliente Secret">
                        <b-form-input
                          v-model="vendizapClientSecret"
                          :disabled="vendizapIntegrated"
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col>
                      <button
                        @click="integrarVendizap"
                        ref="integrarVendizap"
                        class="btn btn-primary font-weight-bold mt-5"
                        :disabled="vendizapIntegrated"
                      >
                        Integrar
                      </button>
                    </b-col>
                    <b-col
                      v-if="vendizapIntegrated"
                      style="
                        display: flex;
                        align-items: end;
                        justify-content: end;
                        padding-right: 2rem;
                      "
                    >
                      <div class="">
                        <p
                          class="mb-0 text-success font-weight-bold"
                          style="font-size: 1.5rem"
                        >
                          Integrado!
                        </p>
                      </div>
                    </b-col>
                  </b-row>
                </b-card>
              </b-collapse>
            </div>
          </b-col>
        </b-row>
        <div class="border-bottom border-light py-3">
          <b-col md="3"> </b-col>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import { mapGetters } from "vuex";
import { SistemaMixin } from "@/modules/Application";
import Plus from "vue-material-design-icons/PlusThick.vue";

export default {
  name: "app-integracoes",
  data() {
    return {
      vendizapClientId: null,
      vendizapClientSecret: null,
      vendizapIntegrated: false,
    };
  },
  components: {
    Plus,
  },
  mixins: [SistemaMixin],
  computed: {
    ...mapGetters(["itsblocked"]),
  },
  created() {
    this.getDataInit();
  },
  methods: {
    async integrarVendizap() {
      this.addLoadingToButton("integrarVendizap");
      try {
        await this.$http.post("/integracao/create", {
          tipo: 1,
          client_id: this.vendizapClientId,
          client_secret: this.vendizapClientSecret,
        });
        this.$toast.success("Cadastrado com sucesso!");
        this.vendizapClientId = null;
        this.vendizapClientSecret = null;
        this.vendizapIntegrated = true;
      } catch (error) {
        console.log(error);
      }
      this.removeLoadingToButton("integrarVendizap");
    },
    async getDataInit() {
      try {
        const response = await this.$http.post("/integracao/get", { tipo: 1 });
        if (response?.data?.id_integracao) this.vendizapIntegrated = true;
      } catch (error) {
        console.log(error);
      }
    },
  },
  mounted() {},
  watch: {},
};
</script>