<template>
  <div class="card card-custom card-stretch gutter-b">
    <div class="card-header border-0 py-5">
      <h3 class="card-title align-items-start flex-column">
        <span class="card-label font-weight-bolder text-dark">
          Editar Vendedor
        </span>
      </h3>
    </div>
    <Form url="/vendedor/update" :vendedor="vendedor" />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Form from "./Form.vue";
import VendedorData from "./VendedorData";

export default {
  name: "app-vendedor-update",
  data() {
    return {
      vendedor: {},
    };
  },
  components: {
    Form,
  },
  methods: {
    ...mapActions(["setLoading"]),
    async getVendedor(id) {
      const { data } = await this.$http.post("/vendedor/find", {
        id_vendedor: id,
      });
      return data;
    },
    async getInit() {
      try {
        const id = this.$route.params.id;
        this.setLoading(true);

        const vendedor = await this.getVendedor(id);
        this.setLoading(false);

        if (!vendedor.id_vendedor || vendedor == null) {
          this.$router.push({ name: "vendedorLista" });

          return this.$toast.error("Vendedor não encontrado!");
        }

        this.vendedor = vendedor;
      } catch (e) {
        return;
      }
    },
  },
  computed: {
    ...mapGetters(["itsblocked"]),
  },
  async mounted() {
    if (this.itsblocked) {
      this.$router.push({ name: "assinatura" });
    }
    await this.getInit();
  },
};
</script>
