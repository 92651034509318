import { required } from "vuelidate/lib/validators";

export default class ClienteData {
  nome = null;
  cnpj_cpf = null;
  solicitante = null;
  constructor(
    nome = null,
    cnpj_cpf = null,
    solicitante = null,
  ) {
    this.nome = nome;
    this.cnpj_cpf = cnpj_cpf;
    this.solicitante = solicitante;
  }
}

export const ClienteDataRequired = {
  nome: {
    required,
  },
};
