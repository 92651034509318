export default {
  state: {
    loading: false
  },
  mutations: {
    loading(state, status) {
      state.loading = status;
    }
  },
  actions: {
    setLoading(context, status) {
      context.commit("loading", status);
    }
  }
};
