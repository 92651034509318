<template>
  <div class="card card-custom card-stretch gutter-b">
    <div class="card-header border-0 py-5">
      <h3 class="card-title align-items-start flex-column">
        <span class="card-label font-weight-bolder text-dark">
          Editar Técnico
        </span>
      </h3>
    </div>
    <Form url="/tecnico/update" :tecnico="tecnico" />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Form from "./Form.vue";
import TecnicoData from "./TecnicoData";

export default {
  name: "app-tecnico-update",
  data() {
    return {
      tecnico: new TecnicoData({}),
    };
  },
  components: {
    Form,
  },
  methods: {
    ...mapActions(["setLoading"]),
    async getTecnico(id_tecnico) {
      try {
        const { data } = await this.$http.post("/tecnico/find", {
          id_tecnico: id_tecnico,
        });
        return data;
      } catch (error) {
        return null;
      }
    },
    async getInit() {
      try {
        const codigo = this.$route.params.id_tecnico;

        this.setLoading(true);

        const tecnico = await this.getTecnico(codigo);

        this.setLoading(false);

        if (!tecnico.id_tecnico || tecnico == null) {
          this.$router.push({ name: "tecnicosLista" });
          return this.$toast.error("Técnico não encontrado!");
        }

        this.tecnico = new TecnicoData(tecnico);
      } catch {
        return;
      }
    },
  },
  computed: {
    ...mapGetters(["itsblocked"]),
  },
  mounted() {
    if (this.itsblocked) {
      this.$router.push({ name: "assinatura" });
    }
    this.getInit();
  },
};
</script>
