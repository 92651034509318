<template>
  <div class="card card-custom card-stretch gutter-b">
    <div class="card-header border-0 py-5">
      <h3 class="card-title align-items-start flex-column">
        <span class="card-label font-weight-bolder text-dark">
          Novo Produto
        </span>
      </h3>
    </div>
    <Form url="/produtos/create" :produto="produto" />
  </div>
</template>

<script>
import Form from "./Form.vue";
import ProdutoData from "./ProdutosData";
import { mapGetters } from "vuex";
import { onboardStore } from "../../../onboardStore";
import {
  SistemaMixin,
} from "@/modules/Application";

export default {
  name: "app-produtos-create",
  data() {
    return {
      onboardStore,
      produto: new ProdutoData({}),
    };
  },
  components: {
    Form,
  },
  mixins: [SistemaMixin],
  computed: {
    ...mapGetters(["itsblocked"]),
  },
  mounted() {
    if (this.itsblocked) {
      this.$router.push({ name: "assinatura" });
    }
    if(this.onboardStore.currentStep === 6){
      this.fireOnboardToast({
        id: 'produtos',
        title: '4/6 - Cadastro de produtos',
        content: 'Neste passo vamos cadastrar novos produtos para poder fazer suas movimentações.',
        buttonText: 'Ok',
        fowardStep: () => this.onboardStore.currentStep = 5
      })
    }
  },
};
</script>
