<template>
  <div style="position: relative">
    <FilterFields
      :fields="[
        {
          type: 'text',
          label: 'Número',
          field: 'numero',
        },
        {
          type: 'text',
          label: 'Cliente',
          field: 'cliente',
        },
        {
          type: 'text',
          label: 'Técnico',
          field: 'tecnico',
        },
        {
          type: 'text',
          label: 'Objeto',
          field: 'objeto',
        },
        {
          type: 'date',
          label: 'Dt. Entrada',
          field: 'dataEntrada',
        },
        {
          type: 'date',
          label: 'Dt. Saída',
          field: 'dataSaida',
        },
        {
          type: 'text',
          label: 'Identificador',
          field: 'identificador',
        },
        {
          type: 'text',
          label: 'Solicitante',
          field: 'solicitante',
        },
        {
          type: 'select',
          label: 'Status',
          field: 'status',
          options: [
            'Todos',
            'Em aberto',
            'Orçada',
            'Autorizada',
            'Cancelada',
            'Exportada (NFe/NFSe)',
            'Não autorizada',
            'Análise do cliente',
            'Em andamento',
            'Finalizada',
            'Aguardando Componentes',
            'Serviço Concluído',
            'Finalizada sem concerto',
          ],
        },
      ]"
    />

    <b-dropdown
      id="menu"
      variant="dark"
      v-b-tooltip.right="'Mais ações'"
      class="mb-5"
      style="position: absolute; top: 0; left: 50px"
      v-if="actionsStore.selectedRows.length > 0"
    >
      <template #button-content>
        <Menu :size="18" />
      </template>
      <b-dropdown-item
        v-b-modal.duplicarOS
        :disabled="actionsStore.selectedRows.length > 1"
      >
        Duplicar OS
      </b-dropdown-item>
      <b-dropdown-item 
      v-b-modal.atualizarOS>
        Atualizar status
      </b-dropdown-item>
      <b-dropdown-item
        v-b-modal.comprovanteEntrega
        :disabled="actionsStore.selectedRows.length > 1"
      >
        Comprovante de entrega
      </b-dropdown-item>
      <b-dropdown-item
        v-b-modal.modalEtiqueta
        :disabled="actionsStore.selectedRows.length > 1"
      >
        Etiqueta
      </b-dropdown-item>
      <b-dropdown-item
        v-if="!store.pafNFCe && actionsStore.selectedRows[0]?.status != 5"
        v-b-modal.faturarOS
        :disabled="
          actionsStore.selectedRows.length > 1 ||
          actionsStore.selectedRows[0]?.status == 14
        "
      >
        Faturar OS
      </b-dropdown-item>
    </b-dropdown>

    <b-modal
      id="comprovanteEntrega"
      title="Deseja imprimir o comprovante de entrega do Objeto?"
      centered
      size="xs"
    >
      <label
        ><p>
          OS a ser impressa número: {{ actionsStore.selectedRows[0]?.numero }}
        </p>
      </label>
      <template #modal-footer="{ ok, cancel }">
        <div>
          <b-button
            variant="primary"
            @click="imprimirComprovante"
            ref="comprovanteEntrega"
            class="mr-5"
          >
            Confirmar
          </b-button>
          <b-button
            @click="
              {
                actionsStore.updateTable = !actionsStore.updateTable;
                actionsStore.selectedRows = [];
                cancel();
              }
            "
          >
            Cancelar
          </b-button>
        </div>
      </template>
    </b-modal>

    <b-modal id="faturarOS" title="Deseja faturar a OS?" centered size="xs">
      <label>
        <p>
          <strong
            >OS a ser faturada número:
            {{ actionsStore.selectedRows[0]?.numero }}
          </strong>
        </p>
        <p v-if="actionsStore.selectedRows[0]?.pagamentos">
          Forma Pagamento:
          {{ formataPagamento(actionsStore.selectedRows[0]?.pagamentos) }}
        </p>
      </label>
      <b-row>
        <b-col md="6">
          <p class="font-weight-bolder text-dark mb-2">Movimentar estoque</p>
          <span class="switch switch-icon">
            <label v-b-tooltip.hover.right="'Não/Sim'">
              <input type="checkbox" v-model="movimentar_estoque" />
              <span></span>
            </label>
          </span>
        </b-col>
        <b-col md="6">
          <p class="font-weight-bolder text-dark mb-2">Gerar financeiro</p>
          <span class="switch switch-icon">
            <label v-b-tooltip.hover.right="'Não/Sim'">
              <input type="checkbox" v-model="gerar_financeiro" />
              <span></span>
            </label>
          </span>
        </b-col>
        <b-col md="12" style="margin-top: 10px;">
          <b-form-group
            id="data_faturamento"
            label-for="data_faturamento"
            label="Data do Faturamento"
          >
            <b-form-input v-model="data_faturamento" type="date" required/>
          </b-form-group>
        </b-col>
      </b-row>
      <template #modal-footer="{ ok, cancel }">
        <div>
          <b-button
            variant="primary"
            @click="faturarOrdemServico"
            ref="faturarOrdemServico"
            class="mr-5"
            :disabled="permitido_faturar"
          >
            Confirmar
          </b-button>
          <b-button
            @click="
              {
                actionsStore.updateTable = !actionsStore.updateTable;
                actionsStore.selectedRows = [];
                cancel();
              }
            "
          >
            Cancelar
          </b-button>
        </div>
      </template>
    </b-modal>

    <b-modal
      id="modalEtiqueta"
      title="Deseja imprimir a etiqueta referente a OS?"
      centered
      size="xs"
    >
      <label
        ><p>
          OS a ser impressa número: {{ actionsStore.selectedRows[0]?.numero }}
        </p>
      </label>
      <b-col md="12">
        <b-form-group label="Informe a quantidade de etiquetas">
          <b-form-input
            type="number"
            min="1"
            max="10"
            v-model="repetirEtiqueta"
          >
          </b-form-input>
        </b-form-group>
      </b-col>
      <template #modal-footer="{ ok, cancel }">
        <div>
          <b-button
            variant="primary"
            @click="imprimirEtiqueta"
            ref="imprimirEtiqueta"
            class="mr-5"
          >
            Confirmar
          </b-button>
          <b-button
            @click="
              {
                actionsStore.updateTable = !actionsStore.updateTable;
                actionsStore.selectedRows = [];
                cancel();
              }
            "
          >
            Cancelar
          </b-button>
        </div>
      </template>
    </b-modal>

    <b-modal
      id="duplicarOS"
      title="Deseja duplicar a OS selecionada?"
      centered
      size="xs"
    >
      <label
        >OS a ser duplicada
        <p>Número: {{ actionsStore.selectedRows[0]?.numero }}</p>
      </label>
      <template #modal-footer="{ ok, cancel }">
        <div>
          <b-button
            variant="primary"
            @click="duplicar"
            ref="doubleOS"
            class="mr-5"
          >
            Confirmar
          </b-button>
          <b-button
            @click="
              {
                actionsStore.updateTable = !actionsStore.updateTable;
                actionsStore.selectedRows = [];
                cancel();
              }
            "
          >
            Cancelar
          </b-button>
        </div>
      </template>
    </b-modal>

    <b-modal
      id="atualizarOS"
      title="Deseja atualizar o status da(s) OS selecionada(s)?"
      centered
      size="xs"
    >
      <label
        >OS(s) para mudar o status
        <p>
          Número(s):
          {{ actionsStore.selectedRows.map((os) => os.numero).join(",") }}
        </p>
      </label>
      <b-form-group label="Selecione o status" label-for="status">
        <VueSelect
          ref="buscarStatus"
          :filterable="false"
          :options="osStatus"
          v-model="statusSelected"
        >
          <template slot="no-options"> Informe o status </template>
          <template slot="option" slot-scope="option">
            <div class="d-center">
              {{ option.descricao }}
            </div>
          </template>
          <template slot="selected-option" slot-scope="option">
            <div class="selected d-center">
              {{ option.descricao }}
            </div>
          </template>
        </VueSelect>
      </b-form-group>
      <template #modal-footer="{ ok, cancel }">
        <div>
          <b-button
            variant="primary"
            @click="atualizarStatus"
            ref="updateOS"
            class="mr-5"
          >
            Confirmar
          </b-button>
          <b-button
            @click="
              {
                actionsStore.updateTable = !actionsStore.updateTable;
                actionsStore.selectedRows = [];
                cancel();
              }
            "
          >
            Cancelar
          </b-button>
        </div>
      </template>
    </b-modal>

    <TableApp
      titulo="Ordem de Serviço"
      acaoBtn="/#/ordensServicos-nova"
      textoBtn="Nova OS"
      api-url="/ordemservico/lista"
      :fields="fields"
      classFilterColumn="numero"
      ref="osList"
      :perPage="10"
      :multiselect="true"
    >
      <template slot="customRow" scope="props">
        <span slot="numero" class="pl-0 pt-8" v-if="props.field == 'numero'">
          <div class="d-flex align-items-center">
            <div class="font-weight-bold">
              {{ props.rowData.numero }}
            </div>
          </div>
        </span>
        <span slot="cliente" class="pl-0 pt-8" v-if="props.field == 'cliente'">
          <div class="d-flex align-items-center">
            <div class="font-weight-bold">
              {{ props.rowData.cliente }}
            </div>
          </div>
        </span>
        <span slot="objeto" class="pl-0 pt-8" v-if="props.field == 'objeto'">
          <div class="d-flex align-items-center">
            <div class="font-weight-bold">
              {{ props.rowData.objeto.descricao }}
            </div>
          </div>
        </span>
        <span
          slot="identificadores"
          class="pl-0 pt-8"
          v-if="props.field == 'identificadores'"
        >
          <div class="d-flex align-items-center">
            <div class="font-weight-bold">
              {{ formataIdentificadores(props.rowData.identificadores) }}
            </div>
          </div>
        </span>
        <span slot="tecnico" class="pl-0 pt-8" v-if="props.field == 'tecnico'">
          <div class="d-flex align-items-center">
            <div
              class="font-weight-bold"
              v-if="props.rowData.tecnico"
            >
              {{ props.rowData.tecnico.nome }}
            </div>
            <div class="font-weight-bold" v-else></div>
          </div>
        </span>
        <span
          slot="dataEntrada"
          class="pl-0 pt-8"
          v-if="props.field == 'dataEntrada'"
        >
          <div class="d-flex align-items-center">
            <div class="font-weight-bold">
              {{ props.rowData.dataEntrada | moment("DD/MM/YYYY") }}
            </div>
          </div>
        </span>
        <span
          slot="dataPrevisao"
          class="pl-0 pt-8"
          v-if="props.field == 'dataPrevisao'"
        >
          <div class="d-flex align-items-center">
            <div class="font-weight-bold">
              {{ props.rowData.dataPrevisao | moment("DD/MM/YYYY") }}
            </div>
          </div>
        </span>
        <span
          slot="dataSaida"
          class="pl-0 pt-8"
          v-if="props.field == 'dataSaida'"
        >
          <div class="d-flex align-items-center">
            <div class="font-weight-bold">
              {{ props.rowData.dataSaida | moment("DD/MM/YYYY") }}
            </div>
          </div>
        </span>
        <span
          slot="status"
          v-if="props.field == 'status'"
          :class="statusOSClass(props.rowData.status)"
        >
          {{ statusOSLabel(props.rowData.status) }}
        </span>
        <span
          slot="valorTotal"
          v-if="props.field == 'valorTotal'"
          class="text-dark-75 font-weight-bolder d-block font-size-lg"
        >
          R$
          {{ numberToReal(props.rowData.valorTotal, 2) }}
        </span>
        <span
          slot="actions"
          v-if="props.field == 'actions'"
          class="text-dark-75 font-weight-bolder d-block font-size-lg"
        >
          <div class="action-buttons">
            <button
              @click="imprimirOS(props.rowData)"
              v-b-tooltip.bottom="'Impressão da OS'"
              class="btn btn-sm btn-info btn-hover-info btn-icon mr-2"
            >
              <PdfIcon :size="22" />
            </button>
            <button
              @click="imprimirOSTecnico(props.rowData)"
              v-b-tooltip.bottom="'Imprimir OS para técnico'"
              class="btn btn-sm btn-warning btn-icon mr-2"
            >
              <PdfIcon :size="22" />
            </button>
            <button
              @click="editarOS(props.rowData)"
              class="btn btn-sm btn-info btn-hover-info btn-icon mr-2"
              v-b-tooltip.bottom="'Editar OS'"
              v-if="
                props.rowData.status !== 3 &&
                props.rowData.status !== 11 &&
                props.rowData.status !== 12 &&
                props.rowData.status !== 14 &&
                props.rowData.status !== 5
              "
            >
              <PencilIcon :size="20" />
            </button>
            <button
              @click="doCopy(props.rowData, props.rowData.id_ordem_servico)"
              class="btn btn-sm btn-success btn-hover-success btn-icon mr-2"
              v-b-tooltip.hover.bottom="'Copiar Link'"
              placement="bottom"
            >
              <Link :size="16" />
            </button>
            <button
              @click="deleteOS(props.rowData)"
              v-if="props.rowData.status === 1"
              v-b-tooltip="'Deletar OS'"
              class="btn btn-sm btn-danger btn-hover-danger btn-icon mr-2"
            >
              <Close :size="20" />
            </button>
            <button
              @click="shareToWhatsapp(props.rowData)"
              v-b-tooltip.hover.bottom="'Encaminhar para o whatsapp'"
              class="btn btn-sm btn-success btn-hover-success btn-icon mr-2"
            >
              <Whatsapp :size="20" />
            </button>
            <button
              @click="linkOSFaturada(props.rowData)"
              class="btn btn-sm btn-info btn-hover-info btn-icon mr-2"
              v-b-tooltip.bottom="'OS Faturada'"
              v-if="props.rowData.status == 14"
            >
              <Money :size="20" />
            </button>
          </div>
        </span>
      </template>
    </TableApp>

    <SendToWppModal
      :data="dataToWhatsapp"
      :openModal="wppOpenModal"
      @handleOpenModal="(openModal) => (wppOpenModal = openModal)"
      module="os"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import TableApp from "@/components/Table/Table2";
import swal from "sweetalert";
import { SistemaMixin } from "@/modules/Application";
import flatPickr from "flatpickr";
import "flatpickr/dist/flatpickr.css";
import Close from "vue-material-design-icons/FileCancel.vue";
import Whatsapp from "vue-material-design-icons/Whatsapp.vue";
import PdfIcon from "vue-material-design-icons/FilePdfBox.vue";
import PencilIcon from "vue-material-design-icons/Pencil.vue";
import Money from "vue-material-design-icons/CashCheck.vue";
import "flatpickr/dist/themes/material_blue.css";
import { Portuguese } from "flatpickr/dist/l10n/pt.js";
import FilterFields from "@/components/FilterFields";
import SendToWppModal from "@/components/SendToWppModal";
import Menu from "vue-material-design-icons/Menu";
import { actionsStore } from "@/components/Table/actionsStore";
import Link from "vue-material-design-icons/LinkPlus.vue";
import VueSelect from "vue-select";
import { store } from "../storeOS";


export default {
  name: "os",
  mixins: [SistemaMixin],
  components: {
    TableApp,
    Close,
    VueSelect,
    PdfIcon,
    PencilIcon,
    Whatsapp,
    FilterFields,
    Link,
    SendToWppModal,
    Menu,
    Money,
  },
  data() {
    return {
      store,
      actionsStore,
      components: {
        flatPickr,
      },
      wppNumberToSend: null,
      wppOpenModal: false,
      isLoading: false,
      userEmailsToSend: [],
      selectedEmail: null,
      nfeIdToSendEmail: null,
      ccEmail: null,
      dataToWhatsapp: null,
      osStatus: [],
      statusSelected: null,
      fields: [
        {
          label: "OS",
          field: "numero",
          type: "text",
          slot: true,
          sortable: false,
          width: "2%",
        },
        {
          label: "Cliente",
          field: "cliente",
          type: "text",
          slot: true,
          sortable: false,
          width: "15%",
        },
        {
          label: "Objeto",
          field: "objeto",
          type: "text",
          slot: true,
          width: "10%",
          sortable: false,
        },
        {
          label: "Identificadores",
          field: "identificadores",
          type: "text",
          slot: true,
          width: "25%",
          sortable: false,
        },
        {
          label: "Técnico",
          field: "tecnico",
          type: "text",
          slot: true,
          sortable: false,
          width: "10%",
        },
        {
          label: "Entrada",
          field: "dataEntrada",
          sortable: false,
          slot: true,
          type: "date",
          dateInputFormat: "yyyy-MM-dd HH:mm:ss",
          dateOutputFormat: "dd-MM-yyyy",
        },
        {
          label: "Previsão",
          field: "dataPrevisao",
          sortable: false,
          slot: true,
          type: "date",
          dateInputFormat: "yyyy-MM-dd HH:mm:ss",
          dateOutputFormat: "dd-MM-yyyy",
        },
        {
          label: "Saida",
          field: "dataSaida",
          sortable: false,
          slot: true,
          type: "date",
          dateInputFormat: "yyyy-MM-dd HH:mm:ss",
          dateOutputFormat: "dd-MM-yyyy",
        },
        {
          label: "Status",
          width: "20%",
          field: "status",
          sortable: true,
          slot: true,
        },
        {
          label: "Total",
          field: "valorTotal",
          sortable: false,
          slot: true,
          width: "10%",
        },
        {
          label: "Ações",
          field: "actions",
          sortable: false,
          slot: true,
          width: "30%",
        },
      ],
      numeroNota: null,
      nomeCliente: null,
      correcaoText: null,
      pdfCorrecaoUrl: null,
      xmlCorrecaoUrl: null,
      nfeId: null,
      form: {},
      serie: null,
      xmlFiles: [],
      repetirEtiqueta: 1,
      gerar_financeiro: true,
      movimentar_estoque: true,
      permitido_faturar: false,
      data_faturamento: null,
    };
  },
  methods: {
    async getDataInit() {
      const { data } = await this.$http.post("/ordemservico/status");
      this.osStatus = data;
    },
    async faturarOrdemServico() {
      if (!this.data_faturamento) {
        this.$toast.wa("Informe a data do faturamento!");
        return;
      }
      const { id_ordem_servico } = this.actionsStore?.selectedRows[0];

      this.addLoadingToButton("faturarOrdemServico");

      try {
        const response = await this.$http.post("/ordemservico/faturar", {
          id_ordem_servico,
          movimentar_estoque: this.movimentar_estoque,
          gerar_financeiro: this.gerar_financeiro,
          data_faturamento: this.data_faturamento,
        });

        if (response["data"]["url_os_faturada"]) {
          window.open(response["data"]["url_os_faturada"], "_blank");
        }

        this.actionsStore.updateTable = !this.actionsStore.updateTable;
        this.$toast.success("OS faturada com sucesso!");
        this.$bvModal.hide("faturarOS");
        this.data_faturamento = null;
        this.movimentar_estoque = true;
        this.permitido_faturar = null;
      } catch (error) {
        console.log(error);
      }
      this.removeLoadingToButton("faturarOrdemServico");
    },
    async imprimirEtiqueta() {
      const { id_ordem_servico } = this.actionsStore?.selectedRows[0];

      this.addLoadingToButton("imprimirEtiqueta");

      try {
        const response = await this.$http.post(
          "/ordemservico/impressao-etiqueta",
          {
            id_ordem_servico,
            repetir: this.repetirEtiqueta,
          }
        );
        if (response["data"]["url_etiqueta"]) {
          window.open(response["data"]["url_etiqueta"], "_blank");
        }
        this.actionsStore.updateTable = !this.actionsStore.updateTable;
        this.$toast.success("Etiqueta impressa com sucesso!");
        this.$bvModal.hide("imprimirEtiqueta");
      } catch (error) {
        console.log(error);
      }
      this.removeLoadingToButton("imprimirEtiqueta");
    },
    async imprimirComprovante() {
      const { id_ordem_servico } = this.actionsStore?.selectedRows[0];

      this.addLoadingToButton("comprovanteEntrega");

      try {
        const response = await this.$http.post(
          "/ordemservico/impressao-comprovante",
          {
            id_ordem_servico,
          }
        );
        if (response["data"]["url_comprovante_entrega"]) {
          window.open(response["data"]["url_comprovante_entrega"], "_blank");
        }
        this.actionsStore.updateTable = !this.actionsStore.updateTable;
        this.$toast.success("Comprovante de entrega impresso com sucesso!");
        this.$bvModal.hide("comprovanteEntrega");
      } catch (error) {
        console.log(error);
      }
      this.removeLoadingToButton("comprovanteEntrega");
    },
    async duplicar() {
      const { id_ordem_servico } = this.actionsStore?.selectedRows[0];

      this.addLoadingToButton("doubleOS");

      try {
        await this.$http.post("/ordemservico/duplicar", {
          id_ordem_servico,
        });
        this.actionsStore.updateTable = !this.actionsStore.updateTable;
        this.$toast.success("OS duplicada com sucesso!");
        this.$refs.osList.refresh();
        this.$bvModal.hide("duplicarOS");
      } catch (error) {
        console.log(error);
      }
      this.removeLoadingToButton("doubleOS");
    },
    async atualizarStatus() {
      const idsOS = this.actionsStore?.selectedRows.map((os) => ({
        id_ordem_servico: os.id_ordem_servico,
      }));
      if (!this.statusSelected) {
        this.$toast.error("Selecione o status");
        return;
      }
      this.addLoadingToButton("updateOS");
      try {
        await this.$http.post("/ordemservico/update-status", {
          ordem_servicos: idsOS,
          status: this.statusSelected.id,
        });
        this.$bvModal.hide("atualizarOS");
        this.$toast.success("OS(s) atualizada(s) com sucesso!");
        this.statusSelected = null;
        this.removeLoadingToButton("updateOS");
        this.$refs.osList.refresh();
        this.actionsStore.updateTable = !this.actionsStore.updateTable;
      } catch (error) {
        this.removeLoadingToButton("updateOS");
        console.log(error);
      }
    },
    linkOSFaturada(data) {
      swal({
        title: "Deseja visualizar a OS faturada?",
        text: "Ao confirmar será aberto em nova aba o PDF",
        icon: "info",
        buttons: {
          cancel: {
            text: "Cancelar",
            value: "cancel",
            visible: true,
            closeModal: true,
          },
          confirm: {
            text: "OK",
            visible: true,
            closeModal: false,
          },
        },
        dangerMode: false,
        closeModal: false,
        closeOnClickOutside: false,
      }).then(async (value) => {
        if (value === "cancel") {
          swal.close();
          swal.stopLoading();
          return;
        }
        swal.close();
        swal.stopLoading();
        this.$copyText(data.link);
        if (data.link_faturamento) {
          window.open(data.link_faturamento, "_blank");
        }
        this.$toast.success("PDF gerado com sucesso!");
      });
    },
    doCopy(data) {
      swal({
        title: "Deseja enviar o link para o cliente?",
        text: "Ao confirmar será copiado para a area de transferencia o link",
        icon: "info",
        buttons: {
          cancel: {
            text: "Cancelar",
            value: "cancel",
            visible: true,
            closeModal: true,
          },
          confirm: {
            text: "OK",
            visible: true,
            closeModal: false,
          },
        },
        dangerMode: false,
        closeModal: false,
        closeOnClickOutside: false,
      }).then(async (value) => {
        if (value === "cancel") {
          swal.close();
          swal.stopLoading();
          return;
        }
        swal.close();
        swal.stopLoading();
        this.$copyText(data.link);
        this.$toast.success("Link copiado com sucesso!");
        this.$http.post("/ordemservico/update-status-os", {
          id_ordem_servico: data.id_ordem_servico,
        });
        this.$refs.osList.refresh();
      });
    },
    async shareToWhatsapp(data) {
      this.dataToWhatsapp = data;
      this.wppOpenModal = true;
    },
    imprimirOS(data) {
      swal({
        title: "Deseja gerar o pdf da OS?",
        text: "Aguarde, pois será gerado o pdf automaticamente!",
        icon: "info",
        buttons: {
          cancel: {
            text: "Cancelar",
            value: "cancel",
            visible: true,
            closeModal: true,
          },
          confirm: {
            text: "OK",
            visible: true,
            closeModal: false,
          },
        },
        dangerMode: false,
        closeModal: false,
        closeOnClickOutside: false,
      }).then(async (value) => {
        if (value === "cancel") {
          swal.close();
          swal.stopLoading();
          return;
        }
        await this.$http
          .post("/ordemservico/impressao", {
            id_ordem_servico: data.id_ordem_servico,
          })
          .then((result) => {
            if (result.data) {
              window.open(result.data.url_os, "_blank");
            }
            swal.stopLoading();
            swal.close();
          })
          .catch(() => {
            swal.stopLoading();
            swal.close();
          });
      });
    },
    imprimirOSTecnico(data) {
      swal({
        title: "Deseja gerar a impressão da OS para o técnico?",
        text: "Aguarde, pois será gerado o pdf automaticamente!",
        icon: "info",
        buttons: {
          cancel: {
            text: "Cancelar",
            value: "cancel",
            visible: true,
            closeModal: true,
          },
          confirm: {
            text: "OK",
            visible: true,
            closeModal: false,
          },
        },
        dangerMode: false,
        closeModal: false,
        closeOnClickOutside: false,
      }).then(async (value) => {
        if (value === "cancel") {
          swal.close();
          swal.stopLoading();
          return;
        }
        await this.$http
          .post("/ordemservico/impressao-tecnico", {
            id_ordem_servico: data.id_ordem_servico,
          })
          .then((result) => {
            if (result.data) {
              window.open(result.data.url_os, "_blank");
            }
            swal.stopLoading();
            swal.close();
          })
          .catch(() => {
            swal.stopLoading();
            swal.close();
          });
      });
    },
    deleteOS(data) {
      this.getRequestAdmin(() => {
        swal({
          title: "Deseja deletar a OS?",
          icon: "info",
          buttons: {
            cancel: {
              text: "Cancelar",
              value: "cancel",
              visible: true,
              closeModal: true,
            },
            confirm: {
              text: "OK",
              visible: true,
              closeModal: false,
            },
          },
          dangerMode: false,
          closeModal: false,
          closeOnClickOutside: false,
        }).then(async (value) => {
          if (value === "cancel") {
            swal.close();
            swal.stopLoading();
            return;
          }

          await this.$http
            .post("/ordemservico/delete", {
              id_ordem_servico: data.id_ordem_servico,
            })
            .then(() => {
              swal.stopLoading();
              swal.close();
              this.$toast.success("OS cancelada com sucesso!");
              this.$refs.osList.refresh();
              this.actionsStore.updateTable = !this.actionsStore.updateTable;
            })
            .catch(() => {
              swal.stopLoading();
              swal.close();
            });
        });
      });
    },
    async editarOS({ id_ordem_servico }) {
      swal({
        title: "Deseja editar a OS?",
        text: "Você ira ser direcionado para a pagina de edição aguarde...",
        icon: "info",
        buttons: {
          cancel: {
            text: "Cancelar",
            value: "cancel",
            visible: true,
            closeModal: true,
          },
          confirm: {
            text: "OK",
            visible: true,
            closeModal: false,
          },
        },
        dangerMode: false,
        closeModal: false,
        closeOnClickOutside: false,
      }).then(async (value) => {
        if (value === "cancel") {
          swal.close();
          swal.stopLoading();
          return;
        }

        await this.$http
          .post("/ordemservico/find", {
            id_ordem_servico,
          })
          .then(({ data }) => {
            swal.stopLoading();
            swal.close();
            this.$toast.info("Você será redirecionado agora");
            this.$router.push({
              name: "ordensServicosNova",
              params: { osData: { ...data, id_ordem_servico } },
            });
          })
          .catch(() => {
            swal.stopLoading();
            this.$toast.error("Erro ao solicitar o endpoint da NFe");
            swal.close();
          });
      });
    },
    formataIdentificadores(identificadores) {
      const identificador = JSON.parse(identificadores);
      var identificadorFormatado = "";
      identificador.forEach((identifi) => {
        var valorIdentificador = "";
        if (identifi.value) {
          valorIdentificador = identifi.value;
        }
        identificadorFormatado +=
          identifi.descricao + ": " + valorIdentificador + ", ";
      });
      return identificadorFormatado;
    },
    formataPagamento(pagamento) {
      const pagamentos = JSON.parse(pagamento);
      var pagamentoFormatado = "";
      pagamentos.forEach((pag) => {
        if (pag.valor == 0) {
          pagamentoFormatado =
            "Ordem de Serviço sem valores para realizar faturamento.";
          this.permitido_faturar = true;
        }

        if (pag.valor != 0) {
          this.permitido_faturar = false;
          pagamentoFormatado +=
            pag.forma_pagamento.descricao +
            ": R$ " +
            this.numberToReal(pag.valor, 2);
        }
      });
      return pagamentoFormatado;
    },
  },
  computed: {
    ...mapGetters(["layoutConfig", "itsblocked"]),
    config() {
      return this.layoutConfig();
    },
  },
  created() {
    this.getDataInit();
  },
  async mounted() {
    if (this.itsblocked) {
      this.$router.push({ name: "assinatura" });
    }
    let inputs = ['input[placeholder="Data"]'];
    inputs.forEach(function (input) {
      flatPickr(input, {
        dateFormat: "Y-m-d",
        allowInput: true,
        locale: Portuguese,
      });
    });
  },
};
</script>

<style>
.flatpickr-months .flatpickr-month,
.flatpickr-current-month .flatpickr-monthDropdown-months,
.flatpickr-weekdays,
span.flatpickr-weekday {
  background: var(--primary-color) !important;
}

.headerColumn {
  text-align: start;
}

.flatpickr-calendar.arrowTop:before {
  border-bottom-color: var(--primary-color) !important;
  color: var(--primary-color) !important;
}
</style>
