<template>
  <div class="card card-custom card-stretch gutter-b">
    <div class="card-header border-0 py-5">
      <h3 class="card-title align-items-start flex-column">
        <span class="card-label font-weight-bolder text-dark">
          Novo serviço
        </span>
      </h3>
    </div>
    <div class="card card-custom gutter-b" style="box-shadow: none">
      <div class="card-body">
        <b-tabs class="hide-tabs" v-model="tabIndex">
          <b-tab active>
            <b-row>
              <b-col md="7">
                <b-form-group id="codServico" label-for="codServico" label="Código serviço">
                  <VueSelect label="codServico" :class="{ notFilled: servicoError }" @input="setCodServico" :options="codServicoOptions" :filterable="false"
                    :value="codServicoSelected" placeholder="Digite pela descrição" @search="onSearchCodServico">
                    <template slot="no-options">
                      Informe a descrição do serviço
                    </template>
                    <template slot="option" slot-scope="option">
                      <div class="d-center">
                        {{ `${option.codigo} - ${option.descricao}` }}
                      </div>
                    </template>
                    <template slot="selected-option" slot-scope="option">
                      <div class="selected d-center">
                        {{ `${option.codigo} - ${option.descricao}` }}
                      </div>
                    </template>
                  </VueSelect>
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group id="tributacao" label-for="tributacao" label="Tributação">
                  <VueSelect label="tributacao" :class="{ notFilled: tributacaoError }" @input="setTributacao" :options="tributacaoOptions" :filterable="false"
                    :value="tributacaoSelected" placeholder="Digite pela descrição" @search="onSearchTributacao">
                    <template slot="no-options">
                      Informe a descrição da tributação
                    </template>
                    <template slot="option" slot-scope="option">
                      <div class="d-center">
                        {{ `${option.descricao}` }}
                      </div>
                    </template>
                    <template slot="selected-option" slot-scope="option">
                      <div class="selected d-center">
                        {{ `${option.descricao}` }}
                      </div>
                    </template>
                  </VueSelect>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-form-group id="discriminacao" label-for="discriminacao" label="Descrição">
                  <b-form-input v-model="form.discriminacao" :state="discriminacaoFilled" autofocus />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-form-group id="codigoTributacaoMunicipio" label-for="codigoTributacaoMunicipio"
                  label="Cód. Tributação município">
                  <b-form-input v-model="form.codigoTributacaoMunicipio"  autofocus />
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group id="art" label-for="art" label="Art">
                  <b-form-input v-model="form.art" autofocus />
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group id="codigo_obra" label-for="codigo_obra" label="Cód. Obra">
                  <b-form-input v-model="form.codigo_obra" autofocus />
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group id="cei" label-for="cei" label="CEI">
                  <b-form-input v-model="form.cei" autofocus />
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group id="valor" label-for="valor" label="Valor R$">
                  <money class="form-control" v-bind="configMask"  id="valor" :style="valorFilled === false ? {border: '2px solid red'}: {}"  name="valor" v-model="form.valor" />
                </b-form-group>
              </b-col>
            </b-row>
          </b-tab>
        </b-tabs>
      </div>

      <div class="card-footer">
        <div class="row">
          <div class="col-lg-12" style="text-align: center">
            <button type="submit" ref="submit_novo_servico" @click="onSubmit" class="btn btn-primary font-weight-bold">
              Salvar
            </button>
            <router-link class="btn btn-clean font-weight-bold" :to="{
              name: 'servicos',
            }">
              Cancelar
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import VueSelect from "vue-select";
import _ from "lodash";
import { Money } from "v-money";
import { MONEY as settingMoney } from "@/modules/Application";
import { mapActions } from "vuex";
import ServicoData from '../ServicosData';

import {
  SistemaMixin,
} from "@/modules/Application";

export default {
  name: "app-servico-create",
  components: {
    VueSelect,
    // TableApp,
    // TrashCanIcon,
    // PlusIcon,
    Money,
  },
  data() {
    return {
      form: {},
      codServicoSelected: null,
      configMask: settingMoney,
      tabIndex: 0,
      render: 0,
      servicoError: false,
      tributacaoError: false,
      discriminacaoFilled: null,
      valorFilled: null,
      codServicoOptions: [],
      tributacaoOptions: [],
      tributacaoSelected: null,
      tributacaoTipos: [],
      tributacao: null,
      exigibilidade: null,
      exigibilidadeTipos: [],
      regras: [],
      municipioSelected: null,
      municipioToAdd: {
        aliquota_iss: null,
        retencao_iss: null,
      },
    };
  },

  mixins: [SistemaMixin],
  computed: {
    ...mapGetters(["itsblocked"]),
    valorError() {
      return this.form.valor === 0;
    },
  },
  methods: {
    ...mapActions(["setLoading"]),
    setActiveTab(event) {
      const tab = event.target.closest('[role="tablist"]');
      const links = tab.querySelectorAll(".nav-link");

      for (let i = 0; i < links.length; i++) {
        links[i].classList.remove("active");
      }

      this.tabIndex = parseInt(event.target.getAttribute("data-tab"));

      event.target.classList.add("active");
    },
    setCodServico(value) {
      this.form.codigo = value.codigo;
      this.codServicoSelected = value;
      this.servicoError = false;
    },
    setTributacao(value) {
      this.tributacaoSelected = value;
      this.form.id_tributacao_servico = value.id_tributacao_servico;
      this.tributacaoError = false;
    },
    addMunicipioISS() {
      this.regras.push({
        cidade: this.municipioSelected.cidade + ' - ' + this.municipioSelected.uf,
        id_cidade: this.municipioSelected.id_cidade,
        aliquota_iss: this.municipioToAdd.aliquota_iss,
        retencao_iss: this.municipioToAdd.retencao_iss,
      })
      this.municipioSelected = null;
      this.municipioToAdd.aliquota_iss = null;
      this.municipioToAdd.retencao_iss = null;
      this.$bvModal.hide('modal-iss-cidade')
    },
    handleDeleteIss(data) {
      this.regras = this.regras.filter(regra => regra.id_cidade !== data.id_cidade);
    },
    onSearchTributacao(search, loading) {
      if (search.length > 2) {
        loading(true);
        this.searchTributacao(loading, search, this);
      }
    },
    searchTributacao: _.debounce(async (loading, search, vm) => {
      const response = await vm.$http.post('/tributacaoservico/tributacao-by-description', {
        descricao: search,
      });
      vm.tributacaoOptions.splice(0, vm.tributacaoOptions.length, ...response.data);
      loading(false);
    }, 500),
    onSearchCodServico(search, loading) {
      if (search.length > 2) {
        loading(true);
        this.searchCodServico(loading, search, this);
      }
    },
    searchCodServico: _.debounce(async (loading, search, vm) => {
      const response = await vm.$http.post('/tributacaoservico/get-codigo-servico', {
        search,
      });
      vm.codServicoOptions.splice(0, vm.codServicoOptions.length, ...response.data);
      loading(false);
    }, 500),

    async getDataInit() {

      this.setLoading(true);
      const responseTributacao = await this.$http.post('/tributacaoservico/get-tipos-tributacao');
      const responseExigibilidadeTipos = await this.$http.post('/tributacaoservico/get-exigibilidade-tributacao');
      this.tributacaoTipos = responseTributacao.data;
      this.exigibilidadeTipos = responseExigibilidadeTipos.data;

      if (!_.isEmpty(this.$route.params)) {
        const servicoData = this.$route.params.servicoData;
        const tributacaoData = this.$route.params.tributacaoData;
        this.form = { ...servicoData };
        this.tributacaoSelected = tributacaoData;
        this.codServicoSelected = servicoData.codigo_servico;
        this.render++;
      }
      this.setLoading(false);
    },
    async onSubmit() {
      let haveAnyError = false;

      this.addLoadingToButton('submit_novo_servico')
      if (!this.codServicoSelected) {
        this.removeLoadingToButton('submit_novo_servico')
        this.$toast.error('Preencha o serviço!')
        this.servicoError = true;
        haveAnyError= true;
      }

      if (!this.tributacaoSelected) {
        this.removeLoadingToButton('submit_novo_servico')
        this.$toast.error('Preencha a tributação!')
        this.tributacaoError = true;
        haveAnyError= true;
      }

      if (!this.form.discriminacao) {
        this.removeLoadingToButton('submit_novo_servico')
        this.$toast.error('Discriminação obrigatória!')
        haveAnyError = true;
        this.discriminacaoFilled = false;
      }

      if (this.form.valor === 0) {
        this.removeLoadingToButton('submit_novo_servico')
        this.$toast.error('Valor tem que ser maior que 0!')
        haveAnyError= true;
        this.valorFilled = false;
      }

      if(haveAnyError) return;

      const dataToSave = {
        ...this.form,
        id_tributacao_servico: this.tributacaoSelected.id_tributacao_servico,
        codigo: this.codServicoSelected.codigo,
        id_codigo_servico: this.codServicoSelected.id_codigo_servico
      }

      try {
        if (!_.isEmpty(this.$route.params)) {
          await this.$http.post('/servicos/update', dataToSave);
          this.$toast.success('Serviço atualizado com sucesso!');
        } else {
          await this.$http.post('/servicos/create', dataToSave);
          this.$toast.success('Serviço salvo com sucesso!');
        }
        this.$router.push({ name: 'servicos' })
      } catch (error) {
        console.log(error);
      }
      this.removeLoadingToButton('submit_novo_servico')
    }
  },
  created() {
    if (this.itsblocked) {
      this.$router.push({ name: "assinatura" });
    }
    this.getDataInit();
  }
};
</script>


<style>

.notFilled .vs__dropdown-toggle {
  border: 2px solid red;
  /* color: #394066; */
}

.notFilled .vs__clear,
.notFilled .vs__open-indicator {
  fill: red;
}

.inputFilled .vs__dropdown-toggle {
  border: 2px solid #1bc5bd;
  /* color: #394066; */
}

.inputFilled .vs__clear,
.inputFilled .vs__open-indicator {
  fill: #1bc5bd;
}
</style>