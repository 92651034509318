import vendasLista from "@/modules/Vendas/pages/Index";
import vendasNovo from "@/modules/Vendas/pages/Create";
import vendasEditar from "@/modules/Vendas/pages/Update";
import relatorios from "@/modules/Vendas/pages/Relatorios";

export default [
  {
    name: "vendasLista",
    path: "/vendas",
    component: vendasLista,
    meta: { title: "PDV" },
  },
  {
    name: "vendasNovo",
    path: "/venda-nova",
    component: vendasNovo,
    meta: { title: "PDV" },
  },
  {
    name: "vendasEditar",
    path: "/vendas/:id/editar",
    component: vendasEditar,
    meta: { title: "PDV" },
  },
  {
    name: "relatoriosMovimentacoes",
    path: "/relatoriosMovimentacoes",
    component: relatorios,
    meta: { title: "PDV" },
  },
];
