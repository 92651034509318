import perfil from "@/modules/Perfil/pages/Index";


export default [
  {
    name: "perfil",
    path: "/perfil",
    component: perfil,
    meta: { title: "Perfil" },
  },
];
