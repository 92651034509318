export default class TransportadorData {
  nome = null;
  nome_fantasia = null;
  cnpj_cpf = null;
  endereco = {};
  veiculo = {};
  tipoFrete = {};
  quantidade = 0;
  especie = null;
  marca = null;
  pesoBruto = 0;
  pesoLiquido = 0;
  numeracao = null;
  constructor(
    nome = null,
    nome_fantasia = null,
    cnpj_cpf = null,
    endereco = {},
    veiculo = {},
    tipoFrete = {},
    quantidade = 0,
    especie = null,
    marca = null,
    pesoBruto = 0,
    pesoLiquido = 0,
    numeracao = null
  ) {
    this.nome = nome;
    this.nome_fantasia = nome_fantasia;
    this.cnpj_cpf = cnpj_cpf;
    this.endereco = endereco;
    this.veiculo = veiculo;
    this.tipoFrete = tipoFrete;
    this.quantidade = quantidade;
    this.especie = especie;
    this.marca = marca;
    this.pesoBruto = pesoBruto;
    this.pesoLiquido = pesoLiquido;
    this.numeracao = numeracao;
  }
}
