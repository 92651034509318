<template>
  <div style="position: relative">
    <FilterFields :fields="[
      {
        type: 'number',
        label: 'Código',
        field: 'codigo',
      },
      {
        type: 'text',
        label: 'Nome',
        field: 'nome',
      },
    ]" />


    <TableApp titulo="Cadastro de Kits" acaoBtn="/#/kit/novo" textoBtn="Novo" api-url="/kit/lista" :fields="fields"
      classFilterColumn="kit" ref="kit" :perPage="10" :multiselect="true">
      <template slot="customRow" scope="props">
        <span slot="codigo" v-if="props.field == 'codigo'">
          <span class="pl-0 pt-8">
            <div class="d-flex align-items-center">
              <div>
                <a class="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg">{{ props.rowData.codigo
                }}</a>
              </div>
            </div>
          </span>
        </span>
        <span v-if="props.field == 'nome'">
          <span class="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg">{{ props.rowData.nome}}</span>
        </span>
        <span v-if="props.field == 'valor_total'">
          <span class="text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg">{{ numberToReal(props.rowData.valor_total)}}</span>
        </span>
        <span slot="actions" v-if="props.field == 'actions'">
          <div class="row-actions" style="text-align: right !important">
            <span style="overflow: visible; position: relative; width: 130px">
              <div class="dropdown dropdown-inline">
                <button @click="editarKit(props.rowData)" class="btn btn-sm btn-info btn-hover-info btn-icon mr-2" v-b-tooltip.right="'Editar Kit'">
                  <Pencil :size="20" />
                </button>
                <button @click="excluir(props.rowData)" class="btn btn-sm btn-danger btn-hover-danger btn-icon mr-2"
                  v-b-tooltip.hover.bottom="'Excluir'">
                  <TrashCanIcon :size="16" />
                </button>
              </div>
            </span>
          </div>
        </span>
      </template>
    </TableApp>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Pencil from "vue-material-design-icons/Pencil.vue";
import TrashCanIcon from "vue-material-design-icons/TrashCan.vue";
import TableApp from "@/components/Table/Table2";
import { SistemaMixin } from "@/modules/Application";
import { onboardStore } from "../../../onboardStore";
import FilterFields from "@/components/FilterFields";
import { actionsStore } from "@/components/Table/actionsStore";
import swal from "sweetalert";

export default {
  name: "app-kit",
  mixins: [SistemaMixin],
  components: {
    Pencil,
    TrashCanIcon,
    TableApp,
    FilterFields,
  },
  data() {
    return {
      actionsStore,
      onboardStore,
      newProductDescription: null,
      fields: [
        {
          label: "Código",
          field: "codigo",
          type: "text",
          slot: true,
          sortable: true,
          filterOptions: {
            enabled: false,
            placeholder: "Pesquisar codigo",
          },
        },
        {
          label: "Nome",
          field: "nome",
          type: "text",
          slot: true,
          sortable: true,
          filterOptions: {
            enabled: false,
          },
        },
        {
          label: "Valor R$",
          field: "valor_total",
          type: "text",
          slot: true,
          sortable: true,
          filterOptions: {
            enabled: false,
          },
        },
        {
          label: "",
          field: "actions",
          sortable: false,
          slot: true,
          width: "10%",
          filterOptions: {
            enabled: false,
          },
        },
      ],
    };
  },
  methods: {
    async editarKit(data) {
      swal({
        title: "Deseja editar o Kit?",
        text: "Você ira ser direcionado para a pagina de edição aguarde...",
        icon: "info",
        buttons: {
          cancel: {
            text: "Cancelar",
            value: "cancel",
            visible: true,
            closeModal: true,
          },
          confirm: {
            text: "OK",
            visible: true,
            closeModal: false,
          },
        },
        dangerMode: false,
        closeModal: false,
        closeOnClickOutside: false,
      }).then(async (value) => {
        if (value === "cancel") {
          swal.close();
          swal.stopLoading();
          return;
        }

        await this.$http
          .post("/kit/find", {
            id_kit: data.id_kit,
          })
          .then((response) => {
            swal.stopLoading();
            swal.close();
            this.$toast.info("Você será redirecionado agora");
            this.$router.push({
              name: "kitNovo",
              params: { kitData: { ...response.data, id_kit: data.id_kit } },
            });
          })
          .catch(() => {
            swal.stopLoading();
            this.$toast.error("Erro ao solicitar o endpoint do Kit");
            swal.close();
          });
      });
    },
    excluir(data) {
      this.getRequestAdmin(() => {
        this.$swal
          .fire({
            icon: "warning",
            title: "Excluir o Kit " + data.nome + "?",
            showCancelButton: true,
            confirmButtonText: `Sim`,
            cancelButtonText: `Não`,
            confirmButtonColor: "#f64e60",
          })
          .then(async (result) => {
            if (result.isConfirmed) {
              await this.$http.post("/kit/delete", {
                id_kit : data.id_kit,
              });
              this.$refs.kit.refresh();
              this.$toast.success("Kit excluído!");
            }
          })
      })
    },
  },
  computed: {
    ...mapGetters(["layoutConfig", "itsblocked"]),
    config() {
      return this.layoutConfig();
    },
  },
  mounted() {
    if (this.itsblocked) {
      this.$router.push({ name: "assinatura" });
    }
  },
};
</script>
