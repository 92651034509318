var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"position":"relative"}},[_c('FilterFields',{attrs:{"fields":[
      {
        type: 'text',
        label: 'Nome',
        field: 'nome',
      },
    ]}}),_c('TableApp',{ref:"produto",attrs:{"titulo":"Variações","acaoBtn":"/#/variacoes/novo","textoBtn":"Nova","api-url":"/variavel/lista","fields":_vm.fields,"classFilterColumn":"produto","perPage":10},scopedSlots:_vm._u([{key:"customRow",fn:function(props){return [(props.field == 'nome')?_c('span',{attrs:{"slot":"nome"},slot:"nome"},[_c('span',{staticClass:"pl-0 pt-8"},[_c('div',{staticClass:"d-flex align-items-center"},[_c('div',[_c('a',{staticClass:"text-dark-75 font-weight-bolder text-hover-primary mb-1 font-size-lg"},[_vm._v(_vm._s(props.rowData.nome))])])])])]):_vm._e(),(props.field == 'actions')?_c('span',{attrs:{"slot":"actions"},slot:"actions"},[_c('div',{staticClass:"row-actions",staticStyle:{"text-align":"right !important"}},[_c('span',{staticStyle:{"overflow":"visible","position":"relative","width":"130px"}},[_c('div',{staticClass:"dropdown dropdown-inline"},[_c('router-link',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.bottom",value:('Editar'),expression:"'Editar'",modifiers:{"hover":true,"bottom":true}}],staticClass:"btn btn-sm btn-info btn-hover-info btn-icon mr-2",attrs:{"to":{
                  name: 'variacoesUpdate',
                  params: {
                    id_variavel: props.rowData.id_variavel,
                  },
                }}},[_c('Pencil',{attrs:{"size":16,"title":"Editar"}})],1),_c('button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.bottom",value:('Excluir'),expression:"'Excluir'",modifiers:{"hover":true,"bottom":true}}],staticClass:"btn btn-sm btn-danger btn-hover-danger btn-icon mr-2",on:{"click":function($event){return _vm.excluir(props.rowData)}}},[_c('TrashCanIcon',{attrs:{"size":16}})],1)],1)])])]):_vm._e()]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }