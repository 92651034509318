<template>
  <div>
    <div class="card card-custom gutter-b">
      <div class="card-header card-header-tabs-line">
        <ul
          class="nav nav-dark nav-bold nav-tabs nav-tabs-line"
          role="tablist"
          ref="builder-tab"
        >
          <li class="nav-item">
            <a
              class="nav-link active"
              v-on:click="setActiveTab"
              data-tab="0"
              data-toggle="tab"
              href="#"
              role="tab"
            >
              Cadastro
            </a>
          </li>
        </ul>
      </div>

      <b-form class="form" @submit.stop.prevent="onSubmit">
        <div class="card-body">
          <b-tabs class="hide-tabs" v-model="tabIndex">
            <b-tab active>
              <b-row>
                <b-col md="4">
                  <b-form-group
                    label="Escolha o tipo da conta"
                    label-for="tipo"
                  >
                    <select
                      name="tipo"
                      id="tipo"
                      v-model="form.tipo"
                      class="form-control"
                    >
                      <option
                        v-for="(t, index) in tipos"
                        :value="t.type"
                        :key="'code' + index"
                      >
                        {{ t.description }}
                      </option>
                    </select>
                  </b-form-group>
                </b-col>
                <b-col md="3">
                  <b-form-group id="nome" label-for="nome" label="Nome">
                    <b-form-input
                      maxlength="20"
                      v-model="$v.form.nome.$model"
                      :state="validateState('nome')"
                    >
                    </b-form-input>
                  </b-form-group>
                </b-col>
                <b-col md="4">
                  <b-form-group label="Escolha o banco" label-for="banco">
                    <VueSelect
                      id="banco"
                      ref="banco"
                      :filterable="false"
                      @input="setBanco"
                      :options="bancos"
                      v-model="bancoSelected"
                      placeholder="Informe o código do banco para filtrar"
                      @search="onSearchBancos"
                      :class="{
                        hasErrorsAcvtivity: !bancoFilled,
                        inputFilledBanco: bancoFilled,
                      }"
                    >
                      <template slot="no-options">
                        Informe o código do banco
                      </template>
                      <template slot="option" slot-scope="option">
                        <div class="d-center">
                          {{ option.name }}
                        </div>
                      </template>
                      <template slot="selected-option" slot-scope="option">
                        <div class="selected d-center">
                          {{ option.name }}
                        </div>
                      </template>
                    </VueSelect>
                  </b-form-group>
                </b-col>
                <b-col md="2">
                  <b-form-group
                    id="agencia"
                    label-for="agencia"
                    label="Agência"
                  >
                    <b-form-input
                      maxlength="4"
                      v-model="$v.form.agencia.$model"
                      :state="validateState('agencia')"
                    >
                    </b-form-input>
                  </b-form-group>
                </b-col>
                <b-col md="2">
                  <b-form-group id="conta" label-for="conta" label="Conta">
                    <b-form-input
                      maxlength="10"
                      v-model="$v.form.conta.$model"
                      :state="validateState('conta')"
                    >
                    </b-form-input>
                  </b-form-group>
                </b-col>
                <b-col md="4">
                  <b-form-group
                    id="beneficiario"
                    label-for="beneficiario"
                    label="Nome do Benificiário"
                  >
                    <b-form-input
                      v-model="$v.form.beneficiario.$model"
                      :state="validateState('beneficiario')"
                    >
                    </b-form-input>
                  </b-form-group>
                </b-col>
                <b-col md="2">
                  <b-form-group
                    id="saldo"
                    label-for="prsaldoeco"
                    label="Saldo Inicial"
                  >
                    <money
                      class="form-control"
                      v-model="form.saldo"
                      v-bind="configMoney"
                    >
                    </money>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-tab>
          </b-tabs>
        </div>

        <div class="card-footer">
          <div class="row">
            <div class="col-lg-12" style="text-align: center">
              <button
                type="submit"
                ref="submit_novo_banco"
                class="btn btn-primary font-weight-bold"
              >
                Salvar
              </button>
              <router-link
                class="btn btn-clean font-weight-bold"
                :to="{
                  name: 'bancosLista',
                }"
              >
                Cancelar
              </router-link>
            </div>
          </div>
        </div>
      </b-form>
    </div>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { SistemaMixin } from "@/modules/Application";
import bancosData, { BancosDataRequired } from "./BancosData";
import { mask } from "vue-the-mask";
import { mapGetters } from "vuex";
import { MONEY as settingMoney } from "@/modules/Application";
import { Money } from "v-money";
import _ from "lodash";
import VueSelect from "vue-select";
import "vue-select/dist/vue-select.css";

export default {
  name: "app-bancos-form",
  props: {
    url: {
      type: String,
    },
    banco: {
      type: Object,
      default: () => {},
    },
  },
  directives: {
    mask,
  },
  components: {
    Money,
    VueSelect,
  },
  mixins: [validationMixin, SistemaMixin],
  data() {
    return {
      tabIndex: 0,
      form: {},
      bancos: [],
      configMoney: settingMoney,
      tipos: [
        {
          type: "savings",
          description: "Conta Poupança",
        },
        {
          type: "checking",
          description: "Conta Corrente",
        },
      ],
      bancoFilled: false,
      bancoSelected: null,
    };
  },
  watch: {
    banco(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.form = newValue;
        this.bancos = newValue.banco_zoop ?? null;
      }
    },
  },
  validations: {
    form: BancosDataRequired,
  },
  computed: {
    ...mapGetters(["layoutConfig", "itsblocked"]),
    config() {
      return this.layoutConfig();
    },
  },
  async mounted() {
    if (this.itsblocked) {
      this.$router.push({ name: "assinatura" });
    }
    this.getDataInit();
  },
  methods: {
    async setBanco(banco) {
      this.bancoSelected = banco;
      this.bancoFilled = true;
    },
    async getDataInit() {
      this.form = new bancosData({});
      if (this.banco.id_banco) {
        this.form = this.banco;
        this.setBanco(this.banco.banco_zoop);
        return;
      }
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    setActivePreviousTab() {
      this.tabIndex = parseInt(localStorage.getItem("builderTab")) || 0;
      const links = this.$refs["builder-tab"].querySelectorAll(".nav-link");

      for (let i = 0; i < links.length; i++) {
        links[i].classList.remove("active");
      }

      this.$refs["builder-tab"]
        .querySelector(`[data-tab="${this.tabIndex}"]`)
        .classList.add("active");
    },
    setActiveTab(event) {
      const tab = event.target.closest('[role="tablist"]');
      const links = tab.querySelectorAll(".nav-link");

      for (let i = 0; i < links.length; i++) {
        links[i].classList.remove("active");
      }

      this.tabIndex = parseInt(event.target.getAttribute("data-tab"));

      event.target.classList.add("active");
    },
    async onSubmit() {
      const submitButton = this.$refs["submit_novo_banco"];

      try {
        this.$v.form.$touch();

        if (this.$v.form.$anyError) {
          return;
        }

        submitButton.classList.add("spinner", "spinner-light", "spinner-right");

        if (this.bancoSelected) {
          this.form.banco = this.bancoSelected.code;
          this.form.id_banco_zoop = this.bancoSelected.id_banco_zoop;
        }

        await this.$http.post(this.url, this.form);

        this.$router.push({ name: "bancosLista" });
      } catch (error) {
        submitButton.classList.remove(
          "spinner",
          "spinner-light",
          "spinner-right"
        );
      }
    },
    onSearchBancos(search, loading) {
      if (search.length) {
        loading(true);
        this.searchBanco(loading, search, this);
      }
    },
    searchBanco: _.debounce(async (loading, search, vm) => {
      vm.bancos = await vm.getBancoZoopByCode(search);
      loading(false);
    }, 350),
  },
};
</script>

<style>
.vs__dropdown-toggle {
  border: 2px solid #dedede;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border-radius: 6px;
  outline: none;
  height: 38px;
  color: black;
}

.hasErrorsAcvtivity .vs__dropdown-toggle {
  border: 2px solid red;
  /* color: #394066; */
}

.vs__dropdown-menu {
  color: black !important;
}

.hasErrorsAcvtivity .vs__clear,
.hasErrorsAcvtivity .vs__open-indicator {
  fill: red;
}

.inputFilledBanco .vs__dropdown-toggle {
  border: 2px solid #1bc5bd;
  /* color: #394066; */
}

.inputFilledBanco .vs__clear,
.inputFilledBanco .vs__open-indicator {
  fill: #1bc5bd;
}

.inputFilledTipo .vs__dropdown-toggle {
  border: 2px solid #1bc5bd;
  /* color: #394066; */
}

.inputFilledTipo .vs__clear,
.inputFilledTipo .vs__open-indicator {
  fill: #1bc5bd;
}


.inputFilled {
  border: 2px solid #1bc5bd !important;
}
</style>
