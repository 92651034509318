import termos from "@/modules/Termos/pages/Termos";

export default [
  {
    path: "/termos",
    name: "termos",
    component: termos,
    meta: { title: "Termos" },
  },
];
