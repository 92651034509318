<template>
  <div>
    <div class="card card-custom gutter-b" style="box-shadow: none">
      <div class="card-body">
        <b-row>
          <b-col md="6">
            <b-form-group id="Descricao" label-for="Descricao" label="Descrição">
              <b-form-input v-model="form.descricao" />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>

          <b-col md="6">
            <b-form-group id="Intervalos" label-for="Intervalos" label="Intervalos">
              <VueSelect ref="intervalos" :filterable="false" v-model="intervaloSelected" @input="setIntervalo"
                :options="intervalos" :searchable="false">
                <template slot="no-options">
                  Selecione o intervalo
                </template>
                <template slot="option" slot-scope="option">
                  <div class="d-center">
                    {{ option.descricao }}
                  </div>
                </template>
                <template slot="selected-option" slot-scope="option">
                  <div class="selected d-center">
                    {{ option.descricao }}
                  </div>
                </template>
              </VueSelect>
            </b-form-group>
          </b-col>

          <b-col md="2" v-if="form.tipo_repeticao === 9">
            <b-form-group id="intervaloDias" label-for="intervaloDias" label="Intervalo em dias">
              <b-form-input v-model="intervaloDiasToAdd" type="number" @keyup.enter="addInterval" />
            </b-form-group>
          </b-col>
          <b-col v-if="form.tipo_repeticao === 9" style="display: flex; align-items: center;">
            <button @click="addInterval">
              <Plus class="btn btn-sm btn-info btn-hover-info btn-icon mr-2" />
            </button>
          </b-col>
        </b-row>

        <b-row v-if="form.tipo_repeticao === 9">
          <b-col md="6">
            <TableApp item="intervalos" subTitulo="" :data="intervalo_dias_collection" :fields="fields"
              classFilterColumn="nome" ref="nome">
              <template slot="customRow" scope="props">

                <span slot="dias" class="pl-0 pt-8" v-if="props.field == 'dias'">
                  <div class="d-flex align-items-center">
                    <div class="font-weight-bold ">
                      <b-form-input :value="props.rowData.dias"
                        @change="(event) => changeDiaValue(event, props.rowData)" type="number" style="width: 80px;" />
                    </div>
                  </div>
                </span>

                <span slot="actions" v-if="props.field == 'actions'">
                  <div class="row-actions" style="text-align: right !important">
                    <span style="overflow: visible; position: relative; width: 130px">
                      <div class="action-buttons">
                        <button class="btn btn-sm btn-danger btn-hover-danger btn-icon mr-2"
                          @click="() => removeInterval(props.rowData.originalIndex)">
                          <TrashCanIcon :size="16" />
                        </button>
                      </div>
                    </span>
                  </div>
                </span>

              </template>
            </TableApp>
          </b-col>
        </b-row>

        <b-row>

          <b-col md="2">
            <b-form-group label="Vezes" label-for="vezes">
              <b-form-input v-model="form.vezes" type="number" :disabled="form.tipo_repeticao === 9" />
            </b-form-group>
          </b-col>

          <b-col md="6">
            <b-form-group id="MeioPagamento" label-for="MeioPagamento" label="Meio de Pagamento">
              <VueSelect ref="meiosPagamentos" :filterable="false" v-model="meioPagamentosSelected"
                @input="setMeioPagamento" :options="meiosPagamentos" :searchable="false">
                <template slot="no-options">
                  Selecione o meio de pagamento
                </template>
                <template slot="option" slot-scope="option">
                  <div class="d-center">
                    {{ option.descricao }}
                  </div>
                </template>
                <template slot="selected-option" slot-scope="option">
                  <div class="selected d-center">
                    {{ option.descricao }}
                  </div>
                </template>
              </VueSelect>
            </b-form-group>
          </b-col>

        </b-row>
        <b-row>
          <b-col md="3">
            <b-form-group id="visualizar" label="Visualizar" label-for="visualizar">
              <span class="switch switch-icon">
                <label>
                  <input type="checkbox" v-model="form.visualizar" />
                  <span></span>
                </label>
              </span>
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group id="visualizar_app" label="Visualizar App" label-for="visualizar_app">
              <span class="switch switch-icon">
                <label>
                  <input type="checkbox" v-model="form.visualizar_app" />
                  <span></span>
                </label>
              </span>
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group id="informar_cliente" label="Informar Cliente" label-for="informar_cliente">
              <span class="switch switch-icon">
                <label>
                  <input type="checkbox" v-model="form.informar_cliente" />
                  <span></span>
                </label>
              </span>
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group id="movimenta_caixa" label="Gerar movimento de caixa" label-for="movimenta_caixa">
              <span class="switch switch-icon">
                <label>
                  <input type="checkbox" v-model="form.movimenta_caixa" />
                  <span></span>
                </label>
              </span>
            </b-form-group>
          </b-col>
        </b-row>
      </div>

      <div class="card-footer">
        <div class="row">
          <div class="col-lg-12" style="text-align: center">
            <button type="submit" ref="subit_novo_parcelamento" @click="onSubmit"
              class="btn btn-primary font-weight-bold">
              Salvar
            </button>
            <router-link class="btn btn-clean font-weight-bold" :to="{
                name: 'parcelamentos',
              }">
              Cancelar
            </router-link>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { SistemaMixin } from "@/modules/Application";
import ParcelamentoData, { ParcelamentoDataRequired } from "./ParcelamentoData";
import VueSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import _ from "lodash";
import { mapGetters, mapActions } from "vuex";
import Plus from "vue-material-design-icons/PlusThick.vue";
import TableApp from "@/components/Table/Table3.vue";
import TrashCanIcon from "vue-material-design-icons/TrashCan.vue";

export default {
  name: "app-parcelamentos-form",
  props: {
    url: {
      type: String,
    },
    parcelamento: {
      type: Object,
      default: () => { },
    },
  },
  components: {
    VueSelect,
    Plus,
    TableApp,
    TrashCanIcon,
  },
  mixins: [validationMixin, SistemaMixin],
  data() {
    return {
      intervalos: [],
      intervaloSelected: null,
      meiosPagamentos: [],
      intervalo_dias_collection: [],
      intervaloDiasToAdd: null,
      meioPagamentosSelected: null,
      windowWidth: window.innerWidth,
      form: {},
      fields: [
        {
          label: "Intervalo (Dias)",
          field: "dias",
          sortable: false,
          slot: true,
        },
        {
          label: "",
          field: "actions",
          sortable: false,
          slot: true,
          width: "10%",
        },
      ],
    };
  },
  validations: {
    form: ParcelamentoDataRequired,
  },
  watch: {
    parcelamento(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.form = newValue;
        this.intervalo_dias_collection = this.form.intervalo_dias_collection;
        this.meioPagamentosSelected = this.meiosPagamentos.find(el => el.id === this.form.id_meio_pagamento);
        this.intervaloSelected = this.intervalos.find(el => el.id === this.form.intervalo_dias.id);
      }
    },
  },
  computed: {
    ...mapGetters(["itsblocked"]),
  },
  created() {
    if (this.itsblocked) {
      this.$router.push({ name: "assinatura" });
    }
    this.getDataInit();
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
    });
  },
  methods: {
    ...mapActions(["setLoading"]),
    removeInterval(index) {
      this.intervalo_dias_collection.splice(index, 1);
      this.form.vezes = this.intervalo_dias_collection.length;
    },
    changeDiaValue(e, rowData) {
      const aux = _.cloneDeep(this.intervalo_dias_collection);
      aux.splice(rowData.originalIndex, 1, {
        dias: e
      })
      this.intervalo_dias_collection = aux;
    },
    addInterval() {
      this.intervalo_dias_collection.push({
        dias: parseInt(this.intervaloDiasToAdd)
      })
      this.intervaloDiasToAdd = null;
      this.form.vezes = this.intervalo_dias_collection.length;
    },
    setIntervalo(intervalo) {
      this.form.tipo_repeticao = intervalo.id;
      if (intervalo.id !== 9) {
        this.intervalo_dias_collection = [];
        this.form.vezes = null;
      }
    },
    setMeioPagamento(meioPagamento) {
      this.form.id_meio_pagamento = meioPagamento.id;
    },
    onResize() {
      this.windowWidth = window.innerWidth;
    },
    resetInputs() {
      this.quantidade = 1;
      this.preco = 0;
      this.total = 0;
    },

    async getDataInit() {
      this.setLoading(true)
      this.form = new ParcelamentoData({});
      try {
        const [{ data: intervaloData }, { data: meiosPagamentoData }] = await Promise.all([this.$http.post('/financeiro/get-tipo-intervalo'), this.$http.post('/financeiro/meios-pagamento')])
        this.intervalos = intervaloData;
        this.meiosPagamentos = meiosPagamentoData;
      } catch (error) {
        console.log(error);
      }
      this.setLoading(false)
    },
    async onSubmit() {
      this.addLoadingToButton('subit_novo_parcelamento');
      try {
        await this.$http.post(this.url, {
          ...this.form,
          ...(this.form.tipo_repeticao === 9 && { intervalo_dias_collection: this.intervalo_dias_collection })
        });
        this.$router.push({ name: "parcelamentos" });
      } catch (error) {
        console.log(error);
      }
      this.removeLoadingToButton('subit_novo_parcelamento')
    },
  },
};
</script>
