<template>
  <div>
    <div class="card card-custom gutter-b" style="box-shadow: none">
      <div class="card-header border-0 py-5">
        <h3 class="card-title align-items-start flex-column">
          <span class="card-label font-weight-bolder text-dark">
            Novo Motorista
          </span>
        </h3>
      </div>
      <div class="card-body">
        <b-tabs class="hide-tabs" v-model="tabIndex">
          <b-tab active>
            <b-row>
              <b-col md="8">
                <b-form-group id="nome" label-for="nome" label="Nome">
                  <b-form-input maxlength="100" v-model="form.nome" autofocus :state="validateState('nome')">
                  </b-form-input>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="8">
                <b-form-group id="cpf" label-for="cpf" label="CPF">
                  <b-form-input maxlength="100" v-mask="['###.###.###-##']" v-model="form.cpf" autofocus
                    :state="validateState('cpf')">
                  </b-form-input>
                </b-form-group>
              </b-col>
            </b-row>
          </b-tab>
        </b-tabs>
      </div>

      <div class="card-footer">
        <div class="row">
          <div class="col-lg-12" style="text-align: center">
            <button type="submit" ref="submit_novo_motorista" @click="onSubmit" class="btn btn-primary font-weight-bold">
              Salvar
            </button>
            <router-link class="btn btn-clean font-weight-bold" :to="{
              name: 'motoristas',
            }">
              Cancelar
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { SistemaMixin } from "@/modules/Application";
import MotoristaData, { MotoristaDataRequired } from "./MotoristaData";
import { MONEY as settingMoney } from "@/modules/Application";
import "vue-select/dist/vue-select.css";
import _ from "lodash";
import { mapGetters, mapActions } from "vuex";
import { mask } from "vue-the-mask";

export default {
  name: "app-mmotorista-form",
  directives: {
    mask,
  },
  components: {
  },
  mixins: [validationMixin, SistemaMixin],
  data() {
    return {
      tabIndex: 0,
      configMoney: settingMoney,
      form: { nome: null, cpf: null},
    };
  },
  validations: {
    form: MotoristaDataRequired,
  },
  watch: {
  },
  computed: {
    ...mapGetters(["itsblocked"]),
  },
  created() {
    if (this.itsblocked) {
      this.$router.push({ name: "assinatura" });
    }
    this.getDataInit();
  },
  methods: {
    ...mapActions(["setLoading"]),
    async getDataInit() {
      this.setLoading(true);
      try {
        if (this.$route.params?.motoristaData) {
          const motoristaData = this.$route.params?.motoristaData
          this.form = motoristaData;
          this.form.tipo_caroceria = this.tiposCaroceria.find(caroceria => caroceria.id === motoristaData.tipo_caroceria);
          this.form.tipo_rodado = this.tiposRodados.find(rodado => rodado.id === motoristaData.tipo_rodado);
        }
      } catch (error) {
        console.log(error);
      }
      this.setLoading(false);
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    setActivePreviousTab() {
      this.tabIndex = parseInt(localStorage.getItem("builderTab")) || 0;
      const links = this.$refs["builder-tab"].querySelectorAll(".nav-link");

      for (let i = 0; i < links.length; i++) {
        links[i].classList.remove("active");
      }

      this.$refs["builder-tab"]
        .querySelector(`[data-tab="${this.tabIndex}"]`)
        .classList.add("active");
    },
    setActiveTab(event) {
      const tab = event.target.closest('[role="tablist"]');
      const links = tab.querySelectorAll(".nav-link");

      for (let i = 0; i < links.length; i++) {
        links[i].classList.remove("active");
      }

      this.tabIndex = parseInt(event.target.getAttribute("data-tab"));

      event.target.classList.add("active");
    },

    async onSubmit() {
      this.addLoadingToButton("submit_novo_motorista")

      try {
        this.$v.form.$touch();

        if (this.$v.form.$anyError) {
          this.$toast.error(
            "Verifique todos os campos obrigatórios do formulário!"
          );
          this.removeLoadingToButton('submit_novo_motorista')
          return;
        }
        const url = this.$route.params?.motoristaData?.id_motorista ?
          '/motoristas/update'
          :
          '/motoristas/create'

        await this.$http.post(url, { ...this.form });

        this.$router.push({ name: "motoristas" });
      } catch (error) {
        console.log(error)
      }
      this.removeLoadingToButton('submit_novo_motorista')
    },
  },
};
</script>