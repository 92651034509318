<template>
  <div>
    <b-card
      title="Configuração White Label"
      tag="article"
      class="mb-12 border border-primary"
    >
      <div class="col-md-12">
        <b-row>
          <b-col md="4">
            <b-form-group label="Cor Primária" label-for="cor_primaria">
              <b-form-input type="color" v-model="form.cor_primaria" />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group label="Cor Primária Hover" label-for="cor_primaria">
              <b-form-input type="color" v-model="form.cor_primaria_hover" />
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group label="Cor Secundária" label-for="cor_secundaria">
              <b-form-input type="color" v-model="form.cor_secundaria" />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="6">
            <div class="mt-3">Selecione a logomarca primária</div>
            <b-form-file
              accept=".jpg, .png"
              placeholder="Selecione o logomarca primária..."
              @change="setLogomarca"
            ></b-form-file>
          </b-col>
          <b-col md="2" v-if="urlLogo">
            <b-card-img
              :src="urlLogo"
              class="rounded-0"
              style="max-width: 100px !important"
            ></b-card-img>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="6">
            <div class="mt-3">Selecione a logomarca secundária</div>
            <b-form-file
              accept=".jpg, .png"
              placeholder="Selecione o logomarca secundária..."
              @change="setLogomarcaSecundaria"
            ></b-form-file>
          </b-col>
          <b-col md="6" v-if="urlLogo">
            <b-card-img
              :src="urlLogoSecundaria"
              class="rounded-0"
              style="max-width: 100px !important"
            ></b-card-img>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="6">
            <div class="mt-3">Selecione a ícone</div>
            <b-form-file
              accept=".jpg, .png"
              placeholder="Selecione o ícone..."
              @change="setIcone"
            ></b-form-file>
          </b-col>
          <b-col md="6" v-if="urlIcone">
            <b-card-img
              :src="urlIcone"
              class="rounded-0"
              style="max-width: 100px !important"
            ></b-card-img>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="6">
            <div class="mt-3">Selecione a ícone branco</div>
            <b-form-file
              accept=".jpg, .png"
              placeholder="Selecione o ícone branco..."
              @change="setIconeBranco"
            ></b-form-file>
          </b-col>
          <b-col md="6" v-if="urlIconeBranco">
            <b-card-img
              :src="urlIconeBranco"
              class="rounded-0"
              style="max-width: 100px !important"
            ></b-card-img>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="6">
            <div class="mt-3">Selecione o Favicon</div>
            <b-form-file
              accept=".jpg, .png"
              placeholder="Selecione o favicon..."
              @change="setFavIcon"
            ></b-form-file>
          </b-col>
          <b-col md="6" v-if="ulrFavIcon">
            <b-card-img
              :src="ulrFavIcon"
              class="rounded-0"
              style="max-width: 100px !important"
            ></b-card-img>
          </b-col>
        </b-row>
        <br />
        <b-row>
          <b-col md="12">
            <b-form-group label="SubDomínio" label-for="host">
              <b-form-input type="text" v-model="form.host" />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <h3 class="text-muted font-weight-bold d-block">
            Configuração E-mail:
          </h3>
        </b-row>
        <br />
        <b-row>
          <b-col md="6">
            <b-form-group label="E-mail Host" label-for="mail_host">
              <b-form-input type="text" v-model="form.mail_host" />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label="E-mail Porta" label-for="mail_port">
              <b-form-input type="text" v-model="form.mail_port" />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label="Usuário" label-for="mail_username">
              <b-form-input type="text" v-model="form.mail_username" />
            </b-form-group>
          </b-col>

          <b-col md="6">
            <b-form-group label="Senha" label-for="mail_password">
              <b-form-input type="password" v-model="form.mail_password" />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label="Encryption" label-for="mail_encryption">
              <b-form-input type="text" v-model="form.mail_encryption" />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="6">
            <b-form-group label="Nome da plataforma" label-for="app_name">
              <b-form-input
                type="text"
                v-model="form.app_name"
                maxlength="10"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <br />
        <br />
        <b-col class="col-lg-12" style="text-align: center">
          <button
            @click="gravarConfiguracao"
            class="btn btn-primary font-weight-bold"
          >
            Configurar
          </button>
          <router-link
            class="btn btn-clean font-weight-bold"
            :to="{
              name: 'clientesLista',
            }"
          >
            Voltar
          </router-link>
        </b-col>
      </div>
    </b-card>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { SistemaMixin } from "@/modules/Application";
import { mapGetters, mapActions } from "vuex";
import { mask } from "vue-the-mask";

export default {
  name: "app-configuracao-partner",
  mixins: [validationMixin, SistemaMixin],
  data() {
    return {
      form: {},
      totais: [],
      urlLogo: null,
      urlIcone: null,
      urlLogoSecundaria: null,
      urlIconeBranco: null,
      ulrFavIcon: null,
    };
  },
  directives: {
    mask,
  },
  computed: {
    ...mapGetters([
      "itsblocked",
      "idRevenda",
      "dadosRevenda",
      "isRevenda",
      "currentUser",
    ]),
  },
  async mounted() {
    if (!this.isRevenda) {
      this.$router.push({ name: "dashboard" });
    }
    await this.getDataInit();
  },

  methods: {
    ...mapActions(["setLoading"]),
    async getDataInit() {
      this.setLoading(true);

      const data = await this.$http.post(
        "/empresa/cofing-partner",
        this.currentUser.usuario_empresa.empresa.nivel_revenda
      );
      if (data.data) {
        this.form = data.data;
        this.urlLogo = data.data.logo_principal;
        this.urlIcone = data.data.icone;
        this.urlIconeBranco = data.data.icone_branco;
        this.ulrFavIcon = data.data.favicon;
        this.urlLogoSecundaria = data.data.logo_secundaria;
      }
      this.setLoading(false);
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    async setLogomarcaSecundaria(event) {
      this.form.logo_secundaria = await event.target.files[0];
      this.urlLogoSecundaria = URL.createObjectURL(this.form.logo_secundaria);
    },
    async setLogomarca(event) {
      this.form.logo_principal = await event.target.files[0];
      this.urlLogo = URL.createObjectURL(this.form.logo_principal);
    },
    async setIcone(event) {
      this.form.icone = await event.target.files[0];
      this.urlIcone = URL.createObjectURL(this.form.icone);
    },
    async setIconeBranco(event) {
      this.form.icone_branco = await event.target.files[0];
      this.urlIconeBranco = URL.createObjectURL(this.form.icone_branco);
    },
    async setFavIcon(event) {
      this.form.favicon = await event.target.files[0];
      this.ulrFavIcon = URL.createObjectURL(this.form.favicon);
    },
    async gravarConfiguracao() {
      this.setLoading(true);

      let configuracao = new FormData();

      if (
        this.form.logo_principal !== null &&
        this.form.logo_principal instanceof Blob
      ) {
        configuracao.append("logo_principal", this.form.logo_principal);
      }

      if (
        this.form.logo_secundaria !== null &&
        this.form.logo_secundaria instanceof Blob
      ) {
        configuracao.append("logo_secundaria", this.form.logo_secundaria);
      }

      if (
        this.form.icone_branco !== null &&
        this.form.icone_branco instanceof Blob
      ) {
        configuracao.append("icone_branco", this.form.icone_branco);
      }

      if (this.form.favicon !== null && this.form.favicon instanceof Blob) {
        configuracao.append("favicon", this.form.favicon);
      }

      if (this.form.icone !== null && this.form.icone instanceof Blob) {
        configuracao.append("icone", this.form.icone);
      }

      if (this.form.cor_primaria !== null) {
        configuracao.append("cor_primaria", this.form.cor_primaria);
      }

      if (this.form.cor_primaria_hover !== null) {
        configuracao.append("cor_primaria_hover", this.form.cor_primaria_hover);
      }

      if (this.form.cor_secundaria !== null) {
        configuracao.append("cor_secundaria", this.form.cor_secundaria);
      }

      if (this.form.host !== null) {
        configuracao.append("host", this.form.host);
      }

      if (this.form.mail_host !== null) {
        configuracao.append("mail_host", this.form.mail_host);
      }

      if (this.form.mail_port !== null) {
        configuracao.append("mail_port", this.form.mail_port);
      }

      if (this.form.mail_username !== null) {
        configuracao.append("mail_username", this.form.mail_username);
      }

      if (this.form.mail_password !== null) {
        configuracao.append("mail_password", this.form.mail_password);
      }

      if (this.form.mail_encryption !== null) {
        configuracao.append("mail_encryption", this.form.mail_encryption);
      }

      if (this.form.app_name !== null) {
        configuracao.append("app_name", this.form.app_name);
      }

      await this.$http
        .post("/empresa/create-configuracao-partner", configuracao)
        .then((result) => {
          if (result) {
            this.setLoading(false);
            this.$toast.success("Configuração atualizada com sucesso!");
          }
        });
      this.setLoading(false);
    },
  },
};
</script>

<style lang="scss">
.vs__dropdown-toggle {
  border: 2px solid #dedede;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border-radius: 6px;
  outline: none;
  height: 500px;
}
@import "../../../assets/styles/styleCardPayment.scss";
</style>
