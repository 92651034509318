<template>
  <b-tab
    title="Outras informações"
    :active="isTheCurrentTab"
    :disabled="!isTheCurrentTab && !store.isUpdate"
    class="w-75"
    @click="() => (store.currentTab = 3)"
  >
    <label>Data Solicitação</label>
    <b-row>
      <b-col sm="6">
        <b-form-group id="data_solicitacao" label-for="data_solicitacao">
          <b-form-datepicker v-model="form.data_solicitacao" locale="pt-br" />
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="6">
        <b-form-group label="Status" label-for="status">
          <VueSelect
            ref="buscarStatus"
            :filterable="false"
            @input="setStatus"
            :options="allStatus"
            @search="onSearchStatus"
            v-model="form.status"
          >
            <template slot="no-options"> Informe o status </template>
            <template slot="option" slot-scope="option">
              <div class="d-center">
                {{ option.descricao }}
              </div>
            </template>
            <template slot="selected-option" slot-scope="option">
              <div class="selected d-center">
                {{ option.descricao }}
              </div>
            </template>
          </VueSelect>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col class="">
        <b-form-group label="Informações Adicionais" label-for="observacao">
          <v-textarea
            full-width
            no-resize
            rows="1"
            auto-grow
            v-model="form.observacao"
          ></v-textarea>
        </b-form-group>
      </b-col>
    </b-row>
  </b-tab>
</template>

<script>
import { mapGetters, mapState, mapActions } from "vuex";
import VueSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import { validationMixin } from "vuelidate";
import { SistemaMixin, ValidacaoMixin } from "@/modules/Application";
import _ from "lodash";
import { mask } from "vue-the-mask";
import { store } from "../../storeOrdemCompra";
import InformacoesData from "../InformacoesData";

export default {
  name: "app-ordem-form-informacoesTab",
  components: {
    VueSelect,
  },
  directives: {
    mask,
  },
  data() {
    return {
      form: {},
      allStatus: [
        {
          id: 1,
          descricao: "Em aberto",
        },
        {
          id: 2,
          descricao: "Em andamento",
        },
        {
          id: 3,
          descricao: "Cancelado",
        },
        {
          id: 4,
          descricao: "Atendido",
        },
        {
          id: 5,
          descricao: "Encaminhado",
        },
      ],
      status: null,
      keyToReRender: 0,
      store,
      statusError: false,
      enderecoError: false,
      statusFilled: false,
    };
  },
  mixins: [validationMixin, SistemaMixin, ValidacaoMixin],
  methods: {
    ...mapActions(["setLoading"]),
    async getDataInit() {
      this.form = new InformacoesData({});
      this.form.status = this.allStatus.find((item) => item.id === 1);
      this.form.data_solicitacao = this.thisDate();

      if (Object.keys(this.$route.params).length !== 0) {
        const {
          data_solicitacao,
          observacao,
          status: statusId,
        } = this.$route.params.ordemData;
        this.form.observacao = observacao;
        this.form.data_solicitacao = data_solicitacao;
        this.form.status = this.allStatus.find(
          (status) => status.id === statusId
        );
      }
    },
    onSearchStatus(search, loading) {
      if (search.length) {
        loading(true);
        this.searchStatus(loading, search, this);
      }
    },
    searchStatus: _.debounce(async (loading, search, vm) => {
      vm.allStatus = await vm.getClientes(search);
      loading(false);
    }, 350),
    setStatus(status) {
      this.form.status = status;
      this.statusFilled = true;
    },
  },
  created() {
    this.getDataInit();
  },
  computed: {
    ...mapGetters([
      "isAuthenticated",
      "breadcrumbs",
      "pageTitle",
      "layoutConfig",
    ]),
    ...mapState(["loading"]),
    isLoading() {
      return this.loading.loading;
    },
    isTheCurrentTab() {
      return store.currentTab === 3;
    },
  },
  watch: {
    "store.formInformacoes"(newValue, oldValue) {
      if (JSON.stringify(newValue) !== JSON.stringify(oldValue)) {
        this.keyToReRender++;
      }
    },
    form(newValue) {
      this.store.formInformacoes = newValue;
    },
  },
};
</script>
