

import { reactive } from 'vue';

export const priceOptions = reactive({
  decimal: ",",
  thousands: ".",
  prefix: "",
  suffix: "",
  precision: 2,
  masked: false,
});

export const quantityOptions = reactive({
  decimal: ",",
  thousands: ".",
  prefix: "",
  suffix: "",
  precision: 2,
  masked: false,
});
