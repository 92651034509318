<template>
  <div style="position: relative" class="container-fluid">
    <div class="card-body">
      <b-card>
        <b-tabs pills :horizontal="windowWidth < 768" :vertical="windowWidth > 768"
          :nav-wrapper-class="(windowWidth > 768 ? 'w-25' : 'w-100')" fill>
          <DadosGerais />
          <Veiculos />
          <Carga />
          <Viagem />
          <Documentos />
          <Seguro />
          <Pedagio />
        </b-tabs>
        <div class="card-footer">
          <div class="row">
            <div class="col-lg-12" style="text-align: center">
              <div v-if="canSaveNfe">
                <div class="form mb-5">
                  <button class="btn btn-secondary font-weight-bold mr-4" @click="handlePrevious">
                    Anterior
                  </button>
                  <button ref="submit_nova_mdfe" class="btn btn-primary font-weight-bold" @click="salvarMdfe(false)">
                    Salvar
                  </button>
                  <button v-b-modal.modalTransmitirNfe @click="salvarMdfe(true)"
                    class="btn btn-secondary font-weight-bold mx-2" ref="transmitir_nova_mdfe">
                    Transmitir
                  </button>
                  <router-link class="btn btn-clean font-weight-bold" :to="{
                    name: 'mdfe',
                  }">
                    Cancelar
                  </router-link>
                </div>
              </div>
              <div v-else>
                <button :disabled="store.currentTab === 0" class="btn btn-secondary font-weight-bold mr-4"
                  @click="handlePrevious">
                  Anterior
                </button>
                <button class="btn btn-primary font-weight-bold" @click="handleNext">
                  Proximo
                </button>
                <router-link class="btn btn-clean font-weight-bold" :to="{
                  name: 'mdfe',
                }" @click="() => { store.dataFetched = false }">
                  Cancelar
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </b-card>
    </div>

  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { SistemaMixin } from "@/modules/Application";
import DadosGerais from "./componentes/DadosGeraisTab.vue";
import Veiculos from "./componentes/VeiculosTab.vue";
import Carga from "./componentes/CargaTab.vue";
import Viagem from "./componentes/ViagemTab.vue";
import Seguro from "./componentes/SeguroTab.vue";
import Pedagio from "./componentes/PedagioTab.vue";
import { store } from "../storeLogistica";
import Documentos from "./componentes/DocumentosTab.vue";

export default {
  name: "mdfe-form",
  mixins: [SistemaMixin],
  components: {
    DadosGerais,
    Veiculos,
    Carga,
    Viagem,
    Documentos,
    Seguro,
    Pedagio,
  },
  data() {
    return {
      windowWidth: window.innerWidth,
      store,
      mdfeId: null,
    };
  },
  created() { },
  methods: {
    onResize() {
      this.windowWidth = window.innerWidth;
    },
    handleNext() {
      if (this.store.currentTab === 6) return;
      this.store.currentTab++;
    },
    handlePrevious() {
      if (this.store.currentTab === 0) return;
      this.store.currentTab--;
    },
    async salvarMdfe(transmitir) {
      this.addLoadingToButton(transmitir ? 'transmitir_nova_mdfe' : 'submit_nova_mdfe');
      if (Object.keys(this.$route.params).length !== 0 || this.mdfeId) {
        const id_mdfe = this.$route.params?.mdfeData?.id_mdfe;
        this.mdfeId = id_mdfe ?? this.mdfeId;
        try {
          await this.$http.post("/mdfe/update", {
            dados: {
              ...this.store.formData,
              id_mdfe: this.mdfeId,
            },
          });
          this.$toast.success("MDFe editado com sucesso!");
        } catch (error) {
          this.$toast.error("Erro ao editar MDFe!");
          this.removeLoadingToButton(transmitir ? 'transmitir_nova_mdfe' : 'submit_nova_mdfe');
          return;
        }
      } else {
        try {
          const { data } = await this.$http.post("/mdfe/create", {
            dados: { ...this.store.formData },
          });
          this.mdfeId = data.id_mdfe;
          this.$toast.success("MDFe salvo com sucesso!");
        } catch (error) {
          console.log(error);
          this.$toast.error("Erro ao salvar MDFe");
          this.removeLoadingToButton(transmitir ? 'transmitir_nova_mdfe' : 'submit_nova_mdfe');
          return;
        }
      }
      if (transmitir) {
        try {
          const { data } = await this.$http.post('/mdfe/transmissao', {
            id_mdfe: this.mdfeId,
          });
          console.log(data);
          this.$toast.success("MDFe transmitido com sucesso!");
          this.$toast.warning("Gerando PDF do MDFe!");
          window.open(data['mdfe-url'], "_blank");
          this.$router.push({ name: 'mdfe' })
        } catch (error) {
          console.log(error);
        }
      } else {
        this.store.currentTab = 0;
        this.$router.push({ name: "mdfe" });
      }
      this.removeLoadingToButton(transmitir ? 'transmitir_nova_mdfe' : 'submit_nova_mdfe');
    },
  },
  computed: {
    ...mapGetters(["layoutConfig", "itsblocked"]),
    config() {
      return this.layoutConfig();
    },
    canSaveNfe() {
      return this.store.currentTab === 6;
    },
  },
  async mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
    });
  },
};
</script>

<style></style>
