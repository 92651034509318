import { required } from "vuelidate/lib/validators";

export default class InformacoesData {
  data_solicitacao = null;
  status = null;
  observacao = "";
  constructor(
    data_solicitacao = null,
    status = null,
    observacao = "",
  ) {
    this.data_solicitacao = data_solicitacao;
    this.status = status;
    this.observacao = observacao;
  }
}

export const InformacoesDataRequired = {
  data_solicitacao: {
    required,
  },
  status: {
    required,
  },
};
