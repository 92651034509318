<template>
  <fragment>
    <Label
      v-bind="labelProps"
      :html-for="id"
      :bold="labelBold"
      v-if="label"
      :disabled="disabled"
    >
      {{ label }}
    </Label>
    <b-form-textarea
      class="input"
      :class="inputClass"
      :id="id"
      @input="updateValue"
      :value="value"
      :placeholder="placeholder"
      :disabled="disabled"
      :maxlength="maxlength"
      :type="type"
      v-on:keyup.enter="handleOnEnter"
    />
    <div
      class="input-description input-margin"
      v-if="description"
      :class="descriptionClass"
    >
      {{ description }}
    </div>
  </fragment>
</template>

<script>
import Label from "./Label";

export default {
  name: "TextArea",
  components: {
    Label
  },
  props: {
    id: {
      type: String,
      required: true
    },
    value: {},
    label: {
      type: String
    },
    type: {
      type: String,
      default: () => "text"
    },
    maxlength: {
      type: Number
    },
    labelBold: {
      type: Boolean,
      default: () => false
    },
    labelProps: {
      type: Object
    },
    placeholder: {
      type: String
    },
    description: {
      type: String
    },
    success: {
      type: Boolean,
      default: () => false
    },
    error: {
      type: Boolean,
      default: () => false
    },
    disabled: {
      type: Boolean,
      default: () => false
    },
    onEnter: {
      type: Function,
      default: () => {}
    }
  },
  methods: {
    handleOnEnter() {
      this.onEnter();
    },
    updateValue(value) {
      this.$emit("input", value);
    }
  },
  computed: {
    inputClass() {
      return {
        "input-margin": !this.description,
        success: this.success,
        error: this.error
      };
    },
    descriptionClass() {
      return {
        success: this.success,
        error: this.error
      };
    }
  }
};
</script>
