import { required } from "vuelidate/lib/validators";

export default class BancosData {
  nome = "";

  banco = null;

  agencia = "";

  conta = "";

  beneficiario = "";

  tipo = "checking";

  constructor({
    nome = "",
    banco = null,
    agencia = "",
    conta = "",
    beneficiario = "",
    tipo = "checking"
  }) {
    this.nome = nome;
    this.banco = banco;
    this.agencia = agencia;
    this.conta = conta;
    this.beneficiario = beneficiario;
    this.tipo = tipo;
  }
}

export const BancosDataRequired = {
  nome: {
    required,
  },
  agencia: {
    required,
  },
  conta: {
    required,
  },
  beneficiario: {
    required,
  },
};
