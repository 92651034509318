<template>
  <div class="card card-custom gutter-b" style="box-shadow: none">
    <div class="card-header py-5">
      <h3 class="card-title align-items-start flex-column">
        <span class="card-label font-weight-bolder text-dark">
          {{ pageTitle }}
        </span>
      </h3>
    </div>
    <div class="card-body">
      <b-row>
        <b-col md="6">
          <b-form-group label="Descriçao do kit" label-for="kitName">
            <b-form-input v-model="kitName" />
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group label="Observação" label-for="kitObservacao">
            <b-form-input v-model="kitObservacao" />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row align-v="center">
        <b-col md="6">
          <b-form-group label="Produto" label-for="name">
            <VueSelect
              ref="buscarProduto"
              :filterable="false"
              @input="setProduto"
              :options="produtosFetched"
              @search="onSearchProduto"
              v-model="produtoSelected"
            >
              <template slot="no-options">
                Informe o produto pelo nome
              </template>
              <template slot="option" slot-scope="option">
                <div class="d-flex justify-content-between">
                  <div>{{ option.codigo }} - {{ option.descricao }}</div>
                  <span class="text-muted">QTD: {{ option.quantidade }}</span>
                </div>
              </template>
              <template slot="selected-option" slot-scope="option">
                <div class="selected d-center">
                  {{ option.codigo }} - {{ option.descricao }}
                </div>
              </template>
            </VueSelect>
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col :cols="windowWidth > 768 ? 0 : 6">
          <b-form-group label="Quantidade" label-for="quantidade">
            <money
              class="form-control"
              ref="quantidade"
              id="quantidade"
              name="quantidade"
              v-model="quantidade"
              v-bind="quantityOptions"
              v-on:keyup.native.enter="handleEnter('preco')"
            />
          </b-form-group>
        </b-col>
        <b-col :cols="windowWidth > 768 ? 0 : 6">
          <b-form-group label="Unitário" label-for="preco">
            <money
              class="form-control"
              ref="preco"
              id="preco"
              name="preco"
              v-model="preco"
              v-bind="priceOptions"
              v-on:keyup.native.enter="addProduto(produtoSelected)"
            />
          </b-form-group>
        </b-col>
        <b-col :cols="windowWidth > 768 ? 0 : 6">
          <b-form-group label="Total R$" label-for="total">
            <money
              disabled
              class="form-control"
              id="total"
              name="total"
              v-model="total"
              v-bind="priceOptions"
            />
          </b-form-group>
        </b-col>
        <b-col>
          <b-form-group label-for="add">
            <button @click="addProduto(produtoSelected)">
              <Plus
                class="btn btn-sm btn-info btn-hover-info btn-icon mr-2 mt-9"
                v-if="windowWidth > 768"
              />
              <button
                v-else
                class="btn btn-info btn-hover-dark font-weight-bolder font-size-lg ml-2"
              >
                Adicionar produto
              </button>
            </button>
          </b-form-group>
        </b-col>
      </b-row>

      <TableApp
        item="produto"
        subTitulo=""
        :data="produtos"
        :fields="fields"
        classFilterColumn="nome"
        ref="nome"
      >
        <template slot="customRow" scope="props">
          <span slot="item" class="pl-0 pt-8" v-if="props.field == 'item'">
            <div class="d-flex align-items-center">
              <div class="font-weight-bold text-muted">
                {{ props.rowData.originalIndex + 1 }} /
                {{ props.rowData.product.codigo }}
              </div>
            </div>
          </span>
          <span slot="nome" class="pl-0 pt-8" v-if="props.field == 'descricao'">
            <div class="d-flex align-items-center">
              <div class="font-weight-bold text-muted">
                {{ props.rowData.product.descricao }}
                <span
                  v-b-tooltip.hover.right="
                    'Item atendeu a regra de preço de atacado'
                  "
                  v-if="props.rowData.product.tipo_atacado"
                >
                  <InformationOutline :size="14" />
                </span>
              </div>
            </div>
          </span>
          <span
            slot="quantidade"
            class="pl-0 pt-8"
            v-if="props.field == 'quantidade'"
          >
            <div class="d-flex align-items-center">
              <div class="font-weight-bold text-muted">
                {{
                  numberToReal(props.rowData.product.quantidade, "quantidade")
                }}
              </div>
            </div>
          </span>
          <span slot="preco" class="pl-0 pt-8" v-if="props.field == 'preco'">
            <div class="d-flex align-items-center">
              <div class="font-weight-bold text-muted">
                {{ numberToReal(props.rowData.product.preco, "preco") }}
              </div>
            </div>
          </span>

          <span
            slot="total"
            v-if="props.field == 'total'"
            class="text-dark-75 font-weight-bolder d-block font-size-lg"
          >
            R$
            {{
              numberToReal(
                props.rowData.product.quantidade * props.rowData.product.preco,
                "preco"
              )
            }}
          </span>
          <span slot="actions" v-if="props.field == 'actions'">
            <div class="row-actions" style="text-align: right !important">
              <span style="overflow: visible; position: relative; width: 130px">
                <div class="action-buttons">
                  <button
                    @click="
                      openModalProduto(
                        props.rowData,
                        props.rowData.originalIndex
                      )
                    "
                    class="btn btn-sm btn-info btn-hover-info btn-icon mr-2"
                  >
                    <Pencil :size="16" title="Editar" />
                  </button>
                  <button
                    @click="handleDeleteProduto(props.rowData)"
                    class="btn btn-sm btn-danger btn-hover-danger btn-icon mr-2"
                  >
                    <TrashCanIcon :size="16" />
                  </button>
                </div>
              </span>
            </div>
          </span>
        </template>
      </TableApp>
      <b-row v-if="produtos.length > 0">
        <b-col></b-col>
        <b-col></b-col>
        <b-col
          ><span class="text-primary font-weight-bolder">Total Itens:</span>
          {{
            numberToReal(
              produtos.reduce(
                (acc, produto) => acc + produto.product.quantidade,
                0
              ),
              "quantidade"
            )
          }}</b-col
        >
        <b-col
          ><span class="text-primary font-weight-bolder">Total R$:</span
          >{{
            numberToReal(
              produtos.reduce((acc, produto) => acc + produto.totalPrice, 0),
              "preco"
            )
          }}</b-col
        >
      </b-row>
    </div>

    <div class="card-footer">
      <div class="row">
        <div class="col-lg-12" style="text-align: center">
          <button
            type="submit"
            ref="submit-kit"
            @click="onSubmit"
            class="btn btn-primary font-weight-bold"
          >
            Salvar
          </button>
          <router-link
            class="btn btn-clean font-weight-bold"
            :to="{
              name: 'kit',
            }"
          >
            Cancelar
          </router-link>
        </div>
      </div>
    </div>
    <ModalProduto
      :close="closeModalProduto"
      :changeProduto="saveEditProduto"
      :produto="produtoToEdit"
      :open="modalProdutoIsOpen"
      v-if="modalProdutoIsOpen"
    />
  </div>
</template>

<script>
import VueSelect from "vue-select";
import {
  MONEY as settingMoney,
  priceOptions,
  quantityOptions,
} from "@/modules/Application";
import { SistemaMixin, ValidacaoMixin } from "@/modules/Application";
import { Money } from "v-money";
import { validationMixin } from "vuelidate";
import Pencil from "vue-material-design-icons/Pencil.vue";
import InformationOutline from "vue-material-design-icons/InformationOutline.vue";
import TrashCanIcon from "vue-material-design-icons/TrashCan.vue";
import Plus from "vue-material-design-icons/PlusThick.vue";
import TableApp from "@/components/Table/Table3.vue";
import _ from "lodash";
import ModalProduto from "./components/ModalProduto.vue";

export default {
  name: "app-kit-create",
  components: {
    VueSelect,
    ModalProduto,
    TableApp,
    Pencil,
    Plus,
    TrashCanIcon,
    Money,
    InformationOutline,
  },
  data() {
    return {
      kitId: null,
      produtosFetched: [],
      windowWidth: window.innerWidth,
      kitName: null,
      kitObservacao: null,
      produtoToEdit: null,
      modalProdutoIsOpen: false,
      configMask: settingMoney,
      priceOptions,
      quantityOptions,
      quantidade: 1,
      preco: 0,
      total: 0,
      produtoSelected: null,
      estoqueProduto: 0,
      dataSendToApi: {},
      produtoError: false,
      produtoFilled: false,
      modalProdutoImpostosIsOpen: false,
      produtoToImpostos: null,
      produtos: [],
      fields: [
        {
          label: "Item/Cód",
          field: "item",
          slot: true,
          sortable: false,
          width: "5%",
          filterOptions: {
            enabled: false,
          },
        },
        {
          label: "Nome",
          field: "descricao",
          slot: true,
          sortable: false,
          width: "40%",
          filterOptions: {
            enabled: false,
          },
        },
        {
          label: "Qtd",
          field: "quantidade",
          slot: true,
          sortable: false,
          width: "8%",
        },
        {
          label: "Unitário",
          field: "preco",
          sortable: false,
          slot: true,
          width: "10%",
        },
        {
          label: "Total",
          field: "total",
          sortable: false,
          slot: true,
          width: "20%",
        },
        {
          label: "",
          field: "actions",
          sortable: false,
          slot: true,
          width: "10%",
        },
      ],
    };
  },
  mixins: [validationMixin, SistemaMixin, ValidacaoMixin],
  methods: {
    handleEnter(ref) {
      if (this.fromSelect) {
        this.fromSelect = false;
        return;
      }
      if (!this.$refs[ref]) return;
      this.$refs[ref].$el.focus();
    },
    closeModalProduto() {
      this.modalProdutoIsOpen = false;
    },
    openModalProduto(produtoData, currentIndex) {
      this.modalProdutoIsOpen = true;
      this.produtoToEdit = {
        ...produtoData.product,
        currentIndex,
      };
    },
    async saveEditProduto(newEditedProduto) {
      const aux = _.cloneDeep(this.produtos);
      aux.splice(newEditedProduto.currentIndex, 1, {
        ...this.produtos[newEditedProduto.currentIndex],
        product: newEditedProduto,
        preco: newEditedProduto.preco,
        quantidade: newEditedProduto.quantidade,
        totalPrice: newEditedProduto.preco * newEditedProduto.quantidade,
        total: newEditedProduto.preco * newEditedProduto.quantidade,
      });
      this.produtos = aux;
    },
    resetInputs() {
      this.quantidade = 1;
      this.preco = 0;
      this.total = 0;
    },
    async onSubmit() {
      if (!this.kitName) return this.$toast.error("Insira a descrição");
      if (this.produtos.length === 0)
        return this.$toast.error("Insira ao menos um produto");
      this.addLoadingToButton("submit-kit");
      try {
        await this.$http.post(!this.kitId ? "/kit/create" : "/kit/update", {
          nome: this.kitName,
          produtos: this.produtos.map((produto) => ({
            product: produto.product,
          })),
          ...(this.kitId && { id_kit: this.kitId }),
          observacao: this.kitObservacao,
          valor_total: this.produtos.reduce(
            (acc, produto) => acc + produto.totalPrice,
            0
          ),
        });
        this.removeLoadingToButton("submit-kit");
        this.$router.push({ name: "kit" });
      } catch (error) {
        console.log(error);
        this.removeLoadingToButton("submit-kit");
      }
    },
    handleDeleteProduto(item) {
      this.$swal
        .fire({
          icon: "warning",
          title: "Excluir o produto " + item.product.descricao + "?",
          showCancelButton: true,
          confirmButtonText: `Sim`,
          cancelButtonText: `Não`,
          confirmButtonColor: "#f64e60",
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            const aux = this.produtos
              .filter((produto, index) => index !== item.originalIndex)
              .map((item, index) => ({
                ...item,
                product: { ...item.product, currentIndex: index },
              }));
            this.produtos = _.cloneDeep(aux);
          }
        });
    },
    async addProduto(produto) {
      if (_.isEmpty(produto)) return;
      const produtoFormatted = {
        ...produto,
        preco: this.preco,
        quantidade: this.quantidade,
        totalPrice: this.preco * this.quantidade,
        total: this.preco * this.quantidade,
      };
      this.produtos = [
        ...this.produtos,
        {
          product: produtoFormatted,
          active: true,
          currentIndex: this.produtos.length,
          totalPrice: this.preco * this.quantidade,
          total: this.preco * this.quantidade,
        },
      ];
      this.produtoError = false;
      this.$nextTick(() => {
        this.$refs.buscarProduto.$refs.search.focus();
      });
      this.produtoSelected = null;
      this.resetInputs();
    },
    onResize() {
      this.windowWidth = window.innerWidth;
    },
    setProduto(produto) {
      if (!produto) return;
      this.descricao = produto.descricao;
      this.estoqueProduto = produto.quantidade;
      const preco_atacado = produto?.preco_atacado ?? 0;
      const qtd_atacado = produto?.quantidade_atacado ?? 0;
      this.quantidade = 1;

      const isAtacadoValue =
        this.quantidade >= qtd_atacado && preco_atacado > 0;

      if (isAtacadoValue) {
        this.preco = parseFloat(preco_atacado);
      } else this.preco = parseFloat(produto.preco);

      this.total = this.quantidade * parseFloat(this.preco);
      this.produtoSelected = {
        ...produto,
        preco: this.preco,
        quantidade: this.quantidade,
        tipo_atacado: isAtacadoValue,
        totalPrice: this.total,
        total: this.total,
      };
      this.produtoSelected.total = this.total;

      this.produtoFilled = true;
      this.fromSelect = true;
      this.$nextTick(() => {
        this.$refs.quantidade.$el.focus();
      });
    },
    onSearchProduto(search, loading) {
      if (search.length > 2 || /^[0-9]+$/.test(search)) {
        loading(true);
        this.searchProdutos(loading, search, this);
      }
    },
    searchProdutos: _.debounce(async (loading, search, vm) => {
      vm.produtosFetched = await vm.fetchProdutos(search, vm.typeFilter);
      loading(false);
    }, 350),
  },
  watch: {
    preco(newValue, oldValue) {
      if (oldValue !== newValue) {
        this.total = newValue * this.quantidade;
      }
    },
    quantidade(newValue, oldValue) {
      if (oldValue !== newValue) {
        const isAtacado =
          newValue >= this.produtoSelected.quantidade_atacado &&
          this.produtoSelected.preco_atacado > 0;

        if (
          this.preco === 0 ||
          this.preco == this.produtoSelected.preco_atacado ||
          this.preco == this.produtoSelected.preco
        )
          this.preco = isAtacado
            ? this.produtoSelected.preco_atacado
            : this.produtoSelected.preco;

        this.total = newValue * parseFloat(this.preco);
        this.produtoSelected.total = this.total;
        this.produtoSelected.tipo_atacado = isAtacado;
        this.produtoSelected.quantidade = newValue;
      }
    },
  },
  created() {
    if (this.$route.params?.kitData.id_kit) {
      const kitData = this.$route.params?.kitData;
      this.kitId = kitData.id_kit;
      this.kitName = kitData.nome;
      this.kitObservacao = kitData.observacao;
      this.produtos = kitData.produtos;

      this.produtos = this.produtos.map((produto) => {
        this.total =
          produto.product.quantidade * parseFloat(produto.product.preco);

        const produtoFormatted = {
          ...produto.product,
          preco: produto.product.preco,
          quantidade: produto.product.quantidade,
          desconto: produto.product.desconto,
          totalPrice: this.total,
        };
        return {
          product: produtoFormatted,
          preco: produto.product.preco,
          quantidade: produto.product.quantidade,
          totalPrice: this.total,
          currentIndex: produto.currentIndex,
        };
      });
    }
  },
  computed: {
    pageTitle() {
      return !this.kitId ? "Novo Kit" : "Editar Kit";
    },
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
    });
  },
};
</script>
