<template>
  <Select
    placeholder="Informe o Nome/CPF/CNPJ"
    labelBusca="name"
    ref="buscarCliente"
    inputClass="busca-cliente"
    :label="label"
    :value="client"
    :options="clients"
    :change="change"
    :search="searchCliente"
    :disabled="disabled"
  >
    <template slot="options" slot-scope="props">
      <div class="selected" style="text-transform: uppercase;">
        {{ props.data.name }}
        <br />
        CPF/CNPJ {{ props.data.identification }}
      </div>
    </template>
  </Select>
</template>

<script>
import "vue-select/dist/vue-select.css";
import _ from "lodash";
import { Select } from "./index";

export default {
  name: "SelectClient",
  components: {
    Select
  },
  props: {
    label: {
      type: String,
      default: "Cliente"
    },
    client: {
      type: Object,
      default: null
    },
    change: {
      type: Function,
      default: () => null
    },
    disabled: {
      type: Boolean,
      default: () => false
    }
  },
  data() {
    return {
      clients: []
    };
  },
  methods: {
    searchCliente(search, loading) {
      loading(true);
      this.getClient(loading, search, this);
    },
    getClient: _.debounce(async (loading, search, vm) => {
      if (search === "" || search === null) {
        return loading(false);
      }

      const $this = vm;

      const { data } = await $this.$http.post("/person.get-person", {
        search,
        active: true,
        isClient: true,
        maxResults: 10
      });

      $this.clients = data;
      return loading(false);
    }, 350)
  }
};
</script>
