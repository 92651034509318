<template>
  <Modal :value="open" :onClose="close" :close="false">
    <b-row class="form-group">
      <b-col class="busca" md="12">
        <b-form-group label="Descrição" label-for="discriminacao">
          <v-textarea
            full-width
            no-resize
            rows="3"
            auto-grow
            maxlength="200"
            v-model="valueServico.discriminacao"
          ></v-textarea>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row class="form-group">
      <b-col>
        <b-form-group label="Quantidade" label-for="quantidade">
          <money
            class="form-control"
            id="quantidade"
            name="quantidade"
            v-model="valueServico.quantidade"
            v-bind="quantityOptions"
          />
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group label="Valor Unitário" label-for="unitario">
          <money
            class="form-control"
            id="unitario"
            name="unitario"
            v-model="valueServico.unitario"
            v-bind="priceOptions"
          />
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group label="Total R$" label-for="total">
          <money
            class="form-control"
            id="total"
            name="total"
            v-model="valueServico.total"
            v-bind="priceOptions"
            disabled
          />
        </b-form-group>
      </b-col>
    </b-row>
    <div class="confirm-buttons">
      <Button type="error" @click="close()">Fechar</Button>
      <Button type="success" @click="save()">Gravar</Button>
    </div>
  </Modal>
</template>

<script>
import { Modal } from "@/components";
import { Button } from "@/components/Form";
import { Money } from "v-money";
import { priceOptions, quantityOptions } from "@/modules/Application";
import { SistemaMixin } from "@/modules/Application";
import FormatMixin from "@/modules/Application/mixins/FormatMixin";
import PessoaMixin from "@/modules/Application/mixins/PessoaMixin";
import "vue-select/dist/vue-select.css";

export default {
  name: "ModalServico",
  mixins: [FormatMixin, PessoaMixin, SistemaMixin],
  components: {
    Modal,
    Button,
    Money,
  },
  data() {
    return {
      valueServico: {},
      priceOptions,
      quantityOptions,
    };
  },
  props: {
    open: {
      type: Boolean,
      default: false,
    },
    close: {
      type: Function,
      required: true,
    },
    changeServico: {
      type: Function,
      required: true,
    },
    servico: {
      type: Object,
      required: true,
    },
  },
  watch: {
    servico(newValue) {
      this.valueServico = newValue;
    },
  },
  mounted() {
    console.log();
    this.valueServico = this.servico;
    this.getDataInit();
  },
  methods: {
    save() {
      this.valueServico.total =
        this.valueServico.unitario * this.valueServico.quantidade;
      this.changeServico(this.valueServico);
      this.$toast.success("Serviço editado com sucesso!");
    },
    async getDataInit() {},
  },
  computed: {
    totalServico() {
      return this.valueServico.quantidade * this.valueServico.unitario;
    },
  },
};
</script>
