import comprasLista from "@/modules/Compras/pages/Index";
import comprasNovo from "@/modules/Compras/pages/Create";
import comprasEditar from "@/modules/Compras/pages/Update";
import outrosLancamentos from "@/modules/Compras/pages/Others";

export default [
  {
    name: "comprasLista",
    path: "/compras",
    component: comprasLista,
    meta: { title: "Compra" },
  },
  {
    name: "comprasNovo",
    path: "/compra-nova",
    component: comprasNovo,
    meta: { title: "Compra" },
  },
  {
    name: "comprasEditar",
    path: "/compras/:id/editar",
    component: comprasEditar,
    meta: { title: "Compra" },
  },
  {
    name: "comprasOutros",
    path: "/compras/outros-lancamentos",
    component: outrosLancamentos,
    meta: { title: "Compra" },
  },
];
