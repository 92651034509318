import { required } from "vuelidate/lib/validators";

export default class MotoristaData {
  nome = null;
  cpf = null;
  constructor(
    nome = null,
    cpf = null,
  ) {
    this.nome = nome;
    this.cpf = cpf;
  }
}

export const MotoristaDataRequired = {
  nome: {
    required,
  },
  cpf: {
    required,
  },
};
