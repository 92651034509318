<template>
  <Form url="/bancos/create" :banco="{}" />
</template>

<script>
import Form from "./Form.vue";

export default {
  name: "app-banco-create",
  components: {
    Form,
  },
  mounted() {},
};
</script>
