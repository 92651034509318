var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{style:(_vm.cssProps)},[_c('div',{staticClass:"card card-custom card-stretch gutter-b"},[(_vm.titulo != null)?_c('div',{staticClass:"card-header border-0 py-5"},[_c('h3',{staticClass:"card-title align-items-start flex-column"},[_c('span',{staticClass:"card-label font-weight-bolder text-dark"},[_vm._v(_vm._s(_vm.titulo))])]),_c('div',{staticClass:"card-toolbar"},[(_vm.licencas)?_c('span',{staticClass:"card-label font-weight-bolder text-dark",staticStyle:{"margin-right":"20px"}},[_vm._v("Total de assinaturas disponíveis: "+_vm._s(_vm.licencas))]):_vm._e(),(_vm.secondaryButtonUrl != null)?_c('a',{staticClass:"btn btn-dark font-weight-bolder font-size-sm mr-2",staticStyle:{"margin-left":"5px"},attrs:{"href":_vm.secondaryButtonUrl}},[_vm._v(_vm._s(_vm.secondaryButtonText))]):_vm._e(),(_vm.acaoBtn != null)?_c('a',{staticClass:"btn btn-dark font-weight-bolder font-size-sm",attrs:{"href":_vm.acaoBtn}},[_vm._v(_vm._s(_vm.textoBtn))]):_vm._e(),_c('button',{staticClass:"btn btn-dark font-weight-bolder font-size-sm ml-2",on:{"click":_vm.printData}},[_c('IconPrint',{attrs:{"size":18}})],1),(_vm.acaoBtnTwo != null)?_c('a',{staticClass:"btn btn-dark font-weight-bolder font-size-sm",staticStyle:{"margin-left":"5px"},attrs:{"href":_vm.acaoBtnTwo}},[_vm._v(_vm._s(_vm.textoBtnTwo))]):_vm._e()])]):_vm._e(),_c('div',{staticClass:"card-body pt-0 pb-3"},[_c('div',{staticClass:"tab-content"},[_c('div',{staticClass:"table-responsive"},[_c('vue-good-table',{key:_vm.tableKey,ref:"table-custom",attrs:{"mode":"remote","theme":"polar-bear","row-style-class":_vm.customRowStyle,"styleClass":"vgt-table table-striped","select-options":{
              enabled: _vm.multiselect,
              selectionInfoClass: 'selectionTableClass',
              selectionText: 'linhas selecionadas',
              clearSelectionText: 'limpar',
            },"totalRows":_vm.totalRecords,"isLoading":_vm.isLoading,"sort-options":_vm.sortOptions,"pagination-options":{
              mode: 'pages',
              enabled: true,
              nextLabel: 'Próximo',
              prevLabel: 'Voltar',
              rowsPerPageLabel: '',
              ofLabel: 'de',
              allLabel: 'Todos',
              dropdownAllowAll: false,
              setCurrentPage: _vm.filtersStore[_vm.moduleName].page,
              perPageDropdown: [10, 20, 30, 40, 50, 100],
              perPage: _vm.filtersStore[_vm.moduleName].per_page,
              infoFn: (params) => '',
            },"rows":_vm.data,"columns":_vm.fields},on:{"on-page-change":_vm.onPageChange,"on-sort-change":_vm.onSortChange,"on-column-filter":_vm.onColumnFilter,"on-per-page-change":_vm.onPerPageChange,"on-selected-rows-change":_vm.selectionChanged,"update:isLoading":function($event){_vm.isLoading=$event},"update:is-loading":function($event){_vm.isLoading=$event}},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [_vm._v(" "+_vm._s(props.slot)+" "),(props.column.slot)?[_vm._t("customRow",null,{"rowData":props.row,"field":props.column.field})]:[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")]]}}],null,true)},[_c('template',{slot:"loadingContent"},[_c('Loading')],1),_c('template',{slot:"emptystate"},[_c('div',{staticClass:"vgt-center-align vgt-text-disabled"},[_vm._v(" Nenhum registro encontrado ")])])],2)],1)])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }