var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"card-body"},[_c('b-card',[_c('div',{staticClass:"d-flex justify-content-between"},[_c('div',[_c('h5',{staticClass:"card-title align-items-start flex-column mb-2"},[_c('span',{staticClass:"card-label font-weight-bolder text-dark"},[_vm._v(" Cliente: ")]),_c('span',{staticClass:"card-label text-dark"},[_vm._v(" "+_vm._s(_vm.cliente.nome)+" ")])]),_c('h5',{staticClass:"card-title align-items-start flex-column mb-2"},[_c('span',{staticClass:"card-label font-weight-bolder text-dark"},[_vm._v(" Valor do orçamento: ")]),_c('span',{staticClass:"card-label text-dark"},[_vm._v(" R$ "+_vm._s(_vm.numberToReal(_vm.orcamento.valorTotal, 2))+" ")])]),_c('h5',{staticClass:"card-title align-items-start flex-column mb-20"},[_c('span',{staticClass:"card-label font-weight-bolder text-dark"},[_vm._v(" Orçamento número: ")]),_c('span',{staticClass:"card-label text-dark"},[_vm._v(" "+_vm._s(_vm.orcamento.numero)+" ")])])]),_c('div',[_c('button',{staticClass:"text-primary font-weight-bolder h6",on:{"click":_vm.goBackToOrcamentoList}},[_vm._v(" Voltar ")])])]),_vm._l((_vm.timelineItems),function(item){return _c('vue-timeline-update',{key:item.name,attrs:{"date":new Date(item.date),"description":`${item.label} em ${_vm.$moment(item.date).format(
          'DD/MM/YYYY'
        )} as ${item.time} ${
          item.label === 'Respondido'
            ? `, Contato: ${item.contato} ${
                item.observacao ? '- ' + item.observacao : ''
              }`
            : ''
        } `,"category":item.name,"color":item.color,"icon":"code"}})})],2)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }