export default class ImpostosData {
  baseICMS = 0;
  valorICMS = 0;
  baseICMSST = 0;
  valorICMSST = 0;
  valorIPI = 0;
  vFCPST = 0;
  totalProd = 0;
  ICMSdesoneracao = 0;
  totalNFE = 0;
  freightPrice = 0;
  insurance = 0;
  extraValue = 0;
  discount = 0;
  constructor(
    baseICMS = 0,
    valorICMS = 0,
    baseICMSST = 0,
    valorICMSST = 0,
    valorIPI = 0,
    ICMSdesoneracao = 0,
    totalProd = 0,
    totalNFE = 0,
    vFCPST = 0,
    freightPrice = 0,
    insurance = 0,
    extraValue = 0,
    discount = 0,
  ) {
    this.baseICMS = baseICMS;
    this.valorICMS = valorICMS;
    this.baseICMSST = baseICMSST;
    this.valorICMSST = valorICMSST;
    this.valorIPI = valorIPI;
    this.ICMSdesoneracao = ICMSdesoneracao;
    this.totalProd = totalProd;
    this.totalNFE = totalNFE;
    this.vFCPST = vFCPST;
    this.freightPrice = freightPrice;
    this.insurance = insurance;
    this.extraValue = extraValue;
    this.discount = discount;
  }
}

