<template>
  <b-tab title="Observações" @click="() => store.currentTab = 6" :active="isTheCurrentTab" :disabled="!isTheCurrentTab && !store.isUpdate" class="mt-5">
    <b-row>
      <b-col>
        <b-form-group label="Observação Interna" label-for="observacaoInterna">
          <v-textarea full-width rows="6" v-model="form.observacaoInterna" />
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-form-group label="Observação" label-for="observacao">
          <v-textarea full-width rows="6" v-model="form.observacao" />
        </b-form-group>
      </b-col>
    </b-row>
  </b-tab>
</template>

<script>
import { mapGetters, mapState, mapActions } from "vuex";
import "vue-select/dist/vue-select.css";
import { validationMixin } from "vuelidate";
import { SistemaMixin, ValidacaoMixin } from "@/modules/Application";
import _ from "lodash";
import { store } from "../../storeOS";

export default {
  name: "app-os-form-observacao-tab",
  data() {
    return {
      store,
      form: {
        observacao: null,
        observacaoInterna: null,
      }
    }
  },
  mixins: [
    validationMixin,
    SistemaMixin,
    ValidacaoMixin,
  ],
  watch: {
    form: {
      handler(value) {
        this.store.formObservacoes = value;
      },
      deep: true
    }
  },
  methods: {
    ...mapActions(["setLoading"]),
    async getDataInit() {
      if (Object.keys(this.$route.params).length !== 0) {
        const { observacao, observacaoInterna } = this.$route.params.osData;
        this.form.observacao = observacao;
        this.form.observacaoInterna = observacaoInterna;
      }
    },
  },
  created() {
    this.getDataInit();
  },
  computed: {
    ...mapGetters([
      "isAuthenticated",
      "breadcrumbs",
      "pageTitle",
      "layoutConfig"
    ]),
    ...mapState(["loading"]),
    isLoading() {
      return this.loading.loading;
    },
    transportadorName() {
      return JSON.stringify(store.formTransportador?.nome) === '{}' ? null : store.formTransportador
    },
    isTheCurrentTab() {
      return store.currentTab === 6
    }
  }
}

</script>