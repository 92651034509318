<template>
  <div :style="cssProps">
    <div class="card card-custom card-stretch gutter-b">
      <div class="card-header border-0 py-5" v-if="titulo != null">
        <h3 class="card-title align-items-start flex-column">
          <span class="card-label font-weight-bolder text-dark">{{
            titulo
          }}</span>
        </h3>
        <div class="card-toolbar">
          <span
            class="card-label font-weight-bolder text-dark"
            v-if="licencas"
            style="margin-right: 20px"
            >Total de assinaturas disponíveis: {{ licencas }}</span
          >
          <a
            v-if="secondaryButtonUrl != null"
            :href="secondaryButtonUrl"
            class="btn btn-dark font-weight-bolder font-size-sm mr-2"
            style="margin-left: 5px"
            >{{ secondaryButtonText }}</a
          >
          <a
            v-if="acaoBtn != null"
            :href="acaoBtn"
            class="btn btn-dark font-weight-bolder font-size-sm"
            >{{ textoBtn }}</a
          >
          <button
            class="btn btn-dark font-weight-bolder font-size-sm ml-2"
            @click="printData"
          >
            <IconPrint :size="18" />
          </button>
          <a
            v-if="acaoBtnTwo != null"
            :href="acaoBtnTwo"
            class="btn btn-dark font-weight-bolder font-size-sm"
            style="margin-left: 5px"
            >{{ textoBtnTwo }}</a
          >
        </div>
      </div>
      <div class="card-body pt-0 pb-3">
        <div class="tab-content">
          <div class="table-responsive">
            <vue-good-table
              :key="tableKey"
              ref="table-custom"
              mode="remote"
              theme="polar-bear"
              :row-style-class="customRowStyle"
              styleClass="vgt-table table-striped"
              :select-options="{
                enabled: multiselect,
                selectionInfoClass: 'selectionTableClass',
                selectionText: 'linhas selecionadas',
                clearSelectionText: 'limpar',
              }"
              @on-page-change="onPageChange"
              @on-sort-change="onSortChange"
              @on-column-filter="onColumnFilter"
              @on-per-page-change="onPerPageChange"
              @on-selected-rows-change="selectionChanged"
              :totalRows="totalRecords"
              :isLoading.sync="isLoading"
              :sort-options="sortOptions"
              :pagination-options="{
                mode: 'pages',
                enabled: true,
                nextLabel: 'Próximo',
                prevLabel: 'Voltar',
                rowsPerPageLabel: '',
                ofLabel: 'de',
                allLabel: 'Todos',
                dropdownAllowAll: false,
                setCurrentPage: filtersStore[moduleName].page,
                perPageDropdown: [10, 20, 30, 40, 50, 100],
                perPage: filtersStore[moduleName].per_page,
                infoFn: (params) => '',
              }"
              :rows="data"
              :columns="fields"
            >
              <template slot="loadingContent">
                <Loading></Loading>
              </template>
              <template slot="emptystate">
                <div class="vgt-center-align vgt-text-disabled">
                  Nenhum registro encontrado
                </div>
              </template>
              <template late slot="table-row" slot-scope="props">
                {{ props.slot }}
                <template v-if="props.column.slot">
                  <slot
                    name="customRow"
                    :rowData="props.row"
                    :field="props.column.field"
                  ></slot>
                </template>
                <template v-else>
                  {{ props.formattedRow[props.column.field] }}
                </template>
              </template>
            </vue-good-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import "vue-good-table/dist/vue-good-table.css";
import { VueGoodTable } from "vue-good-table";
import Loading from "@/components/Loading";
import _ from "lodash";
import { chartStore } from "@/components/Charts/chartStore";
import { filtersStore } from "@/components/FilterFields/filtersStore";
import IconPrint from "vue-material-design-icons/Printer";
import { actionsStore } from "./actionsStore";
import { SistemaMixin } from "@/modules/Application";

export default {
  name: "table-app",
  mixins: [SistemaMixin],
  data() {
    return {
      actionsStore,
      chartStore,
      filtersStore,
      tableKey: "",
      isLoading: false,
      totalRecords: 0,
      storageName: null,
      contasMarcadasBackground: null,
      sortOptions: {
        enabled: true,
        initialSortBy: null,
      },
      data: [],
      serverParams: {
        columnFilters: {},
        sort: {
          field: "",
          type: "",
        },
        page: 1,
        per_page: 10,
      },
    };
  },
  props: {
    titulo: {
      type: String,
      default: null,
    },
    subTitulo: {
      type: String,
      default: null,
    },
    acaoBtn: {
      type: String,
      default: null,
    },
    textoBtn: {
      type: String,
      default: null,
    },
    apiUrl: {
      type: String,
      required: true,
    },
    fields: {
      type: Array,
      required: true,
    },
    acaoBtnTwo: {
      type: String,
      default: null,
    },
    textoBtnTwo: {
      type: String,
      default: null,
    },
    id_banco: {
      type: Number,
      default: null,
    },
    secondaryButtonUrl: {
      type: String,
      required: false,
      default: null,
    },
    secondaryButtonText: {
      type: String,
      required: false,
      default: null,
    },
    licencas: {
      type: String,
      required: false,
    },
    multiselect: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  components: {
    VueGoodTable,
    Loading,
    IconPrint,
  },
  methods: {
    ...mapActions(["setLoading"]),
    customRowStyle(row) {
      if (row.estoque_minimo > 0) {
        return row?.quantidade < row?.estoque_minimo ? "contas-marcadas-class" : "";
      }
      if (row.conta_receber_vencida) {
        return row?.conta_receber_vencida ? "contas-marcadas-class" : "";
      }
      return row?.conta_marcada ? "contas-marcadas-class" : "";
    },
    dateRangeFilter(data, filterString) {
      let dateRange = filterString.split("to");
      let startDate = Date.parse(dateRange[0]);
      let endDate = Date.parse(dateRange[1]);
      return (data =
        Date.parse(data) >= startDate && Date.parse(data) <= endDate);
    },
    serach: _.debounce((vm) => {
      vm.setLoading(true);
      vm.$http
        .post(vm.apiUrl, vm.serverParams)
        .then(function (response) {
          vm.data = response.data.data;
          vm.setLoading(false);
          (vm.totalRecords = response.data.total),
            (vm.serverParams.page = response.data.current_page);
        })
        .catch(() => {
          vm.setLoading(false);
        });
    }, 350),
    printData() {
      this.$router.push({
        name: "visualizarImpressao",
        params: {
          data: this.data,
          labels: this.fields,
          title: this.titulo,
        },
      });
    },
    updateParams(newProps) {
      this.serverParams = Object.assign({}, this.serverParams, newProps);
    },
    onPageChange(params) {
      this.updateParams({ page: params.currentPage });
      this.filtersStore[this.moduleName].page = params.currentPage;
      this.loadItems();
    },
    onPerPageChange(params) {
      this.updateParams({ per_page: params.currentPerPage });
      this.filtersStore[this.moduleName].per_page = params.currentPerPage;
      this.loadItems();
    },
    onSortChange(params) {
      localStorage.setItem(
        `${this.storageName}sortOptions`,
        JSON.stringify(params[0])
      );
      if (!params[0]) {
        return;
      }

      this.updateParams({
        sort: [
          {
            type: params[0].type,
            field: params[0].field,
          },
        ],
      });
      this.loadItems();
    },
    onColumnFilter(params) {
      if (
        this.apiUrl === "/contareceber" ||
        this.apiUrl === "/contapagar" ||
        this.apiUrl === "/financeiro"
      ) {
        this.chartStore.bodyToSendToApi = params;
      }
      this.updateParams(params);
      this.loadItems();
    },
    loadItems() {
      if (this.id_banco) {
        this.serverParams.id_banco = this.id_banco;
      }
      this.serach(this);
    },
    getSortOptionOnStorage() {
      const sortStorage = localStorage.getItem(
        `${this.storageName}sortOptions`
      );
      if (sortStorage) this.sortOptions.initialSortBy = JSON.parse(sortStorage);
    },
    refresh() {
      this.loadItems(this);
    },
    selectionChanged(selectedObject) {
      this.actionsStore.selectedRows = [...selectedObject.selectedRows];
    },
  },
  created() {
    this.contasMarcadasBackground = this.convertHexToRGBA(
      localStorage.getItem("contas_marcadas_color") ?? "#aeeae9",
      0.1
    );

    this.storageName = this.titulo.replaceAll(" ", "");
    this.getSortOptionOnStorage();
    this.actionsStore.selectedRows = [];
    if (this.filtersStore[this.moduleName]) {
      this.serverParams.columnFilters =
        this.filtersStore[this.moduleName].filters;
      this.chartStore.bodyToSendToApi = this.filtersStore[this.moduleName].filters
      this.serverParams.per_page = this.filtersStore[this.moduleName].per_page;
      this.serverParams.page = this.filtersStore[this.moduleName].page;
      this.loadItems();
    } else this.loadItems();
  },
  computed: {
    moduleName() {
      const aux = this.$route.fullPath.replace("/", "").split("/");
      const isBancoMovimentacao =
        aux.at(-1) === "movimentacao" && aux.at(0) === "bancos";
      return !isBancoMovimentacao
        ? this.$route.fullPath.replace("/", "")
        : "bancosMovimentacao";
    },
    cssProps() {
      return {
        "--contas-marcadas-background": this.contasMarcadasBackground,
      };
    },
  },
  watch: {
    filtersStore: {
      handler(newValue) {
        this.serverParams.columnFilters = newValue[this.moduleName].filters;
        this.chartStore.bodyToSendToApi = newValue[this.moduleName].filters;
        this.serverParams.per_page = newValue[this.moduleName].per_page;
        this.serverParams.page = newValue[this.moduleName].page;
        this.loadItems();
      },
      deep: true,
    },
    "actionsStore.updateTable"() {
      this.loadItems();
      this.tableKey = Math.random().toString();
    },
  },
};
</script>

<style>
.vgt-table.polar-bear th.line-numbers,
.vgt-table.polar-bear th.vgt-checkbox-col {
  width: 40px !important;
}

.vgt-table.bordered th {
  border: 0 !important;
  background-color: #f3f6f9 !important;
  border-bottom: 0 !important;
  letter-spacing: 1px !important;
  font-weight: 600;
  color: #b5b5c3 !important;
  font-size: 0.9rem;
  text-transform: uppercase;
}

.vgt-wrap__footer {
  background-color: #f3f6f9 !important;
  color: #b5b5c3 !important;
  border: 0 !important;
}

.vgt-table th.sortable button:before {
  border-top: 5px solid #7e8299;
}

.vgt-table th.sortable button:after {
  border-bottom: 5px solid #7e8299;
}

table.vgt-table td {
  padding: 0.75rem !important;
  border-bottom: 1px solid #fff !important;
  color: #3f4254 !important;
  vertical-align: middle !important;
  font-weight: 600 !important;
}

table.vgt-table {
  border: 0 !important;
}

.vgt-table.polar-bear thead th {
  color: #b5b5c3;
  font-weight: 600;
  border-bottom: 1px solid #e3e8ee;
  background: transparent !important;
}

.vgt-table.polar-bear thead th .vgt-left-align {
  background: transparent !important;
}

.vgt-wrap.polar-bear .vgt-wrap__footer {
  background: transparent !important;
  margin-top: 15px;
}

.vgt-wrap.polar-bear .vgt-wrap__footer .footer__row-count__label {
  display: none;
}

.vgt-table.polar-bear thead th {
  border: 0;
}

.vgt-wrap {
  box-shadow: 0px 0px 30px 0px rgb(82 63 105 / 5%) !important;
  border-radius: 0.42rem !important;
}

.vgt-responsive {
  border-radius: 0.42rem !important;
}

.table-striped tbody tr:nth-of-type(even) {
  background-color: #f3f6f9;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: #fff;
}

.contas-marcadas-class {
  background-color: var(--contas-marcadas-background) !important;
}
</style>
