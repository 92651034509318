import ordemCompra from "@/modules/OrdemCompra/pages/Index";
import ordemCompraNova from "@/modules/OrdemCompra/pages/Create";

export default [
  {
    name: "ordemCompra",
    path: "/ordemCompra",
    component: ordemCompra,
    meta: { title: "Ordem de Compra" },
  },
  {
    path: "/ordem-compra-nova",
    name: "ordemCompraNova",
    component: ordemCompraNova,
    meta: { title: "Ordem de Compra" },
  },
];
