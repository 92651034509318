<template>
  <div>
    <div class="card card-custom gutter-b" style="box-shadow: none">
      <div class="card-header card-header-tabs-line">
        <ul
          class="nav nav-dark nav-bold nav-tabs nav-tabs-line"
          role="tablist"
          ref="builder-tab"
        >
          <li class="nav-item">
            <a
              class="nav-link active"
              data-tab="0"
              data-toggle="tab"
              href="#"
              role="tab"
            >
              Cadastro
            </a>
          </li>
        </ul>
      </div>

      <b-form class="form" @submit.stop.prevent="onSubmit">
        <div class="card-body">
          <b-tabs class="hide-tabs" v-model="tabIndex">
            <b-tab active>
              <b-row>
                <b-col md="8">
                  <b-row>
                    <b-col>
                      <b-form-group
                        id="descricao"
                        label-for="descricao"
                        label="Descrição"
                      >
                        <b-form-input
                          v-model="$v.form.descricao.$model"
                          :state="validateState('descricao')"
                          maxlength="50"
                          autofocus
                        >
                        </b-form-input>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-col>
                <b-col>
                  <b-form-group id="status" label-for="status" label="Ativo">
                    <span class="switch switch-icon">
                      <label>
                        <input
                          type="checkbox"
                          :value="true"
                          @change="setAtivo"
                          :checked="form.status == true"
                        />
                        <span></span>
                      </label>
                    </span>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group
                    id="cliente"
                    label-for="cliente"
                    label="Cliente"
                  >
                    <VueSelect
                      placeholder="Informe o nome do cliente"
                      ref="buscarCliente"
                      label="id_pessoa"
                      :filterable="false"
                      @input="setCliente"
                      v-model="valueCliente"
                      :options="clientes"
                      @search="onSearchCliente"
                    >
                      <template slot="no-options">
                        Informe o nome do cliente
                      </template>
                      <template slot="option" slot-scope="option">
                        <div class="d-center">
                          {{ option.nome }}
                          <span class="text-muted"
                            >CNPJ/CPF:
                            {{ formatCpfCnpj(option.cnpj_cpf) }}</span
                          >
                        </div>
                      </template>
                      <template slot="selected-option" slot-scope="option">
                        <div class="selected d-center">
                          {{ option.nome }}
                        </div>
                      </template>
                    </VueSelect>
                  </b-form-group>
                </b-col>
                <b-col md="12">
                  <b-row v-if="identificadores.length > 0">
                    <b-col
                      v-for="identificador in identificadores"
                      :key="identificador.id_identificador"
                      sm="4"
                    >
                      <b-form-group
                        :label="identificador.descricao"
                        :label-for="identificador.descricao"
                      >
                        <b-form-input
                          @input="
                            (value) =>
                              changeValues(identificador.descricao, value)
                          "
                          maxLength="30"
                          :value="
                            identificadoresFilled[identificador.descricao]
                          "
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
            </b-tab>
          </b-tabs>
        </div>

        <div class="card-footer">
          <div class="row">
            <div class="col-lg-12" style="text-align: center">
              <button
                type="submit"
                ref="submit_novo_objeto"
                class="btn btn-primary font-weight-bold"
              >
                Salvar
              </button>
              <router-link
                class="btn btn-clean font-weight-bold"
                :to="{
                  name: 'objetosLista',
                }"
              >
                Cancelar
              </router-link>
            </div>
          </div>
        </div>
      </b-form>
    </div>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import ObjetoData, { ObjetoDataRequired } from "./ObjetoData";
import { mapGetters } from "vuex";
import _ from "lodash";
import VueSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import PessoaMixin from "@/modules/Application/mixins/PessoaMixin";
import { SistemaMixin } from "@/modules/Application";

export default {
  name: "app-objetos-form",
  props: {
    url: {
      type: String,
    },
    objeto: {
      type: Object,
      default: () => {},
    },
  },
  mixins: [validationMixin, PessoaMixin, SistemaMixin],
  components: {
    VueSelect,
  },
  data() {
    return {
      tabIndex: 0,
      form: {},
      clientes: [],
      valueCliente: null,
      identificadores: [],
      identificadoresFilled: {},
    };
  },
  validations: {
    form: ObjetoDataRequired,
  },
  watch: {
    objeto(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.form = newValue;
        if (newValue) {
          this.valueCliente = newValue.pessoa;
        }
        console.log(newValue.identificadores);
        const identificadoresParsed = newValue.identificadores;

        this.identificadoresFilled = identificadoresParsed.reduce(
          (acc, identificador) => ({
            ...acc,
            [identificador.descricao]: identificador.value,
          }),
          {}
        );
        this.form.identificadores = identificadoresParsed;
      } else {
        this.identificadoresFilled = this.identificadores.reduce(
          (acc, identificador) => ({
            ...acc,
            [identificador.descricao]: null,
          }),
          {}
        );
      }
    },
  },
  computed: {
    ...mapGetters(["itsblocked"]),
  },
  mounted() {
    if (this.itsblocked) {
      this.$router.push({ name: "assinatura" });
    }
    this.getDataInit();
  },
  methods: {
    async getDataInit() {
      this.form = await new ObjetoData({});

      const { data } = await this.$http.post(
        "/identificador/get-identificador"
      );
      this.identificadores = data;
    },
    changeValues(key, value) {
      this.identificadoresFilled[key] = value;
      this.form = {
        ...this.form,
        identificadores: this.identificadores.map((identificador) => ({
          descricao: identificador.descricao,
          value: this.identificadoresFilled[identificador.descricao],
        })),
      };
    },
    setAtivo() {
      this.form.status = this.form.status == true ? false : true;
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    async onSubmit() {
      const submitButton = this.$refs["submit_novo_objeto"];

      try {
        this.$v.form.$touch();

        if (this.$v.form.$anyError) {
          this.$toast.error(
            "Verifique todos os campos obrigatórios do formulário!"
          );
          return;
        }

        submitButton.classList.add("spinner", "spinner-light", "spinner-right");
        submitButton.setAttribute("disabled", "");

        if (this.valueCliente) {
          this.form.id_pessoa = this.valueCliente.id_pessoa;
        }

        await this.$http.post(this.url, this.form);

        this.$router.push({ name: "objetosLista" });
      } catch (error) {
        submitButton.classList.remove(
          "spinner",
          "spinner-light",
          "spinner-right"
        );
        submitButton.removeAttribute("disabled", "");
      }
    },
    setCliente(cliente) {
      this.valueCliente = cliente;
    },
    onSearchCliente(search, loading) {
      if (search.length) {
        loading(true);
        this.searchClient(loading, search, this);
      }
    },
    searchClient: _.debounce(async (loading, search, vm) => {
      vm.clientes = await vm.getPessoaNome(search);
      loading(false);
    }, 450),
  },
};
</script>

<style>
.vs__dropdown-toggle {
  border: 2px solid #dedede;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border-radius: 6px;
  outline: none;
  height: 38px;
}
</style>
