<template>
  <div>
    <b-card
      title="Plano de Contas"
      tag="article"
      class="mb-2 border border-primary"
    >
      <div v-if="isLoading" class="text-center">
        <b-spinner variant="primary"></b-spinner>
        <p>Carregando...</p>
      </div>

      <div v-else>
        <Tree :treeData="treeData" />
      </div>
    </b-card>
  </div>
</template>

<script>
import Tree from "./componentes/Tree.vue";

export default {
  name: "app-plano-de-contas",
  data() {
    return {
      treeData: [],
      isLoading: false,
      showModal: false,
      modalTitle: "",
      modalAction: "",
      form: {
        id: null,
        descricao: "",
        tipo: "",
        id_pai: null,
      },
    };
  },
  components: {
    Tree,
  },
  async mounted() {
    await this.fetchTreeData();
  },
  methods: {
    async fetchTreeData() {
      this.isLoading = true;
      try {
        const response = await this.$http.post("/financeiro/plano-all");
        this.treeData = this.buildTree(response.data);
      } catch (error) {
        console.error(error);
        this.showErrorToast("Erro ao buscar os dados do plano de contas.");
      } finally {
        this.isLoading = false;
      }
    },

    buildTree(data) {
      const map = {};
      const tree = [];

      data.forEach((item) => {
        map[item.id] = { ...item, children: [] };
      });

      data.forEach((item) => {
        if (item.id_pai === null) {
          tree.push(map[item.id]);
        } else if (map[item.id_pai]) {
          map[item.id_pai].children.push(map[item.id]);
        }
      });

      return tree;
    },
  },
};
</script>

<style>
</style>
