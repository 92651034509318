export default {
  data() {
    return {
      emitente: {},
    };
  },
  methods: {
    async getDadosEmitente() {
      const { data } = await this.$http.post("/empresa");
      return data;
    },
    async getDadosEmissor() {
      const { data } = await this.$http.post("/empresa/dados-emissor");
      return data;
    },
    formatCpfCnpj(valor) {
      if (valor === null) {
        return "";
      }
      if (valor.length <= 11) {
        // eslint-disable-next-line
        return valor.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, `\$1.\$2.\$3\-\$4`);
      }
      // eslint-disable-next-line
      return valor.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g, "\$1.\$2.\$3\/\$4\-\$5");
    },
  },
};
