<template>
  <div>
    <h4>Financeiro</h4>
    <b-row>
      <b-col md="4">
        <div class="card card-custom gutter-b">
          <b-form class="form" @submit.stop.prevent="onSubmit">
            <div class="card-body">
              <h4>Contas Receber:</h4>
              <b-row>
                <b-col md="6">
                  <b-form-group
                    id="data_inicial"
                    label-for="data_inicial"
                    label="Data Inicial"
                  >
                    <b-form-input type="date" v-model="data_inicial">
                    </b-form-input>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group
                    id="data_final"
                    label-for="data_final"
                    label="Data Final"
                  >
                    <b-form-input type="date" v-model="data_final">
                    </b-form-input>
                  </b-form-group>
                </b-col>
              </b-row>
              <button
                @click="gerarContasReceber()"
                class="btn btn-primary font-weight-bold"
              >
                Gerar
              </button>
            </div>
          </b-form>
        </div>
      </b-col>

      <b-col md="4">
        <div class="card card-custom gutter-b">
          <b-form class="form" @submit.stop.prevent="onSubmit">
            <div class="card-body">
              <h4>Contas a Pagar:</h4>
              <b-row>
                <b-col md="6">
                  <b-form-group
                    id="data_inicial_pagar"
                    label-for="data_inicial_pagar"
                    label="Data Inicial"
                  >
                    <b-form-input type="date" v-model="data_inicial_pagar">
                    </b-form-input>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group
                    id="data_final_pagar"
                    label-for="data_final_pagar"
                    label="Data Final"
                  >
                    <b-form-input type="date" v-model="data_final_pagar">
                    </b-form-input>
                  </b-form-group>
                </b-col>
              </b-row>
              <button
                @click="gerarContasPagar()"
                class="btn btn-primary font-weight-bold"
              >
                Gerar
              </button>
            </div>
          </b-form>
        </div>
      </b-col>
    </b-row>
    <hr />
    <h4>Compras</h4>
    <b-row>
      <b-col md="4">
        <div class="card card-custom gutter-b">
          <b-form class="form" @submit.stop.prevent="onSubmit">
            <div class="card-body">
              <h4>Compras:</h4>
              <b-row>
                <b-col md="6">
                  <b-form-group
                    id="data_inicial_compras"
                    label-for="data_inicial_compras"
                    label="Data Inicial"
                  >
                    <b-form-input type="date" v-model="data_inicial_compras">
                    </b-form-input>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group
                    id="data_final_compras"
                    label-for="data_final_compras"
                    label="Data Final"
                  >
                    <b-form-input type="date" v-model="data_final_compras">
                    </b-form-input>
                  </b-form-group>
                </b-col>
              </b-row>
              <button
                @click="gerarCompras()"
                class="btn btn-primary font-weight-bold"
              >
                Gerar
              </button>
            </div>
          </b-form>
        </div>
      </b-col>

      <b-col md="4">
        <div class="card card-custom gutter-b">
          <b-form class="form" @submit.stop.prevent="onSubmit">
            <div class="card-body">
              <h4>Resumo de Compras:</h4>
              <b-row>
                <b-col md="6">
                  <b-form-group
                    id="data_inicial_resumocompras"
                    label-for="data_inicial_resumocompras"
                    label="Data Inicial"
                  >
                    <b-form-input
                      type="date"
                      v-model="data_inicial_resumocompras"
                    >
                    </b-form-input>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group
                    id="data_final_resumocompras"
                    label-for="data_final_resumocompras"
                    label="Data Final"
                  >
                    <b-form-input
                      type="date"
                      v-model="data_final_resumocompras"
                    >
                    </b-form-input>
                  </b-form-group>
                </b-col>
              </b-row>
              <button
                @click="gerarResumoCompras()"
                class="btn btn-primary font-weight-bold"
              >
                Gerar
              </button>
            </div>
          </b-form>
        </div>
      </b-col>
    </b-row>
    <hr />
    <h4>Estoque</h4>
    <b-row>
      <b-col md="4">
        <div class="card card-custom gutter-b">
          <b-form class="form" @submit.stop.prevent="onSubmit">
            <div class="card-body">
              <h4>Movimentação do item:</h4>
              <b-row>
                <b-col md="12">
                  <VueSelect
                    placeholder="Informe a descrição do produto"
                    ref="buscarProduto"
                    label="id"
                    :filterable="false"
                    @input="setProduto"
                    v-model="valueProduto"
                    :options="produtos"
                    @search="onSearchProduto"
                  >
                    <template slot="no-options">
                      Informe a descrição do produto
                    </template>
                    <template slot="option" slot-scope="option">
                      <div class="d-center">
                        {{ option.descricao }}
                      </div>
                    </template>
                    <template slot="selected-option" slot-scope="option">
                      <div class="selected d-center">
                        {{ option.descricao }}
                      </div>
                    </template>
                  </VueSelect>
                </b-col>
              </b-row>
              <br />
              <button
                @click="gerarMovimentacaoProduto()"
                class="btn btn-primary font-weight-bold"
              >
                Gerar
              </button>
            </div>
          </b-form>
        </div>
      </b-col>

      <b-col md="4">
        <div class="card card-custom gutter-b">
          <b-form class="form" @submit.stop.prevent="onSubmit">
            <div class="card-body">
              <h4>Lista de preços:</h4>
              <button
                @click="gerarListaDePrecos()"
                class="btn btn-primary font-weight-bold"
              >
                Gerar
              </button>
            </div>
          </b-form>
        </div>
      </b-col>

      <b-col md="4">
        <div class="card card-custom gutter-b">
          <b-form class="form" @submit.stop.prevent="onSubmit">
            <div class="card-body">
              <h4>Inventário:</h4>
              <b-row>
                <b-col md="6">
                  <b-form-group
                    id="data_limite"
                    label-for="data_limite"
                    label="Data Limite"
                  >
                    <b-form-input type="date" v-model="data_limite">
                    </b-form-input>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group
                    id="livro"
                    label-for="livro"
                    label="Número livro"
                  >
                    <b-form-input type="number" v-model="livro"> </b-form-input>
                  </b-form-group>
                </b-col>
              </b-row>
              <button
                @click="gerarInventario()"
                class="btn btn-primary font-weight-bold"
              >
                Gerar
              </button>
            </div>
          </b-form>
        </div>
      </b-col>
    </b-row>
    <hr />
    <h4>Cliente</h4>
    <b-row>
      <b-col md="4">
        <div class="card card-custom gutter-b">
          <b-form class="form" @submit.stop.prevent="onSubmit">
            <div class="card-body">
              <h4>Histórico:</h4>
              <b-row>
                <b-col md="12">
                  <VueSelect
                    placeholder="Informe o nome do cliente"
                    ref="buscarCliente"
                    label="id_pessoa"
                    :filterable="false"
                    @input="setCliente"
                    v-model="valueCliente"
                    :options="clientes"
                    @search="onSearchCliente"
                  >
                    <template slot="no-options">
                      Informe o nome do cliente
                    </template>
                    <template slot="option" slot-scope="option">
                      <div class="d-center">
                        {{ option.nome }}
                      </div>
                    </template>
                    <template slot="selected-option" slot-scope="option">
                      <div class="selected d-center">
                        {{ option.nome }}
                      </div>
                    </template>
                  </VueSelect>
                </b-col>
              </b-row>
              <br />
              <button
                @click="gerarHistoricoCliente()"
                class="btn btn-primary font-weight-bold"
              >
                Gerar
              </button>
            </div>
          </b-form>
        </div>
      </b-col>
    </b-row>
    <hr />
    <h4>Vendas</h4>
    <b-row>
      <b-col md="4">
        <div class="card card-custom gutter-b">
          <b-form class="form" @submit.stop.prevent="onSubmit">
            <div class="card-body">
              <h4>Resumo vendas:</h4>
              <b-row>
                <b-col md="6">
                  <b-form-group
                    id="data_inicial_venda"
                    label-for="data_inicial_venda"
                    label="Data Inicial"
                  >
                    <b-form-input type="date" v-model="data_inicial_venda">
                    </b-form-input>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group
                    id="data_final_venda"
                    label-for="data_final_venda"
                    label="Data Final"
                  >
                    <b-form-input type="date" v-model="data_final_venda">
                    </b-form-input>
                  </b-form-group>
                </b-col>
              </b-row>
              <button
                @click="gerarResumoVendas()"
                class="btn btn-primary font-weight-bold"
              >
                Gerar
              </button>
            </div>
          </b-form>
        </div>
      </b-col>

      <b-col md="4">
        <div class="card card-custom gutter-b">
          <b-form class="form" @submit.stop.prevent="onSubmit">
            <div class="card-body">
              <h4>Vendas detalhado:</h4>
              <b-row>
                <b-col md="6">
                  <b-form-group
                    id="data_inicial_vendadetalhada"
                    label-for="data_inicial_vendadetalhada"
                    label="Data Inicial"
                  >
                    <b-form-input
                      type="date"
                      v-model="data_inicial_vendadetalhada"
                    >
                    </b-form-input>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group
                    id="data_final_vendadetalhada"
                    label-for="data_final_vendadetalhada"
                    label="Data Final"
                  >
                    <b-form-input
                      type="date"
                      v-model="data_final_vendadetalhada"
                    >
                    </b-form-input>
                  </b-form-group>
                </b-col>
              </b-row>
              <button
                @click="gerarVendaDetalhada()"
                class="btn btn-primary font-weight-bold"
              >
                Gerar
              </button>
            </div>
          </b-form>
        </div>
      </b-col>
    </b-row>
    <hr />
    <h4>Fiscal</h4>
    <b-row>
      <b-col md="4">
        <div class="card card-custom gutter-b">
          <b-form class="form" @submit.stop.prevent="onSubmit">
            <div class="card-body">
              <h4>Sped:</h4>
              <b-row>
                <b-col md="6">
                  <b-form-group
                    id="data_inicial_sped"
                    label-for="data_inicial_sped"
                    label="Data Inicial"
                  >
                    <b-form-input type="date" v-model="data_inicial_sped">
                    </b-form-input>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group
                    id="data_final_sped"
                    label-for="data_final_sped"
                    label="Data Final"
                  >
                    <b-form-input type="date" v-model="data_final_sped">
                    </b-form-input>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group
                    id="haveInventory"
                    label-for="haveInventory"
                    label="Gerar Inventário"
                  >
                    <span class="switch switch-icon">
                      <label>
                        <input
                          type="checkbox"
                          :value="false"
                          @change="setInventario"
                          :checked="haveInventory == true"
                        />
                        <span></span>
                      </label>
                    </span>
                  </b-form-group>
                </b-col>
                <b-col md="6" v-show="haveInventory">
                  <b-form-group
                    id="data_inventario"
                    label-for="data_inventario"
                    label="Data Base"
                  >
                    <b-form-input type="date" v-model="data_inventario">
                    </b-form-input>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <b-form-group
                    id="haveC170"
                    label-for="haveC170"
                    label="Gerar itens das Notas Eletrônicas (Registro C170)"
                  >
                    <span class="switch switch-icon">
                      <label>
                        <input
                          type="checkbox"
                          :value="false"
                          @change="setC170"
                          :checked="haveC170 == true"
                        />
                        <span></span>
                      </label>
                    </span>
                  </b-form-group>
                </b-col>
              </b-row>
              <button
                @click="gerarSped()"
                class="btn btn-primary font-weight-bold"
              >
                Gerar
              </button>
            </div>
          </b-form>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import { SistemaMixin } from "@/modules/Application";
import PessoaMixin from "@/modules/Application/mixins/PessoaMixin";
import swal from "sweetalert";
import VueSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import _ from "lodash";

export default {
  name: "relatorios",
  mixins: [SistemaMixin, PessoaMixin],
  components: {
    VueSelect,
  },
  data() {
    return {
      data_inicial: null,
      data_final: null,
      data_inicial_pagar: null,
      data_final_pagar: null,
      data_inicial_compras: null,
      data_final_compras: null,
      data_inicial_resumocompras: null,
      data_final_resumocompras: null,
      reportTarge: null,
      valueProduto: null,
      produtos: [],
      clientes: [],
      valueCliente: null,
      data_inicial_venda: null,
      data_final_venda: null,
      data_final_vendadetalhada: null,
      data_inicial_vendadetalhada: null,
      data_limite: null,
      livro: null,
      data_inicial_sped: null,
      data_final_sped: null,
      haveInventory: false,
      data_inventario: null,
      haveC170: false,
    };
  },
  watch: {
    reportTarge(newValue, oldValue) {
      if (newValue !== oldValue) {
        window.Echo.leaveChannel(
          "reportGenerate" + this.currentUser.usuario_empresa.id_empresa
        );
      }
    },
  },
  methods: {
    async getDataInit() {},
    gerarContasReceber() {
      if (!this.data_inicial || !this.data_final) {
        this.$toast.warning("Informe as datas para gerar o relatório!");
        return;
      }
      swal({
        title: "Deseja realizar a visualização do pdf?",
        text: "Aguarde, pois seu relátorio será gerado!",
        icon: "info",
        buttons: {
          cancel: {
            text: "Cancelar",
            value: "cancel",
            visible: true,
            closeModal: true,
          },
          confirm: {
            text: "OK",
            visible: true,
            closeModal: false,
          },
        },
        dangerMode: false,
        closeModal: false,
        closeOnClickOutside: false,
      }).then(async (value) => {
        if (value === "cancel") {
          swal.close();
          swal.stopLoading();
          return;
        }
        await this.$http
          .post("/relatorios/receitas", {
            data_inicial: this.data_inicial,
            data_final: this.data_final,
          })
          .then(() => {
            window.Echo.channel(
              "reportGenerate" + this.currentUser.usuario_empresa.id_empresa
            ).listen(
              ".newReport" + this.currentUser.usuario_empresa.id_empresa,
              (message) => {
                if (message) {
                  this.reportTarge = message.message;
                  window.open(message.message, "_blank");
                }
              }
            );
            swal.stopLoading();
            swal.close();
            this.$toast.success("Seu relátorios será gerado automaticamente!");
          })
          .catch(() => {
            swal.stopLoading();
            swal.close();
          });
      });
    },
    gerarContasPagar() {
      if (!this.data_inicial_pagar || !this.data_final_pagar) {
        this.$toast.warning("Informe as datas para gerar o relatório!");
        return;
      }
      swal({
        title: "Deseja realizar a visualização do pdf?",
        text: "Aguarde, pois seu relátorio será gerado!",
        icon: "info",
        buttons: {
          cancel: {
            text: "Cancelar",
            value: "cancel",
            visible: true,
            closeModal: true,
          },
          confirm: {
            text: "OK",
            visible: true,
            closeModal: false,
          },
        },
        dangerMode: false,
        closeModal: false,
        closeOnClickOutside: false,
      }).then(async (value) => {
        if (value === "cancel") {
          swal.close();
          swal.stopLoading();
          return;
        }
        await this.$http
          .post("/relatorios/despesas", {
            data_inicial: this.data_inicial_pagar,
            data_final: this.data_final_pagar,
          })
          .then(() => {
            window.Echo.channel(
              "reportGenerate" + this.currentUser.usuario_empresa.id_empresa
            ).listen(
              ".newReport" + this.currentUser.usuario_empresa.id_empresa,
              (message) => {
                if (message) {
                  this.reportTarge = message.message;
                  window.open(message.message, "_blank");
                }
              }
            );
            swal.stopLoading();
            swal.close();
            this.$toast.success("Seu relátorios será gerado automaticamente!");
          })
          .catch(() => {
            swal.stopLoading();
            swal.close();
          });
      });
    },
    gerarCompras() {
      if (!this.data_inicial_compras || !this.data_final_compras) {
        this.$toast.warning("Informe as datas para gerar o relatório!");
        return;
      }
      swal({
        title: "Deseja realizar a visualização do pdf?",
        text: "Aguarde, pois seu relátorio será gerado!",
        icon: "info",
        buttons: {
          cancel: {
            text: "Cancelar",
            value: "cancel",
            visible: true,
            closeModal: true,
          },
          confirm: {
            text: "OK",
            visible: true,
            closeModal: false,
          },
        },
        dangerMode: false,
        closeModal: false,
        closeOnClickOutside: false,
      }).then(async (value) => {
        if (value === "cancel") {
          swal.close();
          swal.stopLoading();
          return;
        }
        await this.$http
          .post("/relatorios/compras", {
            data_inicial: this.data_inicial_compras,
            data_final: this.data_final_compras,
          })
          .then(() => {
            window.Echo.channel(
              "reportGenerate" + this.currentUser.usuario_empresa.id_empresa
            ).listen(
              ".newReport" + this.currentUser.usuario_empresa.id_empresa,
              (message) => {
                if (message) {
                  this.reportTarge = message.message;
                  window.open(message.message, "_blank");
                }
              }
            );
            swal.stopLoading();
            swal.close();
            this.$toast.success("Seu relátorios será gerado automaticamente!");
          })
          .catch(() => {
            swal.stopLoading();
            swal.close();
          });
      });
    },
    gerarResumoCompras() {
      if (!this.data_inicial_resumocompras || !this.data_final_resumocompras) {
        this.$toast.warning("Informe as datas para gerar o relatório!");
        return;
      }
      swal({
        title: "Deseja realizar a visualização do pdf?",
        text: "Aguarde, pois seu relátorio será gerado!",
        icon: "info",
        buttons: {
          cancel: {
            text: "Cancelar",
            value: "cancel",
            visible: true,
            closeModal: true,
          },
          confirm: {
            text: "OK",
            visible: true,
            closeModal: false,
          },
        },
        dangerMode: false,
        closeModal: false,
        closeOnClickOutside: false,
      }).then(async (value) => {
        if (value === "cancel") {
          swal.close();
          swal.stopLoading();
          return;
        }
        await this.$http
          .post("/relatorios/resumo-compras", {
            data_inicial: this.data_inicial_resumocompras,
            data_final: this.data_final_resumocompras,
          })
          .then(() => {
            window.Echo.channel(
              "reportGenerate" + this.currentUser.usuario_empresa.id_empresa
            ).listen(
              ".newReport" + this.currentUser.usuario_empresa.id_empresa,
              (message) => {
                if (message) {
                  this.reportTarge = message.message;
                  window.open(message.message, "_blank");
                }
              }
            );
            swal.stopLoading();
            swal.close();
            this.$toast.success("Seu relátorios será gerado automaticamente!");
          })
          .catch(() => {
            swal.stopLoading();
            swal.close();
          });
      });
    },
    gerarListaDePrecos() {
      swal({
        title: "Deseja realizar a visualização do pdf?",
        text: "Aguarde, pois seu relátorio será gerado!",
        icon: "info",
        buttons: {
          cancel: {
            text: "Cancelar",
            value: "cancel",
            visible: true,
            closeModal: true,
          },
          confirm: {
            text: "OK",
            visible: true,
            closeModal: false,
          },
        },
        dangerMode: false,
        closeModal: false,
        closeOnClickOutside: false,
      }).then(async (value) => {
        if (value === "cancel") {
          swal.close();
          swal.stopLoading();
          return;
        }
        await this.$http
          .post("/relatorios/lista-preco")
          .then(() => {
            window.Echo.channel(
              "reportGenerate" + this.currentUser.usuario_empresa.id_empresa
            ).listen(
              ".newReport" + this.currentUser.usuario_empresa.id_empresa,
              (message) => {
                if (message) {
                  this.reportTarge = message.message;
                  window.open(message.message, "_blank");
                }
              }
            );
            swal.stopLoading();
            swal.close();
            this.$toast.success("Seu relátorios será gerado automaticamente!");
          })
          .catch(() => {
            swal.stopLoading();
            swal.close();
          });
      });
    },
    gerarMovimentacaoProduto() {
      if (!this.valueProduto) {
        this.$toast.warning(
          "Selecione o produto que deseja ver a movimentação!"
        );
        return;
      }
      swal({
        title: "Deseja realizar a visualização do pdf?",
        text: "Aguarde, pois seu relátorio será gerado!",
        icon: "info",
        buttons: {
          cancel: {
            text: "Cancelar",
            value: "cancel",
            visible: true,
            closeModal: true,
          },
          confirm: {
            text: "OK",
            visible: true,
            closeModal: false,
          },
        },
        dangerMode: false,
        closeModal: false,
        closeOnClickOutside: false,
      }).then(async (value) => {
        if (value === "cancel") {
          swal.close();
          swal.stopLoading();
          return;
        }
        await this.$http
          .post("/relatorios/movimentacao-produto", {
            id: this.valueProduto.id,
          })
          .then(() => {
            window.Echo.channel(
              "reportGenerate" + this.currentUser.usuario_empresa.id_empresa
            ).listen(
              ".newReport" + this.currentUser.usuario_empresa.id_empresa,
              (message) => {
                if (message) {
                  this.reportTarge = message.message;
                  window.open(message.message, "_blank");
                }
              }
            );
            swal.stopLoading();
            swal.close();
            this.$toast.success("Seu relátorios será gerado automaticamente!");
          })
          .catch(() => {
            swal.stopLoading();
            swal.close();
          });
      });
    },
    gerarHistoricoCliente() {
      if (!this.valueCliente) {
        this.$toast.warning("Selecione o cliente que deseja ver o histórico!");
        return;
      }
      swal({
        title: "Deseja realizar a visualização do pdf?",
        text: "Aguarde, pois seu relátorio será gerado!",
        icon: "info",
        buttons: {
          cancel: {
            text: "Cancelar",
            value: "cancel",
            visible: true,
            closeModal: true,
          },
          confirm: {
            text: "OK",
            visible: true,
            closeModal: false,
          },
        },
        dangerMode: false,
        closeModal: false,
        closeOnClickOutside: false,
      }).then(async (value) => {
        if (value === "cancel") {
          swal.close();
          swal.stopLoading();
          return;
        }
        await this.$http
          .post("/relatorios/historico-cliente", {
            id: this.valueCliente.id_pessoa,
          })
          .then(() => {
            window.Echo.channel(
              "reportGenerate" + this.currentUser.usuario_empresa.id_empresa
            ).listen(
              ".newReport" + this.currentUser.usuario_empresa.id_empresa,
              (message) => {
                if (message) {
                  this.reportTarge = message.message;
                  window.open(message.message, "_blank");
                }
              }
            );
            swal.stopLoading();
            swal.close();
            this.$toast.success("Seu relátorios será gerado automaticamente!");
          })
          .catch(() => {
            swal.stopLoading();
            swal.close();
          });
      });
    },
    gerarResumoVendas() {
      if (!this.data_inicial_venda || !this.data_final_venda) {
        this.$toast.warning("Informe as datas para gerar o relatório!");
        return;
      }
      swal({
        title: "Deseja realizar a visualização do pdf?",
        text: "Aguarde, pois seu relátorio será gerado!",
        icon: "info",
        buttons: {
          cancel: {
            text: "Cancelar",
            value: "cancel",
            visible: true,
            closeModal: true,
          },
          confirm: {
            text: "OK",
            visible: true,
            closeModal: false,
          },
        },
        dangerMode: false,
        closeModal: false,
        closeOnClickOutside: false,
      }).then(async (value) => {
        if (value === "cancel") {
          swal.close();
          swal.stopLoading();
          return;
        }
        await this.$http
          .post("/relatorios/resumo-vendas", {
            data_inicial: this.data_inicial_venda,
            data_final: this.data_final_venda,
          })
          .then(() => {
            window.Echo.channel(
              "reportGenerate" + this.currentUser.usuario_empresa.id_empresa
            ).listen(
              ".newReport" + this.currentUser.usuario_empresa.id_empresa,
              (message) => {
                if (message) {
                  this.reportTarge = message.message;
                  window.open(message.message, "_blank");
                }
              }
            );
            swal.stopLoading();
            swal.close();
            this.$toast.success("Seu relátorios será gerado automaticamente!");
          })
          .catch(() => {
            swal.stopLoading();
            swal.close();
          });
      });
    },
    gerarVendaDetalhada() {
      if (
        !this.data_inicial_vendadetalhada ||
        !this.data_final_vendadetalhada
      ) {
        this.$toast.warning("Informe as datas para gerar o relatório!");
        return;
      }
      swal({
        title: "Deseja realizar a visualização do pdf?",
        text: "Aguarde, pois seu relátorio será gerado!",
        icon: "info",
        buttons: {
          cancel: {
            text: "Cancelar",
            value: "cancel",
            visible: true,
            closeModal: true,
          },
          confirm: {
            text: "OK",
            visible: true,
            closeModal: false,
          },
        },
        dangerMode: false,
        closeModal: false,
        closeOnClickOutside: false,
      }).then(async (value) => {
        if (value === "cancel") {
          swal.close();
          swal.stopLoading();
          return;
        }
        await this.$http
          .post("/relatorios/vendas", {
            data_inicial: this.data_inicial_vendadetalhada,
            data_final: this.data_final_vendadetalhada,
          })
          .then(() => {
            window.Echo.channel(
              "reportGenerate" + this.currentUser.usuario_empresa.id_empresa
            ).listen(
              ".newReport" + this.currentUser.usuario_empresa.id_empresa,
              (message) => {
                if (message) {
                  this.reportTarge = message.message;
                  window.open(message.message, "_blank");
                }
              }
            );
            swal.stopLoading();
            swal.close();
            this.$toast.success("Seu relátorios será gerado automaticamente!");
          })
          .catch(() => {
            swal.stopLoading();
            swal.close();
          });
      });
    },
    gerarInventario() {
      if (!this.data_limite) {
        this.$toast.warning("Informe a data limite para gerar o relatório!");
        return;
      }
      swal({
        title: "Deseja realizar a visualização do pdf?",
        text: "Aguarde, pois seu relátorio será gerado!",
        icon: "info",
        buttons: {
          cancel: {
            text: "Cancelar",
            value: "cancel",
            visible: true,
            closeModal: true,
          },
          confirm: {
            text: "OK",
            visible: true,
            closeModal: false,
          },
        },
        dangerMode: false,
        closeModal: false,
        closeOnClickOutside: false,
      }).then(async (value) => {
        if (value === "cancel") {
          swal.close();
          swal.stopLoading();
          return;
        }
        await this.$http
          .post("/relatorios/inventario", {
            data_limite: this.data_limite,
            livro: this.livro,
          })
          .then(() => {
            window.Echo.channel(
              "reportGenerate" + this.currentUser.usuario_empresa.id_empresa
            ).listen(
              ".newReport" + this.currentUser.usuario_empresa.id_empresa,
              (message) => {
                if (message) {
                  this.reportTarge = message.message;
                  window.open(message.message, "_blank");
                }
              }
            );
            swal.stopLoading();
            swal.close();
            this.$toast.success("Seu relátorios será gerado automaticamente!");
          })
          .catch(() => {
            swal.stopLoading();
            swal.close();
          });
      });
    },
    gerarSped() {
      if (!this.data_inicial_sped && !this.data_final_sped) {
        this.$toast.warning("Informe as datas para gerar o sped!");
        return;
      }
      swal({
        title: "Deseja realizar a visualização do pdf?",
        text: "Aguarde, pois seu relátorio será gerado!",
        icon: "info",
        buttons: {
          cancel: {
            text: "Cancelar",
            value: "cancel",
            visible: true,
            closeModal: true,
          },
          confirm: {
            text: "OK",
            visible: true,
            closeModal: false,
          },
        },
        dangerMode: false,
        closeModal: false,
        closeOnClickOutside: false,
      }).then(async (value) => {
        if (value === "cancel") {
          swal.close();
          swal.stopLoading();
          return;
        }
        await this.$http
          .post("/relatorios/sped", {
            data_inicial: this.data_inicial_sped,
            data_final: this.data_final_sped,
            haveInventory: this.haveInventory,
            data_inventario: this.data_inventario,
            haveC170: this.haveC170,
          })
          .then(() => {
            window.Echo.channel(
              "reportGenerate" + this.currentUser.usuario_empresa.id_empresa
            ).listen(
              ".newReport" + this.currentUser.usuario_empresa.id_empresa,
              (message) => {
                if (message) {
                  this.reportTarge = message.message;
                  window.open(message.message, "_blank");
                }
              }
            );
            swal.stopLoading();
            swal.close();
            this.$toast.success(
              "Seu sped será gerado automaticamente, verique nas notificações!"
            );
          })
          .catch(() => {
            swal.stopLoading();
            swal.close();
          });
      });
    },
    setInventario() {
      this.haveInventory = this.haveInventory == true ? false : true;
    },
    setC170() {
      this.haveC170 = this.haveC170 == true ? false : true;
    },
    setProduto(produto) {
      this.valueProduto = produto;
    },
    setCliente(cliente) {
      this.valueCliente = cliente;
    },
    onSearchProduto(search, loading) {
      if (search.length) {
        loading(true);
        this.searchProduto(loading, search, this);
      }
    },
    searchProduto: _.debounce(async (loading, search, vm) => {
      vm.produtos = await vm.getProdutoByDescricao(search);
      loading(false);
    }, 350),
    onSearchCliente(search, loading) {
      if (search.length) {
        loading(true);
        this.searchClient(loading, search, this);
      }
    },
    searchClient: _.debounce(async (loading, search, vm) => {
      vm.clientes = await vm.getPessoaNome(search);
      loading(false);
    }, 350),
  },
  computed: {
    ...mapGetters(["layoutConfig", "itsblocked"]),
    ...mapState({
      currentUser: (state) => state.auth.user,
    }),
    config() {
      return this.layoutConfig();
    },
  },
  async mounted() {
    await this.getDataInit();
    if (this.itsblocked) {
      this.$router.push({ name: "assinatura" });
    }
  },
};
</script>
