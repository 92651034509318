<template>
  <b-row>
    <b-col md="12" style="height: 100% !important">
      <b-card
        class="card-itens tableItensCompra"
        style="max-height: 100% !important"
        id="tableItensCompra"
      >
        <table
          class="table table-head-custom table-head-bg table-vertical-center"
        >
          <thead>
            <tr>
              <th></th>
              <th class="text-left font-weight-bold text-muted pl-7">Itens Nota</th>
              <th class="pl-0 font-weight-bold text-muted pl-2">CFOP</th>
              <th class="text-right pr-0 font-weight-bold text-muted">Qtd</th>
              <th class="text-center pr-0 font-weight-bold text-muted">UN</th>
              <th class="text-right pr-0 font-weight-bold text-muted pr-2">
                Conversão
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(i, index) in items" :key="'item' + index">
              <td>{{ parseFloat(index + 1) }}</td>
              <td class="pl-0" style="font-size: 12px">
                {{ i.produto.descricao }}
              </td>
              <td class="text-right pr-0 pt-7" style="width: 200px">
                <VueSelect
                  placeholder="Digite código"
                  ref="buscarCFOP"
                  :filterable="false"
                  v-model="i.cfop_compra"
                  @input="setCFOP(i, index)"
                  :options="cfops"
                  @search="onSearchCFOP"
                >
                  <template slot="no-options">
                    Informe o código do CFOP
                  </template>
                  <template slot="option" slot-scope="option">
                    <div class="d-center">
                      {{ option.codigo }} - {{ option.descricao }}
                    </div>
                  </template>
                  <template slot="selected-option" slot-scope="option">
                    <div class="selected d-center">
                      {{ option.codigo }}
                    </div>
                  </template>
                </VueSelect>
              </td>
              <td class="text-right pr-0 pt-7">
                {{ i.quantidade }}
              </td>
              <td class="text-right pr-0 pt-7" style="width: 200px">
                <VueSelect
                  ref="buscarUN"
                  :filterable="false"
                  :options="unidades_medidas"
                  @input="setConversao(i, index)"
                  @search="onSearchUN"
                  v-model="i.unidade_medida"
                  :class="{ hasErrors: unidadeError }"
                >
                  <template slot="no-options">
                    Informe a descrição da unidade de medida
                  </template>

                  <template slot="option" slot-scope="option">
                    <div class="d-center">
                      {{ option.nome }}
                    </div>
                  </template>

                  <template slot="selected-option" slot-scope="option">
                    <div class="selected d-center">
                      {{ option.nome }}
                    </div>
                  </template>
                </VueSelect>
              </td>
              <td class="text-right pr-0 pr-7 pt-7">
                {{ i.quantidade_conversao }}
              </td>
            </tr>
          </tbody>
        </table>
      </b-card>
      <blockquote />
    </b-col>
  </b-row>
</template>

<script>
import _ from "lodash";
import VueSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import { SistemaMixin } from "@/modules/Application";
import { validationMixin } from "vuelidate";
import { store } from "../../storeCompra";

export default {
  name: "compra-quantidade-itens",
  components: {
    VueSelect,
  },
  mixins: [SistemaMixin, validationMixin],
  data() {
    return {
      store,
      unidades_medidas: [],
      multiplicar: 1,
      divisao: 2,
      unidadeError: false,
      hasFilled: false,
      cfops: [],
      cfop_compra: null,
    };
  },
  props: {
    items: {},
  },
  methods: {
    setCFOP(i) {
      if (!i.cfop_compra) {
        i.cfop_compra = this.cfop_compra;
        return;
      }
    },
    onSearchCFOP(search, loading) {
      if (search.length) {
        loading(true);
        this.searchCFOP(loading, search, this);
      }
    },
    searchCFOP: _.debounce(async (loading, search, vm) => {
      vm.cfops = await vm.getCFOPByCodigo(search);
      loading(false);
    }, 350),
    onSearchUN(search, loading) {
      if (search.length) {
        loading(true);
        this.searchUN(loading, search, this);
      }
    },
    searchUN: _.debounce(async (loading, search, vm) => {
      vm.unidades_medidas = await vm.getUNByDescricao(search);
      loading(false);
    }, 350),
    setConversao(i, index) {
      if (!i.unidade_medida) {
        i.quantidade_conversao = i.quantidade;
        return;
      }

      if (i.unidade_medida.tipo_conversor == this.multiplicar) {
        i.quantidade_conversao =
          i.quantidade_conversao * i.unidade_medida.conversor;

        return;
      }
      if (i.unidade_medida.tipo_conversor == this.divisao) {
        i.quantidade_conversao =
          i.quantidade_conversao / i.unidade_medida.conversor;

        return;
      }
      this.unidadeError = false;
      this.hasFilled = true;
      if (index === 0) {
        this.$swal
          .fire({
            icon: "warning",
            title: `Deseja aplicar "${i.unidade_medida.nome}" e para todos os itens?`,
            showCancelButton: true,
            confirmButtonText: `Sim`,
            cancelButtonText: `Não`,
            confirmButtonColor: "#f64e60",
          })
          .then(async (result) => {
            if (result.isConfirmed) {
              this.items = this.items.map((item) => ({
                ...item,
                unidade_medida: i.unidade_medida,
              }));
              this.$toast.success(
                `"${i.unidade_medida.nome}" aplicada para todos os items!`
              );
              this.$emit("onItemsChange", this.items);
            }
          });
      }
    },
  },
  watch: {
    "store.currentTab"(newValue, oldValue) {
      if (newValue !== oldValue) {
        if (oldValue === 4 && newValue === 5) {
          if (!this.items.every((element) => !!element.unidade_medida)) {
            this.$toast.error("Selecione uma unidade para prosseguir!");
            this.unidadeError = true;
            this.store.currentTab = oldValue;
          }
        }
      }
    },
  },
};
</script>

<style>
.card-titulo-itens .card-title {
  margin-bottom: 0 !important;
  font-weight: 700;
  font-size: 1.75rem;
  padding: 0.75rem;
}

.card-titulo-itens .card-body {
  padding: 0.45rem;
}

.card-titulo-itens {
  background-color: #fff;
  border: 0px;
  text-align: center;
  margin-bottom: 0px;
  padding: 0;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}

.card-itens .card-body {
  padding: 0;
}

.table {
  border-bottom: solid 0px #dedede;
  margin-bottom: 0px;
}

.tableItensCompra {
  /* padding: 10px; */
  overflow-y: auto;
  max-height: calc(100vh - 297px);
  /* height: calc(100vh - 297px); */
  border: 0px;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
}

.tableItensCompra th,
.tableItensCompra td {
  padding: 8px 16px;
}

.tableItensCompra .table tr th {
  border-bottom: 2px solid var(--color-primary);
}

.tableItensCompra th {
  position: sticky;
  top: 0;
  background: #fff;
}

::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-thumb {
  background: transparent;
  border-radius: 1px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 200px rgb(229 245 234 / 19%);
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: var(--color-secondary);
}

.card-total-venda {
  background: transparent;
  border: 0px;
  top: 30px;
}

.card-total-venda .card-body {
  padding: 0rem 1rem 0rem 1rem;
}

.card-total-left {
  background-color: var(--color-primary);
  border-color: var(--color-primary);
  color: #fff;
  border-radius: 0px;
  border-bottom-left-radius: 20px !important;
  height: 90px;
  width: 101%;
  display: table;
  text-align: left;
}

.card-total-left .card-body {
  display: table-cell;
  vertical-align: middle;
}

.card-total-right {
  background-color: var(--color-secondary);
  border-color: var(--color-secondary);
  color: #fff;
  border-radius: 0px;
  align-items: flex-end;
  border-bottom-right-radius: 20px !important;
  height: 90px;
  width: 100%;
  display: table;
  text-align: center;
  text-align: right;
}

.card-total-right .card-body {
  display: table-cell;
  vertical-align: middle;
}

.card-total-right p,
.card-total-left p {
  margin-bottom: 0;
  font-size: 0.8rem;
}

.card-total-right h5,
.card-total-left h5 {
  font-size: 1.75rem !important;
  margin-bottom: 0;
}

.tableItensCompra tbody tr {
  border-top-width: 1.5px;
  /* border-top-style: dashed; */
  border-top-color: var(--color-primary);
}

blockquote {
  margin: 0 auto;
  position: relative;
  text-align: center;
  z-index: 1;
}

blockquote:after {
  content: "";
  display: block;
  position: absolute;
  width: 100%;
  height: 29px;
  right: 0;
  bottom: -24px;
  left: 0;
  padding-top: 10px;
  background: linear-gradient(-45deg, transparent 70%, white 75%),
    linear-gradient(45deg, transparent 70%, white 75%);
  background-repeat: repeat-x;
  background-size: 25px 20px, 25px 20px;
  background-position: center;
}
</style>
