import { required } from "vuelidate/lib/validators";

export default class DigitalData {
  //dados da empresa
  business_name = "";
  business_phone = "";
  business_email = "";
  ein = "";
  mcc = "";
  business_opening_date = "";
  statement_descriptor = "";

  //dados proprietário
  first_name = "";
  last_name = "";
  email = "";
  phone_number = "";
  taxpayer_id = "";
  birthdate = "";

  //dados do endereco da empresa
  line1 = "";
  line2 = "";
  neighborhood = "";
  city = "";
  state = "";
  postal_code = "";
  country_code = "BR";



  constructor({
    business_name = "",
    business_phone = "",
    business_email = "",
    ein = "",
    mcc = "",
    business_opening_date = "",
    statement_descriptor = "",
    first_name = "",
    last_name = "",
    email = "",
    phone_number = "",
    taxpayer_id = "",
    birthdate = "",
    line1 = "",
    line2 = "",
    neighborhood = "",
    city = "",
    state = "",
    postal_code = "",
    country_code = "",


  }) {
    this.business_name = business_name;
    this.business_phone = business_phone;
    this.business_email = business_email;
    this.ein = ein;
    this.mcc = mcc;
    this.business_opening_date = business_opening_date;
    this.statement_descriptor = statement_descriptor;
    this.first_name = first_name;
    this.last_name = last_name;
    this.email = email;
    this.phone_number = phone_number;
    this.taxpayer_id = taxpayer_id;
    this.birthdate = birthdate;
    this.line1 = line1;
    this.line2 = line2;
    this.neighborhood = neighborhood;
    this.city = city;
    this.state = state;
    this.postal_code = postal_code;
    this.country_code = country_code;

  }
}

export const DigitalDataRequired = {
  business_name: {
    required,
  },
  business_phone: {
    required,
  },
  business_email: {
    required,
  },
  ein: {
    required,
  },
  business_opening_date: {
    required,
  },
  statement_descriptor: {
    required,
  },
  first_name: {
    required,
  },
  last_name: {
    required,
  },
  email: {
    required,
  },
  phone_number: {
    required,
  },
  taxpayer_id: {
    required,
  },
  birthdate: {
    required,
  },
  line1: {
    required,
  },
  line2: {
    required,
  },
  neighborhood: {
    required,
  },
  state: {
    required,
  },
  postal_code: {
    required,
  },
  city: {
    required,
  },
};
