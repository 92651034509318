<template>
  <div class="topbar-item">
    <div
      id="kt_quick_user_toggle"
      class="
        btn btn-icon btn-hover-transparent-white
        d-flex
        align-items-center
        btn-lg
        px-md-2
        w-md-auto
      "
      @click="$router.push({ path: '/perfil' })"
    >
      <span class="symbol symbol-35">{{ currentUser.nome }}</span>
    </div>

    <div
      id="kt_quick_user"
      ref="kt_quick_user"
      class="offcanvas offcanvas-right p-10"
    >
      <div
        class="
          offcanvas-header
          d-flex
          align-items-center
          justify-content-between
          pb-5
        "
      >
        <h3 class="font-weight-bold m-0">Perfil do usuário</h3>
        <a
          href="#"
          class="btn btn-xs btn-icon btn-light btn-hover-primary"
          id="kt_quick_user_close"
        >
          <i class="ki ki-close icon-xs text-muted"></i>
        </a>
      </div>

      <perfect-scrollbar
        class="offcanvas-content pr-5 mr-n5 scroll"
        style="max-height: 90vh; position: relative"
      >
        <div class="d-flex align-items-center mt-5">
          <div class="symbol symbol-100 mr-5"></div>
          <div class="d-flex flex-column">
            <a
              href="#/usuarios"
              class="
                font-weight-bold font-size-h5
                text-dark-75 
              "
            >
              {{ currentUser.nome }}
            </a>
            <a
              href="#/usuarios"
              class="
                font-weight-bold font-size-h5
                text-dark-75 text-hover-primary
              "
            >
            </a>
            <div class="navi mb-2">
              <a href="#/perfil" class="navi-item" v-b-tooltip="'Clique aqui para editar'">
                <span class="navi-link p-0 pb-2">
                  <span
                    class="navi-text text-muted text-hover-primary"
                    style="font-size: 10px"
                  >
                    Ver perfil
                  </span>
                </span>
              </a>
            </div>
            <button class="btn btn-light-primary btn-bold" @click="onLogout">
              Sair
            </button>
          </div>
        </div>
      </perfect-scrollbar>
    </div>
  </div>
</template>

<style lang="scss" scoped>
#kt_quick_user {
  overflow: hidden;
}
</style>

<script>
import { LOGOUT } from "@/core/services/store/auth.module";
import KTLayoutQuickUser from "@/assets/js/layout/extended/quick-user.js";
import KTOffcanvas from "@/assets/js/components/offcanvas.js";
import { mapState } from "vuex";

export default {
  name: "KTQuickUser",
  data() {
    return {};
  },
  mounted() {
    KTLayoutQuickUser.init(this.$refs["kt_quick_user"]);
  },
  methods: {
    onLogout() {
      this.$store
        .dispatch(LOGOUT)
        .then(() => this.$router.push({ name: "login" }));
    },
    closeOffcanvas() {
      new KTOffcanvas(KTLayoutQuickUser.getElement()).hide();
    },
  },
  computed: {
    picture() {
      return process.env.BASE_URL + "media/users/300_21.jpg";
    },
    ...mapState({
      currentUser: (state) => state.auth.user,
    }),
  },
};
</script>
