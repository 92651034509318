import { required } from "vuelidate/lib/validators";

export default class ConfigBoletoData {
  late_fee_mode = "FIXED";
  late_fee_amount = 0;
  late_fee_percentage = 0;
  late_fee_start_date = null;
  interest_mode = "DAILY_AMOUNT";
  interest_amount = 0;
  interest_percentage = 0;
  interest_start_date = null;
  discount_mode = "FIXED";
  discount_amount = 0;
  discount_percentage = 0;
  discount_limit_date = null;
  use_logo = true;
  instructions_line1 = null;
  instructions_line2 = null;
  instructions_line3 = null;
  instructions_line4 = null;
  instructions_line5 = null;

  constructor({
    late_fee_mode = "FIXED",
    late_fee_amount = 0,
    late_fee_percentage = 0,
    late_fee_start_date = null,
    interest_mode = "DAILY_AMOUNT",
    interest_amount = null,
    interest_percentage = null,
    interest_start_date = null,
    discount_mode = "FIXED",
    discount_amount = null,
    discount_percentage = null,
    discount_limit_date = null,
    use_logo = true,
    instructions_line1 = null,
    instructions_line2 = null,
    instructions_line3 = null,
    instructions_line4 = null,
    instructions_line5 = null,
  }) {
    this.late_fee_mode = late_fee_mode;
    this.late_fee_amount = late_fee_amount;
    this.late_fee_percentage = late_fee_percentage;
    this.late_fee_start_date = late_fee_start_date;
    this.interest_mode = interest_mode;
    this.interest_amount = interest_amount;
    this.interest_percentage = interest_percentage;
    this.discount_mode = discount_mode;
    this.interest_start_date = interest_start_date;
    this.discount_amount = discount_amount;
    this.discount_limit_date = discount_limit_date;
    this.discount_percentage = discount_percentage;
    this.use_logo = use_logo;
    this.instructions_line1 = instructions_line1;
    this.instructions_line2 = instructions_line2;
    this.instructions_line3 = instructions_line3;
    this.instructions_line4 = instructions_line4;
    this.instructions_line5 = instructions_line5;
  }
}

export const ConfigBoletoDataRequired = {
  late_fee_mode: {
    required,
  },
  interest_mode: {
    required,
  },
  discount_mode: {
    required,
  },
  interest_start_date: {
    required,
  },
  late_fee_start_date: {
    required,
  },
  discount_limit_date: {
    required,
  },
};
