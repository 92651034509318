<template>
  <div class="card card-custom card-stretch gutter-b">
    <div class="card-header border-0 py-5">
      <h3 class="card-title align-items-start flex-column">
        <span class="card-label font-weight-bolder text-dark">
          Editar Objeto
        </span>
      </h3>
    </div>
    <Form url="/objeto/update" :objeto="objeto" />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Form from "./Form.vue";
import ObjetoData from "./ObjetoData";

export default {
  name: "app-objeto-update",
  data() {
    return {
      objeto: new ObjetoData({}),
    };
  },
  components: {
    Form,
  },
  methods: {
    ...mapActions(["setLoading"]),
    async getObjeto(id_objeto) {
      try {
        const { data } = await this.$http.post("/objeto/find", {
          id_objeto: id_objeto,
        });
        return data;
      } catch (error) {
        return null;
      }
    },
    async getInit() {
      try {
        const codigo = this.$route.params.id_objeto;

        this.setLoading(true);

        const objeto = await this.getObjeto(codigo);

        this.setLoading(false);

        if (!objeto.id_objeto || objeto == null) {
          this.$router.push({ name: "objetosLista" });
          return this.$toast.error("Objeto não encontrado!");
        }

        this.objeto = new ObjetoData(objeto);
      } catch {
        return;
      }
    },
  },
  computed: {
    ...mapGetters(["itsblocked"]),
  },
  mounted() {
    if (this.itsblocked) {
      this.$router.push({ name: "assinatura" });
    }
    this.getInit();
  },
};
</script>
