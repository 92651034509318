var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"position":"relative"}},[_c('FilterFields',{attrs:{"fields":[
      {
        type: 'text',
        label: 'Descrição',
        field: 'descricao',
      },
      {
        type: 'select',
        label: 'Status',
        field: 'status',
        options: ['Todos', 'Ativo', 'Inativo'],
      },
    ]}}),_c('TableApp',{ref:"objetos",attrs:{"titulo":"Objetos","acaoBtn":"/#/objetos/novo","textoBtn":"Novo","api-url":"/objeto/lista","fields":_vm.fields,"classFilterColumn":"descricao","perPage":10},scopedSlots:_vm._u([{key:"customRow",fn:function(props){return [(props.field == 'descricao')?_c('span',{attrs:{"slot":"nome"},slot:"nome"},[_c('span',{staticClass:"pl-0 pt-8"},[_c('div',{staticClass:"d-flex align-items-center"},[_c('div',[_c('span',{staticClass:"text-dark-75font-weight-bold d-block"},[_vm._v(_vm._s(props.rowData.descricao))])])])])]):_vm._e(),(props.field == 'status')?_c('span',[(props.rowData.status == true)?_c('span',{staticClass:"label label-lg label-inline label-light-success"},[_vm._v("Ativo")]):_c('span',{staticClass:"label label-lg label-inline label-light-danger"},[_vm._v("Inativo")])]):_vm._e(),(props.field == 'actions')?_c('span',[_c('div',{staticClass:"row-actions",staticStyle:{"text-align":"right !important"}},[_c('span',{staticStyle:{"overflow":"visible","position":"relative","width":"130px"}},[_c('div',{staticClass:"dropdown dropdown-inline"},[_c('router-link',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.bottom",value:('Editar'),expression:"'Editar'",modifiers:{"hover":true,"bottom":true}}],staticClass:"btn btn-sm btn-info btn-hover-info btn-icon mr-2",attrs:{"to":{
                  name: 'objetosEditar',
                  params: {
                    id_objeto: props.rowData.id_objeto,
                  },
                }}},[_c('Pencil',{attrs:{"size":16,"title":"Editar"}})],1),_c('button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.bottom",value:('Excluir'),expression:"'Excluir'",modifiers:{"hover":true,"bottom":true}}],staticClass:"btn btn-sm btn-danger btn-hover-danger btn-icon mr-2",on:{"click":function($event){return _vm.excluir(props.rowData)}}},[_c('TrashCanIcon',{attrs:{"size":16}})],1)],1)])])]):_vm._e()]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }