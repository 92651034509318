<template>
  <div>
    <div class="card card-custom gutter-b" style="box-shadow: none">
      <div class="card-body">
        <b-row>
          <b-col md="2">
            <b-form-group id="nome" label-for="nome" label="Nome">
              <b-form-input v-model="form.nome" autofocus
                :placeholder="`Nome da variação ${form.utiliza_cor ? '(Ex: Cor)' : '(Ex: Tamanho)'}`" />
            </b-form-group>
          </b-col>
          <b-col md="2">
            <b-form-group id="aparecerCores" label-for="aparecerCores" label="Utilizar exibição de cores">
              <span class="switch switch-icon">
                <label>
                  <input type="checkbox" :checked="form.utiliza_cor" @change="handleCheckboxColor" />
                  <span></span>
                </label>
              </span>
            </b-form-group>
          </b-col>

        </b-row>
        <b-row>
          <b-col md="3">
            <b-form-group id="variaveis" label-for="variaveis" label="Variáveis">
              <div class="d-flex align-items-center mb-3" style="gap: 10px;" v-for="(variavel, index) in form.variaveis"
                :key="variavel.nome">
                <b-form-input type="color" v-if="form.utiliza_cor" v-model="variavel.cor" style="width: 50px;" />
                <b-form-input autofocus v-model="variavel.nome"
                  :placeholder="form.utiliza_cor ? 'Ex: Preto' : 'Ex: GG'" />
                <button @click="excluir(index)" class="btn btn-sm btn-danger btn-hover-danger btn-icon mr-2"
                  style="min-width: 30px;" v-b-tooltip.hover.bottom="'Excluir'">
                  <TrashCanIcon :size="20" />
                </button>
              </div>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="2">
            <button ref="submit_nova_variacao" @click="addVariavel"
              class="btn btn-secondary font-weight-bold border border-primary">
              <div class="d-flex align-items-center" style="gap: 5px">
                <Plus :size="20" />
                <p class="mb-0">Adicionar mais</p>
              </div>
            </button>
          </b-col>
          <b-col md="2">
            <button ref="import" v-b-modal.importarTemplate
              class="btn btn-secondary font-weight-bold border border-primary">
              <div class="d-flex align-items-center " style="gap: 5px">
                <p class="mb-0">Importar exemplo</p>
              </div>
            </button>
          </b-col>
        </b-row>
      </div>

      <div class="card-footer">
        <div class="row">
          <div class="col-lg-12" style="text-align: center">
            <button type="submit" ref="submit_nova_variacao" @click="onSubmit" class="btn btn-primary font-weight-bold">
              Salvar
            </button>
            <router-link class="btn btn-clean font-weight-bold" :to="{
              name: 'variacoes',
            }">
              Cancelar
            </router-link>
          </div>
        </div>
      </div>
    </div>

    <b-modal id="importarTemplate" title="Importar exemplo" size="md" centered>
      <div class="mx-auto">
        <b-form-group label="Exemplo">
          <b-form-select v-model="selectedTemplate" placeholder="">
            <b-form-select-option v-for="template in templatesExamples" :key="template.nome" :value="template">
              {{ template.nome }}
            </b-form-select-option>
          </b-form-select>
        </b-form-group>
        <b-row v-if="!!selectedTemplate">
          <b-col>
            <b-form-group id="variaveis" label-for="variaveis" label="Variaveis">
              <span class="text-muted">{{ selectedTemplate.variaveis.map(el => el.nome).join(', ') }}</span>
            </b-form-group>
          </b-col>
        </b-row>
      </div>
      <template #modal-footer="{ ok, cancel }">
        <b-button @click="cancel()">
          Cancelar
        </b-button>
        <b-button variant="primary" @click="importarTemplate">
          Importar
        </b-button>
      </template>
    </b-modal>

  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { SistemaMixin } from "@/modules/Application";
import { MONEY as settingMoney, priceOptions, quantityOptions } from "@/modules/Application";
import "vue-select/dist/vue-select.css";
import _ from "lodash";
import { mapGetters } from "vuex";
import { onboardStore } from "../../../onboardStore";
import Plus from "vue-material-design-icons/PlusThick.vue";
import TrashCanIcon from "vue-material-design-icons/TrashCan.vue";

export default {
  name: "app-produtos-form",
  props: {
    url: {
      type: String,
    },
    variacao: {
      type: Object,
      default: () => { },
    },
  },
  components: {
    Plus,
    TrashCanIcon,
  },
  mixins: [validationMixin, SistemaMixin],
  data() {
    return {
      onboardStore,
      configMoney: settingMoney,
      quantityOptions,
      priceOptions,
      windowWidth: window.innerWidth,
      exibirCores: false,
      tabIndex: 0,
      selectedTemplate: null,
      templatesExamples: [],
      keyToRender: 0,
      form: {
        nome: null,
        variaveis: [],
        utiliza_cor: false,
      },
      fields: [
        {
          label: "Item/Cód",
          field: "item",
          slot: true,
          sortable: false,
          width: "5%",
          filterOptions: {
            enabled: false,
          },
        },
        {
          label: "Nome",
          field: "descricao",
          slot: true,
          sortable: false,
          width: "40%",
          filterOptions: {
            enabled: false,
          },
        },
        {
          label: "Qtd",
          field: "quantidade",
          slot: true,
          sortable: false,
          width: "8%",
        },
        {
          label: "Unitário",
          field: "preco",
          sortable: false,
          slot: true,
          width: "10%",
        },
        {
          label: "Total",
          field: "total",
          sortable: false,
          slot: true,
          width: "20%",
        },
        {
          label: "",
          field: "actions",
          sortable: false,
          slot: true,
          width: "10%",
        },
      ],
    };
  },
  watch: {
    variacao: {
      handler(newValue) {
        this.form = { ...newValue }
      }, deep: true
    }
  },
  computed: {
    ...mapGetters(["itsblocked"]),
  },
  created() {
    if (this.itsblocked) {
      this.$router.push({ name: "assinatura" });
    }
    this.getDataInit();
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
    });
  },
  methods: {
    addVariavel() {
      this.form.variaveis = [
        ...this.form.variaveis,
        {
          nome: null,
          ...(this.form.utiliza_cor && { cor: null })
        }
      ]
    },
    excluir(index) {
      const aux = [...this.form.variaveis];
      aux.splice(index, 1);
      this.form.variaveis = [...aux];
    },
    importarTemplate() {
      this.form = this.selectedTemplate;
      this.selectedTemplate = null;
      this.$bvModal.hide('importarTemplate');
    },
    handleEnter(ref) {
      if (this.fromSelect) {
        this.fromSelect = false;
        return;
      }
      if (!this.$refs[ref]) return
      this.$refs[ref].$el.focus();
    },
    handleCheckboxColor() {
      this.form.utiliza_cor = !this.form.utiliza_cor;
      if (this.form.utiliza_cor) {
        this.form.variaveis = this.form.variaveis.map(variavel => ({
          ...variavel,
          cor: variavel?.cor ?? '#000000'
        }))
      }
    },
    onResize() {
      this.windowWidth = window.innerWidth;
    },
    async getDataInit() {
      try {
        const { data } = await this.$http.post('/variavel/tamplates')
        this.templatesExamples = data.map(el => el.tamplate);
      } catch (error) {
        console.log(error);
      }
    },

    async onSubmit() {
      this.addLoadingToButton('submit_nova_variacao');
      try {

        await this.$http.post(this.url, this.form);
        this.$router.push({ name: "variacoes" });
      } catch (err) {
        console.log(err);
      }
      this.removeLoadingToButton('submit_nova_variacao');
    },

  },
};
</script>

