<template>
  <b-tab title="Intermediador (Opcional)" @click="() => store.currentTab = 6" :active="isTheCurrentTab" :disabled="!isTheCurrentTab && !store.isUpdate" class="mt-5">
    <b-row>
      <b-col>
        <b-form-group label="Indicador de intermediador/Marketplace" label-for="indicador">
          <VueSelect ref="buscarCliente" :filterable="false" :options="indicadorOptions"
            @search="onSearchCliente" v-model="store.formIntermediador.indicador">
            <template slot="no-options">
              Informe o indicador
            </template>
            <template slot="option" slot-scope="option">
              <div class="d-center">
                {{ `${option.id} - ${option.name}` }}
              </div>
            </template>
            <template slot="selected-option" slot-scope="option">
              <div class="selected d-center">
                {{ `${option.id} - ${option.name}` }}
              </div>
            </template>
          </VueSelect>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-form-group label="Intermediador da Transação" label-for="intermediador">
          <VueSelect ref="buscarIntermediador" :filterable="false" :state="validateIntermediadorState('intermediador')"
            :options="clientes" @search="onSearchCliente" v-model="intermediador" @input="setIntermediador" :disabled="isNoIntermediador">
            <template slot="no-options">
              Pesquisar intermediador
            </template>
            <template slot="option" slot-scope="option">
              <div class="d-center">
                {{ option.nome }}
              </div>
            </template>
            <template slot="selected-option" slot-scope="option">
              <div class="selected d-center">
                {{ option.nome }}
              </div>
            </template>
          </VueSelect>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="6">
        <b-form-group label="Nome do usuário" label-for="usuario">
          <b-form-input id="usuario" name="usuario" v-model="store.formIntermediador.usuario"
            :disabled="isNoIntermediador">
          </b-form-input>
        </b-form-group>
      </b-col>
    </b-row>
  </b-tab>
</template>

<script>
import { mapGetters, mapState, mapActions } from "vuex";
import VueSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import { validationMixin } from "vuelidate";
import IntermediadorData, { IntermediadorDataRequired } from "../IntermediadorData";
import { SistemaMixin, ValidacaoMixin } from "@/modules/Application";
import _ from "lodash";
import { mask } from "vue-the-mask";
import { store } from "../../storeNfe";

export default {
  name: "app-nfe-form-intermediadorTab",
  components: {
    VueSelect
  },
  directives: {
    mask
  },
  data() {
    return {
      clientes: [],
      cliente: null,
      intermediador: null,
      indicadorOptions: [{
        id: 0,
        name: 'Operação sem intermediador'
      }, {
        id: 1,
        name: 'Operação em site ou plataforma de terceiros (intermediadores/marketplace)'
      }],
      store,
    }
  },
  mixins: [
    validationMixin,
    SistemaMixin,
    ValidacaoMixin,
  ],
  validations: {
    form: IntermediadorDataRequired
  },
  methods: {
    ...mapActions(["setLoading"]),
    async getDataInit() {
      this.store.formIntermediador = new IntermediadorData({});
      if(Object.keys(this.$route.params).length !== 0){
        if(this.$route.params.nfeData?.intermediador){
          const intermediador = this.$route.params.nfeData?.intermediador ?? {}
          this.store.formIntermediador = _.cloneDeep(intermediador);
          this.intermediador = this.store.formIntermediador;
        }else {
          this.store.formIntermediador.indicador = this.indicadorOptions[0] 
          this.intermediador = null;
        }
        
      }
    },
    setIndicador(ie) {
      this.store.formCliente.ie_rg = ie;
    },
    validateIntermediadorState(name) {
      // const { $dirty, $error } = this.$v.form[name];
      // return $dirty ? !$error : null;
      return name;
    },
    onSearchCliente(search, loading) {
      if (search.length) {
        loading(true);
        this.searchCliente(loading, search, this);
      }
    },
    searchCliente: _.debounce(async (loading, search, vm) => {
      vm.clientes = await vm.getClientes(search);
      loading(false);
    }, 350),
    setIntermediador() {
      const oldIndicador = this.store.formIntermediador?.indicador;
      if (this.intermediador === null){
        this.store.formIntermediador = null,
        this.store.formIntermediador = {
          indicador: oldIndicador ?? null,
        }
        return;
      }
        this.store.formIntermediador = {
          ...this.intermediador, 
          indicador: oldIndicador ?? null,
        }
      this.enderecos = this.intermediador.enderecos
      this.store.formIntermediador.endereco = this.enderecos[0];
      this.fones = this.intermediador.fones.map(item => item.fone);
      this.store.formIntermediador.fone = this.store.formIntermediador.fones[0]?.fone ?? null;
      this.emails = this.intermediador.emails.map(item => item.email) ?? null;
      this.store.formIntermediador.email = this.emails[0];
      this.isCnpj = this.store.formIntermediador.cnpj_cpf.length > 11;
      this.store.formIntermediador.contato = this.intermediador.nome.split(" ")[0] ?? null;
    },
  },
  created() {
    this.getDataInit();
  },
  computed: {
    ...mapGetters([
      "isAuthenticated",
      "breadcrumbs",
      "pageTitle",
      "layoutConfig"
    ]),
    ...mapState(["loading"]),
    isLoading() {
      return this.loading.loading;
    },
    isNoIntermediador() {
      return this.store.formIntermediador?.indicador?.id === 0
    },
    isTheCurrentTab() {
      return store.currentTab === 6
    }
  }
}

</script>

<style>
.style-chooser .vs__dropdown-toggle {
  width: 20rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.style-chooser .vs__dropdown-menu {
  width: 43rem;
  white-space: normal;
  /* overflow: hidden; */
  /* text-overflow: ellipsis; */
}
</style>