<template>
  <Form url="/compras/create" :compra="compra" />
</template>

<script>
import Form from "./Form.vue";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import CompraData from "./CompraData";

export default {
  name: "app-compra-create",
  data() {
    return {
      compra: new CompraData({}),
    };
  },
  components: {
    Form
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {
        title: "Compras"
      }
    ]);
  }
};
</script>
