export default {
  data() {
    return {
      pagamentos: []
    };
  },
  methods: {
    async getPagamentos() {
      const { data } = await this.$http.post("/financeiro/formas-pagamento");
      this.pagamentos = data;
    },
  },
};
