<template>
  <div>
    <div
      class="d-flex flex-column pt-12 bgi-size-cover bgi-no-repeat rounded-top"
      style="background-color: #172c30"
    >
      <h4 class="d-flex flex-center rounded-top">
        <span class="text-white">Informativos</span>
        <span
          class="btn btn-text btn-primary btn-sm font-weight-bold btn-font-md ml-2"
        >
          {{ totalNotificacoes }}
        </span>
      </h4>
      <ul
        class="nav nav-bold nav-tabs nav-tabs-line nav-tabs-line-3x nav-tabs-line-transparent-white nav-tabs-line-active-border-success mt-3 px-8"
        role="tablist"
      >
        <li class="nav-item">
          <a
            v-on:click="setActiveTab"
            data-tab="0"
            class="nav-link active"
            data-toggle="tab"
            href="#"
            role="tab"
          >
          </a>
        </li>
      </ul>
    </div>

    <b-tabs class="hide-tabs" v-model="tabIndex">
      <b-tab active class="p-8">
        <perfect-scrollbar
          class="scroll pr-7 mr-n7"
          style="max-height: 40vh; position: relative"
        >
          <template v-if="notificacao">
            <div class="d-flex align-items-center mb-6">
              <div class="label label-inline label-light-success mr-5">
                Nova Mensagem
              </div>
              <div class="d-flex flex-column font-weight-bold">
                <a
                  target="_blank"
                  :href="notificacao.url"
                  class="text-dark text-hover-primary mb-1 font-size-lg"
                >
                  {{ notificacao.titulo }}
                </a>
                <span class="text-muted">
                  {{ formatDate(notificacao.created_at, "DD/MM/YYYY") }}
                </span>
              </div>
            </div>
          </template>
        </perfect-scrollbar>
      </b-tab>
    </b-tabs>
  </div>
</template>

<style lang="scss">
/* hide default vue-bootstrap tab links */
.hide-tabs > div:not(.tab-content) {
  display: none;
}
</style>

<script>
import { SistemaMixin } from "@/modules/Application";
import { mapState } from "vuex";
import { notificationStore } from "../../../../notificationStore";

export default {
  name: "KTDropdownNotificationCentral",
  mixins: [SistemaMixin],
  data() {
    return {
      tabIndex: 0,
      notificacao: {},
      totalNotificacoes: 0,
      notificationStore,
    };
  },
  watch: {
    totalNotificacoes(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.notificationStore.totalCentral = newValue;
      }
    },
  },

  methods: {
    async getDataInit() {
      await this.getNotificacoes();
    },
    async getNotificacoes() {
      try {
        const { data } = await this.$http.post("/notificacao/get-central");
        this.notificacao = data.notificacao.mensagem;
        this.totalNotificacoes = data.notificacao.total;
      } catch (e) {
        console.log(e);
      }
    },
    setActiveTab(event) {
      const tab = event.target.closest('[role="tablist"]');
      const links = tab.querySelectorAll(".nav-link");

      for (let i = 0; i < links.length; i++) {
        links[i].classList.remove("active");
      }

      this.tabIndex = parseInt(event.target.getAttribute("data-tab"));

      event.target.classList.add("active");
    },
  },
  async mounted() {
    await this.getDataInit();
  },
  computed: {
    backgroundImage() {
      return process.env.BASE_URL + "media/misc/bg-1.jpg";
    },
    ...mapState({
      currentUser: (state) => state.auth.user,
    }),
  },
};
</script>
