import { reactive } from 'vue';

export const store = reactive({
  formCliente: {},
  formProduto: {},
  formTransportador: {},
  formFaturamento: {},
  formInformacoes: {},
  isUpdate: false,
  currentTab: 0,
  dataFetching: {
    produtos: true,
    servicos: true,
  },
  verificaEstoque: false,
  pafNFCe: false,
});
