import { required } from "vuelidate/lib/validators";

export default class ContratoData {
  valor = 0;
  vezes = "";
  dia_pagamento = "";
  ano = "2024";
  id_empresa = null;
  gerar_boleto = true;
  id = null;

  constructor({
    valor = 0,
    vezes = "",
    dia_pagamento = "",
    ano = "2024",
    id_empresa = null,
    gerar_boleto = true,
    id = null,
  }) {
    this.valor = valor;
    this.vezes = vezes;
    this.dia_pagamento = dia_pagamento;
    this.ano = ano;
    this.id_empresa = id_empresa;
    this.gerar_boleto = gerar_boleto;
    this.id = id;
  }
}

export const ContratoDataRequired = {
  valor: {
    required,
  },
  vezes: {
    required,
  },
  dia_pagamento: {
    required,
  },
  ano: {
    required,
  },
}