<template>
  <div class="topbar">
    <div
      v-if="clients.length > 0 && souRevenda"
      style="position: relative; margin-top: 15px"
    >
      <input
        type="text"
        id="search-client"
        class="search-input"
        v-model="searchQuery"
        placeholder="Trocar de conta"
        autocomplete="off"
      />
      <div v-if="searchQuery && filteredClients.length > 0" class="client-list">
        <ul>
          <li
            v-for="client in filteredClients"
            :key="client.id"
            @click="selectClient(client)"
            class="client-item"
          >
            <strong style="margin-right: 10px">{{ client.cliente }} - {{ formatCpfCnpj(client.cnpj_cpf) }}</strong>
            <AccountCircle
              :size="20"
              style="margin-left: 50px; margin-right: 2px"
            />
            <span style="margin-left: 2px">&nbsp;</span>
          </li>
        </ul>
      </div>
    </div>
    <b-dropdown
      size="sm"
      variant="link"
      toggle-class="topbar-item text-decoration-none"
      no-caret
      right
      no-flip
      v-if="visualizarInformativo"
    >
      <template v-slot:button-content>
        <div
          class="btn btn-icon btn-hover-transparent-white btn-dropdown btn-lg mr-1 pulse pulse-primary position-relative"
        >
          <span class="svg-icon svg-icon-xl">
            <inline-svg src="media/svg/icons/Code/Info-circle.svg" />
          </span>
          <span class="pulse-ring" v-if="notificationStore.totalCentral > 0" />
          <span
            class="font-size-sm position-absolute notifications"
            v-if="notificationStore.totalCentral > 0"
            >{{ notificationStore.totalCentral }}</span
          >
        </div>
      </template>
      <b-dropdown-text tag="div" class="min-w-md-350px">
        <form>
          <KTDropdownNotificationCentral></KTDropdownNotificationCentral>
        </form>
      </b-dropdown-text>
    </b-dropdown>
    <b-dropdown
      size="sm"
      variant="link"
      toggle-class="topbar-item text-decoration-none"
      no-caret
      right
      no-flip
    >
      <template v-slot:button-content>
        <div
          class="btn btn-icon btn-hover-transparent-white btn-dropdown btn-lg mr-1 pulse pulse-primary position-relative"
        >
          <span class="svg-icon svg-icon-xl">
            <inline-svg src="media/svg/icons/Code/Compiling.svg" />
          </span>
          <span class="pulse-ring" v-if="notificationStore.total > 0" />
          <span
            class="font-size-sm position-absolute notifications"
            v-if="notificationStore.total > 0"
            >{{ notificationStore.total }}</span
          >
        </div>
      </template>
      <b-dropdown-text tag="div" class="min-w-md-350px">
        <form>
          <KTDropdownNotification></KTDropdownNotification>
        </form>
      </b-dropdown-text>
    </b-dropdown>

    <KTQuickUser></KTQuickUser>
  </div>
</template>

<style lang="scss">
.notifications {
  right: 5px;
  bottom: 0;
}
.topbar {
  .dropdown-toggle {
    padding: 0;

    &:hover {
      text-decoration: none;
    }

    &.dropdown-toggle-no-caret {
      &:after {
        content: none;
      }
    }
  }

  .dropdown-menu {
    margin: 0;
    padding: 0;
    outline: none;

    .b-dropdown-text {
      padding: 0;
    }
  }
}
</style>

<script>
import KTDropdownNotification from "@/view/layout/extras/dropdown/DropdownNotification.vue";
import KTDropdownNotificationCentral from "@/view/layout/extras/dropdown/DropdownNotificationCentral.vue";
import KTQuickUser from "@/view/layout/extras/offcanvas/QuickUser.vue";
import { notificationStore } from "../../../notificationStore";
import swal from "sweetalert";
import { SistemaMixin } from "@/modules/Application";
import AccountCircle from "vue-material-design-icons/AccountArrowRightOutline.vue";
import JwtService from "@/core/services/jwt.service";
import { mapState } from "vuex";

export default {
  name: "KTTopbar",
  data() {
    return {
      notificationStore,
      souRevenda: false,
      searchQuery: "",
      clients: [],
      selectedClient: null,
      visualizarInformativo: false,
    };
  },
  mixins: [SistemaMixin],
  components: {
    KTDropdownNotification,
    KTQuickUser,
    KTDropdownNotificationCentral,
    AccountCircle,
  },
  methods: {
    selectClient(client) {
      this.selectedClient = client.id_empresa;
      this.searchQuery = client.cliente;

      swal({
        icon: "warning",
        title: "Deseja acessar a conta do cliente " + client.cliente + "?",
        showCancelButton: true,
        buttons: {
          cancel: {
            text: "Cancelar",
            value: "cancel",
            visible: true,
            closeModal: true,
          },
          confirm: {
            text: "Sim",
            visible: true,
            closeModal: false,
          },
        },
        confirmButtonColor: "#f64e60",

        dangerMode: false,
        closeModal: false,
        closeOnClickOutside: false,
      }).then(async (value) => {
        if (value === "cancel") {
          swal.close();
          swal.stopLoading();
          return;
        }
        swal.close();
        swal.stopLoading();

        this.$toast.success(
          "Aguarde, carregando os dados referente ao clente..."
        );

        const response = await this.$http.post("/empresa/acessar-conta", {
          id_empresa: client.id_empresa,
          id_revenda: client.id_revenda,
        });

        if (response) {
          JwtService.saveTokenCliente(response.data.access_token);
          JwtService.saveParceiro(true);
        }

        setTimeout(() => {
          window.location.reload();
        }, 500);
      });
    },
  },
  watch: {
    async currentUser(user) {
      this.souRevenda = user.usuario_empresa.empresa.revenda;

      if (!this.souRevenda) {
        this.souRevenda = await JwtService.getParceiro();
      }

      if (this.souRevenda) {
        let id_revenda = user.usuario_empresa.empresa.parceiro.id_revenda;
        if (id_revenda === 1) {
          id_revenda = user.usuario_empresa.empresa.parceiro.id_empresa;
        }
        const data = await this.$http.post("/empresa/meus-clientes-lista", {
          id_revenda: id_revenda,
        });
        if (data.data) {
          this.clients = data.data;
        }
      }

      if (this.currentUser.usuario_empresa.empresa.parceiro.id_revenda == 1) {
        this.visualizarInformativo = true;
      }
    },
  },
  computed: {
    ...mapState({
      currentUser: (state) => state.auth.user,
    }),
    filteredClients() {
      return this.clients.filter((client) =>
        client.cliente.toLowerCase().includes(this.searchQuery.toLowerCase())
      );
    },
  },
};
</script>

<style scoped>
.search-container {
  margin-left: 150px;
  margin-top: 20px;
}

input,
select {
  display: block;
  margin-bottom: 10px;
}
.client-list {
  border: 1px solid #ccc;
  max-height: 150px;
  overflow-y: auto;
  background-color: white;
  position: absolute; /* Permite o ajuste da posição */
  z-index: 1000; /* Para garantir que a lista fique acima de outros elementos */
  right: 0; /* Fixar à direita */
  left: auto; /* Evitar que a lista se mova para a esquerda */
}

.client-item {
  padding: 8px;
  cursor: pointer;
  display: flex; /* Usar flexbox para alinhar o ícone e o texto */
  align-items: center; /* Alinha verticalmente */
  width: 300px;
}

.client-icon {
  width: 20px; /* Defina o tamanho do ícone */
  height: 20px; /* Defina o tamanho do ícone */
  margin-right: 8px; /* Espaço entre o ícone e o texto */
}

.client-item:hover {
  background-color: var(--primary-color); /* Cor de fundo ao passar o mouse */
}

.search-input {
  color: white; /* Cor do texto digitado */
  background-color: transparent; /* Fundo transparente, se necessário */
  border: none; /* Remove as bordas */
  border-bottom: 2px solid white; /* Linha inferior branca */
  outline: none; /* Remove o contorno padrão ao focar */

  /* Transição suave para o foco */
  transition: border-bottom-color 0.3s ease-in-out;
}

/* Estilizando o placeholder para ficar branco */
.search-input::placeholder {
  color: white;
  opacity: 1; /* Garante que o placeholder fique totalmente visível */
}
</style>