<template>
  <b-tab
    title="Fornecedor"
    :active="isTheCurrentTab"
    @click="() => (store.currentTab = 0)"
    :disabled="!isTheCurrentTab && !store.isUpdate"
  >
    <b-row>
      <b-col md="12">
        <b-form-group label="Fornecedor" label-for="nome">
          <VueSelect
            ref="buscarCliente"
            :filterable="false"
            :state="validateClienteState('nome')"
            @input="setFornecedor"
            :class="{ hasErrors: clienteError, inputFilled: clienteFilled }"
            :options="fornecedores"
            @search="onSearchCliente"
            v-model="form"
          >
            <template slot="no-options">
              Informe o nome do fornecedor
            </template>
            <template slot="option" slot-scope="option">
              <div class="d-flex justify-content-between">
                <div>{{ option.codigo }} - {{ option.nome }}</div>
                <span class="text-muted"
                  >CPF: {{ formatCpfCnpj(option.cnpj_cpf) }}</span
                >
              </div>
            </template>
            <template slot="selected-option" slot-scope="option">
              <div class="selected d-center">
                {{ option.nome }}
              </div>
            </template>
          </VueSelect>
        </b-form-group>
      </b-col>
      <b-col md="4">
        <b-form-group label="CPF/CNPJ" label-for="cnpj_cpf">
          <b-form-input
            :key="keyToReRender"
            disabled
            id="cnpj_cpf"
            name="cnpj_cpf"
            v-mask="['###.###.###-##', '##.###.###/####-##']"
            v-model="form.cnpj_cpf"
          >
          </b-form-input>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col sm="10" v-if="form?.enderecos">
        <b-form-group
          :key="keyToReRender"
          label="Endereço"
          label-for="endereco"
        >
          <b-form-input
            disabled
            id="endereco"
            name="endereco"
            :value="formatEndereco(form?.enderecos[0])"
          />
        </b-form-group>
      </b-col>
    </b-row>
  </b-tab>
</template>

<script>
import { mapGetters, mapState, mapActions } from "vuex";
import VueSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import { validationMixin } from "vuelidate";
import { SistemaMixin, ValidacaoMixin } from "@/modules/Application";
import _ from "lodash";
import { mask } from "vue-the-mask";
import { store } from "../../storeOrdemCompra";
import { ClienteDataRequired } from "../ClienteData";

export default {
  name: "app-pedido-form-clienteTab",
  components: {
    VueSelect,
  },
  directives: {
    mask,
  },
  data() {
    return {
      form: {
        nome: null,
        cnpj_cpf: null,
      },
      fornecedores: [],
      cliente: null,
      keyToReRender: 0,
      store,
      vendedores: [],
      clienteError: false,
      enderecoError: false,
      clienteFilled: false,
      nomeCliente: null,
      cpfCnpjCliente: null,
      enderecos: [],
      cidades: [],
      formEndereco: {},
    };
  },
  mixins: [validationMixin, SistemaMixin, ValidacaoMixin],
  validations: {
    form: ClienteDataRequired,
  },
  methods: {
    ...mapActions(["setLoading"]),
    handleLoading(value) {
      this.setLoading(value);
    },
    onSearchCliente(search, loading) {
      if (search.length > 2 || /^[0-9]+$/.test(search)) {
        loading(true);
        this.searchCliente(loading, search, this);
      }
    },
    validateClienteState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    searchCliente: _.debounce(async (loading, search, vm) => {
      vm.fornecedores = await vm.getFornecedores(search);
      loading(false);
    }, 350),
    setFornecedor(cliente) {
      if (!cliente) return;
      this.form = cliente;
      this.clienteFilled = true;
    },
    formatEndereco(endereco) {
      if (!endereco) return "";
      const formatData = `${endereco.cep ?? ""}, ${
        endereco.logradouro ?? ""
      }, ${endereco.numero ?? ""}, ${endereco.bairro ?? ""} - ${
        endereco?.cidade?.cidade ?? ""
      } / ${endereco?.cidade?.uf ?? ""}`;
      return formatData;
    },
  },
  async created() {
    this.tipoEndereco = await this.getTipoEndereco();
    if (Object.keys(this.$route.params).length !== 0) {
      this.handleLoading(true);
      const id_pessoa = this.$route.params.ordemData.fornecedor.id_pessoa;
      this.$http
        .post("/pessoa/find", {
          id_pessoa,
        })
        .then((response) => {
          this.setFornecedor(response.data);
        })
        .catch((error) =>
          this.$toast.error(`Erro ao buscar fornecedor:\n${error}`)
        );
      this.handleLoading(false);
    }
  },
  computed: {
    ...mapGetters([
      "isAuthenticated",
      "breadcrumbs",
      "pageTitle",
      "layoutConfig",
    ]),
    ...mapState(["loading"]),
    isLoading() {
      return this.loading.loading;
    },
    isTheCurrentTab() {
      return store.currentTab === 0;
    },
  },
  watch: {
    "store.formFornecedor"(newValue, oldValue) {
      if (JSON.stringify(newValue) !== JSON.stringify(oldValue)) {
        this.keyToReRender++;
      }
    },
    form: {
      handler(newValue) {
        this.store.formFornecedor = {
          ...this.store.formFornecedor,
          ...newValue,
        };
      },
      deep: true,
    },
    "store.currentTab"(newValue, oldValue) {
      if (newValue !== oldValue && newValue === 1 && oldValue < newValue) {
        this.$v.form.$touch();
        if (this.$v.form.$anyError) {
          if (this.$v.form.$anyError) {
            this.store.currentTab = oldValue;
            if (this.$v.form.nome.$anyError) {
              this.$toast.error("Informe o fornecedor!");
              this.clienteError = true;
            }
          }
          return;
        }
      }
    },
  },
};
</script>
