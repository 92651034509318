import pedidos from "@/modules/Pedidos/pages/Index";
import pedidosNovo from "@/modules/Pedidos/pages/Create";

export default [
  {
    name: "pedidos",
    path: "/pedidos",
    component: pedidos,
    meta: { title: "Pedidos" },
  },
  {
    path: "/pedidos-novo",
    name: "pedidosNovo",
    component: pedidosNovo,
    meta: { title: "Pedidos" },
  },
];
