<template>
  <div>
    <div class="card card-custom gutter-b">
      <div class="card-header card-header-tabs-line">
        <ul
          class="nav nav-dark nav-bold nav-tabs nav-tabs-line"
          role="tablist"
          ref="builder-tab"
        >
          <li class="nav-item">
            <a
              class="nav-link active"
              v-on:click="setActiveTab"
              data-tab="0"
              data-toggle="tab"
              href="#"
              role="tab"
            >
              Geral
            </a>
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              v-on:click="setActiveTab"
              data-tab="1"
              data-toggle="tab"
              href="#"
              role="tab"
            >
              Alíquotas
            </a>
          </li>
        </ul>
      </div>

      <b-form class="form" @submit.stop.prevent="onSubmit">
        <div class="card-body">
          <b-tabs class="hide-tabs" v-model="tabIndex">
            <b-tab active>
              <b-row>
                <b-col md="4">
                  <b-form-group
                    id="descricao"
                    label-for="descricao"
                    label="Descrição"
                    placeholder="Venda de mercadoria"
                  >
                    <b-form-input
                      v-model="$v.form.descricao.$model"
                      :state="validateState('descricao')"
                    >
                    </b-form-input>
                  </b-form-group>
                </b-col>
                <b-col md="2">
                  <b-form-group
                    id="base_icms"
                    label-for="cofins"
                    label="% Base ICMS"
                  >
                    <money
                      class="form-control"
                      v-model="form.base_icms"
                      v-bind="configMoney"
                    >
                    </money>
                  </b-form-group>
                </b-col>
                <b-col md="2">
                  <b-form-group
                    id="base_icmsfe"
                    label-for="cofins"
                    label="% Base ICMS F.E"
                  >
                    <money
                      class="form-control"
                      v-model="form.base_icmsfe"
                      v-bind="configMoney"
                    >
                    </money>
                  </b-form-group>
                </b-col>
                <b-col md="2">
                  <b-form-group
                    id="base_icmsst"
                    label-for="cofins"
                    label="% Base ICMS ST"
                  >
                    <money
                      class="form-control"
                      v-model="form.base_icmsst"
                      v-bind="configMoney"
                    >
                    </money>
                  </b-form-group>
                </b-col>
                <b-col md="2">
                  <b-form-group
                    id="diferimento"
                    label-for="cofins"
                    label="% Diferimento"
                  >
                    <money
                      class="form-control"
                      v-model="form.diferimento"
                      v-bind="configMoney"
                    >
                    </money>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-tab>
            <b-tab>
              <b-row>
                <b-col md="2">
                  <b-form-group label="AC">
                    <money
                      class="form-control"
                      v-model="form.aliquota.ac"
                      v-bind="configMoney"
                    >
                    </money>
                  </b-form-group>
                </b-col>
                <b-col md="2">
                  <b-form-group label="AL">
                    <money
                      class="form-control"
                      v-model="form.aliquota.al"
                      v-bind="configMoney"
                    >
                    </money>
                  </b-form-group>
                </b-col>
                <b-col md="2">
                  <b-form-group label="AM">
                    <money
                      class="form-control"
                      v-model="form.aliquota.am"
                      v-bind="configMoney"
                    >
                    </money>
                  </b-form-group>
                </b-col>
                <b-col md="2">
                  <b-form-group label="AP">
                    <money
                      class="form-control"
                      v-model="form.aliquota.ap"
                      v-bind="configMoney"
                    >
                    </money>
                  </b-form-group>
                </b-col>
                <b-col md="2">
                  <b-form-group label="BA">
                    <money
                      class="form-control"
                      v-model="form.aliquota.ba"
                      v-bind="configMoney"
                    >
                    </money>
                  </b-form-group>
                </b-col>
                <b-col md="2">
                  <b-form-group label="CE">
                    <money
                      class="form-control"
                      v-model="form.aliquota.ce"
                      v-bind="configMoney"
                    >
                    </money>
                  </b-form-group>
                </b-col>
                <b-col md="2">
                  <b-form-group label="DF">
                    <money
                      class="form-control"
                      v-model="form.aliquota.df"
                      v-bind="configMoney"
                    >
                    </money>
                  </b-form-group>
                </b-col>
                <b-col md="2">
                  <b-form-group label="ES">
                    <money
                      class="form-control"
                      v-model="form.aliquota.es"
                      v-bind="configMoney"
                    >
                    </money>
                  </b-form-group>
                </b-col>
                <b-col md="2">
                  <b-form-group label="GO">
                    <money
                      class="form-control"
                      v-model="form.aliquota.go"
                      v-bind="configMoney"
                    >
                    </money>
                  </b-form-group>
                </b-col>
                <b-col md="2">
                  <b-form-group label="MA">
                    <money
                      class="form-control"
                      v-model="form.aliquota.ma"
                      v-bind="configMoney"
                    >
                    </money>
                  </b-form-group>
                </b-col>
                <b-col md="2">
                  <b-form-group label="MG">
                    <money
                      class="form-control"
                      v-model="form.aliquota.mg"
                      v-bind="configMoney"
                    >
                    </money>
                  </b-form-group>
                </b-col>
                <b-col md="2">
                  <b-form-group label="MS">
                    <money
                      class="form-control"
                      v-model="form.aliquota.ms"
                      v-bind="configMoney"
                    >
                    </money>
                  </b-form-group>
                </b-col>
                <b-col md="2">
                  <b-form-group label="MT">
                    <money
                      class="form-control"
                      v-model="form.aliquota.mt"
                      v-bind="configMoney"
                    >
                    </money>
                  </b-form-group>
                </b-col>
                <b-col md="2">
                  <b-form-group label="PA">
                    <money
                      class="form-control"
                      v-model="form.aliquota.pa"
                      v-bind="configMoney"
                    >
                    </money>
                  </b-form-group>
                </b-col>
                <b-col md="2">
                  <b-form-group label="PB">
                    <money
                      class="form-control"
                      v-model="form.aliquota.pb"
                      v-bind="configMoney"
                    >
                    </money>
                  </b-form-group>
                </b-col>
                <b-col md="2">
                  <b-form-group label="PE">
                    <money
                      class="form-control"
                      v-model="form.aliquota.pe"
                      v-bind="configMoney"
                    >
                    </money>
                  </b-form-group>
                </b-col>
                <b-col md="2">
                  <b-form-group label="PI">
                    <money
                      class="form-control"
                      v-model="form.aliquota.pi"
                      v-bind="configMoney"
                    >
                    </money>
                  </b-form-group>
                </b-col>
                <b-col md="2">
                  <b-form-group label="PR">
                    <money
                      class="form-control"
                      v-model="form.aliquota.pr"
                      v-bind="configMoney"
                    >
                    </money>
                  </b-form-group>
                </b-col>
                <b-col md="2">
                  <b-form-group label="RJ">
                    <money
                      class="form-control"
                      v-model="form.aliquota.rj"
                      v-bind="configMoney"
                    >
                    </money>
                  </b-form-group>
                </b-col>
                <b-col md="2">
                  <b-form-group label="RN">
                    <money
                      class="form-control"
                      v-model="form.aliquota.rn"
                      v-bind="configMoney"
                    >
                    </money>
                  </b-form-group>
                </b-col>
                <b-col md="2">
                  <b-form-group label="RO">
                    <money
                      class="form-control"
                      v-model="form.aliquota.ro"
                      v-bind="configMoney"
                    >
                    </money>
                  </b-form-group>
                </b-col>
                <b-col md="2">
                  <b-form-group label="RR">
                    <money
                      class="form-control"
                      v-model="form.aliquota.rr"
                      v-bind="configMoney"
                    >
                    </money>
                  </b-form-group>
                </b-col>
                <b-col md="2">
                  <b-form-group label="RS">
                    <money
                      class="form-control"
                      v-model="form.aliquota.rs"
                      v-bind="configMoney"
                    >
                    </money>
                  </b-form-group>
                </b-col>
                <b-col md="2">
                  <b-form-group label="SC">
                    <money
                      class="form-control"
                      v-model="form.aliquota.sc"
                      v-bind="configMoney"
                    >
                    </money>
                  </b-form-group>
                </b-col>
                <b-col md="2">
                  <b-form-group label="SE">
                    <money
                      class="form-control"
                      v-model="form.aliquota.se"
                      v-bind="configMoney"
                    >
                    </money>
                  </b-form-group>
                </b-col>
                <b-col md="2">
                  <b-form-group label="SP">
                    <money
                      class="form-control"
                      v-model="form.aliquota.sp"
                      v-bind="configMoney"
                    >
                    </money>
                  </b-form-group>
                </b-col>
                <b-col md="2">
                  <b-form-group label="TO">
                    <money
                      class="form-control"
                      v-model="form.aliquota.to"
                      v-bind="configMoney"
                    >
                    </money>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-tab>
          </b-tabs>
        </div>

        <div class="card-footer">
          <div class="row">
            <div class="col-lg-12" style="text-align: center">
              <button
                type="submit"
                ref="submit_nova_tributacao"
                class="btn btn-primary font-weight-bold"
              >
                Salvar
              </button>

              <router-link
                class="btn btn-clean font-weight-bold"
                :to="{
                  name: 'tributacoesLista',
                }"
              >
                Cancelar
              </router-link>
            </div>
          </div>
        </div>
      </b-form>
    </div>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { SistemaMixin } from "@/modules/Application";
import TributacaoData, { TributacaoDataRequired } from "./TributacaoData";
import { Money } from "v-money";

export default {
  name: "app-tributacao-form",
  props: {
    url: {
      type: String,
    },
    tributacao: {
      type: Object,
      default: () => {},
    },
  },
  mixins: [validationMixin, SistemaMixin],
  data() {
    return {
      tabIndex: 0,
      form: {},
      configMoney: {
        decimal: ",",
        thousands: ".",
        prefix: "",
        suffix: "%",
        precision: 2,
        masked: false,
      },
      aliquota: [],
    };
  },
  components: {
    Money,
  },
  validations: {
    form: TributacaoDataRequired,
  },
  tributacao: {
    produto(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.form = newValue;
      }
    },
  },
  mounted() {
    this.getDataInit();
  },
  methods: {
    async getDataInit() {
      this.form = new TributacaoData({});
      this.aliquota = this.form.aliquota;
      if (this.tributacao.id) {
        this.form = this.tributacao;
      }
    },
    setActiveTab(event) {
      const tab = event.target.closest('[role="tablist"]');
      const links = tab.querySelectorAll(".nav-link");

      for (let i = 0; i < links.length; i++) {
        links[i].classList.remove("active");
      }

      this.tabIndex = parseInt(event.target.getAttribute("data-tab"));

      event.target.classList.add("active");
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    async onSubmit() {
      const submitButton = this.$refs["submit_nova_tributacao"];
      try {
        this.$v.form.$touch();

        if (this.$v.form.$anyError) {
          return;
        }

        submitButton.classList.add("spinner", "spinner-light", "spinner-right");
        submitButton.setAttribute("disabled", "");

        await this.$http.post(this.url, this.form);

        this.$router.push({ name: "tributacoesLista" });
      } catch (error) {
        submitButton.classList.remove(
          "spinner",
          "spinner-light",
          "spinner-right"
        );
        submitButton.removeAttribute("disabled", "");
      }
    },
  },
};
</script>

<style>
.vs__dropdown-toggle {
  border: 2px solid #dedede;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border-radius: 6px;
  outline: none;
  height: 38px;
}
</style>
