<template>
  <div class="card card-custom card-stretch gutter-b">
    <div class="card-header border-0 py-5">
      <h3 class="card-title align-items-start flex-column">
        <span class="card-label font-weight-bolder text-dark">
          Editar Movimentação Financeira
        </span>
      </h3>
    </div>
    <Form url="/financeiro/update" :financeiro="financeiro" />
  </div>
</template>

<script>
import { mapActions } from "vuex";
import Form from "./Form.vue";
import { mapGetters } from "vuex";

export default {
  name: "app-financeiro-update",
  data() {
    return {
      financeiro: {},
    };
  },
  components: {
    Form,
  },
  computed: {
    ...mapGetters(["itsblocked"]),
  },
  methods: {
    ...mapActions(["setLoading"]),
    async getfinanceiro(id) {
      const { data } = await this.$http.post("/financeiro/find", {
        id: id,
      });

      return data;
    },
    async getInit() {
      try {
        const id = this.$route.params.id;

        this.setLoading(true);

        const financeiro = await this.getfinanceiro(id);

        this.setLoading(false);

        if (!financeiro.id || financeiro == null) {
          this.$router.push({ name: "financeiroLista" });
          return this.$toast.error("Movimento não encontrado!");
        }

        this.financeiro = await financeiro;
      } catch {
        return;
      }
    },
  },
  mounted() {
    if (this.itsblocked) {
      this.$router.push({ name: "assinatura" });
    }

    this.getInit();
  },
};
</script>
