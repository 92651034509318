<template>
  <div class="card card-custom card-stretch gutter-b">
    <div class="card-header border-0 py-5">
      <h3 class="card-title align-items-start flex-column">
        <span class="card-label font-weight-bolder text-dark">
          Editar Configurações
        </span>
      </h3>
    </div>
    <Form url="/configuracoes/update" :configuracao="configuracao" />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Form from "./Form.vue";
import ConfiguracaoData from "./ConfiguracaoData";

export default {
  name: "app-configuracoes-update",
  data() {
    return {
      configuracao: new ConfiguracaoData({}),
    };
  },
  components: {
    Form,
  },
  computed: {
    ...mapGetters(["itsblocked"]),
  },
  methods: {
    ...mapActions(["setLoading"]),
    async getConfiguracao(id_configuracao) {
      try {
        const { data } = await this.$http.post("/configuracoes/find", {
          id: id_configuracao,
        });
        return data;
      } catch (error) {
        return null;
      }
    },
    async getInit() {
      try {
        const codigo = this.$route.params.id;

        this.setLoading(true);

        const configuracao = await this.getConfiguracao(codigo);

        this.setLoading(false);

        if (!configuracao.id || configuracao == null) {
          this.$router.push({ name: "configuracoesLista" });
          return this.$toast.error("Configuração não encontrada!");
        }

        this.configuracao = new ConfiguracaoData(configuracao);
      } catch {
        return;
      }
    },
  },
  mounted() {
    if (this.itsblocked) {
      this.$router.push({ name: "assinatura" });
    }
    this.getInit();
  },
};
</script>
