<template>
  <Modal :value="open" :onClose="close" :close="false">
    <b-row class="form-group">
      <b-col class="busca" md="6">
        <Input
          ref="descricao"
          label="Descrição"
          id="descricao"
          @keypress.enter.prevent="save"
          labelBold
          v-model="valueProduto.descricao"
        />
      </b-col>
      <b-col>
        <b-form-group label="Quantidade" label-for="quantidade">
          <money
            class="form-control"
            id="quantidade"
            name="quantidade"
            v-model="valueProduto.quantidade"
            v-bind="quantityOptions"
          />
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group label="Valor Unitário" label-for="preco">
          <money
            class="form-control"
            id="preco"
            name="preco"
            v-model="valueProduto.preco"
            v-bind="priceOptions"
          />
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group label="Desconto" label-for="desconto">
          <money
            class="form-control"
            id="desconto"
            name="desconto"
            v-model="valueProduto.desconto"
            v-bind="configMask"
          />
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="4">
        <b-form-group label="Total de Item R$" label-for="total">
          <money
            class="form-control"
            id="total"
            name="total"
            disabled
            v-model="totalProduto"
            v-bind="priceOptions"
          />
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-form-group id="observacao" label="Observação" label-for="observacao">
          <b-form-textarea
            rows="6"
            max-rows="10"
            id="observacao"
            name="observacao"
            placeholder=""
            v-model="valueProduto.obs"
          >
          </b-form-textarea>
        </b-form-group>
      </b-col>
    </b-row>
    <div class="confirm-buttons">
      <Button type="error" @click="close()">Fechar</Button>
      <Button
        type="success"
        @click="
          () => {
            save();
            close();
          }
        "
        >Gravar</Button
      >
    </div>
  </Modal>
</template>

<script>
import { Modal } from "@/components";
import { Button, Input } from "@/components/Form";
import { Money } from "v-money";
import {
  MONEY as settingMoney,
  priceOptions,
  quantityOptions,
} from "@/modules/Application";
import { SistemaMixin } from "@/modules/Application";
import FormatMixin from "@/modules/Application/mixins/FormatMixin";
import PessoaMixin from "@/modules/Application/mixins/PessoaMixin";

export default {
  name: "ModalProduto",
  mixins: [FormatMixin, PessoaMixin, SistemaMixin],
  components: {
    Modal,
    Button,
    Input,
    Money,
  },
  data() {
    return {
      valueProduto: {},
      priceOptions,
      quantityOptions,
      configMask: settingMoney,
    };
  },
  props: {
    open: {
      type: Boolean,
      default: false,
    },
    close: {
      type: Function,
      required: true,
    },
    changeProduto: {
      type: Function,
      required: true,
    },
    produto: {
      type: Object,
      required: true,
    },
  },
  watch: {
    produto(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.valueProduto = newValue;
      }
    },
  },
  mounted() {
    this.valueProduto = this.produto;
    this.getDataInit();
  },
  methods: {
    save() {
      this.valueProduto = {
        ...this.valueProduto,
      };
      this.changeProduto(this.valueProduto);
      this.$toast.success("Produto editado com sucesso!");
    },
    async getDataInit() {},
    setProduto(produto) {
      this.valueProduto = produto;
    },
  },
  computed: {
    totalProduto() {
      return (
        this.valueProduto.quantidade * this.valueProduto.preco -
        this.valueProduto.desconto
      );
    },
  },
};
</script>
