import { required } from "vuelidate/lib/validators";

export default class VendedorData {
  nome = "";

  status = true;

  id_vendedor = null;

  comissao_vista = 0;

  comissao_prazo = 0;

  constructor({
    nome = "",
    status = true,
    id_vendedor = null,
    comissao_vista = 0,
    comissao_prazo = 0,
  }) {
    this.nome = nome;
    this.status = status;
    this.id_vendedor = id_vendedor;
    this.comissao_vista = comissao_vista;
    this.comissao_prazo = comissao_prazo;
  }
}

export const VendedorDataRequired = {
  nome: {
    required,
  },
};
