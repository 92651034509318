import { required } from "vuelidate/lib/validators";

export default class OthersData {
  natureza = null;
  dataEmissao = null;
  dataEntrada = null;
  numeroNFe = null;
  serieNFe = null;
  modeloNFe = null;
  pagamentos = [];
  fornecedor = {};
  produtos = [];
  impostos = {};
  chaveDeAcesso = null
  constructor(
    natureza = null,
    dataEmissao = null,
    dataEntrada = null,
    numeroNFe = null,
    serieNFe = null,
    modeloNFe = null,
    pagamentos = [],
    fornecedor = {},
    produtos = [],
    impostos = {},
    chaveDeAcesso = null,
  ) {
    this.natureza = natureza;
    this.dataEmissao = dataEmissao;
    this.dataEntrada = dataEntrada;
    this.numeroNFe = numeroNFe;
    this.serieNFe = serieNFe;
    this.modeloNFe = modeloNFe;
    this.pagamentos = pagamentos;
    this.fornecedor = fornecedor;
    this.produtos = produtos;
    this.impostos = impostos;
    this.chaveDeAcesso = chaveDeAcesso;
  }
}

export const OthersDataRequired = {
  dataEmissao: {
    required,
  },
  dataEntrada: {
    required,
  },
  numeroNFe: {
    required,
  },
  serieNFe: {
    required,
  },
  modeloNFe: {
    required,
  },
};
