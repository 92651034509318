export default class FornecedorData {
  nome = null;
  nome_fantasia = null;
  cnpj_cpf = null;
  ie_rg = null;
  inscricao_municipal = null;
  cep = null;
  logradouro = null;
  numero = null;
  complemento = null;
  bairro = null;
  cep = null;
  uf = null;
  cidade = null;
  fone = null;
  id_pessoa = null;
  constructor(
    nome = null,
    nome_fantasia = null,
    cnpj_cpf = null,
    ie_rg = null,
    inscricao_municipal = null,
    cep = null,
    logradouro = null,
    numero = null,
    complemento = null,
    bairro = null,
    uf = null,
    cidade = null,
    fone = null,
    id_pessoa = null,
  ) {
    this.nome = nome;
    this.nome_fantasia = nome_fantasia;
    this.cnpj_cpf = cnpj_cpf;
    this.ie_rg = ie_rg;
    this.inscricao_municipal = inscricao_municipal;
    this.cep = cep;
    this.logradouro = logradouro;
    this.numero = numero;
    this.complemento = complemento;
    this.bairro = bairro;
    this.uf = uf;
    this.cidade = cidade;
    this.fone = fone;
    this.id_pessoa = id_pessoa;
  }
}
