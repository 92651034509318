<template>
  <b-tab
    title="Tomador"
    :active="isTheCurrentTab"
    :disabled="!isTheCurrentTab"
    class="mt-5"
  >
    <b-row>
      <b-col>
        <b-form-group label="Nome" label-for="nome">
          <VueSelect
            ref="buscarCliente"
            :filterable="false"
            @input="setCliente"
            :class="{ hasErrors: clienteError, inputFilled: clienteFilled }"
            :options="clients"
            @search="onSearchCliente"
            v-model="store.formTomador"
          >
            <template slot="no-options">Informe o nome do cliente</template>
            <template slot="option" slot-scope="option">
              <div class="d-flex justify-content-between">
                <div>
                  {{ option.nome }}
                </div>
                <span class="text-muted"
                  >CPF/CNPJ: {{ formatCpfCnpj(option.cnpj_cpf) }}</span
                >
              </div>
            </template>
            <template slot="selected-option" slot-scope="option">
              <div class="selected d-center">
                {{ option.nome }}
              </div>
            </template>
          </VueSelect>
        </b-form-group>
      </b-col>
      <b-col md="4">
        <b-form-group label="CPF/CNPJ" label-for="cnpj_cpf">
          <b-form-input
            :key="keyToReRender"
            disabled
            id="cnpj_cpf"
            name="cnpj_cpf"
            v-model="store.formTomador.cnpj_cpf"
          >
          </b-form-input>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col sm="8">
        <b-form-group
          :key="keyToReRender"
          label="Endereço"
          label-for="endereco"
        >
          <VueSelect
            ref="endereco"
            :filterable="false"
            @input="setEndereco"
            :options="enderecos"
            :class="{ hasErrors: enderecoError, inputFilled: enderecoFilled }"
            @search="onSearchEndereco"
            :value="valueAdress"
          >
            <template slot="no-options"> Informe o Endereço </template>
            <template slot="option" slot-scope="option">
              <div class="d-center">
                {{
                  `${option.cep ?? ""}, ${option.logradouro ?? ""}, ${
                    option.numero ?? ""
                  }, ${option.bairro ?? ""} - ${
                    option?.cidade?.cidade ?? ""
                  } / ${option?.cidade?.uf ?? ""}`
                }}
              </div>
            </template>
            <template slot="selected-option" slot-scope="option">
              <div class="selected d-center">
                {{ option.label }}
              </div>
            </template>
          </VueSelect>
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group id="emissao" label-for="emissao" label="Data de emissão">
          <b-form-datepicker
            v-model="emissao"
            locale="pt-br"
            :date-format-options="{
              year: 'numeric',
              month: 'numeric',
              day: 'numeric',
            }"
          >
          </b-form-datepicker>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <div class="border-bottom border-light py-3">
        <b-col md="12">
          <p class="font-weight-bolder text-dark mb-2">Calcular retenção</p>
          <span class="switch switch-icon">
            <label v-b-tooltip.hover.right="'Não/Sim'">
              <input
                type="checkbox"
                v-model="store.formTomador.realizar_reducao"
              />
              <span></span>
            </label>
          </span>
        </b-col>
      </div>
      <div
        class="border-bottom border-light py-3"
        v-show="
          store.formTomador.cnpj_cpf && store.formTomador.cnpj_cpf.length >= 14
        "
      >
        <b-col md="12">
          <p class="font-weight-bolder text-dark mb-2">Situação Tributária</p>
          <span class="switch switch-icon">
            <label v-b-tooltip.hover.right="'Não/Sim'">
              <input
                type="checkbox"
                v-model="store.formTomador.calcular_substituicao"
              />
              <span></span>
            </label>
          </span>
        </b-col>
      </div>
      <b-col md="8">
        <button
          ref="add_buyer"
          class="btn btn-primary font-weight-bold"
          v-b-modal.newClient
          style="font-size: 11px; margin-top: 30px; float: right"
        >
          Novo Cliente
        </button>
      </b-col>
    </b-row>
    <b-modal id="newClient" size="lg" centered title="Informe os dados">
      <b-row> </b-row>
      <b-row>
        <b-col md="6">
          <b-form-group label="Nome" label-for="nome">
            <b-form-input
              id="nome"
              name="nome"
              v-model="nomeCliente"
              maxlength="50"
              required
            >
            </b-form-input>
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group label="CPF/CNPJ" label-for="cnpj_cpf">
            <b-form-input
              id="cpfCnpjCliente"
              name="cpfCnpjCliente"
              v-mask="['###.###.###-##', '###.###.###/####-##']"
              v-model="cpfCnpjCliente"
            >
            </b-form-input>
          </b-form-group>
        </b-col>
        <b-col md="4">
          <b-form-group label="CEP" label-for="formEndereco.cep">
            <b-form-input
              ref="cep"
              id="formEndereco.cep"
              name="formEndereco.cep"
              v-model="formEndereco.cep"
              v-mask="['#####-###']"
              aria-describedby="formEndereco.cep"
            >
            </b-form-input>
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group style="margin-top: 30px">
            <a class="btn btn-primary font-weight-bold" @click="consultaCep">
              Consultar CEP
            </a>
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group label="Número" label-for="formEndereco.numero">
            <b-form-input
              id="formEndereco.numero"
              name="formEndereco.numero"
              v-model="formEndereco.numero"
              aria-describedby="formEndereco.numero"
            >
            </b-form-input>
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group label="Bairro" label-for="formEndereco.bairro">
            <b-form-input
              id="formEndereco.bairro"
              name="formEndereco.bairro"
              v-model="formEndereco.bairro"
              aria-describedby="formEndereco.bairro"
            >
            </b-form-input>
          </b-form-group>
        </b-col>
        <b-col md="5">
          <b-form-group label="Logradouro" label-for="formEndereco.logradouro">
            <b-form-input
              id="formEndereco.logradouro"
              name="formEndereco.logradouro"
              v-model="formEndereco.logradouro"
              aria-describedby="formEndereco.logradouro"
            >
            </b-form-input>
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group
            label="Complemento"
            label-for="formEndereco.complemento"
          >
            <b-form-input
              id="formEndereco.complemento"
              name="formEndereco.complemento"
              v-model="formEndereco.complemento"
              aria-describedby="formEndereco.complemento"
            >
            </b-form-input>
          </b-form-group>
        </b-col>
        <b-col md="4">
          <b-form-group label="Cidade" label-for="formEndereco.cidade">
            <VueSelect
              label="cidade"
              @input="setCidade"
              :options="cidades"
              @search="onSearch"
            >
              <template slot="no-options"> Informe o nome da cidade </template>
              <template slot="option" slot-scope="option">
                <div class="d-center">
                  {{ option.cidade + " - " + option.uf }}
                </div>
              </template>
              <template slot="selected-option" slot-scope="option">
                <div class="selected d-center">
                  {{ option.cidade + " - " + option.uf }}
                </div>
              </template>
            </VueSelect>
          </b-form-group>
        </b-col>
        <b-col md="1">
          <button
            type="button"
            style="margin-top: 25px; margin-right: 15px"
            @click="onSubmitEndereco"
            class="btn btn-light-primary btn-bold"
          >
            Adicionar
          </button>
        </b-col>
        <div class="table-responsive">
          <b-col md="12">
            <table class="table table-hover">
              <thead>
                <tr>
                  <th>CEP</th>
                  <th>Logradouro</th>
                  <th>Bairro</th>
                  <th>Número</th>
                  <th>Complemento</th>
                  <th>Cidade</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(e, index) in enderecos"
                  :key="'endereco_' + index"
                >
                  <td>{{ e.cep }}</td>
                  <td>{{ e.logradouro }}</td>
                  <td>{{ e.bairro }}</td>
                  <td>{{ e.numero }}</td>
                  <td>{{ e.complemento }}</td>
                  <td>
                    {{
                      e.cidade == null
                        ? ""
                        : e.cidade.cidade + " - " + e.cidade.uf
                    }}
                  </td>
                  <td align="right">
                    <button
                      @click="
                        () => {
                          $toast.error('Endereço excluído');
                          enderecos.splice(index, 1);
                        }
                      "
                      class="btn btn-light-primary btn-sm btn-bold"
                    >
                      <Close :size="16" />
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </b-col>
        </div>
      </b-row>
      <template #modal-footer="{ ok, cancel }">
        <b-button
          @click="
            () => {
              cancel();
              openModal = false;
            }
          "
        >
          Cancelar
        </b-button>
        <b-button variant="primary" ref="saveClient" @click="saveClient">
          Confirmar
        </b-button>
      </template>
    </b-modal>
  </b-tab>
</template>

<script>
import { mapGetters, mapState, mapActions } from "vuex";
import VueSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import { validationMixin } from "vuelidate";
import {
  SistemaMixin,
  ValidacaoMixin,
  PessoaMixin,
} from "@/modules/Application";
import _ from "lodash";
import { mask } from "vue-the-mask";
import { store } from "../../storeServicos";
import ClienteData, { ClienteDataRequired } from "../ClienteData";
import Close from "vue-material-design-icons/Close.vue";

export default {
  name: "app-nfse-form-tomador",
  components: {
    VueSelect,
    Close,
  },
  directives: {
    mask,
  },
  data() {
    return {
      store,
      emissao: null,
      firstLoad: false,
      clientSelected: {
        cnpj_cpf: null,
      },
      clients: [],
      clienteFilled: false,
      clienteError: false,
      enderecoError: false,
      enderecoFilled: false,
      keyToReRender: 0,
      enderecos: [],
      realizar_reducao: false,
      calcular_substituicao: false,
      nomeCliente: null,
      cpfCnpjCliente: null,
      cidades: [],
      formEndereco: {},
    };
  },
  validations: {
    form: ClienteDataRequired,
  },
  mixins: [validationMixin, SistemaMixin, ValidacaoMixin, PessoaMixin],
  methods: {
    ...mapActions(["setLoading"]),
    onSearchEmail() {},
    onSearchCliente(search, loading) {
      if (search.length > 2) {
        loading(true);
        this.searchCliente(loading, search, this);
      }
    },
    searchCliente: _.debounce(async (loading, search, vm) => {
      vm.clients = await vm.getClientes(search);
      loading(false);
    }, 350),
    setCliente(cliente) {
      if (!cliente) return;
      this.store.formTomador = cliente;
      this.clienteFilled = true;
      this.enderecos = cliente.enderecos;
      this.store.formTomador.endereco = cliente.enderecos[0];
      this.keyToReRender++;
    },
    async getTomador(id_pessoa) {
      const pessoa = await this.getPessoa(id_pessoa);
      this.store.formTomador = {
        ...this.store.formTomador,
        ...pessoa,
      };
    },
    searchEndereco: _.debounce(async (loading, search, vm) => {
      vm.enderecos = vm.enderecos.filter((endereco) =>
        endereco.includes(search)
      );
      loading(false);
    }, 350),
    onSearchEndereco(search, loading) {
      if (search.length) {
        loading(true);
        this.searcEndereco(loading, search, this);
      }
    },
    setEndereco(endereco) {
      this.formTomador.endereco = endereco;
      this.keyToReRender++;
      this.enderecoFilled = true;
    },
    formatEndereco(endereco) {
      if (!endereco) return "";
      const formatData = `${endereco.cep ?? ""}, ${
        endereco.logradouro ?? ""
      }, ${endereco.numero ?? ""}, ${endereco.bairro ?? ""} - ${
        endereco?.cidade?.cidade ?? ""
      } / ${endereco?.cidade?.uf ?? ""}`;
      return formatData;
    },
    async saveClient() {
      this.addLoadingToButton("saveClient");

      if (!this.nomeCliente) {
        this.$toast.warning("Informe o nome do cliente");
        this.removeLoadingToButton("saveClient");
        return;
      }
      try {
        var newObjetoData = {
          nome: this.nomeCliente,
          cnpj_cpf: this.cpfCnpjCliente ?? null,
          pessoa_tipo: [
            {
              id_tipo: 1,
              descricao: "Cliente",
            },
          ],
        };

        if (this.enderecos && this.formEndereco.cep) {
          if (!this.cidade) {
            this.$toast.warning("Selecione a cidade");
            this.removeLoadingToButton("saveClient");
            return;
          }

          newObjetoData.endereco = this.enderecos;
        }

        const response = await this.$http.post("/pessoa/create", newObjetoData);
        if (response.data) {
          newObjetoData.id_pessoa = response.data.id_pessoa;
          newObjetoData.enderecos = this.enderecos;
          this.setCliente(newObjetoData);
        }
        this.cidade = {};
        this.formEndereco = {};
        this.nomeCliente = null;
        this.cpfCnpjCliente = null;
        this.enderecos = [];
        this.$bvModal.hide("newClient");
      } catch (error) {
        console.log(error);
      }
      this.removeLoadingToButton("saveClient");
    },
    async onSubmitEndereco() {
      if (!this.cidade.id_cidade) {
        return this.$toast.error("Selecione uma cidade!");
      }

      this.enderecos.push({
        numero: this.formEndereco.numero,
        cep: this.formEndereco.cep,
        logradouro: this.formEndereco.logradouro,
        bairro: this.formEndereco.bairro,
        complemento: this.formEndereco.complemento ?? "Sem Complemento",
        id_endereco_tipo: 1,
        id_cidade: this.cidade.id_cidade,
        cidade: this.cidade,
        tipo: {
          id_tipo: 1,
          descricao: "Principal",
        },
        id_tipo: 1,
      });

      this.$toast.success("Endereço adicionado!");
      return;
    },
    async consultaCep() {
      this.setLoading(true);
      const cep = await this.getCep(this.formEndereco.cep);

      if (cep.length == 0) {
        this.setLoading(false);
        return;
      }

      this.formEndereco.logradouro = cep.logradouro;
      this.formEndereco.bairro = cep.bairro;

      if (cep.erro) {
        this.setLoading(false);
        this.$toast.warning("Confira o CEP informado!");
        return;
      }

      const city = await this.getCidadeByUF(cep.localidade, cep.uf);

      this.cidades = city;
      this.setLoading(false);
    },
    setCidade(cidade) {
      this.cidade = cidade;
    },
    onSearch(search, loading) {
      if (search.length) {
        loading(true);
        this.search(loading, search, this);
      }
    },
    search: _.debounce(async (loading, search, vm) => {
      const city = await vm.getCidade(search);
      vm.cidades = city;
      loading(false);
    }, 350),
  },
  created() {
    if (Object.keys(this.$route.params).length !== 0) {
      const { tomador, data_emissao, endereco } = this.$route.params.nfseData;
      this.getTomador(tomador.id_pessoa);
      this.emissao = data_emissao;
      this.store.formTomador.endereco = endereco;
    } else {
      this.store.formTomador = {};
      this.emissao = this.thisDate();
    }
  },
  computed: {
    ...mapGetters([
      "isAuthenticated",
      "breadcrumbs",
      "pageTitle",
      "layoutConfig",
    ]),
    ...mapState(["loading"]),
    isLoading() {
      return this.loading.loading;
    },
    isTheCurrentTab() {
      return store.currentTab === 0;
    },
    valueAdress() {
      return this.store.formTomador?.endereco
        ? this.formatEndereco(this.store.formTomador.endereco)
        : null;
    },
  },
  watch: {
    "store.currentTab"(newValue, oldValue) {
      if (newValue !== oldValue && newValue === 1 && oldValue < newValue) {
        if (_.isEmpty(this.store.formTomador)) {
          this.$toast.error("Selecione o tomador!");
          this.store.currentTab = oldValue;
          return;
        }
        if (_.isEmpty(this.store.formTomador.endereco)) {
          this.$toast.error("Selecione um endereço!");
          this.store.currentTab = oldValue;
        }
        if (!this.emissao) {
          this.store.currentTab = oldValue;
          this.$toast.error("Informe a data de emissão!");
        }
        this.store.formTomador.emissao = this.emissao;
      }
    },
  },
};
</script>
