<template>
  <b-tab title="Pedágio (Opcional)" :active="isTheCurrentTab" :disabled="!isTheCurrentTab" class="mt-5">
    <b-row>

    </b-row>
    <b-row>
      <b-col md="4">
        <b-form-group id="valor" label-for="valor" label="Valor R$">
          <money class="form-control" v-model="form.valor" v-bind="configMoney" 
            autofocus />
        </b-form-group>
      </b-col>
      <b-col md="8">
        <b-form-group id="numero_compra" label-for="numero_compra" label="Número Compra">
          <b-form-input maxlength="50" v-model="form.numero_compra"  />
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="5">
        <b-form-group id="tipo_combinacao_veiculo" label-for="tipo_combinacao_veiculo" label="Tipo combinação veículo">
          <VueSelect ref="selectTipoEmissor" :filterable="false" :searchable="false" :options="combinacoesVeiculares"
            v-model="form.tipo_combinacao_veiculo" :class="{ hasErrors: unidadeError }"
            @input="() => unidadeError = false">
            <template slot="no-options">
              Selecione o tipo de combinação
            </template>
            <template slot="option" slot-scope="option">
              <div class="d-center">
                {{ option.description }}
              </div>
            </template>
            <template slot="selected-option" slot-scope="option">
              <div class="selected d-center">
                {{ option.description }}
              </div>
            </template>
          </VueSelect>
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group id="tipo_pedagio" label-for="tipo_pedagio" label="Tipo de Pedágio">
          <VueSelect ref="tipo_pedagio" :filterable="false" :searchable="false" :options="tiposPedagio"
            v-model="form.tipo_pedagio" :class="{ hasErrors: tipoPedagioError }" @input="() => tipoPedagioError = false">
            <template slot="no-options">
              Informe o tipo de pedágio
            </template>
            <template slot="option" slot-scope="option">
              <div class="d-center">
                {{ option.descricao }}
              </div>
            </template>
            <template slot="selected-option" slot-scope="option">
              <div class="selected d-center">
                {{ option.descricao }}
              </div>
            </template>
          </VueSelect>
        </b-form-group>
      </b-col>

    </b-row>
    <b-row>
      <b-col md="4">
        <b-form-group id="identificao_responsavel" label-for="identificao_responsavel" label="Identificaçao responsável">
          <b-form-input maxlength="50" v-model="form.identificao_responsavel"
             v-mask="['###.###.###-##']" />
        </b-form-group>
      </b-col>
      <b-col md="4">
        <b-form-group id="responsavel" label-for="responsavel" label="Responsável (CPNJ)">
          <b-form-input id="responsavel" name="responsavel" v-model="form.responsavel.cnpj"
            v-mask="['##.###.###/####-##']"  />
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="12">
        <b-form-group id="observacoes" label="Informações Complementares"
          label-for="observacoes">
          <b-form-textarea rows="6" max-rows="6" id="observacoes" name="observacoes"
            v-model="form.observacoes">
          </b-form-textarea>
        </b-form-group>
      </b-col>
    </b-row>
  </b-tab>
</template>

<script>
import { mapGetters, mapState, mapActions } from "vuex";
import { validationMixin } from "vuelidate";
import { SistemaMixin } from "@/modules/Application";
import { store } from "../../storeLogistica";
import VueSelect from "vue-select";
import _ from "lodash";
import { Money } from "v-money";
import { MONEY as settingMoney } from "@/modules/Application";
import { mask } from "vue-the-mask";

export default {
  name: "app-mdfe-form-pedagioTab",
  components: {
    VueSelect,
    Money,
  },
  directives: {
    mask,
  },
  data() {
    return {
      store,
      configMoney: settingMoney,
      form: {
        tipo_pedagio: null,
        numero_compra: null,
        valor: null,
        identificao_responsavel: null,
        tipo_combinacao_veiculo: null,
        observacoes: null,
        responsavel: {
          cnpj: null,
        }
      },

      tipoPedagioError: false,
      unidadeError: false,
      tiposPedagio: [],
      combinacoesVeiculares: [],
    }
  },
  mixins: [
    validationMixin,
    SistemaMixin,
  ],
  methods: {
    ...mapActions(["setLoading"]),
    async getDataInit() {
      const mdfeData = this.$route.params?.mdfeData?.mdfe;
      const [{ data: pedagioTiposData }, { data: combinacoesVeicularesData }] = await Promise.all([
        this.$http.post('/mdfe/get-pedagio-tipo'),
        this.$http.post('/mdfe/get-combinacao-veicular'),
      ])
      this.tiposPedagio = pedagioTiposData;
      this.combinacoesVeiculares = combinacoesVeicularesData;
      console.log(mdfeData);
      this.form.observacoes = mdfeData?.observacoes ?? ''
      if (mdfeData) {
        this.form.valor = mdfeData.pedagio[0].valor;
        this.form.numero_compra = mdfeData.pedagio[0].numero_compra;
        this.form.tipo_combinacao_veiculo = combinacoesVeicularesData.find(combinacao => combinacao.id = mdfeData.pedagio[0].tipo_combinacao_veiculo);
        this.form.tipo_pedagio = pedagioTiposData.find(pedagio => pedagio.id = mdfeData.pedagio[0].tipo_pedagio);
        this.form.identificao_responsavel = mdfeData.pedagio[0].identificao_responsavel;
        this.form.responsavel = mdfeData.pedagio[0].responsavel;
      }
    },
    onSearchUnidades(search, loading) {
      if (search.length) {
        loading(true);
        this.searchUnidades(loading, search, this);
      }
    },
    searchUnidades: _.debounce(async (loading, nome, vm) => {
      const { data } = await vm.$http.post('/produtos/get-unidade-medida', { nome });
      vm.combinacoesVeiculares = data;
      if (loading) loading(false);
    }, 350),
    validateState(field) {
      return !!this.form[field];
    }
  },
  created() {
    this.getDataInit();
  },
  computed: {
    ...mapGetters([
      "isAuthenticated",
      "breadcrumbs",
      "pageTitle",
      "layoutConfig"
    ]),
    ...mapState(["loading"]),
    isLoading() {
      return this.loading.loading;
    },
    isTheCurrentTab() {
      return store.currentTab === 6
    }
  },
  watch: {
    form: {
      handler(form) {
        this.store.formData = {
          ...this.store.formData,
          observacoes: this.form.observacoes,
          pedagio: [{
            tipo_combinacao_veiculo: this.form.tipo_combinacao_veiculo?.id ?? null,
            tipo_pedagio: form.tipo_pedagio?.id ?? null,
            numero_compra: this.form.numero_compra,
            valor: this.form.valor,
            identificao_responsavel: this.form.identificao_responsavel,
            responsavel: this.form.responsavel
          }],
        }
      }, deep: true
    }
  }
}

</script>

<style></style> 