import { required } from "vuelidate/lib/validators";

export default class LinkData {
  nome_destinatario = null;
  valor = 0;
  data_expiracao = null;
  hora = null;
  vezes = null;
  descricao = null;

  constructor({
    nome_destinatario = null,
    valor = 0,
    data_expiracao = null,
    hora = null,
    vezes = null,
    descricao = null,
  }) {
    this.nome_destinatario = nome_destinatario;
    this.valor = valor;
    this.data_expiracao = data_expiracao;
    this.hora = hora;
    this.vezes = vezes;
    this.descricao = descricao;
  }
}

export const LinkDataRequired = {
  nome_destinatario: {
    required,
  },
  data_expiracao: {
    required,
  },
  hora: {
    required,
  },
  valor: {
    required,
  },
  descricao: {
    required,
  },
};
