<template>
  <b-form-input v-on:keyup.enter="handleEnter" :ref="label" class="form-control" :id="label"
  :name="label" :value="valuePlaceholder" v-money="options" @input="setValue" :disabled="disabled"/>
</template>

<script>
import { VMoney } from "v-money";

export default {
name: "number-input",
components: {
  // Label,
},
directives: {
  money: VMoney,
},
props: {
  label: {
    type: String
  },
  defaultValue: {
    type: Number,
  },
  modelValue: {
    type: Number
  },
  nextInput: {
    type: String,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  onEnter: {
    type: Function,
    default: null,
  }
},
data() {
  return {
    valuePlaceholder: 0,
    options: {
      decimal: ",",
      thousands: ".",
      precision: 2,
      masked: false,
    },
  };
},
watch: {
  defaultValue(newValue, oldValue){
    if(oldValue !== 0 && newValue === 0) this.valuePlaceholder = 0 
    else {
      this.valuePlaceholder = 0
    }
  },
},
methods: {
  setValue(value) {
    const valueFormatted = Number(value.replaceAll(',', '').replaceAll('.', ''))/100
    if(isNaN(valueFormatted)) return
    this.$emit('changeValue', valueFormatted)
  },
  changeInput() {
    const nextInputElement = document.getElementById(this.nextInput)
    nextInputElement.focus();
  },
  handleEnter(){
    if(!this.onEnter) {
      this.changeInput();
    } else {
      if(!this.nextInput) {
        this.onEnter()
      } else {
        this.onEnter()
        this.changeInput();
      }
    }
  }
},
};
</script>
