<template>
  <b-tab
    title="Problema"
    :active="isTheCurrentTab"
    :disabled="!isTheCurrentTab && !store.isUpdate"
    class="mt-5"
    @click="() => store.currentTab = 2"
  >
    <b-row class="p-4">
      <b-col class="w-100">
        <b-form-group label="Problema" label-for="problema">
          <v-textarea full-width rows="6" v-model="form.problema" />
        </b-form-group>
      </b-col>
    </b-row>
  </b-tab>
</template>

<script>
import { mapGetters, mapState, mapActions } from "vuex";
import "vue-select/dist/vue-select.css";
import { validationMixin } from "vuelidate";
import { store } from "../../storeOS";
import { SistemaMixin, ValidacaoMixin } from "@/modules/Application";
import _ from "lodash";

export default {
  name: "app-os-form-problemaTab",
  data() {
    return {
      store,
      loading: false,
      windowWidth: window.innerWidth,
      form: {
        problema: null,
      },
    };
  },
  mixins: [validationMixin, SistemaMixin, ValidacaoMixin],
  methods: {
    ...mapActions(["setLoading"]),
    async getDataInit() {
      if (Object.keys(this.$route.params).length !== 0) {
        const { problema } = this.$route.params.osData;
        this.form.problema = problema;
      }
    },
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
    });
  },
  created() {
    this.getDataInit();
  },
  watch: {
    form: {
      handler(value) {
        this.store.formProblema = value;
      },
      deep: true,
    },
  },
  computed: {
    ...mapGetters([
      "isAuthenticated",
      "breadcrumbs",
      "pageTitle",
      "layoutConfig",
    ]),
    ...mapState(["loading"]),
    isLoading() {
      return this.loading.loading;
    },
    isTheCurrentTab() {
      return store.currentTab === 2;
    },
  },
};
</script>