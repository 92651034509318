<template>
  <Modal :value="open" :onClose="close" :close="false" size="small">
    <h4 class="title">Impostos do Item</h4>
    <b-row class="form-group">
      <b-col class="busca" md="6">
        <Input
          ref="descricao"
          label="Descrição"
          id="descricao"
          @keypress.enter.prevent="save"
          labelBold
          v-model="valueProduto.descricao"
          disabled
        />
      </b-col>
      <b-col>
        <b-form-group label="Quantidade" label-for="quantidade">
          <money
            class="form-control"
            id="quantidade"
            name="quantidade"
            v-model="valueProduto.quantity"
            disabled
            v-bind="configMask"
          />
        </b-form-group>
      </b-col>
      <b-col md="3">
        <b-form-group label="Total do Item R$" label-for="totalProd">
          <money
            class="form-control"
            id="totalProd"
            name="totalProd"
            disabled
            v-model="totalProduto"
            v-bind="configMask"
          />
        </b-form-group>
      </b-col>
      <b-col md="3">
        <b-form-group label="BC ICMS %" label-for="baseICMS">
          <money
            class="form-control"
            id="baseICMS"
            name="baseICMS"
            disabled
            v-model="valueProduto.totais.baseICMS"
            v-bind="configMask"
          />
        </b-form-group>
      </b-col>
      <b-col md="3">
        <b-form-group label="Valor do ICMS R$" label-for="valorICMS">
          <money
            class="form-control"
            id="valorICMS"
            name="valorICMS"
            disabled
            v-model="valueProduto.totais.valorICMS"
            v-bind="configMask"
          />
        </b-form-group>
      </b-col>
      <b-col md="3">
        <b-form-group label="BC ICMS ST %" label-for="baseICMSST">
          <money
            class="form-control"
            id="baseICMSST"
            name="baseICMSST"
            disabled
            v-model="valueProduto.totais.baseICMSST"
            v-bind="configMask"
          />
        </b-form-group>
      </b-col>
      <b-col md="3">
        <b-form-group label="Valor ICMS ST R$" label-for="valorICMSST">
          <money
            class="form-control"
            id="valorICMSST"
            name="valorICMSST"
            disabled
            v-model="valueProduto.totais.valorICMSST"
            v-bind="configMask"
          />
        </b-form-group>
      </b-col>
      <b-col md="3">
        <b-form-group label="Valor IPI R$" label-for="valorIPI">
          <money
            class="form-control"
            id="valorIPI"
            name="valorIPI"
            disabled
            v-model="valueProduto.totais.valorIPI"
            v-bind="configMask"
          />
        </b-form-group>
      </b-col>
    </b-row>
    <b-row> </b-row>
    <div class="confirm-buttons">
      <Button type="error" @click="close()">Fechar</Button>
    </div>
  </Modal>
</template>

<script>
import { Modal } from "@/components";
import { Button, Input } from "@/components/Form";
import { Money } from "v-money";
import { MONEY as settingMoney } from "@/modules/Application";
import { SistemaMixin } from "@/modules/Application";
import FormatMixin from "@/modules/Application/mixins/FormatMixin";
import PessoaMixin from "@/modules/Application/mixins/PessoaMixin";

export default {
  name: "ModalProdutoImpostos",
  mixins: [FormatMixin, PessoaMixin, SistemaMixin],
  components: {
    Modal,
    Button,
    Input,
    Money,
  },
  data() {
    return {
      valueProduto: {},
      configMask: settingMoney,
      calculoAutomatico: false,
    };
  },
  props: {
    open: {
      type: Boolean,
      default: false,
    },
    close: {
      type: Function,
      required: true,
    },
    produto: {
      type: Object,
      required: true,
    },
  },
  watch: {
    produto(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.valueProduto = newValue;
      }
    },
  },
  mounted() {
    this.valueProduto = this.produto;
  },
  methods: {},
  computed: {
    totalProduto() {
      return (this.valueProduto.quantity * this.valueProduto.price) - this.valueProduto.discount
    },
  },
};
</script>
