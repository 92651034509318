<template>
  <TableApp
    titulo="Cadastro de Links de pagamento"
    acaoBtn="/#/links/novo"
    textoBtn="Novo"
    api-url="/link/lista"
    :fields="fields"
    classFilterColumn="links"
    ref="links"
    :perPage="10"
  >
    <template slot="customRow" scope="props">
      <span slot="id_link" v-if="props.field == 'id_link'">
        <span class="pl-0 pt-8">
          <div class="d-flex align-items-center">
            <div>
              <a
                class="
                  text-dark-75
                  font-weight-bolder
                  text-hover-primary
                  mb-1
                  font-size-lg
                "
                >{{ props.rowData.id_link }}</a
              >
            </div>
          </div>
        </span>
      </span>
      <span slot="descricao" v-if="props.field == 'descricao'">
        <span class="pl-0 pt-8">
          <div class="d-flex align-items-center">
            <div>
              <a
                class="
                  text-dark-75
                  font-weight-bolder
                  text-hover-primary
                  mb-1
                  font-size-lg
                "
                >{{ props.rowData.descricao }}</a
              >
            </div>
          </div>
        </span>
      </span>
      <span slot="nome_destinatario" v-if="props.field == 'nome_destinatario'">
        <span class="pl-0 pt-8">
          <div class="d-flex align-items-center">
            <div>
              <a
                class="
                  text-dark-75
                  font-weight-bolder
                  text-hover-primary
                  mb-1
                  font-size-lg
                "
                >{{ props.rowData.nome_destinatario }}</a
              >
            </div>
          </div>
        </span>
      </span>
      <span slot="valor" v-if="props.field == 'valor'">
        <div class="font-weight-bold text-muted">
          R$ {{ numberToReal(props.rowData.valor, 2) }}
        </div>
      </span>
      <span slot="data_expiracao" v-if="props.field == 'data_expiracao'">
        <div class="font-weight-bold text-muted">
          {{ formatDate(props.rowData.data_expiracao, "DD/MM/YYYY HH:mm") }}
        </div>
      </span>
      <span slot="data_pagamento" v-if="props.field == 'data_pagamento'">
        <div class="font-weight-bold text-muted">
          {{ formatDate(props.rowData.data_pagamento, "DD/MM/YYYY HH:mm") }}
        </div>
      </span>
      <span slot="vezes" v-if="props.field == 'vezes'">
        <div class="font-weight-bold text-muted">
          {{ props.rowData.vezes }}
        </div>
      </span>
      <span slot="status" v-if="props.field == 'status'">
        <span
          v-if="props.rowData.status == 1"
          class="label label-lg label-inline label-light-primary"
          >Em Aberto</span
        >
        <span v-else class="label label-lg label-inline label-light-success"
          >Pago</span
        >
      </span>
      <span slot="actions" v-if="props.field == 'actions'">
        <div class="row-actions" style="text-align: right !important">
          <span style="overflow: visible; position: relative; width: 130px">
            <div class="dropdown dropdown-inline">
              <button
                v-if="props.rowData.status == 1"
                @click="doCopy(props.rowData)"
                class="btn btn-sm btn-success btn-hover-success btn-icon mr-2"
                v-b-tooltip="'Copiar Link'"
              >
                <Link :size="16" />
              </button>
              <button
                v-if="props.rowData.status == 1"
                @click="excluir(props.rowData)"
                class="btn btn-sm btn-danger btn-hover-danger btn-icon mr-2"
                v-b-tooltip="'Excluir'"
              >
                <TrashCanIcon :size="16" />
              </button>
            </div>
          </span>
        </div>
      </span>
    </template>
  </TableApp>
</template>

<script>
import { mapGetters } from "vuex";
import TrashCanIcon from "vue-material-design-icons/TrashCan.vue";
import TableApp from "@/components/Table/Table2";
import { SistemaMixin } from "@/modules/Application";
import Link from "vue-material-design-icons/LinkPlus.vue";

export default {
  name: "app-links",
  mixins: [SistemaMixin],
  components: {
    TrashCanIcon,
    TableApp,
    Link,
  },
  data() {
    return {
      fields: [
        {
          label: "Código",
          field: "id_link",
          type: "text",
          slot: true,
          sortable: false,
          width: "5%",
          filterOptions: {
            enabled: true,
            placeholder: "Pesquisar link",
          },
        },
        {
          label: "Descrição",
          field: "descricao",
          type: "text",
          slot: true,
          sortable: false,
          width: "25%",
          filterOptions: {
            enabled: true,
            placeholder: "Pesquisar descrição",
          },
        },
        {
          label: "Destinatário",
          field: "nome_destinatario",
          type: "text",
          slot: true,
          sortable: false,
          width: "10%",
          filterOptions: {
            enabled: true,
            placeholder: "Pesquisar pelo destinatário",
          },
        },
        {
          label: "Preço",
          field: "valor",
          sortable: false,
          slot: true,
        },
        {
          label: "Data Expiração",
          field: "data_expiracao",
          sortable: false,
          slot: true,
          width: "5%",
        },
        {
          label: "Data Pagamento",
          field: "data_pagamento",
          sortable: false,
          slot: true,
          width: "5%",
        },
        {
          label: "Parcelas(x)",
          field: "vezes",
          sortable: false,
          slot: true,
        },
        {
          label: "Status",
          field: "status",
          sortable: false,
          slot: true,
          width: "10%",
        },
        {
          label: "Ações",
          field: "actions",
          sortable: false,
          slot: true,
          width: "10%",
          filterOptions: {
            enabled: false,
          },
        },
      ],
    };
  },
  methods: {
    doCopy(data) {
      this.$copyText(data.link_pagamento);
      this.$toast.success("Link copiado com sucesso!");
    },
    excluir(data) {
      this.getRequestAdmin(() => {
        this.$swal
          .fire({
            icon: "warning",
            title: "Excluir o Link " + data.id_link + "?",
            showCancelButton: true,
            confirmButtonText: `Sim`,
            cancelButtonText: `Não`,
            confirmButtonColor: "#f64e60",
          })
          .then(async (result) => {
            if (result.isConfirmed) {
              await this.$http.post("/link/delete", {
                id_link: data.id_link,
              });
              this.$refs.links.refresh();
              this.$toast.success("Link excluído!");
            }
          });
      })
    },
  },
  computed: {
    ...mapGetters(["layoutConfig", "itsblocked"]),
    config() {
      return this.layoutConfig();
    },
  },
  mounted() {
    if (this.itsblocked) {
      this.$router.push({ name: "assinatura" });
    }
  },
};
</script>
