<template>
  <Form url="/nfe/create" :venda="venda" />
</template>

<script>
import Form from "./Form.vue";
import VendaData from "./VendaData";
import { mapGetters } from "vuex";

export default {
  name: "app-venda-create",
  data() {
    return {
      venda: new VendaData({}),
    };
  },
  components: {
    Form,
  },
  computed: {
    ...mapGetters(["itsblocked"]),
  },
  mounted() {
    if (this.itsblocked) {
      this.$router.push({ name: "assinatura" });
    }
  },
};
</script>
