<template>
  <div class="card card-custom card-stretch gutter-b">
    <div class="card-header border-0 py-5">
      <h3 class="card-title align-items-start flex-column">
        <span class="card-label font-weight-bolder text-dark">
          Editar Produto
        </span>
      </h3>
    </div>
    <Form url="/produtos/update" :produto="produto" />
  </div>
</template>

<script>
import Form from "./Form.vue";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "app-produto-update",
  data() {
    return {
      produto: {},
    };
  },
  components: {
    Form,
  },
  methods: {
    ...mapActions(["setLoading"]),
    async getProduto(id) {
      try {
        const response = await this.$http.post("/produtos/find", {
          id: id,
        });
        const data = (response.data);
        return {
          ...data,
          custo: parseFloat(response?.data?.custo),
          preco: parseFloat(response?.data?.preco)
        };
      } catch (error) {
        return null;
      }
    },
    async getInit() {
      try {
        const codigo = this.$route.params.id_produto;

        this.setLoading(true);

        const produto = await this.getProduto(codigo);

        if (!produto.id || produto == null) {
          this.$router.push({ name: "produtosLista" });
          return this.$toast.error("Produto não encontrado!");
        }

        this.produto = await produto;
        this.setLoading(false);
      } catch {
        return;
      }
    },
  },
  computed: {
    ...mapGetters(["itsblocked"]),
  },
  mounted() {
    if (this.itsblocked) {
      this.$router.push({ name: "assinatura" });
    }
    this.getInit();
  },
};
</script>
