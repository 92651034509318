<template>
  <div class="termos-css">
    <h1 style="text-align: center">
      TERMOS DO USO DO SOFTWARE DESENVOLVIDO PELA “NUTS TECNOLOGIA LTDA.”
    </h1>
    <p>
      Este Contrato de Licença de Usuário Final é um acordo legal entre o
      licenciado (pessoa física ou jurídica – USUÁRIO) e a Nuts Tecnologia LTDA,
      pessoa jurídica de direito privado, inscrita no CNPJ sob n.
      49.050.590/0001-56, com sede na Rua Oswaldo Valentim Zandavalli, N 431,
      Centro, na cidade de Concórdia, Santa Catarina – Brasil, de ora em diante
      denominada simplesmente de LICENCIANTE, para uso do programa de computador
      (SOFTWARE) denominado “Nuts”, disponibilizado neste ato pela LICENCIANTE
      por meio do site “www.sistema.nuts.app.br”, compreendendo o programa de
      computador e podendo incluir os meios físicos eventualmente a ele
      associados, bem como quaisquer materiais impressos e qualquer documentação
      online ou eletrônica, e, ao utilizar o SOFTWARE, mesmo que parcialmente ou
      a título de teste, o USUÁRIO estará vinculado aos termos deste
      instrumento, concordando com todas as suas disposições, principalmente com
      relação ao CONSENTIMENTO PARA o ACESSO, COLETA, USO, ARMAZENAMENTO,
      TRATAMENTO E TÉCNICAS DE PROTEÇÃO ÀS INFORMAÇÕES do USUÁRIO pela
      LICENCIANTE, necessárias para a integral execução das funcionalidades
      ofertadas pelo SOFTWARE, razão pela qual, em caso de discordância com os
      termos aqui apresentados, a utilização do SOFTWARE deve ser imediatamente
      interrompida pelo USUÁRIO.
    </p>
    <p>
      <strong>1.</strong> Da Propriedade O USUÁRIO não adquire, pelo presente
      instrumento ou pela utilização do SOFTWARE, nenhum direito sobre a
      propriedade ou outros direitos exclusivos, incluindo patentes, desenhos,
      marcas, direitos autorais ou quaisquer direitos sobre informações
      confidenciais ou segredos de negócio, bem como todo o conteúdo
      disponibilizado no SITE, incluindo, os textos, gráficos, imagens,
      logotipos, ícones, fotografias, conteúdo editorial, notificações,
      softwares e qualquer outro material, relacionado direta ou indiretamente
      ao SOFTWARE, ainda que parcial.
    </p>

    <p>
      <strong>2.</strong> Declarações do USUÁRIO O USUÁRIO declara ter pleno
      conhecimento dos direitos e obrigações decorrentes do presente
      instrumento, declarando ainda ter lido e compreendido todo o seu conteúdo,
      razão pela qual expressamente aceita todos os termos e condições aqui
      expressas. O USUÁRIO declara ainda que aceita a política de privacidade,
      consentindo livre e expressamente às ações de coleta, uso, armazenamento e
      tratamento das referidas informações e dados. O USUÁRIO declara também que
      está ciente de que as operações que correspondam à aceitação dos termos do
      presente instrumento, sua eventual rescisão ou a aceitação a eventuais
      aditivos disponibilizados pelo LICENCIANTE, serão registradas em banco de
      dados própria, juntamente com a data e hora em que foram realizadas pelo
      USUÁRIO, podendo tais informações serem utilizadas como prova pelas
      partes, independentemente do cumprimento de qualquer outra formalidade. O
      USUÁRIO declara que está ciente de que, em qualquer hipótese, deve atender
      rigorosamente a legislação, especialmente no que se refere às suas
      obrigações tributárias, fiscais, trabalhistas e previdenciárias, seja de
      natureza principal ou acessória, bem como cumprir a regulação de proteção
      de dados aplicável e quaisquer outras, entendendo que o SOFTWARE objeto
      deste instrumento trata-se de uma condição de meio e não de resultado, não
      dispensando, portanto, a correta alimentação das informações e
      parametrizações necessárias pelo USUÁRIO com base na legislação em vigor.
    </p>

    <p>
      <strong>3.</strong> Da Licença de Uso A aceitação dos termos e condições
      aqui estabelecidos, por este instrumento é concedido ao USUÁRIO uma
      licença revogável, não exclusiva e intransferível para “uso” do SOFTWARE.
      O USUÁRIO não poderá utilizar e nem permitir que o SOFTWARE seja utilizado
      para outra finalidade que não seja o processamento de suas informações ou
      da pessoa jurídica indicada no ato do cadastramento. Em nenhuma hipótese o
      USUÁRIO terá acesso ao código fonte do SOFTWARE ora licenciado, por este
      se tratar de propriedade intelectual da LICENCIANTE.
    </p>

    <p>
      <strong>4.</strong> Das Restrições ao Uso Em hipótese alguma é permitido
      ao USUÁRIO ou a terceiros copiar, ceder, sublicenciar, vender, dar em
      locação ou em garantia, reproduzir, doar, alienar de qualquer forma,
      transferir total ou parcialmente, sob quaisquer modalidades, gratuita ou
      onerosamente, provisória ou permanentemente, o SOFTWARE objeto deste
      instrumento, assim como seus módulos, partes, manuais ou quaisquer
      informações a ele relativas, bem como praticar engenharia reversa,
      descompilação ou desmontagem do SOFTWARE.
    </p>

    <p>
      <strong>5.</strong> Do Prazo O presente instrumento entra em vigor na data
      de seu aceite pelo USUÁRIO e vigorará pelo prazo do Plano contratado. Este
      contrato será automaticamente renovado por iguais períodos caso o USUÁRIO
      não se manifeste expressamente em contrário, nos mesmos termos e
      condições, inclusive no que se refere à eventuais penalidades.
    </p>

    <p>
      <strong>6.</strong> Da Remuneração e Forma de Pagamento O USUÁRIO deverá
      pagar à LICENCIANTE o valor do plano de licenciamento escolhido e de
      acordo com a periodicidade definida entre as opções de pagamento
      disponibilizadas no ato da contratação. Caso o USUÁRIO, no decorrer da
      vigência do presente instrumento, opte por outro plano de licenciamento
      superior (upgrade), os valores serão alterados de acordo com o respectivo
      plano escolhido. Caso esta operação gere créditos eles serão utilizados
      nas próximas renovações, sendo vedada a devolução de valores já pagos que
      se transformaram em crédito por uma operação de alteração de plano ou de
      recorrência. Se o USUÁRIO, no decorrer da vigência do presente
      instrumento, optar por um plano de licenciamento inferior (downgrade), a
      alteração de valores será efetuada na próxima renovação, sendo vedada a
      devolução de valores já pagos A falta de pagamento de quaisquer valores
      nas respectivas datas de vencimento não acarretará na rescisão automática
      do presente contrato, mas causará a suspensão do acesso do USUÁRIO ao
      SOFTWARE até que a(s) pendência(s) financeira(s) seja(m) regularizada(s).
      O acesso ao SOFTWARE somente será restabelecido após a identificação pela
      LICENCIANTE do pagamento integral de todos os valores devidos enquanto o
      mesmo esteve suspenso. A identificação poderá ocorrer em até três dias
      úteis após a data de pagamento pelo USUÁRIO. Caso o USUÁRIO não resolva a
      pendência financeira no prazo de 10 (dez) dias úteis contados do
      vencimento do valor não pago, a LICENCIANTE se reserva o direito de
      rescindir o presente intrumento. Caso o USUÁRIO não resolva a pendência
      financeira no prazo de 90 (noventa) dias corridos, contados do vencimento
      do valor não pago, a LICENCIANTE se reserva ao direito de apagar de forma
      definitiva e irrecuperável todas as informações do USUÁRIO que por ventura
      estejam armazenadas no SOFTWARE, nos termos da Cláusula 7. Os valores dos
      planos de licenciamento estabelecidos no ato do licenciamento do SOFTWARE
      poderão ser atualizados anualmente de acordo com a tabela de planos e
      preços vigente no momento do reajuste, caso isto ocorra o USUÁRIO será
      informado antes da renovação em que ocorrerá o reajuste de preços.
      Eventualmente, poderão ocorrer alterações nos preços dos planos e
      adicionais contratados fora do período indicado anteriormente; caso isto
      ocorra, o USUÁRIO também será informado com antes da sua próxima
      renovação. Ocorrendo o reajuste, o plano de assinatura terá seu preço
      reajustado, independente da data da contratação, mesmo que não tenham
      decorridos 12 (doze) meses de vigência do presente instrumento. A
      LICENCIANTE poderá, a seu critério, por mera liberalidade, oferecer
      descontos nos valores dos planos de licenciamento. Neste caso, os
      descontos serão válidos por até 12 (doze) meses. A LICENCIANTE poderá
      renovar ou não os percentuais de desconto, podendo ainda cancelar os
      descontos após o período de validade, sem aviso prévio.
    </p>

    <p>
      <strong>7.</strong> Restituição das Informações Suspenso o acesso do
      USUÁRIO ao SOFTWARE, a LICENCIANTE manterá as informações do USUÁRIO
      armazenadas no SOFTWARE pelo período de 90 (noventa) dias, contados da
      suspensão de acesso. Durante este período, a LICENCIANTE tornará as
      informações do USUÁRIO disponíveis para serem extraídas do SOFTWARE em
      formato “.csv”. Conforme descrito na cláusula 6 acima, passados 90
      (noventa) dias da suspensão do acesso do USUÁRIO ao SOFTWARE, todas as
      INFORMAÇÕES, incluindo as INFORMAÇÕES PESSOAIS, INFORMAÇÕES DE CONTA e
      INFORMAÇÕES FINANCEIRAS, em poder da LICENCIANTE serão excluídos
      permanentemente do banco de dados da LICENCIANTE, independentemente de
      terem sido extraídas ou não pelo USUÁRIO. Não obstante o disposto acima,
      as informações referentes à data e hora de acesso e ao endereço de
      protocolo de internet utilizado pelo USUÁRIO para acessar o SITE e o
      SOFTWARE permanecerão armazenadas pela LICENCIANTE por 6 (meses) a contar
      da data de cada acesso realizado, independentemente do término da relação
      jurídica e comercial entre a LICENCIANTE e o USUÁRIO, em cumprimento ao
      disposto no Artigo 15 da Lei nº 12.965/2014, podendo ser armazenados por
      um período maior de tempo mediante ordem judicial.
    </p>

    <p>
      <strong>8.</strong> Das Obrigações do USUÁRIO Obriga-se o USUÁRIO a manter
      pessoal treinado para a operação do SOFTWARE e para a comunicação com a
      LICENCIANTE e prover, sempre que ocorrerem quaisquer problemas com o
      SOFTWARE, toda a documentação, relatórios e demais informações que relatem
      as circunstâncias em que os problemas ocorreram, objetivando facilitar e
      agilizar os trabalhos; Manter, às suas expensas, linha de telecomunicação,
      modem, software de comunicação, endereço de correio eletrônico e outros
      recursos necessários à comunicação com a LICENCIANTE; Responder pelas
      INFORMAÇÕES e dados inseridos no SOFTWARE, pelo cadastramento, permissões,
      senhas e modo de utilização de seus usuários, incluindo às informações
      relativas aos métodos de pagamento (boletos), números de contas bancárias
      e a informações financeiras disponibilizadas às autoridades fiscais por
      meio do SOFTWARE para a correta e regular emissão de notas fiscais e
      quanto ao cumprimento das obrigações acessórias disponibilizadas através
      do SOFTWARE. Garantir a regularidade dos dados pessoais inseridos no
      SOFTWARE, em especial, no que tange ao cumprimento de bases legais e
      direitos dos titulares de dados; A qualquer tempo a LICENCIANTE poderá
      bloquear acesso ao SOFTWARE caso constate qualquer prática pelo USUÁRIO ou
      terceiros de violação ao presente Termo de uso e/ou qualquer tentativa de
      fraude ou dê a entender tratar-se de uma tentativa, não reduzindo essa
      ação a responsabilidade do USUÁRIO sobre seus atos. Não utilizar o
      SOFTWARE de qualquer forma que possa implicar em ato ilícito, infração,
      violação de direitos ou danos à LICENCIANTE ou terceiros, incluindo, mas
      não se limitando ao uso para invasão de dispositivo informático com o
      objetivo de obter, adulterar ou destruir dados ou informações sem a
      autorização expressa do titular de tais dados ou do dispositivo ou
      servidor nos quais estes estejam armazenados; Não publicar, enviar ou
      transmitir qualquer arquivo que contenha vírus, worms, cavalos de tróia ou
      qualquer outro programa que possa contaminar, destruir ou interferir no
      bom funcionamento do SOFTWARE; Informar a LICENCIANTE sempre que houver
      qualquer alteração das INFORMAÇÕES fornecidas à LICENCIANTE e que possam
      impedir, limitar ou prejudicar o acesso da LICENCIANTE às INFORMAÇÕES
      necessárias para a execução das funcionalidades ofertadas pelo SOFTWARE;
      Atender rigorosamente a legislação brasileira e toda obrigação legal
      imposta e/ou decorrente de sua atividade e em razão da utilização deste
      SOFTWARE. Caso o USUÁRIO acredite que seu login e senha de acesso ao
      SOFTWARE tenham sido roubados ou sejam de conhecimento de outras pessoas,
      por qualquer razão, o USUÁRIO deverá imediatamente comunicar tal fato à
      LICENCIANTE, sem prejuízo da alteração da sua senha imediatamente, por
      meio do SOFTWARE.
    </p>

    <p>
      <strong>9.</strong> Das Obrigações do Licenciante Obriga-se a LICENCIANTE
      a garantir ao USUÁRIO que o SOFTWARE deverá funcionar regularmente, se
      respeitadas as condições de uso definidas na documentação. Na ocorrência
      de falhas de programação (“bugs”), a LICENCIANTE obrigar-se-á a corrigir
      tais falhas, podendo a seu critério substituir a cópia dos programas com
      falhas por cópias corrigidas; A manterá atualizada as funções existentes
      no SOFTWARE com relação às variáveis normalmente alteradas pela legislação
      federal. A interpretação legal das normas editadas pelo governo será
      efetuada com base no entendimento majoritário dos “usuários” do SOFTWARE,
      doutrinadores e jurisprudência pátria. Ficam excluídas da manutenção as
      alterações no SOFTWARE originárias de obrigações assumidas pelo USUÁRIO
      junto a sindicatos ou associações, tais como convenções coletivas de
      trabalho e outras, bem como necessidades específicas de certos segmentos,
      além do atendimento de legislações municipais e/ou estaduais. Alterar as
      especificações e/ou características do SOFTWARE licenciados para a
      melhoria e/ou correções de erros, de acordo com o plano de produtos
      definido pela LICENCIANTE;
    </p>

    <p>
      <strong>10.</strong> Isenção de Responsabilidade da Licenciante A
      LICENCIANTE não se responsabiliza por falha de operação, operação por
      pessoas não autorizadas ou qualquer outra causa em que não exista culpa da
      LICENCIANTE; Pelo cumprimento dos prazos legais do USUÁRIO para a entrega
      de documentos fiscais ou pagamentos de impostos; Pelos danos ou prejuízos
      decorrentes de decisões administrativas, gerenciais ou comerciais tomadas
      com base nas informações fornecidas pelo SOFTWARE; Por problemas definidos
      como “caso fortuito” ou “força maior”, contemplados pelo Art. 393 do
      Código Civil Brasileiro; Por eventuais problemas oriundos de ações de
      terceiros que possam interferir na qualidade do serviço; Por danos
      causados a terceiros em razão de conteúdo gerado pelo LICENCIANTE através
      de SOFTWARE; Por revisar as INFORMAÇÕES DE CONTA fornecidas pelo USUÁRIO,
      bem como as INFORMAÇÕES FINANCEIRAS obtidas junto aos sites e/ou meios
      virtuais das instituições financeiras, seja no que tange à precisão dos
      dados, seja quanto à legalidade ou ameaça de violação em função do
      fornecimento destas informações; Por quaisquer produtos e/ou serviços
      oferecidos por meio dos sites e/ou meios virtuais das instituições
      financeiras, ainda que de maneira solidária ou subsidiária; Por eventuais
      infrações legais cometidas pelo USUÁRIO, de ordem fiscal, tributária,
      trabalhista, previdenciária, criminal, ou qualquer outra. Pela geração e
      envio de obrigações fiscais acessórias, cabendo este procedimento somente
      ao USUÁRIO. O acesso às INFORMAÇÕES DE CONTA e às INFORMAÇÕES FINANCEIRAS
      do USUÁRIO dependem de serviços prestados pelas instituições financeiras.
      Sendo assim, a LICENCIANTE não assume qualquer responsabilidade quanto à
      qualidade, precisão, pontualidade, entrega ou falha na obtenção das
      referidas INFORMAÇÕES junto aos sites e/ou meios virtuais das instituições
      financeiras. A LICENCIANTE não se responsabiliza pelo aceite e pelo
      preenchimento incorreto dessas informações, cabendo somente ao USUÁRIO a
      checagem correta de suas próprias informações inseridas, sendo necessário
      ao USUÁRIO sempre revisar as regras de automatização de impostos e
      configuração tributária com um profissional de contabilidade.
    </p>

    <p>
      <strong>11.</strong> Do Cancelamento da Licença A LICENCIANTE se reserva o
      direito de cancelar imediatamente o acesso do USUÁRIO ao SOFTWARE nos
      casos em que o USUÁRIO usar o SOFTWARE de forma diversa daquela estipulada
      no presente instrumento
    </p>

    <p>
      <strong>12.</strong> Das Garantias Na extensão máxima permitida pela lei
      em vigor, o SOFTWARE é fornecido “no estado em que se encontra” e
      “conforme a disponibilidade”, com todas as falhas e sem garantia de
      qualquer espécie. A LICENCIANTE não garante que as funções contidas no
      SOFTWARE atendam às suas necessidades, que a operação do SOFTWARE será
      ininterrupta ou livre de erros, que qualquer serviço continuará
      disponível, que os defeitos no SOFTWARE serão corrigidos ou que o SOFTWARE
      será compatível ou funcione com qualquer SOFTWARE, aplicações ou serviços
      de terceiros. O USUÁRIO reconhece que o SOFTWARE não deve ser utilizado ou
      não é adequado para ser utilizado em situações ou ambientes nos quais a
      falha ou atrasos, os erros ou inexatidões de conteúdo, dados ou
      informações fornecidas pelo SOFTWARE possam levar à morte, danos pessoais,
      ou danos físicos ou ambientais graves, incluindo, mas não se limitando, à
      operação de instalações nucleares, sistemas de navegação ou de comunicação
      aérea, controle de tráfego aéreo, sistemas de suporte vital ou de armas.
    </p>

    <p>
      <strong>13.</strong> Limitação de Responsabilidade Em nenhum caso a
      LICENCIANTE será responsável por danos pessoais ou qualquer prejuízo
      incidental, especial, indireto ou consequente, incluindo, sem limitação,
      prejuízos por perda de lucro, corrupção ou perda de dados, falha de
      transmissão ou recepção de dados, não continuidade do negócio ou qualquer
      outro prejuízo ou perda comercial, decorrentes ou relacionados ao seu uso
      ou sua inabilidade em usar o SOFTWARE, por qualquer outro motivo. Sob
      nenhuma circunstância a responsabilidade integral da LICENCIANTE com
      relação ao USUÁRIO por todos os danos excederá a quantia correspondente ao
      último plano de licenciamento pago pelo USUÁRIO à LICENCIANTE pela
      obtenção da presente licença de SOFTWARE.
    </p>

    <p>
      <strong>14.</strong> Consentimento livre e expresso para acesso a
      informações confidenciais e dados pessoais O USUÁRIO, ao aceitar utilizar
      o SOFTWARE, além de aceitar integralmente os termos do presente
      instrumento, também consente, de forma livre e expressa, que a LICENCIANTE
      colete, use, armazene e faça o tratamento de suas informações, incluindo
      seus dados pessoais, financeiros, bancários, de conta, os quais serão
      necessários para que o serviço ofertado seja prestado em sua
      integralidade. Ainda, o USUÁRIO declara e reconhece que para a prestação
      dos serviços e funcionalidades de integração a rede bancária ofertadas
      pelo SOFTWARE, a LICENCIANTE acessa informações financeiras diretamente no
      site e/ou outros meios virtuais das instituições financeiras, sem fazer
      qualquer emulação de medidas de segurança, utilizando-se apenas das
      informações pessoais, informações de conta, bem como outras eventualmente
      necessárias, fornecidas pelo USUÁRIO, conforme autorizado. O USUÁRIO
      consente que, ao acessar o site da LICENCIANTE, esta poderá coletar
      informações técnicas de navegação, tais como tipo de navegador do
      computador utilizado para acesso ao site, endereço de protocolo de
      Internet, páginas visitadas e tempo médio gasto no site. Tais informações
      poderão ser usadas para orientar o próprio USUÁRIO e melhorar os serviços
      ofertados. O USUÁRIO consente livre e expressamente que suas INFORMAÇÕES
      poderão ser transferidas a terceiros em decorrência da venda, aquisição,
      fusão, reorganização societária ou qualquer outra mudança no controle da
      LICENCIANTE. A LICENCIANTE, contudo, compromete-se, nestes casos, a
      informar o USUÁRIO. O USUÁRIO consente livre e expressamente que a
      LICENCIANTE utilize cookies apenas para controlar a audiência e a
      navegação em seu site e possibilitar a identificação de serviços
      segmentados e personalizados ao perfil do USUÁRIO. A LICENCIANTE garante
      que estas informações coletadas por meio de cookies são estatísticas e não
      pessoais, bem como que não serão utilizadas para propósitos diversos dos
      expressamente previstos neste instrumento, comprometendo-se a adotar todas
      as medidas necessárias a fim de evitar o acesso e o uso de tais
      informações por quaisquer terceiros, sem a devida autorização.
    </p>

    <p>
      <strong>15.</strong> Da Rescisão O USUÁRIO poderá solicitar devolução
      total dos valores pagos em até 7 (sete) dias após a data de contratação ou
      conforme a adesão de eventuais campanhas promocionais. Após este prazo não
      haverá devolução dos valores pagos e o USUÁRIO poderá solicitar somente o
      cancelamento da renovação automática, desde que comunique à LICENCIANTE,
      por escrito com antecedência mínima de 15 (quinze) dias da próxima
      renovação, devendo pagar o saldo devedor do plano de licenciamento
      contratado, se existente. Para os planos de licenciamento com pagamento
      antecipado, caso o USUÁRIO decida rescindir o contrato antes do término do
      prazo contratado, o LICENCIANTE não restituirá ao USUÁRIO o saldo restante
      do plano de licenciamento contratado. O valor será retido pela LICENCIANTE
      para cobrir os despesas operacionais. A LICENCIANTE poderá rescindir o
      presente contrato a qualquer tempo, desde que comunique ao USUÁRIO, com
      antecedência mínima de 15 (quinze) dias, devendo neste caso restituir ao
      USUÁRIO o saldo devedor do plano de licenciamento contratado, se
      existente. Este prazo de antecedência mínima e a obrigação de restituição
      acima não se aplicarão nos casos previstos na cláusula acima e nos casos
      de violação do presente instrumento. A LICENCIANTE poderá rescindir o
      presente contrato a qualquer momento em caso de violação pelo USUÁRIO dos
      termos e condições ora acordados, ou em caso de atraso de pagamento não
      sanado no prazo de 90 (noventa) dias, conforme cláusula 6 acima.
    </p>

    <p>
      <strong>16.</strong> Disposições Legais O USUÁRIO não poderá prestar
      serviços a terceiros utilizando o SOFTWARE da LICENCIANTE sem autorização
      prévia e expressa da LICENCIANTE. A autorização de uso do SOFTWARE é
      fornecida por CNPJ. Desta forma, o SOFTWARE não pode operar sob o regime
      de multiempresa, necessitando para cada CNPJ uma licença específica; Caso
      o USUÁRIO venha a desenvolver um novo módulo ou produto que caracterize
      cópia, de todo ou em parte, quer seja do dicionário de dados quer seja do
      programa, será considerado como parte do SOFTWARE fornecido pela
      LICENCIANTE, ficando, portanto, sua propriedade incorporada pela
      LICENCIANTE e seu uso condicionado a estas cláusulas contratuais; Este
      instrumento obriga as partes e seus sucessores e somente o USUÁRIO possui
      licença não exclusiva para a utilização do SOFTWARE, sendo-lhe,
      entretanto, vedado transferir os direitos e obrigações acordados por este
      instrumento. Tal limitação, no entanto, não atinge a LICENCIANTE, que
      poderá, a qualquer tempo, ceder, no todo ou em parte, os direitos e
      obrigações inerentes ao presente instrumento; A tolerância de uma parte
      para com a outra quanto ao descumprimento de qualquer uma das obrigações
      assumidas neste instrumento não implicará em novação ou renúncia de
      direito. A parte tolerante poderá, a qualquer tempo, exigir da outra parte
      o fiel e cabal cumprimento deste instrumento; Não constitui causa de
      rescisão contratual o não cumprimento das obrigações aqui assumidas em
      decorrência de fatos que independam da vontade das partes, tais como os
      que configuram o caso fortuito ou força maior, conforme previsto no artigo
      393 do Código Civil Brasileiro; O USUÁRIO aceita, ainda, receber
      comunicações via correio eletrônico sobre treinamentos, parcerias e
      campanhas relacionadas ao SOFTWARE; Neste ato, o LICENCIANTE expressamente
      autoriza o USUÁRIO a colher e utilizar seus dados técnicos e operacionais
      presentes no SOFTWARE, para fins de estudos e melhorias no SOFTWARE; A
      LICENCIANTE poderá, a seu exclusivo critério, a qualquer tempo e sem a
      necessidade de comunicação prévia ao USUÁRIO:
    </p>
    <p>
      <strong>
        a. Encerrar, modificar ou suspender, total ou parcialmente, o acesso do
        USUÁRIO ao SOFTWARE, quando referido acesso ou cadastro estiver em
        violação das condições estabelecidas neste instrumento;
      </strong>
    </p>
    <p>
      <strong>
        b. Excluir, total ou parcialmente, as informações cadastradas pelo
        USUÁRIO que não estejam em consonância com as disposições deste
        instrumento;
      </strong>
    </p>
    <p>
      <strong>
        c. Acrescentar, excluir ou modificar o Conteúdo oferecido no site;
      </strong>
    </p>
    <p>
      <strong>
        d. Alterar quaisquer termos e condições deste instrumento mediante
        simples comunicação ao USUÁRIO.
      </strong>
    </p>

    <p>
      A LICENCIANTE ainda poderá, a seu exclusivo critério, suspender, modificar
      ou encerrar as atividades do SOFTWARE, mediante comunicação prévia ao
      USUÁRIO, com antecedência mínima de 15 (quinze) dias, disponibilizando
      formas e alternativas de extrair do SITE as informações, salvo nas
      hipóteses de caso fortuito ou força maior. A LICENCIANTE poderá, por meio
      de comunicação ao endereço eletrônico indicado pelo USUÁRIO em seu
      cadastro ou por meio de aviso no SITE, definir preços para a oferta de
      determinados conteúdos e/ou serviços, ainda que inicialmente tais serviços
      tenham sido ofertados de forma gratuita, sendo a utilização destes, após o
      referido aviso, considerada como concordância do USUÁRIO com a cobrança de
      tais preços. Fica certo e entendido pelo USUÁRIO que somente a pessoa
      cadastrada pelo próprio USUÁRIO como administradora de conta poderá
      solicitar que as informações do USUÁRIO inseridas no Software sejam
      apagadas. Ainda, o USUÁRIO declara sua ciência de que uma vez apagadas,
      estas não poderão mais ser recuperadas, ficando a LICENCIANTE isenta de
      qualquer responsabilidade por quaisquer perdas ou danos decorrentes deste
      procedimento solicitado pelo USUÁRIO. O presente instrumento é regido,
      interpretado e se sujeitará às leis brasileiras e, o USUÁRIO e a
      LICENCIANTE desde logo elegem, de forma irrevogável e irretratável, o foro
      da Comarca da Cidade de Concórdia, Estado de Santa Catarina, para dirimir
      quaisquer dúvidas ou controvérsias oriundas deste instrumento, com a
      exclusão de qualquer outro, por mais privilegiado que seja. Concórdia,
      2024.
    </p>

    <p>
      <strong>Nuts Tecnologia LTDA.</strong>
    </p>
  </div>
</template>

<script>
export default {
  name: "app-termos",
  data() {
    return {};
  },
};
</script>

<style>
.text-left {
  margin-left: 36pt;
}
.termos-css {
  margin-left: 36pt;
  font-size: 14px;
}
</style>
