<template>
  <div>
    <div class="card card-custom gutter-b" style="box-shadow: none">
      <div class="card-header card-header-tabs-line">
        <ul
          class="nav nav-dark nav-bold nav-tabs nav-tabs-line"
          role="tablist"
          ref="builder-tab"
        >
          <li class="nav-item">
            <a
              class="nav-link active"
              v-on:click="setActiveTab"
              data-tab="0"
              data-toggle="tab"
              href="#"
              role="tab"
            >
              Cadastro
            </a>
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              v-on:click="setActiveTab"
              data-tab="1"
              data-toggle="tab"
              href="#"
              role="tab"
            >
              Endereços
            </a>
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              v-on:click="setActiveTab"
              data-tab="2"
              data-toggle="tab"
              href="#"
              role="tab"
            >
              E-mail
            </a>
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              v-on:click="setActiveTab"
              data-tab="3"
              data-toggle="tab"
              href="#"
              role="tab"
            >
              Telefones
            </a>
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              v-on:click="setActiveTab"
              data-tab="4"
              data-toggle="tab"
              href="#"
              role="tab"
            >
              Veículos
            </a>
          </li>
        </ul>
      </div>

      <div class="card-body">
        <b-tabs class="hide-tabs" v-model="tabIndex">
          <b-tab active>
            <b-row>
              <b-col md="12">
                <b-row>
                  <b-col md="6">
                    <b-form-group label="Tipo" label-for="tipo_pessoa">
                      <b-form-checkbox-group
                        v-model="tipoPessoaSelected"
                        :options="tipoPessoaOptions"
                        text-field="name"
                        value-field="item"
                        size="lg"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col md="3">
                    <b-form-group
                      id="cnpj_cpf"
                      label="CPF/CNPJ"
                      label-for="cnpj_cpf"
                    >
                      <b-form-input
                        id="cnpj_cpf"
                        name="cnpj_cpf"
                        v-model="form.cnpj_cpf"
                        v-mask="['###.###.###-##', '##.###.###/####-##']"
                      >
                      </b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col
                    v-if="form.cnpj_cpf !== null && form.cnpj_cpf.length > 14"
                  >
                    <button
                      type="button"
                      style="margin-top: 25px"
                      @click="consultaReceita"
                      class="btn btn-light-primary btn-bold"
                    >
                      Consultar CNPJ
                    </button>
                  </b-col>
                  <b-col
                    v-if="form.cnpj_cpf !== null && form.cnpj_cpf.length == 14"
                  >
                    <button
                      type="button"
                      style="margin-top: 25px"
                      @click="consultaCPF"
                      class="btn btn-light-primary btn-bold"
                      size="sm"
                    >
                      Consultar CPF
                    </button>
                    <button
                      style="margin-top: 25px"
                      @click="consultarCreditosUnicheck"
                      class="btn btn-sm btn-primmary btn-hover-primmary btn-icon mr-2"
                      v-b-tooltip="'Consultar Créditos'"
                    >
                      <AccountStar :size="16" />
                    </button>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col md="7">
                    <b-form-group
                      id="nome"
                      label-for="responsavel"
                      label="Nome"
                    >
                      <b-form-input
                        v-model="$v.form.nome.$model"
                        :state="validateState('nome')"
                        autofocus
                      >
                      </b-form-input>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col md="7">
                    <b-form-group
                      id="responsavel"
                      label="Responsável"
                      label-for="responsavel"
                    >
                      <b-form-input
                        id="responsavel"
                        name="responsavel"
                        v-model="form.responsavel"
                      >
                      </b-form-input>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col
                    v-if="form.cnpj_cpf !== null && form.cnpj_cpf.length > 14"
                  >
                    <template>
                      <b-form-group
                        id="identificador_ie"
                        label="Indicador de IE"
                        label-for="identificador_ie"
                      >
                        <select
                          name="identificador_ie"
                          id="identificador_ie"
                          v-model="form.identificador_ie"
                          class="form-control"
                          @change="setIdentificador()"
                        >
                          <option
                            v-for="(i, index) in identificadores_ie"
                            :value="i.id"
                            :key="'identificador' + index"
                          >
                            {{ i.descricao }}
                          </option>
                        </select>
                      </b-form-group>
                    </template>
                  </b-col>
                  <b-col md="3">
                    <b-form-group
                      id="ie_rg"
                      :label="
                        form.cnpj_cpf !== null && form.cnpj_cpf.length > 14
                          ? 'IE'
                          : 'RG'
                      "
                      label-for="ie_rg"
                    >
                      <b-form-input
                        id="ie_rg"
                        name="ie_rg"
                        v-model="form.ie_rg"
                        v-mask="['#################']"
                      >
                      </b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col v-if="form.cnpj_cpf !== null && form.cnpj_cpf">
                    <template>
                      <b-form-group
                        v-if="
                          form.cnpj_cpf !== null && form.cnpj_cpf.length > 14
                        "
                        id="inscricao_municipal"
                        label="Insc. Municípal"
                        label-for="inscricao_municipal"
                      >
                        <b-form-input
                          id="inscricao_municipal"
                          name="inscricao_municipal"
                          v-model="form.inscricao_municipal"
                          placeholder=""
                        >
                        </b-form-input>
                      </b-form-group>
                    </template>
                  </b-col>
                </b-row>
                <b-row v-if="form.cnpj_cpf !== null && form.cnpj_cpf.length">
                  <b-col>
                    <b-form-group
                      v-if="form.cnpj_cpf !== null && form.cnpj_cpf.length > 14"
                      id="nome_fantasia"
                      label="Nome Fantasia"
                      label-for="nome_fantasia"
                    >
                      <b-form-input
                        id="nome_fantasia"
                        name="nome_fantasia"
                        v-model="form.nome_fantasia"
                        placeholder=""
                      >
                      </b-form-input>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col md="2">
                    <b-form-group
                      id="orgao_emissor_rg"
                      label="Órgão emissor RG"
                      label-for="orgao_emissor_rg"
                    >
                      <b-form-input
                        id="orgao_emissor_rg"
                        name="orgao_emissor_rg"
                        v-model="form.orgao_emissor_rg"
                        maxlength="10"
                      >
                      </b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col md="2">
                    <b-form-group
                      id="data_nascimento"
                      label="Data Nascimento"
                      label-for="data_nascimento"
                    >
                      <b-form-input
                        type="date"
                        id="data_nascimento"
                        name="data_nascimento"
                        v-model="form.data_nascimento"
                      >
                      </b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col md="2">
                    <b-form-group
                      id="naturalidade"
                      label="Naturalidade"
                      label-for="naturalidade"
                    >
                      <b-form-input
                        maxlength="25"
                        id="naturalidade"
                        name="naturalidade"
                        v-model="form.naturalidade"
                      >
                      </b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col md="3">
                    <b-form-group
                      id="profissao"
                      label="Profissão"
                      label-for="profissao"
                    >
                      <b-form-input
                        maxlength="25"
                        id="profissao"
                        name="profissao"
                        v-model="form.profissao"
                      >
                      </b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col md="2">
                    <b-form-group
                      id="estado_civil"
                      label="Estado Civil"
                      label-for="estado_civil"
                    >
                      <select
                        name="estado_civil"
                        id="estado_civil"
                        v-model="form.estado_civil"
                        class="form-control"
                      >
                        <option
                          v-for="(e, index) in estados_civil"
                          :value="e.id"
                          :key="'e' + index"
                        >
                          {{ e.descricao }}
                        </option>
                      </select>
                    </b-form-group>
                  </b-col>
                  <b-col md="4">
                    <b-form-group
                      id="conjuge"
                      label="Cônjuge"
                      label-for="conjuge"
                    >
                      <b-form-input
                        maxlength="25"
                        id="conjuge"
                        name="conjuge"
                        v-model="form.conjuge"
                      >
                      </b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col md="4">
                    <b-form-group
                      id="pai_mae"
                      label="Pai/Mãe"
                      label-for="pai_mae"
                    >
                      <b-form-input
                        maxlength="25"
                        id="pai_mae"
                        name="pai_mae"
                        v-model="form.pai_mae"
                      >
                      </b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col md="4">
                    <b-form-group
                      id="representante"
                      label="Representante/Vendedor"
                      label-for="representante"
                    >
                      <b-form-input
                        maxlength="80"
                        id="representante"
                        name="representante"
                        v-model="form.representante"
                      >
                      </b-form-input>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row v-if="form.estrangeiro">
                  <b-col md="5">
                    <b-form-group
                      label="Logradouro"
                      label-for="form.logradouro_estrangeiro"
                    >
                      <b-form-input
                        id="form.logradouro_estrangeiro"
                        name="form.logradouro_estrangeiro"
                        v-model="form.logradouro_estrangeiro"
                        aria-describedby="form.logradouro_estrangeiro"
                        maxlength="50"
                      >
                      </b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col md="3">
                    <b-form-group
                      label="Número"
                      label-for="form.numero_estrangeiro"
                    >
                      <b-form-input
                        id="form.numero_estrangeiro"
                        name="form.numero_estrangeiro"
                        v-model="form.numero_estrangeiro"
                        aria-describedby="form.numero_estrangeiro"
                        maxlength="8"
                      >
                      </b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col md="3">
                    <b-form-group
                      label="Bairro"
                      label-for="form.bairro_estrangeiro"
                    >
                      <b-form-input
                        id="form.bairro_estrangeiro"
                        name="form.bairro_estrangeiro"
                        v-model="form.bairro_estrangeiro"
                        aria-describedby="form.bairro_estrangeiro"
                        maxlength="50"
                      >
                      </b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col md="1">
                    <b-form-group label="UF" label-for="form.uf_estrangeiro">
                      <b-form-input
                        id="form.uf_estrangeiro"
                        name="form.uf_estrangeiro"
                        v-model="form.uf_estrangeiro"
                        aria-describedby="form.uf_estrangeiro"
                        maxlength="2"
                      >
                      </b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col md="4">
                    <b-form-group
                      label="Munícipio"
                      label-for="form.municipio_estrangeiro"
                    >
                      <b-form-input
                        id="form.municipio_estrangeiro"
                        name="form.municipio_estrangeiro"
                        v-model="form.municipio_estrangeiro"
                        aria-describedby="form.municipio_estrangeiro"
                        maxlength="20"
                      >
                      </b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col md="2">
                    <b-form-group
                      id="pais_municipio"
                      label="País"
                      label-for="pais_municipio"
                    >
                      <select
                        name="pais_municipio"
                        id="pais_municipio"
                        v-model="form.pais_estrangeiro"
                        class="form-control"
                      >
                        <option
                          v-for="(p, index) in paises"
                          :value="p.id"
                          :key="'p' + index"
                        >
                          {{ p.descricao }}
                        </option>
                      </select>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <b-form-group
                      id="observacao"
                      label="Observação"
                      label-for="observacao"
                    >
                      <b-form-textarea
                        id="observacao"
                        name="observacao"
                        v-model="form.observacao"
                        placeholder=""
                      >
                      </b-form-textarea>
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="1">
                <b-form-group
                  id="produtor_rural"
                  label-for="produtor_rural"
                  label="Produtor Rural"
                >
                  <span class="switch switch-icon">
                    <label>
                      <input
                        type="checkbox"
                        :value="true"
                        @change="setProdutorRural"
                        :checked="form.produtor_rural"
                      />
                      <span></span>
                    </label>
                  </span>
                </b-form-group>
              </b-col>
              <b-col md="1">
                <b-form-group
                  id="tipo_atacado"
                  label-for="tipo_atacado"
                  label="Atacado"
                >
                  <span class="switch switch-icon">
                    <label>
                      <input
                        type="checkbox"
                        :value="true"
                        @change="setTipoAtacado"
                        :checked="form.tipo_atacado"
                      />
                      <span></span>
                    </label>
                  </span>
                </b-form-group>
              </b-col>
              <b-col md="1">
                <b-form-group id="status" label-for="status" label="Ativo">
                  <span class="switch switch-icon">
                    <label>
                      <input
                        type="checkbox"
                        :value="true"
                        @change="setAtivo"
                        :checked="form.status == 'A'"
                      />
                      <span></span>
                    </label>
                  </span>
                </b-form-group>
              </b-col>
              <b-col md="1">
                <b-form-group
                  id="estrangeiro"
                  label-for="estrangeiro"
                  label="Estrangeiro"
                >
                  <span class="switch switch-icon">
                    <label>
                      <input
                        type="checkbox"
                        :value="true"
                        @change="setEstrangeiro"
                        :checked="form.estrangeiro"
                      />
                      <span></span>
                    </label>
                  </span>
                </b-form-group>
              </b-col>
              <b-col md="2">
                <b-form-group
                  id="created_at"
                  label-for="created_at"
                  label="Data Cadastro"
                >
                  <b-form-input
                    disabled
                    id="created_at"
                    name="created_at"
                    v-model="form.created_at"
                  >
                  </b-form-input>
                </b-form-group>
              </b-col>
              <b-col md="2">
                <b-form-group id="limite_credito" label-for="limite_credito" label="Limite de crédito">
                  <money
                    class="form-control"
                    v-model="form.limite_credito"
                    v-bind="priceOptions"
                  >
                  </money>
                </b-form-group>
              </b-col>
            </b-row>
          </b-tab>

          <b-tab>
            <b-row>
              <b-col md="2">
                <b-form-group label="Tipo" label-for="tipo_endereco">
                  <b-form-select
                    v-model="$v.formEndereco.tipo.$model"
                    placeholder=""
                    :state="validateStateEndereco('tipo')"
                  >
                    <b-form-select-option
                      v-for="(t, index) in tipoEndereco"
                      :value="t"
                      :key="'tipo_endereco' + index"
                    >
                      {{ t.descricao }}
                    </b-form-select-option>
                  </b-form-select>
                </b-form-group>
              </b-col>
              <b-col md="3">
                <b-form-group label="CEP" label-for="formEndereco.cep">
                  <b-form-input
                    ref="cep"
                    id="formEndereco.cep"
                    name="formEndereco.cep"
                    v-model="$v.formEndereco.cep.$model"
                    :state="validateStateEndereco('cep')"
                    v-mask="['#####-###']"
                    aria-describedby="formEndereco.cep"
                  >
                  </b-form-input>
                </b-form-group>
              </b-col>
              <b-col md="2">
                <b-form-group style="margin-top: 30px">
                  <a
                    class="btn btn-primary font-weight-bold"
                    @click="consultaCep"
                  >
                    Consultar CEP
                  </a>
                </b-form-group>
              </b-col>
              <b-col md="2">
                <b-form-group label="Número" label-for="formEndereco.numero">
                  <b-form-input
                    id="formEndereco.numero"
                    name="formEndereco.numero"
                    v-model="formEndereco.numero"
                    aria-describedby="formEndereco.numero"
                  >
                  </b-form-input>
                </b-form-group>
              </b-col>
              <b-col md="3">
                <b-form-group label="Bairro" label-for="formEndereco.bairro">
                  <b-form-input
                    id="formEndereco.bairro"
                    name="formEndereco.bairro"
                    v-model="formEndereco.bairro"
                    aria-describedby="formEndereco.bairro"
                  >
                  </b-form-input>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-form-group
                  label="Logradouro"
                  label-for="formEndereco.logradouro"
                >
                  <b-form-input
                    id="formEndereco.logradouro"
                    name="formEndereco.logradouro"
                    v-model="formEndereco.logradouro"
                    aria-describedby="formEndereco.logradouro"
                  >
                  </b-form-input>
                </b-form-group>
              </b-col>
              <b-col md="3">
                <b-form-group
                  label="Complemento"
                  label-for="formEndereco.complemento"
                >
                  <b-form-input
                    id="formEndereco.complemento"
                    name="formEndereco.complemento"
                    v-model="formEndereco.complemento"
                    aria-describedby="formEndereco.complemento"
                  >
                  </b-form-input>
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group label="Cidade" label-for="formEndereco.cidade">
                  <VueSelect
                    label="cidade"
                    @input="setCidade"
                    :options="cidades"
                    @search="onSearch"
                  >
                    <template slot="no-options">
                      Informe o nome da cidade
                    </template>
                    <template slot="option" slot-scope="option">
                      <div class="d-center">
                        {{ option.cidade + " - " + option.uf }}
                      </div>
                    </template>
                    <template slot="selected-option" slot-scope="option">
                      <div class="selected d-center">
                        {{ option.cidade + " - " + option.uf }}
                      </div>
                    </template>
                  </VueSelect>
                </b-form-group>
              </b-col>
              <b-col md="1">
                <button
                  type="button"
                  style="margin-top: 25px; margin-right: 15px"
                  @click="onSubmitEndereco"
                  class="btn btn-light-primary btn-bold"
                >
                  Adicionar
                </button>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="11">
                <table class="table table-hover">
                  <thead>
                    <tr>
                      <th>Tipo</th>
                      <th>CEP</th>
                      <th>Logradouro</th>
                      <th>Bairro</th>
                      <th>Número</th>
                      <th>Complemento</th>
                      <th>Cidade</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(e, index) in enderecos"
                      :key="'endereco_' + index"
                    >
                      <td>{{ e.tipo.descricao }}</td>
                      <td>{{ e.cep }}</td>
                      <td>{{ e.logradouro }}</td>
                      <td>{{ e.bairro }}</td>
                      <td>{{ e.numero }}</td>
                      <td>{{ e.complemento }}</td>
                      <td>
                        {{
                          e.cidade == null
                            ? ""
                            : e.cidade.cidade + " - " + e.cidade.uf
                        }}
                      </td>
                      <td align="right">
                        <button
                          @click="setEnderecoModal(e, index)"
                          v-b-modal="'editEndereco'"
                          style="margin-right: 15px"
                          class="btn btn-light-primary btn-sm btn-bold"
                        >
                          <Pencil :size="16" />
                        </button>
                        <button
                          @click="
                            () => {
                              $toast.error('Endereço excluído');
                              enderecos.splice(index, 1);
                            }
                          "
                          class="btn btn-light-primary btn-sm btn-bold"
                        >
                          <Close :size="16" />
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </b-col>
            </b-row>
          </b-tab>

          <b-tab>
            <b-row>
              <b-col md="3">
                <b-form-group label="Tipo" label-for="tipo_email">
                  <b-form-select
                    v-model="$v.formEmail.tipo.$model"
                    placeholder=""
                    :state="validateStateEmail('tipo')"
                  >
                    <b-form-select-option
                      v-for="(t, index) in tipoEmail"
                      :value="t"
                      :key="'tipo_email' + index"
                    >
                      {{ t.descricao }}
                    </b-form-select-option>
                  </b-form-select>
                </b-form-group>
              </b-col>
              <b-col md="7">
                <b-form-group
                  id="email"
                  label="E-mail"
                  label-for="formEmail.email"
                >
                  <b-form-input
                    id="formEmail.email"
                    name="formEmail.email"
                    v-model="$v.formEmail.email.$model"
                    :state="validateStateEmail('email')"
                    aria-describedby="formEmail.email"
                  >
                  </b-form-input>
                </b-form-group>
              </b-col>
              <b-col>
                <button
                  type="button"
                  style="margin-top: 25px"
                  @click="onSubmitEmail"
                  class="btn btn-light-primary btn-bold"
                >
                  Adicionar
                </button>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="10">
                <table class="table table-hover">
                  <thead>
                    <tr>
                      <th>Tipo</th>
                      <th>E-mail</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(e, index) in emails" :key="'email_' + index">
                      <td>{{ e.tipo.descricao }}</td>
                      <td>
                        {{ e.email }}
                      </td>
                      <td align="right">
                        <button
                          @click="
                            () => {
                              $toast.error('E-mail excluído');
                              emails.splice(index, 1);
                            }
                          "
                          class="btn btn-light-primary btn-sm btn-bold"
                        >
                          <Close :size="16" />
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </b-col>
            </b-row>
          </b-tab>

          <b-tab>
            <b-row>
              <b-col md="3">
                <b-form-group label="Tipo" label-for="tipo_telefone">
                  <b-form-select
                    v-model="$v.formTelefone.tipo.$model"
                    placeholder=""
                    :state="validateStateTelefone('tipo')"
                  >
                    <b-form-select-option
                      v-for="(t, index) in tipoFone"
                      :value="t"
                      :key="'tipo_telefone' + index"
                    >
                      {{ t.descricao }}
                    </b-form-select-option>
                  </b-form-select>
                </b-form-group>
              </b-col>
              <b-col md="7">
                <b-form-group
                  id="telefone"
                  label="Telefone"
                  label-for="example-input-0"
                >
                  <b-form-input
                    v-mask="['(##) #### ####', '(##) # #### ####']"
                    v-model="$v.formTelefone.telefone.$model"
                    :state="validateStateTelefone('telefone')"
                  >
                  </b-form-input>
                </b-form-group>
              </b-col>
              <b-col>
                <button
                  type="button"
                  style="margin-top: 25px"
                  @click="onSubmitTelefone"
                  class="btn btn-light-primary btn-bold"
                >
                  Adicionar
                </button>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="10">
                <table class="table table-hover">
                  <thead>
                    <tr>
                      <th>Tipo</th>
                      <th>Telefone</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(e, index) in telefones" :key="'fone_' + index">
                      <td>{{ e.tipo.descricao }}</td>
                      <td>
                        {{ e.fone }}
                      </td>
                      <td align="right">
                        <button
                          @click="
                            () => {
                              $toast.error('Telefone excluído');
                              telefones.splice(index, 1);
                            }
                          "
                          class="btn btn-light-primary btn-sm btn-bold"
                        >
                          <Close :size="16" />
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </b-col>
            </b-row>
          </b-tab>

          <b-tab>
            <b-row>
              <b-col md="3">
                <b-form-group
                  id="placa"
                  label="Placa"
                  label-for="example-input-0"
                >
                  <b-form-input
                    v-mask="['AAA-#X##']"
                    v-model="$v.formVeiculo.placa.$model"
                    :state="validateStatePlaca('placa')"
                    aria-describedby="input-0-live-feedback"
                  >
                  </b-form-input>
                </b-form-group>
              </b-col>
              <b-col md="3">
                <b-form-group
                  id="ANTT"
                  label="ANTT"
                  label-for="example-input-0"
                >
                  <b-form-input
                    v-model="formVeiculo.ANTT"
                    aria-describedby="input-0-live-feedback"
                  >
                  </b-form-input>
                </b-form-group>
              </b-col>
              <b-col md="3">
                <b-form-group label="UF" label-for="formVeiculo.estado">
                  <VueSelect label="estado" @input="setEstado" :options="ufs">
                    <template slot="no-options">
                      Informe o nome da cidade
                    </template>
                    <template slot="option" slot-scope="option">
                      <div class="d-center">
                        {{ option.sigla }}
                      </div>
                    </template>
                    <template slot="selected-option" slot-scope="option">
                      <div class="selected d-center">
                        {{ option.sigla }}
                      </div>
                    </template>
                  </VueSelect>
                </b-form-group>
              </b-col>
              <b-col>
                <button
                  type="button"
                  style="margin-top: 25px"
                  @click="onSubmitVeiculo"
                  class="btn btn-light-primary btn-bold"
                >
                  Adicionar
                </button>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="10">
                <table class="table table-hover">
                  <thead>
                    <tr>
                      <th>Placa</th>
                      <th>ANTT</th>
                      <th>Estado</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(v, index) in veiculos"
                      :key="'veiculo_' + index"
                    >
                      <td>
                        {{ v.placa }}
                      </td>
                      <td>
                        {{ v.ANTT }}
                      </td>
                      <td>
                        {{ v.estado }}
                      </td>
                      <td align="right">
                        <button
                          @click="
                            () => {
                              $toast.error('Veículo excluído');
                              veiculos.splice(index, 1);
                            }
                          "
                          class="btn btn-light-primary btn-sm btn-bold"
                        >
                          <Close :size="16" />
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </b-col>
            </b-row>
          </b-tab>
        </b-tabs>
      </div>

      <b-modal id="editEndereco" size="md" centered title="Editando endereço">
        <b-row>
          <b-col md="4">
            <b-form-group label="CEP" label-for="enderecoModal.cep">
              <b-form-input
                ref="cep"
                id="enderecoModal.cep"
                name="enderecoModal.cep"
                v-model="enderecoModal.cep"
                v-mask="['#####-###']"
                aria-describedby="enderecoModal.cep"
              >
              </b-form-input>
            </b-form-group>
          </b-col>
          <b-col md="2">
            <b-form-group label="Número" label-for="enderecoModal.numero">
              <b-form-input
                id="enderecoModal.numero"
                name="enderecoModal.numero"
                v-model="enderecoModal.numero"
                aria-describedby="enderecoModal.numero"
              >
              </b-form-input>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label="Bairro" label-for="enderecoModal.bairro">
              <b-form-input
                id="enderecoModal.bairro"
                name="enderecoModal.bairro"
                v-model="enderecoModal.bairro"
                aria-describedby="enderecoModal.bairro"
              >
              </b-form-input>
            </b-form-group>
          </b-col>
          <b-col md="8">
            <b-form-group
              label="Logradouro"
              label-for="enderecoModal.logradouro"
            >
              <b-form-input
                id="enderecoModal.logradouro"
                name="enderecoModal.logradouro"
                v-model="enderecoModal.logradouro"
                aria-describedby="enderecoModal.logradouro"
              >
              </b-form-input>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group
              label="Complemento"
              label-for="enderecoModal.complemento"
            >
              <b-form-input
                id="enderecoModal.complemento"
                name="enderecoModal.complemento"
                v-model="enderecoModal.complemento"
                aria-describedby="enderecoModal.complemento"
              >
              </b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
        <template #modal-footer="{ ok, cancel }">
          <b-button
            @click="
              () => {
                cancel();
                openModal = false;
              }
            "
          >
            Cancelar
          </b-button>
          <b-button
            variant="primary"
            ref="salvarEnderecoModal"
            @click="salvarEnderecoModal"
          >
            Confirmar
          </b-button>
        </template>
      </b-modal>

      <div class="card-footer">
        <div class="row">
          <div class="col-lg-12" style="text-align: center">
            <button
              type="submit"
              ref="submit_nova_pessoa"
              class="btn btn-primary font-weight-bold"
              @click="onSubmit"
            >
              Salvar
            </button>
            <router-link
              class="btn btn-clean font-weight-bold"
              :to="{
                name: 'pessoasLista',
              }"
            >
              Cancelar
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { validationMixin } from "vuelidate";
import { mask } from "vue-the-mask";
import { SistemaMixin, ValidacaoMixin } from "@/modules/Application";
import PessoaEmailData, { PessoaEmailRequired } from "./PessoaEmailData";
import PessoaData, { PessoaDataRequired } from "./PessoaData";
import PessoaTelefoneData, {
  PessoaTelefoneDataRequired,
} from "./PessoaTelefoneData";
import PessoaEnderecoData, {
  PessoaEnderecoDataRequired,
} from "./PessoaEnderecoData";
import PessoaVeiculoData, {
  PessoaVeiculoDataRequired,
} from "./PessoaVeiculoData";
import Close from "vue-material-design-icons/Close.vue";
import VueSelect from "vue-select";
import loadsh from "loadsh";
import "vue-select/dist/vue-select.css";
import { mapGetters } from "vuex";
import { onboardStore } from "../../../onboardStore";
import AccountStar from "vue-material-design-icons/AccountStar.vue";
import Pencil from "vue-material-design-icons/Pencil.vue";
import { Money } from "v-money";

export default {
  name: "app-pessoa-form",
  props: {
    url: {
      type: String,
    },
    pessoa: {
      type: Object,
      required: true,
    },
  },
  components: {
    Close,
    VueSelect,
    AccountStar,
    Pencil,
    Money,
  },
  directives: {
    mask,
  },
  mixins: [validationMixin, SistemaMixin, ValidacaoMixin],
  data() {
    return {
      onboardStore,
      tabIndex: 0,
      tipoEndereco: [],
      tipoFone: [],
      tipoEmail: [],
      tipoPessoa: [],
      tipoPessoaSelected: [],
      tipoPessoaOptions: [
        {
          id_tipo: 1,
          descricao: "Cliente",
          item: {
            id_tipo: 1,
            descricao: "Cliente",
          },
          name: "Cliente",
        },
        {
          id_tipo: 2,
          descricao: "Fornecedor",
          item: {
            id_tipo: 2,
            descricao: "Fornecedor",
          },
          name: "Fornecedor",
        },
        {
          id_tipo: 3,
          descricao: "Transportador",
          item: {
            id_tipo: 3,
            descricao: "Transportador",
          },
          name: "Transportador",
        },
        {
          id_tipo: 5,
          descricao: "Administradoras",
          item: {
            id_tipo: 5,
            descricao: "Administradoras",
          },
          name: "Administradoras",
        },
      ],
      cidades: [],
      emails: [],
      telefones: [],
      enderecos: [],
      formEmail: {},
      formTelefone: {},
      formEndereco: {},
      form: {},
      cidade: {},
      veiculos: [],
      ufs: [],
      uf: null,
      identificadores_ie: [
        {
          id: 1,
          descricao: "Contribuinte",
        },
        {
          id: 2,
          descricao: "Isento",
        },
        {
          id: 9,
          descricao: "Não Contribuinte",
        },
      ],
      estados_civil: [
        {
          id: 1,
          descricao: "Casado(a)",
        },
        {
          id: 2,
          descricao: "Solteiro(a)",
        },
        {
          id: 3,
          descricao: "Separado(a)",
        },
        {
          id: 4,
          descricao: "Divorciado(a)",
        },
        {
          id: 5,
          descricao: "Viúvo(a)",
        },
      ],
      paises: [],
      enderecoModal: {},
    };
  },
  validations: {
    form: PessoaDataRequired,
    formEmail: PessoaEmailRequired,
    formTelefone: PessoaTelefoneDataRequired,
    formEndereco: PessoaEnderecoDataRequired,
    formVeiculo: PessoaVeiculoDataRequired,
  },
  watch: {
    pessoa(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.form = newValue;
        this.emails = newValue.emails;
        this.telefones = newValue.fones;
        this.enderecos = newValue.enderecos;
        this.veiculos = newValue.veiculos;
        this.tipoPessoaSelected = newValue.pessoa_tipo.map((item) => ({
          descricao: item.tipo.descricao,
          id_tipo: item.tipo.id_tipo,
        }));
        this.form.created_at = this.formatDate(
          newValue.created_at,
          "YYYY/MM/DD"
        );
      }
    },
    tipoPessoaSelected: {
      handler(value) {
        this.form.pessoa_tipo = value;
      },
      deep: true,
    },
  },
  computed: {
    ...mapGetters(["itsblocked"]),
  },
  async mounted() {
    await this.getDataInit();
    if (this.itsblocked) {
      this.$router.push({ name: "assinatura" });
    }
  },
  methods: {
    ...mapActions(["setLoading"]),
    setEnderecoModal(e, index) {
      this.enderecoModal = e;
      this.enderecoModal.index = index;
    },
    salvarEnderecoModal() {
      this.enderecos.splice(this.enderecoModal.index, 1);

      this.enderecos.push({
        numero: this.enderecoModal.numero,
        cep: this.enderecoModal.cep,
        logradouro: this.enderecoModal.logradouro,
        bairro: this.enderecoModal.bairro,
        complemento: this.enderecoModal.complemento,
        id_endereco_tipo: this.enderecoModal.tipo.id_tipo,
        id_cidade: this.enderecoModal.cidade.id_cidade,
        cidade: this.enderecoModal.cidade,
        tipo: this.enderecoModal.tipo,
        id_tipo: this.enderecoModal.tipo.id_tipo,
      });

      this.$bvModal.hide("editEndereco");
      this.enderecoModal = {};
    },
    setEstado(uf) {
      this.uf = uf.sigla;
    },
    setTipoAtacado() {
      this.form.tipo_atacado = this.form.tipo_atacado == true ? false : true;
    },
    setProdutorRural() {
      this.form.produtor_rural =
        this.form.produtor_rural == true ? false : true;
    },
    setAtivo() {
      this.form.status = this.form.status == "A" ? "I" : "A";
    },
    setEstrangeiro() {
      this.form.estrangeiro = this.form.estrangeiro == true ? false : true;
      this.form.municipio_estrangeiro = "EXTERIOR";
      this.form.uf_estrangeiro = "EX";
    },
    setCidade(cidade) {
      this.cidade = cidade;
    },
    onSearch(search, loading) {
      if (search.length) {
        loading(true);
        this.search(loading, search, this);
      }
    },
    search: loadsh.debounce(async (loading, search, vm) => {
      const city = await vm.getCidade(search);
      vm.cidades = city;
      loading(false);
    }, 350),
    async consultaCep() {
      this.setLoading(true);
      const cep = await this.getCep(this.formEndereco.cep);

      if (cep.length == 0) {
        this.setLoading(false);
        return;
      }

      this.formEndereco.logradouro = cep.logradouro;
      this.formEndereco.bairro = cep.bairro;

      if (cep.erro) {
        this.setLoading(false);
        this.$toast.warning("Confira o CEP informado!");
        return;
      }

      const city = await this.getCidadeByUF(cep.localidade, cep.uf);

      this.cidades = city;
      this.setLoading(false);
    },
    async getDataInit() {
      this.form = new PessoaData({});
      this.emails = this.form.emails;
      this.telefones = this.form.fones;
      this.enderecos = this.form.enderecos;
      this.veiculos = this.form.veiculos;

      this.formEmail = new PessoaEmailData({});
      this.formTelefone = new PessoaTelefoneData({});
      this.formEndereco = new PessoaEnderecoData({});
      this.formVeiculo = new PessoaVeiculoData({});

      this.tipoEndereco = await this.getTipoEndereco();
      this.tipoFone = await this.getTipoFone();
      this.tipoEmail = await this.getTipoEmail();
      this.tipoPessoa = await this.getTipoPessoa();
      this.ufs = await this.getUF();
      this.paises = await this.getPaises();
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    validateStateEmail(name) {
      const { $dirty, $error } = this.$v.formEmail[name];
      return $dirty ? !$error : null;
    },
    validateStateTelefone(name) {
      const { $dirty, $error } = this.$v.formTelefone[name];
      return $dirty ? !$error : null;
    },
    validateStateEndereco(name) {
      const { $dirty, $error } = this.$v.formEndereco[name];
      return $dirty ? !$error : null;
    },
    validateStatePlaca(name) {
      const { $dirty, $error } = this.$v.formVeiculo[name];
      return $dirty ? !$error : null;
    },
    validateStateANTT(name) {
      const { $dirty, $error } = this.$v.formVeiculo[name];
      return $dirty ? !$error : null;
    },
    setActivePreviousTab() {
      this.tabIndex = parseInt(localStorage.getItem("builderTab")) || 0;
      const links = this.$refs["builder-tab"].querySelectorAll(".nav-link");

      for (let i = 0; i < links.length; i++) {
        links[i].classList.remove("active");
      }

      this.$refs["builder-tab"]
        .querySelector(`[data-tab="${this.tabIndex}"]`)
        .classList.add("active");
    },
    setActiveTab(event) {
      const tab = event.target.closest('[role="tablist"]');
      const links = tab.querySelectorAll(".nav-link");

      for (let i = 0; i < links.length; i++) {
        links[i].classList.remove("active");
      }

      this.tabIndex = parseInt(event.target.getAttribute("data-tab"));

      event.target.classList.add("active");
    },
    async onSubmitEmail() {
      this.$v.formEmail.$touch();

      if (this.$v.formEmail.$anyError) {
        return;
      }

      this.emails.push({
        id_tipo_email: this.formEmail.tipo.id_tipo,
        tipo: this.formEmail.tipo,
        id_tipo: this.formEmail.tipo.id_tipo,
        email: this.formEmail.email,
        descricao: this.formEmail.descricao,
      });

      this.$toast.success("E-mail adicionado!");
      this.$v.formEmail.$reset();
      this.formEmail = new PessoaEmailData({});

      return;
    },
    async onSubmitTelefone() {
      this.$v.formTelefone.$touch();

      if (this.$v.formTelefone.$anyError) {
        return;
      }

      this.telefones.push({
        tipo: this.formTelefone.tipo,
        fone: this.formTelefone.telefone,
        id_tipo: this.formTelefone.tipo.id_tipo,
        descricao: this.formTelefone.descricao,
      });

      this.$toast.success("Telefone adicionado!");
      this.$v.formTelefone.$reset();
      this.formTelefone = new PessoaTelefoneData({});

      return;
    },
    async onSubmitEndereco() {
      this.$v.formEndereco.$touch();

      if (this.$v.formEndereco.$anyError) {
        return;
      }

      if (!this.cidade.id_cidade) {
        return this.$toast.error("Selecione uma cidade!");
      }

      this.enderecos.push({
        numero: this.formEndereco.numero,
        cep: this.formEndereco.cep,
        logradouro: this.formEndereco.logradouro,
        bairro: this.formEndereco.bairro,
        complemento: this.formEndereco.complemento,
        id_endereco_tipo: this.formEndereco.tipo.id_tipo,
        id_cidade: this.cidade.id_cidade,
        cidade: this.cidade,
        tipo: this.formEndereco.tipo,
        id_tipo: this.formEndereco.tipo.id_tipo,
      });

      this.$toast.success("Endereço adicionado!");
      this.$v.formEndereco.$reset();
      this.cidade = {};
      this.formEndereco = new PessoaEnderecoData({});

      return;
    },
    async onSubmitVeiculo() {
      this.$v.formVeiculo.$touch();

      if (this.$v.formVeiculo.$anyError) {
        return;
      }

      this.veiculos.push({
        placa: this.formVeiculo.placa,
        estado: this.uf,
        ANTT: this.formVeiculo.ANTT,
      });

      this.$toast.success("Veículo adicionado!");
      this.$v.formVeiculo.$reset();
      this.uf = null;
      this.formVeiculo = new PessoaVeiculoData({});

      return;
    },
    async onSubmit() {
      const submitButton = this.$refs["submit_nova_pessoa"];

      try {
        this.$v.form.$touch();

        if (this.$v.form.$anyError) {
          this.$toast.error(
            "Verifique todos os campos obrigatórios do formulário!"
          );
          return;
        }

        if (this.form.pessoa_tipo == null) {
          return this.$toast.warning(
            "Informe o tipo do cadastro, se vai ser um cliente/fornecedor ou transportador"
          );
        }

        if (this.form.cnpj_cpf) {
          if (!this.validaCpfCnpj(this.form.cnpj_cpf)) {
            return this.$toast.error("CNPJ/CPF informado é inválido!");
          }
        }

        submitButton.classList.add("spinner", "spinner-light", "spinner-right");
        submitButton.setAttribute("disabled", "");

        if (this.emails != null) {
          this.form.email = this.emails;
        }
        if (this.enderecos != null) {
          if (this.formEndereco.cep && this.cidade) {
            this.enderecos.push({
              numero: this.formEndereco.numero,
              cep: this.formEndereco.cep,
              logradouro: this.formEndereco.logradouro,
              bairro: this.formEndereco.bairro,
              complemento: this.formEndereco.complemento,
              id_endereco_tipo: this.formEndereco.tipo.id_tipo,
              id_cidade: this.cidade.id_cidade,
              cidade: this.cidade,
              tipo: this.formEndereco.tipo,
              id_tipo: this.formEndereco.tipo.id_tipo,
            });
          }

          this.form.endereco = this.enderecos;
        }
        if (this.telefones != null) {
          this.form.telefone = this.telefones;
        }
        await this.$http.post(this.url, this.form);

        if (this.onboardStore.currentStep > 0) {
          this.onboardStore.currentStep = 8;
        }

        this.$router.push({ name: "pessoasLista" });
      } catch (error) {
        submitButton.classList.remove(
          "spinner",
          "spinner-light",
          "spinner-right"
        );
        submitButton.removeAttribute("disabled", "");
      }
    },
    setIdentificador() {
      if (this.form.identificador_ie == 2) {
        this.form.ie_rg = "Isento";
      }
    },
    async consultaReceita() {
      this.setLoading(true);
      try {
        const receita = await this.getConsultaReceita(this.form.cnpj_cpf);
        this.form.nome = receita.nome;
        this.form.nome_fantasia = receita.nome_fantasia ?? null;
        this.form.responsavel = receita.responsavel ?? null;

        if (receita.endereco.cep != null) {
          this.enderecos.push({
            numero: receita.endereco.numero,
            cep: receita.endereco.cep,
            logradouro: receita.endereco.logradouro,
            bairro: receita.endereco.bairro,
            complemento: receita.endereco.complemento,
            id_cidade: receita.endereco.id_cidade,
            cidade: receita.endereco.cidade,
            id_tipo: receita.endereco.id_tipo,
            tipo: receita.endereco.tipo,
          });
        }

        if (receita.telefone.fone != null) {
          this.telefones.push({
            tipo: receita.telefone.tipo,
            fone: receita.telefone.fone,
            id_tipo: receita.telefone.id_tipo,
            descricao: receita.telefone.descricao,
          });
        }

        if (receita.email.email != null) {
          this.emails.push({
            id_tipo_email: receita.email.id_tipo_email,
            tipo: receita.email.tipo,
            id_tipo: receita.email.id_tipo,
            email: receita.email.email,
            descricao: receita.email.descricao,
          });
        }
        this.$toast.success(
          "Dados carregados com sucesso, verifique os dados!"
        );
      } catch (error) {
        console.log(error);
      }

      this.setLoading(false);
    },
    async consultaCPF() {
      this.setLoading(true);
      try {
        const dadosCPF = await this.getConsultaCPF(this.form.cnpj_cpf);

        if (
          dadosCPF.status == false &&
          dadosCPF.msg == "Nenhum saldo disponível entre em contato com a Nuts"
        ) {
          this.setLoading(false);
          return this.$toast.warning(dadosCPF.msg);
        }

        if (dadosCPF.status == false) {
          this.setLoading(false);
          return this.$toast.warning(
            "Verifique o CPF informado, pois não foi possível localizar."
          );
        }
        this.form.nome = dadosCPF.nome;
        this.form.ie_rg = dadosCPF.ie_rg ?? null;
        this.form.data_nascimento = dadosCPF.data_nascimento ?? null;
        this.form.pai_mae = dadosCPF.pai_mae ?? null;

        if (dadosCPF.endereco) {
          this.enderecos.push({
            numero: dadosCPF.endereco.numero,
            cep: dadosCPF.endereco.cep,
            logradouro: dadosCPF.endereco.logradouro,
            bairro: dadosCPF.endereco.bairro,
            complemento: dadosCPF.endereco.complemento,
            id_cidade: dadosCPF.endereco.id_cidade,
            cidade: dadosCPF.endereco.cidade,
            id_tipo: dadosCPF.endereco.id_tipo,
            tipo: dadosCPF.endereco.tipo,
          });
        }

        if (dadosCPF.telefone) {
          this.telefones.push({
            tipo: dadosCPF.telefone.tipo,
            fone: dadosCPF.telefone.fone,
            id_tipo: dadosCPF.telefone.id_tipo,
            descricao: dadosCPF.telefone.descricao,
          });
        }

        if (dadosCPF.email) {
          this.emails.push({
            id_tipo_email: dadosCPF.email.id_tipo_email,
            tipo: dadosCPF.email.tipo,
            id_tipo: dadosCPF.email.id_tipo,
            email: dadosCPF.email.email,
            descricao: dadosCPF.email.descricao,
          });
        }
        this.$toast.success(
          "Dados carregados com sucesso, verifique os dados!"
        );
      } catch (error) {
        console.log(error);
      }

      this.setLoading(false);
    },
    async consultarCreditosUnicheck() {
      const { data } = await this.$http.post("/saldo/consulta-saldo", {
        tipo: 1,
      });
      this.$toast.warning("Seu saldo é de: " + data.saldo + " consulta(s)");
      return;
    },
  },
};
</script>

<style>
.vs__dropdown-toggle {
  border: 2px solid #dedede;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border-radius: 6px;
  outline: none;
  height: 38px;
}
</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>