<template>
  <Form url="/tributacao/create" :tributacao="{}" />
</template>

<script>
import Form from "./Form.vue";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

export default {
  name: "app-tributacao-create",
  components: {
    Form
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {
        title: "Natureza"
      }
    ]);
  }
};
</script>
