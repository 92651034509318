/* eslint-disable */

import Vue from "vue";
import Router from "vue-router";
import Login from '@/modules/Login';
import Dashboard from '@/modules/Dashboard';
import Pessoa from '@/modules/Pessoa';
import Emitente from '@/modules/Emitente';
import Naturezas from '@/modules/Naturezas';
import UnidadeMedidas from '@/modules/UnidadeMedidas';
import Grupos from '@/modules/Grupos';
import Produtos from '@/modules/Produtos';
import Parcelamentos from '@/modules/Parcelamentos';
import Variacoes from '@/modules/Variacoes';
import ResetPassword from '@/modules/ResetPassword';
import Financeiro from '@/modules/Financeiro';
import ContaReceber from '@/modules/ContaReceber';
import ContaPagar from '@/modules/ContaPagar';
import Usuarios from '@/modules/Usuarios';
import Configuracoes from '@/modules/Configuracoes';
import Vendas from '@/modules/Vendas';
import NFe from '@/modules/NFe';
import Pedidos from '@/modules/Pedidos';
import Kit from '@/modules/Kit';
import OS from '@/modules/OS';
import Integracoes from '@/modules/Integracoes';
import OP from '@/modules/Producao';
import Servicos from '@/modules/Servicos';
import Logistica from '@/modules/Logistica';
import Orcamento from '@/modules/Orcamento';
import Layout from '@/view/layout/Layout';
import Termos from '@/modules/Termos';
import Tributacoes from '@/modules/Tributacoes';
import Relatorios from '@/modules/Relatorios';
import Contador from '@/modules/Contador';
import Compras from '@/modules/Compras';
import Assinatura from '@/modules/Assinatura';
import Revenda from '@/modules/Revenda';
import Clientes from '@/modules/Clientes';
import ConfirmacaoCadastro from '@/modules/ConfirmacaoCadastro';
import Gerais from '@/modules/ConfiguracoesGerais'
import Bancos from '@/modules/Bancos';
import Boletos from '@/modules/Boletos';
import Perfil from '@/modules/Perfil';
import Pix from '@/modules/Pix';
import Links from "./modules/Links";
import LinkPayment from "./modules/LinkPayment";
import LinkOrcamento from "./modules/LinkOrcamento";
import LinkPedido from "./modules/LinkPedido";
import LinkOrdemServico from "./modules/LinkOrdemServico";
import VisualizacaoImpressao from "./modules/VisualizacaoImpressao";
import OrcamentoTimeline from "./modules/OrcamentoTimeline";
import Vendedor from "./modules/Vendedor";
import LayoutPix from '@/view/layout/LayoutPix';
import LayoutImpressao from '@/view/layout/LayoutImpressao';
import LayoutPaymentLink from '@/view/layout/LayoutPaymentLink';
import MonitorNFe from '@/modules/MonitorNFe';
import Objetos from '@/modules/Objetos';
import Identificadores from '@/modules/Identificadores';
import Tecnicos from '@/modules/Tecnicos';
import Prospect from '@/modules/Prospect';
import LinkProspect from '@/modules/LinkProspect';
import LayoutProspectLink from '@/view/layout/LayoutProspectLink';
import Pos from '@/modules/Pos';
import OrdemCompra from '@/modules/OrdemCompra';
import LinkOrdemCompra from "./modules/LinkOrdemCompra";
import VisualizacaoBanco from "./modules/VisualizacaoBanco";
import PlanoDeContas from "./modules/PlanoDeContas";

Vue.use(Router);

export default new Router({
  routes: [
    {
      path: "/",
      redirect: "/dashboard",
      component: Layout,
      children: [
        ...Dashboard,
        ...Pessoa,
        ...Emitente,
        ...Naturezas,
        ...Grupos,
        ...Produtos,
        ...Parcelamentos,
        ...Variacoes,
        ...UnidadeMedidas,
        ...Financeiro,
        ...ContaReceber,
        ...ContaPagar,
        ...Usuarios,
        ...Configuracoes,
        ...Vendas,
        ...Tributacoes,
        ...Relatorios,
        ...Contador,
        ...Compras,
        ...Assinatura,
        ...Revenda,
        ...Clientes,
        ...Bancos,
        ...Boletos,
        ...NFe,
        ...Pedidos,
        ...Kit,
        ...OS,
        ...Integracoes,
        ...OP,
        ...OrcamentoTimeline,
        ...Orcamento,
        ...Links,
        ...Perfil,
        ...Gerais,
        ...Vendedor,
        ...MonitorNFe,
        ...Objetos,
        ...Identificadores,
        ...Tecnicos,
        ...Servicos,
        ...Logistica,
        ...Prospect,
        ...Pos,
        ...OrdemCompra,
        ...PlanoDeContas,
      ],
    },
    {
      path: "/",
      component: LayoutPix,
      children: [
        ...Pix
      ],
    },
    {
      path: "/",
      component: LayoutImpressao,
      children: [
        ...VisualizacaoImpressao,
        ...VisualizacaoBanco
      ],
    },
    {
      path: "/",
      component: LayoutPaymentLink,
      children: [
        ...LinkPayment,
        ...LinkOrcamento,
        ...LinkPedido,
        ...LinkOrdemServico,
        ...LinkProspect,
        ...LinkOrdemCompra,
      ],
    },
    {
      path: "/",
      component: LayoutProspectLink,
      children: [
        ...LinkProspect,
      ],
    },
    ...Login,
    ...ResetPassword,
    ...Termos,
    ...ConfirmacaoCadastro,
    {
      path: "/*",
      name: "404",
      component: () => import("@/modules/Errors/pages/404.vue")
    }
  ]
});
