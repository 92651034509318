<template>
  <div class="card-form container" v-if="!finishedPayment">
    <div style="text-align: center" class="pt-10">
      <img
        alt="Logo"
        :src="layoutConfig('self.logo.icone')"
        class="max-h-80px"
      />
    </div>
    <form-wizard
      @on-complete="handleSendPayment"
      title="Pagamento"
      subtitle="Preencha todos os campos obrigatórios"
      nextButtonText="Avançar"
      backButtonText="Voltar"
      finishButtonText="Finalizar"
      stepSize="xs"
      color="var(--primary-color)"
      errorColor="#172c30"
    >
      <tab-content title="Identificação" :before-change="() => validateStep(1)">
        <b-row class="justify-content-md-center">
          <b-col>
            <div class="card-input">
              <label for="name" class="card-input__label"> Nome *</label>
              <b-form-input
                autofocus
                maxlength="50"
                v-model="formIdentificacao.nome"
                class="card-input__input"
              />
            </div>
          </b-col>
        </b-row>
        <b-row class="justify-content-md-center">
          <b-col>
            <div class="card-input">
              <label for="email" class="card-input__label"> Email *</label>
              <b-form-input
                autofocus
                maxlength="50"
                v-model="formIdentificacao.email"
                class="card-input__input"
              />
            </div>
          </b-col>
        </b-row>
        <b-row class="justify-content-md-center">
          <b-col>
            <div class="card-input">
              <label for="cpf_cnpj" class="card-input__label">
                CPF ou CNPJ *</label
              >
              <b-form-input
                autofocus
                v-model="formIdentificacao.cpf_cnpj"
                v-mask="['###.###.###-##', '##.###.###/####-##']"
                class="card-input__input"
              />
            </div>
          </b-col>
        </b-row>
        <b-row class="justify-content-md-center">
          <b-col>
            <div class="card-input">
              <label for="telefone" class="card-input__label">
                Telefone com DDD</label
              >
              <b-form-input
                autofocus
                v-model="formIdentificacao.telefone"
                v-mask="['(##) #####-####']"
                placeholder="(00) 00000-0000"
                class="card-input__input"
              />
            </div>
          </b-col>
        </b-row>
        <b-row class="justify-content-md-center">
          <b-col>
            <div class="card-input">
              <label for="telefone" class="card-input__label">
                Celular com DDD *</label
              >
              <b-form-input
                autofocus
                v-model="formIdentificacao.celular"
                v-mask="['(##) #####-####']"
                placeholder="(00) 00000-0000"
                class="card-input__input"
              />
            </div>
          </b-col>
        </b-row>
      </tab-content>

      <tab-content title="Endereço" :before-change="() => validateStep(2)">
        <b-row class="justify-content-md-center">
          <b-col>
            <div class="card-input">
              <label for="cep" class="card-input__label"> CEP *</label>
              <b-form-input
                ref="cep"
                id="formEndereco.cep"
                name="formEndereco.cep"
                v-mask="['#####-###']"
                aria-describedby="formEndereco.cep"
                v-model="formEndereco.cep"
                class="card-input__input"
              />
              <label
                role="button"
                for="consulta-cep"
                class="text-primary"
                @click="consultaCep"
              >
                consulta CEP</label
              >
            </div>
          </b-col>
        </b-row>
        <b-row class="justify-content-md-center">
          <b-col>
            <div class="card-input">
              <label for="cep" class="card-input__label"> Logradouro *</label>
              <b-form-input
                ref="logradouro"
                id="formEndereco.logradouro"
                name="formEndereco.logradouro"
                v-model="formEndereco.logradouro"
                aria-describedby="formEndereco.logradouro"
                class="card-input__input"
              />
            </div>
          </b-col>
        </b-row>
        <b-row class="justify-content-md-center">
          <b-col>
            <div class="card-input">
              <label for="cep" class="card-input__label"> Número *</label>
              <b-form-input
                ref="numero"
                id="formEndereco.numero"
                name="formEndereco.numero"
                v-model="formEndereco.numero"
                aria-describedby="formEndereco.numero"
                class="card-input__input"
              />
            </div>
          </b-col>
        </b-row>
        <b-row class="justify-content-md-center">
          <b-col>
            <div class="card-input">
              <label for="cep" class="card-input__label"> Complemento</label>
              <b-form-input
                ref="complemento"
                id="formEndereco.complemento"
                name="formEndereco.complemento"
                v-model="formEndereco.complemento"
                aria-describedby="formEndereco.complemento"
                class="card-input__input"
              />
            </div>
          </b-col>
        </b-row>
        <b-row class="justify-content-md-center">
          <b-col>
            <div class="card-input">
              <label for="cep" class="card-input__label"> Bairro *</label>
              <b-form-input
                ref="bairro"
                id="formEndereco.bairro"
                name="formEndereco.bairro"
                v-model="formEndereco.bairro"
                aria-describedby="formEndereco.bairro"
                class="card-input__input"
              />
            </div>
          </b-col>
        </b-row>
        <b-row class="justify-content-md-center">
          <b-col>
            <div class="card-input">
              <label for="cep" class="card-input__label"> Cidade *</label>
              <select
                class="card-input__input -select"
                id="cidade"
                v-model="selectedCity"
                v-if="consultaCepFire"
                data-card-field
                @change="onChangeCidadeSelected"
              >
                <option
                  v-bind:value="option"
                  v-for="option in cidades"
                  v-bind:key="option.cidade"
                >
                  {{ option.cidade + " (" + option.uf + ")" }}
                </option>
              </select>
              <div class="card-input" v-else>
                <b-form-input
                  ref="cidade"
                  id="formEndereco.cidade"
                  name="formEndereco.cidade"
                  v-model="formEndereco.cidade"
                  aria-describedby="formEndereco.cidade"
                  class="card-input__input"
                />
              </div>
            </div>
          </b-col>
        </b-row>
        <b-row class="justify-content-md-center">
          <b-col>
            <div class="card-input">
              <label for="cep" class="card-input__label"> Estado *</label>
              <b-form-input
                ref="estado"
                id="formEndereco.estado"
                name="formEndereco.estado"
                maxlength="2"
                v-model="formEndereco.estado"
                aria-describedby="formEndereco.estado"
                class="card-input__input"
              />
            </div>
          </b-col>
        </b-row>
      </tab-content>

      <tab-content title="Pagamento" :before-change="() => validateStep(3)">
        <b-row class="mt-10">
          <b-col>
            <Card
              :fields="fields"
              :labels="formCartao"
              :isCardNumberMasked="true"
              :randomBackgrounds="true"
            />
          </b-col>
        </b-row>
        <b-row class="mt-15">
          <b-col :md="colMDCartao">
            <div class="card-form__group">
              <label for="cardMonth" class="card-input__label">
                Vezes (Pagamento)</label
              >
              <select
                class="card-input__input -select"
                :id="fields.cardVezes"
                v-model="formCartao.cardVezes"
                data-card-field
              >
                <option value disabled selected>Vezes</option>
                <option
                  v-bind:value="parcela"
                  v-for="parcela in parcelas"
                  v-bind:key="parcela.vezes"
                >
                  {{ parcela.vezes_descricao }}
                </option>
              </select>
            </div>
          </b-col>
        </b-row>
        <b-row class="mt-5">
          <b-col>
            <div class="card-input">
              <label for="cardNumber" class="card-input__label"
                >Número do Cartão</label
              >
              <input
                type="tel"
                :id="fields.cardNumber"
                @input="changeNumber"
                @focus="focusCardNumber"
                @blur="blurCardNumber"
                class="card-input__input"
                :value="formCartao.cardNumber"
                :maxlength="cardNumberMaxLength"
                data-card-field
                autocomplete="off"
              />
              <button
                class="card-input__eye"
                :class="{ '-active': !isCardNumberMasked }"
                title="Show/Hide card number"
                tabindex="-1"
                :disabled="formCartao.cardNumber === ''"
                @click="toggleMask"
              ></button>
            </div>
          </b-col>
        </b-row>

        <b-row>
          <b-col>
            <div class="card-input">
              <label for="cardName" class="card-input__label">
                Nome Titular
              </label>
              <input
                type="text"
                :id="fields.cardName"
                v-letter-only
                @input="changeName"
                class="card-input__input"
                :value="formCartao.cardName"
                data-card-field
                autocomplete="off"
              />
            </div>
          </b-col>
        </b-row>

        <b-row>
          <b-col md="8">
            <div class="card-form__row">
              <div class="card-form__col">
                <div class="card-form__group">
                  <label for="cardMonth" class="card-input__label">
                    Data de Vencimento</label
                  >
                  <b-row class="w-100 text-center">
                    <b-col>
                      <select
                        class="card-input__input -select"
                        :id="fields.cardMonth"
                        v-model="formCartao.cardMonth"
                        @change="changeMonth"
                        data-card-field
                      >
                        <option value disabled selected>Mês</option>
                        <option
                          v-bind:value="n < 10 ? '0' + n : n"
                          v-for="n in 12"
                          v-bind:disabled="n < minCardMonth"
                          v-bind:key="n"
                        >
                          {{ generateMonthValue(n) }}
                        </option>
                      </select>
                    </b-col>
                    <b-col>
                      <select
                        class="card-input__input -select"
                        :id="fields.cardYear"
                        v-model="formCartao.cardYear"
                        @change="changeYear"
                        data-card-field
                      >
                        <option value disabled selected>Ano</option>
                        <option
                          v-bind:value="$index + minCardYear"
                          v-for="(n, $index) in 12"
                          v-bind:key="n"
                        >
                          {{ $index + minCardYear }}
                        </option>
                      </select>
                    </b-col>
                  </b-row>
                </div>
              </div>
            </div>
          </b-col>
          <b-col>
            <div class="card-form-row">
              <div class="card-form__col -cvv">
                <div class="card-input">
                  <label for="cardCvv" class="card-input__label">CVV</label>
                  <input
                    type="tel"
                    class="card-input__input"
                    v-number-only
                    :id="fields.cardCvv"
                    maxlength="3"
                    :value="formCartao.cardCvv"
                    @input="changeCvv"
                    data-card-field
                    autocomplete="off"
                  />
                </div>
              </div>
            </div>
          </b-col>
        </b-row>
      </tab-content>

      <tab-content title="Revisão">
        <b-card
          title="Identificação"
          tag="article"
          class="mb-2 border border-primary"
        >
          <b-card-text>
            <strong>Nome:</strong> {{ formIdentificacao.nome }} <br />
            <strong>Email:</strong> {{ formIdentificacao.email }} <br />
            <strong>CPF/CNPJ:</strong> {{ formIdentificacao.cpf_cnpj }} <br />
            <strong>Telefone:</strong> {{ formIdentificacao.telefone }} <br />
            <strong>Celular:</strong> {{ formIdentificacao.celular }}
          </b-card-text>
        </b-card>
        <b-card
          title="Endereço"
          tag="article"
          class="mb-2 border border-primary"
        >
          <b-card-text>
            <strong>CEP:</strong> {{ formEndereco.cep }} <br />
            <strong>Endereço:</strong>
            {{
              `${formEndereco.logradouro}, ${formEndereco.numero} - ${formEndereco.bairro}`
            }}
            <br />
            <strong>Cidade:</strong> {{ formEndereco.cidade }} <br />
            <strong>UF:</strong> {{ formEndereco.estado }}
          </b-card-text>
        </b-card>
        <b-card
          title="Pagamento"
          tag="article"
          class="mb-2 border border-primary"
        >
          <b-card-text>
            <strong>Titular:</strong> {{ formCartao.cardName }} <br />
            <strong>Número:</strong> {{ formCartao.cardNumber }} <br />
            <strong>Pagamento:</strong>
            {{
              `${formCartao.cardVezes.vezes} x ${numberToReal(
                formCartao.cardVezes.valor, 2
              )}`
            }}
          </b-card-text>
        </b-card>
      </tab-content>
    </form-wizard>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { validationMixin } from "vuelidate";
import { SistemaMixin, ValidacaoMixin } from "@/modules/Application";
import { FormWizard, TabContent } from "vue-form-wizard";
import { mask } from "vue-the-mask";
// import VueSelect from "vue-select";
// import CardForm from "@/modules/Assinatura/Cards/CardForm.vue";
import Card from "@/modules/Assinatura/Cards/Card.vue";
import loadsh from "loadsh";

export default {
  name: "app-link-payment",
  mixins: [validationMixin, SistemaMixin, ValidacaoMixin],
  directives: {
    mask,
    "number-only": {
      bind(el) {
        function checkValue(event) {
          event.target.value = event.target.value.replace(/[^0-9]/g, "");
          if (event.charCode >= 48 && event.charCode <= 57) {
            return true;
          }
          event.preventDefault();
        }
        el.addEventListener("keypress", checkValue);
      },
    },
    "letter-only": {
      bind(el) {
        function checkValue(event) {
          if (event.charCode >= 48 && event.charCode <= 57) {
            event.preventDefault();
          }
          return true;
        }
        el.addEventListener("keypress", checkValue);
      },
    },
  },

  data() {
    return {
      consultaCepFire: false,
      selectedCity: null,
      finishedPayment: false,
      fields: {
        cardNumber: "v-card-number",
        cardName: "v-card-name",
        cardMonth: "v-card-month",
        cardYear: "v-card-year",
        cardCvv: "v-card-cvv",
      },
      uuid: null,
      formIdentificacao: {
        nome: null,
        email: null,
        cpf_cnpj: null,
        telefone: null,
        celular: null,
      },
      isCardNumberMasked: true,
      formEndereco: {
        logradouro: null,
        bairro: null,
        cidade: null,
        estado: null,
        complemento: null,
        numero: null,
        cep: null,
      },
      cidades: [],
      formCartao: {
        cardName: "",
        cardNumber: "",
        cardMonth: "",
        cardYear: "",
        cardCvv: "",
        cardVezes: "",
      },
      assinatura: {
        status: null,
        data_validade: null,
        data_pagamento: null,
        motivo_bloqueio: null,
        valor: null,
        id_plan: null,
        plano: null,
      },
      id_empresa: null,
      parcelas: [],
      isSale: false,
      essencialMonthly: "3",
      essencialYearly: "2",
      minCardYear: new Date().getFullYear(),
    };
  },
  components: {
    FormWizard,
    TabContent,
    Card,
    // VueSelect,
    // CardForm,
  },
  watch: {},
  computed: {
    ...mapGetters(["pageTitle", "layoutConfig"]),
  },
  methods: {
    ...mapActions(["setLoading"]),
    async getInit() {},
    generateMonthValue(n) {
      return n < 10 ? `0${n}` : n;
    },
    onChangeCidadeSelected() {
      this.formEndereco.cidade = this.selectedCity.cidade;
      this.formEndereco.estado = this.selectedCity.uf;
    },
    validateStep(step) {
      if (step === 1) {
        if (
          !!this.formIdentificacao.nome &&
          this.formIdentificacao.nome !== "" &&
          !!this.formIdentificacao.email &&
          this.formIdentificacao.email !== "" &&
          !!this.formIdentificacao.cpf_cnpj &&
          this.formIdentificacao.cpf_cnpj !== "" &&
          !!this.formIdentificacao.celular &&
          this.formIdentificacao.celular !== ""
        )
          return true;
        else {
          this.$toast.error("Preencha todos os campos!");
          return false;
        }
      }
      if (step === 2) {
        if (
          !!this.formEndereco.cep &&
          this.formEndereco.cep !== "" &&
          !!this.formEndereco.logradouro &&
          this.formEndereco.logradouro !== "" &&
          !!this.formEndereco.numero &&
          this.formEndereco.numero !== "" &&
          !!this.formEndereco.bairro &&
          this.formEndereco.bairro !== "" &&
          !!this.formEndereco.cidade &&
          this.formEndereco.cidade !== "" &&
          !!this.formEndereco.estado &&
          this.formEndereco.estado !== ""
        )
          return true;
        else {
          this.$toast.error("Preencha todos os campos!");
          return false;
        }
      }
      if (step === 3) {
        if (
          !!this.formCartao.cardCvv &&
          this.formCartao.cardCvv !== "" &&
          !!this.formCartao.cardMonth &&
          this.formCartao.cardMonth !== "" &&
          !!this.formCartao.cardYear &&
          this.formCartao.cardYear !== "" &&
          !!this.formCartao.cardName &&
          this.formCartao.cardName !== "" &&
          !!this.formCartao.cardNumber &&
          this.formCartao.cardNumber !== "" &&
          !!this.formCartao.cardVezes &&
          this.formCartao.cardVezes !== ""
        )
          return true;
        else {
          this.$toast.error("Preencha todos os campos!");
          return false;
        }
      }
    },
    async handleSendPayment() {
      this.setLoading(true);
      const dataToSendToPayment = {
        uuid: this.uuid,
        id_empresa: this.id_empresa,
        ...this.formIdentificacao,
        name: this.formIdentificacao.nome,
        ...this.formEndereco,
        uf: this.formEndereco.estado,
        numero_cartao: this.formCartao.cardNumberNotMask,
        nome_cartao: this.formCartao.cardName,
        ano: this.formCartao.cardYear,
        mes: this.formCartao.cardMonth,
        cvv: this.formCartao.cardCvv,
        vezes: this.formCartao.cardVezes,
      };
      try {
        await this.$http.post("/link/pagar-link", dataToSendToPayment);
        this.setLoading(false);
        this.finishedPayment = true;
        this.$swal
          .fire({
            icon: "success",
            title: "Obrigado por realizar a compra com a Nuts!",
            confirmButtonText: `Fechar`,
          })
          .then(async (result) => {
            if (result.isConfirmed) {
              // this.$router.push({ name: "comprasLista" });
            }
          });
      } catch (error) {
        this.setLoading(false);
        console.log(JSON.stringify(error));
      }
    },
    onSearch(search, loading) {
      if (search.length) {
        loading(true);
        this.search(loading, search, this);
      }
    },
    search: loadsh.debounce(async (loading, search, vm) => {
      const city = await vm.getCidade(search);
      vm.cidades = city;
      loading(false);
    }, 350),
    async consultaCep() {
      this.setLoading(true);
      const cep = await this.getCep(this.formEndereco.cep);
      this.setLoading(false);
      cep.length === 0
        ? (this.consultaCepFire = false)
        : (this.consultaCepFire = true);
      this.formEndereco.logradouro = cep.logradouro;
      this.formEndereco.bairro = cep.bairro;
      const city = await this.getCidadeByUF(cep.localidade, cep.uf);
      this.cidades = city;
    },
    changeName(e) {
      this.formCartao.cardName = e.target.value;
      this.$emit("input-card-name", this.formCartao.cardName);
    },
    changeNumber(e) {
      this.formCartao.cardNumber = e.target.value;
      let value = this.formCartao.cardNumber.replace(/\D/g, "");
      // american express, 15 digits
      if (/^3[47]\d{0,13}$/.test(value)) {
        this.formCartao.cardNumber = value
          .replace(/(\d{4})/, "$1 ")
          .replace(/(\d{4}) (\d{6})/, "$1 $2 ");
        this.cardNumberMaxLength = 17;
      } else if (/^3(?:0[0-5]|[68]\d)\d{0,11}$/.test(value)) {
        // diner's club, 14 digits
        this.formCartao.cardNumber = value
          .replace(/(\d{4})/, "$1 ")
          .replace(/(\d{4}) (\d{6})/, "$1 $2 ");
        this.cardNumberMaxLength = 16;
      } else if (/^\d{0,16}$/.test(value)) {
        // regular cc number, 16 digits
        this.formCartao.cardNumber = value
          .replace(/(\d{4})/, "$1 ")
          .replace(/(\d{4}) (\d{4})/, "$1 $2 ")
          .replace(/(\d{4}) (\d{4}) (\d{4})/, "$1 $2 $3 ");
        this.cardNumberMaxLength = 19;
      }
      // eslint-disable-next-line eqeqeq
      if (e.inputType == "deleteContentBackward") {
        let lastChar = this.formCartao.cardNumber.substring(
          this.formCartao.cardNumber.length,
          this.formCartao.cardNumber.length - 1
        );
        // eslint-disable-next-line eqeqeq
        if (lastChar == " ") {
          this.formCartao.cardNumber = this.formCartao.cardNumber.substring(
            0,
            this.formCartao.cardNumber.length - 1
          );
        }
      }
      this.$emit("input-card-number", this.formCartao.cardNumber);
    },
    changeMonth() {
      this.$emit("input-card-month", this.formCartao.cardMonth);
    },
    changeYear() {
      this.$emit("input-card-year", this.formCartao.cardYear);
    },
    changeCvv(e) {
      this.formCartao.cardCvv = e.target.value;
      this.$emit("input-card-cvv", this.formCartao.cardCvv);
    },
    blurCardNumber() {
      if (this.isCardNumberMasked) {
        this.maskCardNumber();
      }
    },
    maskCardNumber() {
      this.formCartao.cardNumberNotMask = this.formCartao.cardNumber;
      this.mainCardNumber = this.formCartao.cardNumber;
      let arr = this.formCartao.cardNumber.split("");
      arr.forEach((element, index) => {
        if (index > 4 && index < 14 && element.trim() !== "") {
          arr[index] = "*";
        }
      });
      this.formCartao.cardNumber = arr.join("");
    },
    unMaskCardNumber() {
      this.formCartao.cardNumber = this.mainCardNumber;
    },
    focusCardNumber() {
      this.unMaskCardNumber();
    },
    toggleMask() {
      this.isCardNumberMasked = !this.isCardNumberMasked;
      if (this.isCardNumberMasked) {
        this.maskCardNumber();
      } else {
        this.unMaskCardNumber();
      }
    },
    // eslint-disable-next-line no-unused-vars
    updateCardNumber(val) {},
    // eslint-disable-next-line no-unused-vars
    updateCardName(val) {},
    // eslint-disable-next-line no-unused-vars
    updateCardMonth(val) {},
    // eslint-disable-next-line no-unused-vars
    updateCardYear(val) {},
    // eslint-disable-next-line no-unused-vars
    updateCardCvv(val) {},
  },
  mounted() {
    this.getInit();
  },
  created() {
    const { uuid } = this.$route.params;
    this.setLoading(true);
    this.$http
      .post("/link/busca-link", {
        uuid,
      })
      .then((response) => {
        this.parcelas = response.data.vezes;
        this.uuid = uuid;
        this.id_empresa = response.data.id_empresa;
        this.setLoading(false);
      })
      .catch((error) => {
        this.setLoading(false);
        this.$toast.error(`Erro ao buscar pagamento:\n${error}`);
        this.$router.push({ name: 404 });
      });
  },
};
</script>
<style scoped>
svg {
  outline-width: 0;
  outline-color: transparent;
  color: rgb(241, 249, 255);
}

.container {
  background-color: white;
  margin-top: 20px;
  border-radius: 10px;
  box-shadow: 0px 0px 30px 0px rgba(82, 63, 105, 0.05);
}

.select-city .vs__search::placeholder,
.select-city .vs__dropdown-toggle,
.select-city .vs__dropdown-menu {
  height: 10rem !important;
  /* width:  100%; */
  background-color: green !important;
}
</style>