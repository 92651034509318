<template>
  <button
    class="btn input-margin"
    :class="buttonClass"
    :disabled="disabled"
    @click="click"
    :type="typeButton"
  >
    <span class="container-button">
      <slot name="icon" :size="18" />
      <slot />
    </span>
  </button>
</template>

<script>
export default {
  name: "Button",
  props: {
    type: {
      type: String,
      validator: () => [
        "default",
        "primary",
        "success",
        "warning",
        "error",
        "clean"
      ],
      default: () => "default"
    },
    outline: {
      type: Boolean,
      default: () => false
    },
    rounded: {
      type: Boolean,
      default: () => false
    },
    size: {
      type: String,
      validator: () => ["", "small"],
      default: () => ""
    },
    align: {
      type: String,
      validator: () => ["left", "center"],
      default: () => "left"
    },
    upperCase: {
      type: Boolean,
      default: () => true
    },
    disabled: {
      type: Boolean,
      default: () => false
    },
    fullWidth: {
      type: Boolean,
      default: () => false
    },
    submit: {
      type: Boolean,
      default: () => false
    },
    icon: {}
  },
  data() {
    return {
      isIcon: false
    };
  },
  methods: {
    click() {
      this.$emit("click");
    }
  },
  computed: {
    typeButton() {
      return this.submit ? "submit" : "button";
    },
    buttonClass() {
      const classType = {
        default: "btn-default",
        primary: "btn-primary",
        success: "btn btn-primary font-weight-bold",
        error: "btn-error",
        warning: "btn-warning",
        clean: "btn btn-light-primary font-weight-bold"
      };
      return {
        [classType[this.type]]: true,
        upper: this.upperCase,
        [this.size]: true,
        "btn-outline": this.outline,
        "btn-rounded": this.rounded,
        "btn-full-width": this.fullWidth,
        "btn-center": this.align === "center"
      };
    }
  }
};
</script>
