const ID_TOKEN_KEY = "id_token";
const TOKEN_CLIENTE = "token_cliente";
const PARCEIRO = "parceiro";

export const getToken = () => {
  return window.localStorage.getItem(ID_TOKEN_KEY);
};

export const saveToken = token => {
  window.localStorage.setItem(ID_TOKEN_KEY, token);
};

export const saveParceiro = parceiro => {
  window.localStorage.setItem(PARCEIRO, parceiro);
};

export const destroyToken = () => {
  window.localStorage.removeItem(ID_TOKEN_KEY);
};

export const getTokenCliente = () => {
  return window.localStorage.getItem(TOKEN_CLIENTE);
};

export const saveTokenCliente = token => {
  window.localStorage.setItem(TOKEN_CLIENTE, token);
};

export const destroyTokenCliente = () => {
  window.localStorage.removeItem(TOKEN_CLIENTE);
};

export const destroyParceiro = () => {
  window.localStorage.removeItem(PARCEIRO);
};

export const getParceiro = () => {
  return window.localStorage.getItem(PARCEIRO);
};


export default {
  getToken,
  saveToken,
  destroyToken,
  getTokenCliente,
  saveTokenCliente,
  destroyTokenCliente,
  saveParceiro,
  destroyParceiro,
  getParceiro,
};
