import { required } from "vuelidate/lib/validators";

export default class IntermediadorData {
  nome = null;
  usuario = null;
  intermediario = null;
  indicador = null;
  constructor(
    nome = null,
    usuario = null,
    intermediario = null,
    indicador = {
      id: 0,
      name: 'Operação sem intermediador'
    },
  ) {
    this.nome = nome;
    this.usuario = usuario;
    this.intermediario = intermediario;
    this.indicador = indicador;
  }
}

export const IntermediadorDataRequired = {
  nome: {
    required
  },
  usuario: {
    required
  },
  intermediario: {
    required
  },
  indicador: {
    required
  },
};
