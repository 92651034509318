<template>
  <b-tab title="Dados Gerais" :active="isTheCurrentTab" :disabled="!isTheCurrentTab" class="mt-5">
    <b-row>
      <b-col md="8">
        <b-form-group id="motorista" label-for="motorista" label="Motorista">
          <VueSelect ref="buscarMotorista" :filterable="false" :options="motoristas" v-model="motorista"
            :class="{ hasErrors: motoristaError }" @search="onSearchMotoristas" @input="() => motoristaError = false">
            <template slot="no-options">
              Informe o nome do motorista
            </template>
            <template slot="option" slot-scope="option">
              <div class="d-center">
                {{ option.nome }}
              </div>
            </template>
            <template slot="selected-option" slot-scope="option">
              <div class="selected d-center">
                {{ option.nome }}
              </div>
            </template>
          </VueSelect>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="8">
        <b-form-group id="tipo_emissor1" label-for="tipo_emissor1" label="Tipo Emissor">
          <VueSelect ref="selectTipoEmissor" :filterable="false" :searchable="false" :options="emissores"
            v-model="form.tipo_emissor" :class="{ hasErrors: emissorError }" @input="() => emissorError = false">
            <template slot="no-options">
              Carregando
            </template>
            <template slot="option" slot-scope="option">
              <div class="d-center">
                {{ option.descricao }}
              </div>
            </template>
            <template slot="selected-option" slot-scope="option">
              <div class="selected d-center">
                {{ option.descricao }}
              </div>
            </template>
          </VueSelect>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="4">
        <b-form-group id="data_jornada" label-for="data_jornada" label="Data da Jornada">
          <b-form-datepicker v-model="form.data_jornada" :state="validateState('data_jornada')" locale="pt-br"
            :date-format-options="{
              year: 'numeric',
              month: 'numeric',
              day: 'numeric',
            }">
          </b-form-datepicker>
        </b-form-group>
      </b-col>
      <b-col md="4">
        <b-form-group id="hora_jornada" label-for="hora_jornada" label="Hora Jornada">
          <b-form-timepicker v-model="form.hora_jornada" locale="pt-br" :state="validateState('hora_jornada')" />
        </b-form-group>
      </b-col>
    </b-row>
  </b-tab>
</template>

<script>
import { mapGetters, mapState, mapActions } from "vuex";
import { validationMixin } from "vuelidate";
import { SistemaMixin } from "@/modules/Application";
import { store } from "../../storeLogistica";
import VueSelect from "vue-select";
import _ from "lodash";

export default {
  name: "app-mdfe-form-dadosGeraisTab",
  components: {
    VueSelect
  },
  directives: {
  },
  data() {
    return {
      store,
      motorista: null,
      form: {
        tipo_emissor: null,
        data_jornada: null,
        hora_jornada: null,
        serie: null,
        modelo: null,
      },
      motoristaError: false,
      emissorError: false,
      emissores: [],
      motoristas: [],
    }
  },
  mixins: [
    validationMixin,
    SistemaMixin,
  ],
  methods: {
    ...mapActions(["setLoading"]),
    async getDataInit() {
      this.form.data_jornada = this.thisDate();
      this.form.hora_jornada = this.thisTime();
      const mdfeData = this.$route.params?.mdfeData?.mdfe;
      const [{ data: configData }, {data: emissorData}] = await Promise.all([
        this.$http.post('/configuracoes/current-config', { modelo: 58 }),
        this.$http.post('/mdfe/get-emissor-tipo'),
      ])
      this.form.serie = configData[0]?.serie;
      this.form.modelo = 58;
      this.emissores = emissorData;
      if (mdfeData) {
        this.motorista = mdfeData.motorista;
        this.form.tipo_emissor = this.emissores.find(emissor => emissor.id === mdfeData.tipo_emissor);
        this.form.data_jornada = mdfeData.data_jornada;
        this.form.hora_jornada = mdfeData.hora_jornada;
      }
      this.store.currentTab = 0;
    },
    onSearchMotoristas(search, loading) {
      if (search.length) {
        loading(true);
        this.searchMotoristas(loading, search, this);
      }
    },
    searchMotoristas: _.debounce(async (loading, search, vm) => {
      const { data } = await vm.$http.post('/motoristas/get-motorista', { search });
      vm.motoristas = data;
      if (loading) loading(false);
    }, 350),
    validateState(field) {
      return !!this.form[field];
    }
  },
  created() {
    this.getDataInit();
  },
  computed: {
    ...mapGetters([
      "isAuthenticated",
      "breadcrumbs",
      "pageTitle",
      "layoutConfig"
    ]),
    ...mapState(["loading"]),
    isLoading() {
      return this.loading.loading;
    },
    isTheCurrentTab() {
      return store.currentTab === 0
    }
  },
  watch: {
    "store.currentTab"(newValue, oldValue) {
      if (newValue !== oldValue && newValue === 1 && oldValue < newValue) {
        if (!this.motorista) {
          this.$toast.error('Motorista Obrigatorio!')
          this.motoristaError = true;
          this.store.currentTab = oldValue;
        }
        if (!this.form.tipo_emissor) {
          this.$toast.error('Tipo emissor obvrigatório!')
          this.emissorError = true;
          this.store.currentTab = oldValue;
        }
        if (!this.form.hora_jornada) {
          this.$toast.error('Hora de jornada obrigatória!')
          this.store.currentTab = oldValue;
        }
        if (!this.form.data_jornada) {
          this.$toast.error('Data de jornada obrigatória!')
          this.store.currentTab = oldValue;
        }
      }
      this.store.formData = {
        ...this.store.formData,
        ...this.form,
        motorista: this.motorista,
        tipo_emissor: this.form.tipo_emissor.id,
      }
    },
  }
}

</script>

<style></style>