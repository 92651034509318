import usuariosLista from "@/modules/Usuarios/pages/Index";
import usuariosNovo from "@/modules/Usuarios/pages/Create";
import usuariosEditar from "@/modules/Usuarios/pages/Update";

export default [
  {
    name: "usuariosLista",
    path: "/usuarios",
    component: usuariosLista,
    meta: { title: "Usuários" },
  },
  {
    name: "usuariosNovo",
    path: "/usuarios/novo",
    component: usuariosNovo,
    meta: { title: "Usuários" },
  },
  {
    name: "usuariosEditar",
    path: "/usuarios/:id/editar",
    component: usuariosEditar,
    meta: { title: "Usuários" },
  },
];
