<template>
  <div>
    <div class="card card-custom gutter-b" style="box-shadow: none">
      <div class="card-header card-header-tabs-line">
        <ul
          class="nav nav-dark nav-bold nav-tabs nav-tabs-line"
          role="tablist"
          ref="builder-tab"
        >
          <li class="nav-item">
            <a
              class="nav-link active"
              data-tab="0"
              data-toggle="tab"
              href="#"
              role="tab"
            >
              Cadastro
            </a>
          </li>
        </ul>
      </div>

      <b-form class="form" @submit.stop.prevent="onSubmit">
        <div class="card-body">
          <b-tabs class="hide-tabs" v-model="tabIndex">
            <b-tab active>
              <b-row>
                <b-col md="8">
                  <b-row>
                    <b-col>
                      <b-form-group
                        id="descricao"
                        label-for="descricao"
                        label="Descrição"
                      >
                        <b-form-input
                          v-model="$v.form.descricao.$model"
                          :state="validateState('descricao')"
                          maxlength="50"
                          autofocus
                        >
                        </b-form-input>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-col>
                <b-col>
                  <b-form-group id="status" label-for="status" label="Ativo">
                    <span class="switch switch-icon">
                      <label>
                        <input
                          type="checkbox"
                          :value="true"
                          @change="setAtivo"
                          :checked="form.status == true"
                        />
                        <span></span>
                      </label>
                    </span>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-tab>
          </b-tabs>
        </div>

        <div class="card-footer">
          <div class="row">
            <div class="col-lg-12" style="text-align: center">
              <button
                type="submit"
                ref="submit_novo_identificador"
                class="btn btn-primary font-weight-bold"
              >
                Salvar
              </button>
              <router-link
                class="btn btn-clean font-weight-bold"
                :to="{
                  name: 'identificadoresLista',
                }"
              >
                Cancelar
              </router-link>
            </div>
          </div>
        </div>
      </b-form>
    </div>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import IdentificadorData, { IdentificadorDataRequired } from "./IdentificadorData";
import { mapGetters } from "vuex";

export default {
  name: "app-identificador-form",
  props: {
    url: {
      type: String,
    },
    identificador: {
      type: Object,
      default: () => {},
    },
  },
  mixins: [validationMixin],
  data() {
    return {
      tabIndex: 0,
      form: {},
    };
  },
  validations: {
    form: IdentificadorDataRequired,
  },
  watch: {
    identificador(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.form = newValue;
      }
    },
  },
  computed: {
    ...mapGetters(["itsblocked"]),
  },
  mounted() {
    if (this.itsblocked) {
      this.$router.push({ name: "assinatura" });
    }
    this.getDataInit();
  },
  methods: {
    async getDataInit() {
      this.form = await new IdentificadorData({});
    },
    setAtivo() {
      this.form.status = this.form.status == true ? false : true;
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    async onSubmit() {
      const submitButton = this.$refs["submit_novo_identificador"];

      try {
        this.$v.form.$touch();

        if (this.$v.form.$anyError) {
          this.$toast.error(
            "Verifique todos os campos obrigatórios do formulário!"
          );
          return;
        }

        submitButton.classList.add("spinner", "spinner-light", "spinner-right");
        submitButton.setAttribute("disabled", "");

        await this.$http.post(this.url, this.form);

        this.$router.push({ name: "identificadoresLista" });
      } catch (error) {
        submitButton.classList.remove(
          "spinner",
          "spinner-light",
          "spinner-right"
        );
        submitButton.removeAttribute("disabled", "");
      }
    },
  },
};
</script>

<style>
.vs__dropdown-toggle {
  border: 2px solid #dedede;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border-radius: 6px;
  outline: none;
  height: 38px;
}
</style>
