<template>
  <div class="card card-custom card-stretch gutter-b">
    <div class="card-header border-0 py-5">
      <h3 class="card-title align-items-start flex-column">
        <span class="card-label font-weight-bolder text-dark">
          Editar Parcelamento
        </span>
      </h3>
    </div>
    <Form url="/financeiro/update-forma-pagamento" :parcelamento="parcelamento" />
  </div>
</template>

<script>
import Form from "./Form.vue";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "app-parcelamento-update",
  data() {
    return {
      parcelamento: {},
    };
  },
  components: {
    Form,
  },
  methods: {
    ...mapActions(["setLoading"]),
    async getParcelamento(id) {
      try {
        const response = await this.$http.post("/financeiro/get-forma-pagamento", {
          id: id,
        });
        const data = (response.data);
        return {
          ...data,
        };
      } catch (error) {
        return null;
      }
    },
    async getInit() {
      try {
        const id = this.$route.params.id_parcelamento;

        this.setLoading(true);

        const parcelamento = await this.getParcelamento(id);

        if (!parcelamento.id || parcelamento == null) {
          this.$router.push({ name: "produtosLista" });
          return this.$toast.error("Produto não encontrado!");
        }

        this.parcelamento = parcelamento;
        this.setLoading(false);
      } catch {
        return;
      }
    },
  },
  computed: {
    ...mapGetters(["itsblocked"]),
  },
  mounted() {
    if (this.itsblocked) {
      this.$router.push({ name: "assinatura" });
    }
    this.getInit();
  },
};
</script>
