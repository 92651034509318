<template>
  <div class="termos-css">
    <h1 style="text-align: center">Dúvidas sobre</h1>
    <h6 style="text-align: center; margin-bottom: 50px">
      Multa, juros e descontos
    </h6>
    <p>
      A definição das taxas de multa e juros é responsabilidade do emitente do
      boleto e não do banco. Por isso, é importante que você se mantenha
      atualizado sobre os percentuais permitidos. Também existe a possibilidade
      de oferecer descontos quando o pagamento for antecipado.
    </p>

    <p><strong>Multa</strong></p>

    <p>
      Cobrando multa De acordo com o
      <a
        href="http://www.planalto.gov.br/ccivil_03/leis/L8078.htm"
        target="_blank"
        >artigo 52, parágrafo primeiro do Código de Defesa do Consumidor, Lei
        8.078/90</a
      >, a cobrança da multa não pode ser maior que 2%. Na prática, imagine que
      você tem um boleto de R$ 100,00 e a multa em caso de atraso é de 2%. Se o
      seu cliente atrasar ele vai pagar os R$ 100,00 do boleto + R$ 2,00 de
      multa + o valor do juros que vamos falar a seguir, caso seja aplicado.
      Aplicando multa O modo de cobrança de multa pode ser percentual ou fixa, e
      ter início em uma data superior ao vencimento do documento.
    </p>

    <p><strong>Juros</strong></p>
    <p>
      Diferente da multa, juros são um tipo de cobrança que leva em conta o
      tempo de atraso do boleto bancário. Por exemplo, se o boleto está atrasado
      há um mês, os juros serão mais altos do que o boleto que está atrasado há
      apenas um dia. Os juros também são conhecidos como juros de mora. No
      passado também eram conhecidos pelo termo juros moratórios, mas este termo
      caiu em desuso.
    </p>
    <p>
      Aplicando juros Segundo o
      <a
        href="https://presrepublica.jusbrasil.com.br/legislacao/91577/codigo-civil-lei-10406-02#art-406"
        target="_blank"
        >art. 406 do Código Civil</a
      >
      e o
      <a
        href="https://presrepublica.jusbrasil.com.br/legislacao/91647/codigo-tributario-nacional-lei-5172-66#par-1--art-161"
        target="_blanck"
        >,artigo 161, parágrafo primeiro, do Código Tributário Nacional</a
      >, os juros de mora devem ser cobrados a, no máximo, 1% ao mês. Essa
      porcentagem deve ser cobrada proporcionalmente aos dias de atraso do
      boleto bancário baseada no mês comercial que tem 30 dias. Então divide-se
      1% por 30 dias e chega ao resultado de 0,033% que é o percentual máximo
      por dia que pode ser cobrado de juros. Logo, é só multiplicar 0,033% pela
      quantidade de dias em atraso. Na prática, imagine aquele mesmo boleto de
      R$ 100,00, mas ele foi pago com 10 dias de atraso.0,033% ao dia x 10 dias
      de atraso = 0,33%. 100,00 x 0,33% = 0,33. Valor que deve ser cobrado de
      juros = R$ 0,33. O valor final do boleto será de R$ 100,00 da compra + R$
      2,00 da multa + R$ 0,33 de juros de mora. Totalizando R$ 102,33.
    </p>

    <p><strong>Descontos</strong></p>
    <p>
      Descontos Quando falamos de boletos também há a possibilidade de oferecer
      descontos para pagamentos antecipados. Por exemplo caso o cliente pague o
      boleto até uma data específica descontos podem ser aplicados, esses
      descontos podem ser valores fixos ou valores percentuais. Na prática,
      imagine um boleto de R$ 100,00 da compra com o vencimento para 30/12/2035.
      Podemos oferecer vários descontos em determinadas datas. Exemplo de
      desconto: caso o pagamento ocorra até dia 20/12/2035 podemos oferecer 10%
      de desconto. 100,00 - 10% = 90 Valor que deve ser oferecido de desconto =
      R$ 10,00. Podemos oferecer até 3 regras de descontos por boleto. Veja
      abaixo como enviar os parâmetros necessários na chamada de API. Aplicando
      descontos O modo de aplicação de desconto pode ser percentual ou fixo.
      Pode haver múltiplos descontos em um boleto, podendo ser criado modelos de
      descontos progressivos.
    </p>
  </div>
</template>

<script>
export default {
  name: "app-config-boletos-quest",
  data() {
    return {};
  },
};
</script>

<style>
.text-left {
  margin-left: 36pt;
}
.termos-css {
  margin-left: 36pt;
  font-size: 14px;
}
</style>
