<template>
  <div style="position: relative">
    <FilterFields :fields="[
      {
        type: 'text',
        label: 'Nome',
        field: 'nome',
      },
      {
        type: 'text',
        label: 'CPF',
        field: 'cpf',
      },
    ]" />
    <TableApp titulo="Motoristas" acaoBtn="/#/motoristas/novo-motorista" textoBtn="Novo motorista"
      api-url="/motoristas/lista" :fields="fields" classFilterColumn="nome" ref="motoristaList" :perPage="10">
      <template slot="customRow" scope="props">
        <span slot="nome" class="pl-0 pt-8" v-if="props.field == 'nome'">
          <div class="d-flex align-items-center">
            <div class="font-weight-bold text-muted">
              {{ props.rowData.nome }}
            </div>
          </div>
        </span>

        <span slot="valor" class="pl-0 pt-8" v-if="props.field == 'cpf'">
          <div class="d-flex align-items-center">
            <div class="font-weight-bold text-muted">
              {{ formatarCampo(props.rowData.cpf) }}
            </div>
          </div>
        </span>

        <span slot="actions" v-if="props.field == 'actions'">
          <div class="row-actions" style="text-align: right !important">
            <span style="overflow: visible; ">
              <div class="action-buttons">
                <button @click="handleEditMotorista(props.rowData)" v-b-tooltip.bottom="'Editar motorista'"
                  class="btn btn-sm btn-info btn-hover-info btn-icon mr-2">
                  <Pencil :size="16" />
                </button>
                <button @click="handleDeleteMotorista(props.rowData)" v-b-tooltip.bottom="'Excluir motorista'"
                  class="btn btn-sm btn-danger btn-icon mr-2">
                  <TrashCanIcon :size="16" />
                </button>
              </div>
            </span>
          </div>
        </span>
      </template>
    </TableApp>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { SistemaMixin } from "@/modules/Application";
import TableApp from "@/components/Table/Table2";
import Pencil from "vue-material-design-icons/Pencil.vue";
import TrashCanIcon from "vue-material-design-icons/TrashCan.vue";
import swal from "sweetalert";
import FilterFields from "@/components/FilterFields";

export default {
  name: "motoristas",
  mixins: [SistemaMixin],
  components: {
    TableApp,
    Pencil,
    TrashCanIcon,
    FilterFields,
  },
  data() {
    return {
      fields: [
        {
          label: "Nome",
          field: "nome",
          type: "text",
          slot: true,
          width: '60%',
          sortable: false,
        },
        {
          label: "Cpf",
          field: "cpf",
          slot: true,
          sortable: false,
        },
        {
          label: "",
          field: "actions",
          sortable: false,
          width: "5%",
          slot: true,
        },
      ],
    };
  },
  created() {

  },
  methods: {
    handleEditMotorista(data) {
      swal({
        title: "Deseja editar o motorista?",
        text: "Você ira ser direcionado para a pagina de edição aguarde...",
        icon: "info",
        buttons: {
          cancel: {
            text: "Cancelar",
            value: "cancel",
            visible: true,
            closeModal: true,
          },
          confirm: {
            text: "OK",
            visible: true,
            closeModal: false,
          },
        },
        dangerMode: false,
        closeModal: false,
        closeOnClickOutside: false,
      }).then(async (value) => {
        if (value === "cancel") {
          swal.close();
          swal.stopLoading();
          return;
        }

        await this.$http
          .post("/motoristas/find", {
            id_motorista: data.id_motorista,
          })
          .then((response) => {
            swal.stopLoading();
            swal.close();
            this.$toast.info("Você será redirecionado agora");
            this.$router.push({
              name: "novoMotoristas",
              params: { motoristaData: { ...response.data, id_motorista: data.id_motorista } },
            });
          })
          .catch(() => {
            swal.stopLoading();
            this.$toast.error("Erro ao solicitar o endpoint do motorista");
            swal.close();
          });
      });
    },
    handleDeleteMotorista(data) {
      this.getRequestAdmin(() => {
        this.$swal
          .fire({
            icon: "warning",
            title: "Excluir o motorista?",
            showCancelButton: true,
            confirmButtonText: `Sim`,
            cancelButtonText: `Não`,
            confirmButtonColor: "#f64e60",
          })
          .then(async (result) => {
            if (result.isConfirmed) {
              await this.$http.post("/motoristas/delete", {
                id_motorista: data.id_motorista,
              });
              this.$refs.motoristaList.refresh();
              this.$toast.success("Motorista excluído!");
            }
          });
      })
    },
  },
  computed: {
    ...mapGetters(["layoutConfig", "itsblocked"]),
    config() {
      return this.layoutConfig();
    },
  },
  async mounted() { },
};
</script>

<style></style>
