export default {
  methods: {
    divide(number, divisor, decimals) {
      return Number(
        (Math.floor((number / divisor) * 100) / 100).toFixed(decimals || 2)
      );
    },

    subtract(number, subtracting, decimals) {
      return Number((number - subtracting).toFixed(decimals || 2));
    },

    multiply(number, multiple, decimals) {
      return Number((number * multiple).toFixed(decimals || 2));
    },

    roundMultiply(number, multiple, decimals) {
      return Number((number * multiple).round(decimals).toFixed(decimals || 2));
    },

    round(number, decimals) {
      const factor = 10 ** (decimals || 2);

      /* Multiplies the number by the factor then round it, if the decimal is >= 5
       * it will be rounded to up, then split it by the factor again.
       * Getting a correct rounded number.
       */
      return Math.round(number * factor) / factor;
    },

    add(number, sum, decimals) {
      return Number((number + sum).toFixed(decimals || 2));
    },

    toNumber(number) {
      let newValue = number;
      if (!number.length) {
        return 0;
      }

      if (newValue.indexOf(",") >= 0) {
        newValue = newValue.replace(/\./g, "").replace(/,/g, ".");
      }

      if (!parseFloat(newValue).isNaN) {
        return parseFloat(newValue);
      }

      return 0;
    }
  }
};
