<template>
  <label class="checkbox-container" :class="checkboxClass">
    <input
      type="checkbox"
      @change="updateValue"
      :checked="value"
      :disabled="disabled"
    />
    <span class="container-align" :class="disabled ? 'disabled' : ''">
      <span class="checkmark" :class="value ? 'checked' : ''">
        <CheckIcon :size="14" fillColor="white"/>
      </span>
      <span class="text">{{ label }}</span>
    </span>
  </label>
</template>

<script>
import CheckIcon from "vue-material-design-icons/CheckBold";

export default {
  name: "CheckBox",
  components: {
    CheckIcon
  },
  props: {
    label: {
      type: String
    },
    value: {
      required: true
    },
    disabled: {
      type: Boolean,
      default: () => false
    },
    border: {
      type: String,
      default: () => "default",
      validator: value => ["default", "white"].indexOf(value) > -1
    }
  },
  methods: {
    updateValue() {
      this.$emit("input", !this.value);
    }
  },
  computed: {
    checkboxClass() {
      return {
        "border-white": this.border === "white"
      };
    }
  }
};
</script>
