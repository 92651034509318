<template>
  <div style="position: relative">
    <FilterFields :fields="[
      {
        type: 'text',
        label: 'Número',
        field: 'numero',
      },
      {
        type: 'date',
        label: 'Data de Jornada',
        field: 'data_jornada',
      },
      {
        type: 'date',
        label: 'Data de Emissão',
        field: 'data_emissao',
      },
      {
        type: 'text',
        label: 'Motorista',
        field: 'motorista',
      },
      {
        type: 'text',
        label: 'Veículo',
        field: 'veiculo',
      },
      {
        type: 'select',
        label: 'Status',
        field: 'status',
        options: [
          'Todos',
          'Autorizado',
          'Cancelado',
          'Editando',
          'Rejeitado',
          'Encerrado',
          'Em processamento',
        ],
      },
    ]" />
    <TableApp titulo="MDFe" acaoBtn="/#/mdfe/nova-mdfe" textoBtn="Nova MDFe" api-url="/mdfe/lista" :fields="fields"
      classFilterColumn="numero" ref="mdfeList" :perPage="10">
      <template slot="customRow" scope="props">
        <span slot="numero" class="pl-0 pt-8" v-if="props.field == 'numero'">
          <div class="d-flex align-items-center">
            <div class="font-weight-bold text-muted">
              {{ props.rowData.numero }}
            </div>
          </div>
        </span>
        <span slot="status" v-if="props.field == 'status'" :class="statusMdfeClass(props.rowData.status)">
          {{ statusMdfeLabel(props.rowData.status) }}
        </span>
        <span slot="valor" class="pl-0 pt-8" v-if="props.field == 'data_jornada'">
          <div class="d-flex align-items-center justify-content-end font-weight-bold text-muted">
            {{ props.rowData.data_jornada | moment("DD/MM/YYYY") }}
          </div>
        </span>
        <span slot="valor" class="pl-0 pt-8" v-if="props.field == 'data_emissao'">
          <div class="d-flex align-items-center justify-content-end font-weight-bold text-muted">
            {{ props.rowData.data_emissao | moment("DD/MM/YYYY") }}
          </div>
        </span>
        <span slot="valor" class="pl-0 pt-8" v-if="props.field == 'motorista'">
          <div class="d-flex align-items-center">
            <div class="font-weight-bold text-muted">
              {{ lerJsonMotorista(props.rowData.dados) }}
            </div>
          </div>
        </span>
        <span slot="valor" class="pl-0 pt-8" v-if="props.field == 'veiculo'">
          <div class="d-flex align-items-center">
            <div class="font-weight-bold text-muted">
              {{ lerJsonVeiculo(props.rowData.dados) }}
            </div>
          </div>
        </span>

        <span slot="actions" v-if="props.field == 'actions'">
          <div class="row-actions" style="text-align: right !important">
            <span style="overflow: visible">
              <div class="action-buttons">
                <button @click="handleEditMdfe(props.rowData)" v-if="props.rowData.status === 1 || props.rowData.status === 6
                  " v-b-tooltip.bottom="'Editar MDFe'" class="btn btn-sm btn-info btn-hover-info btn-icon mr-2">
                  <Pencil :size="16" />
                </button>
                <button @click="cancelMdfe(props.rowData)" v-if="props.rowData.status === 2"
                  v-b-tooltip.bottom="'Cancelar MDFe'" class="btn btn-sm btn-danger btn-icon mr-2">
                  <Close :size="20" />
                </button>
                <button @click="consultarMdfe(props.rowData)" v-b-tooltip.bottom="'Consultar MDFe'" v-if="props.rowData.status === 2 ||
                  props.rowData.status === 4 ||
                  props.rowData.status === 3 ||
                  props.rowData.status === 6 
                  " class="btn btn-sm btn-warning btn-icon mr-2">
                  <Search :size="20" />
                </button>
                <button @click="imprimirPDF(props.rowData)" v-if="props.rowData.status === 2 || props.rowData.status === 4
                  " v-b-tooltip.bottom="'Gerar Pdf'" class="btn btn-sm btn-info btn-hover-info btn-icon mr-2">
                  <PdfIcon :size="22" />
                </button>
                <button v-show="props.rowData.status == 1" @click="transmitir(props.rowData)"
                  class="btn btn-sm btn-info btn-icon mr-2" v-b-tooltip.bottom="'Transmitir MDFe'">
                  <TransmitIcon :size="16" />
                </button>
                <button v-show="props.rowData.status == 2" v-b-modal.encerrarMdfe
                  @click="selectMdfe(props.rowData.id_mdfe)" class="btn btn-sm btn-success btn-icon mr-2"
                  v-b-tooltip.bottom="'Encerrar MDFe'">
                  <PackageCheck :size="16" />
                </button>
                <button @click="gerarlXml(props.rowData)" v-if="props.rowData.arquivo_xml !== null"
                  v-b-tooltip.bottom="'Gerar XML'" class="btn btn-sm btn-info btn-hover-info btn-icon mr-2">
                  <XmlIcon :size="22" />
                </button>
              </div>
            </span>
          </div>
        </span>
      </template>
    </TableApp>

    <b-modal id="encerrarMdfe" title="Encerrar MDFe" centered>
      <b-form class="form">
        <b-row align-h="center">
          <b-col md="4">
            <b-form-group id="uf_encerramento" label-for="uf_encerramento" label="UF de encerramento">
              <VueSelect ref="uf_encerramento" :filterable="false" @search="searchUf" :options="ufsSearched"
                v-model="ufSelected">
                <template slot="no-options"> Informe a UF </template>
                <template slot="option" slot-scope="option">
                  <div class="d-center">
                    {{ option.sigla }}
                  </div>
                </template>
                <template slot="selected-option" slot-scope="option">
                  <div class="selected d-center">
                    {{ option.sigla }}
                  </div>
                </template>
              </VueSelect>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group id="cidadeEncerramento" label-for="cidadeEncerramento" label="Cidade de encerramento">
              <VueSelect ref="cidadeEncerramento" @search="onSearchCidades" :filterable="false" :options="cidades"
                v-model="cidadeEncerramento">
                <template slot="no-options">
                  Informe o nome da cidade
                </template>
                <template slot="option" slot-scope="option">
                  <div class="d-center">
                    {{ option.cidade }}
                  </div>
                </template>
                <template slot="selected-option" slot-scope="option">
                  <div class="selected d-center">
                    {{ option.cidade }}
                  </div>
                </template>
              </VueSelect>
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>
      <template #modal-footer="{ ok, cancel }">
        <b-button variant="primary" @click="encerrarMdfe" ref="encerrar_mdfe">
          Encerrar
        </b-button>
        <b-button @click="() => {
            cancel();
            this.selectMdfe = null;
          }
          ">
          Cancelar
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { SistemaMixin } from "@/modules/Application";
import TableApp from "@/components/Table/Table2";
import Pencil from "vue-material-design-icons/Pencil.vue";
import swal from "sweetalert";
import FilterFields from "@/components/FilterFields";
import Close from "vue-material-design-icons/FileCancel.vue";
import Search from "vue-material-design-icons/Magnify.vue";
import TransmitIcon from "vue-material-design-icons/SendCheckOutline";
import PackageCheck from "vue-material-design-icons/CubeSend";
import PdfIcon from "vue-material-design-icons/FilePdfBox.vue";
import XmlIcon from "vue-material-design-icons/Download.vue";
import _ from "lodash";
import VueSelect from "vue-select";

export default {
  name: "mdfe-lista",
  mixins: [SistemaMixin],
  components: {
    TableApp,
    Pencil,
    FilterFields,
    Close,
    Search,
    TransmitIcon,
    PackageCheck,
    VueSelect,
    PdfIcon,
    XmlIcon,
  },
  data() {
    return {
      ufs: [],
      ufsSearched: [],
      cidades: [],
      ufSelected: null,
      selectedMdfe: null,
      cidadeEncerramento: null,
      fields: [
        {
          label: "Número",
          field: "numero",
          type: "text",
          slot: true,
          width: "10%",
          sortable: false,
        },
        {
          label: "Status",
          field: "status",
          sortable: true,
          slot: true,
        },
        {
          label: "Data de Jornada",
          field: "data_jornada",
          sortable: false,
          slot: true,
          type: "date",
          dateInputFormat: "yyyy-MM-dd HH:mm:ss",
          dateOutputFormat: "dd-MM-yyyy",
        },
        {
          label: "Data de Emissão",
          field: "data_emissao",
          sortable: false,
          slot: true,
          type: "date",
          dateInputFormat: "yyyy-MM-dd HH:mm:ss",
          dateOutputFormat: "dd-MM-yyyy",
        },
        {
          label: "Motorista",
          field: "motorista",
          type: "text",
          slot: true,
          width: "15%",
          sortable: false,
        },
        {
          label: "Veículo",
          field: "veiculo",
          type: "text",
          slot: true,
          width: "15%",
          sortable: false,
        },
        {
          label: "Ações",
          field: "actions",
          sortable: false,
          width: "8%",
          slot: true,
        },
      ],
    };
  },
  async created() {
    const [{ data: ufsData }] = await Promise.all([
      this.$http.post("/sistema/get-uf"),
    ]);
    this.ufs = ufsData;
    this.ufsSearched = ufsData;
  },
  methods: {
    handleEditMdfe(data) {
      swal({
        title: "Deseja editar o MDFe?",
        text: "Você ira ser direcionado para a pagina de edição aguarde...",
        icon: "info",
        buttons: {
          cancel: {
            text: "Cancelar",
            value: "cancel",
            visible: true,
            closeModal: true,
          },
          confirm: {
            text: "OK",
            visible: true,
            closeModal: false,
          },
        },
        dangerMode: false,
        closeModal: false,
        closeOnClickOutside: false,
      }).then(async (value) => {
        if (value === "cancel") {
          swal.close();
          swal.stopLoading();
          return;
        }

        await this.$http
          .post("/mdfe/get-by-id", {
            id_mdfe: data.id_mdfe,
          })
          .then((response) => {
            swal.stopLoading();
            swal.close();
            this.$toast.info("Você será redirecionado agora");
            this.$router.push({
              name: "novaMdfe",
              params: { mdfeData: { ...response.data, id_mdfe: data.id_mdfe } },
            });
          })
          .catch(() => {
            swal.stopLoading();
            this.$toast.error("Erro ao solicitar o endpoint do MDFe");
            swal.close();
          });
      });
    },
    transmitir(data) {
      swal({
        title: "Deseja realizar a transmissão do MDFe?",
        text: "Aguarde, pois será enviada o MDFe para transmissão!",
        icon: "info",
        buttons: {
          cancel: {
            text: "Cancelar",
            value: "cancel",
            visible: true,
            closeModal: true,
          },
          confirm: {
            text: "OK",
            visible: true,
            closeModal: false,
          },
        },
        dangerMode: false,
        closeModal: false,
        closeOnClickOutside: false,
      }).then(async (value) => {
        if (value === "cancel") {
          swal.close();
          swal.stopLoading();
          return;
        }
        await this.$http
          .post("/mdfe/transmissao", {
            id_mdfe: data.id_mdfe,
          })
          .then((result) => {
            if (result["data"]["mdfe-url"]) {
              window.open(result["data"]["mdfe-url"], "_blank");
            }
            swal.stopLoading();
            swal.close();
            this.$refs.vendas.refresh();
            this.$toast.success("MDFe transmitido com sucesso!");
          })
          .catch(() => {
            swal.stopLoading();
            swal.close();
          });
      });
    },
    consultarMdfe(data) {
      swal({
        title: "Deseja consultar o MDFe?",
        icon: "info",
        buttons: {
          cancel: {
            text: "Cancelar",
            value: "cancel",
            visible: true,
            closeModal: true,
          },
          confirm: {
            text: "Sim",
            visible: true,
            closeModal: false,
          },
        },
        dangerMode: false,
        closeModal: false,
        closeOnClickOutside: false,
      }).then(async (value) => {
        if (value === "cancel") {
          swal.close();
          swal.stopLoading();
          return;
        }

        const consulta = await this.$http.post("/mdfe/consultar", {
          id_mdfe: data.id_mdfe,
        });
        this.$toast.success(consulta.data.xMotivo);
        swal.stopLoading();
        swal.close();
      });
    },
    searchUf(search, ufNumber) {
      this.ufsSearched = this.ufs.filter((uf) =>
        uf.sigla.toLowerCase().includes(search.toLowerCase())
      );
    },
    onSearchCidades(search, loading) {
      if (!this.ufSelected) {
        this.$toast.error("Informe primeiro o UF");
        return;
      }
      if (search.length) {
        loading(true);
        this.searchCidades(loading, search, this);
      }
    },
    searchCidades: _.debounce(async (loading, cidade, vm) => {
      const { data } = await vm.$http.post("/sistema/get-cidade-by-uf", {
        uf: vm.ufSelected.sigla,
        cidade,
      });
      vm.cidades = data;
      if (loading) loading(false);
    }, 350),
    selectMdfe(id_mdfe) {
      console.log(id_mdfe);
      this.selectedMdfe = id_mdfe;
    },
    async encerrarMdfe() {
      this.addLoadingToButton("encerrar_mdfe");
      if (!this.cidadeEncerramento) {
        this.$toast.error("Selecione a cidade para encerrar a MDFe!");
        this.removeLoadingToButton("encerrar_mdfe");
        return;
      }
      try {
        await this.$http.post("/mdfe/encerrar", {
          id_mdfe: this.selectedMdfe,
          uf: this.ufSelected.sigla,
          id_cidade: this.cidadeEncerramento.id_cidade,
        });
      } catch (error) {
        console.log(error);
        this.removeLoadingToButton("encerrar_mdfe");
      }
      this.$bvModal.hide("encerrarMdfe");
      this.selectedMdfe = null;
      this.ufSelected.sigla = null;
      this.cidadeEncerramento.id_cidade = null;
      this.removeLoadingToButton("encerrar_mdfe");
      this.$toast.success("MDFe encerrado com sucesso!");
      this.$refs.mdfeList.refresh();
    },
    imprimirPDF(data) {
      swal({
        title: "Deseja gerar o pdf do MDFe?",
        text: "Aguarde, pois será gerado o pdf automaticamente!",
        icon: "info",
        buttons: {
          cancel: {
            text: "Cancelar",
            value: "cancel",
            visible: true,
            closeModal: true,
          },
          confirm: {
            text: "OK",
            visible: true,
            closeModal: false,
          },
        },
        dangerMode: false,
        closeModal: false,
        closeOnClickOutside: false,
      }).then(async (value) => {
        if (value === "cancel") {
          swal.close();
          swal.stopLoading();
          return;
        }
        await this.$http
          .post("/mdfe/imprimir", {
            id_mdfe: data.id_mdfe,
          })
          .then((result) => {
            const resultData = result["data"]["mdfe_url"];
            if (resultData) {
              window.open(resultData, "_blank");
            }
            swal.stopLoading();
            swal.close();
            this.$toast.success("PDF gerado com sucesso!");
          })
          .catch(() => {
            swal.stopLoading();
            swal.close();
          });
      });
    },
    cancelMdfe(data) {
      this.getRequestAdmin(() => {
        swal({
          title: "Deseja cancelar o MDFe?",
          text: "Informe a justificativa do cancelamento!",
          icon: "info",
          content: "input",
          buttons: {
            cancel: {
              text: "Cancelar",
              value: "cancel",
              visible: true,
              closeModal: true,
            },
            confirm: {
              text: "OK",
              visible: true,
              closeModal: false,
            },
          },
          dangerMode: false,
          closeModal: false,
          closeOnClickOutside: false,
        }).then(async (value) => {
          if (value === "cancel") {
            swal.close();
            swal.stopLoading();
            return;
          }

          if (value.length < 15) {
            swal.stopLoading();
            this.$toast.warning(
              "A justificativa tem que possuir no mínimo 15 caracteres"
            );
            return;
          }

          await this.$http
            .post("/mdfe/cancelar", {
              id_mdfe: data.id_mdfe,
              justificativa: value,
            })
            .then(() => {
              swal.stopLoading();
              swal.close();
              this.$toast.success("MDFe cancelado com sucesso!");
              this.$refs.mdfeList.refresh();
            })
            .catch(() => {
              swal.stopLoading();
              swal.close();
            });
        });
      })
    },
    handleDeleteMdfe(data) {
      this.$swal
        .fire({
          icon: "warning",
          title: "Excluir a MDFe?",
          showCancelButton: true,
          confirmButtonText: `Sim`,
          cancelButtonText: `Não`,
          confirmButtonColor: "#f64e60",
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            await this.$http.post("/mdfe/delete", {
              mdfes: [
                {
                  id_mdfe: data.id_mdfe,
                },
              ],
            });
            this.$refs.mdfeList.refresh();
            this.$toast.success("MDFe excluída!");
          }
        });
    },
    lerJsonMotorista(dados) {
      const json = JSON.parse(dados);
      return json.motorista.nome;
    },
    lerJsonVeiculo(dados) {
      const json = JSON.parse(dados);
      return json.veiculo.descricao + " - " + json.veiculo.placa;
    },
    gerarlXml(data) {
      swal({
        title: "Deseja realizar a visualização do xml?",
        text: "Aguarde, pois será gerado o xml automaticamente!",
        icon: "info",
        buttons: {
          cancel: {
            text: "Cancelar",
            value: "cancel",
            visible: true,
            closeModal: true,
          },
          confirm: {
            text: "OK",
            visible: true,
            closeModal: false,
          },
        },
        dangerMode: false,
        closeModal: false,
        closeOnClickOutside: false,
      }).then(async (value) => {
        if (value === "cancel") {
          swal.close();
          swal.stopLoading();
          return;
        }

        if (data.xml_cancelado) {
          window.open(
            process.env.VUE_APP_DIGITAL_BASE_URL + data.xml_cancelado,
            "_blank"
          );
          swal.close();
          swal.stopLoading();
          return;
        }
        window.open(
          process.env.VUE_APP_DIGITAL_BASE_URL + data.arquivo_xml,
          "_blank"
        );
        swal.close();
        swal.stopLoading();
        return;
      });
    },
  },
  computed: {
    ...mapGetters(["layoutConfig", "itsblocked"]),
    config() {
      return this.layoutConfig();
    },
  },
  async mounted() { },
};
</script>

<style></style>
