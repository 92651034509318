import { required } from "vuelidate/lib/validators";

export default class VeiculoData {
  placa = null;
  uf = null;
  descricao = null;
  renavam = null;
  tipo_rodado = null;
  tipo_caroceria = null;
  tara = null;
  tara_kg = null;
  capacidade_kg = null;
  capacidade_m3 = null;
  constructor(
    placa = null,
    uf = null,
    descricao = null,
    renavam = null,
    tipo_rodado = null,
    tipo_caroceria = null,
    tara = null,
    tara_kg = null,
    capacidade_kg = null,
    capacidade_m3 = null,
  ) {
    this.placa = placa;
    this.uf = uf;
    this.descricao = descricao;
    this.renavam = renavam;
    this.tipo_rodado = tipo_rodado;
    this.tipo_caroceria = tipo_caroceria;
    this.tara = tara;
    this.tara_kg = tara_kg;
    this.capacidade_kg = capacidade_kg;
    this.capacidade_m3 = capacidade_m3;
  }
}

export const VeiculoDataRequired = {
  placa: {
    required,
  },
  uf: {
    required,
  },
  descricao: {
    required,
  },
  renavam: {
    required,
  },
  tipo_rodado: {
    required,
  },
  tipo_caroceria: {
    required,
  },
  tara: {
    required,
  },
  tara_kg: {
    required,
  },
  capacidade_kg: {
    required,
  },
  capacidade_m3: {
    required,
  },
};
