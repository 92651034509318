<template>
  <div style="position: relative">
    <FilterFields :fields="[
      {
        type: 'text',
        label: 'Número',
        field: 'numero',
      },
      {
        type: 'text',
        label: 'Cliente',
        field: 'cliente',
      },
      {
        type: 'text',
        label: 'Usuário',
        field: 'usuario',
      },
      {
        type: 'date',
        label: 'Data Atendimento',
        field: 'dataAtendimento',
      },
      {
        type: 'select',
        label: 'Negócio',
        field: 'negocio',
        options: ['Prospecção', 'Venda', 'Agendamento'],
      },
    ]" />
    <TableApp titulo="Atendimento" textoBtn="Novo" api-url="/prospect/lista" :fields="fields"
      classFilterColumn="descricao" ref="prospects" :perPage="10">
      <template slot="customRow" scope="props">
        <span slot="numero" v-if="props.field == 'numero'">
          <span class="pl-0 pt-8">
            <div class="d-flex align-items-center">
              <div>
                <span class="text-dark-75font-weight-bold d-block">{{
                  props.rowData.numero
                }}</span>
              </div>
            </div>
          </span>
        </span>
        <span slot="cliente" v-if="props.field == 'cliente'">
          <span class="pl-0 pt-8">
            <div class="d-flex align-items-center">
              <div>
                <span class="font-weight-bold text-muted">{{
                  props.rowData.cliente
                }}</span>
              </div>
            </div>
          </span>
        </span>
        <span slot="usuario" v-if="props.field == 'usuario'">
          <span class="pl-0 pt-8">
            <div class="d-flex align-items-center">
              <div>
                <span class="font-weight-bold text-muted">{{
                  props.rowData.usuario
                }}</span>
              </div>
            </div>
          </span>
        </span>
        <span slot="dataAtendimento" v-if="props.field == 'dataAtendimento'">
          <span class="pl-0 pt-8">
            <div class="d-flex align-items-center">
              <div>
                <span class="font-weight-bold text-muted">
                  {{ props.rowData.dataAtendimento | moment("DD/MM/YYYY") }}
                </span>
              </div>
            </div>
          </span>
        </span>
        <span v-if="props.field == 'negocio'">
          <span v-if="props.rowData.negocio == 1"
            class="label label-lg label-inline label-light-warning">Prospecção</span>
          <span v-else-if="props.rowData.negocio == 2"
            class="label label-lg label-inline label-light-success">Venda</span>
          <span v-else class="label label-lg label-inline label-light-primary">Agendamento</span>
        </span>
        <span v-if="props.field == 'actions'">
          <div class="row-actions" style="text-align: right !important">
            <span style="overflow: visible; position: relative; width: 130px">
              <div class="dropdown dropdown-inline">
                <button v-b-modal.visualizarProspect class="btn btn-sm btn-warning btn-hover-warning btn-icon mr-2"
                  v-b-tooltip.hover.bottom="'Visualizar'" @click="selecionarAtendimento(props.rowData)">
                  <Eye :size="16" />
                </button>
                <button @click="excluir(props.rowData)" class="btn btn-sm btn-danger btn-hover-danger btn-icon mr-2"
                  v-b-tooltip.hover.bottom="'Excluir'">
                  <TrashCanIcon :size="16" />
                </button>
              </div>
            </span>
          </div>
        </span>
      </template>
    </TableApp>

    <b-modal id="visualizarProspect" title="Visualização do atendimento realizado" centered size="md">
      <div class="d-flex" v-if="atendimentoSelecionado">
        <b-row>
          <p>Número: {{ atendimentoSelecionado.numero }}</p>
        </b-row>
      </div>
      <div class="d-flex" v-if="atendimentoSelecionado">
        <b-row>
          <p>Cliente: {{ atendimentoSelecionado.cliente }}</p>
        </b-row>
      </div>
      <div class="d-flex" v-if="atendimentoSelecionado">
        <b-row>
          <p>Contato: {{ atendimentoSelecionado.contato }}</p>
        </b-row>
      </div>
      <div class="d-flex" v-if="atendimentoSelecionado">
        <b-row>
          <p>Resonsável: {{ atendimentoSelecionado.responsavel }}</p>
        </b-row>
      </div>
      <div class="d-flex" v-if="atendimentoSelecionado">
        <b-row>
          <p>OBs: {{ atendimentoSelecionado.observacao }}</p>
        </b-row>
      </div>
      <div v-if="urlAnexo1">
        <b-row>
          <strong><a :href="urlAnexo1" target="_blank"> Primeiro Anexo</a></strong>
        </b-row>
      </div>
      <div v-if="urlAnexo2">
        <b-row>
          <strong><a :href="urlAnexo2" target="_blank"> Segundo Anexo</a></strong>
        </b-row>
      </div>
      <div v-if="urlAnexo3">
        <b-row>
          <strong><a :href="urlAnexo3" target="_blank"> Terceiro Anexo</a></strong>
        </b-row>
      </div>
      <div>
        <b-row>
          <strong><a :href="urlGoogleMaps" target="_blank">
              Ver Localização no Google Maps</a></strong>
        </b-row>
      </div>

      <template #modal-footer="{ cancel }">
        <div>
          <b-button @click="
                        {
            cancel();
          }
            ">
            Cancelar
          </b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import TableApp from "@/components/Table/Table2";
import { SistemaMixin } from "@/modules/Application";
import FilterFields from "@/components/FilterFields";
import TrashCanIcon from "vue-material-design-icons/TrashCan.vue";
import Eye from "vue-material-design-icons/Eye.vue";

export default {
  name: "app-prospect",
  mixins: [SistemaMixin],
  components: {
    Eye,
    TrashCanIcon,
    TableApp,
    FilterFields,
  },
  data() {
    return {
      fields: [
        {
          label: "Número",
          field: "numero",
          type: "text",
          sortable: true,
          slot: true,
          width: "5%",
        },
        {
          label: "Cliente",
          field: "cliente",
          type: "text",
          sortable: true,
          slot: true,
          width: "25%",
        },
        {
          label: "Usuário",
          field: "usuario",
          type: "text",
          sortable: true,
          slot: true,
          width: "25%",
        },
        {
          label: "Dt. Atendimento",
          field: "dataAtendimento",
          type: "text",
          sortable: true,
          slot: true,
          width: "15%",
        },
        {
          label: "Negócio",
          field: "negocio",
          type: "text",
          sortable: false,
          slot: true,
          width: "20%",
        },
        {
          label: "Ações",
          field: "actions",
          sortable: false,
          slot: true,
          width: "15%",
        },
      ],
      atendimentoSelecionado: null,
      urlGoogleMaps: null,
      urlAnexo1: null,
      urlAnexo2: null,
      urlAnexo3: null,
    };
  },
  methods: {
    selecionarAtendimento(atendimento) {
      this.atendimentoSelecionado = atendimento;
      this.urlGoogleMaps =
        "https://www.google.com.br/maps/@" +
        atendimento.latitude +
        "," +
        atendimento.longitude;
      this.urlAnexo1 = atendimento.anexo_1;
      this.urlAnexo2 = atendimento.anexo_2;
      this.urlAnexo3 = atendimento.anexo_3;
    },
    excluir(data) {
      this.getRequestAdmin(() => {
        this.$swal
          .fire({
            icon: "warning",
            title: "Excluir o atendimento " + data.numero + "?",
            showCancelButton: true,
            confirmButtonText: `Sim`,
            cancelButtonText: `Não`,
            confirmButtonColor: "#f64e60",
          })
          .then(async (result) => {
            if (result.isConfirmed) {
              await this.$http.post("/prospect/delete", {
                id_prospect: data.id_prospect,
              });

              this.$refs.prospects.refresh();
              this.$toast.success("Atendimento excluído!");
            }
          });
      })
    },
  },
  computed: {
    ...mapGetters(["layoutConfig", "itsblocked", "currentUser"]),
    config() {
      return this.layoutConfig();
    },
  },
  mounted() {
    if (this.itsblocked) {
      this.$router.push({ name: "assinatura" });
    }
  },
};
</script>
