import { required } from "vuelidate/lib/validators";

export default class ConfiguracaoData {
  ambiente = "";
  id = null;
  modelo = null;
  serie = "";
  idTokenCSC = "";
  tokenCSC = "";
  proximaNota = "";
  proximaVenda = "";
  ativo = true;
  informacoesComplementares = "";
  tipo_transportador = null;
  rntrc = null;
  solicitarDadosCartao = false;
  contigenciaSVCAN= false;
  constructor({
    ambiente = "",
    id = null,
    modelo = null,
    serie = "",
    idTokenCSC = "",
    tokenCSC = "",
    proximaNota = "",
    proximaVenda = "",
    ativo = true,
    informacoesComplementares,
    tipo_transportador = null,
    rntrc = null,
    solicitarDadosCartao = false,
    contigenciaSVCAN = false,
  }) {
    this.ambiente = ambiente;
    this.id = id;
    this.modelo = modelo;
    this.serie = serie;
    this.idTokenCSC = idTokenCSC;
    this.tokenCSC = tokenCSC;
    this.proximaNota = proximaNota;
    this.proximaVenda = proximaVenda;
    this.ativo = ativo;
    this.informacoesComplementares = informacoesComplementares;
    this.tipo_transportador = tipo_transportador;
    this.rntrc = rntrc;
    this.solicitarDadosCartao = solicitarDadosCartao;
    this.contigenciaSVCAN = contigenciaSVCAN;
  }
}

export const ConfiguracaoDataRequired = {
  ambiente: {
    required,
  },
  serie: {
    required,
  },
  proximaNota: {
    required,
  },
};
