<template>
  <transition name="fade-modal">
    <div class="modal-container" v-if="value">
      <div class="panel" :class="classes">
        <div class="header">
          <Title v-if="title" :title="title" :titleAlign="titleAlign" />
        </div>
        <slot />
      </div>
    </div>
  </transition>
</template>

<script>
import Title from "../Title";

export default {
  name: "Modal",
  components: {
    Title
  },
  props: {
    size: {
      type: String,
      validator: () => ["default", "large", "small", "tiny"],
      default: () => "default"
    },
    title: {
      type: String
    },
    titleAlign: {
      type: String
    },
    value: {
      type: Boolean
    },
    close: {
      type: Boolean,
      default: () => true
    },
    onClose: {
      type: Function,
      required: false
    }
  },
  created() {
    const onEscape = e => {
      if (
        this.value &&
        e.keyCode === 27 &&
        e.target._prevClass !== "vs__search"
      ) {
        this.handleClose();
      }
    };

    document.addEventListener("keydown", onEscape);
    this.$once("hook:destroyed", () => {
      document.removeEventListener("keydown", onEscape);
    });
  },
  methods: {
    handleClose() {
      if (this.onClose) {
        this.onClose();
        return;
      }
      this.$emit("input", false);
    }
  },
  watch: {
    value(newValue, oldValue) {
      if (newValue !== oldValue) {
        document.querySelector("body").style.overflowY = newValue
          ? "hidden"
          : "auto";
      }
    }
  },
  computed: {
    classes() {
      const classes = [`modal-size-${this.size}`];
      return classes.join(" ");
    }
  }
};
</script>
