import pessoaLista from "@/modules/Pessoa/pages/Index";
import pessoaNova from "@/modules/Pessoa/pages/Create";
import pessoaEditar from "@/modules/Pessoa/pages/Update";
import relatoriosCadastros from "@/modules/Pessoa/pages/Relatorios";

export default [
  {
    name: "pessoasLista",
    path: "/pessoas",
    component: pessoaLista,
    meta: { title: "Pessoas" },
  },
  {
    name: "pessoasNova",
    path: "/pessoas/nova",
    component: pessoaNova,
    meta: { title: "Pessoas" },
  },
  {
    name: "pessoasEditar",
    path: "/pessoas/:id_pessoa/editar",
    component: pessoaEditar,
    meta: { title: "Pessoas" },
  },
  {
    name: "relatoriosCadastros",
    path: "/relatoriosCadastros",
    component: relatoriosCadastros,
    meta: { title: "Pessoas" },
  },
];
