import { required } from "vuelidate/lib/validators";

export default class NaturezaData {
  descricao = "";

  id_empresa = 1;

  cfop = [];

  tributacao = [];

  movimentaEstoque = true;

  calculaIpi = false;

  retencaoPISCOFINSCSLL = false;

  informacoesComplementares = "";

  calculaTributo = true;
  constructor({
    descricao = "",
    cfop = [],
    tributacao = [],
    movimentaEstoque = true,
    calculaIpi = false,
    retencaoPISCOFINSCSLL = false,
    informacoesComplementares = "",
    calculaTributo = true,
  }) {
    this.descricao = descricao;
    this.cfop = cfop;
    this.tributacao = tributacao;
    this.movimentaEstoque = movimentaEstoque;
    this.calculaIpi = calculaIpi;
    this.retencaoPISCOFINSCSLL = retencaoPISCOFINSCSLL;
    this.informacoesComplementares = informacoesComplementares;
    this.calculaTributo = calculaTributo;
  }
}

export const NaturezaDataRequired = {
  descricao: {
    required,
  },
};
