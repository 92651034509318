<template>
  <div>
    <div class="card card-custom gutter-b" style="box-shadow: none">
      <div class="card-header card-header-tabs-line">
        <ul
          class="nav nav-dark nav-bold nav-tabs nav-tabs-line"
          role="tablist"
          ref="builder-tab"
        >
          <li class="nav-item">
            <a
              class="nav-link active"
              data-tab="0"
              data-toggle="tab"
              href="#"
              role="tab"
            >
              Cadastro
            </a>
          </li>
        </ul>
      </div>

      <b-form class="form" @submit.stop.prevent="onSubmit">
        <div class="card-body">
          <b-tabs class="hide-tabs" v-model="tabIndex">
            <b-tab active>
              <b-row>
                <b-col md="3">
                  <b-form-group
                    id="documento"
                    label-for="documento"
                    label="Documento "
                  >
                    <b-form-input
                      maxlength="30"
                      v-model="$v.form.documento.$model"
                      :state="validateState('documento')"
                      autofocus
                    >
                    </b-form-input>
                  </b-form-group>
                </b-col>
                <b-col md="6">
                  <b-form-group
                    id="historico"
                    label-for="historico"
                    label="Descrição"
                  >
                    <b-form-input
                      maxlength="50"
                      v-model="$v.form.historico.$model"
                      :state="validateState('historico')"
                    >
                    </b-form-input>
                  </b-form-group>
                </b-col>
                <b-col md="3">
                  <b-form-group id="banco" label-for="banco" label="Banco">
                    <b-form-select v-model="form.banco">
                      <b-form-select-option
                        v-for="b in bancos"
                        :key="b.id_banco + 'bancos'"
                        :value="b"
                      >
                        {{ b.nome }}
                      </b-form-select-option>
                    </b-form-select>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col>
                  <b-form-group label="Cliente" label-for="form.cliente">
                    <VueSelect
                      label="id_pessoa"
                      :filterable="false"
                      @input="setCliente"
                      v-model="cliente"
                      :options="clientes"
                      @search="onSearch"
                    >
                      <template slot="no-options">
                        Informe o nome do cliente
                      </template>
                      <template slot="option" slot-scope="option">
                        <div class="d-center">
                          {{ option.nome + " - " + option.cnpj_cpf }}
                        </div>
                      </template>
                      <template slot="selected-option" slot-scope="option">
                        <div class="selected d-center">
                          {{ option.nome + " - " + option.cnpj_cpf }}
                        </div>
                      </template>
                    </VueSelect>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group
                    id="id_plano_contas"
                    label-for="id_plano_contas"
                    label="Plano de Contas"
                  >
                    <VueSelect
                      label="id_plano_contas"
                      :filterable="false"
                      @input="setPlano"
                      v-model="plano"
                      :options="plano_contas"
                      @search="onSearchPlano"
                    >
                      <template slot="no-options">
                        Informe a descrição
                      </template>
                      <template slot="option" slot-scope="option">
                        <div class="d-center">
                          {{ option.descricao }}
                        </div>
                      </template>
                      <template slot="selected-option" slot-scope="option">
                        <div class="selected d-center">
                          {{ option.descricao }}
                        </div>
                      </template>
                    </VueSelect>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col md="4">
                  <b-form-group
                    label="Meio de pagamento"
                    label-for="form.meio_pagamento"
                  >
                    <VueSelect
                      label="id_pessoa"
                      :filterable="false"
                      v-model="meioPagamento"
                      :options="meiosDePagamento"
                      @search="onSearchMeioPagamento"
                    >
                      <template slot="no-options">
                        Informe o meio de pagamento
                      </template>
                      <template slot="option" slot-scope="option">
                        <div class="d-center">
                          {{ option.descricao }}
                        </div>
                      </template>
                      <template slot="selected-option" slot-scope="option">
                        <div class="selected d-center">
                          {{ option.descricao }}
                        </div>
                      </template>
                    </VueSelect>
                  </b-form-group>
                </b-col>
                <b-col md="4">
                  <b-form-group
                    id="data-vencimento"
                    label-for="data_vencimento"
                    label="Data Vencimento "
                  >
                    <b-form-input
                      id="data-vencimento"
                      placeholder=" "
                      locale="pt-br"
                      v-model="$v.form.data_vencimento.$model"
                      :state="validateState('data_vencimento')"
                      type="date"
                    >
                    </b-form-input>
                  </b-form-group>
                </b-col>
                <b-col md="3" v-if="form.recebido">
                  <b-form-group
                    id="data-recebimento"
                    label-for="data_recebimento"
                    label="Data Recebimento "
                  >
                    <b-form-input
                      id="data-recebimento"
                      placeholder=" "
                      locale="pt-br"
                      v-model="form.data_recebimento"
                      type="date"
                    >
                    </b-form-input>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col md="3">
                  <b-form-group id="valor" label-for="valor" label="Valor R$">
                    <money
                      class="form-control"
                      v-model="form.valor"
                      v-bind="configMoney"
                    >
                    </money>
                  </b-form-group>
                </b-col>
                <b-col md="2">
                  <b-form-group id="juros" label-for="juros" label="Juros R$">
                    <money
                      class="form-control"
                      v-model="form.juros"
                      v-bind="configMoney"
                    >
                    </money>
                  </b-form-group>
                </b-col>
                <b-col md="2">
                  <b-form-group id="multa" label-for="multa" label="Multa R$">
                    <money
                      class="form-control"
                      v-model="form.multa"
                      v-bind="configMoney"
                    >
                    </money>
                  </b-form-group>
                </b-col>
                <b-col md="2">
                  <b-form-group
                    id="desconto"
                    label-for="desconto"
                    label="Desconto R$"
                  >
                    <money
                      class="form-control"
                      v-model="form.desconto"
                      v-bind="configMoney"
                    >
                    </money>
                  </b-form-group>
                </b-col>
                <b-col md="3">
                  <b-form-group
                    id="valor_total"
                    label-for="valor_total"
                    label="Valor a Receber R$"
                  >
                    <money
                      disabled
                      class="form-control"
                      v-model="valor_total"
                      v-bind="configMoney"
                    >
                    </money>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row align-v="center" v-if="form.repetir && isNotEdit">
                <b-col md="2">
                  <b-form-group id="vezes" label-for="vezes" label="Parcelas">
                    <b-form-input
                      id="vezes"
                      placeholder=" "
                      locale="pt-br"
                      v-model.number="form.vezes"
                      type="number"
                    >
                    </b-form-input>
                  </b-form-group>
                </b-col>
                <b-col md="2">
                  <button
                    type="button"
                    ref="gerar_parcelas"
                    class="btn btn-primary font-weight-bold"
                    @click="gerarParcelas"
                  >
                    Gerar Parcelas
                  </button>
                </b-col>
              </b-row>
              <b-row v-if="form.repetir && isNotEdit">
                <b-col md="5">
                  <TableApp
                    item="pagamento"
                    subTitulo=""
                    :data="form.parcelas"
                    :fields="fields"
                    classFilterColumn="valor"
                    ref="valor"
                  >
                    <template slot="customRow" scope="props">
                      <span
                        slot="nome"
                        class="pl-0 pt-8"
                        v-if="props.field == 'id'"
                      >
                        <div class="d-flex align-items-center">
                          <div class="font-weight-bold text-muted">
                            {{ props.rowData.originalIndex + 1 }}
                          </div>
                        </div>
                      </span>
                      <span
                        slot="nome"
                        class="pl-0 pt-8"
                        v-if="props.field == 'valor'"
                      >
                        <div class="d-flex align-items-center">
                          <div
                            v-b-modal.modal-pagamento
                            @click="
                              changePagamento(props.rowData.originalIndex)
                            "
                            class="font-weight-bold text-muted cursor-pointer"
                          >
                            {{ numberToReal(props.rowData.valor, 2) }}
                          </div>
                        </div>
                      </span>
                      <span
                        slot="quantidade"
                        class="pl-0 pt-8"
                        v-if="props.field == 'vencimento'"
                      >
                        <div class="d-flex align-items-center">
                          <div
                            v-b-modal.modal-calendar
                            class="font-weight-bold text-muted cursor-pointer"
                            @click="
                              selectVencimento(props.rowData.originalIndex)
                            "
                          >
                            {{
                              props.rowData.data_vencimento
                                | moment("DD/MM/YYYY")
                            }}
                          </div>
                        </div>
                      </span>
                    </template>
                  </TableApp>
                </b-col>
              </b-row>
              <b-row>
                <b-col md="1">
                  <b-form-group id="status" label-for="status" label="Recebido">
                    <span class="switch switch-icon">
                      <label>
                        <input
                          type="checkbox"
                          :value="true"
                          @change="setAtivo"
                          :checked="form.recebido"
                        />
                        <span></span>
                      </label>
                    </span>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group
                    id="repetir"
                    label-for="repetir"
                    label="Repetir"
                    v-if="isNotEdit"
                  >
                    <span class="switch switch-icon">
                      <label>
                        <input
                          type="checkbox"
                          :value="true"
                          @change="setRepetir"
                          :checked="form.repetir"
                        />
                        <span></span>
                      </label>
                    </span>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-tab>
          </b-tabs>
        </div>

        <div class="card-footer">
          <div class="row">
            <div class="col-lg-12" style="text-align: center">
              <button
                type="submit"
                ref="submit_conta_novo"
                class="btn btn-primary font-weight-bold"
              >
                Salvar
              </button>
              <router-link
                class="btn btn-clean font-weight-bold"
                :to="{
                  name: 'contasReceber',
                }"
              >
                Cancelar
              </router-link>
            </div>
          </div>
        </div>
      </b-form>
    </div>

    <b-modal id="modal-calendar" title="Ajustar vencimento" size="sm" centered>
      <div class="mx-auto">
        <b-calendar v-model="vencimentoSelected" locale="pt-BR"></b-calendar>
      </div>
      <template #modal-footer="{ ok, cancel }">
        <b-button @click="cancel()"> Cancelar </b-button>
        <b-button
          variant="primary"
          @click="salvarVencimento"
          ref="salvarVencimento"
        >
          Salvar
        </b-button>
      </template>
    </b-modal>

    <b-modal id="modal-pagamento" title="Ajustar pagamento" size="sm" centered>
      <div class="mx-auto">
        <b-form class="form" @submit.stop.prevent="salvarPagamento">
          <money
            @input="changePagamentoEdited"
            class="form-control"
            id="valorPagamento"
            name="valorPagamento"
            :value="valorPagamento"
            v-bind="configMoney"
          >
          </money>
        </b-form>
      </div>
      <template #modal-footer="{ ok, cancel }">
        <b-button @click="cancel()"> Cancelar </b-button>
        <b-button
          variant="primary"
          @click="salvarPagamento"
          ref="transmitir_nfe"
        >
          Salvar
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { SistemaMixin } from "@/modules/Application";
import { PessoaMixin } from "@/modules/Application";
import ContaReceberData, { ContaReceberDataRequired } from "./ContaReceberData";
import { Money } from "v-money";
import { MONEY as settingMoney } from "@/modules/Application";
import VueSelect from "vue-select";
import loadsh from "loadsh";
import "vue-select/dist/vue-select.css";
import { mapGetters } from "vuex";
import TableApp from "@/components/Table/Table3.vue";

export default {
  name: "app-contaReceber-form",
  props: {
    url: {
      type: String,
    },
    conta: {
      type: Object,
      default: () => {},
    },
  },
  components: {
    Money,
    VueSelect,
    TableApp,
  },
  mixins: [validationMixin, SistemaMixin, PessoaMixin],
  watch: {
    conta(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.form = newValue;
        this.form.valor = this.numberToReal(newValue.valor, 2);
        this.form.valor_total = this.numberToReal(newValue.valor_total, 2);
        this.form.juros = this.numberToReal(newValue.juros, 2);
        this.form.multa = this.numberToReal(newValue.multa, 2);
      }
    },
  },
  data() {
    return {
      tabIndex: 0,
      configMoney: settingMoney,
      form: {},
      vencimentoSelected: null,
      plano_contas: [],
      cliente: null,
      plano: null,
      pagamentoEditado: 0,
      clientes: [],
      meiosDePagamento: [],
      meiosPagamentoFetched: [],
      selectedPagamento: null,
      indexVencimento: null,
      meioPagamento: null,
      bancos: [
        {
          id_banco: 99999,
          nome: "Movimento Caixa",
        },
      ],
      fields: [
        {
          label: "",
          field: "id",
          slot: true,
          width: "10%",
          sortable: false,
        },
        {
          label: "Valor R$",
          field: "valor",
          slot: true,
          width: "45%",
          sortable: false,
        },
        {
          label: "Vencimento",
          field: "vencimento",
          slot: true,
          sortable: false,
          width: "45%",
        },
      ],
    };
  },
  validations: {
    form: ContaReceberDataRequired,
  },
  computed: {
    ...mapGetters(["itsblocked"]),
    valorPagamento() {
      if (!this.form?.parcelas) return;
      if (this.form.parcelas.length === 0) return 0;
      else return this.form.parcelas[0].valor;
    },
    isNotEdit() {
      return JSON.stringify(this.$route.params) === "{}";
    },
    valor_total() {
      return (
        this.form.valor + this.form.juros + this.form.multa - this.form.desconto
      );
    },
  },
  created() {
    if (this.itsblocked) {
      this.$router.push({ name: "assinatura" });
    }
    this.getDataInit();
  },
  methods: {
    setAtivo() {
      this.form.recebido = this.form.recebido ? false : true;
    },
    setRepetir() {
      this.form.repetir = this.form.repetir ? false : true;
    },
    changePagamento(pagamentoIndex) {
      this.selectedPagamento = pagamentoIndex;
    },
    changePagamentoEdited(valor) {
      this.pagamentoEditado = valor;
    },
    salvarPagamento() {
      this.form.parcelas[this.selectedPagamento].valor = this.pagamentoEditado;
      this.$bvModal.hide("modal-pagamento");
    },
    selectVencimento(index) {
      this.indexVencimento = index;
    },
    salvarVencimento() {
      this.form.parcelas[this.indexVencimento].data_vencimento =
        this.vencimentoSelected;
      this.$bvModal.hide("modal-calendar");
    },
    gerarParcelas() {
      if (this.form.valor === 0) {
        this.$toast.error("Insira um valor para gerar parcelas!");
        return;
      }
      this.form.parcelas = this.defineParcelas(
        this.form.vezes,
        this.form.valor,
        this.form.data_vencimento
      );
    },
    setCliente(cliente) {
      this.cliente = cliente;
    },
    setPlano(plano) {
      this.plano = plano;
    },
    onSearchPlano(search, loading) {
      if (search.length) {
        loading(true);
        this.searchPlano(loading, search, this);
      }
    },
    searchPlano: loadsh.debounce(async (loading, search, vm) => {
      vm.plano_contas = await vm.getPlanoContasContaReceber(search);
      loading(false);
    }, 450),
    onSearch(search, loading) {
      if (search.length) {
        loading(true);
        this.search(loading, search, this);
      }
    },
    search: loadsh.debounce(async (loading, search, vm) => {
      vm.clientes = await vm.getPessoaNome(search);
      loading(false);
    }, 350),
    onSearchMeioPagamento(search) {
      this.meiosDePagamento = this.meiosPagamentoFetched.filter((pagamento) =>
        pagamento.descricao.toLowerCase().includes(search.toLowerCase())
      );
    },
    async getDataInit() {
      this.form = new ContaReceberData({});
      this.plano_contas = await this.getPlanoContasContaReceber();
      const bancos = await this.getBancosByEmpresa();

      const response = await this.$http.post("/financeiro/meios-pagamento");
      this.meiosDePagamento = response.data;
      this.meiosPagamentoFetched = this.meiosDePagamento;

      this.bancos.push(...bancos);

      if (this.conta.id) {
        this.form = this.conta;
        this.meioPagamento = this.conta.meio_pagamento;
        this.setCliente(this.conta.pessoa);
        this.setPlano(this.conta.plano_contas);
      }
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    async onSubmit() {
      const submitButton = this.$refs["submit_conta_novo"];

      try {
        this.$v.form.$touch();

        if (this.$v.form.$anyError) {
          this.$toast.error(
            "Verifique todos os campos obrigatórios do formulário!"
          );
          return;
        }

        submitButton.classList.add("spinner", "spinner-light", "spinner-right");
        submitButton.setAttribute("disabled", "");

        this.form.pessoa = this.cliente;
        this.form.id_plano_contas = this.plano?.id ?? null;

        if (this.valor_total) {
          this.form.valor_total = this.valor_total;
        }
        this.form.id_meio_pagamento = this.meioPagamento?.id ?? null;

        if (this.form.repetir) {
          if (!this.form.pessoa) {
            this.$toast.warning(
              "Para função de repetir parcelas é necessário informar cliente!"
            );
            submitButton.classList.remove(
              "spinner",
              "spinner-light",
              "spinner-right"
            );
            submitButton.removeAttribute("disabled", "");
            return;
          }
        }

        await this.$http.post(this.url, this.form);

        this.$router.push({ name: "contasReceber" });
      } catch (error) {
        submitButton.classList.remove(
          "spinner",
          "spinner-light",
          "spinner-right"
        );
        submitButton.removeAttribute("disabled", "");
      }
    },
  },
};
</script>

<style>
.vs__dropdown-toggle {
  border: 2px solid #dedede;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border-radius: 6px;
  outline: none;
  height: 38px;
}
</style>
