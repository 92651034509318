<template>
  <div class="card card-custom gutter-b" style="box-shadow: none">
    <div class="card-header card-header-tabs-line">
      <ul
        class="nav nav-dark nav-bold nav-tabs nav-tabs-line"
        role="tablist"
        ref="builder-tab"
      >
        <li class="nav-item">
          <a
            class="nav-link active"
            v-on:click="setActiveTab"
            data-tab="0"
            data-toggle="tab"
            href="#"
            role="tab"
          >
            Cadastro
          </a>
        </li>
      </ul>
    </div>

    <b-form class="form" @submit.stop.prevent="onSubmit">
      <div class="card-body">
        <b-tabs class="hide-tabs" v-model="tabIndex">
          <b-tab active>
            <b-row>
              <b-col md="6">
                <b-form-group
                  id="nome"
                  label-for="responsavel"
                  label="Nome/Razão Social"
                >
                  <b-form-input
                    v-model="$v.form.nome.$model"
                    :state="validateState('nome')"
                    autofocus
                  >
                  </b-form-input>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group
                  id="cnpj_cpf"
                  label="CPF/CNPJ"
                  label-for="cnpj_cpf"
                >
                  <b-form-input
                    id="cnpj_cpf"
                    name="cnpj_cpf"
                    v-model="$v.form.cnpj_cpf.$model"
                    v-mask="['###.###.###-##', '##.###.###/####-##']"
                    :state="validateState('cnpj_cpf')"
                  >
                  </b-form-input>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row v-show="form.cnpj_cpf">
              <b-col md="6" v-show="juridica">
                <b-form-group
                  id="nome_fantasia"
                  label="Nome Fantasia"
                  label-for="nome_fantasia"
                >
                  <b-form-input
                    id="nome_fantasia"
                    name="nome_fantasia"
                    v-model="form.nome_fantasia"
                    placeholder=""
                  >
                  </b-form-input>
                </b-form-group>
              </b-col>
              <b-col md="3" v-show="juridica">
                <template>
                  <b-form-group
                    id="inscricao_municipal"
                    label="Insc. Municípal"
                    label-for="inscricao_municipal"
                  >
                    <b-form-input
                      id="inscricao_municipal"
                      name="inscricao_municipal"
                      v-model="form.inscricao_municipal"
                      placeholder=""
                    >
                    </b-form-input>
                  </b-form-group>
                </template>
              </b-col>
              <b-col md="3">
                <b-form-group id="ie_rg" label="IE/RG" label-for="ie_rg">
                  <b-form-input
                    id="ie_rg"
                    name="ie_rg"
                    v-model="form.ie_rg"
                    :state="validateState('ie_rg')"
                  >
                  </b-form-input>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="4">
                <b-form-group
                  id="responsavel"
                  label="Responsável"
                  label-for="responsavel"
                >
                  <b-form-input
                    id="responsavel"
                    name="responsavel"
                    v-model="form.responsavel"
                    placeholder=""
                    :state="validateState('responsavel')"
                  >
                  </b-form-input>
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group
                  id="telefone"
                  label="Telefone"
                  label-for="example-input-0"
                >
                  <b-form-input
                    v-mask="['(##) #### ####', '(##) # #### ####']"
                    v-model="$v.form.fone.$model"
                    :state="validateState('fone')"
                    aria-describedby="input-0-live-feedback"
                  >
                  </b-form-input>
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group
                  id="email"
                  label="E-mail"
                  label-for="example-input-0"
                >
                  <b-form-input
                    v-model="$v.form.email.$model"
                    :state="validateState('email')"
                    aria-describedby="input-0-live-feedback"
                  >
                  </b-form-input>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="3">
                <b-form-group label="CEP" label-for="form.cep">
                  <b-form-input
                    ref="cep"
                    id="form.cep"
                    name="form.cep"
                    v-model="form.cep"
                    v-mask="['#####-###']"
                    @change="consultaCep"
                    aria-describedby="form.cep"
                    :state="validateState('cep')"
                  >
                  </b-form-input>
                </b-form-group>
              </b-col>
              <b-col md="2">
                <b-form-group label="Número" label-for="form.numero">
                  <b-form-input
                    id="form.numero"
                    name="form.numero"
                    v-model="form.numero"
                    aria-describedby="form.numero"
                    :state="validateState('numero')"
                  >
                  </b-form-input>
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group label="Bairro" label-for="form.bairro">
                  <b-form-input
                    id="form.bairro"
                    name="form.bairro"
                    v-model="form.bairro"
                    aria-describedby="form.bairro"
                    :state="validateState('bairro')"
                  >
                  </b-form-input>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-form-group label="Logradouro" label-for="form.logradouro">
                  <b-form-input
                    id="form.logradouro"
                    name="form.logradouro"
                    v-model="form.logradouro"
                    aria-describedby="form.logradouro"
                    :state="validateState('logradouro')"
                  >
                  </b-form-input>
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group label="Complemento" label-for="form.complemento">
                  <b-form-input
                    id="form.complemento"
                    name="form.complemento"
                    v-model="form.complemento"
                    aria-describedby="form.complemento"
                  >
                  </b-form-input>
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group label="Cidade" label-for="form.cidade">
                  <VueSelect
                    label="cidade"
                    @input="setCidade"
                    :options="cidades"
                    v-model="cidade"
                    @search="onSearch"
                  >
                    <template slot="no-options">
                      Informe o nome da cidade
                    </template>
                    <template slot="option" slot-scope="option">
                      <div class="d-center">
                        {{ option.cidade + " - " + option.uf }}
                      </div>
                    </template>
                    <template slot="selected-option" slot-scope="option">
                      <div class="selected d-center">
                        {{ option.cidade + " - " + option.uf }}
                      </div>
                    </template>
                  </VueSelect>
                </b-form-group>
              </b-col>
            </b-row>
          </b-tab>
        </b-tabs>
      </div>

      <div class="card-footer">
        <div class="row">
          <div class="col-lg-12" style="text-align: center">
            <button
              type="submit"
              ref="submit_emitente"
              class="btn btn-primary font-weight-bold"
            >
              Salvar
            </button>
            <router-link
              class="btn btn-clean font-weight-bold"
              :to="{
                name: 'revendasLista',
              }"
            >
              Cancelar
            </router-link>
          </div>
        </div>
      </div>
    </b-form>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { validationMixin } from "vuelidate";
import { mask } from "vue-the-mask";
import {
  SistemaMixin,
  ValidacaoMixin,
  EmitenteMixin,
} from "@/modules/Application";
import RevendaData, { RevendaDataRequired } from "./RevendaData";
import VueSelect from "vue-select";
import loadsh from "loadsh";
import "vue-select/dist/vue-select.css";
import { MONEY as settingMoney } from "@/modules/Application";

export default {
  name: "app-revenda-form",
  props: {
    url: {
      type: String,
    },
    conta: {
      type: Object,
      default: () => {},
    },
  },
  components: {
    VueSelect,
  },
  directives: {
    mask,
  },
  mixins: [validationMixin, SistemaMixin, ValidacaoMixin, EmitenteMixin],
  watch: {
    cliente(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.form = newValue;
        this.form.cnpj_cpf = this.formatCpfCnpj(newValue.cnpj_cpf);
      }
    },
    logo(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.logo = newValue;
      }
    },
  },
  data() {
    return {
      tabIndex: 0,
      form: {},
      cidades: [],
      file: null,
      certificado: null,
      logo: null,
      checkNewCeritificate: true,
      configMoney: settingMoney,
      logomarca: null,
      cidade: null,
      juridica: true,
      cliente: new RevendaData({}),
      urlLogo: null,
    };
  },
  validations: {
    form: RevendaDataRequired,
  },
  async mounted() {
    await this.getDataInit();

    if (!this.isNuts) {
      this.$router.push({ name: "dashboard" });
    }
  },
  methods: {
    ...mapActions(["setLoading"]),
    async getDataInit() {
      this.setLoading(true);
      const id = this.$route.params.id_empresa;
      const data = await this.getDadosRevenda(id);

      this.setLoading(false);

      if (!data.id_empresa || data == null) {
        return this.$toast.error("Cliente não encontrado!");
      }

      this.cliente = new RevendaData(data);
      this.logo = data.logo;
      this.cidade = data.cidade;

      if (data.cnpj_cpf && data.cnpj_cpf.length < 14) {
        this.juridica = false;
      }
      if (this.cliente.cidade) {
        this.setCidade(this.cliente.cidade);
      }
    },
    setCidade(cidade) {
      if (cidade.id_cidade) {
        this.form.id_cidade = cidade.id_cidade;
      }
    },
    onSearch(search, loading) {
      if (search.length) {
        loading(true);
        this.search(loading, search, this);
      }
    },
    search: loadsh.debounce(async (loading, search, vm) => {
      const city = await vm.getCidade(search);
      vm.cidades = city;
      loading(false);
    }, 350),
    async consultaCep() {
      const cep = await this.getCep(this.form.cep);

      if (cep.length == 0) {
        this.setLoading(false);
        return;
      }

      this.form.logradouro = cep.logradouro;
      this.form.bairro = cep.bairro;

      if (cep.erro) {
        this.setLoading(false);
        this.$toast.warning("Confira o CEP informado!");
        return;
      }

      const city = await this.getCidadeByUF(cep.localidade, cep.uf);

      this.cidades = city;
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    setActivePreviousTab() {
      this.tabIndex = parseInt(localStorage.getItem("builderTab")) || 0;
      const links = this.$refs["builder-tab"].querySelectorAll(".nav-link");

      for (let i = 0; i < links.length; i++) {
        links[i].classList.remove("active");
      }

      this.$refs["builder-tab"]
        .querySelector(`[data-tab="${this.tabIndex}"]`)
        .classList.add("active");
    },
    setActiveTab(event) {
      const tab = event.target.closest('[role="tablist"]');
      const links = tab.querySelectorAll(".nav-link");

      for (let i = 0; i < links.length; i++) {
        links[i].classList.remove("active");
      }

      this.tabIndex = parseInt(event.target.getAttribute("data-tab"));

      event.target.classList.add("active");
    },

    setImage(event) {
      this.certificado = event.target.files[0];
      this.checkNewCeritificate = false;
    },

    async setLogomarca(event) {
      this.logomarca = await event.target.files[0];
      this.urlLogo = URL.createObjectURL(this.logomarca);
    },
    async onSubmit() {
      const submitButton = this.$refs["submit_emitente"];

      try {
        this.$v.form.$touch();

        if (this.$v.form.$anyError) {
          this.$toast.error(
            "Verifique todos os campos obrigatórios do formulário!"
          );
          return;
        }

        if (!this.validaCpfCnpj(this.form.cnpj_cpf)) {
          return this.$toast.error("CNPJ/CPF informado é inválido!");
        }

        submitButton.classList.add("spinner", "spinner-light", "spinner-right");
        submitButton.setAttribute("disabled", "");

        let cliente = new FormData();

        if (this.form.complemento != null) {
          cliente.append("complemento", this.form.complemento);
        }

        if (this.form.inscrical_municipal != null) {
          cliente.append("inscricao_municipal", this.form.inscrical_municipal);
        }

        if (this.form.nome_fantasia != null) {
          cliente.append("nome_fantasia", this.form.nome_fantasia);
        }

        if (this.form.site != null) {
          cliente.append("site", this.form.site);
        }

        if (this.form.fone != null) {
          cliente.append("fone", this.form.fone);
        }

        cliente.append("id_empresa", this.cliente.id_empresa);
        cliente.append("nome", this.form.nome);
        cliente.append("cnpj_cpf", this.form.cnpj_cpf);
        cliente.append("ie_rg", this.form.ie_rg);
        cliente.append("responsavel", this.form.responsavel);
        cliente.append("cep", this.form.cep);
        cliente.append("logradouro", this.form.logradouro);
        cliente.append("numero", this.form.numero);
        cliente.append("bairro", this.form.bairro);
        cliente.append("fiscal", true);
        cliente.append("id_cidade", this.form.id_cidade);

        await this.$http.post("empresa/update-cliente", cliente);

        submitButton.classList.remove(
          "spinner",
          "spinner-light",
          "spinner-right"
        );

        this.$toast.success("Cliente atualizado com sucesso!");

        submitButton.removeAttribute("disabled", "");
        this.$router.push({ name: "revendasLista" });
      } catch (error) {
        submitButton.classList.remove(
          "spinner",
          "spinner-light",
          "spinner-right"
        );
        submitButton.removeAttribute("disabled", "");
      }
    },
  },
  computed: {
    ...mapGetters(["isNuts"]),
  },
};
</script>

<style>
.vs__dropdown-toggle {
  border: 2px solid #dedede;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border-radius: 6px;
  outline: none;
  height: 38px;
}
</style>
