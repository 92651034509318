import { required } from "vuelidate/lib/validators";

export default class InformacoesData {
  validade = null;
  status = null;
  observacao = '';
  pedido_compra = null;
  constructor(
    validade = null,
    status = null,
    observacao = '',
    pedido_compra = null
  ) {
    this.validade = validade;
    this.status = status;
    this.observacao = observacao;
    this.pedido_compra = pedido_compra;
  }
}

export const InformacoesDataRequired = {
  validade: {
    required,
  },
  status: {
    required,
  },
  observacao: {
    required,
  },
};
