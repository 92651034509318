<template>
  <div class="card-form container">
    <b-row class="mb-5 w-100 d-flex justify-content-around" :style="cardStyle">
      <img alt="Logo" :src="emissor.logo" class="max-h-80px" />
      <div class="mt-5" style="float: right; text-align: right">
        <span>{{ emissor.nome }} - {{ emissor.telefone }}</span> <br />
        <span v-if="emissor.cnpj_cpf.length < 14"
          >CPF: {{ formatCpfCnpj(emissor.cnpj_cpf) }}</span
        >
        <span v-if="emissor.cnpj_cpf.length >= 14"
          >CNPJ: {{ formatCpfCnpj(emissor.cnpj_cpf) }}</span
        >
        <br />
        <span>{{
          `${emissor.logradouro} - ${emissor.numero} - ${emissor.bairro}, ${emissor.cep}`
        }}</span
        ><br />
        <span>{{ `${emissor.cidade} - ${emissor.uf}` }}</span>
        <br />
      </div>
    </b-row>

    <div>
      <div>
        <b-form class="form" @submit.stop.prevent="onSubmit">
          <b-tabs class="mt-3">
            <b-tab title="Gerais" active>
              <b-card
                title="Dados do cliente"
                tag="article"
                class="my-2 border border-primary"
                :style="cardStyle"
              >
                <b-row>
                  <b-col md="12">
                    <VueSelect
                      placeholder="Informe o nome do cliente"
                      ref="buscarCliente"
                      label="id_pessoa"
                      :filterable="false"
                      @input="setCliente"
                      v-model="valueCliente"
                      :options="clientes"
                      @search="onSearchCliente"
                    >
                      <template slot="no-options">
                        Informe o nome do cliente
                      </template>
                      <template slot="option" slot-scope="option">
                        <div class="d-center">
                          {{ option.nome }}
                        </div>
                      </template>
                      <template slot="selected-option" slot-scope="option">
                        <div class="selected d-center">
                          {{ option.nome }}
                        </div>
                      </template>
                    </VueSelect>
                  </b-col> </b-row
                ><br />
                <b-row>
                  <b-col md="12">
                    <b-form-group
                      id="contato"
                      label-for="contato"
                      label="Contato"
                    >
                      <b-form-input v-model="form.contato"> </b-form-input>
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col md="12">
                    <b-form-group
                      id="responsavel"
                      label-for="responsavel"
                      label="Responsável"
                    >
                      <b-form-input v-model="form.responsavel"> </b-form-input>
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-card>
              <div>
                <b-card
                  title="Situação do atendimento"
                  tag="article"
                  class="my-2 border border-primary"
                  :style="cardStyle"
                >
                  <b-row>
                    <b-col md="12">
                      <b-form-group
                        label="Negócios"
                        label-for="negocioSelecinado"
                      >
                        <select
                          name="negocioSelecinado"
                          id="negocioSelecinado"
                          v-model="negocioSelecinado"
                          class="form-control"
                        >
                          <option
                            v-for="(n, index) in negocios"
                            :value="n.id"
                            :key="'id' + index"
                          >
                            {{ n.descricao }}
                          </option>
                        </select>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col md="12">
                      <b-form-group label="Observações" label-for="Observações">
                        <b-form-textarea
                          id="observacao"
                          name="observacao"
                          v-model="form.observacao"
                        >
                        </b-form-textarea>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-card>
              </div>
            </b-tab>
            <b-tab title="Anexos">
              <b-card
                title="Anexo"
                tag="article"
                class="my-2 border border-primary"
                :style="cardStyle"
              >
                <b-row>
                  <b-col md="6">
                    <b-form-group label="Anexo 1" label-for="anexo1">
                      <b-card-img
                        :src="anexo_1"
                        class="rounded-0"
                        v-if="anexo_1"
                      />
                      <b-form-file
                        accept="image/jpeg, image/png"
                        @change="(event) => setAnexo(event, 'anexo_1')"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col md="6">
                    <b-form-group label="Anexo 2" label-for="anexo2">
                      <b-card-img
                        :src="anexo_2"
                        class="rounded-0"
                        v-if="anexo_2"
                      />
                      <b-form-file
                        accept="image/jpeg, image/png"
                        @change="(event) => setAnexo(event, 'anexo_2')"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col md="6">
                    <b-form-group label="Anexo 3" label-for="anexo3">
                      <b-card-img
                        :src="anexo_3"
                        class="rounded-0"
                        v-if="anexo_3"
                      />
                      <b-form-file
                        accept="image/jpeg, image/png"
                        @change="(event) => setAnexo(event, 'anexo_3')"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-card>
            </b-tab>
          </b-tabs>
          <div class="mt-5 w-100 d-flex justify-content-center">
            <button
              type="submit"
              ref="submit_prospect_novo"
              class="btn btn-primary font-weight-bold"
            >
              Enviar
            </button>
          </div>
        </b-form>
      </div>
    </div>

    <div class="mt-5 w-100 d-flex justify-content-end">
      <img
        alt="Logo"
        :src="layoutConfig('self.logo.secundary')"
        class="max-h-30px mr-5"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import { validationMixin } from "vuelidate";
import {
  SistemaMixin,
  ValidacaoMixin,
  EmitenteMixin,
  PessoaMixin,
} from "@/modules/Application";
import { mask } from "vue-the-mask";
import VueSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import _ from "lodash";

export default {
  name: "app-link-prospect",
  mixins: [
    validationMixin,
    SistemaMixin,
    ValidacaoMixin,
    EmitenteMixin,
    PessoaMixin,
  ],
  directives: {
    mask,
  },
  components: {
    VueSelect,
  },
  data() {
    return {
      form: {},
      anexo_1: null,
      anexo_2: null,
      anexo_3: null,
      emissor: null,
      statusClient: null,
      observacao: null,
      windowWidth: window.innerWidth,
      clientes: [],
      valueCliente: null,
      statusSelecinado: null,
      negocioSelecinado: null,
      negocios: [
        {
          id: 1,
          descricao: "Prospecção",
        },
        {
          id: 2,
          descricao: "Venda",
        },
        {
          id: 3,
          descricao: "Agendamento",
        },
      ],
      status: [
        {
          id: 1,
          descricao: "Novo Cliente",
        },
        {
          id: 2,
          descricao: "Cliente Ativo",
        },
      ],
      localizacao: {},
    };
  },
  watch: {},
  computed: {
    ...mapGetters(["pageTitle", "layoutConfig"]),
    ...mapState({
      currentUser: (state) => state.auth.user,
    }),
    isMobile() {
      return this.windowWidth < 569;
    },
    cardStyle() {
      return {
        width: this.isMobile ? "94vw" : "",
      };
    },
  },
  methods: {
    ...mapActions(["setLoading"]),
    onResize() {
      this.windowWidth = window.innerWidth;
    },
    async getInit() {
      const data = await this.getDadosEmissor();
      if (data) {
        this.emissor = data;
      }
      this.getLocation();
    },
    async setAnexo(event, anexo) {
      const data = await event.target.files[0];
      const url = URL.createObjectURL(data);
      this[anexo] = url;
      this.form[anexo] = data;
    },
    onSearchCliente(search, loading) {
      if (search.length) {
        loading(true);
        this.searchClient(loading, search, this);
      }
    },
    searchClient: _.debounce(async (loading, search, vm) => {
      vm.clientes = await vm.getPessoaNome(search);
      loading(false);
    }, 350),
    async handleSendResponse() {
      this.addLoadingToButton("submit");
      if (!this.valueCliente) {
        this.$toast.error("Informe o status do orçamento!");
        this.removeLoadingToButton("submit");
        return;
      }
    },
    async onSubmit() {
      const submitButton = this.$refs["submit_prospect_novo"];

      if (!this.valueCliente) {
        this.$toast.error("Informe o cliente!");
        submitButton.removeAttribute("disabled", "");
        return;
      }

      if (!this.negocioSelecinado) {
        this.$toast.error("Selecione a forma de negócio!");
        submitButton.removeAttribute("disabled", "");
        return;
      }

      try {
        submitButton.classList.add("spinner", "spinner-light", "spinner-right");
        submitButton.setAttribute("disabled", "");

        let prospect = new FormData();
        prospect.append("contato", this.form.contato);
        prospect.append("responsavel", this.form.responsavel);
        prospect.append("observacao", this.form.observacao);
        prospect.append("status", 1);
        prospect.append("id_usuario", this.currentUser.id_usuario);

        if (this.valueCliente) {
          prospect.append("id_pessoa", this.valueCliente.id_pessoa);
        }

        if (this.negocioSelecinado) {
          prospect.append("negocio", this.negocioSelecinado);
        }

        if (this.localizacao) {
          prospect.append("latitude", this.localizacao.latitude);
          prospect.append("longitude", this.localizacao.longitude);
        }

        if (this.form.anexo_1) {
          prospect.append("anexo_1", this.form.anexo_1);
        }

        if (this.form.anexo_2) {
          prospect.append("anexo_2", this.form.anexo_2);
        }

        if (this.form.anexo_3) {
          prospect.append("anexo_3", this.form.anexo_3);
        }

        await this.$http.post("prospect/create", prospect);

        this.$toast.success("Atendimento gravado com sucesso!");
        submitButton.classList.remove(
          "spinner",
          "spinner-light",
          "spinner-right"
        );
        submitButton.removeAttribute("disabled", "");
        this.clearForm();
      } catch (error) {
        submitButton.classList.remove(
          "spinner",
          "spinner-light",
          "spinner-right"
        );
        submitButton.removeAttribute("disabled", "");
      }
    },
    setCliente(cliente) {
      this.valueCliente = cliente;
    },
    clearForm() {
      this.form = {};
      this.statusSelecinado = null;
      this.negocioSelecinado = null;
      this.valueCliente = null;
    },
    getLocation() {
      navigator.geolocation.getCurrentPosition(
        (success) => {
          this.localizacao.latitude = success.coords.latitude;
          this.localizacao.longitude = success.coords.longitude;
        },
        (failure) => {
          if (failure.message.startsWith("Only secure origins are allowed")) {
            this.$toast.error("Habilite a localização do seu navegador!");
          }
        }
      );
    },
  },
  async mounted() {
    this.setLoading(true);
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
    });
    await this.getInit();
    this.setLoading(false);
  },
  created() {},
};
</script>
<style scoped>
svg {
  outline-width: 0;
  outline-color: transparent;
  color: rgb(241, 249, 255);
}

.container {
  background-color: white;
  border-radius: 10px;
  padding: 1rem;
  box-shadow: 0px 0px 30px 0px rgba(82, 63, 105, 0.05);
}

.select-city .vs__search::placeholder,
.select-city .vs__dropdown-toggle,
.select-city .vs__dropdown-menu {
  height: 10rem !important;
  background-color: green !important;
}

.contato-input {
  height: 3rem;
}
</style>