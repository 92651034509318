<template>
  <div class="container-fluid">
    <div class="card-body">
      <b-card>
        <b-tabs pills :horizontal="windowWidth < 768" :vertical="windowWidth > 768"
          :nav-wrapper-class="(windowWidth > 768 ? 'w-25' : 'w-100')" fill>
          <Cliente />
          <Produto />
          <Faturamento />
          <Informacoes />
        </b-tabs>
      </b-card>
      <div class="card-footer">
        <div class="row">
          <div class="col-lg-12" style="text-align: center">
            <div v-if="canSaveOrcamento">
              <div class="form mb-5">
                <button v-if="store.currentTab !== 0" class="btn btn-secondary font-weight-bold mr-2" @click="handlePrevious">
                  Anterior
                </button>
                <button v-if="store.isUpdate && store.currentTab !== 3"  class="btn btn-primary font-weight-bold mr-4" @click="handleNext">
                  Proximo
                </button>
                <button ref="submit_novo_orcamento" class="btn btn-primary font-weight-bold" @click="salvarOrcamento">
                  Salvar
                </button>
                <button ref="submit_previa_nfe" class="btn btn-clean font-weight-bold"
                  @click="confirmCancel('orcamento')">
                  Cancelar
                </button>
              </div>
            </div>
            <div v-else>
              <button :disabled="store.currentTab === 0" class="btn btn-secondary font-weight-bold mr-4"
                @click="handlePrevious">
                Anterior
              </button>
              <button class="btn btn-primary font-weight-bold" @click="handleNext">
                Proximo
              </button>
              <button ref="submit_previa_nfe" class="btn btn-clean font-weight-bold" @click="confirmCancel('orcamento')">
                Cancelar
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState, mapActions } from "vuex";
import "vue-select/dist/vue-select.css";
import { validationMixin } from "vuelidate";
import FormatMixin from "../../Application/mixins/FormatMixin";
import { SistemaMixin, ValidacaoMixin } from "@/modules/Application";
import { mask } from "vue-the-mask";
import PagamentosMixin from "../../Pdv/mixins/PagamentosMixin";
import InstallmentsMixin from "../../Application/mixins/InstallmentsMixin";
import Cliente from "./componentes/ClienteTab.vue";
import Informacoes from "./componentes/Informacoes.vue";
import Produto from "./componentes/ProdutoTab.vue";
import Faturamento from "./componentes/FaturamentoTab.vue";
import { store } from "../storeOrcamento";

export default {
  name: "app-orcamento-form",
  props: {
    notaCompra: {
      type: Object,
      default: () => { },
    },
  },
  directives: {
    mask,
  },
  data() {
    return {
      store,
      formProduto: {},
      formFaturamento: {},
      orcamentoId: null,
      windowWidth: window.innerWidth,
    };
  },
  mixins: [
    validationMixin,
    FormatMixin,
    SistemaMixin,
    ValidacaoMixin,
    PagamentosMixin,
    InstallmentsMixin,
  ],
  components: {
    Cliente,
    Produto,
    Faturamento,
    Informacoes,
  },
  watch: {
    total(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.valorTotal = newValue;
      }
    },
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
    });
    if (this.$route.params.nfeData.orcamentoId) {
      this.id = this.$route.params.nfeData.orcamentoId;
    }
    this.getDataInit();

    this.store.currentTab = 0;

    if (!this.isAuthenticated) {
      this.$router.push({ name: "login" });
    }
  },
  methods: {
    ...mapActions(["setLoading"]),
    async getDataInit() {
      this.getPagamentos();
      if (this.$route.params.nfeData.orcamentoId) {
        this.setLoading(true);
      }
    },
    onResize() {
      this.windowWidth = window.innerWidth;
    },
    handleSair() {
      this.$swal
        .fire({
          icon: "warning",
          title: "Cancelar o lançamento em andamento?",
          showCancelButton: true,
          confirmButtonText: `Sim`,
          cancelButtonText: `Não`,
          confirmButtonColor: "#f64e60",
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            this.$router.push({ name: "comprasLista" });
          }
        });
    },
    async salvarOrcamento() {
      this.addLoadingToButton('submit_novo_orcamento')

      if (Object.keys(this.$route.params).length !== 0) {
        const { id_orcamento } = this.$route.params.orcamentoData;
        const data = this.formatDataToSendToOrcamentoApi(
          this.store.formCliente,
          this.store.formProduto,
          this.store.formFaturamento,
          this.store.formInformacoes
        );
        try {
          await this.$http.post("/orcamento/update", {
            id_orcamento,
            ...data,
          });
          this.$toast.success("Orçamento editado com sucesso!");
          this.$router.push({ name: "orcamento" });
        } catch (error) {
          this.$toast.error("Erro ao editar Orçamento!");
        }
      } else {
        try {
          const data = this.formatDataToSendToOrcamentoApi(
            this.store.formCliente,
            this.store.formProduto,
            this.store.formFaturamento,
            this.store.formInformacoes
          );
          if (this.orcamentoId) {
            await this.$http.post("/orcamento/update", {
              id_orcamento: this.orcamentoId,
              ...data,
            });
          } else {
            const { id_orcamento } = await this.$http.post("/orcamento/create", data);
            this.orcamentoId = id_orcamento;
          }
          this.$toast.success("Orçamento salvo com sucesso!");
          this.$router.push({ name: "orcamento" });
        } catch (error) {
          this.$toast.error("Erro ao salvar Orçamento");
          console.log(error);
        }
      }
      this.removeLoadingToButton('submit_novo_orcamento')
    },
    handleNext() {
      if (this.store.currentTab === 6) return;
      this.store.currentTab++;
    },
    handlePrevious() {
      if (this.store.currentTab === 0) return;
      this.store.currentTab--;
    },
  },
  computed: {
    ...mapGetters([
      "isAuthenticated",
      "breadcrumbs",
      "pageTitle",
      "layoutConfig",
      "nutsDigital",
    ]),
    ...mapState(["loading"]),
    isLoading() {
      return this.loading.loading;
    },
    loaderEnabled() {
      return !/false/.test(this.layoutConfig("loader.type"));
    },
    contentFluid() {
      return this.layoutConfig("content.width") === "fluid";
    },
    toolbarDisplay() {
      return true;
    },
    subheaderDisplay() {
      return !!this.layoutConfig("subheader.display");
    },
    displaySubheaderOnDashboard() {
      return this.$route.name !== "dashboard";
    },
    canSaveOrcamento() {
      return this.store.currentTab === 3 || this.store.isUpdate;
    },
  },
  created() {
    this.store.currentTab = 0
    this.store.isUpdate = false;
    if (Object.keys(this.$route.params).length !== 0) {
      const { id_orcamento } = this.$route.params.orcamentoData;
      this.orcamentoId = id_orcamento;
      this.store.isUpdate = true;
    }
  },
};
</script>

<style>
.action-buttons {
  display: flex;
}

.cardHeader {
  display: flex;
  max-width: 11rem;
  justify-content: center;
  background-color: white;
  margin-bottom: 5px;
  border-radius: 14px;
  padding: 0.5rem;
  margin-left: auto;
}

.busca-produto .vs__search {
  font-weight: 400;
  font-size: 1.45rem !important;
  margin-bottom: 0.5rem !important;
  line-height: 1.2 !important;
  text-transform: uppercase !important;
}

.busca-produto .vs__dropdown-toggle {
  border: 0px !important;
}

.busca-produto .vs__actions {
  display: none;
}

.busca-produto .vs__selected-options {
  max-width: 100%;
  flex-wrap: initial;
}

.busca-produto .vs__selected {
  text-align: left !important;
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.busca-cliente .vs__search {
  font-size: 18px;
}

.busca-cliente .vs__dropdown-toggle {
  color: var(--primary-color);
  background-color: #fff;
  border: 2px solid #dedede;
  height: 40px;
}

.busca-cliente .vs--open .vs__dropdown-toggle {
  background-color: #fff;
  border: 2px solid var(--primary-color);
}

.busca-cliente .vs__actions {
  display: none;
}

.busca-cliente .vs__dropdown-option--highlight,
.vs__dropdown-menu li:hover,
.vs__dropdown-menu li:focus {
  background-color: var(--primary-color)5e !important;
  color: var(--primary-color) !important;
}

.busca-cliente .vs__dropdown-menu {
  color: var(--primary-color) !important;
}

.busca-cliente .vs__selected-options {
  max-width: 100%;
  flex-wrap: initial;
}

.busca-cliente .vs__selected {
  text-align: left !important;
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.remove-pgto {
  font-weight: bold;
  font-size: 18px;
  color: #4f4f4f;
}

.add-pgto {
  font-weight: bold;
  font-size: 22px;
  color: var(--primary-color);
  float: right;
}

.input-quantidade {
  font-size: 1.75rem;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
  background: transparent;
  border: transparent;
  color: #fff;
  padding: 0px;
  margin-top: -10px;
  margin-bottom: 0px;
  text-align: right;
}

.input-quantidade:focus {
  box-shadow: 0 0 0 0;
  border: 0 none;
  outline: 0;
  border: 0px;
  background-color: transparent;
  color: #fff;
}

.card {
  border: 0;
}

h4 {
  color: #6d747c;
  /* font-weight: 900; */
}

.btn-finalizar-venda {
  background-color: #eee5ff !important;
  font-size: 1.25rem !important;
  text-align: center !important;
  cursor: pointer;
}

.card-total-right p,
.card-total-left p {
  color: #fff !important;
}

.card-header h5,
.card-header h3 {
  margin-bottom: 0rem;
}

.card-header .form-control {
  height: auto;
  margin-top: 0px;
}

.card-header .busca-produto {
  margin-top: 2px;
}

.card-titulo-itens .card-body {
  padding: 5px 0 !important;
}

.card-itens .table tr th {
  padding: 5px;
  font-size: 12px;
}

.card-itens .table tr td,
.card-total-right p,
.card-total-left p {
  font-size: 12px;
}

.tableItensCompra {
  max-height: 300px !important;
  height: calc(100vh - 395px) !important;
}

.card-total-left,
.card-total-right {
  height: 80px !important;
}

.btn.btn-secondary {
  color: var(--primary-color);
  background-color: #fff;
  border-color: #fff;
}
</style>
