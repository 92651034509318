<template>
  <b-tab title="Documentos" :active="isTheCurrentTab" :disabled="!isTheCurrentTab" class="mt-5">
    <b-row>
      <b-col md="10">
        <b-form-group id="numero_nfe" label-for="numero_nfe" label="Número NFe">
          <VueSelect ref="numero_nfe" :filterable="false" @search="onSearchNfe" :options="searchedNfes"
            v-model="selectedNFe">
            <template slot="no-options">
              Digite o número da NFe para buscar
            </template>
            <template slot="option" slot-scope="option">
              <div class="d-center">
                {{ option.numero }} - {{ option.nomeCliente }} - {{ option.chave }}
              </div>
            </template>
            <template slot="selected-option" slot-scope="option">
              <div class="selected d-center">
                {{ option.numero }} - {{ option.nomeCliente }} - {{ option.chave }}
              </div>
            </template>
          </VueSelect>
        </b-form-group>
      </b-col>
      <b-col>
        <b-form-group label-for="add">
          <button @click="addNfe(selectedNFe)">
            <Plus class="btn btn-sm btn-info btn-hover-info btn-icon mr-2 mt-9" v-if="windowWidth > 768" />
            <button v-else class="btn btn-info btn-hover-dark font-weight-bolder font-size-lg ml-2">
              Adicionar produto
            </button>
          </button>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <TableApp item="produto" subTitulo="" :data="documentosVinculados" :fields="fields" classFilterColumn="nome" ref="nome">
          <template slot="customRow" scope="props">
            <span slot="nome" class="pl-0 pt-8" v-if="props.field == 'numero'">
              <div class="d-flex align-items-center">
                <div class="font-weight-bold text-muted">
                  {{ props.rowData.numero }}
                </div>
              </div>
            </span>
            <span slot="quantidade" class="pl-0 pt-8" v-if="props.field == 'chave'">
              <div class="d-flex align-items-center">
                <div class="font-weight-bold text-muted">
                  {{ props.rowData.chave }}
                </div>
              </div>
            </span>
            <span slot="actions" v-if="props.field == 'actions'">
              <div class="row-actions" style="text-align: right !important">
                <span style="overflow: visible; position: relative; width: 130px">
                  <div class="action-buttons">
                    <button @click="handleDeleteNfe(props.rowData)"
                      class="btn btn-sm btn-danger btn-hover-danger btn-icon mr-2">
                      <TrashCanIcon :size="16" />
                    </button>
                  </div>
                </span>
              </div>
            </span>
          </template>
        </TableApp>
      </b-col>
    </b-row>
  </b-tab>
</template>

<script>
import { mapGetters, mapState, mapActions } from "vuex";
import { validationMixin } from "vuelidate";
import { SistemaMixin } from "@/modules/Application";
import { store } from "../../storeLogistica";
import _ from "lodash";
import VueSelect from "vue-select";
import Plus from "vue-material-design-icons/PlusThick.vue";
import TableApp from "../../../../components/Table/Table3.vue"
import TrashCanIcon from "vue-material-design-icons/TrashCan.vue";

export default {
  name: "app-nfe-form-documentosTab",
  components: {
    VueSelect,
    Plus,
    TableApp,
    TrashCanIcon,
  },
  directives: {
  },
  data() {
    return {
      store,
      selectedNFe: null,
      searchedNfes: [],
      windowWidth: window.innerWidth,
      documentosVinculados: [],
      fields: [
        {
          label: "Número",
          field: "numero",
          slot: true,
          sortable: false,
          width: "40%",
          filterOptions: {
            enabled: false,
          },
        },
        {
          label: "Chave",
          field: "chave",
          slot: true,
          sortable: false,
          width: "40%",
        },
        {
          label: "",
          field: "actions",
          sortable: false,
          slot: true,
          width: "20%",
        },
      ],
    }
  },
  mixins: [
    validationMixin,
    SistemaMixin,
  ],
  methods: {
    ...mapActions(["setLoading"]),
    async getDataInit() {
      const mdfeData = this.$route.params?.mdfeData?.mdfe;
      if(mdfeData){
        this.documentosVinculados = mdfeData.documentosVinculados
      }
    },
    onSearchNfe(search, loading) {
      if (search.length === 0) return;
      if (!/^[0-9]+$/.test(search)) {
        this.$toast.error('Insira apenas números para a busca!')
        return;
      }
      this.searchNfe(loading, search, this);
    },
    searchNfe: _.debounce(async (loading, numero, vm) => {
      if (loading) loading(true);
      try {
        const { data } = await vm.$http.post('/nfe/get-nfe-by-numero', { numero });
        vm.searchedNfes = [data]
      } catch (e) {
        console.log(e);
      }
      if (loading) loading(false);
    }, 1000),
    onResize() {
      this.windowWidth = window.innerWidth;
    },
    addNfe(nfeToAdd){
      const isAlreadyAdded = this.documentosVinculados.find(doc => doc.id_nfe === nfeToAdd.id);
      if(isAlreadyAdded){
        this.$toast.warning('NFe ja adicionada!');
        return;
      }
      this.documentosVinculados = [...this.documentosVinculados, {
        chave: nfeToAdd.chave,
        tipo_chave: 1,
        tipo_nfe: nfeToAdd.tipoNF,
        id_nfe: nfeToAdd.id,
        numero: nfeToAdd.numero
      }]
      this.selectedNFe = null;
    },
    handleDeleteNfe(nfeToRemove){
      this.documentosVinculados = this.documentosVinculados.filter(doc => doc.id_nfe !== nfeToRemove.id_nfe)
    },
  },
  created() {
    this.getDataInit();
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
    });
  },
  computed: {
    ...mapGetters([
      "isAuthenticated",
      "breadcrumbs",
      "pageTitle",
      "layoutConfig"
    ]),
    ...mapState(["loading"]),
    isLoading() {
      return this.loading.loading;
    },
    isTheCurrentTab() {
      return store.currentTab === 4
    }
  },
  watch: {
    "store.currentTab"(newValue, oldValue) {
      if (newValue !== oldValue && newValue === 5 && oldValue < newValue) {
        if(this.documentosVinculados.length === 0){
          this.$toast.error('Obrigatório ao menos um documento vinculado!')
          this.store.currentTab = oldValue;
          return;
        }
        this.store.formData = {
          ...this.store.formData,
          documentosVinculados: this.documentosVinculados,
        }
      }
    },
  }
}

</script>

<style></style>