<template>
  <div class="card card-custom card-stretch gutter-b">
    <div class="card-header border-0 py-5">
      <h3 class="card-title align-items-start flex-column">
        <span class="card-label font-weight-bolder text-dark">
          Editar terminal
        </span>
      </h3>
    </div>
    <Form url="/pos/update" :pos="pos" />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Form from "./Form.vue";
import PosData from "./PosData";

export default {
  name: "app-pos-update",
  data() {
    return {
      pos: {},
    };
  },
  components: {
    Form,
  },
  methods: {
    ...mapActions(["setLoading"]),
    async getPos(id_configuracao_pos) {
      try {
        const { data } = await this.$http.post("/pos/find", {
          id_configuracao_pos: id_configuracao_pos,
        });
        return data;
      } catch (error) {
        return null;
      }
    },
    async getInit() {
      try {
        const codigo = this.$route.params.id_configuracao_pos;

        this.setLoading(true);

        const pos = await this.getPos(codigo);

        this.setLoading(false);

        if (!pos.id_configuracao_pos || pos == null) {
          this.$router.push({ name: "posLista" });
          return this.$toast.error("Pos não encontrado!");
        }

        this.pos = new PosData(pos);
      } catch {
        return;
      }
    },
  },
  computed: {
    ...mapGetters(["itsblocked"]),
  },
  mounted() {
    if (this.itsblocked) {
      this.$router.push({ name: "assinatura" });
    }
    this.getInit();
  },
};
</script>
