<template>
  <Form url="/compras/create" :compra="compra" />
</template>

<script>
import Form from "./Form.vue";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import NaturezaData from "./NaturezaData";

export default {
  name: "app-nfe-create",
  data() {
    return {
      compra: new NaturezaData({}),
    };
  },
  components: {
    Form,
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {
        title: "novaNfe",
      },
    ]);
  },
};
</script>
