<template>
  <form @submit.prevent="onSubmit">
    <slot />
  </form>
</template>

<script>
export default {
  name: "Form",
  props: {
    onSubmit: {
      type: Function
    }
  }
};
</script>
