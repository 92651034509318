<template>
  <div class="card card-custom card-stretch gutter-b">
    <div class="card-header border-0 py-5">
      <h3 class="card-title align-items-start flex-column">
        <span class="card-label font-weight-bolder text-dark">
          Nova Variação
        </span>
      </h3>
    </div>
    <Form url="/variavel/create" :variacao="null" />
  </div>
</template>

<script>
import Form from "./Form.vue";
import { mapGetters } from "vuex";
import {
  SistemaMixin,
} from "@/modules/Application";

export default {
  name: "app-variacoes-create",
  data() {
    return {
      variacao: {
        nome: null,
        variaveis: []
      },
    };
  },
  components: {
    Form,
  },
  mixins: [SistemaMixin],
  computed: {
    ...mapGetters(["itsblocked"]),
  },
  mounted() {
    if (this.itsblocked) {
      this.$router.push({ name: "assinatura" });
    }
  },
};
</script>
