import moment from "moment";
import { priceOptions } from "@/modules/Application"

export default {
  methods: {
    formatCpfCnpj(valor) {
      if (valor === null) {
        return "";
      }
      if (valor.length <= 11) {
        // eslint-disable-next-line
        return valor.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, `\$1.\$2.\$3\-\$4`);
      }
      // eslint-disable-next-line
      return valor.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g, "\$1.\$2.\$3\/\$4\-\$5");
    },
    valorUS(valor = 0) {
      let valorFormatado = valor;
      if (typeof valor === "string") {
        valorFormatado = valor.split(".").join("");
        return parseFloat(valorFormatado.replace(",", ".").replace("R$ ", ""));
      }
      return 0;
    },
    valorReal(valor = 0, aplyConfig) {
      return valor.toLocaleString("pt-br", {
        minimumFractionDigits: aplyConfig ? priceOptions.precision : 2,
      });
    },
    roundTo2Digits(value, decPlaces = 2) {
      let val = Math.round(Math.round((value * (10 ** decPlaces) + Number.EPSILON) * 100) / 100);
      let fraction = (Math.round((val - parseInt(val, 10)) * 10) / 10);
      if (fraction === -0.5) fraction = -0.6;
      val = Math.round(parseInt(val, 10) + fraction) / (10 ** decPlaces);
      return val;
    },
    formatDate(value, fmt = "DD/MM/YYYY") {
      return value === null
        ? ""
        : moment(value, "YYYY-MM-DD HH:mm:ss").format(fmt);
    },
  },
};
