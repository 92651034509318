<template>
  <div>
    <Label v-if="label != null" :disabled="disabled">
      {{ label }}
    </Label>
    <template v-if="busca">
      <VueSelect
        :label="labelBusca"
        :class="inputClass"
        :ref="refBusca"
        :options="options"
        :placeholder="placeholderInField"
        :resetOnOptionsChange="true"
        :disabled="disabled"
        :value="value"
        @input="handleChange"
      >
        <template slot="no-options">
          {{ placeholder }}
        </template>
        <template slot="option" slot-scope="option">
          <slot name="options" :data="option"></slot>
        </template>
        <template slot="selected-option" slot-scope="option">
          <slot name="selected" :data="option"></slot>
        </template>
      </VueSelect>
    </template>
    <template v-else>
      <VueSelect
        :label="labelBusca"
        :ref="refBusca"
        :class="inputClass"
        :filterable="filterable"
        :placeholder="'F2 - Lançar Produto'"
        :options="options"
        :resetOnOptionsChange="true"
        :disabled="disabled"
        :value="value"
        @input="handleChange"
        @search="handleSearch"
      >
        <template slot="no-options">
          {{ placeholder }}
        </template>
        <template slot="option" slot-scope="option">
          <slot name="options" :data="option"></slot>
        </template>
        <template slot="selected-option" slot-scope="option">
          <slot name="selected" :data="option"></slot>
        </template>
      </VueSelect>
    </template>
  </div>
</template>

<script>
import VueSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import { Label } from "../../../../components/Form";

export default {
  name: "Select",
  components: {
    VueSelect,
    Label,
  },
  props: {
    busca: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: null,
    },
    placeholderInField: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
    },
    labelBusca: {
      type: String,
    },
    refBusca: {
      type: String,
    },
    inputClass: {
      type: String,
    },
    filterable: {
      type: Boolean,
      default: false,
    },
    change: {
      type: Function,
      required: true,
    },
    search: {
      type: Function,
      required: true,
    },
    options: {
      type: Array,
    },
    disabled: {
      type: Boolean,
      default: () => false,
    },
    value: {
      type: Object,
      default: null,
    },
  },
  methods: {
    handleChange(selected) {
      this.change(selected);
    },
    handleSearch(search, loading) {
      this.search(search, loading);
    },
  },
};
</script>

<style>
.vs--disabled > div {
  border: 2px solid #dedede !important;
  color: #7e7e7e !important;
  background-color: #e9ecef !important;
  opacity: 1;
}

.vs--disabled input {
  background-color: #e9ecef !important;
}
</style>
