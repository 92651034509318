<template>
  <Form url="/nfe/update" :nfe="nfe" />
</template>

<script>
import { mapActions } from "vuex";
import Form from "./Form.vue";
import VendaData from "./VendaData";
import { mapGetters } from "vuex";

export default {
  name: "app-venda-update",
  data() {
    return {
      nfe: new VendaData({}),
    };
  },
  components: {
    Form,
  },
  methods: {
    ...mapActions(["setLoading"]),
    async getNFE(id) {
      try {
        const { data } = await this.$http.post("/nfe/get", {
          id: id,
        });
        return data;
      } catch (error) {
        return null;
      }
    },
    async getInit() {
      try {
        const id = this.$route.params.id;

        this.setLoading(true);

        const { nfe } = await this.getNFE(id);
        this.setLoading(false);

        if (!nfe.modelo || nfe == null) {
          this.$router.push({ name: "vendasLista" });
          return this.$toast.error("Venda não encontrada!");
        }

        
        this.nfe = nfe;
      } catch {
        return;
      }
    },
  },
  computed: {
    ...mapGetters(["itsblocked"]),
  },
  mounted() {
    this.getInit();
    if (this.itsblocked) {
      this.$router.push({ name: "assinatura" });
    }
  },
};
</script>
